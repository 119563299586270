<mat-accordion>
    <div class="form_sections" *ngFor="let v of quoteApplicationData.vehicles; let i = index">
        <mat-expansion-panel [expanded]="i == 0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>
                        <u>
                            <span>Horsebox {{i + 1}}</span>
                        </u>
                    </h4>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-md-10">
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Registration Number:</label></div>
                        <div class="col-md-8">
                            <input type="text" name="registration_number" [value]="v.registration_number"
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Model</label></div>
                        <div class="col-md-8">
                            <input type="text" name="model" [value]="v.model" class="form-control form-control-sm"
                                readonly>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Make</label></div>
                        <div class="col-md-8">
                            <input type="text" name="make" [value]="v.make" class="form-control form-control-sm"
                                readonly>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Year of Make</label></div>
                        <div class="col-md-8">
                            <input type="text" name="year" [value]="v.year" class="form-control form-control-sm"
                                readonly>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Gross Vehicle Weight</label></div>
                        <div class="col-md-8">
                            <input type="text" name="weight" [value]="v.weight" class="form-control form-control-sm"
                                readonly>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Value</label></div>
                        <div class="col-md-8">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                </div>
                                <input type="text" name="value" [value]="v.value" class="form-control form-control-sm"
                                    readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Cover Type</label></div>
                        <div class="col-md-8">
                            <input type="text" name="cover_type" [value]="v.cover_type"
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Driving Restriction</label></div>
                        <div class="col-md-8">
                            <input type="text" name="driving_restriction" [value]="v.driving_restriction"
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="v.extended_use">
                        <div class="col-md-4 text-white"><label>Extended Use</label></div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-checkbox name="proposer_equine" class="mb-2"
                                        [checked]="v.extended_use.proposer_equine" disabled>
                                        Business use for proposer's equine trade
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-6">
                                    <mat-checkbox name="proposer_non_equine" disabled
                                        [checked]="v.extended_use.proposer_non_equine" class="mb-2">
                                        Business use for proposer's non-equine trade
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-6">
                                    <mat-checkbox name="driver_equine" [checked]="v.extended_use.driver_equine"
                                        class="mb-2" disabled>
                                        Business use for named driver's equine trade
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-6">
                                    <mat-checkbox name="driver_non_equine" [checked]="v.extended_use.driver_non_equine"
                                        class="mb-2" disabled>
                                        Business use for named driver's non-equine trade
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-6">
                                    <mat-checkbox name="livestock_carriage" disabled
                                        [checked]="v.extended_use.livestock_carriage" class="mb-2">
                                        Hire and reward for the carriage of horses/livestock.
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Annual Green card</label></div>
                        <div class="col-md-8">
                            <mat-radio-group class="row" aria-label="Select an option" name="green_card" disabled>
                                <div class="col-md-6">
                                    <div class="quote_radio">
                                        <mat-radio-button value="1" [checked]="v.green_card">Yes</mat-radio-button>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="quote_radio">
                                        <mat-radio-button value="2" [checked]="!v.green_card">No</mat-radio-button>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Annual Mileage</label></div>
                        <div class="col-md-8">
                            <input type="text" name="mileage" [value]="v.mileage"
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Registered Owner</label></div>
                        <div class="col-md-8">
                            <mat-radio-group class="row" aria-label="Select an option" name="owner" disabled>
                                <div class="col-md-6">
                                    <div class="quote_radio">
                                        <mat-radio-button value="1" [checked]="!v.owner">Yes</mat-radio-button>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="quote_radio">
                                        <mat-radio-button value="2" [checked]="v.owner">No</mat-radio-button>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="v.owner">
                        <div class="col-md-4 text-white"><label>Owner Name</label></div>
                        <div class="col-md-8"> 
                            <input type="text" name="owner_name" [value]="v.owner | titlecase"
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Is this vehicle kept overnight at {{ (quoteApplicationData && quoteApplicationData.proposer && quoteApplicationData.proposer.address)?quoteApplicationData.proposer.address.postcode:'' }}?</label></div>
                        <div class="col-md-8">
                            <mat-radio-group class="row" aria-label="Select an option" name="is_kept_overnight" disabled>
                                <div class="col-md-6">
                                    <div class="quote_radio">
                                        <mat-radio-button value="1" [checked]="!v.postcode">Yes</mat-radio-button>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="quote_radio">
                                        <mat-radio-button value="2" [checked]="v.postcode">No</mat-radio-button>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="v.postcode">
                        <div class="col-md-4 text-white"><label>Postcode </label></div>
                        <div class="col-md-8"> 
                            <input type="text" name="owner_name" [value]="v.postcode | titlecase"
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Tracker </label></div>
                        <div class="col-md-8"> 
                            <input type="text" name="owner_name" [value]="v.tracker | titlecase"
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>NCD Years </label></div>
                        <div class="col-md-8"> 
                            <input type="text" name="owner_name" [value]="v.ncd"
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                </div>
            </div>

        </mat-expansion-panel>
    </div>
</mat-accordion>