
<div class="row">
    <div class="col-md-7">
        <div class="row mb-3">
            <div class="col-md-6"><label>Latest Version:</label></div>
            <div class="col-md-6"> 
                <input type="text" name="quote_version" [value]="quote.sequence_number"
                     class="form-control form-control-sm" readonly></div>
        </div>
        <div class="row mb-3" *ngIf="quote.policy_ref_number">
            <div class="col-md-6"><label>Policy Ref No.:</label></div>
            <div class="col-md-6">
                <a class="text-white" (click)="policyDetails()"><input type="text" name="policy_ref" [value]="quote.policy_ref_number"
                    class="form-control form-control-sm" readonly style="cursor: pointer;"></a></div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>Start Date:</label></div>
            <div class="col-md-6">
                <input type="text" name="policy_start_date" [value]="quote.policy_start_date | date:'dd-MM-yyyy'"
                    placeholder="Policy Start Date" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6"><label>Transaction Type:</label></div>
            <div class="col-md-6">
                <input type="text" name="transction_type" [(ngModel)]="transction_type" readonly
                        class="form-control form-control-sm">
            </div>
        </div>
        <div class="row mb-3" *ngIf="quote.type == 2">
            <div class="col-md-6"><label>Retroactive Date:</label></div>
            <div class="col-md-6">
                <input type="text" name="retroactive_date" [value]="quote.retroactive_date | date:'dd-MM-yyyy'"
                    placeholder="Retroactive Date" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6"><label>Status:</label></div>
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <mat-select class="form-control form-control-sm" name="status" disabled 
                        [(ngModel)]="quote.insurance_status_id">
                        <mat-option [value]="status.id" *ngFor="let status of policyStatuses">
                            {{status.display_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6"><label>Insurer:</label></div>
            <div class="col-md-6">
                <mat-form-field class="w-100">  
                    <mat-select [(ngModel)]="quote.insurer" disabled
                        class="form-control form-control-sm" name="insurer" >
                        <mat-option *ngFor="let insurer of insurers" [value]="insurer.id">
                         {{insurer.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<app-quote-versions [quote]="quote" [is_policy]="0"  (hasImportantNotes)="onhasImportantNotesChange($event)"
 (quoteVersionsUpdated)="quoteVersionsUpdated($event)"></app-quote-versions>