<form class="g-3 needs-validation" novalidate [formGroup]="frmFields" (ngSubmit)="onSubmit()">
    <div class="form_sections">
        <div class="row mt-3">
            <div class="col-md-10">
                <h6 class="text-white">Has any driver other than the proposer had any unspent major and/or more than 2
                    minor motoring convictions?
                    <span class="ml-1"
                        (click)="helpTextPopup(content,'The following conviction codes are defined as major convictions, all other codes are considered minor.

                        Any motoring conviction where the conviction code starts: AC, BA, CD, DD, GD, DR, E, IN, MR, NI, NR or, Q.
                        
                        Any motoring conviction where the conviction code is: MS60, MS61, MS62, MS63, MS64, MS65, MS66, NEND, TT99, Z007 or Z008.
                        
                        Any of the following Northern Ireland conviction codes; 41.04.001, 41.04.002, 50.08.015 to 50.08.023, 50.08.025 to 50.08.035, 50.08.123 to 50.08.142, 50.08.146, 50.08.147, 50.08.200 to 50.08.217, 50.10.001, 50.10.002, and 52.08.093')"><i
                            class="mdi mdi-comment-question-outline"></i></span>
                </h6>
                <mat-radio-group class="row" aria-label="Select an option" name="driver_conviction_declaration"
                    formControlName="driver_conviction_declaration" required (change)="viewDriversSection()">
                    <div class="col-md-4">
                        <div class="quote_radio w-90">
                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="quote_radio w-90">
                            <mat-radio-button [value]="false">No</mat-radio-button>
                        </div>
                    </div>
                </mat-radio-group>
                <div class="invalid-feedback" style="font-size: 13px;"
                    *ngIf="(f.driver_conviction_declaration.touched || submitted) && f.driver_conviction_declaration.errors?.required">
                    Please select an option.
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-10">
                <h6 class="text-white">
                    During the past 3 years, has any driver other than the proposer been involved in a motor accident or
                    made a motor insurance claim?
                </h6>
                <mat-radio-group class="row" aria-label="Select an option" name="driver_claim_declaration"
                    formControlName="driver_claim_declaration" required (change)="viewDriversSection()">
                    <div class="col-md-4">
                        <div class="quote_radio w-90">
                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="quote_radio w-90">
                            <mat-radio-button [value]="false">No</mat-radio-button>
                        </div>
                    </div>
                </mat-radio-group>
                <div class="invalid-feedback" style="font-size: 13px;"
                    *ngIf="(f.driver_claim_declaration.touched || submitted) && f.driver_claim_declaration.errors?.required">
                    Please select an option.
                </div>
            </div>
        </div>
    </div>

    <!---  DRIVER DETAILS  -->
    <div class="row mt-3" *ngIf="modelObj.drivers && modelObj.drivers.length > 0">
        <div class="col-md-12">
            <h6 class="text-white">
                Based on your previous selection we need you to provide details of the following drivers:
            </h6>
            <mat-accordion formArrayName="drivers" multi>
                <div class="form_sections" *ngFor="let d of drivers.controls; let i = index">
                    <mat-expansion-panel [expanded]="true" formGroupName="{{i}}">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h4>
                                    <span *ngIf="!d.get('first_name').value">Driver {{i + 1}}</span>
                                    <span *ngIf="d.get('first_name').value">{{ d.get('title').value }} {{
                                        d.get('first_name').value }} {{ d.get('last_name').value }}</span>
                                    <a (click)="removeDriver(i)" title="Remove Driver"
                                        class="btn btn-danger btn-sm p-1 ml-3"><i
                                            class="mdi mdi-delete"></i></a>
                                            
                                        <!-- *ngIf="i > 0 && (this.modelObj && (d.get('first_name').value != this.modelObj.proposer.first_name))" -->
                                </h4>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                            <div class="col-md-4" *ngIf="staticData">
                                <mat-form-field class="w-100">
                                    <mat-label>Title</mat-label>
                                    <mat-select formControlName="title" class="form-control form-control-sm"
                                        name="title" required
                                        [ngClass]="{ 'is-invalid': (d.get('title').touched || submitted) && d.get('title').errors?.required }">
                                        <mat-option *ngFor="let title of staticData.title_options" [value]="title">
                                            {{ title }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="invalid-feedback"
                                    *ngIf="(d.get('title').touched || submitted) && d.get('title').errors?.required">
                                    Title is required.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group mt-1">
                                    <input type="text" name="first_name" placeholder="First Name"
                                        class="form-control form-control-sm" formControlName="first_name" required
                                        [ngClass]="{ 'is-invalid': (d.get('first_name').touched || submitted) && d.get('first_name').errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(d.get('first_name').touched || submitted) && d.get('first_name').errors?.required">
                                        First name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" name="last_name" placeholder="Last Name"
                                        class="form-control form-control-sm" formControlName="last_name">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 mt-1">
                                <input type="text" required name="dob" formControlName="dob" placeholder="Date of Birth"
                                    class="form-control form-control-sm datepicker" matInput
                                    (focus)="dobDatepicker.open()" [matDatepicker]="dobDatepicker" [max]="maxDOB"
                                    [ngClass]="{ 'is-invalid': (d.get('dob').touched || submitted) && d.get('dob').errors?.required }">
                                <mat-datepicker-toggle [for]="dobDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #dobDatepicker></mat-datepicker>
                                <div class="invalid-feedback"
                                    *ngIf="(d.get('dob').touched || submitted) && d.get('dob').errors?.required">
                                    DOB is required.
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="staticData">
                                <mat-form-field class="w-100">
                                    <mat-label>Type of licence</mat-label>
                                    <mat-select formControlName="licence_type" class="form-control form-control-sm"
                                        name="licence_type" required
                                        [ngClass]="{ 'is-invalid': (d.get('licence_type').touched || submitted) && d.get('licence_type').errors?.required }">
                                        <mat-option *ngFor="let type of staticData.licence_types" [value]="type">
                                            {{ type }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="invalid-feedback"
                                    *ngIf="(d.get('licence_type').touched || submitted) && d.get('licence_type').errors?.required">
                                    Licence type is required.
                                </div>
                            </div>
                            <div class="col-md-4 mt-1">
                                <input type="text" required name="licence_length" formControlName="licence_length"
                                    placeholder="Length licence held" class="form-control form-control-sm datepicker"
                                    matInput (focus)="llDatepicker.open()" [matDatepicker]="llDatepicker"
                                    [ngClass]="{ 'is-invalid': (d.get('licence_length').touched || submitted) && d.get('licence_length').errors?.required }">
                                <mat-datepicker-toggle [for]="llDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #llDatepicker></mat-datepicker>
                                <div class="invalid-feedback"
                                    *ngIf="(d.get('licence_length').touched || submitted) && d.get('licence_length').errors?.required">
                                    Licence length is required.
                                </div>
                            </div>
                        </div>
                        <!--- Claims Section --->
                        <app-am-claims [staticData]="staticData" formControlName="claims" [submitted]="submitted" [modelObj]="modelObj" [driver_id]="d.get('id').value" [is_claims_selected]="f.driver_claim_declaration.value"></app-am-claims>
                        <app-am-convictions [staticData]="staticData" formControlName="convictions" [submitted]="submitted" [modelObj]="modelObj" [driver_id]="d.get('id').value" [is_convictions_selected]="f.driver_conviction_declaration.value"></app-am-convictions>
                        <!-- <a (click)="addClaim()" class="btn  btn-default btn-success btn-sm f-12" *ngIf="f.driver_claim_declaration.value">Add Claim</a> -->
                    </mat-expansion-panel>
                </div>
                <a (click)="addNewDriver()" class="btn  btn-default btn-success btn-sm f-12" *ngIf="f.driver_claim_declaration.value || f.driver_conviction_declaration.value">Add Driver</a>
                <div *ngIf="isDriverNodeEmpty" class="invalid-feedback mb-4">Please add the driver.</div>
            </mat-accordion>
        </div>
    </div>




    <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6 text-right"><button class="btn btn-success btn-sm">Proceed</button></div>
    </div>
</form>

<ng-template #content let-modal>
    <div class="modal-body">
        <div class="helptext" [innerHTML]="helptext"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>

<!-- <p>
    Form is {{frmFields.valid ? 'Valid' : 'Invalid'}}
</p>
<pre>
            {{frmFields.value | json}}
            </pre> -->