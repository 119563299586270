import { Component, OnInit, Input, forwardRef, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder, Validators, FormGroup, FormArray,
  ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { ActivatedRoute, Router } from "@angular/router";
import { HorseDetails } from '../../../model/quote/horse_details.model';
import { QuoteService } from '../../quote.service';

@Component({
  selector: 'app-animals-insurance',
  templateUrl: './animals-insurance.component.html',
  styleUrls: ['../../quote.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AnimalsInsuranceComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AnimalsInsuranceComponent),
      multi: true
    }
  ]
})
export class AnimalsInsuranceComponent implements ControlValueAccessor, OnInit {
  @Input() submitted: boolean;
  @Input() selectedTabIndex;
  @Input() quoteModelDetails = new QuoteVersions();
  @Output() calDetails = new EventEmitter();
  QuoteDetails = new QuoteVersions();
  animalSection: FormGroup;
  subscriptions: Subscription[] = [];
  totalBullsInsurance = [];
  allClaims = [];
  ratings = [];
  animal_details: FormArray;
  liveStocks = [];
  CoverPeriods = [];
  AllCoverPeriods = [];
  calculations = new CalculationsDetails();
  ipt_fee = 0;
  livestockSex = [];
  @Input()
  set staticData(value: any) {
    if (value) {
      this.liveStocks = value.livestock;
      this.CoverPeriods = value.livestock_cover_periods;
      this.AllCoverPeriods = value.livestock_cover_periods;
      this.allClaims = value.claim_loading;
      this.ratings = value.rating;
      value.livetock_sex.forEach((res) => {
        if (res.id != '30246458-ac55-4d2d-aef8-8c75101f8ce2' && res.id != '30346458-ac55-4d2d-aef8-8c75101f8ce2') {
          this.livestockSex.push(res);
        }
      })

      if (value.peliwica_commissions.code == "IPT") {
        this.ipt_fee = value.peliwica_commissions.commission_percentage;
      }
    }
  }

  constructor(private fb: FormBuilder, private modalService: NgbModal, private cdRef: ChangeDetectorRef, private route: ActivatedRoute,
    private router: Router, private quoteService: QuoteService) {
    // Total number of bulls require insurance
    this.totalBullsInsurance = Array(10).fill(0, 0, 10).map((x, i) => i + 1);
  }
  maxDOB;
  minPolicyDate;
  maxPolicyDate = new Date();
  isView: boolean = false;
  isQuoteView: boolean = false;
  ngOnInit(): void {
    // Initiaize form controls
    this.animalSection = this.fb.group({
      animals_to_be_insured: ['', Validators.required],
      total_animals_require_insurance: ['', Validators.required],
      animal_details: this.fb.array([]),
      breeding_warranty_cover: ['', Validators.required],
      breeding_society_name: [''],
      breed_society_inspection: [''],
      claims: ['', Validators.required],
    });
    this.subscriptions.push(
      // Any time the inner form changes update the parent of any change
      this.animalSection.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    let minDate = new Date().setFullYear(new Date().getFullYear() - 5);
    this.minPolicyDate = new Date(minDate);
    // Set Date of Birth not more then 3 months
    let maxDate = new Date().setMonth(new Date().getMonth() - 3);
    this.maxDOB = new Date(maxDate);

    if (this.router.url.includes('policy')) {
      this.disableFields = true;
      this.isView = true;
    }
    else if (this.router.url.includes('quote-details')) {
      this.disableFields = true;
      this.isView = true;
      this.isQuoteView = true;
    }

    
  }

  quote_type: number = 0;
  disableFields: boolean = false;
  minTrialPeriodDate;
  maxTrialPeriodDate;
  ngOnChanges(changes: SimpleChanges) {
    this.QuoteDetails = this.quoteModelDetails;
    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);
    }
    if (this.animalSection && this.quoteModelDetails) {
      if (this.quoteModelDetails.livestock_meta) {
        this.animalSection.patchValue({
          animals_to_be_insured: this.quoteModelDetails.livestock_meta.livestock_type_id,
          total_animals_require_insurance: this.quoteModelDetails.livestock_meta.animal_count,
          animal_details: (this.quoteModelDetails.livestock_meta.animal_count > 0) ? this.populateAnimalsArr() : this.quoteModelDetails.livestock_details,
          breeding_warranty_cover: Number(this.quoteModelDetails.livestock_meta.is_nba_breeding_warranty),
          breeding_society_name: this.quoteModelDetails.livestock_meta.breeding_society_name,
          breed_society_inspection: this.quoteModelDetails.livestock_meta.breed_society_inspection,
          claims: this.quoteModelDetails.livestock_meta.claim_count,
        });
      }

      
      if(this.animalSection.get("breeding_warranty_cover").value == 0) {
        this.animalSection.get("breeding_warranty_cover").setValue(null);
      }
      if (this.livestockSex && this.quoteModelDetails.livestock_meta) {
        this.livestockGender = this.livestockSex.filter(x => x.livestock_type_id == this.quoteModelDetails.livestock_meta.livestock_type_id);
      }

      if (this.route.snapshot.params.id || this.route.snapshot.params.quote_id) {
        this.livestockDetails();
        this.quoteModelDetails.livestock_details.forEach((res, i) => {
          this.AllCalculations(i);
          let animals = this.frmCtrl.animal_details as FormArray;
          if (animals.at(i)) {
            if (res.livestock_cover_id == '70146458-ac55-4d2d-aef8-8c75101f8ce2') {
              animals.at(i).get('cover_period').disable();
            }
            if (!this.isEweSelected && animals) {
              animals.at(i).get('sex').disable();
            }

            this.enableDisablePurchasers(res.livestock_cover_id, i);
            this.vetCertActions(i);
          }
        })

        // Bind Claims
        this.bindClaims(this.quoteModelDetails.livestock_meta.claim_count);

      }
      this.toggleFieldsForAnimalTypes(this.quoteModelDetails.livestock_meta.livestock_type_id);
      this.CoverPeriods = [];
      this.quoteModelDetails.livestock_details.forEach((res, i) => {
        // if (this.selectedTabIndex == 6) {       
        this.getCoverPeriods(res.livestock_cover_id, i);
        let animals = this.frmCtrl.animal_details as FormArray;
        if (animals.at(i)) {
          animals.at(i).get("cover_period").setValue(res.livestock_cover_period_id);
        }
        //this.getCoverPeriods(res.livestock_cover_id, i);
        // }

        if (res.livestock_purchasers && res.livestock_cover_id) {
          this.enableDisablePurchasers(res.livestock_cover_id, i);
        }
        this.enableDisableAnimalOtherDetails(i);
        this.AllCalculations(i);
      })

      if (this.selectedTabIndex > 2 && this.quoteModelDetails.livestock_details && this.quoteModelDetails.livestock_details.length > 0 && !this.isView) {
        (this.animalSection.controls['animal_details'] as FormArray).clear();
        this.populateAnimalsArr();
        this.livestockDetails();
      }

      //this.toggleFieldsForAnimalTypes(this.quoteModelDetails.livestock_meta.livestock_type_id);

    }
    if (this.frmCtrl && this.frmCtrl.total_animals_require_insurance.value == 0) {
      this.animalSection.get("total_animals_require_insurance").setValue(null);
    }

    if (this.quoteModelDetails.livestock_details && this.quoteModelDetails.livestock_details.length == 0) {
      this.animalSection.get("total_animals_require_insurance").setValue(null);
    }

    if (this.selectedTabIndex == 6) {
      this.disableFields = true;
    }

    if (this.frmCtrl && this.frmCtrl.animal_details) {
      let animals = this.frmCtrl.animal_details as FormArray;
      if (animals.get("lot_no")) {
        animals.get("lot_no").disable();
        if (this.QuoteDetails.livestock_meta.type_of_policy == 3) {
          animals.get("lot_no").disable();
        } else {
          animals.get("lot_no").enable();
        }
      }
    }

    // Trial Period Date Min/Max
    if (this.QuoteDetails.livestock_meta) {
      this.minTrialPeriodDate = this.QuoteDetails.livestock_meta.sale_date;
      let maxDate = new Date().setMonth(new Date().getMonth() + 3);
      this.maxTrialPeriodDate = new Date(maxDate);
    }

  }

  temp_type_of_policy: number = 0;
  ngAfterContentChecked(): void {
    if (this.QuoteDetails && this.QuoteDetails.livestock_meta) {
      if (this.isEweSelected && this.QuoteDetails.livestock_meta.type_of_policy == 1) {
        this.totalBullsInsurance = Array(15).fill(0, 0, 15).map((x, i) => i + 1);
      } else {
        this.totalBullsInsurance = Array(10).fill(0, 0, 10).map((x, i) => i + 1);
      }
      if (this.temp_type_of_policy != this.QuoteDetails.livestock_meta.type_of_policy) {
        this.animalSection.get('animals_to_be_insured').setValue(null);
        (this.animalSection.controls['animal_details'] as FormArray).clear();
        this.temp_type_of_policy = this.QuoteDetails.livestock_meta.type_of_policy
      }
    }
    this.cdRef.detectChanges();
  }

  enableDisableAnimalOtherDetails(i) {
    let animals = this.frmCtrl.animal_details as FormArray;
    if (animals.at(i)) {
      if (this.selectedTabIndex == 6 || this.QuoteDetails.livestock_meta.type_of_policy >= 3) {
        animals.at(i).get("tag_no").enable();
        animals.at(i).get("animal_name").enable();
        //animals.at(i).get("dob").enable();
      } else {
        if (!this.isView) {
          animals.at(i).get("tag_no").disable();
          animals.at(i).get("animal_name").disable();
        }
        //animals.at(i).get("dob").disable();
      }
    }
  }

  populateAnimalsArr() {
    this.animalsArr.controls = [];
    if (this.quoteModelDetails.livestock_details) {
      if (!this.quoteModelDetails.livestock_details[0].livestock_breed_id) {
        this.QuoteDetails.livestock_meta.animal_count = 0;
        this.quoteModelDetails.livestock_details = [];
      }
      this.quoteModelDetails.livestock_details.forEach((res, i) => {
        // Get cover periods list
        //this.getCoverPeriods(res.livestock_cover_id, i);
        let animals = this.fb.group({
          'id': res.id,
          'lot_no': res.lot_no,
          'tag_no': res.tag_no,
          'animal_name': res.name.trim(),
          'dob': res.horse_purchase_date,
          'bull_breed': res.livestock_breed_id,
          'livestock_breed_other': res.livestock_breed_other,
          'sale_price': res.value,
          'cover_required': res.livestock_cover_id,
          'sex': res.livestock_sex_id,
          'cover_period': res.livestock_cover_period_id,
          'semen_tested': res.is_semen_tested,
          'vet_cert_provided': res.vet_certificate_required,
          'is_trial_period_deferred': res.is_trial_period_deferred,
          'trial_period_date': res.trial_period_date,
          'net_rate': res.net_rate,
          'sum_insured': res.sum_insured,
          'animal_type': res.animal_type,
          'livestock_purchasers': this.fb.array([this.PurchasersFB])
        });

        this.enableDisableOtherBreed(i);
        animals = this.enableDisableAnimalAllFields(animals, res.livestock_cover_id);


        // bind purchasers
        if (res.livestock_purchasers && res.livestock_purchasers.length > 0) {
          (animals.get("livestock_purchasers") as FormArray).clear();
          res.livestock_purchasers.forEach((res) => {
            let data = this.fb.group({
              first_name: [res.first_name],
              last_name: [res.last_name],
              email: [res.email],
              postcode: [res.postcode],
              address1: [res.address1],
              address2: [res.address2],
              city: [res.city],
              country: [res.country],
              ownership: [res.ownership],
            })
            let lp = animals.get("livestock_purchasers") as FormArray
            lp.push(data);
          })
        }



        this.animal_details = this.animalSection.get('animal_details') as FormArray;
        this.animal_details.push(animals);



        this.enableDisablePurchasers(res.livestock_cover_id, i);

      })

      this.animalsArr.controls = this.animal_details.controls;

      if (this.route.snapshot.params.id) {
        this.bindPurchasers();
      }

    }
    //if (!this.route.snapshot.params.id) {
    return this.animalsArr.controls;
    //}
  }

  trialPeriod: boolean = false;
  enableDisableAnimalAllFields(animals, livestock_cover_id) {
    if (this.selectedTabIndex == 6 || this.QuoteDetails.livestock_meta.type_of_policy >= 3) {
      animals.get("tag_no").enable();
      animals.get("animal_name").enable();
      //animals.get("dob").enable();
    } else {
      if (!this.isView) {
        animals.get("tag_no").disable();
        animals.get("animal_name").disable();
      }
      //animals.get("dob").disable();
    }

    if (this.QuoteDetails.livestock_meta.type_of_policy == 3) {
      animals.get("lot_no").disable();
      animals.get("cover_period").disable();
      if (!this.isBullSelected) {
        //animals.get("cover_required").disable();
      }
    } else {
      if (this.QuoteDetails.livestock_meta.type_of_policy == 4) {
        animals.get("lot_no").disable();
      } else {
        animals.get("lot_no").enable();
      }
      if (!this.isHeiferSelected) {
        animals.get("cover_required").enable();
        if (livestock_cover_id == '70146458-ac55-4d2d-aef8-8c75101f8ce2') {
          animals.get("cover_period").disable();
        } else {
          animals.get("cover_period").enable();
        }
      } else {
        //animals.get("cover_required").disable();
        //animals.get("cover_period").disable();
      }
    }
    if (this.isBullSelected && this.quoteModelDetails.livestock_meta.type_of_policy == 2) {
      animals.get("vet_cert_provided").disable();
    } else {
      if (this.isHeiferSelected) {
        if (this.quoteModelDetails.livestock_meta.type_of_policy == 4) {
          animals.get("vet_cert_provided").enable();
        } else {
          animals.get("vet_cert_provided").disable();
        }
      } else {
        animals.get("vet_cert_provided").enable();
      }
    }
    if (this.isBullSelected) {
      animals.get("sex").disable();
    } else {
      animals.get("sex").enable();
    }

    if (this.quoteModelDetails.livestock_meta.type_of_policy == 2 || this.quoteModelDetails.livestock_meta.type_of_policy == 4) {
      animals.get("livestock_purchasers").disable();
    } else {
      animals.get("livestock_purchasers").enable();
    }

    // Is trial period to be deferred
    if (this.quoteModelDetails.livestock_meta.type_of_policy == 3 && this.isBullSelected) {
      this.trialPeriod = true;
      animals.get("is_trial_period_deferred").enable();
      animals.get("trial_period_date").enable();
    } else {
      this.trialPeriod = false;
      animals.get("is_trial_period_deferred").disable();
      animals.get("trial_period_date").disable();
    }

    return animals;
  }

  enableDisableOtherBreed(i) {
    let animals = this.frmCtrl.animal_details as FormArray;
    if (animals.at(i)) {
      let breed_id = animals.at(i).get("bull_breed").value;
      if (breed_id == '77777777-ac55-4d2d-aef8-8c75101f8ce2' || breed_id == '88888888-ac55-4d2d-aef8-8c75101f8ce2' || breed_id == '99999999-ac55-4d2d-aef8-8c75101f8ce2') {
        animals.at(i).get("livestock_breed_other").enable();
      } else {
        animals.at(i).get("livestock_breed_other").disable();
      }
    }
  }

  bindPurchasers() {
    this.quoteModelDetails.livestock_details.forEach((res, i) => {
      (this.animalsArr.at(i).get("livestock_purchasers") as FormArray).clear();
      res.livestock_purchasers.forEach((res) => {
        let data = this.fb.group({
          first_name: [res.first_name],
          last_name: [res.last_name],
          email: [res.email],
          postcode: [res.postcode],
          address1: [res.address1],
          address2: [res.address2],
          city: [res.city],
          country: [res.country],
          ownership: [res.ownership],
        })
        let lp = this.animalsArr.at(i).get("livestock_purchasers") as FormArray
        lp.push(data);
      })

    });

  }


  /*
  * ControlValueAccessor methods
  * registerOnChange, writeValue, registerOnTouched
  * Used to pass data to parent form
  */
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }
  writeValue(value) {

  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  /*
  * Indicate parent form if animal form is valid or not.
  */
  validate(_: FormControl) {
    return this.animalSection.valid ? null : { animals: { valid: false } };
  }

  /*
  * Access form control values
  */
  get frmCtrl() {
    if (this.animalSection) {
      return this.animalSection.controls;
    }
  }

  get animalsArr() { return this.frmCtrl.animal_details as FormArray; }

  /*
  * Help Text PopUp Modal
  */
  msg = '';
  helpTextPopup(modal, content) {
    this.msg = content;
    this.modalService.open(modal, { centered: true });
  }

  /*
  * Get Total Number Of Bulls Required Insurance
  */
  totalAnimalsSelected: any = [];
  animalBreeds: any = [];
  onAnimalInsuranceChange(event) {
    this.QuoteDetails.livestock_meta.animal_count = event.value;
    this.totalAnimalsSelected = Array(event.value).fill(0, 0, event.value).map((x, i) => i + 1);

    // Get Animal Details
    this.livestockDetails();

    let initialPoint = 1;
    if (this.animal_details) {
      initialPoint = this.animal_details.length + 1;
    }


    if (this.animalsArr.length < event.value) {
      if (!this.QuoteDetails.id) {
        this.animalsArr.clear();

      }
      for (let i = initialPoint; i <= event.value; i++) {
        this.animalsArr.controls.forEach((res, index) => {
          if (index + 1 == i && !res.get('bull_breed').value && this.QuoteDetails.livestock_meta.type_of_policy >= 3) {
            this.animalsArr.clear();
          }
        })
        const animals = this.fb.group({
          id: [''],
          lot_no: ['', Validators.required],
          tag_no: ['', Validators.required],
          animal_name: ['', Validators.required],
          dob: ['', Validators.required],
          bull_breed: ['', Validators.required],
          livestock_breed_other: ['', Validators.required],
          sale_price: ['', Validators.required],
          cover_required: ['', Validators.required],
          sex: ['', Validators.required],
          cover_period: ['', Validators.required],
          semen_tested: [''],
          vet_cert_provided: ['', Validators.required],
          is_trial_period_deferred: ['', Validators.required],
          trial_period_date: ['', Validators.required],
          net_rate: [''],
          sum_insured: [''],
          livestock_purchasers: this.fb.array([this.PurchasersFB])
        });

        animals.get("livestock_breed_other").disable();
        this.enableDisableAnimalAllFields(animals, '');

        this.animalsArr.push(animals);
      }
    } else {
      for (let i = this.animalsArr.length; i >= event.value; i--) {
        this.animalsArr.removeAt(i);
      }
    }
    // if (this.isHeiferSelected && this.QuoteDetails.livestock_meta.type_of_policy == 4) {
    //   this.coverRequired = false;
    // }

  }

  // Get Livestock Other Details - Breed/Covers
  livestockBreeds = [];
  livestockCovers = [];
  test = [];
  livestockDetails() {
    let livestocks = this.liveStocks.filter(x => x.id == this.frmCtrl.animals_to_be_insured.value);
    if (livestocks.length > 0) {
      this.livestockBreeds = livestocks[0].breeds;
      if (this.QuoteDetails.livestock_meta.type_of_policy == 3 && this.isBullSelected) {
        this.livestockCovers = livestocks[0].covers.filter(x => x.id == '70146458-ac55-4d2d-aef8-8c75101f8ce2');
      }
      else if (this.QuoteDetails.livestock_meta.type_of_policy == 4 && this.isBullSelected) {
        this.livestockCovers = livestocks[0].covers.filter(x => x.id !== '70146458-ac55-4d2d-aef8-8c75101f8ce2');
      }
      else {
        livestocks[0].covers.forEach((res) => {
          if (this.QuoteDetails.livestock_meta.type_of_policy == 1 && this.isBullSelected && res.id == '70246458-ac55-4d2d-aef8-8c75101f8ce2') {
            res.cover = "Breeding Warranty plus Mortality & Loss of Use";
          }
          else if (this.isEweSelected && res.id == '70346458-ac55-4d2d-aef8-8c75101f8ce2') {
            res.cover = "Mortality";
          }
          else if (this.isHeiferSelected && res.id == '70446458-ac55-4d2d-aef8-8c75101f8ce2') {
            res.cover = "Mortality";
          }
          else if (this.QuoteDetails.livestock_meta.type_of_policy >= 2) {
            res.cover = "Mortality & Loss of Use";
          }
        })
        this.livestockCovers = livestocks[0].covers;
      }
    }
  }

  /*
  * Change Cover Required Dropdown - Get Cover Periods
  */
  displayCoverPeriod: boolean = true;
  onLivestockCoverChange(event, i) {
    if (event.value) {
      let animals = this.frmCtrl.animal_details as FormArray;
      this.getCoverPeriods(event.value, i);
      if (event.value == '70146458-ac55-4d2d-aef8-8c75101f8ce2' || event.value == '70246458-ac55-4d2d-aef8-8c75101f8ce2') {
        if (this.QuoteDetails.livestock_meta.type_of_policy == 4 || this.QuoteDetails.livestock_meta.type_of_policy == 2) {
          this.displayCoverPeriod = true;
          animals.at(i).get('cover_period').enable();
        } else {
          //this.displayCoverPeriod = false;
          if (event.value == '70246458-ac55-4d2d-aef8-8c75101f8ce2') {
            animals.at(i).get('cover_period').enable();
          }
          else {
            animals.at(i).get('cover_period').disable();
          }
        }
      } else {
        this.displayCoverPeriod = true;
        animals.at(i).get('cover_period').enable();
      }
      this.enableDisablePurchasers(event.value, i);

      if (this.QuoteDetails.livestock_meta.type_of_policy == 3 && this.isBullSelected) {
        // this.displayCoverPeriod = true;
        // animals.at(i).get('cover_period').enable();
      }
    }
    this.AllCalculations(i);
  }

  enableDisablePurchasers(cover_id, i) {
    let animals = this.frmCtrl.animal_details as FormArray;
    animals.at(i).get('livestock_purchasers').disable();
    if (this.QuoteDetails.livestock_meta.type_of_policy == 1) {
      if ((cover_id == '70246458-ac55-4d2d-aef8-8c75101f8ce2' || cover_id == '70346458-ac55-4d2d-aef8-8c75101f8ce2') && this.selectedTabIndex == 6) {
        animals.at(i).get('livestock_purchasers').enable();
      }
    }
    if (this.QuoteDetails.livestock_meta.type_of_policy == 3) {
      if (cover_id == '70246458-ac55-4d2d-aef8-8c75101f8ce2' && this.selectedTabIndex == 2) {
        animals.at(i).get('livestock_purchasers').enable();
      }
    }

    if (this.isEweSelected && this.selectedTabIndex == 6) {
      animals.at(i).get('livestock_purchasers').enable();
    }

  }

  getCoverPeriods(cover_id, i) {
    this.CoverPeriods[i] = [];
    this.CoverPeriods[i] = this.AllCoverPeriods;
    let animals = this.frmCtrl.animal_details as FormArray;
    let breed_id = '';
    let livestock_sex_id = '';

    if (animals.at(i)) {
      breed_id = animals.at(i).get("bull_breed").value;
      livestock_sex_id = animals.at(i).get("sex").value;
      if (!this.QuoteDetails.id) {
        animals.at(i).get("cover_period").setValue('');
      }

    }
    if (this.isBullSelected) {
      let livestocks = this.liveStocks.filter(x => x.id == this.frmCtrl.animals_to_be_insured.value);
      if (livestocks.length > 0) {
        let covers = livestocks[0].covers.filter(x => x.id == cover_id);
        if (covers.length > 0) {
          //this.CoverPeriods[i] = covers[0].cover_periods;
          if (this.quoteModelDetails.livestock_meta.type_of_policy == 2 || this.quoteModelDetails.livestock_meta.type_of_policy == 4) {
            this.CoverPeriods[i] = covers[0].cover_periods.filter(x => x.id == '95446458-ac55-4d2d-aef8-8c75101f8ce2');
          }
          else {
            this.CoverPeriods[i] = covers[0].cover_periods;
          }
        }
      }
    } else {
      // If Cows/Heifer and Mortality then display only '12 Months' option
      if (this.isHeiferSelected) {
        // If Cows/Heifer and Mortality then display only '12 Months' option
        //this.CoverPeriods[i] = this.CoverPeriods[i].filter(x => x.id == '96146458-ac55-4d2d-aef8-8c75101f8ce2');
        let livestocks = this.liveStocks.filter(x => x.id == this.frmCtrl.animals_to_be_insured.value);
        if (livestocks.length > 0) {
          let covers = livestocks[0].covers.filter(x => x.id == '70446458-ac55-4d2d-aef8-8c75101f8ce2');
          if (covers.length > 0) {
            //this.CoverPeriods[i] = covers[0].cover_periods;
            if (this.quoteModelDetails.livestock_meta.type_of_policy == 2 || this.quoteModelDetails.livestock_meta.type_of_policy == 4) {
              this.CoverPeriods[i] = covers[0].cover_periods.filter(x => x.id == '96146458-ac55-4d2d-aef8-8c75101f8ce2');
            }
            else {
              this.CoverPeriods[i] = covers[0].cover_periods;
            }
          }
        }
      } else if (this.isEweSelected && (breed_id == '35546458-ac55-4d2d-aef8-8c75101f8ce2' || breed_id == '35646458-ac55-4d2d-aef8-8c75101f8ce2') && (livestock_sex_id == '30246458-ac55-4d2d-aef8-8c75101f8ce2' || livestock_sex_id == '30346458-ac55-4d2d-aef8-8c75101f8ce2')) {
        // Ewe/Ram + Blue Face Leicester/Beltex Ewe in Lamb/Not in Lamb - display only '12 months' option
        this.CoverPeriods[i] = this.CoverPeriods[i].filter(x => x.id == '95746458-ac55-4d2d-aef8-8c75101f8ce2');
      }
      // else if (this.isEweSelected && cover_id == '70346458-ac55-4d2d-aef8-8c75101f8ce2' && this.quoteModelDetails.livestock_meta.type_of_policy == 2) {
      //   // If Raw/Ewe and Mortality then display only '12 Months' option
      //   this.CoverPeriods[i] = this.CoverPeriods[i].filter(x => x.id == '95746458-ac55-4d2d-aef8-8c75101f8ce2');
      // } 
      // else if (this.isHeiferSelected && cover_id == '70446458-ac55-4d2d-aef8-8c75101f8ce2' && this.quoteModelDetails.livestock_meta.type_of_policy == 4) {
      //   // If Private sale purchaser, Cows and Mortality then display only '12 Months' option
      //   this.CoverPeriods[i] = this.CoverPeriods[i].filter(x => x.id == '96146458-ac55-4d2d-aef8-8c75101f8ce2');
      // }
      // Ewe/Ram For Vendor Purchaser and Private Purchaser 12 Month period
      else if (this.isEweSelected && (this.quoteModelDetails.livestock_meta.type_of_policy == 4)) {
        this.CoverPeriods[i] = this.CoverPeriods[i].filter(x => x.id == '95746458-ac55-4d2d-aef8-8c75101f8ce2');
      }
      else {
        this.CoverPeriods[i] = this.AllCoverPeriods;
      }
    }
  }

  /*
  * Animals To Be Required Change
  * Toggle form fields based on selection(Bull/Ewe &  Ram/ Heifer & Cows)
  */
  isBullSelected: boolean = false;
  isEweSelected: boolean = false;
  isHeiferSelected: boolean = false;
  coverRequired: boolean = false;
  livestockGender = [];
  onAnimalsToBeRequiredChange(event) {
    this.animalSection.get("total_animals_require_insurance").setValue('');
    this.animalsArr.controls = [];
    (this.animalSection.controls['animal_details'] as FormArray).clear();
    this.calculations = new CalculationsDetails();
    this.calculations.clearStatus = true;
    this.calDetails.emit(this.calculations);
    this.toggleFieldsForAnimalTypes(event.value);
    this.livestockDetails();
    this.CoverPeriods = [];
    // Livestock Gender
    this.livestockGender = this.livestockSex.filter(x => x.livestock_type_id == event.value);

    // Hide Vet Cert Dropdown if Vendor Public Auction/Purchaser Public Auction + RAM
    if (this.QuoteDetails.livestock_meta.type_of_policy <= 2 && event.value == '10246458-ac55-4d2d-aef8-8c75101f8ce2') {
      this.hideVetCert = true;
    }


  }

  agesHelpText: string = '';
  vetCertHelpText: string = '';
  toggleFieldsForAnimalTypes(animalType) {
    this.isBullSelected = false;
    this.isEweSelected = false;
    this.isHeiferSelected = false;
    this.coverRequired = false;
    this.quoteModelDetails.livestock_meta.livestock_type_id = animalType;
    this.vetCertHelpText = '';
    switch (animalType) {
      case '10146458-ac55-4d2d-aef8-8c75101f8ce2':
        this.isBullSelected = true;
        this.agesHelpText = 'If fewer than 3 months old or more than 4 years old, please contact your Broker to discuss';
        this.vetCertHelpText = 'If the bull has not passed the pre-sale inspection, please contact your Broker to discuss If the Trial Period deferred button is selected, can this date be shown on the schedule. This date cannot be more than 3 months from the date of the sale.';
        break;
      case '10246458-ac55-4d2d-aef8-8c75101f8ce2':
        this.isEweSelected = true;
        this.quoteModelDetails.isButton = false;
        this.agesHelpText = 'Cover can be given for animals between the ages of 3 months and 4 years old';
        break;
      case '10346458-ac55-4d2d-aef8-8c75101f8ce2':
        this.isHeiferSelected = true;
        this.quoteModelDetails.isButton = false;
        this.agesHelpText = 'Cover can be given for animals between the ages of 3 months and 8 years old';
        break;
      default:
        this.isBullSelected = false;
        this.isEweSelected = false;
        this.isHeiferSelected = false;
        break;
    }
    this.totalAnimalsSelected = [];

    this.disableHiddenFields();



    // Show/Hide cover required field
    this.coverRequired = true;
    // if (this.quoteModelDetails.livestock_meta.type_of_policy == 1 && this.isHeiferSelected) {
    //   this.coverRequired = false;
    // } else if (this.quoteModelDetails.livestock_meta.type_of_policy == 2 && this.isHeiferSelected) {
    //   this.coverRequired = false;
    // } else if (this.quoteModelDetails.livestock_meta.type_of_policy == 3 && (this.isHeiferSelected || this.isEweSelected)) {
    //   this.coverRequired = false;
    // } else if (this.quoteModelDetails.livestock_meta.type_of_policy == 4 && this.isHeiferSelected) {
    //   this.coverRequired = false;
    // } else {
    //   this.coverRequired = true;
    // }
  }

  /*
  * Display popup if purchase price is > 10K
  */
  hideVetCertOptionNo: boolean = false;
  purchasePriceChange(modal, event, i) {
    if (Number(event.target.value) > 10000 && this.isBullSelected && this.quoteModelDetails.livestock_meta.type_of_policy >= 3) {
      this.msg = "Proof of copy of sale (value) required for animals over £10k";
      this.modalService.open(modal, { centered: true });
    }
    this.vetCertPopup(modal, i);

    // Set Vet Cert 1 if Vendor Public Auction/Purchaser Public Auction + RAM
    if (this.QuoteDetails.livestock_meta.type_of_policy <= 2 && this.animalSection.value.animals_to_be_insured == '10246458-ac55-4d2d-aef8-8c75101f8ce2') {
      let animals = this.frmCtrl.animal_details as FormArray;
      animals.at(i).get("vet_cert_provided").setValue(1);
      this.hideVetCertOptionNo = true;
    }
    this.AllCalculations(i);
  }

  /*
  * Calculate data on breed change
  */
  isSemenTested: boolean = false;
  onBreedChange(event, i) {
    let animal_details = this.animalSection.get('animal_details') as FormArray;
    if (event.value == '30746458-ac55-4d2d-aef8-8c75101f8ce2') {
      animal_details.controls[i].patchValue({ "semen_tested": 1 });
      this.isSemenTested = true;
    } else {
      // animal_details.controls[i].patchValue({ "semen_tested": null });
      this.isSemenTested = false;
    }
    if (event.value == '30746458-ac55-4d2d-aef8-8c75101f8ce2' && (this.quoteModelDetails.livestock_meta.type_of_policy == 2 || this.quoteModelDetails.livestock_meta.type_of_policy == 4)) {
      animal_details.controls[i].patchValue({ "semen_tested": 0 });
      this.isSemenTested = false;
    }
    this.getCoverPeriods(animal_details.at(i).get('cover_required').value, i);
    this.vetCertActions(i);
    this.enableDisableOtherBreed(i);
    this.AllCalculations(i);
  }


  /*
  * Calculate data on Gender change
  */
  onGenderChange(event, i) {
    this.getCoverPeriods(event.value, i);
    this.vetCertActions(i);
    this.AllCalculations(i);
  }

  /*
  * Ewe/Ram -> Ram + Blue Face Leicester/Beltex -> Mark vet vert yes and disable field
  * 30146458-ac55-4d2d-aef8-8c75101f8ce2 -> Ram
  * 35546458-ac55-4d2d-aef8-8c75101f8ce2 -> Blue Face Leicester
  * 35646458-ac55-4d2d-aef8-8c75101f8ce2 -> Beltex
  * 
  * 30246458-ac55-4d2d-aef8-8c75101f8ce2 -> EWE not in lamb
  * 30346458-ac55-4d2d-aef8-8c75101f8ce2 -> Ewe in lamb
  * 
  * 35846458-ac55-4d2d-aef8-8c75101f8ce2 -> Sweldale
  */
  hideVetCert: boolean = false;
  vetCertActions(i) {
    this.animalSection.value.animal_details.forEach((res) => {
      let animals = this.frmCtrl.animal_details as FormArray;
      if ((this.isBullSelected || this.isHeiferSelected) && this.quoteModelDetails.livestock_meta.type_of_policy == 2) {
        animals.at(i).get("vet_cert_provided").disable();
      }
      if (res.bull_breed == "35546458-ac55-4d2d-aef8-8c75101f8ce2" || res.bull_breed == "35646458-ac55-4d2d-aef8-8c75101f8ce2") {
        if (res.sex == "30146458-ac55-4d2d-aef8-8c75101f8ce2") {
          animals.at(i).get('vet_cert_provided').disable();
          animals.at(i).get('vet_cert_provided').setValue(1);
          this.hideVetCert = false;
        } else if (res.sex == "30246458-ac55-4d2d-aef8-8c75101f8ce2" || res.sex == "30346458-ac55-4d2d-aef8-8c75101f8ce2") {
          animals.at(i).get('vet_cert_provided').setValue(1);
          animals.at(i).get('vet_cert_provided').disable();
        }
      } else {
        if (this.isEweSelected) {
          animals.at(i).get('vet_cert_provided').enable();
          if (res.bull_breed == "35846458-ac55-4d2d-aef8-8c75101f8ce2") {
            this.hideVetCert = true;
            animals.at(i).get('vet_cert_provided').disable();
            animals.at(i).get('vet_cert_provided').setValue(1);
          } else {
            this.hideVetCert = false;
            animals.at(i).get('vet_cert_provided').setValue(res.vet_cert_provided);
          }
        }
      }
    });

  }

  /*
  * Calculate data on Cover Period change
  */

  onCoverPeriodChange(event, i) {
    this.AllCalculations(i);
  }
  /*
  * Calculate data on Semen Tested change
  */
  onSemenTestedChange(i) {
    this.AllCalculations(i);
  }
  /*
  * Calculate data on Vet Inspection change
  */
  onVetCertChange(i, event, helpTextPopupModal) {
    this.vetCertPopup(helpTextPopupModal, i);
    this.AllCalculations(i);
  }

  vetCertPopup(helpTextPopupModal, i) {
    let animals = this.frmCtrl.animal_details as FormArray;
    let sale_price = animals.at(i).get('sale_price').value;
    let vet_cert_provided = animals.at(i).get('vet_cert_provided').value;
    if (vet_cert_provided == 2 && sale_price) {
      let amount = '';
      if ((this.isBullSelected || this.isHeiferSelected) && this.quoteModelDetails.livestock_meta.type_of_policy == 4) {
        amount = '£2,000';
      } else if (this.quoteModelDetails.livestock_meta.type_of_policy == 2 && this.isEweSelected && sale_price > 1000) {
        amount = '£1,000';
      } else if (this.quoteModelDetails.livestock_meta.type_of_policy == 4 && this.isEweSelected && sale_price > 1000) {
        amount = '£1,000';
      }
      if (amount) {
        this.modalService.open(helpTextPopupModal, { centered: true });
        this.msg = "Sum insured up to " + amount + " without vetcert.  If the sum insured is more than " + amount + " need to ‘speak to your broker’.";
      }

    }
  }

  // Calculate Data
  AllCalculations(i) {
    if (this.animalSection) {
      let animal_details = this.animalSection.get('animal_details') as FormArray;
      this.animalSection.value.animal_details.forEach((res, index) => {

        let net_rate = 0;
        let is_british_blue = 0;
        let ratings_data = this.ratings.filter(x => x.livestock_type_id == this.animalSection.value.animals_to_be_insured);
        if (res.cover_required && !this.isEweSelected) {
          // if (res.cover_required != '70146458-ac55-4d2d-aef8-8c75101f8ce2') {
          //   res.semen_tested = 0;
          // }
          ratings_data = ratings_data.filter(x => x.livestock_cover_id == res.cover_required);
        }

        if (res.cover_period) {
          ratings_data = ratings_data.filter(x => x.livestock_cover_period_id == res.cover_period);
        }
        if (res.bull_breed) {
          if (res.bull_breed == '30746458-ac55-4d2d-aef8-8c75101f8ce2' || res.bull_breed == '40646458-ac55-4d2d-aef8-8c75101f8ce2') {
            is_british_blue = 1;
            ratings_data = ratings_data.filter(x => x.is_british_blue == 1);
          } else {
            is_british_blue = 0;
            if (this.isEweSelected && (res.bull_breed == '35546458-ac55-4d2d-aef8-8c75101f8ce2' || res.bull_breed == '35646458-ac55-4d2d-aef8-8c75101f8ce2' || res.bull_breed == '35846458-ac55-4d2d-aef8-8c75101f8ce2') && (res.sex == '30146458-ac55-4d2d-aef8-8c75101f8ce2')) {
              ratings_data = ratings_data.filter(x => x.livestock_breed_id == res.bull_breed);
            }
            else if (this.isEweSelected && (res.bull_breed == '35546458-ac55-4d2d-aef8-8c75101f8ce2' || res.bull_breed == '35646458-ac55-4d2d-aef8-8c75101f8ce2') && (res.sex == '30246458-ac55-4d2d-aef8-8c75101f8ce2' || res.sex == '30346458-ac55-4d2d-aef8-8c75101f8ce2')) {
              ratings_data = ratings_data.filter(x => x.livestock_breed_id == res.bull_breed);
            } else if (res.bull_breed != '35546458-ac55-4d2d-aef8-8c75101f8ce2' || res.bull_breed != '35846458-ac55-4d2d-aef8-8c75101f8ce2') {
              ratings_data = ratings_data.filter(x => x.livestock_breed_id == null);
            }
          }


        }
        if (res.semen_tested) {
          let semen_tested = (res.semen_tested == 2) ? 0 : res.semen_tested;
          ratings_data = ratings_data.filter(x => x.is_semen_tested == semen_tested);
        }
        if (res.vet_cert_provided && this.isEweSelected) {
          let vet_cert_provided = 1;
          // if (res.vet_cert_provided == 2) {
          //   vet_cert_provided = 0;
          // }
          ratings_data = ratings_data.filter(x => x.vet_inspection == vet_cert_provided);
        }

        if (res.sex) {
          ratings_data = ratings_data.filter(x => x.livestock_sex_id == res.sex);
        }

        let sum_insured = Number(res.sale_price);
        let policy_type = 0;
        if (this.isBullSelected) {
          if (this.quoteModelDetails.livestock_meta.type_of_policy == 1 || this.quoteModelDetails.livestock_meta.type_of_policy == 3) {
            policy_type = 1;
          } else {
            policy_type = 2;
          }
        } else {
          policy_type = 1;
        }
        ratings_data = ratings_data.filter(x => x.lower_sum_insured <= sum_insured && x.upper_sum_insured >= sum_insured && x.is_british_blue == is_british_blue && x.policy_type == policy_type);



        if (ratings_data.length > 0) {
          net_rate = Number(ratings_data[0].rate);
        }
        res.net_rate = net_rate;
        res.sum_insured = sum_insured;
        if (index == i) {
          animal_details.controls[i].patchValue({ "net_rate": net_rate });
          animal_details.controls[i].patchValue({ "sum_insured": sum_insured });
        }
      });

      if (this.selectedTabIndex > 0) {
        if (this.QuoteDetails.calculations && this.QuoteDetails.calculations.ipt_fee == 0) {
          this.QuoteDetails.calculations.ipt_fee = this.ipt_fee;
        }
        this.calDetails.emit(this.QuoteDetails.calculations);
      }
    }
  }

  /*
* Claim
*/
  onChangeClaim(event) {
    this.QuoteDetails.calculations.claim_loading_percentage = 0;
    this.bindClaims(event.value);
    this.calDetails.emit(this.QuoteDetails.calculations);
  }

  bindClaims(number_of_claims) {
    let claim = this.allClaims.filter(x => x.number_of_claims == number_of_claims);
    if (claim.length > 0) {
      this.QuoteDetails.calculations.claim_loading_percentage = Number(claim[0].claim_loading_percentage);
    } else {
      this.QuoteDetails.calculations.claim_loading_percentage = 0;
    }
  }
  /*
* Response Change:
* Disbale response text field if 'No' selected
*/
  isBtnFullDetail = false;
  hideBreedingWarranty = false;
  onResponseChange(event) {
    if (event.value == 1) {
      this.quoteModelDetails.isButton = false;
      this.hideBreedingWarranty = false;
      this.animalSection.get("breeding_warranty_cover").enable();
    } else {
      this.hideBreedingWarranty = true;
      this.animalSection.get("breeding_warranty_cover").disable();
      this.quoteModelDetails.isButton = true;
    }
  }

  /*
  * Disable hidden fields
  */
  disableHiddenFields() {
    if ((this.quoteModelDetails.livestock_meta.type_of_policy == 1 || this.quoteModelDetails.livestock_meta.type_of_policy == 3) && this.isBullSelected && !this.isView) {
      this.animalSection.get("breeding_warranty_cover").enable();
      this.animalSection.get("breeding_society_name").enable();
    } else {
      // this.animalSection.get("breeding_warranty_cover").disable();
      // this.animalSection.get("breeding_society_name").disable();
    }
  }



  get PurchasersFB(): FormGroup {
    let animals = this.frmCtrl.animal_details as FormArray;
    if (!animals.get("livestock_purchasers")) {
      return this.fb.group({
        first_name: ['', [Validators.required]],
        last_name: [''],
        email: ['', [Validators.required]],
        postcode: ['', [Validators.required]],
        address1: ['', [Validators.required]],
        address2: [''],
        city: ['', [Validators.required]],
        country: ['', [Validators.required]],
        ownership: ['', [Validators.required]],
      });
    }

  }

  /*
* Add Purchasers
*/
  addPurchasers(animals) {
    animals.get("livestock_purchasers").push(this.PurchasersFB);
  }

  /*
  * Remove Purchasers
  */
  removePurchasers(animals, ip) {
    animals.get("livestock_purchasers").removeAt(ip);
  }

  ownershipValue(event, i, animals) {
    if (event.target.value > 100) {
      //animals.get("livestock_purchasers").at(i).get('ownership').setValue(null);
    }
  }

  ownershipValueBlurEvent(event, i, animals) {
    let totalOwnershipValue = 0;
    animals.get("livestock_purchasers").controls.forEach((res) => {
      totalOwnershipValue += Number(res.get('ownership').value);
    })
    if (totalOwnershipValue > 100) {
      animals.get("livestock_purchasers").at(i).get('ownership').setValue(null);
    }
  }

  /*
 * POSTCODE LOOKUP
 */
  addresses = [];
  postcodeLookup(event) {
    let postcode = event.target.value;
    // API call - postcode lookup
    this.quoteService.getAddressPostcodeLookup(postcode)
      .subscribe(
        (response: any) => {
          this.addresses = response.addresses;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSelectAddress(address, type = -1, prhs) {
    prhs.get("address1").setValue(address.line_1);
    prhs.get("address2").setValue(address.line_2);
    prhs.get("city").setValue(address.town_or_city);
    prhs.get("country").setValue(address.county);
  }

  /*
  * Enable/Disable validations on trial period change
  */
  onTrialPeriodChange(event, i) {
    let animals = this.frmCtrl.animal_details as FormArray;
    if (event.value == 1) {
      animals.at(i).get('trial_period_date').enable();
    } else {
      animals.at(i).get('trial_period_date').disable();
    }
  }
}
