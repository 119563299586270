import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'ngx-webstorage';
import { DashboardService } from './dashboard.service';
import { formatDate } from '@angular/common';
import { UserInfoService } from '../services/user-info.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../quote/quote.component.css', './dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  dashboardForm: FormGroup;
  submitted = false;
  frequency: number = 1;
  miData;
  isBroker: boolean = false;
  isInsurer: boolean = false;
  displayEquineMIICL: boolean = false;
  mi_start_date = '';
  mi_end_date = '';
  userinfo;
  constructor(private formBuilder: FormBuilder, private sessionSt: LocalStorageService,
    private dashboardService: DashboardService, private userService: UserInfoService) { }

  ngOnInit(): void {
    this.userService.getUserDetails();
    this.getMIData();
    this.userinfo = this.sessionSt.retrieve('userInfo');
    if (this.sessionSt.retrieve('is_broker')) {
      this.displayEquineMIICL = true;
      this.isBroker = true;
    }
    if (this.sessionSt.retrieve('is_insurer')) {
      this.displayEquineMIICL = true;
      this.isInsurer = true;
    }
  }

  /*
  * Frequency Change
  */
  onFrequencyChange(event) {
    this.frequency = event.value;
    this.getMIData();
  }

  filterMIByDate() {
    this.getMIData();
  }

  /*
     * GET MI DATA
     */
  getMIData() {
    if (this.mi_start_date) {
      this.mi_start_date = formatDate(this.mi_start_date, 'yyyy-MM-dd', 'en-US');
    }
    if (this.mi_end_date) {
      this.mi_end_date = formatDate(this.mi_end_date, 'yyyy-MM-dd', 'en-US');
    }
    let data = {
      broker_company_id: this.sessionSt.retrieve('broker_company_id'),
      filter_type: this.frequency,
      date_from: this.mi_start_date,
      date_to: this.mi_end_date
    };
    this.dashboardService.getMIData(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.miData = response.result;
          }
        });
  }
}
