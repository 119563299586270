<form [formGroup]="quoteDetailsForm" (ngSubmit)="onSubmit()">
    <app-livestock-common-sections formControlName="commonSections" [brokers]="brokers" [submitted]="submitted"
        [selectedTabIndex]="selectedTabIndex" [quoteModelDetails]="quoteModelDetails" (calDetails)="calDetails($event)"
        (changedSelectedTabIndex)="changedSelectedTabIndex($event)">
    </app-livestock-common-sections>
    <app-proposer formControlName="proposer" [submitted]="submitted" [selectedTabIndex]='selectedTabIndex'
                [quoteModelDetails]="quoteModelDetails"></app-proposer>
               
    <app-proposer-other-details formControlName="pprOtherDetails" [submitted]="submitted"
    [selectedTabIndex]="selectedTabIndex" [quoteModelDetails]="quoteModelDetails"></app-proposer-other-details>
    <app-sale-and-claims formControlName="saleAndClaim" [staticData]="staticData" [submitted]="submitted"
    [selectedTabIndex]="selectedTabIndex" [quoteModelDetails]="quoteModelDetails"></app-sale-and-claims>

    <a class="btn btn-default btn-gray mr-3" (click)="backBtn()">Back</a>
    <button class="btn btn-default btn-success">Proceed</button>
</form>
<!-- <p>
    Form is {{quoteDetailsForm.valid ? 'Valid' : 'Invalid'}}
</p> 
<pre>
            {{quoteDetailsForm.value | json}}
            </pre> -->