import { Component, OnInit, Input, forwardRef, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder, Validators, FormGroup, FormArray,
  ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS
} from '@angular/forms';
import { QuoteService } from '../../quote.service';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-proposer-other-details',
  templateUrl: './proposer-other-details.component.html',
  styleUrls: ['../../quote.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProposerOtherDetailsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProposerOtherDetailsComponent),
      multi: true
    }
  ]
})
export class ProposerOtherDetailsComponent implements ControlValueAccessor, OnInit {
  qdfrm: FormGroup;
  proposer_type_details: FormArray;
  @Input() submitted: boolean;
  @Input() selectedTabIndex;
  @Input() quoteModelDetails = new QuoteVersions();
  QuoteDetails = new QuoteVersions();
  subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder, private quoteService: QuoteService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.qdfrm = this.fb.group({
      proposer_type: ['', Validators.required],
      proposer_type_details: this.fb.array([this.bindProposerTypeDetails()]),
      commercial_details: this.fb.group({
        is_micro_enterprise: [''],
        company_name: ['', Validators.required],
        email: ['', Validators.required],
        year_of_establishment: ['', Validators.required],
        contact_no_1: ['', Validators.required],
        contact_no_2: [''],
        postcode: ['', Validators.required],
        address_line_1: ['', Validators.required],
        address_line_2: [''],
        city: ['', Validators.required],
        county: ['', Validators.required],
      }),
    });
    //this.bindProposerTypeDetails();

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.qdfrm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }
  bindProposerTypeDetails(): FormGroup {
    return this.fb.group({
      proposer_first_name: [null, [Validators.required]],
      proposer_last_name: [null, [Validators.required]],
      proposer_email: [null, [Validators.required]],
      proposer_postcode: [null, [Validators.required]],
      proposer_address_line_1: [null, [Validators.required]],
      proposer_address_line_2: [null],
      proposer_city: [null, [Validators.required]],
      proposer_county: [null, [Validators.required]],
    });
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.QuoteDetails = this.quoteModelDetails;
    if (this.qdfrm && this.quoteModelDetails.livestock_meta) {
      this.quoteModelDetails.livestock_meta.proposer_type = (this.quoteModelDetails.livestock_meta.proposer_type == 0) ? null : this.quoteModelDetails.livestock_meta.proposer_type;
      this.qdfrm.patchValue({
        proposer_type: this.quoteModelDetails.livestock_meta.proposer_type
      });
      if (this.quoteModelDetails.livestock_meta.proposer_type == 3) {
        this.qdfrm.get('proposer_type_details').disable();
        this.qdfrm.get('commercial_details').enable();
        this.qdfrm.get('commercial_details.is_micro_enterprise').setValue(this.quoteModelDetails.policy_holders[0].is_micro_enterprise);
        this.qdfrm.get('commercial_details.company_name').setValue(this.quoteModelDetails.policy_holders[0].company_name);
        this.qdfrm.get('commercial_details.email').setValue(this.quoteModelDetails.policy_holders[0].email);
        this.qdfrm.get('commercial_details.year_of_establishment').setValue(this.quoteModelDetails.policy_holders[0].year_of_establishment);
        this.qdfrm.get('commercial_details.contact_no_1').setValue(this.quoteModelDetails.policy_holders[0].phone);
        this.qdfrm.get('commercial_details.contact_no_2').setValue(this.quoteModelDetails.policy_holders[0].mobile);
        this.qdfrm.get('commercial_details.postcode').setValue((this.quoteModelDetails.policy_holders[0].address) ? this.quoteModelDetails.policy_holders[0].address.postcode : '');
        this.qdfrm.get('commercial_details.address_line_1').setValue((this.quoteModelDetails.policy_holders[0].address) ? this.quoteModelDetails.policy_holders[0].address.address1 : '');
        this.qdfrm.get('commercial_details.address_line_2').setValue((this.quoteModelDetails.policy_holders[0].address) ? this.quoteModelDetails.policy_holders[0].address.address2 : '');
        this.qdfrm.get('commercial_details.city').setValue((this.quoteModelDetails.policy_holders[0].address) ? this.quoteModelDetails.policy_holders[0].address.city : '');
        this.qdfrm.get('commercial_details.county').setValue((this.quoteModelDetails.policy_holders[0].address) ? this.quoteModelDetails.policy_holders[0].address.country : '');

      } else {
        this.qdfrm.get('proposer_type_details').enable();
        this.qdfrm.get('commercial_details').disable();
        (this.qdfrm.get('proposer_type_details') as FormArray).clear();
        this.quoteModelDetails.policy_holders.forEach((res) => {
          let details = this.fb.group({
            proposer_first_name: res.first_name,
            proposer_last_name: res.last_name,
            proposer_email: res.email,
            proposer_postcode: (res.address) ? res.address.postcode : '',
            proposer_address_line_1: (res.address) ? res.address.address1 : '',
            proposer_address_line_2: (res.address) ? res.address.address2 : '',
            proposer_city: (res.address) ? res.address.city : '',
            proposer_county: (res.address) ? res.address.country : ''
          });
          this.proposer_type_details = this.qdfrm.get('proposer_type_details') as FormArray;
          this.proposer_type_details.push(details);
        })
      }
    }
  }

  /*
 * Access form control values
 */
  get frmCtrl() {
    return this.qdfrm.controls;
  }

  get podArr() { return this.frmCtrl.proposer_type_details as FormArray; }

  /*
  * ControlValueAccessor methods
  * registerOnChange, writeValue, registerOnTouched
  * Used to pass data to parent form
  */
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }
  writeValue(value) {

  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  /*
  * Indicate parent form if animal form is valid or not.
  */
  validate(_: FormControl) {
    return this.qdfrm.valid ? null : { pprOtherDetails: { valid: false } };
  }

  /*
 * POSTCODE LOOKUP
 */
  addresses = [];
  postcodeLookup(event) {
    let postcode = event.target.value;
    // API call - postcode lookup
    this.quoteService.getAddressPostcodeLookup(postcode)
      .subscribe(
        (response: any) => {
          this.addresses = response.addresses;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSelectAddress(address, type = -1) {
    if (type == -1) {
      this.qdfrm.get('commercial_details.address_line_1').setValue(address.line_1);
      this.qdfrm.get('commercial_details.address_line_2').setValue(address.line_2);
      this.qdfrm.get('commercial_details.city').setValue(address.town_or_city);
      this.qdfrm.get('commercial_details.county').setValue(address.county);
    } else {
      this.podArr.controls.forEach((res, index) => {
        if (type == index) {
          res.get("proposer_address_line_1").setValue(address.line_1);
          res.get("proposer_address_line_2").setValue(address.line_2);
          res.get("proposer_city").setValue(address.town_or_city);
          res.get("proposer_county").setValue(address.county);
        }
      })
    }
  }

  /*
  * Add Proposer Sections
  */
  addProposer() {
    // this.proposer_type_details = this.qdfrm.get('proposer_type_details') as FormArray;
    // this.proposer_type_details.push(this.bindProposerTypeDetails());
    this.podArr.push(this.bindProposerTypeDetails());
  }
  /*
  * Remove Proposers
  */
  removeProposers(i) {
    const formArray = this.qdfrm.get('proposer_type_details') as FormArray;
    formArray.removeAt(i);
  }

  onPprTypeChange(event) {
    if (event.value == 3) {
      this.qdfrm.get("commercial_details").enable();
      this.qdfrm.get("proposer_type_details").disable();
    } else {
      if (event.value == 1) {
        (this.qdfrm.controls['proposer_type_details'] as FormArray).clear();
        this.podArr.push(this.bindProposerTypeDetails());
      }
      let first_name = this.quoteModelDetails.livestock_meta.about_proposer_first_name;
      let last_name = this.quoteModelDetails.livestock_meta.about_proposer_last_name;
      let email = this.quoteModelDetails.livestock_meta.about_proposer_email;
      this.podArr.controls.forEach((res, index) => {
          res.get("proposer_first_name").setValue(first_name);
          res.get("proposer_last_name").setValue(last_name);
          res.get("proposer_email").setValue(email);
      })

      this.qdfrm.get("commercial_details").disable();
      this.qdfrm.get("proposer_type_details").enable();
    }
  }
}
