<div class="card-header bg-success">
    <h3>Manage Users
        <small class="float-right"><a routerLink="/company" class=" text-white ml-5" *ngIf="UserType != 1 && UserType != 2 && UserType != 3"><i class="mdi mdi-arrow-left"></i>
            Back to company list</a>
        <a [routerLink]="['/user',company_id]" class="btn btn-default btn-sm ml-5"><i class="mdi mdi-plus"></i>
            User</a></small>
    </h3>
</div>
<div class="card-body p-1">
    <ag-grid-angular
    style="width: 100%; height: 380px;"
    class="ag-theme-bootstrap"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    animateRows
    pagination
    paginationPageSize = 10
    rowHeight = 27
    headerHeight=35
    (rowClicked)='onGridRowClicked($event)' 
    >
</ag-grid-angular>
</div>