import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteService } from '../../quote/quote.service';
import { GridOptions } from "ag-grid-community";
import { QuoteNotes } from '../../model/quote/quote_notes.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-underwriting',
  templateUrl: './underwriting.component.html',
  styleUrls: ['./underwriting.component.css']
})
export class UnderwritingComponent implements OnInit {
  @Input() quote: QuoteVersions;
  notes = new QuoteNotes();


  constructor(private quoteService: QuoteService, private modalService: NgbModal, private sharedService: SharedService,
    private sessionSt: LocalStorageService, private _snackBar: MatSnackBar, private router: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges) {
    // API - GET NOTES    
    this.getNotes();

    // GET DOCS
    this.getDocuments();
  }

  url_path: string = '';
  ngOnInit(): void {
    let CURRENT_URL = this.router.snapshot.url;
    if(CURRENT_URL.length > 0) {
      this.url_path = CURRENT_URL[0].path;
    }
  }


  getNotes() {
    if (this.quote.id) {
      this.quoteService.getNotes(this.quote.id, 1)
        .subscribe(
          (response: any) => {
            if (this.sessionSt.retrieve('is_admin') && response.result.length > 0) {
              response.result.forEach((res) => {
                res.isAdmin = true;
              })
            }
            this.rowData = response.result;
          });
    }
  }

  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };

  columnDefs = [
    {
      headerName: 'Notes', field: 'notes', sortable: true, width: 500,
      cellRenderer: function (params) {
        return ((params.data.is_important == 1) ? '<label class="badge badge-danger">Important</label> ' : '') + '<a data-action-type="view">' + params.data.notes + '</a>';
      },
      tooltip: function (params) {
        return params.data.notes;
      },
    },
    {
      headerName: 'Created By', field: 'first_name', sortable: true, width: 120,
      cellRenderer: function (params) {
        if (params.data.user) {
          return params.data.user.first_name + ' ' + params.data.user.last_name;
        }
      }
    },
    {
      headerName: 'Created At', field: 'created_at', sortable: true, width: 200,
      comparator: this.sharedService.agGridDteSortingComparator,
      cellRenderer: function (params) {
        return formatDate(params.data.created_at, 'dd-MM-yyyy hh:mm a', 'en-US');
      }
    },
    {
      headerName: null,
      width: 120,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.isAdmin) {
          links += '<div class="text-right">';
          links += '<a title="Edit Notes" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
          links += '<a class="ml-2" title="Delete Notes" style="font-size: 18px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete"></i></a>';
          links += '</div>';
        }
        return links;
      }
    }
  ];
  rowData: any;

  selectedRow;
  notesText;
  onGridRowClicked(e: any, content, deleteModel, notesViewModel) {
    if (e) {

      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {

            this.modalService.open(content, { centered: true });
            this.notes = e.data;
            break;
          }
        case "view":
          {
            this.modalService.open(notesViewModel, { centered: true });
            this.notesText = e.data.notes;
            break;
          }
        case "delete": {
          this.modalService.open(deleteModel, { centered: true });
          this.selectedRow = this.gridOptions.api.getFocusedCell();
          this.notes = e.data;
        }
      }
    }
  }

  deleteNotes(id, selectedRow) {
    this.quoteService.deleteNotes(id)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
          this.gridOptions.api.setRowData(this.gridOptions.rowData);
        });
  }

  /*
  * ADD NOTES
  */
  isAddingNotes: boolean = false;
  openNotesPopup(content) {
    this.isAddingNotes = true;
    this.modalService.open(content, { centered: true });
    this.notes = new QuoteNotes();
  }

  onCloseNotesModal() {
    this.modalService.dismissAll();
    this.isAddingNotes = false;
  }

  saveNotes() {
    let data = {
      id: this.notes.id,
      quote_id: this.quote.id,
      notes: this.notes.notes,
      is_important: this.notes.is_important,
      type: 5,
    };
    this.quoteService.saveNotes(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.isAddingNotes = false;
            //this.quote.notes = response.result.notes_list;
            this.getNotes();
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
          this.isImportantNote();
          this.modalService.dismissAll();
        });
  }

  isImportantNote() {
    let is_important = false;
    this.rowData.forEach((res) => {
      if (res.is_important == 1) {
        is_important = true;
      }
    })

  }

  /*
  * DOCUMENTS
  */
  rowDataDocs: any;
  gridOptionsDocs: GridOptions = {
    onGridReady: () => {
      this.gridOptionsDocs.api.sizeColumnsToFit();
    }
  };
  getDocuments() {
    if (this.quote.id) {
      // let type = (this.quote.is_policy == 1) ? 2 : 1;
      let type = (this.url_path == 'policy') ? 2 : 1;
      this.quoteService.getQuoteDocuments(this.quote.id, type, 1)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.rowDataDocs = response.result;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
  columnDefsDocs = [
    { headerName: 'Document Name', field: 'title', sortable: true, width: 500, filter: false },
    { headerName: 'Document Version', field: 'quote_version', sortable: true, filter: false },
    {
      headerName: 'Creation Date', field: 'created_date', sortable: true, filter: false,
      comparator: this.sharedService.agGridDteSortingComparator,
      cellRenderer: function (params) {
        return formatDate(params.data.created_date, 'dd-MM-yyyy HH:mm a', 'en-US');
      }
    },
    {
      headerName: null,
      width: 130, filter: false, type: 'rightAligned',
      cellRenderer: function (params) {
        let links = '';
        links += '<a  title="Edit Documents" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit"></i></a>';
        links += '<a class="ml-2" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"  data-action-type="download"></i></a>';
        links += '<a class="ml-2" title="Delete Documents" style="font-size: 18px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete"></i></a>';

        return links;
      }
    }
  ];


  /* 
   * Upload Documents
   */
  docTitle: string = '';
  fileToUpload: File = null;
  isAddingDocs: boolean = false;
  openDocumentsPopup(content) {
    this.documentID = '';
    this.docTitle = '';
    this.fileToUpload = null;
    this.quote.quote_document = '';
    this.documentsEditMode = false;
    this.isAddingDocs = true;
    this.modalService.open(content, { centered: true });
  }

  onCloseDocsModal() {
    this.modalService.dismissAll();
    this.isAddingDocs = false;
  }

  // UPLOAd DOCUMENT
  getUploadedFile(files) {
    this.fileToUpload = files.item(0);
    this.quote.quote_document = this.fileToUpload.name;
  }


  documentUpload() {
    let formData = new FormData();
    formData.append("file_name", this.fileToUpload);
    formData.append("quote_id", this.quote.id);
    formData.append("broker_id", this.quote.broker_id);
    formData.append("title", this.docTitle);
    formData.append("is_underwritten", "1");

    this.quoteService.saveQuoteDocument(formData, this.quote.is_policy)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.getDocuments();
            this.modalService.dismissAll();
            this._snackBar.open('Document uploaded.', '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to upload document.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
   * UPDATE DOCUMENT
   */
  documentsEditMode: boolean = false;
  documentID: string = '';
  updateDocument() {
    let data: any = '';
    data = {
      document_id: this.documentID,
      title: this.docTitle
    };
    this.quoteService.updateDocument(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.rowDataDocs.forEach((res) => {
              if (this.documentID == res.id) {
                res.title = this.docTitle
              }
            });
            this.gridOptionsDocs.api.setRowData(this.rowDataDocs);
            this.gridOptionsDocs.api.refreshCells();
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to update title. Please try again later.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
    * Delete Document
    */
  deleteDocument() {
    let data: any = '';
    data = {
      document_id: this.documentID
    };
    this.quoteService.deleteDocument(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            let rowsDoc = [];
            this.rowDataDocs.forEach((res) => {
              if (this.documentID != res.id) {
                rowsDoc.push(res);
              }
            });
            const selectedRow = this.gridOptionsDocs.api.getFocusedCell()
            const id = this.gridOptionsDocs.rowData[selectedRow.rowIndex].i

            this.gridOptionsDocs.rowData.splice(selectedRow.rowIndex, 1)
            this.gridOptionsDocs.api.setRowData(this.gridOptionsDocs.rowData)

            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
   * GRID BUTTONS ACTION
   */
  onDocsGridRowClicked(e: any, content, documentsDelete) {
    if (e) {
      let download_path = e.event.target.getAttribute("download_path");
      let actionType = e.event.target.getAttribute("data-action-type");
      this.documentID = e.data.id;
      this.docTitle = e.data.title;
      switch (actionType) {
        case "download":
          {
            this.quoteService.downloadDocs(e.data);
            break;
          }
        case "edit": {
          this.documentsEditMode = true;
          this.modalService.open(content, { centered: true });
          break
        }
        case "delete": {
          this.modalService.open(documentsDelete, { centered: true });
          break;
        }
      }
    }
  }

}
