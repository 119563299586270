import { Component, OnInit, Input } from '@angular/core';
import { Amline } from 'src/app/model/quote/amline/amline.model';

@Component({
  selector: 'app-am-drivers-view',
  templateUrl: './am-drivers-view.component.html',
  styleUrls: ['./am-drivers-view.component.css']
})
export class AmDriversViewComponent implements OnInit {
  quote;
  quoteApplicationData = new Amline();
  CoverTo;
  @Input() set quoteDetails(value: any) {
    if (value) {
      this.quote = value;
      this.quoteApplicationData = value.application;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
