<form #f="ngForm">
    <input type="hidden" name="id" placeholder="id">
    <div *ngFor="let agreedQuestion of agreedQuestions; let i = index;">
        <div class="form-group row">
            <div class="col-md-12">
                <div class="quote_radio w-100">
                    <label class="p-3"> 
                        <span>
                            {{agreedQuestion.text}}
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    
</form>