import { PolicyHolderAddress } from './policy_holder_address.model';
import { StableAddress } from './stable_address.model';
import { HorseDetailsSaddlery } from './horse_details_saddlery.model';
import { HorseDetailsTrailer } from './horse_details_trailer.model';
import { LivestockPurchasers } from './livestock_purchasers.model';
export class HorseDetails {
    id: string = '';
    quote_id: string = '';
    policy_id: string = '';
    name: string = '';
    age: number;
    value: number;
    gender: number = 0;
    breed_id: string = '';
    color_id: string = '';
    height: number = 0;
    passport: string = '';
    microchip: string = '';
    horse_purchase_date: string = '';
    is_stable_address_different:number = null;
    address:StableAddress = new StableAddress();
    saddlery_items: Array<object> = [new HorseDetailsSaddlery()];
    trailor_items: Array<object> = [new HorseDetailsTrailer()];
    passport_name:string = '';
    purchase_price:number;
    age_month:number; 
    date_of_vetting:string='';
    is_horse_vetted:number = null;

    // Livestock
    lot_no: string = '';
    livestock_breed_id: string = '';
    livestock_breed_other: string = '';
    livestock_cover_id: string = '';
    livestock_cover_period_id: string = '';
    livestock_sex_id: string = '';
    is_semen_tested: string = '';
    tag_no: string = '';
    vet_certificate_required: string = '';
    is_trial_period_deferred: number = null;
    trial_period_date: string = '';
    animal_type: string = '';
    isButton:boolean;
    livestock_purchasers: any = [new LivestockPurchasers()];
}
