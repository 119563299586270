import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SharedService } from '../services/shared.service';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  
  constructor(private httpClient: HttpClient,private sharedService: SharedService) { }
  
  create(login){
    let url = this.sharedService.apiURL() + 'login';
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.httpClient.post(url, JSON.stringify(login),{headers: headers});
  }
}
