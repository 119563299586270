import { Component, OnInit, Input, forwardRef, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, AbstractControl, FormGroup, FormArray, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-am-claims',
  templateUrl: './am-claims.component.html',
  styleUrls: ['./am-claims.component.css', '../../quote.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmClaimsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AmClaimsComponent),
      multi: true
    }
  ]
})
export class AmClaimsComponent implements OnInit {
  frmFields: FormGroup;
  @Input() submitted: boolean;
  @Input() staticData;
  @Input() modelObj;
  @Input() driver_id;
  @Input() is_claims_selected;
  subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {

  }

  /*
* ControlValueAccessor methods
* registerOnChange, writeValue, registerOnTouched
* Used to pass data to parent form
*/
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }
  writeValue(value) {

  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /*
  * Indicate parent form if claim form is valid or not.
  */
  validate(_: FormControl) {
    return this.frmFields.valid ? null : { drivers: { valid: false } };
  }

  get f(): { [key: string]: AbstractControl } {
    return this.frmFields.controls;
  }

  get claimsArr(): FormArray {
    return this.frmFields.get("claims") as FormArray
  }

  ngOnChanges(changes: SimpleChanges) {
    this.frmFields = this.fb.group({
      claims: this.fb.array([])
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.frmFields.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    if (this.driver_id && this.modelObj && this.modelObj.drivers && this.modelObj.drivers.length > 0) {
      let driver_claims = this.modelObj.drivers.filter(x => x.id == this.driver_id);
      if(driver_claims.length > 0 && driver_claims[0].claims) {
        this.claimsArr.clear();
        driver_claims[0].claims.forEach((res1) => {
          let c = this.fb.group({
            date: [res1.date],
            type: [res1.type],
            ad_cost: [res1.ad_cost],
            tp_cost: [res1.tp_cost],
            fault: [res1.fault],
            open: [res1.open],
          })
          this.claimsArr.push(c);
        })
      }
    }
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  initClaims() {
    return this.fb.group({
      'date': ['', [Validators.required]],
      'type': ['', [Validators.required]],
      'ad_cost': ['', [Validators.required]],
      'tp_cost': ['', [Validators.required]],
      'fault': ['', [Validators.required]],
      'open': ['', [Validators.required]],
    })
  }

  /*
* Claim
*/
  addClaims() {
    this.claimsArr.push(this.initClaims());
  }

  removeClaims(i) {
    this.claimsArr.removeAt(i);
  }

  onSubmit() {
    this.submitted = true;
    if (!this.frmFields.valid) {
      return false;
    }
  }

}
