import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../quote/quote.service';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import { UserInfoService } from '../services/user-info.service';
import { LivestockService } from '../quote/livestock/livestock.service';
import { AmlineService } from '../quote/amline/amline.service';

@Component({
  selector: 'app-quote-pre-qualifier',
  templateUrl: './quote-pre-qualifier.component.html',
  styleUrls: ['./quote-pre-qualifier.component.css']
})
export class QuotePreQualifierComponent implements OnInit {

  constructor(private quoteService: QuoteService, private route: ActivatedRoute, private router: Router,
    private userService: UserInfoService, private LvkService: LivestockService, private as: AmlineService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      let url = event.url.split('/');
      this.routeType = parseInt(url[2]);
      this.getQuestions();
    });
  }

  agreedQuestions;
  ProductId: string = '';
  routeType: number = 0;
  ngOnInit(): void {
    this.routeType = this.route.snapshot.params.type;

    // Authenticate User
    this.userService.getUserDetails();

    // GET AGREED QUESTIONS
    this.getQuestions();
  }

  getQuestions() {
    switch (this.routeType) {
      case 1:
        this.ProductId = environment.EQUINE_ID;
        break;
      case 2:
        this.ProductId = environment.ALPACA_ID;
        break;
      case 3:
        this.ProductId = environment.LIVESTOCK_ID;
        break;
      case 4:
        this.ProductId = environment.ALLIANZ_EQUINE_ID;
        break;
      case 5:
        this.ProductId = environment.AJG_ID;
        break;
      case 6:
        this.ProductId = environment.AMLINE_ID;
        break;
    }

    // GET QUOTE DETAILS
    if (this.routeType == 6) {
      if(this.route.snapshot.params.id) {
      this.as.getAmlineData(this.route.snapshot.params.id)
        .subscribe(
          (response: any) => {
            if (response.result) {
              this.getAgreedQuestions(response.result.quote_data.quote_versions[0].agreed_question_version);
            }
          })
        } else {
          this.getAgreedQuestions(0);
        }
    } else {
      if (this.route.snapshot.params.type != 3) {
        if (this.route.snapshot.params.id) {
          let type = 0;
          if (this.route.snapshot.params.quote_type) {
            type = 1;
          }
          this.quoteService.getQuoteDetailsByQuoteID(this.route.snapshot.params.id, type)
            .subscribe(
              (response: any) => {
                if (response.success) {
                  this.getAgreedQuestions(response.result.agreed_question_version);
                }
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          this.getAgreedQuestions(0);
        }
      }
      else {
        if (this.route.snapshot.params.id) {
          let type = 0;
          if (this.route.snapshot.params.quote_type) {
            type = 1;
          }
          this.LvkService.getQuoteDetailsByQuoteID(this.route.snapshot.params.id, type)
            .subscribe(
              (response: any) => {
                if (response.success) {
                  this.getAgreedQuestions(response.result.agreed_question_version);
                }
              },
              (error) => {
                console.log(error);
              }
            );
        }
        else {
          this.getAgreedQuestions(0);
        }
      }
    }

  }

  //Get Agreed Questions
  getAgreedQuestions(agreed_question_version) {
    this.quoteService.getAgreedQues(this.ProductId, agreed_question_version)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.agreedQuestions = response.result.agreed_questions;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
  * On Agree Button Click
  */
  agreedBtnAction() {
    if (this.route.snapshot.params.type == 1) {
      if (this.route.snapshot.params.id && !this.route.snapshot.params.quote_type) {
        this.router.navigate(['/quote', this.route.snapshot.params.id]);
      } else if (this.route.snapshot.params.quote_type) {
        this.router.navigate(['/quote', this.route.snapshot.params.id, this.route.snapshot.params.quote_type]);
      } else {
        this.router.navigateByUrl("/quote");
      }
    } else if (this.route.snapshot.params.type == 2) {
      if (this.route.snapshot.params.id) {
        if (this.route.snapshot.params.quote_type) {
          this.router.navigate(['/alpaca-admin/quote', this.route.snapshot.params.id, this.route.snapshot.params.quote_type]);
        } else {
          this.router.navigate(['/alpaca-admin/quote', this.route.snapshot.params.id]);
        }
      } else {
        this.router.navigate(['/alpaca-admin/quote']);
      }
    } else if (this.route.snapshot.params.type == 3) {
      if (this.route.snapshot.params.id) {
        if (this.route.snapshot.params.quote_type) {
          this.router.navigate(['/livestock/quote', this.route.snapshot.params.id, this.route.snapshot.params.quote_type]);
        } else {
          this.router.navigate(['/livestock/quote', this.route.snapshot.params.id]);
        }
      } else {
        this.router.navigate(['/livestock/quote']);
      }
    }
    else if (this.route.snapshot.params.type == 4) {
      if (this.route.snapshot.params.id) {
        if (this.route.snapshot.params.quote_type) {
          this.router.navigate(['/allianz-equine/quote', this.route.snapshot.params.id, this.route.snapshot.params.quote_type]);
        } else {
          this.router.navigate(['/allianz-equine/quote', this.route.snapshot.params.id]);
        }
      } else {
        this.router.navigate(['/allianz-equine/quote']);
      }
    }
    else if (this.route.snapshot.params.type == 5) {
      if (this.route.snapshot.params.id) {
        if (this.route.snapshot.params.quote_type) {
          this.router.navigate(['/ajg/quote', this.route.snapshot.params.id, this.route.snapshot.params.quote_type]);
        } else {
          this.router.navigate(['/ajg/quote', this.route.snapshot.params.id]);
        }
      } else {
        this.router.navigate(['/ajg/quote']);
      }
    }
    else if (this.route.snapshot.params.type == 6) {
      if (this.route.snapshot.params.id) {
        if (this.route.snapshot.params.quote_type) {
          this.router.navigate(['/amline/quote', this.route.snapshot.params.id, this.route.snapshot.params.quote_type]);
        } else {
          this.router.navigate(['/amline/quote', this.route.snapshot.params.id]);
        }
      } else {
        this.router.navigate(['/amline/quote']);
      }
    }
  }

}
