<div class="card-header bg-success">Management Information
    <a class="text-white float-right" [routerLink]="['/dashboard']"><u><i class="mdi mdi-arrow-left">Back to Dashboard</i></u></a>
</div>
<div class="card-body">
    <div class="row">        
        <div class="col-md-4">
            <div class="form-group mb-0">
                <mat-form-field class="w-100"> 
                    <mat-label>Frequency </mat-label>
                    <mat-select class="form-control form-control-sm" [(ngModel)]="frequency" name="product"
                        (selectionChange)="onFrequencyChange($event)">
                        <mat-option [value]="1">Daily</mat-option>
                        <mat-option [value]="2">Monthly</mat-option>
                        <mat-option [value]="3">Quarterly</mat-option>
                        <mat-option [value]="4">Bi-Annual</mat-option>
                        <mat-option [value]="5">Annual</mat-option>
                        <mat-option [value]="6">Month to date</mat-option>
                        <mat-option [value]="7">Year to date</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12"> 
            <div class="row text-green text-center">
                <div class="col">
                    <div class="bg-white p-3">No. of live policies<br><b>{{ miData.no_of_live_policies }}</b></div>
                </div>

                <div class="col">
                    <div class="bg-white p-3">No. of issued quotes<br><b>{{ miData.no_of_issued_quotes }}</b></div>
                </div>
                
                <div class="col">
                    <div class="bg-white p-3">Conversion Rate<br><b>{{ miData.conversion_rate }}</b></div>
                </div>
                <div class="col">
                    <div class="bg-white p-3">No. of lapsed policies<br><b>{{ miData.lapsed_policy_count }}</b></div>
                </div>
                <div class="col">
                    <div class="bg-white p-3">No. of Cancelled policies<br><b>{{ miData.cancelled_policy_count }}</b>
                    </div>
                </div>
            </div>
            <div class="row text-green text-center">
                <div class="col">
                    <div class="bg-white p-3">No. of NTU's<br><b>{{ miData.ntu_policy_count }}</b></div>
                </div>
                <div class="col">
                    <div class="bg-white p-3">Retention Rate<br><b>{{ miData.retention_rate }}</b></div>
                </div>
                <div class="col">
                    <div class="bg-white p-3">Gross Written Premium<br><b>&pound;{{ miData.gross_written_premium }}</b></div>
                </div>
                <div class="col">
                    <div class="bg-white p-3">Total Commission<br><b>&pound;0</b></div>
                </div>
                <div class="col" *ngIf="!isBroker">
                    <div class="bg-white p-3">No. of New Business policies<br><b>{{ miData.new_business_policy_count }}</b></div>
                </div>
            </div>
            <div *ngIf="!isBroker">
                <div class="row text-green text-center">                    
                    <div class="col">
                        <div class="bg-white p-3">No. of policies renewed<br><b>{{ miData.renewed_business_policy_count }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">No. of MTA's<br><b>{{ miData.mta_count }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">No. of non-transactional MTA's<br><b>{{ miData.non_transactional_mta }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">No. of MTA Additional Premiums<br><b>{{ miData.additional_premium_mta }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">No. of MTA Returned Premiums<br><b>{{ miData.returned_premium_mta }}</b></div>
                    </div>
                </div>
                <div class="row text-green text-center">                    
                    <div class="col">
                        <div class="bg-white p-3">Total number of MTA's<br><b>0</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">No. of lapsed policies<br><b>0</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">Net Written Premium<br><b>&pound;{{ miData.net_written_premium }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">Average Premium<br><b>&pound;{{ miData.avg_premium }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">New Business Average Premium<br><b>&pound;{{ miData.new_business_average_premium }}</b></div>
                    </div>
                </div>
                <div class="row text-green text-center">     
                    <div class="col">
                        <div class="bg-white p-3">Renewal Average Premium<br><b>&pound;{{ miData.renewal_average_premium }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">No. of live Claims<br><b>{{ miData.number_of_live_claims }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">No. of Settled Claims<br><b>{{ miData.number_of_settled_claims }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">Total Claims Costs<br><b>&pound;{{ miData.total_claims_cost }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">Running Gross Loss Ratio<br><b>{{ miData.running_gross_loss_ratio }}</b></div>
                    </div>
                </div>
                <div class="row text-green text-center">   
                    <div class="col">
                        <div class="bg-white p-3">Claims Frequency<br><b>{{ miData.claims_frequency }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">Premium by Vets Fees<br><b>&pound;{{ miData.premium_by_vets_fee }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">Premium by Loss of Use<br><b>&pound;{{ miData.premium_by_loss_of_use }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">Premium by Death<br><b>&pound;{{ miData.premium_by_death }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">Premium by Theft<br><b>&pound;{{ miData.premium_by_theft }}</b></div>
                    </div>
                </div>
                <div class="row text-green text-center">                 
                    
                    <div class="col">
                        <div class="bg-white p-3">Premium by Public Liability<br><b>&pound;{{ miData.premium_by_public_liability }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">Premium by Personal Accident<br><b>&pound;{{ miData.premium_by_personal_accident }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">Premium by Saddlery & Tack<br><b>&pound;{{ miData.premium_by_saddlery_tack }}</b></div>
                    </div>
                    <div class="col">
                        <div class="bg-white p-3">Premium by Trailer<br><b>&pound;{{ miData.premium_by_trailer }}</b></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

   
</div>