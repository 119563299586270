<div class="calculator">
    <mat-accordion>
        <!-- <mat-expansion-panel *ngIf="quote.is_aeio == 1 || (quote.indicativePage && quote.is_aeio == 1)"
            [expanded]="!quote.full_quote_section" [hidden]="quote.hideAEIOCal"> -->
        <mat-expansion-panel *ngIf="quote.aeio_quote_section && quote.is_aeio == 1" [expanded]="quote.is_aeio == 1">
            <mat-expansion-panel-header>
                <mat-panel-title class="cal_header">
                    AEIO Quote Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="cal_body p-3 bg-white">
                <h5>Accidental, External (Visible) Injury Only (A.E.I.O) <span (click)="helpTextPopup(content)"><i
                            class="mdi mdi-comment-question-outline ml-1"></i></span>
                </h5>
                <p>
                    <b>Policy premium</b><br>&pound;{{calculationsDe.aeio_total_gross_primum | number:'1.2-2' }}
                </p>
                <p>
                    <b>Broker commission</b><br>&pound;{{calculationsDe.aeio_broker_amount}}
                </p>
                <p>
                    <b>Insurance premium tax (12%)</b><br>&pound;{{calculationsDe.aeio_ipt_gross_amount}}
                </p>
                <p>
                    <b>Total premium</b><br>&pound;{{calculationsDe.aeio_total_premium}}
                </p>
                <p>
                    <b>Fees</b><br>&pound;{{calculationsDe.aeio_underwriting_fee}}
                </p>
                <p>
                    <b>Total cost of the policy</b><br>&pound;{{calculationsDe.aeio_total_amount_payable}}
                </p>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mt-2" *ngIf="quote.is_aeio != 1" [expanded]="quote.is_aeio != 1">
            <mat-expansion-panel-header>
                <mat-panel-title class="cal_header">
                    {{ (quote.is_aeio == 3)?'Sports Cover':(quote.is_aeio == 4)?'Bloodstock':'Leisure Cover' }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="cal_body p-3 bg-white">
                <p>
                    <b>Policy premium</b><br>&pound;{{calculationsDe.full_total_gross_primum | number:'1.2-2'}}
                </p>
                <p>
                    <b>Broker commission</b><br>&pound;{{calculationsDe.full_broker_amount}}
                </p>
                <p>
                    <b>Insurance premium tax (12%)</b><br>&pound;{{calculationsDe.full_ipt_gross_amount}}
                </p>
                <p>
                    <b>Total premium</b><br>&pound;{{calculationsDe.full_total_premium}}
                </p>
                <p>
                    <b>Fees</b><br>&pound;{{calculationsDe.full_underwriting_fee}}
                </p>
                <p>
                    <b>Total cost of the policy</b><br>&pound;{{calculationsDe.full_total_amount_payable}}
                </p>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="btn btn-default btn-gray mt-2 w-100 text-left" [hidden]="quote_type == 3 &&  quote.indicativePage"
        *ngIf="quote.quote_ref_number">
        Quote Reference: {{quote.quote_ref_number}}
    </div>

</div>
<ng-template #content let-modal>
    <div class="modal-body">
        Accidental,
        External, (Visible) Injury Only – physical external damage or external trauma to a horse arising
        from a sudden accidental, unexpected specific event that occurs at an identifiable time and
        place and results in a visible external wound only and/or bone fracture. It cannot be caused by
        an illness or disease.
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>