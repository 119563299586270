export class SmallHoldingActivities {
    id: string = '';
    quote_id: string = '';
    is_alpaca_experience_sessions: number = null;
    is_group_visits_insured_premises: number = null;
    is_visits_external_venues: number = null;       
    is_promoting_selling_products: number = null;       
    is_attending_events_trade_stand: number = null;       
    is_public_interact_animal: number = null;       
}
