import { QuoteVersions } from './quote_versions.model';
import { CalculationsDetails } from '../../quote/calculations/calculations.model';

export class Quote {
    id: string = '';
    quote_id: string = '';      
  
    insurance_status_id: string = 'e216564f-552a-4e45-ae67-0a63b7074691';    
    status: number = 0;
    insurer:string = '';
    
    policy_cover_index: string = ''; 
    vet_fees_cover_id: string = '';
    slider_broker_comm: number = 0;
    company_co_pay_discount: number = 0.00;  
    is_full_terms_accepted:  number = 0;
    is_sof_accepted:  number = 0;
    
    full_class_of_use: string = '';
    full_policy_cover: string = '';
    full_vet_fee_cover: string = '';
    full_life_saving_surgery: string = '';
    full_vet_fee_excess: string = '';
    full_public_liability: string = '';
    full_personal_accident: string = '';
    bloodstock_text: string = '';
    full_company_co_pay_excess = false;
    indicativePage: boolean = false;
    company_co_insurance: boolean = false;
    full_quote_section: boolean = true;
    aeio_quote_section: boolean = true;
    hide_sections_for_blood_stock: boolean = false;
    quote_document = 'Choose file';
    LifeSaving7500: boolean = false;
    primaryCover: string = '';
    prev_sequence_number: number = null;
    mta_sum_policy_premium: number = 0;
    old_premium_loading_percentage: number = 0;

    isIndicative: boolean = true;
    hideAEIOCal: boolean = false;

    quote_versions:Array<QuoteVersions> = [new QuoteVersions()];
    calculations: any = new CalculationsDetails();
    
}
