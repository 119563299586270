import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { CompanyService } from './company.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
declare var $;
import { GridOptions } from "ag-grid-community";
import { UserInfoService } from '../services/user-info.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    { headerName: 'Registration Number', field: 'registration_number', sortable: true, filter: true },
    { headerName: 'Name', field: 'name', sortable: true, filter: true },
    { headerName: 'Email', field: 'email', sortable: true, filter: true },
    { headerName: 'Parent Company', field: 'parent_company.name', sortable: true, filter: true },
    {
      headerName: null,
      width: 120,
      cellRenderer: function (params) {
        let buttons = '<button><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></button>';
         buttons += '<button><i class="mdi mdi-account-multiple-outline" data-action-type="users" id="' + params.data.id + '"></i></button>';
        if (params.data.status == 0) {
          buttons += '<button title="Do you want to activate company?"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete" id="' + params.data.id + '"></i></button>';
        } else {
          buttons += '<button title="Do you want to de-activate company?"><i class="mdi mdi-check-circle-outline activeIcon" data-action-type="delete" id="' + params.data.id + '"></i></button>';
        }
        return buttons;

      }
    }
  ];
  rowData: any;

  constructor(private companyService: CompanyService, private router: Router, public dialog: MatDialog,
    private confirmDialogService: ConfirmDialogService, private userService: UserInfoService) { }

  ngOnInit(): void {
    this.userService.getUserDetails();
    this.companyService.getAllCompanies().subscribe(data => {
      this.rowData = (data as any).result;
    });
  }

  onGridRowClicked(e: any) {
    if (e) {
      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {
            this.router.navigate(['/company-manage', id]);
            break;
          }
        case "users":
          {
            this.router.navigate(['/user-list', id]);
            break;
          }
        case "delete":
          { 
            this.deleteDetails(e.data);  
            break;          
          }
      }
    }
  }

  updateCompany(data) {
    this.companyService.saveDetails(data).subscribe(
      (response) => {
        this.gridOptions.api.redrawRows()
      },
      (error) => {
        console.log(error);
      }
    );
  }

  deleteDetails(data) {
    let title = '';
            let message = '';
            let imageType = '';
            if (data.status == 1) {
              data.status = 0;
              message = "Are you sure you want to de-activate " + data.name + "?";
              title = "De-activate Company";
              imageType = 'deactivate';
            }
            else {
              data.status = 1;
              message = "Are you sure you want to Activate " + data.name + "?";
              title = "Activate Company";
              imageType = 'activate';
            }
            const options = {
              title: title,
              message: message,
              imageType: imageType,
              yesNoBtns: true
            };
            this.confirmDialogService.open(options);
            this.confirmDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                this.updateCompany(data);
              }
            });
  }

}
