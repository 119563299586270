import { Component, OnInit, Input } from '@angular/core';
import {User} from '../model/user/user.model'
import {UserInfo} from '../model/user/userinfo.model'
import {UserGroupLinking} from '../model/user/user.group.linking.model'
import {Address} from '../model/address/address.model'
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { UserService } from './user.service';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  user; 
  userroles = [];
  company_id;
  groups: any; 
  newUserID;
  activateAddressTab = false;
  selectedIndex: number = 0; 
  addressCompanyID;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, 
    private userService: UserService, private confirmDialogService: ConfirmDialogService) { }

  ngOnInit(): void {
    this.user = new User();
    this.company_id = this.route.snapshot.params.company_id;
    this.user.id = this.route.snapshot.params.id;

    // GET GROUPS LIST
    this.userService.getGroups()
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.groups = (response as any).result;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );

      // GET USER DETAILS BY ID
      if (this.route.snapshot.params.id) {
        this.activateAddressTab = true;
        this.userService.getUserByID(this.route.snapshot.params.id)
          .subscribe(
            (response: any) => {
              this.user = response.result.user;
              if(response.result.user.address != null) {
                this.user.address = response.result.user.address;
              }else {
                this.user.address = new Address();
              }
              if(!response.result.user.userinfo) {
                this.user.userinfo = new UserInfo();
              }
              if(!response.result.user.group_user_linking) {
                this.user.group_user_linking = new UserGroupLinking();
              }
              
            },
            (error) => {
              console.log(error);
            }
          );
      } 
  }

  // SAVE USER DETAILS
  onSubmit(f: NgForm) {
    if (f.value.id) {
      f.value.status = 1;
    } else {
      f.value.status = 0;
    }
    let data: any = f.value;

    this.userService.saveUser(data)
      .subscribe(
        (response) => {
          if ((response as any).success) {
           
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'success',
              yesNoBtns: true
            };
            this.confirmDialogService.open(options);
            this.confirmDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                this.selectedIndex += 1;
              }
            });
            this.newUserID = (response as any).result.user_id.id;
            this.user.id = (response as any).result.user_id.id;
            this.activateAddressTab = true;
            
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }

        },
        (error) => {
          console.log(error);
        }
      );
  }

  tabChanged(event) {
    this.selectedIndex = event.index;
    if (event.index == 1) {
      if (this.route.snapshot.params.id) {
        this.addressCompanyID = this.route.snapshot.params.id;
      } else {
        //this.addressCompanyID = this.newCompanyID;
      }
    }
  }

  // NEXT BUTTON ACTION
  NextBtnAction() {
    this.selectedIndex += 1;
    // this.modalService.close("successMsg");
  }

 // Previous BUTTON ACTION
 PreviousBtnAction() {
  this.selectedIndex = 0;
}


  // SAVE ADDRESS
  onAddressSubmit(fa: NgForm) {
    fa.value.status = 1;
    
    let data: any = fa.value;

    this.userService.saveAddressDetails(data)
      .subscribe(
        (response) => {         
          if ((response as any).success) {
            this.user.address.id = (response as any).result.address_id;
            
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'success',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);

            // this.router.navigateByUrl("/company");
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }  
    
        },
        (error) => {
          console.log(error);
        }
      );
  }

}
