import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { AlQuoteService } from '../../al-quote/al-quote.service';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import { UserInfoService } from '../../../../services/user-info.service';

@Component({
  selector: 'app-admin-quote-sof',
  templateUrl: './quote-sof.component.html',
  styleUrls: ['../../../quote.component.css'],
})
export class AlQuoteSofComponent implements OnInit {
  @Input() quoteData: QuoteVersions;
  quesTitles = [];
  statementOfFacts;

  constructor(private quoteService: AlQuoteService, private modalService: NgbModal, private userService: UserInfoService) { }
  quote;
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteData;
    if(this.quote.length > 0 && this.quote.statement_of_facts.length > 0) {
      this.quote.statement_of_facts.forEach((res, index) => {
        if(res.response == 0) {
          res.response = null;
        }
      });
    }
    // Get All Questions
    this.quoteService.getStatementOfFacts(this.quote.sof_version)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.statementOfFacts = response.result.statement_of_fact;
            if (this.quote.statement_of_facts.length <= 1) {
              this.statementOfFacts.forEach((res, index) => {
                this.quote.statement_of_facts.push({
                  id: '',
                  quote_id: '',
                  question_id: res.id,
                  response: null,
                  response_bool: false,
                  response_text: '',
                  display_order: res.display_order
                });

              });
            }
          }

        },
        (error) => {
          console.log(error);
        }
      );

  }

  ngDoCheck() {
    if (this.statementOfFacts && this.quote) {
      let temp;
      this.statementOfFacts.forEach((res, index) => {
        // Assign Section Titles
        if (res.product_type == 2 && temp != res.product_type) {
          if(this.quote.about_alpaca && this.quote.about_alpaca.does_vet_treatments_covered == 1) {
            res.quesTitles = 'ALPACA/ LLAMA mortality and limited theft with vets fees statement of facts';
          } else {
            res.quesTitles = 'ALPACA/ LLAMA mortality';
          }          
        } else if (res.product_type == 3 && temp != res.product_type) {
          res.quesTitles = 'Public; Product and Employers Liability statement of Facts';
        } else if (res.product_type == 4 && temp != res.product_type) {
          res.quesTitles = 'Trailer Cover';
        }
        temp = res.product_type;
      });
    }

  }

  ngOnInit(): void {
    
    // Get All Questions
    // this.quoteService.getStatementOfFacts(this.quote.sof_version)
    //   .subscribe(
    //     (response: any) => {
    //       if (response.success) {
    //         this.statementOfFacts = response.result.statement_of_fact;
    //         if (this.quote.statement_of_facts.length <= 1) {
    //           this.statementOfFacts.forEach((res, index) => {
    //             this.quote.statement_of_facts.push({
    //               id: '',
    //               quote_id: '',
    //               question_id: res.id,
    //               response: null,
    //               response_bool: false,
    //               response_text: '',
    //               display_order: res.display_order
    //             });

    //           });
    //         }
    //       }

    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
  }

  /*
  * HELP TEXT POPUP
  */
 helptext = '';
 helpTextPopup(content, text) {
   const modalRef = this.modalService.open(content, { centered: true });
   this.helptext = text;
 }


}
