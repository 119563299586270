import { Component } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent { 
  title = 'Peliwica Insurance';  
  isLogin: boolean = false;

  constructor(private sessionSt: LocalStorageService) { }

  ngOnInit(): void {
    // REDIRECT TO LOGIN SCREEN IF NOT LOGGED IN
    if (this.sessionSt.retrieve('login_token') != null) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  }

}
