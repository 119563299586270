import { Component, OnInit, forwardRef, SimpleChanges, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder, Validators, FormGroup,
  ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { ActivatedRoute } from "@angular/router";
import { QuoteService } from '../../../quote/quote.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-commissions',
  templateUrl: './commissions.component.html',
  styleUrls: ['../../quote.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CommissionsComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CommissionsComponent),
      multi: true
    }
  ]
})
export class CommissionsComponent implements ControlValueAccessor, OnInit {
  @Input() brokers;
  @Input() updatedCalDetails;
  qdfrm: FormGroup;
  subscriptions: Subscription[] = [];
  QuoteDetails = new QuoteVersions();
  @Input() quoteModelDetails = new QuoteVersions();
  @Output() calDetails = new EventEmitter();

  slider_broker_comm_max = 0;
  max_ppa_comission = 0;
  max_prt_comission = 0;
  max_uw_fee = 0;
  max_broker_admin_fee = 0;
  legacy_loading = 0;
  isAdmin = false;
  calculations = new CalculationsDetails();
  company_id: any;
  company_role: any;

  constructor(private fb: FormBuilder, private sessionSt: LocalStorageService,
    private route: ActivatedRoute, private cdRef: ChangeDetectorRef, private quoteService: QuoteService) {

  }

  enableCommissionsSection: boolean = false;
  ngOnInit(): void {
    this.qdfrm = this.fb.group({
      broker_commission: [""],
      peliwica_pay_away_commission: [""],
      peliwica_retained_commission: [""],
      premium_loading: [""],
      uw_fee: [""],
      broker_admin_fee: [""],
      legacy_loading: [""],
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.qdfrm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    if (this.sessionSt.retrieve('company_role') != 'Broker') {
      this.isAdmin = true;
    }

    if (this.route.snapshot.params.id) {
      this.enableCommissionsSection = true;
    }
  }

  PrevCal;
  quote_type;
  ngOnChanges(changes: SimpleChanges) {
    if (changes.updatedCalDetails) {
      this.PrevCal = changes.updatedCalDetails.currentValue;
    }
    this.brokerCommissions();
    this.QuoteDetails = this.quoteModelDetails;

    if (this.route.snapshot.params.id && this.qdfrm && this.quoteModelDetails) {
      this.qdfrm.patchValue({
        broker_commission: this.quoteModelDetails.financial_details.broker_commission,
        peliwica_pay_away_commission: this.quoteModelDetails.financial_details.peliwica_pay_away_commission,
        peliwica_retained_commission: this.quoteModelDetails.financial_details.peliwica_retained_commission,
        premium_loading: this.quoteModelDetails.financial_details.premium_loading_percentage,
        uw_fee: this.quoteModelDetails.financial_details.uw_fee,
        broker_admin_fee: this.quoteModelDetails.financial_details.broker_admin_fee,
        legacy_loading: this.quoteModelDetails.financial_details.tigerlab_premium_loading
      });
      this.broker_commission = this.quoteModelDetails.financial_details.broker_commission;
      this.peliwica_pay_away_commission = this.quoteModelDetails.financial_details.peliwica_pay_away_commission;
      this.peliwica_retained_commission = this.quoteModelDetails.financial_details.peliwica_retained_commission;
      this.uw_fees = this.quoteModelDetails.financial_details.uw_fee;
      this.broker_admin_fee = this.quoteModelDetails.financial_details.broker_admin_fee;
      this.legacy_loading = this.quoteModelDetails.financial_details.tigerlab_premium_loading;
      this.commissionCals();
    }

    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);
    } else {
      this.quote_type = Number(this.quoteModelDetails.type);
    }

  }

  newCals;
  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  broker_commission = 0;
  peliwica_pay_away_commission = 0;
  peliwica_retained_commission = 0;
  uw_fees = 0;
  broker_admin_fee = 0;
  brokerCommissions() {
    // let commission_percentage = 0;
    // let peliwica_pay_away_commission = 0;
    // let peliwica_retained_commission = 0;
    // let uw_fees = 0;
    // let broker_admin_fee = 0;
    // if (this.QuoteDetails.calculations) {
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      this.getBrokerCommById(this.sessionSt.retrieve('broker_company_id'));
      this.QuoteDetails.company_id = this.sessionSt.retrieve('broker_company_id');
    } else {
      if (this.QuoteDetails && this.QuoteDetails.company_id) {
        this.getBrokerCommById(this.QuoteDetails.company_id);
      }
    }

  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.qdfrm.valid ? null : { qdfrm: { valid: false } };
  }

  /*
  * Commission Slider Number Formatter
  */
  formatSliderLabel(value: number) {
    return value + '%';
  }
  formatUWSliderLabel(value: number) {
    return '£' + value;
  }
  /*
 * On Broker Comission Slider Change
 */
  isSliderChanged: boolean = false;
  onBrokerComissionChange(event) {
    this.broker_commission = event.value;
    this.commissionCals();
  }

  isBrokerCommissionReduced: boolean = false;
  getBrokerCommById(broker_id) {
    if (broker_id) {
      this.quoteService.getLiveStockBrokerCommission(broker_id, environment.LIVESTOCK_ID)
        .subscribe(
          (response: any) => {
            if (response.success) {
              let brokers_data = response.result;
              if (brokers_data != null) {
                // this.QuoteDetails.calculations.broker_commission = Number(brokers_data.broker_commission);
                // this.QuoteDetails.calculations.peliwica_pay_away_commission = Number(brokers_data.pay_away_commission);
                // this.QuoteDetails.calculations.peliwica_retained_commission = Number(brokers_data.retained_commission);
                // this.QuoteDetails.calculations.full_underwriting_fee = Number(brokers_data.uw_fee);
                // this.QuoteDetails.calculations.broker_admin_fee = Number(brokers_data.broker_admin_fee);

                this.slider_broker_comm_max = Number(brokers_data.broker_commission);
                this.max_ppa_comission = Number(brokers_data.pay_away_commission);
                this.max_prt_comission = Number(brokers_data.retained_commission);
                this.premium_loading = Number(brokers_data.premium_loading);
                this.max_uw_fee = Number(brokers_data.uw_fee);
                this.max_broker_admin_fee = Number(brokers_data.broker_admin_fee);
                this.broker_admin_fee = Number(brokers_data.broker_admin_fee);

                if (!this.route.snapshot.params.id && !this.isSliderChanged) {
                  this.broker_commission = Number(brokers_data.broker_commission);
                  this.peliwica_pay_away_commission = Number(brokers_data.pay_away_commission);
                  this.peliwica_retained_commission = Number(brokers_data.retained_commission);
                  this.uw_fees = Number(brokers_data.uw_fee);
                }

                if (this.QuoteDetails.financial_details.broker_commission != brokers_data.broker_commission) {
                  this.isBrokerCommissionReduced = true;
                }
              }


            }
          });
    }
  }
  /*
 * On Pay Away Comission Slider Change
 */
  onPPayAwayComissionChange(event) {
    this.peliwica_pay_away_commission = event.value;
    this.commissionCals();
  }
  /*
 * On Retained Comission Slider Change
 */
  onPRetainedComissionChange(event) {
    this.peliwica_retained_commission = event.value;
    this.commissionCals();
  }
  /*
 * On Premium Loading Slider Change
 */
  premium_loading = 0;
  onPreLoadingChange(event) {
    this.premium_loading = event.value;
    this.commissionCals();
  }
  /*
 * On Legecy Loading Change
 */
  ontTgerLabPremLoadingbChange(event) {
    // this.quote.financial_details.tigerlab_premium_loading = event.target.value;
    // this.indicativeCalculationsDetails.tigerlab_premium_loading = event.target.value;
  }

  /*
 * On UW Fee Change
 */
  onFeeChange(event) {
    this.uw_fees = event.value;
    this.commissionCals();
  }
  /*
 * On Admin Broker Fee Change
 */
  onAdminBrokerFeeChange(event) {
    this.broker_admin_fee = event.value;
    this.commissionCals();
  }

  commissionCals() {
    this.calculations.broker_commission = Number(this.broker_commission);
    this.calculations.peliwica_pay_away_commission = Number(this.peliwica_pay_away_commission);
    this.calculations.peliwica_retained_commission = Number(this.peliwica_retained_commission);
    this.calculations.full_underwriting_fee = Number(this.uw_fees);
    this.calculations.broker_admin_fee = Number(this.broker_admin_fee);
    this.calculations.premium_loading_percentage = Number(this.premium_loading);
    // this.calculations.tigerlab_premium_loading = Number(this.legacy_loading);
    this.isSliderChanged = true;
    this.calDetails.emit(this.calculations);
    this.grossCommissionCals();
  }

  ppaGrossCommission = 0;
  prGrossCommission = 0;
  grossCommissionCals() {
    if (this.QuoteDetails && this.QuoteDetails.calculations) {
      // this.ppaGrossCommission = this.QuoteDetails.calculations.full_total_gross_primum * this.peliwica_pay_away_commission / 100;
      // this.prGrossCommission = this.QuoteDetails.calculations.full_total_gross_primum * this.peliwica_retained_commission / 100;
      this.ppaGrossCommission = this.QuoteDetails.calculations.full_pay_away_amount;
      this.prGrossCommission = this.QuoteDetails.calculations.full_peliwica_retained_amount;
    }
  }

}
