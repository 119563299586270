export class CompanyUpsertAddress {
      id: string = '';
      address1: string = '';
      address2: string = '';
      address3: string = '';
      address4: string = '';
      city: string = '';
      country: string = '';
      postcode: string = '';
      status: string = '';
 }