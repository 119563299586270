import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { GridOptions } from "ag-grid-community";
import { QuoteService } from '../quote/quote.service';
import { QuoteVersions } from '../model/quote/quote_versions.model';
import { Router } from "@angular/router";
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-quote-email',
  templateUrl: './quote-email.component.html',
  styleUrls: ['./quote-email.component.css']
})
export class QuoteEmailComponent implements OnInit {
  @Output() emailView = new EventEmitter<any>();
  @Output() newEmailView = new EventEmitter<any>();
  @Input() quote: QuoteVersions;

  constructor(private quoteService: QuoteService, private router: Router) { }

  ngOnChanges(changes: SimpleChanges) {
    // API - GET EMAILS LIST    
    if (this.quote.id) {
      this.quoteService.getEmailsList(this.quote.id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.rowData = response.result;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  ngOnInit(): void {

  }

  gridOptions: GridOptions = {
    onGridReady: () => {
      // this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Subject', field: 'subject', sortable: true, width: 400,
      tooltip: function (params) {
        return params.data.subject;
      },
    },
    {
      headerName: 'To', field: 'email_to', sortable: true, width: 200,
      tooltip: function (params) {
        return params.data.email_to;
      },
    },
    {
      headerName: 'Sent At', field: 'created_at', sortable: false, width: 200,
      cellRenderer: function (params) {
        return formatDate(params.data.created_at, 'dd-MM-yyyy hh:mm a', 'en-US');
      }
    },
    {
      headerName: null,
      width: 120,
      cellRenderer: function (params) {
        let links = '';
        links += '<div class="text-right"><a title="View Email Details" style="font-size: 18px;"><i class="mdi mdi-eye" data-action-type="view"></i></a></div>';
        return links;
      }
    }
  ];
  rowData: any = [];

  onGridRowClicked(e: any) {
    if (e) {
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "view":
          {
            this.emailView.emit(e.data.id);
            break;
          }
      }
    }
  }

  // Send Email - HTML VIEW
  sendEmail() {
    this.newEmailView.emit();
  }


}
