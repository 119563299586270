import {
  Component, OnInit, Input, EventEmitter, SimpleChanges,
  Output
} from '@angular/core';
import { CalculationsDetails } from './calculations.model';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-allianz-calculations',
  templateUrl: './calculations.component.html',
  styleUrls: ['../allianz.component.css'],

})
export class AllianzCalculationsComponent implements OnInit {
  @Input() quoteInfo: QuoteVersions;
  quote: QuoteVersions;
  @Input() calculationsIndicative;
  @Input() indicativeQuoteCalculationSection;
  @Input() indicativeCalculationsDetails: CalculationsDetails;
  @Input() componentName;
  calculationsDe;
  //indicativeCalculationsDetails

  // @Input()
  //   set indicativeCalculationsDetails(value: any) {
  //       this.calculationsDe = value;
  //   }


  constructor(private modalService: NgbModal, private route: ActivatedRoute, private router: Router) { }

  temp;
  ngOnChanges(changes: SimpleChanges) {
    this.calculationsDe = null;
    this.calculationsDe = this.indicativeCalculationsDetails;
    this.quote = this.quoteInfo;
  }


  quote_type;
  ngOnInit(): void {
    //  this.calculationsDe = this.indicativeCalculationsDetails;
    this.quote = this.quoteInfo;
    if (this.route.snapshot.params.quote_type) {
      this.quote_type = this.route.snapshot.params.quote_type;
    }
  }

  /*
  * HELP TEXT POPUP
  */
  helptext = '';
  helpTextPopup(content) {
    this.modalService.open(content, { centered: true });
  }

}
