import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges,ViewChild,ElementRef,AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { LivestockService } from '../livestock.service';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteService } from '../../quote.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-lv-payment-confirmation',
  templateUrl: './lv-payment-confirmation.component.html',
  styleUrls: ['../../quote.component.css', './lv-payment-confirmation.component.css']
})
export class LvPaymentConfirmationComponent implements OnInit,AfterViewInit {
 
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() proceedToNextTab = new EventEmitter();
  @Input() quoteModelDetails;
  @Input() selectedTabIndex;
  quoteDetails = new QuoteVersions();
  quoteDetailsForm: FormGroup;
  calculations = new CalculationsDetails();


  @ViewChild('paymentForm', { static: false }) public paymentForm: ElementRef;

  // readonly worldpayClientKey = 'T_C_6304fe4e-c20a-4e09-b0e1-a9b3a5de9cd4';
  token: string = null;

  constructor(private fb: FormBuilder, private sessionSt: LocalStorageService, private _snackBar: MatSnackBar, private cdRef: ChangeDetectorRef,
    private lvService: LivestockService, private modalService: NgbModal, private quoteService: QuoteService) {

    this.quoteDetailsForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: [''],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
      address: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    //this.loadScript('https://cdn.worldpay.com/v1/worldpay.js', this.init);
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    //var form = this.paymentForm.nativeElement;
    // Worldpay.useOwnForm({
    //   'clientKey': 'T_C_6304fe4e-c20a-4e09-b0e1-a9b3a5de9cd4',
    //   'form': form,
    //   'reusable': false,
    //   'callback': function(status, response) {
    //       document.getElementById('paymentErrors').innerHTML = '';
    //       if (status != "200") {
    //         Worldpay.handleError(form, document.getElementById('paymentErrors'), response.error);
    //       } else {
    //         var token = response.token;
    //         Worldpay.formBuilder(form, 'input', 'hidden', 'token', token);
    //         form.onsubmit;
    //       }
    //   }
    // });

    // Worldpay.useTemplateForm({
    //   'clientKey':'T_C_6304fe4e-c20a-4e09-b0e1-a9b3a5de9cd4',
    //   'form':'paymentForm',
    //   'paymentSection':'paymentSection',
    //   'display':'inline',
    //   'reusable':true,
    //   'callback': function(obj) {
    //     if (obj && obj.token) {
    //       var _el = document.createElement('input');
    //       _el.value = obj.token;
    //       _el.type = 'hidden';
    //       _el.name = 'token';
    //       form.appendChild(_el);
    //       form.onsubmit;
          
    //     }
    //   }
    // });
    
  }


  // init = (): void => {
  //   console.log('Loading');
  //   Worldpay.useTemplateForm({
  //     'clientKey': this.worldpayClientKey,
  //     'form': this.paymentForm.nativeElement,
  //     'paymentSection': 'paymentSection',
  //     'display': 'inline',
  //     'type': 'card',
  //     'crossDomain': true,
  //     'callback': this.worldpayCallback
  //   });
  // }

  // worldpayCallback = (status): void => {
  //   this.token = status.token;
  //   console.log(`Token: ${this.token}`);
  //   this.makePaymet(this.token);
  // }

  // makePaymet(token){
  //    var data= { 
  //       "token" :token, 
  //       "orderDescription" : "Test", 
  //       "amount" : 10, 
  //       "currencyCode" : "GBP" 
  //     }
  //     this.sharedService.postPaymentAPI("https://api.worldpay.com/v1/orders", data).subscribe(
  //       res => {
  //         if (res) {
  //           console.log(res);
  //         } else {
  //           console.log(res);
  //         }
  //       },
  //       err => {
  //         console.log(err);
  //       },
  //     );
  //   }
    
  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quoteModelDetails;
    if (this.selectedTabIndex == 4) {
      this.quoteDetails.calculations = this.lvService.bindCalculations(this.quoteModelDetails);
      this.calculations = this.quoteDetails.calculations;
    }
  }

  get frmCtrl() {
    return this.quoteDetailsForm.controls;
  }

  PaymentSections: boolean = false;
  oneOffPayment() {
    this.PaymentSections = true;
    
  }

  cancelPayment() {
    this.PaymentSections = false;
  }

  submitted: boolean = false;
  proceedBtn(converttopolicymodal) {
    this.submitted = true;
    this.convertToPolicy(converttopolicymodal);
    
  }

   /*
  * On Policy Start Date Change
  */
   onPolicyStartDateChange(event) {
    this.disablIfPastDate = false;
  }

   /*
  * Convert to Policy
  */
   disablIfPastDate: boolean = false;
   minPolicyDate;
   maxPolicyDate;
   minPolicyDate1 = new Date();
   convertToPolicy(converttopolicymodal) {
     if (this.sessionSt.retrieve('company_role') == 'Broker') {
       let maxDate = new Date().setDate(new Date().getDate() + 30);
       this.maxPolicyDate = new Date(maxDate);
    //   this.minPolicyDate = new Date();
    this.minPolicyDate = new Date("2022,05,15");    
    this.minPolicyDate1 = new Date("2022,05,14");  
    this.minPolicyDate1.setHours(0, 0, 0, 0);
  } else {
      if(this.sessionSt.retrieve('user_group') == 'Staff') {
        this.minPolicyDate = new Date();
      }
    }
     // Disable convert to policy buttons if date is past date
     let policy_start_date = new Date(this.quoteDetails.policy_start_date);
     policy_start_date.setHours(0, 0, 0, 0);
     let today = new Date();
     today.setHours(0, 0, 0, 0);
     if (policy_start_date.getTime() < this.minPolicyDate1.getTime() && this.sessionSt.retrieve('company_role') == 'Broker') {
       this.disablIfPastDate = true;
     }
     this.modalService.open(converttopolicymodal, { centered: true });
   }
 
   convertToPolicyPost() {
     let data: any = '';
     data = {
       quote_id: this.quoteDetails.id,
       policy_ref_number: this.quoteDetails.policy_ref_number,
       policy_start_date: formatDate(this.quoteDetails.policy_start_date, 'yyyy-MM-dd', 'en-US')
     };
     this.quoteService.convertToPolicy(data)
       .subscribe(
         (response: any) => {
           this.modalService.dismissAll();
           if (response.success) {
            let temp;
            temp = this.quoteDetails;
            this.quoteDetails = response.result.quote_versions[0];
            this.quoteDetails = this.lvService.bindQouteSaveApiResponse(this.quoteDetails, response.result);   
            this.quoteDetails.calculations = this.calculations;            
            this.quoteDetails.prev_sequence_number = temp.prev_sequence_number;
            this.quoteDetailsUpdated.emit(this.quoteDetails);
            this.proceedToNextTab.emit();
           } else {
             this._snackBar.open(response.message, '', {
               duration: 5000,
               panelClass: 'text-danger'
             });
           }
 
         });
   }



  onSubmit() {
    this.submitted = true;
    // if(!this.quoteDetailsForm.valid) {
    //   return false;
    // }
    this.quoteDetailsUpdated.emit(this.quoteDetails);
    this.proceedToNextTab.emit();
  }

  private loadScript(url: string, callbackFunction: (any) = undefined) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.onload = callbackFunction;
    document.getElementsByTagName('body')[0].appendChild(node);
  }

}
