<h3>Notes <button (click)="openNotesPopup(content)" class="btn btn-success btn-sm ml-3">Add Notes</button></h3>

<ag-grid-angular style="width: 100%;" class="ag-theme-bootstrap mt-3" [gridOptions]="gridOptions"
            [rowData]="rowData" [columnDefs]="columnDefs" animateRows pagination=false paginationPageSize=5 rowHeight=27
            headerHeight=35 domLayout='autoHeight'
            (rowClicked)='onGridRowClicked($event,content,deleteModel,notesViewModel)'> </ag-grid-angular>

<ng-template #content let-modal>
    <div class="modal-body">
        <div class="form-group">
            <label>Notes</label> 
            <textarea name="notes" class="form-control" [(ngModel)]="notes.notes" rows="5"></textarea>
        </div>
        <div class="form-group">
            <mat-checkbox name="is_important" value="1"
             [(ngModel)]="notes.is_important"
                 [checked]="notes.is_important == 1">
                Is this note important?
            </mat-checkbox>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="saveNotes()"
            class="btn btn-success btn-sm pl-4 pr-4 pt-2 pb-2">Save</button>
        <button type="button" class="btn btn-default btn-gray btn-sm" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<ng-template #deleteModel let-modal>
    <div class="modal-body">
        <p>Are you sure you want to delete note?</p>
    </div>
    
    <div class="modal-footer">
        <button type="button" name="ok"  (click)="deleteNotes(notes.id,selectedRow)"
            class="btn btn-success btn-sm pl-4 pr-4 pt-2 pb-2">OK</button>
        <button type="button" class="btn btn-default btn-gray btn-sm" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<ng-template #notesViewModel let-modal>
    <div class="modal-body">
        {{ notesText }}
    </div>
</ng-template>