<div [formGroup]="qdfrm">
    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title *ngIf="QuoteDetails.livestock_meta.type_of_policy == 3">
                    <!-- About the Sale -->
                    Date of Sale
                </mat-panel-title>
                <mat-panel-title *ngIf="QuoteDetails.livestock_meta.type_of_policy == 4">
                    <!-- About the Sale -->
                    Date of Purchase
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-md-6" *ngIf="QuoteDetails.livestock_meta.type_of_policy < 3">
                    <div class="form-group">
                        <input type="text" name="sale_location" placeholder="Sale Location"
                            class="form-control form-control-sm" formControlName="sale_location">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" readonly name="sale_date" formControlName="sale_date"
                            [placeholder]="(QuoteDetails.livestock_meta.type_of_policy==4) ? 'Purchase Date': 'Sale Date'"
                            class="form-control form-control-sm datepicker" matInput (focus)="sdDatepicker.open()"
                            [matDatepicker]="sdDatepicker">
                        <mat-datepicker-toggle [for]="sdDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #sdDatepicker></mat-datepicker>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    About the Claims History
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Claims</mat-label>
                        <mat-select formControlName="claims" class="form-control form-control-sm" name="claims"
                            (selectionChange)="onClaimsChange($event)" required
                            [ngClass]="{ 'is-invalid': (frmCtrl.claims.touched || submitted) && frmCtrl.claims.errors?.required }">
                            <mat-option *ngFor="let claim of allClaims" [value]="claim.number_of_claims">
                                {{ claim.number_of_claims + ((claim.number_of_claims < 2)?' Claim':' Claims') }}
                                    </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="invalid-feedback" style="font-size: 13px;"
                        *ngIf="(frmCtrl.claims.touched || submitted) && frmCtrl.claims.errors?.required">
                        Please select a claim.
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    <div class="form_sections" *ngIf="frmCtrl.claims && frmCtrl.claims.value > 0">
        <div formArrayName="claim_history" *ngFor="let claim of claimsArr.controls; let i = index;">
            <div [formGroupName]="i">
                <label>Claim {{ i + 1 }}</label>
                <div class=" row">
                    <div class="col-md-4">
                        <mat-form-field class="w-100">
                            <mat-label>Animal Type</mat-label>
                            <mat-select formControlName="animal_type" class="form-control form-control-sm"
                                name="animal_type"  required
                                [ngClass]="{ 'is-invalid': (claim.controls.animal_type.touched || submitted) && claim.controls.animal_type.errors?.required }">
                                <mat-option *ngFor="let type of animalTypes | keyvalue" [value]="type.key">
                                    {{ type.value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="invalid-feedback" style="font-size: 13px;"
                            *ngIf="(claim.controls.animal_type.touched || submitted) && claim.controls.animal_type.errors?.required">
                            Please select a claim.
                        </div>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="w-100">
                            <mat-label>How much was the claim for</mat-label>
                            <mat-select formControlName="claim_for" class="form-control form-control-sm"
                                name="claim_for" required
                                [ngClass]="{ 'is-invalid': (claim.controls.claim_for.touched || submitted) && claim.controls.claim_for.errors?.required }">
                                <mat-option *ngFor="let claim of claimFor | keyvalue" [value]="claim.key">
                                    {{ claim.value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="invalid-feedback" style="font-size: 13px;"
                            *ngIf="(claim.controls.claim_for.touched || submitted) && claim.controls.claim_for.errors?.required">
                            Please select a claim.
                        </div>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="w-100">
                            <mat-label>Cover claimed for</mat-label>
                            <mat-select formControlName="cover_claim_for" class="form-control form-control-sm"
                                name="cover_claim_for" required
                                [ngClass]="{ 'is-invalid': (claim.controls.cover_claim_for.touched || submitted) && claim.controls.cover_claim_for.errors?.required }">
                                <mat-option *ngFor="let claim of coverClaimFor | keyvalue" [value]="claim.key">
                                    {{ claim.value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="invalid-feedback" style="font-size: 13px;"
                            *ngIf="(claim.controls.cover_claim_for.touched || submitted) && claim.controls.cover_claim_for.errors?.required">
                            Please select a claim.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>