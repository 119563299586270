import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { CalculationsDetails } from '../../calculations/calculations.model';
import { QuoteService } from '../../quote.service';
import { UserInfoService } from '../../../services/user-info.service';

@Component({
  selector: 'app-allianz-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['../allianz.component.css'],
})
export class AllianzPaymentComponent implements OnInit {
  @Input() quoteDetails: QuoteVersions;
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() BackToFactStatement = new EventEmitter();
  @Output() UnderWritingSubmission = new EventEmitter();
  indicativeCalculationsDetails = new CalculationsDetails();
  quote: QuoteVersions;

  constructor(private quoteService: QuoteService, private el: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
    //this.quote.indicativePage = false;
    if (this.quoteDetails.calculations != '') {
      this.indicativeCalculationsDetails = this.quoteDetails.calculations;
    }
  }

  ngOnInit(): void {
   
  }

  backToFactStatementBtn() {
    this.BackToFactStatement.emit();
  }

  onSubmit(f: NgForm) {
    let data = {
      quote_id: this.quote.id,
      insurance_status_id: 'f6d14624-6351-4d93-b27b-70fb8a06ec5d',
      convert_to_quote_notes: this.quote.convert_to_quote_notes
    };
  
    this.quoteService.updateQuoteStatus(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteDetailsUpdated.emit(this.quote);
            this.UnderWritingSubmission.emit();
          }
        });

  }

}
