import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedService } from '../services/shared.service';
import { User } from '../model/user/user.model';


 
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private sharedService: SharedService,private httpClient: HttpClient) { }

  // GET LIST OF all user by company id
  getAllUsers(company_id) {
    return this.sharedService.getAPI('users/' + company_id);
  }
//   getalluseres(company_id): Observable<User[]> {
//     return this.sharedService.getAPI('users/' + company_id).pipe(
//        map((data: any[]) => data.map((item: any) => {
//            const model = new User();
//            Object.assign(model, item);
//            if(item.status == 1){
//                model.statusname = 'Active';
//             }else{
//                model.statusname = 'De-Active';
//             }
//            return model;
//         }))
//         );
//  }

  // GET LIST OF user group ROLES
  getGroups() {
    return this.sharedService.getAPI('groups');
  }

  // SAVE User DETAILS
  saveUser(data) {
    return this.sharedService.postAPI('user',data);
  }
  // SAVE user DETAILS
  saveAddressDetails(data) {
    return this.sharedService.postAPI('user/address',data);
  }

  // GET Company DETAILS BY ID 
  getUserByID(id) {
    return this.sharedService.getAPI('user/' + id);
    
  }
}
