<h4>Indicative Quote</h4>
<form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div class="form_sections">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="text-white f13">Policy Start Date</label>
                    <div class="w-100">
                        <input type="text" required name="policy_start_date" placeholder="Policy Start Date"
                            class="form-control form-control-sm datepicker" [min]="minPolicyDate" [max]="maxPolicyDate"
                            [(ngModel)]="quote.quote_versions[0].policy_start_date" #policy_start_date="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && policy_start_date.invalid }" matInput
                            (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker" [disabled]="quote_type == 6">
                        <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                        <div *ngIf="f.submitted && policy_start_date.invalid" class="invalid-feedback">
                            Policy date is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6" *ngIf="!isBroker">
                <mat-form-field class="w-100" style="margin-top: -10px;">
                    <span class="text-white f13">Broker</span>
                    <mat-select [(ngModel)]="quote.quote_versions[0].company_id" class="form-control form-control-sm"
                        name="broker_id" [ngClass]="{ 'is-invalid': f.submitted && broker.invalid }" #broker="ngModel"
                        required (selectionChange)="onChangeBroker($event)">
                        <mat-option *ngFor="let brk of brokers" [value]="brk.id">
                            {{brk.name}}
                        </mat-option>
                    </mat-select>
                    <div *ngIf="f.submitted && broker.invalid" class="invalid-feedback">
                        Broker field is required.
                    </div>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label class="text-white f13">Transaction Type</label>
                <mat-form-field class="w-100" style="margin-top: -10px;">
                    <mat-select [(ngModel)]="quote_type" class="form-control form-control-sm" name="quote_type"
                        [disabled]="quote_type >= 3" (selectionChange)="onQuoteTypeChange($event)">
                        <mat-option [value]="1" [hidden]="quote_type >= 3">New Business</mat-option>
                        <mat-option [value]="2" [hidden]="quote_type >= 3">Renewal</mat-option>
                        <mat-option [value]="3" [hidden]="quote_type != 3">MTA</mat-option>
                        <mat-option [value]="4" [hidden]="quote_type != 4">Cancelled</mat-option>
                        <mat-option [value]="5" [hidden]="quote_type != 5">Lapsed</mat-option>
                        <mat-option [value]="6" [hidden]="quote_type != 6">NTU</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="quote_type == 3 || quote_type == 4">
                <mat-form-field class="w-100 mt-3">
                    <mat-label>Policy Reason</mat-label>
                    <mat-select [(ngModel)]="quote.quote_versions[0].mta_reason_id" class="form-control form-control-sm"
                        name="mta_reason_id" [ngClass]="{ 'is-invalid': f.submitted && mta_reason_id.invalid }"
                        #mta_reason_id="ngModel" required>
                        <mat-option *ngFor="let rsn of policyReasons" [value]="rsn.id">
                            {{rsn.reasons}}
                        </mat-option>
                    </mat-select>
                    <div *ngIf="f.submitted && mta_reason_id.invalid" class="invalid-feedback">
                        Please select a specific reason.
                    </div>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="quote.quote_versions[0].mta_reason_id == '877o3763-6a03-4326-b81c-b14fae1b7422'">
            <div class="col-md-12">
                <div class="form-group">
                    <textarea name="other_reason" placeholder="Please specify reason" class="form-control"
                        [(ngModel)]="quote.quote_versions[0].mta_reason_other"
                        [ngClass]="{ 'is-invalid': f.submitted && other_reason.invalid }" #other_reason="ngModel"
                        required></textarea>
                    <div *ngIf="f.submitted && other_reason.invalid" class="invalid-feedback">
                        Please provide a specific reason.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    About The Proposer
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-group">
                <mat-radio-group class="row" aria-label="Select an option" name="proposer_type"
                    [(ngModel)]="quote.quote_versions[0].policy_holder.proposer_type">
                    <div class="col-md-4">
                        <div class="quote_radio w-90 mb-3">
                            <mat-radio-button [value]="1">Individual</mat-radio-button>
                        </div>
                        <span class="ml-1" (click)="helpTextPopup(content,'A person who is acting for purposes which are outside his trade or profession 
                        e.g. a non-profit making hobby such as animals kept as pets.')"><i
                                class="mdi mdi-comment-question-outline"></i></span>
                    </div>
                    <div class="col-md-4">
                        <div class="quote_radio w-90 mb-3">
                            <mat-radio-button [value]="2">Micro Enterprise</mat-radio-button>
                        </div>
                        <span class="ml-1"
                            (click)="helpTextPopup(content,'Smaller businesses that have a turnover or annual balance sheet of not more than 
                        £2m (two million GBP)  and fewer than ten employees. e.g. smallholdings that include animals or small livestock herds.')"><i
                                class="mdi mdi-comment-question-outline"></i></span>
                    </div>
                    <div class="col-md-4">
                        <div class="quote_radio w-90 mb-3">
                            <mat-radio-button [value]="3">Commercial</mat-radio-button>
                        </div>
                        <span class="ml-1" (click)="helpTextPopup(content,'Livestock related businesses that are run for profit that have a turnover or annual balance sheet of more than £2m (two million GBP) or more than 10 employees.
                        e.g. large livestock herds / livestock herds.')"><i
                                class="mdi mdi-comment-question-outline"></i></span>
                    </div>
                </mat-radio-group>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" *ngIf="quote.quote_versions[0].policy_holder.proposer_type == 1">
                        <input type="text" name="first_name" placeholder="First Name"
                            class="form-control form-control-sm"
                            [(ngModel)]="quote.quote_versions[0].policy_holder.first_name"
                            [ngClass]="{ 'is-invalid': f.submitted && first_name.invalid }" #first_name="ngModel"
                            required>
                        <div *ngIf="f.submitted && first_name.invalid" class="invalid-feedback">
                            First name is required.
                        </div>
                    </div>
                    <div class="form-group" *ngIf="quote.quote_versions[0].policy_holder.proposer_type == 1">
                        <input type="text" name="last_name" placeholder="Last Name" class="form-control form-control-sm"
                            [(ngModel)]="quote.quote_versions[0].policy_holder.last_name">
                    </div>
                    <div class="form-group" *ngIf="quote.quote_versions[0].policy_holder.proposer_type != 1">
                        <input type="text" name="company_name"
                            placeholder="Name of Proposer / Trading Name / Name of Company"
                            class="form-control form-control-sm"
                            [ngClass]="{ 'is-invalid': f.submitted && company_name.invalid }" #company_name="ngModel"
                            required [(ngModel)]="quote.quote_versions[0].policy_holder.company_name">
                        <div *ngIf="f.submitted && company_name.invalid" class="invalid-feedback">
                            Company name is required.
                        </div>
                    </div>
                    <!-- <div class="form-group" *ngIf="quote.quote_versions[0].policy_holder.proposer_type != 1">
                        <input type="text" name="trading_name" placeholder="Trading Name"
                            class="form-control form-control-sm"
                            [(ngModel)]="quote.quote_versions[0].policy_holder.trading_name">
                    </div> -->
                    <div class="form-group">
                        <input type="email" name="email" placeholder="Email Address"
                            class="form-control form-control-sm"
                            [(ngModel)]="quote.quote_versions[0].policy_holder.email"
                            pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                            [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" #email="ngModel" required>
                        <div *ngIf="f.submitted && email.errors && (email.invalid || email.touched)"
                            class="invalid-feedback">
                            <div *ngIf="email.invalid && !email.touched">Email is required.</div>
                            <div *ngIf="email.errors.pattern && email.touched">Please provide a valid email
                                address
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input required type="text" name="phone" placeholder="Contact Number 1"
                            class="form-control form-control-sm" required #phone="ngModel"
                            [(ngModel)]="quote.quote_versions[0].policy_holder.phone">
                        <div *ngIf="f.submitted && phone.invalid" class="invalid-feedback">
                            Mobile number is required.
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" name="mobile" placeholder="Contact Number 2"
                            class="form-control form-control-sm"
                            [(ngModel)]="quote.quote_versions[0].policy_holder.mobile">

                    </div>
                </div>


                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" name="postcode_lookup" placeholder="Postcode"
                            class="form-control form-control-sm"
                            (keyup)="postcode_lookup.errors?'':postcodeLookup($event)"
                            pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                            #postcode_lookup="ngModel" required
                            [(ngModel)]="quote.quote_versions[0].policy_holder.address.postcode"
                            [ngClass]="{ 'is-invalid': f.submitted && postcode_lookup.invalid }"
                            [matAutocomplete]="auto" appUpperCase>
                        <div *ngIf="postcode_lookup.errors && (postcode_lookup.invalid || postcode_lookup.touched)"
                            class="invalid-feedback">
                            <div *ngIf="f.submitted && postcode_lookup.invalid && !postcode_lookup.touched">Postcode is
                                required.</div>
                            <div *ngIf="postcode_lookup.errors?.pattern && postcode_lookup.touched">
                                Invalid postcode.</div>
                        </div>
                        <div>
                            <mat-autocomplete #auto="matAutocomplete">
                                <div
                                    *ngIf="!postcode_lookup.errors && quote.quote_versions[0].policy_holder.address.postcode != ''">
                                    <mat-option [value]="quote.quote_versions[0].policy_holder.address.postcode"
                                        (click)="onSelectAddress(adrs)" *ngFor="let adrs of addresses">
                                        {{adrs.formatted_address.join(', ')}}</mat-option>
                                </div>
                            </mat-autocomplete>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" name="policy_holder_address_line_1" placeholder="Line 1"
                            [(ngModel)]="quote.quote_versions[0].policy_holder.address.address1"
                            class="form-control form-control-sm" appTitleCase
                            [ngClass]="{ 'is-invalid': f.submitted && policy_holder_address_line_1.invalid }" required
                            #policy_holder_address_line_1="ngModel">
                        <div *ngIf="f.submitted && policy_holder_address_line_1.invalid" class="invalid-feedback">
                            Address 1 is required.
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" name="policy_holder_address_line_2" placeholder="Line 2"
                            [(ngModel)]="quote.quote_versions[0].policy_holder.address.address2"
                            class="form-control form-control-sm" appTitleCase>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-6">
                            <input type="text" name="policy_holder_city" placeholder="Town"
                                [(ngModel)]="quote.quote_versions[0].policy_holder.address.city"
                                class="form-control form-control-sm" appTitleCase
                                [ngClass]="{ 'is-invalid': f.submitted && policy_holder_town.invalid }" required
                                #policy_holder_town="ngModel">
                            <div *ngIf="f.submitted && policy_holder_town.invalid" class="invalid-feedback">
                                Town is required.
                            </div>
                        </div>
                        <div class="col-md-6">
                            <input type="text" name="policy_holder_county" placeholder="County"
                                [(ngModel)]="quote.quote_versions[0].policy_holder.address.country"
                                class="form-control form-control-sm" appTitleCase
                                [ngClass]="{ 'is-invalid': f.submitted && policy_holder_country.invalid }" required
                                #policy_holder_country="ngModel">
                            <div *ngIf="f.submitted && policy_holder_country.invalid" class="invalid-feedback">
                                County is required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Is the location of where the animals are kept the same as the proposer's address?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-group">
                <mat-radio-group class="row" aria-label="Select an option" name="animal_address"
                    [(ngModel)]="quote.quote_versions[0].is_animal_location_same_as_proposer" required
                    #animal_address="ngModel" (change)="onAnimalAddressChange($event)">
                    <div class="col-md-2">
                        <div class="quote_radio">
                            <mat-radio-button [value]="1">Yes</mat-radio-button>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="quote_radio">
                            <mat-radio-button [value]="2">No</mat-radio-button>
                        </div>
                    </div>
                    <div *ngIf="f.submitted && animal_address.invalid" class="invalid-feedback col-md-12">
                        Please choose an option.
                    </div>
                </mat-radio-group>
            </div>
            <div *ngIf="quote.quote_versions[0].is_animal_location_same_as_proposer == 2">
                <div *ngFor="let adr of quote.quote_versions[0].animal_address; let idx = index;">
                            <h5 class="text-white">Address {{idx + 1}}
                                <span class="ml-4" *ngIf="idx==0">
                                    <button type="button" class="btn btn-success btn-sm" (click)="addAnimalAddress()">Add More
                                        Addresses</button>
                                    </span>
                            </h5>
                    

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="animal_postcode_{{quote.quote_versions[0].animal_address.length + idx}}" placeholder="Postcode"
                                    class="form-control form-control-sm" required
                                    (keyup)="animal_postcode.errors?'':postcodeLookup($event)"
                                    pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                                    #animal_postcode="ngModel"
                                    [(ngModel)]="adr.postcode"
                                    [ngClass]="{ 'is-invalid': f.submitted && animal_postcode.invalid }"
                                    [matAutocomplete]="autoAnimalAddress" appUpperCase>
                                <div *ngIf="animal_postcode.errors && (animal_postcode.invalid || animal_postcode.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="f.submitted && animal_postcode.invalid && !animal_postcode.touched">
                                        Postcode
                                        is
                                        required.</div>
                                    <div *ngIf="animal_postcode.errors?.pattern && animal_postcode.touched">
                                        Invalid postcode.</div>
                                </div>
                                <div>
                                    <mat-autocomplete #autoAnimalAddress="matAutocomplete">
                                        <div
                                            *ngIf="!animal_postcode.errors && adr.postcode != ''">
                                            <mat-option [value]="adr.postcode"
                                                (click)="onSelectAnimalAddress(adrs,idx)" *ngFor="let adrs of addresses">
                                                {{adrs.formatted_address.join(', ')}}</mat-option>
                                        </div>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="animal_address_line_1_{{quote.quote_versions[0].animal_address.length + idx}}" placeholder="Line 1"
                                    [(ngModel)]="adr.address1"
                                    class="form-control form-control-sm" appTitleCase
                                    [ngClass]="{ 'is-invalid': f.submitted && animal_address_line_1.invalid }" required
                                    #animal_address_line_1="ngModel">
                                <div *ngIf="f.submitted && animal_address_line_1.invalid" class="invalid-feedback">
                                    Address 1 is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="animal_address_line_2_{{quote.quote_versions[0].animal_address.length + idx}}" placeholder="Line 2"
                                    [(ngModel)]="adr.address2"
                                    class="form-control form-control-sm" appTitleCase>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="animal_city_{{quote.quote_versions[0].animal_address.length + idx}}" placeholder="Town"
                                    [(ngModel)]="adr.city"
                                    class="form-control form-control-sm" appTitleCase
                                    [ngClass]="{ 'is-invalid': f.submitted && animal_town.invalid }" required
                                    #animal_town="ngModel">
                                <div *ngIf="f.submitted && animal_town.invalid" class="invalid-feedback">
                                    Town is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="animal_county_{{quote.quote_versions[0].animal_address.length + idx}}" placeholder="County"
                                    [(ngModel)]="adr.country"
                                    class="form-control form-control-sm" appTitleCase
                                    [ngClass]="{ 'is-invalid': f.submitted && animal_country.invalid }" required
                                    #animal_country="ngModel">
                                <div *ngIf="f.submitted && animal_country.invalid" class="invalid-feedback">
                                    County is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="idx>0">
                        <button type="button" class="btn btn-danger btn-sm"
                            (click)="removeAnimalAddress(idx)">Remove</button>
                    </div>
                    
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    About Your Policy Cover
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-7 text-white mt-3 f13">
                            Alpaca / Llama Mortality & Limited Theft Cover
                            <span (click)="helpTextPopup(content,'Cover for death of the animal from an accident, illness or disease, excluding Government compulsory slaughter or culling. <br/> <b> Limited Theft </b> </br>
                            Cover for theft, excluding mysterious disappearance.')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <mat-radio-group aria-label="Select an option" name="alpaca_theft_cover"
                                    class="quote_radio" [(ngModel)]="quote.quote_versions[0].alpaca_theft_cover"
                                    required #alpacaTheftCover="ngModel"
                                    (change)="onAlpacaLlamaTheftCoverChange($event)">
                                    <div class=" row mb-3">
                                        <div class="col-md-6">
                                            <mat-radio-button [value]="1" name="alpaca_theft_cover" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_theft_cover" required
                                                #alpacaTheftCoverControl="ngModel">Yes</mat-radio-button>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-radio-button [value]="2" name="alpaca_theft_cover" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_theft_cover" required
                                                #alpacaTheftCoverControl="ngModel">No</mat-radio-button>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && alpacaTheftCoverControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please choose an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-7 text-white f13">
                            Public, Product & Employers Liability
                            <span (click)="helpTextPopup(content,'<b>Public Liability </b> <br/>
                                Protection against your legal liability for bodily injury to third parties and damage to their property, including obstruction, trespass, nuisance, interference, wrongful arrest and eviction. 
                                Excess £250 any one Occurrence applicable to Damage and Defence costs
                                <br/>
                                
                                <b>Products Liability</b> <br/>
                                Legal liability for damages and costs following injury or damage by goods supplied, sold, repaired, tested or maintained.
                                Excess £250 any one Occurrence and in the aggregate applicable to Damage and Defence costs.
                                <br/>
                                <b>Employers’ Liability</b> <br/>
                                Protection against your legal liability for bodily injury to your employees up to a limit of indemnity of £10,000,000 including costs and expenses
                               <br/> No Excess.
                                
                                ')"><i class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group quote_radio">
                                <mat-radio-group aria-label="Select an option" name="public_liability"
                                    [(ngModel)]="quote.quote_versions[0].alpaca_public_liability_cover" required
                                    #publicLiability="ngModel" (change)="onPublicLiabilityChange($event)">
                                    <div class="quote_radio row mb-3">
                                        <div class="col-md-6">
                                            <mat-radio-button [value]="1" name="public_liability" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_public_liability_cover"
                                                required #publicLiabilityControl="ngModel">Yes</mat-radio-button>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-radio-button [value]="2" name="public_liability" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_public_liability_cover"
                                                required #publicLiabilityControl="ngModel">No</mat-radio-button>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && publicLiabilityControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please choose an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="quote.quote_versions[0].alpaca_public_liability_cover == 1">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-7 text-white f13">
                            Trailer Cover
                            <span
                                (click)="helpTextPopup(content,'To pay the current market value, sum insured, or cost of repairs (whichever is the lesser amount) for loss or damage resulting from fire, theft or accidental damage.')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group quote_radio">
                                <mat-radio-group aria-label="Select an option" name="trailer_cover"
                                    [(ngModel)]="quote.quote_versions[0].alpaca_trailer_cover" required
                                    #TrailerCover="ngModel">
                                    <div class="quote_radio row mb-3">
                                        <div class="col-md-6">
                                            <mat-radio-button [value]="1" name="trailer_cover" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_trailer_cover" required
                                                #TrailerCoverControl="ngModel">Yes</mat-radio-button>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-radio-button [value]="2" name="trailer_cover" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_trailer_cover" required
                                                #TrailerCoverControl="ngModel">No</mat-radio-button>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && TrailerCoverControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please choose an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    <div class="form-group">
        <button type="submit" class="btn btn-success btn-sm float-right mb-5"
            [disabled]="quote.quote_versions[0].alpaca_theft_cover == 2 && quote.quote_versions[0].alpaca_public_liability_cover == 2">Proceed
            with Quote</button>

    </div>
</form>

<ng-template #content let-modal>
    <div class="modal-body">
        <div [innerHTML]="helptext"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>