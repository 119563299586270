import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Quote } from '../../../../model/quote/quote.model';
import { LocalStorageService } from 'ngx-webstorage';
import { formatDate, TitleCasePipe } from '@angular/common';
import { GridOptions } from "ag-grid-community";
import { QuoteService } from '../../../quote.service';
import { ActivatedRoute, Router } from "@angular/router";
import { PolicyService } from '../../../../policy/policy.service';
import { UserInfoService } from '../../../../services/user-info.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-allianz-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class AllianzOverviewComponent implements OnInit {
  @Output() quoteDetailsUpdated = new EventEmitter<Quote>();
  @Output() hasImportantNotes = new EventEmitter();
  @Output() staticData = new EventEmitter();
  quote_end_date: string = '';
  quote;
  quote_type;
  @Input() set quoteData(value: any) {
    if (value) {
      this.quote = value;
      if (value.versions) {
        value.versions.forEach((res) => {
          res.first_name = this.titlecasePipe.transform(res.first_name);
          res.last_name = this.titlecasePipe.transform(res.last_name);
        })
      }
    }
  }


  constructor(private sessionSt: LocalStorageService, private quoteService: QuoteService, private router: Router,
    private route: ActivatedRoute, private policyService: PolicyService, private titlecasePipe: TitleCasePipe, private userService: UserInfoService) { }


  minPolicyDate;
  maxPolicyDate;
  underwriters;
  insurers;
  selectedUnderwriterID = this.sessionSt.retrieve('userInfo').id;
  policyReason: string = '';
  policyStatuses;

  ngOnInit(): void {

    // API - GET UNDERWRITERS
    this.quoteService.getUnderwriters()
      .subscribe(
        (response: any) => {
          response.result.forEach((res, index) => {
            this.underwriters = res;
          });

        });

    // API - GET INSURERS
    this.quoteService.getInsurers()
      .subscribe(
        (response: any) => {
          this.insurers = response.result;
        });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.quote) {
      if (this.quote.is_policy == 0) {
        this.getPolicyStatus(0);
      } else {
        this.getPolicyStatus(1);
      }

      this.getQuoteTypes();

      this.getPolicyReasons();
    }
  }

  quoteVersionsUpdated(qudatedQuote) {
    this.quote = qudatedQuote;
    this.quoteDetailsUpdated.emit(qudatedQuote);
    this.getPolicyReasons();
    if (qudatedQuote.is_policy == 0) {
      this.getPolicyStatus(0);
    } else {
      this.getPolicyStatus(1);
    }
    this.staticData.emit(qudatedQuote.rating_version);
    this.transction_type = '';
    this.getQuoteTypes();
    this.getPolicyReasons();
  }
  transction_type = '';
  getQuoteTypes() {
    if (!this.transction_type) {
      let type = 0;
      if (this.quote.is_policy == 1) {
        type = 1;
      }
      // Get Quote Types
      this.quoteService.getQuoteTypes(type)
        .subscribe(
          (response: any) => {
            if (response.success && this.quote.id) {
              this.transction_type = response.result.types[this.quote.type];
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  onhasImportantNotesChange(isImportant) {
    this.hasImportantNotes.emit(isImportant);
  }

  /*
  * Fetch Policy Reasons
  */
  policyReasonsList = [];
  getPolicyReasons() {
    //if (!this.policyReasonsList) {
    this.policyService.getPolicyMTAReasons(environment.ALLIANZ_EQUINE_ID)
      .subscribe(
        (response: any) => {
          if (response.success) {
            if (response.result.types) {
              this.policyReasonsList = response.result.types;
              let reasons = response.result.types.filter(x => x.id == this.quote.mta_reason_id);
              if (reasons.length > 0) {
                this.policyReason = reasons[0].reasons;
              }
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }


  //}

  /*
  * Policy Status Based on Quote/Policy
  * Type => 0: Quote, 1: Policy
  */
  getPolicyStatus(type) {
    this.policyService.getPolicyStatuses()
      .subscribe(
        (response: any) => {
          if (response.success) {
            if (type == 0) {
              this.policyStatuses = response.result.quote_insurance_status;
            } else {
              this.policyStatuses = response.result.policy_insurance_status;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }


  /*
  * View Policy Details
  */
  policyDetails() {
    let quote_id;
    let quote_versions = this.quote.versions.filter(x => x.is_policy == 1);
    if (quote_versions.length > 0) {
      quote_id = quote_versions[0].quote_id;
    }
    this.router.navigate(['allianz-equine/policy', quote_id]);
  }
  /*
* View Quote Details
*/
  quoteDetails() {
    this.sessionSt.store('policy_meta_id', this.quote.id);
    this.sessionSt.store('quote_ref_id', this.quote.id);
    this.router.navigate(['allianz-equine/quote-details', this.quote.id]);
  }
}
