export class AnimalType {
    id: string = '';
    is_alpaca: number = null;
    is_sheep: number = null;
    is_poultry: number = null;       
    is_pig: number = null;       
    is_cattle: number = null;       
    is_horse: number = null;       
    is_other: number = null;       
    other: string = '';       
}
