import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { GridOptions } from "ag-grid-community";
import { formatDate, TitleCasePipe } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteService } from '../quote/quote.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteVersions } from '../model/quote/quote_versions.model';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-quote-versions',
  templateUrl: './quote-versions.component.html',
  styleUrls: ['./quote-versions.component.css']
})
export class QuoteVersionsComponent implements OnInit {
  @Output() quoteVersionsUpdated = new EventEmitter<QuoteVersions>();
  @Output() hasImportantNotes = new EventEmitter();
  @Input() quote = new QuoteVersions();
  @Input() is_policy;
  //quote = new QuoteVersions();
  isAdmin: boolean = false;
  isBroker: boolean = false;

  constructor(private sessionSt: LocalStorageService, private quoteService: QuoteService,
    private modalService: NgbModal, private _snackBar: MatSnackBar) { }

  ngOnChanges(changes: SimpleChanges): void {
    //this.rowData = this.quoteVersions;
    this.rowData = this.quote.versions;
  }
  ngOnInit(): void {
    if (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') {
      this.isAdmin = true;
    }
    if (this.sessionSt.retrieve('is_broker')) {
      this.isBroker = true;
    }
  }

  gridOptions: GridOptions = {
    onGridReady: () => {
      if (this.gridOptions.columnApi) {
        this.gridOptions.columnApi.setColumnsVisible(['bdx_opt_in'], this.isAdmin && (this.is_policy == 1) ? true : false);
        this.gridOptions.columnApi.setColumnsVisible(['policy_ref_number'], (this.is_policy == 1) ? true : false);
        this.gridOptions.columnApi.setColumnsVisible(['policy_converted_by'], (this.is_policy == 1) ? true : false);
        this.gridOptions.columnApi.setColumnsVisible(['is_paid'], (this.is_policy == 1 && !this.isBroker) ? true : false);
        this.gridOptions.columnApi.setColumnsVisible(['quote_ref_number'], (this.is_policy == 0) ? true : false);
        this.gridOptions.columnApi.setColumnsVisible(['agent_first_name'], (this.is_policy == 0) ? true : false);
        this.gridOptions.columnApi.setColumnsVisible(['original_quote_status'], (this.is_policy == 0) ? true : false);
        this.gridOptions.columnApi.setColumnsVisible(['ipt_fee'], (this.is_policy == 0) ? true : false);
      }
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Inception Date', field: 'policy_start_date', sortable: true, filter: false, width: 230,
      cellRenderer: function (params) {
        return formatDate(params.data.policy_start_date, 'dd-MM-yyyy', 'en-US');
      }
    },
    {
      headerName: 'Policy No.', field: 'policy_ref_number', sortable: true, width: 250,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.policy_ref_number) {
          links = '<a title="View Details" data-action-type="view" id="' + params.data.quote_id + '">' + params.data.policy_ref_number + '</a>';
        }
        return links;
      }
    },
    {
      headerName: 'Quote Number', field: 'quote_ref_number', sortable: true, width: 250,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.quote_ref_number) {
          links = '<a title="View Details" data-action-type="view" id="' + params.data.quote_id + '">' + params.data.quote_ref_number + '</a>';
        }
        return links;
      }
    },
    { headerName: 'Ver. No.', field: 'version', sortable: true, width: 100 },
    {
      headerName: 'Customer Name', field: 'first_name', sortable: true, width: 280,
      cellRenderer: function (params) {
        return params.data.customer_name;
      },
      tooltip: function (params) {
        return params.data.customer_name;
      },
    },
    {
      headerName: 'Broker Company', field: 'broker_name', sortable: true, width: 300,
      hide: (this.sessionSt.retrieve('company_role') == 'Broker') ? true : false,
      tooltip: function (params) {
        return params.data.broker_name;
      },
    },
    {
      headerName: 'Agent', field: 'policy_converted_by', sortable: true, width: 280,
      cellRenderer: function (params) {
        return params.data.policy_converted_by;
      },
      tooltip: function (params) {
        return params.data.policy_converted_by;
      },
    },
    {
      headerName: 'Agent', field: 'agent_first_name', sortable: true, width: 280,
      cellRenderer: function (params) {
        if (params.data.insurance_status_id == '80c0db90-ba5d-4387-b09a-4c008a664932') {
          return params.data.quote_converted_by;
        } else if (params.data.is_policy == 1) {
          return params.data.quote_converted_by;
        } else {
          return params.data.agent_first_name + ' ' + params.data.agent_last_name;
        }
      },
      tooltip: function (params) {
        if (params.data.insurance_status_id == '80c0db90-ba5d-4387-b09a-4c008a664932') {
          return params.data.quote_converted_by;
        } else if (params.data.is_policy == 1) {
          return params.data.quote_converted_by;
        } else {
          return params.data.agent_first_name + ' ' + params.data.agent_last_name;
        }
      },
    },
    {
      headerName: 'Type', field: 'quote_type', sortable: true,
      tooltip: function (params) {
        return params.data.quote_type;
      }
    },
    {
      headerName: 'Status', field: 'original_quote_status', sortable: true, width: 280,
      cellRenderer: function (params) {
        return params.data.original_quote_status;
      },
      tooltip: function (params) {
        return params.data.original_quote_status;
      },
    },
    {
      headerName: 'Is Paid', field: 'is_paid', sortable: true, width: 250,
      cellRenderer: function (params) {
        if (params.data.is_paid == 1) {
          return '<span class="badge bg-primary">Paid</span>';
        } else {
          return '<span class="badge bg-danger text-black">Not paid</span>';
        }
      }
    },
    {
      headerName: 'Gross Premium', field: 'policy_premium', sortable: true,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.policy_premium != null) {
          amount = params.data.policy_premium;
        }
        return '&pound;' + amount;
      },
      tooltip: function (params) {
        return params.data.policy_premium;
      },
    },
    {
      headerName: 'IPT', field: 'ipt_fee', sortable: true,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.ipt_fee != null) {
          amount = params.data.ipt_fee;
        }
        return amount + '%';
      },
      tooltip: function (params) {
        return params.data.ipt_fee + '%';
      },
    },
    {
      headerName: 'Fees', field: 'fees', sortable: true,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.fees != null) {
          amount = params.data.fees;
        }
        return '&pound;' + amount;
      },
      tooltip: function (params) {
        return params.data.fees;
      },
    },
    {
      headerName: 'Total Premium', field: 'total_policy_cost', sortable: true,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.total_policy_cost != null) {
          amount = params.data.total_policy_cost;
        }
        return '&pound;' + amount;
      },
      tooltip: function (params) {
        return params.data.total_policy_cost;
      },
    },
    {
      headerName: 'BDX Opt-in', field: 'bdx_opt_in', width: 200, type: 'rightAligned',
      cellRenderer: function (params) {
        let links = '';
        if (params.data.bdx_opt_in == 0) {
          links = '<a title ="Opt In" style="font-size: 18px;" class="text-primary"><i class="mdi mdi-alert-box" data-action-type="opt_in_out" bdx_opt_option="1" id="' + params.data.quote_id + '"></i></a>';
        } else {
          links = '<a title ="Opt Out" style="font-size: 18px;" class="text-danger"><i class="mdi mdi-alert-box" data-action-type="opt_in_out" bdx_opt_option="0" id="' + params.data.quote_id + '"></i></a>';
        }
        return links;
      }
    }
  ];
  rowData: any = [];

  /*
  * GRID BUTTONS ACTION
  */
  selectedRowQuoteID;
  onGridRowClicked(e: any, opt_in_out_modal) {
    if (e) {
      let quote_id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      this.bdx_opt_option = e.event.target.getAttribute("bdx_opt_option");
      switch (actionType) {
        case "view":
          {
            this.getPolicyData(quote_id);
            break;
          }
        case "opt_in_out":
          {
            this.opt_in_out_notes = '';
            this.modalService.open(opt_in_out_modal, { centered: true });
            this.selectedRowQuoteID = quote_id;
            break;
          }
      }
    }
  }

  // GET POLICY DETAILS
  getPolicyData(quote_id) {
    this.quoteService.getQuoteDetailsByQuoteID(quote_id, this.is_policy)
      .subscribe(
        (response: any) => {
          this.quote = response.result;
          this.quote.financial_details.broker_commission = Number(this.quote.financial_details.broker_commission);
          this.quote.financial_details.peliwica_pay_away_commission = Number(this.quote.financial_details.peliwica_pay_away_commission)
          this.quote.financial_details.peliwica_retained_commission = Number(this.quote.financial_details.peliwica_retained_commission);
          this.quote.financial_details.policy_premium = Number(this.quote.financial_details.policy_premium);
          this.quoteService.getNotes(quote_id)
            .subscribe(
              (response: any) => {
                let impNotes = response.result.filter(x => x.is_important == 1);
                if (impNotes.length > 0) {
                  this.hasImportantNotes.emit(true);
                } else {
                  this.hasImportantNotes.emit(false);
                }
              });


          this.quoteVersionsUpdated.emit(this.quote);
        });
  }

  // SUBMIT OPT IN OUT
  opt_in_out_notes;
  bdx_opt_option;
  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      return false;
    }
    let data: any = '';
    let optInOutPrefix = (this.bdx_opt_option == 1) ? 'Opt In' : 'Opt Out';
    data = {
      quote_id: this.selectedRowQuoteID,
      notes: optInOutPrefix + ' - ' + this.opt_in_out_notes,
      bdx_opt_option: this.bdx_opt_option
    };
    this.quoteService.bdxOptIn(data)
      .subscribe(
        (response: any) => {
          if (this.selectedRowQuoteID == this.quote.id) {
            this.quote.notes = response.notes;
          }
          let newRowData = this.rowData.map((row, index) => {
            if (this.selectedRowQuoteID == row.quote_id) {
              row.bdx_opt_in = this.bdx_opt_option;
            }
            return row;
          });
          this.gridOptions.api.setRowData(newRowData);


          this.modalService.dismissAll();

          if (response.success) {
            this._snackBar.open(response.message, '', {
              duration: 5000
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }


}
