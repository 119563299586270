import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { AlCalculationsDetails } from './al-calculations.model';
import { Quote } from '../../model/quote/quote.model';

@Component({
  selector: 'app-admin-al-calculations',
  templateUrl: './al-calculations.component.html',
  styleUrls: ['../../../quote.component.css']
})
export class AdminAlCalculationsComponent implements OnInit {
  @Input() Calculations:AlCalculationsDetails;
  @Input() quoteDetails: Quote;
  calculationsDe = new AlCalculationsDetails();
  quote = new Quote();

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.calculationsDe = this.Calculations;
   this.quote = this.quoteDetails;  
    
  }
  

  ngOnInit(): void {
   
  }

}
