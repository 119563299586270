export class Email {
    subject: string = '';    
    sender_name: string = '';    
    sender_email: string = '';    
    created_at: string = '';    
    email_to: string = '';    
    email_cc: string = '';    
    email_bcc: string = '';    
    body: string = '';    
    attachments: string = '';    
}
