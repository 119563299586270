import { Injectable } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../environments/environment';
import { Environment } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class AJGQuoteService {

  constructor(private sharedService: SharedService, private sessionSt: LocalStorageService) { }

  // Convert To Plolicy Allianz
  convertToPolicy(data) {
    return this.sharedService.postAPI('ajg/quote/convert_to_policy', data);
  }
}

