<a (click)="compliance_assist(messagepopup)" class="btn btn-success btn-sm">
    Compliance Assist
</a>
<ag-grid-angular style="width: 100%; height: 300px;" class="ag-theme-bootstrap mt-3" [gridOptions]="gridOptions"
    [rowData]="rowData" [columnDefs]="columnDefs" animateRows pagination paginationPageSize=10 rowHeight=27
    headerHeight=70>

    <ng-template #messagepopup let-modal>
        <div class="modal-body">
            <p>{{msg}}</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
        </div>
    </ng-template>