import { Injectable } from '@angular/core';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  
  constructor(private sharedService: SharedService) { }
  
   // GET MI Data
   getMIData(data){
    return this.sharedService.postAPI('mi/dashboard', data);
  }

}
