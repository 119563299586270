import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { QuoteService } from '../../quote/quote.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { UserInfoService } from '../../services/user-info.service';

@Component({
  selector: 'app-quote-view-sof',
  templateUrl: './quote-view-sof.component.html',
  styleUrls: ['../../quote/quote.component.css']
})
export class QuoteViewSofComponent implements OnInit {
  @Input() quote: QuoteVersions;
  @Input() productId;
  statementOfFacts = [];


  constructor(private quoteService: QuoteService, private modalService: NgbModal, private userService: UserInfoService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.quote.statement_of_facts.length > 0) {
      if (this.statementOfFacts.length > 0) {
        this.quote.statement_of_facts.forEach((res, index) => {
          if (res.response == 0) {
            res.response = null;
          }
        });
      }

      this.initializeSOFForNewQuest();
    }

    // Get All Questions
    this.getSOF();


  }

  ngOnInit(): void {

  }

  // Get SOF Questions
  getSOF() {
    if(this.quote.product_id && this.quote.sof_version) {
    this.quoteService.getStatementOfFacts(this.quote.product_id, this.quote.sof_version)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.statementOfFacts = response.result.statement_of_fact;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  initializeSOFForNewQuest() {
    if (this.quote.statement_of_facts.length != this.statementOfFacts.length) {
      this.statementOfFacts.forEach((res, index) => {
        let sof = this.quote.statement_of_facts.filter(x => x.question_id == res.id);
        if (sof.length == 0) {
          this.quote.statement_of_facts.push({
            id: '',
            quote_id: '',
            question_id: res.id,
            response: null,
            response_bool: false,
            response_text: '',
            display_order: res.display_order
          });
        }
      });
    }
  }

  /*
  * HELP TEXT POPUP
  */
  helptext = '';
  helpTextPopup(content, text) {
    const modalRef = this.modalService.open(content, { centered: true });
    this.helptext = text;
  }


}
