    <mat-accordion>
        <div class="form_sections" *ngFor="let fleet of quoteApplicationData.fleet_experiences; let i = index">
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h4>
                            <u>
                                Year {{ i + 1 }}
                            </u>
                        </h4>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group w-100">
                            <label class="text-white">Start Date</label>
                            <input type="text" name="start_date" [value]="fleet.start_date | date: 'dd-MM-yyyy'" readonly
                                placeholder="Start Date" class="form-control form-control-sm">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group w-100">
                            <label class="text-white">End Date</label>
                            <input type="text" name="end_date" [value]="fleet.end_date | date: 'dd-MM-yyyy'" readonly
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="text-white">Vehicle Years</label>
                            <input type="number" name="years" [value]="fleet.years" readonly
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="text-white">Number of Reported Claims</label>
                        <div class="form-group"> 
                            <input type="number" name="claims" [value]="fleet.claims" readonly
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>

                <div class="green_border_panel text-white mb-3" *ngIf="fleet.claims > 0">
                    <div class="row">
                        <div class="col-md-2">
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">Own Damage</div>
                                <div class="col-md-3">Fire & Theft</div>
                                <div class="col-md-3">Third Party</div>
                                <div class="col-md-3">Total</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="fleet.paid">
                        <div class="col-md-2">Paid</div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" appDecimalNumbers
                                            name="own_damage" [value]="fleet.paid.own_damage | number:'1.2-2'" readonly>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" appDecimalNumbers
                                            name="fire_theft" [value]="fleet.paid.fire_theft | number:'1.2-2'" readonly>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" appDecimalNumbers
                                            name="third_party" [value]="fleet.paid.third_party | number:'1.2-2'" readonly>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" readonly name="total"
                                            [value]="fleet.paid.own_damage + fleet.paid.fire_theft + fleet.paid.third_party | number:'1.2-2'">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="fleet.outstanding">
                        <div class="col-md-2">Outstanding</div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" appDecimalNumbers
                                            name="own_damage" [value]="fleet.outstanding.own_damage | number:'1.2-2'">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" appDecimalNumbers
                                            name="fire_theft" [value]="fleet.outstanding.fire_theft | number:'1.2-2'">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" appDecimalNumbers
                                            required name="third_party" [value]="fleet.outstanding.third_party | number:'1.2-2'">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" readonly name="total"
                                            [value]="fleet.outstanding.own_damage + fleet.outstanding.fire_theft + fleet.outstanding.third_party | number:'1.2-2'">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-2">Total</div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" readonly name="total_own_damage" 
                                            [value]="fleet.paid.own_damage + fleet.outstanding.own_damage | number:'1.2-2'">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" readonly name="total_fire_theft" 
                                            [value]="fleet.paid.fire_theft + fleet.outstanding.fire_theft | number:'1.2-2'">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" readonly name="total_fire_theft" 
                                            [value]="fleet.paid.third_party + fleet.outstanding.third_party | number:'1.2-2'">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" readonly name="total_fire_theft" 
                                            [value]="fleet.paid.own_damage + fleet.outstanding.own_damage | number:'1.2-2'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="form-group">
                    <textarea name="details"
                        placeholder="Please provide details of any individual large or unusual loss in year {{ i+1 }}"
                        class="form-control" [value]="fleet.details"></textarea>
                </div>
            </mat-expansion-panel>
        </div>

    </mat-accordion>