import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
import { SharedService } from '../../services/shared.service';
import { environment } from '../../../environments/environment';
declare const sidebar_submenu: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  UserType;
  userInfo = this.sessionSt.retrieve('userInfo');
  isEquine: boolean = false;
  isAlpaca: boolean = false;
  isLivestock: boolean = false;
  isAllianz: boolean = false;
  isAJG: boolean = false;
  isAmline: boolean = false;
  company_id;
  isAdmin: boolean = false;
  isBroker: boolean = false;
  isStaff: boolean = false;
  companyProducts = this.sessionSt.retrieve('companyProducts');

  constructor(private router: Router, private sessionSt: LocalStorageService,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.UserType = this.sharedService.getUserType();
    if (this.sessionSt.retrieve('is_broker')) {
      this.isBroker = true;
    }
    if (this.sessionSt.retrieve('is_staff')) {
      this.isStaff = true;
    }
    this.company_id = this.sessionSt.retrieve('broker_company_id');
    if (this.sessionSt.retrieve('companyProducts')) {
      if (this.UserType == 2) {
        this.isEquine = true;
        this.isAlpaca = true;
        this.isLivestock = true;
        this.isAllianz = true;
        this.isAJG = true;
        this.isAmline = true;
      } else {
        this.sessionSt.retrieve('companyProducts').forEach((res) => {
          if (res.product_id == environment.EQUINE_ID) {
            this.isEquine = true;
          } else if (res.product_id == environment.ALPACA_ID) {
            this.isAlpaca = true;
          } else if (res.product_id == environment.LIVESTOCK_ID) {
            this.isLivestock = true;
          } else if (res.product_id == environment.ALLIANZ_EQUINE_ID) {
            if (this.sessionSt.retrieve('is_broker')) {
              this.isAllianz = false;
            } else {
              this.isAllianz = true;
            }
          } else if (res.product_id == environment.AJG_ID) {
            this.isAJG = true;
          } else if (res.product_id == environment.AMLINE_ID) {
            this.isAmline = true;
          }
        })

      }
    }

    if (this.sessionSt.retrieve('is_admin')) {
      this.isAdmin = true;
    }

  }

  submenu(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    const classAttr = target.attributes.class;
    const idAttr = target.attributes.id;
    sidebar_submenu(idAttr.nodeValue);
  }

}
