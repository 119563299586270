export class Claim {
    id: string = '';    
    quote_id: string = '';    
    fnol_date: string = '';    
    loss_date: string = '';   
    claim_number: string = '';   
    claim_type: number = null;
    status: number = null;
    payment_amount: number = null;   
    notes: string = '';   
    legacy_claim_number: string = '';   
}
