<div class="card-header bg-success">
    <div class="row">
        <div class="col-md-6">
            <h3>Live Policy Report</h3>
        </div>
    </div>
</div>
<div class="card-body">
    <h4 class="text-white">Please click on the below link to get the live policy report.</h4>

    <button class="btn btn-success btn-sm" (click)="export_report()">Export</button>
</div>