import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  

  constructor(private httpClient: HttpClient,private sharedService: SharedService) { }

  

  requestpassword(data){
    return this.sharedService.postAPI('requestpassword',data);
  }

  resetpassword(data){
    return this.sharedService.postAPI('resetpassword',data);
    // let url = this.sharedService.apiURL() + 'resetpassword';
    // const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    // return this.httpClient.post(url, JSON.stringify(data),{headers: headers})
  }
}
