import { Component, OnInit, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { Email } from '../email.model';
import { NgForm } from '@angular/forms';
import { QuoteService } from '../../quote/quote.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { QuoteVersions } from '../../model/quote/quote_versions.model';

@Component({
  selector: 'app-quote-email-compose',
  templateUrl: './quote-email-compose.component.html',
  styleUrls: ['./quote-email-compose.component.css']
})
export class QuoteEmailComposeComponent implements OnInit {
  @Input() quote: QuoteVersions;
  emailDetails = new Email();
  TINY_MCE_KEY = environment.TINY_MCE_KEY;

  @Output() backToEmailsList = new EventEmitter<any>();

  constructor(private el: ElementRef, private quoteService: QuoteService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  returnToEmailsList() {
    this.backToEmailsList.emit();
  }

  // UPLOAd DOCUMENT
  fileToUpload: File = null;
  files: string[] = [];
  getUploadedFile(files) {
    this.fileToUpload = files;
    let filename = [];
    for (let i = 0; i < files.length; i++) {
      filename.push(files.item(i).name);
      this.files.push(files[i]);
    }
    let docTitles = filename.join(',');

    this.emailDetails.attachments = docTitles;
  }

  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      for (const key of Object.keys(f.controls)) {
        if (f.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('input[name=' + key + '], mat-select[name=' + key + ']');
          invalidControl.focus();
          invalidControl.scrollIntoView({ behavior: 'smooth' });
          break;
        }
      }

      return false;
    }
    let formData = new FormData();
    formData.append("to", this.emailDetails.email_to);
    formData.append("email_cc", this.emailDetails.email_cc);
    formData.append("email_bcc", this.emailDetails.email_bcc);
    formData.append("object_id", this.quote.id);
    formData.append("subject", this.emailDetails.subject);
    for (var i = 0; i < this.files.length; i++) {
      formData.append("file_name[]", this.files[i]);
    }
    //formData.append("file_name", this.fileToUpload);
    formData.append("body", this.emailDetails.body);

    this.quoteService.sendEmail(formData)
      .subscribe(
        (response: any) => {
          if (response.success) {
            f.resetForm();
            this.backToEmailsList.emit();
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

}
