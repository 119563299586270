import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteEndorsement } from '../../model/quote/quote_endorsement.model';
import { QuoteService } from '../../quote/quote.service';
import { CalculationsDetails } from '../../quote/calculations/calculations.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { NgForm } from '@angular/forms';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { HorseSubClass } from '../../model/quote/horse_sub_class.model';
import { UserInfoService } from '../../services/user-info.service';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-policy-full-details',
  templateUrl: './policy-full-details.component.html',
  styleUrls: ['../../quote/quote.component.css'],
})
export class PolicyFullDetailsComponent implements OnInit {
  @Input() quote: QuoteVersions;
  endorsement = new QuoteEndorsement();
  horseAge;
  calculationDetails = new CalculationsDetails();
  selected_full_vet_fee_amount = 0;
  today = new Date();

  classOfUse;
  policyCover = [];
  bloodStocks;
  publicLiability;
  accidentCover;
  policyDiscount = [];
  vetFeesCover = [];
  vetFeesCoverBloodStock = [];
  vetFeeCoverSpecial = [];
  vetFeeExcess = [];
  saddlery_tack_items;
  BloodStockDetails = false;
  editType = 'fullRow';
  horseSubClasses = [];
  quoteDetails;
  horseSubClass: string = '';
  horseHeight;
  horseBreed;
  horseColor;
  titleList:[];
  titleName:string;
  

  // STATIC DATA
  @Input()
  set staticData(value: any) {
    if (value) {
      //Class Of Use
      this.classOfUse = value.class_of_use;

      if (this.quote) {
        let class_of_use = value.class_of_use.filter(x => x.id == this.quote.horse_class_id);
        if (class_of_use.length > 0) {
          if (this.horseSubClass == '') {
            setInterval(() => {
              let subclass = class_of_use[0].sub_classes.filter(x => x.id == this.quote.horse_class_sub_id);
              if (subclass.length > 0) {
                this.horseSubClass = subclass[0].name;
              }
            }, 1000);
          }
          if (class_of_use[0].class == 5) {
            this.BloodStockDetails = true;
          }
        }
      }

      // BloodStocks
      this.bloodStocks = value.blood_stock;

      // Policy Cover
      value.policy_cover_details.forEach((res, index) => {
        if (res.is_vet_fee_cat == 0 && res.is_addon_cat == 0) {
          this.policyCover.push(res);
        }

        // Vet Fee Cover
        if (res.is_vet_fee_cat == 1) {
          res.section_indeminity_limit.forEach((res1) => {
            if (res1.is_special_vet == 0) {
              if (res1.is_blood_stock == 0) {
                this.vetFeesCover.push(res1);
              } else {
                this.vetFeesCoverBloodStock.push(res1);
              }
            } else {
              this.vetFeeCoverSpecial.push(res1);
            }
          });
        }

        // Public Liability
        if (res.is_addon_cat == 1) {
          if (res.cover_index == 4) {
            this.publicLiability = res.section_indeminity_limit;
          }
          if (res.cover_index == 5) {
            this.accidentCover = res.section_indeminity_limit;
          }
        }

      });

      // Vet Fee Excess
      value.company_excess.forEach((res, index) => {
        this.vetFeeExcess.push(res);
      });

    }
  }

  constructor(private quoteService: QuoteService, private modalService: NgbModal, private route: ActivatedRoute,
    private userService: UserInfoService) {

  }

  saddleryTotalSumInsured: number = 0;
  trailerTotalSumInsured: number = 0;
  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quote;
    if (this.quote.horse_details) {
      this.quote.horse_details.value = Math.round(this.quote.horse_details.value);
    }

    if (this.quote.horse_sub_class == null) {
      this.quote.horse_sub_class = new HorseSubClass();
    }
    // Calculate Sum of Saddlery & Tack Item's Sum Insured
    this.saddleryTotalSumInsured = 0;
    if(this.quoteDetails.horse_details && this.quoteDetails.horse_details.saddlery_items) {
    this.quoteDetails.horse_details.saddlery_items.forEach((res, index) => {
      res.sum_insured = (res.sum_insured == 0) ? null : res.sum_insured;
      this.saddleryTotalSumInsured += Number(res.sum_insured);
    });
  }

    // Calculate Sum of Trailer Item's Sum Insured
    this.trailerTotalSumInsured = 0;
    if(this.quoteDetails.horse_details && this.quoteDetails.horse_details.trailor_items) {
    this.quoteDetails.horse_details.trailor_items.forEach((res, index) => {
      res.sum_insured = (res.sum_insured == 0) ? null : res.sum_insured;
      this.trailerTotalSumInsured += Number(res.sum_insured);
    });
  }

    // Set Cover Details Values
    this.setCoverDetails();

    // API - GET ENDORSEMENT
    if (this.quote && this.quote.endorsements) {
      this.rowData = this.quote.endorsements;
    }
  }

  ngOnInit(): void {

    // Get Horse Details
    this.quoteService.getHorseDetails()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.horseHeight = response.result.height;
            this.horseBreed = response.result.breed;
            this.horseColor = response.result.color;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // Get All List
    this.quoteService.getTitleList()
    .subscribe(
      (response: any) => {
        if (response.success) {
          this.titleList = response.result;
          if(this.titleList.length > 0){
            this.titleList.forEach((res:any, index) => {
               if(res.id == this.quote.policy_holder.title){
                this.titleName = res.title;
               }
             });
          }
        }
      });
  }

  /*
 * Set Cover Detail Values
 */
  setCoverDetails() {
    this.quote.full_vet_fee_cover = '';
    this.quote.full_life_saving_surgery = '';
    this.quote.full_vet_fee_excess = '';
    this.quote.full_public_liability = '';
    this.quote.full_personal_accident = '';
    // Vet Fee Cover
    if (this.vetFeesCover) {
      let vetFeesCoverData = this.vetFeesCover.filter(x => x.id == this.quote.vet_fee_sum_insured_id);
      if (vetFeesCoverData.length > 0) {
        this.quote.full_vet_fee_cover = vetFeesCoverData[0].description;
      } else {
        this.quote.full_vet_fee_cover = '';
      }
    }
    // Life Saving Surgery
    if (this.vetFeeCoverSpecial) {
      this.quote.full_life_saving_surgery = '';
      if (this.quote.life_saving_annual_surgery_id) {
        let vetFeeCoverSpecial = this.vetFeeCoverSpecial.filter(x => x.id == this.quote.life_saving_annual_surgery_id);
        if (vetFeeCoverSpecial.length > 0) {
          this.quote.full_life_saving_surgery = vetFeeCoverSpecial[0].description;
        }
      }
      if (this.quote.life_saving_surgery_id) {
        let vetFeeCoverSpecial = this.vetFeeCoverSpecial.filter(x => x.id == this.quote.life_saving_surgery_id);
        if (vetFeeCoverSpecial.length > 0) {
          this.quote.full_life_saving_surgery = vetFeeCoverSpecial[0].description;
        }
      }

    }
    // Vet Fee Excess    
    if (this.vetFeeExcess) {
      let vetFeeExcess = this.vetFeeExcess.filter(x => x.id == this.quote.company_excess_id);
      if (vetFeeExcess.length > 0) {
        this.quote.full_vet_fee_excess = vetFeeExcess[0].excess_amount;
      } else {
        this.quote.full_vet_fee_excess = '';
      }
    }
    // Public liability
    if (this.publicLiability) {
      let public_liability = this.publicLiability.filter(x => x.id == this.quote.public_liability_cover_id);
      if (public_liability.length > 0) {
        this.quote.full_public_liability = public_liability[0].description;
      } else {
        this.quote.full_public_liability = '';
      }
    }
    // Personal Accident
    if (this.accidentCover) {
      let accident_cover = this.accidentCover.filter(x => x.id == this.quote.personal_accidental_cover_id);
      if (accident_cover.length > 0) {
        this.quote.full_personal_accident = accident_cover[0].description;
      } else {
        this.quote.full_personal_accident = '';
      }
    }
  }

  // SAVE ENDORSEMENT
  addEndorsement(content) {
    this.modalService.open(content, { centered: true });
    this.endorsement = new QuoteEndorsement();
  }
  onEndorsementSubmit(f: NgForm) {
    let data = {
      id: this.endorsement.id,
      quote_id: this.route.snapshot.params.quote_meta_id,
      title: this.endorsement.title,
      endorsement_text: this.endorsement.endorsement_text,
      type: this.endorsement.type
    };
    this.quoteService.saveEndorsement(data)
      .subscribe(
        (response: any) => {
          if (this.endorsement.id == '') {
            this.gridOptions.rowData.push({
              'title': this.endorsement.title,
              'endorsement_text': this.endorsement.endorsement_text,
              'id': response.result.endorsement_id,
              'type': this.endorsement.type,
            });
            this.gridOptions.api.setRowData(this.gridOptions.rowData);
          } else {
            this.gridOptions.api.refreshCells();
          }
          this.modalService.dismissAll();
        });
  }

  // DISPLAY ENDORSEMENTS GRID
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
      var cellDefs = this.gridOptions.api.getEditingCells();
    }


  };
  columnDefs = [
    { headerName: 'Title', field: 'title', sortable: true, width: 200, editable: true },
    {
      headerName: 'Endorsement', field: 'endorsement_text', sortable: true, editable: false, width: 500,
      cellRenderer: function (param) {
        return '<a  data-action-type="view">' + param.data.endorsement_text.replace('\r\n', '<br>') + '</a>';
      },
      tooltip: function (param) {
        return param.data.endorsement_text;
      },
    },
    {
      headerName: 'Created At', field: 'created_at', sortable: false, width: 100,
      cellRenderer: function (params) {
        return formatDate(params.data.created_at, 'dd-MM-yyyy hh:mm a', 'en-US');
      }
    },
  ];
  rowData: any;
  selectedRow;
  defaultColDef = {
    flex: 1,
    wrapText: true,
    autoHeight: true,
    sortable: true,
    resizable: true,
  };
  onColumnResized(params) {
    params.api.resetRowHeights();
  }
  endorsementText;
  onGridRowClicked(e: any, content, deleteModel, endorsementViewModel) {
    if (e) {
      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {

            const modalRef = this.modalService.open(content, { centered: true });
            e.data.type = Number(e.data.type);
            this.endorsement = e.data;
            break;
          }
        case "view":
          {
            this.modalService.open(endorsementViewModel, { centered: true });
            this.endorsementText = e.data.endorsement_text.replace('\r\n', '<br>');
            break;
          }
        case "delete": {
          this.modalService.open(deleteModel, { centered: true });
          this.selectedRow = this.gridOptions.api.getFocusedCell();
          this.endorsement = e.data;
        }
      }
    }
  }

  deleteEndorsement(id, selectedRow) {
    this.quoteService.deleteEndorsement(id)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
          this.gridOptions.api.setRowData(this.gridOptions.rowData);
        });
  }

}
