import { PolicyHolderAddress } from './policy_holder_address.model';
export class PolicyHolder {
    id: string = '';
    quote_id: string = '';
    policy_id: string = '';
    address_id: string = '';
    first_name: string = '';
    last_name: string = '';
    phone: string = '';
    mobile: string = '';
    email: string = '';
    dob: string = '';

    // Alpaca
    company_name: string = '';
    trading_name: string = '';
    proposer_type: number = 1;

    address:PolicyHolderAddress = new PolicyHolderAddress();
    animal_address:PolicyHolderAddress = new PolicyHolderAddress();
}
