<div class="row">
    <div class="col-md-8">
        <div class="text-center">
            <div *ngIf="quote.is_referred && !quote.is_declined">
                <h4 *ngIf="quote">YOUR REFERRED QUOTATION REFERENCE IS - <a class="text-white" target="_blank"
                        [routerLink]="['/alpaca-admin/quote-details',quote.quote_versions[0].id]">{{quote.quote_versions[0].quote_ref_number}}</a>
                </h4>
                <p class="f13 mt-2">YOUR QUOTATION HAS BEEN REFERRED TO OUR UNDERWRITERS </p>
                <p class="f13">
                    We will contact you within 2 working days to confirm your final quotation details with you
                </p>
                <div class="form-group text-center mt-5">
                    <a routerLink="/dashboard" class="btn btn-success btn-sm">Back to dashboard</a>
                </div>
            </div>
            <div *ngIf="quote.is_declined">
                <h4>YOUR DECLINED QUOTATION REFERENCE IS - <a class="text-white" target="_blank"
                        [routerLink]="['/alpaca-admin/quote-details',quote.quote_versions[0].id]">{{quote.quote_versions[0].quote_ref_number}}</a>
                </h4>
                <p class="f13 mt-2">Thank you for using 'Insure My Alpaca' for your insurance quotes.</p>
                <p class="f13">
                    Unfortunately, on this occasion we are unable to offer you cover for the following reason:
                </p>
                <p class="f13" style="white-space: pre-line;">
                    {{quote.declined_reasons}}
                </p>
                <p class="f13">
                    If you would like to discuss this further or would like a arrange cover with us direct, then pleased
                    email
                    us at info@insuremyalpaca.co.uk.
                </p>
                <p class="f13">
                    Customer Service Team<br>
                    Insure My Alpaca
                </p>
                <div class="form-group text-center mt-5">
                    <a routerLink="/dashboard" class="btn btn-success btn-sm">Back to dashboard</a>
                </div>
            </div>
            <div *ngIf="!quote.is_referred && !quote.is_declined">
                <h4 *ngIf="quote">YOUR QUOTATION REFERENCE IS - <a class="text-white" target="_blank"
                        [routerLink]="['/alpaca-admin/quote-details',quote.quote_versions[0].id]">{{quote.quote_versions[0].quote_ref_number}}</a>
                </h4>
                <p class="f13">Your quotation is valid for 30 days</p>
                <p class="f13 mt-3">
                    Your quotation documents will be emailed to you, these will be;
                </p>
                <div class="mt-3 row">
                    <div class="col-md-4 offset-4 text-left">
                        <ul class="f13" style="margin-left: 84px;">
                            <li>Quotation Schedule</li>
                            <li>Policy Wording</li>
                            <li>Livestock Smallholders IPID</li>
                        </ul>
                    </div>
                </div>
                <div class="f13 mt-3">
                    If you would like to proceed to convert your quote to a policy please click on the convert to policy
                    button below
                </div>
                <div class="mt-4">
                    <h5 class="text-center">POLICY PAYMENT TERMS</h5>
                    <div class="mt-3 row">
                        <div class="col-md-10 offset-1 text-left">
                            <ul class="f13" style="margin-left: 84px;" *ngIf="bankDetails">
                                <li>Payment is due within 14 days of your policy effective date, if payment is not
                                    received
                                    within this period your policy may be cancelled</li>
                                <li>If you would like to pay by credit/ debit card please call us on {{
                                    bankDetails.phone }}</li>
                                <li>If you would like to pay by monthly instalments (provided by Premium Credit Limited)
                                    then
                                    please call us on {{ bankDetails.phone }}</li>
                                <li>
                                    If you would like to make a direct bank transfer please use the details below
                                    quoting your
                                    policy number;
                                    <ul class="f13">
                                        <li>Bank Account Sort Code: {{ bankDetails.sort_code }}</li>
                                        <li>Bank Account no: {{ bankDetails.account_number }}</li>
                                        <li>Bank Account Name: {{ bankDetails.account_name }}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="form-group row mt-5">
                    <div class="col-md-4" [hidden]="quote.quote_versions[0].is_policy == 1">
                        <a [routerLink]="['/pre-qualifier',2,quote.quote_versions[0].id]"
                            class="btn btn-default btn-gray text-white btn-sm">Edit Quote</a>
                    </div>
                    <div class="col-md-4">
                        <a routerLink="/dashboard" class="btn btn-success btn-sm">Back to dashboard</a>
                    </div>
                    <div class="col-md-4">
                        <a (click)="convertToPolicy()" class="btn btn-success btn-sm">Proceed to policy & payment
                            terms</a>
                    </div>


                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <app-admin-al-calculations [Calculations]="Calculations" [quoteDetails]="quoteDetails">
        </app-admin-al-calculations>
    </div>
</div>