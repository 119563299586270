import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from "@angular/router";
import { SharedService } from '../services/shared.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  userInfo = this.sessionSt.retrieve('userInfo');


  constructor(private sessionSt: LocalStorageService, private router: Router) { }

  companylogoURL: string = "";
  ngOnInit(): void {
    if (this.sessionSt.retrieve('userInfo').company.logo_path) {
      this.companylogoURL = environment.imageURL + this.sessionSt.retrieve('userInfo').company.logo_path;
    }
    // REDIRECT TO LOGIN SCREEN IF NOT LOGGED IN -- WILL APPLY LATER
    if (this.sessionSt.retrieve('login_token') == null) {
      this.router.navigateByUrl("/");
    }
  }

  isMobileNav: boolean = false;
  mobileNav() {
    if (this.isMobileNav) {
      this.isMobileNav = false;
    } else {
      this.isMobileNav = true;
    }
  }

}
