import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { formatDate } from '@angular/common';
import { NgForm } from '@angular/forms';
import { AlQuoteService } from '../../al-quote/al-quote.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserInfoService } from '../../../../services/user-info.service';

@Component({
  selector: 'app-admin-financials',
  templateUrl: './financials.component.html',
  styles: [
  ]
})
export class AlFinancialsComponent implements OnInit {
  @Input() quoteData: QuoteVersions;
  quote;
  total_premium_ipt: number = 0;

  constructor(private quoteService: AlQuoteService, private _snackBar: MatSnackBar, private userService: UserInfoService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteData;
    if (this.quote && this.quote.financial_details) {
      this.total_premium_ipt = Number(this.quote.financial_details.policy_premium) + (Number(this.quote.financial_details.policy_premium) * Number(this.quote.financial_details.ipt_fee)) / 100;
    }
  }

  ngOnInit(): void {
   
  }

}