import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ElementRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-webstorage';
import { Quote } from '../../model/quote/quote.model';
import { AdminAlCalculationsComponent } from '../al-calculations/al-calculations.component';
import { AlQuoteService } from '../al-quote.service';
import { FinancialDetails } from '../../model/quote/financial_details.model';
import { AlCalculationsDetails } from '../al-calculations/al-calculations.model';
import { PolicyService } from '../../../../policy/policy.service';
import { PolicyHolderAddress } from '../../model/quote/policy_holder_address.model';
import { UserInfoService } from '../../../../services/user-info.service';
import { AlpacaLiability } from '../../model/quote/alpaca_liability.model';
import { AboutAlpaca } from '../../model/quote/about_alpaca.model';
import { SmallHoldingActivities } from '../../model/quote/smallholding_activities.model';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-admin-al-indicative-quote',
  templateUrl: './al-indicative-quote.component.html',
  styleUrls: ['../../../quote.component.css']
})
export class AdminAlIndicativeQuoteComponent implements OnInit {
  @Input() quoteDetails: Quote;
  @Output() quoteDetailsUpdated = new EventEmitter<Quote>();
  calculationDetails = new AdminAlCalculationsComponent();
  @Output() ProceedWithQuoteBtn = new EventEmitter();
  Calculations = new AlCalculationsDetails();
  currDate = new Date();
  isBroker: boolean = false;
  brokers = [];
  @Input()
  set allbrokers(value: any) {
    this.brokers = value;
  }

  policyReasons = [];
  @Input()
  set allPolicyReasons(value: any) {
    this.policyReasons = value;
  }


  constructor(private modalService: NgbModal, private quoteService: AlQuoteService, private _snackBar: MatSnackBar, private userService: UserInfoService,
    private sessionSt: LocalStorageService, private el: ElementRef, private route: ActivatedRoute, private policyService: PolicyService) { }


  ngOnInit(): void {
    this.quote = this.quoteDetails;
    // Set Quote Type
    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);
    } else {
      this.quote_type = Number(this.quote.quote_versions[0].type);
    }
  }

  quote;
  minPolicyDate;
  maxPolicyDate;
  quote_type = 0;
  tigerlabfield: boolean = false;
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
    this.quote.calculations = this.Calculations;

    if (this.sessionSt.retrieve('is_staff')) {
      let minDate = new Date().setDate(new Date().getDate() - 7);
      this.minPolicyDate = new Date(minDate);
      let maxDate = new Date().setDate(new Date().getDate() + 30);
      this.maxPolicyDate = new Date(maxDate);
    }
    if (this.sessionSt.retrieve('is_broker')) {
      this.minPolicyDate = new Date();
      let maxDate = new Date().setDate(new Date().getDate() + 30);
      this.maxPolicyDate = new Date(maxDate);
      this.isBroker = true;
      this.quote.quote_versions[0].company_id = this.sessionSt.retrieve('broker_company_id');
    }



    // Set Quote Type
    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);
    } else {
      this.quote_type = Number(this.quote.quote_versions[0].type);
    }
    if (this.quote_type == 3) {
      if (this.sessionSt.retrieve('is_admin')) {
        this.minPolicyDate = this.quote.quote_versions[0].original_policy_start_date;
      } else {
        if (this.sessionSt.retrieve('is_broker')) {
          if (this.quote.quote_versions[0].policy_start_date) {
            let policy_start_date = new Date(this.quote.quote_versions[0].policy_start_date);
            if (policy_start_date < this.currDate) {
              this.minPolicyDate = new Date();
              this.quote.quote_versions[0].policy_start_date = this.currDate;
            }
          }
        }
        if (this.sessionSt.retrieve('is_staff')) {
          let minDate = new Date().setDate(new Date().getDate() - 7);
          this.minPolicyDate = new Date(minDate);
          if (this.quote.policy_start_date) {
            let policy_start_date = new Date(this.quote.quote_versions[0].policy_start_date);
            if (policy_start_date < this.minPolicyDate) {
              this.quote.quote_versions[0].policy_start_date = this.currDate;
            }
          }
        }
        let maxDate = new Date().setDate(new Date().getDate() + 14);
        this.maxPolicyDate = new Date(maxDate);
      }
      this.minPolicyDate = this.quote.quote_versions[0].original_policy_start_date;
    }

    let uw_fees = 0;
    let ipt_fee = 0;
    this.Calculations.mta_date = this.quote.policy_start_date;
    if (this.quote.quote_versions[0].financial_details) {
      uw_fees = this.quote.quote_versions[0].financial_details.uw_fees;
      ipt_fee = this.quote.quote_versions[0].financial_details.ipt_fee;
    } else {
      this.getBrokerCommissionById(this.quote.quote_versions[0].company_id);
      // if (this.brokers) {
      //   let brokercomission = this.brokers.filter(x => x.id == this.quote.quote_versions[0].company_id);
      //   if (brokercomission.length > 0) {
      //     uw_fees = Number(brokercomission[0].uw_fees);
      //     ipt_fee = Number(brokercomission[0].ipt_fee);
      //   }
      // }
    }
    // this.Calculations.ipt_charges = Number(uw_fees);
    // this.Calculations.administrative_charges = Number(uw_fees);
    // this.Calculations.ipt_amount = Number(ipt_fee);


    // this.Calculations.calculate_data(this.quote);
  }

  ngDoCheck() {
    // Set Quote Type
    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);

    } else {
      this.quote_type = Number(this.quote.quote_versions[0].type);
    }
  }

  /*
  * HELP TEXT POPUP
  */
  helptext = '';
  helpTextPopup(content, text) {
    this.modalService.open(content, { centered: true });
    this.helptext = text;
  }

  /*
  * On Change Broker Dropdown
  */
  onChangeBroker(event) {
    // let commission_percentage = 0;
    // let uw_fees = 0;
    // if (event.value) {
    //   let brokers_data = this.brokers.filter(x => x.id == event.value);

    //   if (brokers_data.length > 0) {
    //     commission_percentage = brokers_data[0].commission_percentage;
    //     uw_fees = brokers_data[0].uw_fees;
    //     this.quote.quote_versions[0].company_id = event.value;
    //   }      
    //   this.Calculations.administrative_charges = Number(uw_fees);
    //   this.Calculations.ipt_charges = Number(commission_percentage);
    // } else {
    //   this.quote.quote_versions[0].financial_details.administrative_charges = 0;
    //   this.Calculations.administrative_charges = 0;
    //   this.Calculations.ipt_charges = 0;
    // }
    // this.Calculations.calculate_data(this.quote);
    this.getBrokerCommissionById(event.value)
  }

  // Get Commission Data On Broker change 
  getBrokerCommissionById(id) {
    let commission_percentage = 0;
    let uw_fees = 0;
    if (id) {
      this.quoteService.getLiveStockBrokerCommission(id, environment.ALPACA_ID)
        .subscribe(
          (response: any) => {
            if (response.success) {
              let brokers_data = response.result;
              if (brokers_data != null) {

                commission_percentage = brokers_data.broker_commission;
                uw_fees = brokers_data.uw_fee;
                this.quote.quote_versions[0].company_id = id;
                this.Calculations.administrative_charges = Number(uw_fees);
                // this.Calculations.ipt_charges = Number(commission_percentage);
                this.Calculations.calculate_data(this.quote);
              }
            }
          });
    }
  }
  /*
  * On Quote Type Change
  */
  onQuoteTypeChange(event) {
    this.quote_type = event.value;
    this.quote.quote_versions[0].type = event.value;
  }



  /*
  * ANIMAL ADDRESS CHANGE
  */
  onAnimalAddressChange(event) {
    if (event.value == 1) {
      this.quote.quote_versions[0].animal_address = [];
      this.quote.quote_versions[0].animal_address.push(new PolicyHolderAddress());
    }
  }

  /*
  * ANIMAL ADDRESS ADD/REMOVE
  */
  addAnimalAddress() {
    this.quote.quote_versions[0].animal_address.push(new PolicyHolderAddress());


  }
  removeAnimalAddress(i) {
    this.quote.quote_versions[0].animal_address.splice(i, 1);
  }

  /*
   * POSTCODE LOOKUP
   */
  addresses;
  postcodeLookup(event) {
    let postcode = event.target.value;
    this.quoteService.getAddressPostcodeLookup(postcode)
      .subscribe(
        (response: any) => {
          this.addresses = response.addresses;
        },
        (error) => {
          console.log(error);
        }
      );
  }
  onSelectAddress(address) {
    this.quote.quote_versions[0].policy_holder.address.address1 = address.line_1;
    this.quote.quote_versions[0].policy_holder.address.address2 = address.line_2;
    this.quote.quote_versions[0].policy_holder.address.city = address.town_or_city;
    this.quote.quote_versions[0].policy_holder.address.country = address.county;
  }
  onSelectAnimalAddress(address,i) {
    this.quote.quote_versions[0].animal_address[i].address1 = address.line_1;
    this.quote.quote_versions[0].animal_address[i].address2 = address.line_2;
    this.quote.quote_versions[0].animal_address[i].city = address.town_or_city;
    this.quote.quote_versions[0].animal_address[i].country = address.county;
  }
  /*
  * On Alpaca / Llama Mortality & Limited Theft Cover Change
  */
  onAlpacaLlamaTheftCoverChange(event) {
    if (event.value == 1) {
      this.quote.quote_versions[0].about_alpaca = new AboutAlpaca();
    } else {
      this.quote.quote_versions[0].about_alpaca = null;
      this.quote.quote_versions[0].number_of_claims = null;
    }
  }
  /*
  * On Public Liability Change
  */
  onPublicLiabilityChange(event) {
    if (event.value == 1) {
      this.quote.quote_versions[0].alpaca_liability = new AlpacaLiability();
      this.quote.quote_versions[0].smallholding_activities = new SmallHoldingActivities();
    } else {
      this.quote.quote_versions[0].alpaca_trailer_cover = null;
      this.quote.quote_versions[0].alpaca_liability = null;
      this.quote.quote_versions[0].smallholding_activities = null;
    }
  }

  


  /*
  * SAVE QUOTE
  */
  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      for (const key of Object.keys(f.controls)) {
        if (f.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('input[name=' + key + '], mat-select[name=' + key + '], textarea[name=' + key + ']');
          if (invalidControl) {
            invalidControl.focus();
            invalidControl.scrollIntoView({ behavior: 'smooth' });
          }
          break;
        }
      }

      return false;
    }
    let data: any = '';
    if (this.sessionSt.retrieve('company_role') != 'Broker') {
      this.sessionSt.store('al_broker_company_id', this.quote.quote_versions[0].company_id)
    }

    this.quote.quote_versions[0].quote_page_index = 0;


     // if (this.quote.quote_versions[0].retroactive_date) {
    //   this.quote.quote_versions[0].retroactive_date = formatDate(this.quote.quote_versions[0].retroactive_date, 'yyyy-MM-dd', 'en-US');
    // }

    if (!this.route.snapshot.params.quote_id && !this.quote.quote_versions[0].financial_details.id) {
      this.quote.quote_versions[0].financial_details = new FinancialDetails();
      this.quote.quote_versions[0].financial_details.ipt_fee = this.Calculations.ipt_charges;
      this.quote.quote_versions[0].financial_details.ipt_value = this.Calculations.ipt_amount;
      this.quote.quote_versions[0].financial_details.uw_fee = this.Calculations.administrative_charges;
      this.quote.quote_versions[0].financial_details.fees = this.Calculations.administrative_charges;
    }

    // if (this.quote.quote_versions[0].alpaca_public_liability_cover != 1) {
    //   this.quote.quote_versions[0].alpaca_liability = null;
    //   this.quote.quote_versions[0].smallholding_activities = null;
    //   this.quote.quote_versions[0].alpaca_trailer_cover = 0;
    // }

    // For Policy Actions
    if (!this.quote.prev_sequence_number) {
      this.quote.prev_sequence_number = this.quote.quote_versions[0].sequence_number;
    }
    if (this.route.snapshot.params.quote_type && this.quote.quote_versions.length > 0) {
      if (this.quote.prev_sequence_number == this.quote.quote_versions[0].sequence_number) {
        this.quote.quote_versions[0].id = null;
        if (this.quote.quote_versions[0].about_alpaca) {
          this.quote.quote_versions[0].about_alpaca.id = null;
          this.quote.quote_versions[0].about_alpaca.quote_id = null;
        }
        if (this.quote.quote_versions[0].alpaca_liability) {
          this.quote.quote_versions[0].alpaca_liability.id = null;
          this.quote.quote_versions[0].alpaca_liability.quote_id = null;
        }
        if (this.quote.quote_versions[0].smallholding_activities) {
          this.quote.quote_versions[0].smallholding_activities.id = null;
          this.quote.quote_versions[0].smallholding_activities.quote_id = null;
        }
        if (this.quote.quote_versions[0].employer_liability) {
          this.quote.quote_versions[0].employer_liability.id = null;
        }
        if (this.quote.quote_versions[0].trailer_cover) {
          this.quote.quote_versions[0].trailer_cover.id = null;
          this.quote.quote_versions[0].trailer_cover.quote_id = null;
        }
        if (this.quote.quote_versions[0].animal_type) {
          this.quote.quote_versions[0].animal_type.id = null;
        }
        //this.quote.quote_versions[0].animal_address.id = null;
        if (this.quote.quote_versions[0].alpaca_trailor_items) {
          this.quote.quote_versions[0].alpaca_trailor_items.forEach((res, index) => {
            res.id = null;
          });
        }
        if (this.quote.quote_versions[0].alpaca_details) {
          this.quote.quote_versions[0].alpaca_details.forEach((res, index) => {
            res.id = null;
          });
        }
        if (this.quote.quote_versions[0].financial_details) {
          this.quote.quote_versions[0].financial_details.quote_id = null;
          this.quote.quote_versions[0].financial_details.id = null;
          this.quote.quote_versions[0].financial_details.alpaca_financials.id = null;
        }
        if (this.quote.quote_versions[0].policy_holder) {
          this.quote.quote_versions[0].policy_holder.quote_id = null;
          this.quote.quote_versions[0].policy_holder.id = null;
        }
        if (this.quote.quote_versions[0].statement_of_facts) {
          this.quote.quote_versions[0].statement_of_facts.forEach((res, index) => {
            res.quote_id = null;
            res.id = null;
          });
        }
      }

      this.quote.quote_versions[0].type = this.route.snapshot.params.quote_type;
    }

    switch (this.route.snapshot.params.quote_type) {
      case '4':
        this.quote.quote_versions[0].insurance_status_id = '7a877243-0919-4f92-8c35-6f5732013ca6';
        break;
      case '5':
        this.quote.quote_versions[0].insurance_status_id = '0c836e51-f8b0-4df8-8736-3ce56a40869f';
        break;
      case '6':
        this.quote.quote_versions[0].insurance_status_id = '9bcea739-fd0b-4c67-980c-9e0dadeb8445';
        break;
      default:
        this.quote.quote_versions[0].insurance_status_id = 'e216564f-552a-4e45-ae67-0a63b7074691';
        break;
    }
    if (!this.quote.quote_versions[0].financial_details.id) {
      this.quote.quote_versions[0].financial_details.administrative_charges = this.Calculations.administrative_charges;
      this.quote.quote_versions[0].financial_details.ipt_fee = this.Calculations.ipt_charges;
      this.quote.quote_versions[0].financial_details.ipt_value = this.Calculations.ipt_amount;
      this.quote.quote_versions[0].financial_details.uw_fee = this.Calculations.administrative_charges;
      this.quote.quote_versions[0].financial_details.fees = this.Calculations.administrative_charges;
    }

    let quote_temp = this.quote;
    data = {
      quote_data: this.quote
    };

    this.quoteService.saveQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            // Disbale to stop clearing mta reasons id if the page loads first time under quote edit screen 
            this.sessionSt.store('showHideMTAReasons', false);
            this.quote = response.result.quote_data;
            this.quote.calculations = quote_temp.calculations;
            this.quote.prev_sequence_number = quote_temp.prev_sequence_number;

            this.quote.quote_versions[0].mta_sum_policy_premium = response.result.quote_data.mta_sum_policy_premium;
            this.quote.quote_versions[0].sum_alpaca_moroality_premium = response.result.quote_data.sum_alpaca_moroality_premium;
            this.quote.quote_versions[0].sum_alpaca_total_premium = response.result.quote_data.sum_alpaca_total_premium;
            this.quote.quote_versions[0].sum_liability_total_premium = response.result.quote_data.sum_liability_total_premium;
            this.quote.quote_versions[0].sum_policy_premium = response.result.quote_data.sum_policy_premium;
            this.quote.quote_versions[0].sum_public_liability_premium = response.result.quote_data.sum_public_liability_premium;
            this.quote.quote_versions[0].sum_trailer_premium = response.result.quote_data.sum_trailer_premium;
            this.quote.quote_versions[0].latest_policy_start_date = response.result.quote_data.latest_policy_start_date;
            this.quote.quote_versions[0].latest_policy_end_date = response.result.quote_data.latest_policy_end_date;


            this.quoteDetailsUpdated.emit(this.quote);
            this.ProceedWithQuoteBtn.emit();
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });

  }
}
