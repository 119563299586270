<form class="g-3 needs-validation" novalidate [formGroup]="frmFields" (ngSubmit)="onSubmit()">
    <mat-accordion formArrayName="vehicles">
        <div class="form_sections" *ngFor="let v of vehicles.controls; let i = index">
            <mat-expansion-panel [expanded]="i == 0" formGroupName="{{i}}">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h4>
                            <u>
                                <span *ngIf="!v.get('registration_number').value">Horsebox {{i + 1}}</span>
                                <span *ngIf="v.get('registration_number').value">{{ v.get('registration_number').value
                                    }}</span>
                                <span *ngIf="v.get('make').value"> - {{ v.get('make').value }}</span>
                                <span *ngIf="v.get('model').value"> - {{ v.get('model').value }}</span>
                            </u>
                            <a (click)="removeHorseboxes(i)" title="Remove Horsebox"
                                class="btn btn-danger btn-sm p-1 ml-3" *ngIf="i > 0"><i class="mdi mdi-delete"></i></a>
                        </h4>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="cal_body">
                    <h6 class="text-white">Vehicle Details</h6>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <!-- <div class="input-group mb-3"> -->
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="Registration Number" required name="registration_number"
                                    formControlName="registration_number" (change)="onRegistrationNumberChange($event, i)"
                                    [ngClass]="{ 'is-invalid': (v.get('registration_number').touched || submitted) && v.get('registration_number').errors?.required }">
                                <!-- <span class="input-group-text text-white dark-green-bg pt-0 pb-0">Search</span> -->
                            <!-- </div> -->
                            <div class="invalid-feedback"
                                *ngIf="(v.get('registration_number').touched || submitted) && v.get('registration_number').errors?.required">
                                Registration number is required.
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <input type="text" class="form-control form-control-sm" placeholder="Make" required
                                    name="make" formControlName="make"
                                    [ngClass]="{ 'is-invalid': (v.get('make').touched || submitted) && v.get('make').errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(v.get('make').touched || submitted) && v.get('make').errors?.required">
                                    Make is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <input type="text" class="form-control form-control-sm" placeholder="Model" required
                                name="model" formControlName="model"
                                [ngClass]="{ 'is-invalid': (v.get('model').touched || submitted) && v.get('model').errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(v.get('model').touched || submitted) && v.get('model').errors?.required">
                                Model is required.
                            </div>
                        </div>
                        <div class="col-md-6">
                            <input type="text" class="form-control form-control-sm" placeholder="Year of Make" required
                                name="year" formControlName="year"
                                [ngClass]="{ 'is-invalid': (v.get('year').touched || submitted) && v.get('year').errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(v.get('year').touched || submitted) && v.get('year').errors?.required">
                                Year is required.
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-6" *ngIf="staticData">
                            <mat-form-field class="w-100">
                                <mat-label>Gross Vehicle weight</mat-label>
                                <mat-select formControlName="weight" class="form-control form-control-sm" name="weight"
                                    required
                                    [ngClass]="{ 'is-invalid': (v.get('weight').touched || submitted) && v.get('weight').errors?.required }">
                                    <mat-option *ngFor="let vw of staticData.gross_vehicle_weights" [value]="vw">
                                        {{ vw }}
                                    </mat-option>
                                </mat-select>
                                <div class="invalid-feedback"
                                    *ngIf="(v.get('weight').touched || submitted) && v.get('weight').errors?.required">
                                    Please select an option.
                                </div>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <div class="input-group mt-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                </div>
                                <input type="number" class="form-control form-control-sm" placeholder="Vehicle Value"
                                    required name="value" formControlName="value"
                                    [ngClass]="{ 'is-invalid': (v.get('value').touched || submitted) && v.get('value').errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(v.get('value').touched || submitted) && v.get('value').errors?.required">
                                    Value is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="staticData">
                        <div class="col-md-6">
                            <mat-form-field class="w-100">
                                <mat-label>Cover Type</mat-label>
                                <mat-select formControlName="cover_type" class="form-control form-control-sm"
                                    name="cover_type" required
                                    [ngClass]="{ 'is-invalid': (v.get('cover_type').touched || submitted) && v.get('cover_type').errors?.required }">
                                    <mat-option *ngFor="let ct of staticData.cover_types" [value]="ct">
                                        {{ ct }}
                                    </mat-option>
                                </mat-select>
                                <div class="invalid-feedback"
                                    *ngIf="(v.get('cover_type').touched || submitted) && v.get('cover_type').errors?.required">
                                    Please select an option.
                                </div>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="w-100">
                                <mat-label>Driving Restriction</mat-label>
                                <mat-select formControlName="driving_restriction" class="form-control form-control-sm"
                                    name="driving_restriction" required
                                    (selectionChange)="onDrivingRestrictionChange($event, i)"
                                    [ngClass]="{ 'is-invalid': (v.get('driving_restriction').touched || submitted) && v.get('driving_restriction').errors?.required }">
                                    <mat-option *ngFor="let dr of drivingRestrictions" [value]="dr">
                                        {{ dr }}
                                    </mat-option>
                                </mat-select>
                                <div class="invalid-feedback"
                                    *ngIf="(v.get('driving_restriction').touched || submitted) && v.get('driving_restriction').errors?.required">
                                    Please select an option.
                                </div>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-------- Drivers -------->
                    <div class="green_border_panel" *ngIf="activateDrivers">
                        <h6 class="text-white">Drivers</h6>
                        <div formArrayName="drivers">  
                            <div *ngFor="let d of driverControls(i).controls; let idx=index">
                                <div formGroupName="{{idx}}" class="mt-2">
                                    <div class="row">
                                        <div class="col-6">
                                            <mat-form-field class="w-100">
                                                <mat-label>Driver {{idx + 1}}</mat-label>
                                                <mat-select formControlName="id"
                                                    class="form-control form-control-sm" name="driver_id" required
                                                    [ngClass]="{ 'is-invalid': (d.get('id').touched || submitted) && d.get('id').errors?.required }">
                                                    <mat-option *ngFor="let dr of drivers_list" [value]="dr.id">
                                                        {{ dr.title + ' ' + dr.first_name + ' ' + dr.last_name }}
                                                    </mat-option>
                                                </mat-select>
                                                <div class="invalid-feedback"
                                                    *ngIf="(d.get('id').touched || submitted) && d.get('id').errors?.required">
                                                    Please select an option.
                                                </div>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-2 mt-2">
                                            <a (click)="removeDrivers(i, idx)" class="btn btn-danger btn-sm p-1"
                                                *ngIf="idx > 0"><i class="mdi mdi-delete"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <a (click)="addExistingDriver(i)" class="btn btn-default btn-success btn-sm f-12 mr-3"
                            *ngIf="driverControls(i).controls.length > 0">Add
                            Existing
                            Driver</a>
                        <a (click)="addNewDriver(i, drivers)" class="btn  btn-default btn-success btn-sm f-12">Add
                            New
                            Driver</a>

                    </div>

                    <!-------- Extended Use -------->
                    <div class="green_border_panel">
                        <div class="row">
                            <div class="col-md-12">
                                <h6 class="text-white">This quote is to cover use for Social, domestic and
                                    pleasure,
                                    including travel to and/or
                                    from the Proposer's place of work and/or study, are there any extended use
                                    requirements?
                                </h6>
                                <mat-radio-group class="row" aria-label="Select an option" name="is_extended_use"
                                    formControlName="is_extended_use" required
                                    (change)="onExtendedUseChange($event, i)">
                                    <div class="col-md-4">
                                        <div class="quote_radio w-90">
                                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="quote_radio w-90">
                                            <mat-radio-button [value]="false">No</mat-radio-button>
                                        </div>
                                    </div>
                                </mat-radio-group>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(v.get('is_extended_use').touched || submitted) && v.get('is_extended_use').errors?.required">
                                    Please select the option.
                                </div>
                            </div>
                        </div>
                        <div class="text-white" *ngIf="v.get('is_extended_use').value == true">
                            <h6 class="mt-3">Extended Use</h6>
                            <small>Please select any additional use required below</small>
                            <div class="row" formGroupName="extended_use">
                                <div class="col-md-6">
                                    <mat-checkbox name="proposer_equine" formControlName="proposer_equine" class="mb-2">
                                        Business use for proposer's equine trade
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-6">
                                    <mat-checkbox name="proposer_non_equine" formControlName="proposer_non_equine"
                                        class="mb-2" (change)="onproposerNonEquineChange($event, i)">
                                        Business use for proposer's non-equine trade
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-6">
                                    <mat-checkbox name="driver_equine" formControlName="driver_equine" class="mb-2"
                                        (change)="onDriverEquineChange($event, i)">
                                        Business use for named driver's equine trade
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-6">
                                    <mat-checkbox name="driver_non_equine" formControlName="driver_non_equine"
                                        class="mb-2" (change)="onDriverNonEquineChange($event, i)">
                                        Business use for named driver's non-equine trade
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-6">
                                    <mat-checkbox name="livestock_carriage" formControlName="livestock_carriage"
                                        class="mb-2">
                                        Hire and reward for the carriage of horses/livestock.
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="row text-white mt-3" *ngIf="is_proposer_non_equine">
                            <div class="col-md-6">
                                <h6>What is the proposer's non-equine trade</h6>
                                <input type="text" class="form-control form-control-sm" placeholder="Non-equine Trade"
                                    name="non_equine_trade" formControlName="non_equine_trade">
                            </div>
                        </div>
                        <!----- Equine Drivers ------>
                        <div class="text-white mt-3" *ngIf="is_driver_equine">
                            <h6>Please specify the named driver(s) that need business use for an equine trade</h6>
                            <div formArrayName="equine_trade_drivers">
                                <div *ngFor="let d of equineDriverControls(i).controls; let idx=index">
                                    <div formGroupName="{{idx}}" class="mt-2">
                                        <div class="row">
                                            <div class="col-6">
                                                <mat-form-field class="w-100">
                                                    <mat-label>Driver {{idx + 1}}</mat-label>
                                                    <mat-select formControlName="id"
                                                        class="form-control form-control-sm" name="driver_id" required
                                                        [ngClass]="{ 'is-invalid': (d.get('id').touched || submitted) && d.get('id').errors?.required }">
                                                        <mat-option *ngFor="let dr of drivers_list"
                                                            [value]="dr.id">
                                                            {{ dr.title + ' ' + dr.first_name + ' ' + dr.last_name }}
                                                        </mat-option>
                                                    </mat-select>
                                                    <div class="invalid-feedback"
                                                        *ngIf="(d.get('id').touched || submitted) && d.get('id').errors?.required">
                                                        Please select an option.
                                                    </div>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-2 mt-2">
                                                <a (click)="removeDrivers(i, idx,2)" class="btn btn-danger btn-sm p-1"
                                                    *ngIf="idx > 0"><i class="mdi mdi-delete"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <a (click)="addExistingDriver(i, 2)" class="btn btn-default btn-success btn-sm f-12 mr-3"
                                *ngIf="equineDriverControls(i).controls.length > 0">Add
                                Existing
                                Driver</a>
                            <a (click)="addNewDriver(i, drivers, 2)"
                                class="btn  btn-default btn-success btn-sm f-12">Add
                                New
                                Driver</a>
                        </div>

                        <!----- Non - Equine Drivers ------>
                        <div class="text-white mt-3" *ngIf="is_driver_non_equine">
                            <h6>Please specify the named driver(s) that need business use for a non-equine trade</h6>
                            <div formArrayName="non_equine_trade_drivers">
                                <div *ngFor="let d of nonEquineDriverControls(i).controls; let idx=index">
                                    <div formGroupName="{{idx}}" class="mt-2">
                                        <div class="row">
                                            <div class="col-6">
                                                <mat-form-field class="w-100">
                                                    <mat-label>Driver {{idx + 1}}</mat-label>
                                                    <mat-select formControlName="id"
                                                        class="form-control form-control-sm" name="driver_id" required
                                                        [ngClass]="{ 'is-invalid': (d.get('id').touched || submitted) && d.get('id').errors?.required }">
                                                        <mat-option *ngFor="let dr of drivers_list"
                                                            [value]="dr.id">
                                                            {{ dr.title + ' ' +  dr.first_name + ' ' + dr.last_name }}
                                                        </mat-option>
                                                    </mat-select>
                                                    <div class="invalid-feedback"
                                                        *ngIf="(d.get('id').touched || submitted) && d.get('id').errors?.required">
                                                        Please select an option.
                                                    </div>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-2 mt-2">
                                                <a (click)="removeDrivers(i, idx,3)" class="btn btn-danger btn-sm p-1"
                                                    *ngIf="idx > 0"><i class="mdi mdi-delete"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <a (click)="addExistingDriver(i, 3)" class="btn btn-default btn-success btn-sm f-12 mr-3"
                                *ngIf="nonEquineDriverControls(i).controls.length > 0">Add
                                Existing
                                Driver</a>
                            <a (click)="addNewDriver(i, drivers, 3)"
                                class="btn  btn-default btn-success btn-sm f-12">Add
                                New
                                Driver</a>
                        </div>
                    </div>
                    <!---- CLOSE EXTENDED PANEL ----->

                    <div class="row mt-3">
                        <div class="col-md-6">
                            <h6 class="text-white">Do you require Annual Green card?
                                <span class="ml-1"
                                    (click)="helpTextPopup(content,'Please select yes if this vehicle requires cover for use abroad at the same level of cover as if it is being used in the United Kingdom.

                                This would apply to any country that is
                                <ul>
                                    <li>a member of the European Union and/or</li>
                                    <li>any other country including Switzerland, Iceland, Norway, Andorra and Liechtenstein which has made arrangements which meet the insurance conditions of and are approved by the Commission of the European Union.</li>
                                </ul>
                                If agreed we would extend cover as standard for trips abroad up to 60 consecutive days and 120 days in total.
                                
                                In the event of a no deal Brexit you would still need to request a physical green card. Please find the request form along with all our Brexit communications <a href=https://www.msamlin.com/en/about-us/brexit-hub.html target=_blank class=text-dark><u>here</u></a>.')"><i
                                        class="mdi mdi-comment-question-outline"></i></span>
                            </h6>
                            <mat-radio-group class="row" aria-label="Select an option" name="green_card"
                                formControlName="green_card" required>
                                <div class="col-md-4">
                                    <div class="quote_radio w-90">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="quote_radio w-90">
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </div>
                                </div>
                            </mat-radio-group>
                            <div class="invalid-feedback" style="font-size: 13px;"
                                *ngIf="(v.get('green_card').touched || submitted) && v.get('green_card').errors?.required">
                                Please select an option.
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="staticData">
                            <h6 class="text-white">What is the annual mileage of this vehicle?
                                <span class="ml-1"
                                    (click)="helpTextPopup(content,'Please select a restricted annual mileage option.')"><i
                                        class="mdi mdi-comment-question-outline"></i></span>
                            </h6>
                            <mat-form-field class="w-100">
                                <mat-label>Mileage</mat-label>
                                <mat-select formControlName="mileage" class="form-control form-control-sm"
                                    name="mileage" required
                                    [ngClass]="{ 'is-invalid': (v.get('mileage').touched || submitted) && v.get('mileage').errors?.required }">
                                    <mat-option [value]="m" *ngFor="let m of staticData.mileage_options"> {{ m }}
                                    </mat-option>
                                </mat-select>
                                <div class="invalid-feedback"
                                    *ngIf="(v.get('mileage').touched || submitted) && v.get('mileage').errors?.required">
                                    Please select an option.
                                </div>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-6"> 
                            <h6 class="text-white">Is the proposer the registered owner of this vehicle?</h6>
                            <mat-radio-group class="row" aria-label="Select an option" name="is_registered_owner"
                                formControlName="is_registered_owner" required
                                (change)="onRegisteredOwnerChange($event, i)">
                                <div class="col-md-4">
                                    <div class="quote_radio w-90">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="quote_radio w-90">
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </div>
                                </div>
                            </mat-radio-group>
                            <div class="invalid-feedback" style="font-size: 13px;"
                                *ngIf="(v.get('is_registered_owner').touched || submitted) && v.get('is_registered_owner').errors?.required">
                                Please select an option.
                            </div>
                            <div class="mt-3" *ngIf="v.get('is_registered_owner').value == false && staticData">
                                <h6 class="text-white">Please confirm who is the registered owner</h6>
                                <mat-form-field class="w-100">
                                    <mat-label>Ownership</mat-label>
                                    <mat-select formControlName="owner" class="form-control form-control-sm"
                                        name="owner_name" required
                                        [ngClass]="{ 'is-invalid': (v.get('owner').touched || submitted) && v.get('owner').errors?.required }">
                                        <mat-option [value]="o" *ngFor="let o of staticData.ownership_options"> {{ o
                                            }}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(v.get('owner').touched || submitted) && v.get('owner').errors?.required">
                                        Please select an option.
                                    </div>
                                </mat-form-field>
                            </div>

                            <div class="mt-3">
                                <h6 class="text-white">Is this vehicle kept overnight at {{ (modelObj && modelObj.proposer)?modelObj.proposer.address.postcode:'' }}?</h6>
                                <mat-radio-group class="row" aria-label="Select an option" name="is_kept_overnight"
                                    formControlName="is_kept_overnight" required
                                    (change)="onkeptOvernightChange($event, i)">
                                    <div class="col-md-4">
                                        <div class="quote_radio w-90">
                                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="quote_radio w-90">
                                            <mat-radio-button [value]="false">No</mat-radio-button>
                                        </div>
                                    </div>
                                </mat-radio-group>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(v.get('is_kept_overnight').touched || submitted) && v.get('is_kept_overnight').errors?.required">
                                    Please select an option.
                                </div>
    
                                <div class="mt-4" *ngIf="v.get('is_kept_overnight').value == false">
                                    <h6 class="text-white">Please provide the postcode for where this vehicle is kept
                                        overnight
                                    </h6>
                                    <input type="text" class="form-control form-control-sm" placeholder="Postcode" required
                                        name="postcode" formControlName="postcode"
                                        [ngClass]="{ 'is-invalid': (v.get('postcode').touched || submitted) && v.get('postcode').errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(v.get('postcode').touched || submitted) && v.get('postcode').errors?.required">
                                        Postcode is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="staticData">
                            <h6 class="text-white">Is the vehicle fitted with a tracker?</h6>
                            <mat-form-field class="w-100">
                                <mat-label>Tracker</mat-label>
                                <mat-select formControlName="tracker" class="form-control form-control-sm"
                                    name="tracker" required
                                    [ngClass]="{ 'is-invalid': (v.get('tracker').touched || submitted) && v.get('tracker').errors?.required }">
                                    <mat-option [value]="t" *ngFor="let t of staticData.tracker_types"> {{ t }}
                                    </mat-option>
                                </mat-select>
                                <div class="invalid-feedback"
                                    *ngIf="(v.get('tracker').touched || submitted) && v.get('tracker').errors?.required">
                                    Please select an option.
                                </div>
                            </mat-form-field>

                            <div class="mt-3" *ngIf="parentModelObj && parentModelObj.rating_type != 'fleet'">
                                <h6 class="text-white">How many years NCD does the vehicle have?</h6>
                                <mat-form-field class="w-100">
                                    <mat-label>NCD</mat-label>
                                    <mat-select formControlName="ncd" class="form-control form-control-sm" name="ncd"
                                        required
                                        [ngClass]="{ 'is-invalid': (v.get('ncd').touched || submitted) && v.get('ncd').errors?.required }">
                                        <mat-option [value]="n" *ngFor="let n of staticData.ncd_options"> {{ n }}
                                        </mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback"
                                        *ngIf="(v.get('ncd').touched || submitted) && v.get('ncd').errors?.required">
                                        Please select an option.
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="staticData">
                        
                        
                        
                       
                    </div>

                   


                    
                </div>
            </mat-expansion-panel>
        </div>

    </mat-accordion>
    <div class="row">
        <div class="col-md-6"><a (click)="addHorsebox()" class="btn btn-gray btn-sm">Add Another Horsebox</a></div>
        <div class="col-md-6  text-right"><button class="btn btn-success btn-sm">Proceed</button></div>
    </div>

</form>
<ng-template #content let-modal>
    <div class="modal-body">
        <div class="helptext" [innerHTML]="helptext"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>

<ng-template #drivers let-modal>
    <div class="modal-header">
        Add New Driver
    </div>
    <div class="modal-body">
        <div *ngIf="validationMsgForPopUp" class="alert alert-danger">{{ validationMsgForPopUp }}</div>
        <div class="row">
            <div class="col-md-2">Name</div>
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field class="w-100">
                            <mat-label class="text-secondary">Title</mat-label>
                            <mat-select class="form-control form-control-sm" name="driver_title"
                                [(ngModel)]="driver_title" [disabled]="dont_have_driver_name">
                                <mat-option *ngFor="let dt of staticData.title_options" [value]="dt">
                                    {{ dt }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" name="driver_forename" placeholder="Forename" [readonly]="dont_have_driver_name"
                                [(ngModel)]="driver_forename" class="form-control form-control-sm">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" name="driver_surname" placeholder="Surname" [(ngModel)]="driver_surname"
                                class="form-control form-control-sm" [readonly]="dont_have_driver_name">
                        </div>
                    </div>
                </div> 

                <div class="form-group vehicles">
                    <mat-checkbox name="dont_have_driver_name" (change)="onNoDriverNameChange($event)" value="1">
                        Don't have driver name yet
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
        <button type="button" class="btn btn-success" (click)="createNewDriver()">Create Driver</button>        
    </div>
</ng-template>
 <!-- <p>
    Form is {{frmFields.valid ? 'Valid' : 'Invalid'}}
</p>
<pre>
            {{frmFields.value | json}}
            </pre>  -->