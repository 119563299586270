import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Quote } from '../../model/quote/quote.model';
import { AlCalculationsDetails } from '../al-calculations/al-calculations.model';
import { environment } from '../../../../../environments/environment';
import { QuoteService } from '../../../quote.service';
import { CompanyBankDetails } from '../../../../model/quote/company_bank_details.model';

@Component({
  selector: 'app-admin-al-policy-and-payment',
  templateUrl: './al-policy-and-payment.component.html',
  styleUrls: ['../../../quote.component.css']
})
export class AdminAlPolicyAndPaymentComponent implements OnInit {
  @Output() BackToQuotationTab = new EventEmitter();
  @Input() quoteDetails: Quote;
  @Input() selectedIndex;
 bankDetails = new CompanyBankDetails();
  Calculations = new AlCalculationsDetails();
  client_website_link: string = '';

  constructor(private qs: QuoteService) { }

  quote;
  ngOnInit(): void {
    this.quote = this.quoteDetails;
    this.client_website_link = environment.CLIENT_WEBSITE_LINK;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;

    // Get Broker Company Bank Details
    if (this.selectedIndex == 5) {
      this.getBrokerCompanyBankDetails();
    }
  }
  getBrokerCompanyBankDetails() {
    this.qs.getCompanyBankDetails(this.quote.quote_versions[0].company_id, environment.ALPACA_ID)
      .subscribe(
        (response: any) => {
          if (response.success) {
            if (response.result) {
              this.bankDetails = response.result;
            } else {
              this.bankDetails = new CompanyBankDetails();
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  /*
  * Back To Quotation Tab
  */
 backToQuotationTab() {
   this.BackToQuotationTab.emit();
 }

}
