import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, AbstractControl, FormGroup, FormArray } from '@angular/forms';
import { AmlineService } from '../amline.service';
import { Amline } from 'src/app/model/quote/amline/amline.model';
import { formatDate } from '@angular/common';
import { QuoteService } from '../../quote.service';
import { ActivatedRoute } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-proposer-details',
  templateUrl: './proposer-details.component.html',
  styleUrls: ['./proposer-details.component.css', '../../quote.component.css']
})
export class ProposerDetailsComponent implements OnInit {
  frmFields: any;
  submitted = false;
  isBroker: boolean = false;
  brokers: any = [];
  quote_type: number = this.route.snapshot.params.quote_type;
  @Input() staticData;
  @Input() authDetails;
  @Input() apiModelObj;
  hideClaims: boolean = true;
  modelObj: any = new Amline();
  @Output() modelObjUpdated = new EventEmitter<Amline>();
  @Output() isFleetActivated = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private as: AmlineService, private quoteService: QuoteService, private cdr: ChangeDetectorRef,
    private route: ActivatedRoute, private _snackBar: MatSnackBar, private sessionSt: LocalStorageService) { }

  minInceptionDate: any = new Date();
  maxInceptionDate: any = new Date();
  maxDOB: any = new Date();
  currDate: any = new Date();
  ngOnInit(): void {
    this.getBrokers();
    this.frmFields = this.fb.group({
      inception_date: ['', Validators.required],
      cover_to: ['', Validators.required],
      company_id: ['', Validators.required],
      effective_date: ['', Validators.required],
      proposer: this.proposer_details(),
      rating_type: ['', Validators.required],
    });

    let maxDate = new Date().setDate(new Date().getDate() + 30);
    this.maxInceptionDate = new Date(maxDate);

    let current_date = new Date();
    current_date.setFullYear(new Date().getFullYear() - 17);
    this.maxDOB = current_date.toISOString().slice(0, 10);

    if (this.quote_type && this.quote_type != 3) {
      this.frmFields.get('effective_date').disable();
    } else {
      this.frmFields.get('effective_date').enable();
    }

    if (this.sessionSt.retrieve('is_broker')) {
      this.isBroker = true;
      this.frmFields.get('company_id').setValue(this.sessionSt.retrieve('broker_company_id'));

    }

  }


  ngOnChanges(changes: SimpleChanges) {
    if (this.apiModelObj) {
      this.modelObj = this.apiModelObj.amlin_data.data.attributes.application;
      this.modelObj.id = this.apiModelObj.amlin_data.data.id;
      this.modelObj.quote_meta_id = this.apiModelObj.quote_meta_id;
      this.modelObj.policy_ref_number = this.apiModelObj.policy_ref_number;
      this.modelObj.reference = this.apiModelObj.amlin_data.data.attributes.reference;
      this.modelObj.status = this.apiModelObj.amlin_data.data.attributes.status;
      this.modelObj.company_id = this.apiModelObj.company_id;
      this.modelObj.sequence_number = this.apiModelObj.sequence_number;
      let inception_date = new Date(this.modelObj.inception_date);
      let curr_date = new Date();
      if (this.apiModelObj.type == 1 && inception_date > curr_date) {
        this.minInceptionDate = new Date();
      } else {

        this.minInceptionDate = new Date(this.modelObj.inception_date);
      }
      this.patchValues();

      this.disablePropserFields(this.modelObj.proposer.proposer_type);
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  get f(): { [key: string]: AbstractControl } {
    if (this.frmFields) {
      return this.frmFields.controls;
    }
  }

  get proposerControls() {
    return ((this.frmFields.get('proposer') as FormGroup).controls)
  }

  getBrokers() {
    this.quoteService.getBrokers(6)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.brokers = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getBrokerCommission(event) {
    let brokerDetails = this.brokers.filter((x => x.id == event.value));
    if (brokerDetails.length > 0) {
      this.modelObj.broker_commission = brokerDetails[0].broker_commission;
      this.modelObj.broker_admin_fee = brokerDetails[0].broker_admin_fee;
    }
  }

  patchValues() {
    var effective_date = (new Date(this.modelObj.inception_date) < new Date()) ? new Date() : this.modelObj.inception_date;
    if (this.modelObj.effective_date) {
      effective_date = this.modelObj.effective_date;
    }
    this.frmFields.patchValue({
      inception_date: this.modelObj.inception_date,
      company_id: this.modelObj.company_id,
      proposer: this.modelObj.proposer,
      rating_type: this.modelObj.rating_type,
      effective_date: effective_date,
    });


    if (this.sessionSt.retrieve('is_broker')) {
      this.frmFields.get('company_id').setValue(this.sessionSt.retrieve('broker_company_id'));
    }


    let convictions = (this.modelObj.proposer.convictions && this.modelObj.proposer.convictions.length > 0) ? true : false;
    this.proposerControls.have_convictions.setValue(convictions);

    this.modelObj.driver_conviction_declaration = false;
    if (convictions) {
      this.modelObj.driver_conviction_declaration = true;

      const control = (<FormArray>this.frmFields.controls['proposer']).get('convictions') as FormArray;
      control.clear();
      if (this.modelObj.proposer.convictions.length > 0) {
        this.modelObj.proposer.convictions.forEach((res) => {
          let data_convictions = this.fb.group({
            'code': [res.code],
            'date': [res.date],
            'points': [res.points],
            'banned': [res.banned],
            'fine': [res.fine],
          });
          control.push(data_convictions);
        })

      }
    }

    let claims = (this.modelObj.proposer.claims && this.modelObj.proposer.claims.length > 0) ? true : false;
    this.proposerControls.have_claims.setValue(claims);
    if (claims) {
      const claimsControl = (<FormArray>this.frmFields.controls['proposer']).get('claims') as FormArray;
      claimsControl.clear();
      if (this.modelObj.proposer.claims.length > 0) {
        this.modelObj.proposer.claims.forEach((res) => {
          let data_claims = this.fb.group({
            'date': [res.date, [Validators.required]],
            'type': [res.type, [Validators.required]],
            'ad_cost': [res.ad_cost, [Validators.required]],
            'tp_cost': [res.tp_cost, [Validators.required]],
            'fault': [res.fault, [Validators.required]],
            'open': [res.open, [Validators.required]],
          });
          claimsControl.push(data_claims);
        })

      }
    }

    this.setCoverToDate(this.modelObj.inception_date);
    this.rating_type(this.modelObj.rating_type);

    if (this.apiModelObj.financial_details) {
      this.modelObj.broker_commission = this.apiModelObj.financial_details.broker_commission;
      this.modelObj.broker_admin_fee = this.apiModelObj.financial_details.broker_admin_fee;
    } else {
      let brokerDetails = this.brokers.filter((x => x.id == this.modelObj.company_id));
      if (brokerDetails.length > 0) {
        this.modelObj.broker_commission = brokerDetails[0].broker_commission;
        this.modelObj.broker_admin_fee = brokerDetails[0].broker_admin_fee;
      }
    }
  }


  policyEndDateMax: Date = new Date();
  onPolicyStartDateChange(event) {
    this.setCoverToDate(event.value);
  }

  setCoverToDate(value) {
    let maxDate = new Date(value).setFullYear(new Date(value).getFullYear() + 1);
    this.policyEndDateMax = new Date(new Date(maxDate).getTime() - (24 * 60 * 60 * 1000));
    this.frmFields.get('cover_to').setValue(this.policyEndDateMax);
  }

  proposer_details() {
    return this.fb.group({
      proposer_type: ['', Validators.required],
      micro_enterprise: ['', Validators.required],
      company_name: ['', Validators.required],
      trading_name: [''],
      year: ['', Validators.required],
      address: this.proposer_address(),

      title: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: [''],
      dob: ['', Validators.required],
      licence_type: [''],
      licence_length: [''],
      have_convictions: [''],
      convictions: this.fb.array([
        this.initConvictions()
      ]),
      have_claims: [''],
      claims: this.fb.array([
        this.initClaims()
      ])
    });
  }

  proposer_address() {
    return this.fb.group({
      line1: ['', Validators.required],
      line2: [''],
      line3: [''],
      county: ['', Validators.required],
      postcode: ['', Validators.required]
    });
  }

  get proposerAddressControls() {
    return ((this.proposerControls.address as FormGroup).controls)
  }

  /*
  * Disable hidden fields based on proposer type
  */
  onProposerChange(event) {
    this.disablePropserFields(event.value);
  }

  disablePropserFields(proposer_type: string) {
    if ((this.modelObj.proposer.claims && this.modelObj.proposer.claims.length > 0)) {
      this.proposerControls.claims.enable();
    } else {
      this.proposerControls.claims.disable();
    }

    this.proposerControls.convictions.disable();
    if (proposer_type == 'individual') {
      this.proposerControls.title.enable();
      this.proposerControls.first_name.enable();
      this.proposerControls.last_name.enable();
      this.proposerControls.dob.enable();
      this.proposerControls.licence_type.enable();
      this.proposerControls.licence_length.enable();
      this.proposerControls.have_convictions.enable();
      if (this.proposerControls.have_convictions.value) {
        this.proposerControls.convictions.enable();
      }
      this.proposerControls.micro_enterprise.disable();
      this.proposerControls.company_name.disable();
      this.proposerControls.year.disable();
    } else {
      this.proposerControls.title.disable();
      this.proposerControls.first_name.disable();
      this.proposerControls.last_name.disable();
      this.proposerControls.dob.disable();
      this.proposerControls.licence_type.disable();
      this.proposerControls.licence_length.disable();
      this.proposerControls.have_convictions.disable();
      this.proposerControls.convictions.disable();

      this.proposerControls.micro_enterprise.enable();
      this.proposerControls.company_name.enable();
      this.proposerControls.year.enable();

      if (proposer_type == 'joint') {
        this.proposerControls.year.disable();
        this.proposerControls.micro_enterprise.disable();
      }
    }
  }


  /*
  * Conventions
  */
  initConvictions() {
    return this.fb.group({
      'code': ['', [Validators.required]],
      'date': ['', [Validators.required]],
      'points': ['', [Validators.required]],
      'banned': [''],
      'fine': ['', [Validators.required]],
    })
  }

  addConvictions() {
    const control = (<FormArray>this.frmFields.controls['proposer']).get('convictions') as FormArray;
    control.push(this.initConvictions());
  }

  removeConvictions(i) {
    this.f.proposer['controls'].convictions.removeAt(i);
  }

  /*
  * Disable Conventions Section if option 'No' is Selected
  */
  onHaveConventionsChange(event) {
    this.f.proposer['controls'].convictions.disable();
    this.modelObj.driver_conviction_declaration = false;
    if (event.value) {
      this.modelObj.driver_conviction_declaration = true;
      this.f.proposer['controls'].convictions.enable();
    }
  }


  /*
  * Claims
  */
  initClaims() {
    return this.fb.group({
      'date': ['', [Validators.required]],
      'type': ['', [Validators.required]],
      'ad_cost': ['', [Validators.required]],
      'tp_cost': ['', [Validators.required]],
      'fault': ['', [Validators.required]],
      'open': ['', [Validators.required]],
    })
  }

  addClaims() {
    const control = (<FormArray>this.frmFields.controls['proposer']).get('claims') as FormArray;
    control.push(this.initClaims());
  }

  removeClaims(i) {
    this.f.proposer['controls'].claims.removeAt(i);
  }

  onHaveClaimsChange(event) {
    this.f.proposer['controls'].claims.disable();
    this.modelObj.driver_claim_declaration = false;
    if (event.value == 1) {
      this.modelObj.driver_claim_declaration = true;
      this.f.proposer['controls'].claims.enable();
    }
  }

  onRatingTypeChange(event) {
    this.rating_type(event.value);
  }

  rating_type(value) {
    if (value == 'fleet') {
      this.hideClaims = true;
      this.isFleetActivated.emit(true);
      this.f.proposer['controls'].claims.disable();
    } else {
      this.hideClaims = false;
      this.isFleetActivated.emit(false);
      if (this.proposerControls.have_claims.value == 1) {
        this.f.proposer['controls'].claims.enable();
      } else {
        this.f.proposer['controls'].claims.disable();
      }
    }
  }


  /*
  * POSTCODE LOOKUP
  */
  addresses;
  postcodeLookup(event) {
    let postcode = event.target.value;
    this.quoteService.getAddressPostcodeLookup(postcode)
      .subscribe(
        (response: any) => {
          this.addresses = response.addresses;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSelectAddress(address) {
    this.proposerAddressControls.line1.setValue(address.line_1);
    this.proposerAddressControls.line2.setValue(address.line_2);
    this.proposerAddressControls.line3.setValue(address.line_3);
    this.proposerAddressControls.county.setValue(address.county);
  }

  onSubmit() {
    this.submitted = true;
    if (this.quote_type != 3 && !this.modelObj.effective_date) {
      this.frmFields.get('effective_date')!.disable();
    }
    if (!this.frmFields.valid) {
      return false;
    }
    let formData = this.frmFields.value;
    //this.modelObj = formData;
    this.modelObj.cover_to = formData.cover_to;
    this.modelObj.broker_id = this.sessionSt.retrieve('userInfo').id;
    this.modelObj.proposer = formData.proposer;
    this.modelObj.rating_type = formData.rating_type;
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      this.modelObj.company_id = this.sessionSt.retrieve('broker_company_id');
    } else {
      this.modelObj.company_id = formData.company_id;
    }
    if (this.route.snapshot.params.id) {
      //this.modelObj.id = this.route.snapshot.params.id;
    } else {
      if (!this.modelObj.id) {
        this.modelObj.vehicles = null;
        this.modelObj.fleet_experiences = null;
      }
    }

    this.modelObj.inception_date = formatDate(formData.inception_date, 'yyyy-MM-dd', 'en-US');
    if (formData.proposer && formData.proposer.dob) {
      this.modelObj.proposer.dob = formatDate(formData.proposer.dob, 'yyyy-MM-dd', 'en-US');
    }
    this.modelObj.declaration = true;


    if (this.quote_type == 3) {
      if (this.apiModelObj && this.apiModelObj.amlin_data && this.apiModelObj.amlin_data.data && this.apiModelObj.amlin_data.data.attributes.application.policy_id) {
        this.modelObj.policy_id = this.apiModelObj.amlin_data.data.attributes.application.policy_id;
        this.modelObj.effective_date = formatDate(formData.effective_date, 'yyyy-MM-dd', 'en-US');
        this.modelObj.old_policy_id = this.modelObj.id;
        this.modelObj.id = null;
        this.modelObj.type = 3;
      }
    }


    let drivers = [];
    if (this.modelObj.drivers) {
      this.modelObj.drivers.forEach((res) => {
        if (res.first_name != '') {
          drivers.push(res);
        }
      })
    }
    this.modelObj.drivers = drivers;

    if (this.modelObj.type == 2) {
      this.modelObj.policy_id = null;
      this.modelObj.effective_date = null;
    }

    if (this.modelObj.drivers && this.modelObj.drivers.length == 1) {
      if (!this.modelObj.drivers[0].id) {
        this.modelObj.drivers = [];
      }
    }


    let data = {
      data: {
        "type": (this.quote_type == 3) ? 3 : this.modelObj.type,
        "attributes": this.modelObj,
        "id": this.modelObj.id,
        "broker_id": this.modelObj.broker_id,
        "company_id": this.modelObj.company_id,
        "sequence_number": this.modelObj.sequence_number,

        "policy_ref_number": this.modelObj.policy_ref_number,
        "old_policy_id": this.modelObj.old_policy_id,

        "quote_meta_id": this.modelObj.quote_meta_id,
      }

    };
    this.as.saveQuote(data)
      .subscribe(
        (res: any) => {
          if (res.success) {
            if (res.result.quote_data && res.result.quote_data.quote_versions[0].amlin_data) {
              this.modelObj = res.result.quote_data.quote_versions[0].amlin_data.attributes.application;
              this.modelObj.id = res.result.quote_data.quote_versions[0].amlin_data.id;
              this.modelObj.broker_id = res.result.quote_data.quote_versions[0].broker_id;
              this.modelObj.company_id = res.result.quote_data.quote_versions[0].company_id;
              this.modelObj.policy_ref_number = res.result.quote_data.quote_versions[0].policy_ref_number;
              this.modelObj.quote_meta_id = res.result.quote_data.quote_versions[0].quote_meta_id;
              this.modelObj.reference = res.result.quote_data.quote_versions[0].amlin_data.attributes.reference;
              this.modelObj.status = res.result.quote_data.quote_versions[0].amlin_data.attributes.status;
              this.modelObj.company_id = res.result.quote_data.quote_versions[0].company_id;
              this.modelObj.broker_commission = res.result.quote_data.quote_versions[0].financial_details.broker_commission;
              this.modelObj.broker_admin_fee = res.result.quote_data.quote_versions[0].financial_details.broker_admin_fee;
              this.modelObjUpdated.emit(this.modelObj);
            };
          } else {
            this._snackBar.open(res.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

}
