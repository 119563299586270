<div class="bg-white text-green p-5 text-center">
    <input type="hidden" name="id" placeholder="id" class="form-control form-control-sm" [(ngModel)]="quote.id">
    <h4 class="">Insurance Quote reference is: <a [routerLink]="['/quote-details',quote.id]" class="text-green">{{quote.quote_ref_number}}</a></h4>
    <p>Your request has been submitted to the Underwriters at Peliwica.<br><br>

        Peliwica will confirm the full premium, terms and conditions and any additional information that may
        be required within 4 working hours. The policy schedule or quotation schedule and all supporting
        documentation will be made available in your work inbox for delivery to your customer. If you have
        any questions please call, email or use our webchat option and we will be happy to assist.</p>
    <br><br>
    <h6>IMPORTANT INFORMATION</h6>
    <p>
        Please submit any clinical history documentation and previous years renewal invitation.
    </p>
    <p><a routerLink="/quote-list" class="btn btn-success btn-gray mt-3">Back to Dasboard</a></p>
</div>