import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-unauthorized-access',
  templateUrl: './unauthorized-access.component.html',
  styleUrls: ['../quote/quote.component.css','./unauthorized-access.component.css']
})
export class UnauthorizedAccessComponent implements OnInit {

  constructor(private sessionSt: LocalStorageService) { }

  msg: string = 'This broker is not authorised to access this resource';
  ngOnInit(): void {
  }
  ngDoCheck(): void {
    this.msg = this.sessionSt.retrieve('unauthorized_msg');
  }

}
