import { Component, OnInit, Output, EventEmitter, SimpleChanges, Input } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['../quote.component.css'],
})
export class SubmissionComponent implements OnInit {
  @Input() quoteDetails: QuoteVersions;
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  quote:QuoteVersions;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
  }

  ngOnInit(): void {
  }

}
