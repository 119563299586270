import { Component, OnInit,SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteService } from '../../../quote/quote.service';
import { AlQuoteService } from '../../../quote/alpaca/al-quote/al-quote.service';
import { QuoteVersions } from '../../../quote/alpaca/model/quote/quote_versions.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PolicyService } from '../../policy.service';
import { UserInfoService } from '../../../services/user-info.service';

@Component({
  selector: 'app-al-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.css']
})
export class AlPolicyDetailsComponent implements OnInit {
  quoteData = new QuoteVersions();
  staticData;
  isAdmin = false;
  isInsurer = false;
  isStaff = false;
  isBroker = false;
  is_NTU: boolean = false;
  enableLapse: boolean = false;
  actionDropDown: boolean = false;
  isBrokerCommissionReduced: boolean = false;

  constructor(private route: ActivatedRoute, private quoteService: QuoteService, private sessionSt: LocalStorageService,
    private router: Router, private modalService: NgbModal, private _snackBar: MatSnackBar, private policyService: PolicyService, private userService: UserInfoService) { }


  hasImportantNotes: boolean = false;
  totalImpNotes: number = 0;
  isPolicy: boolean = false;
  isPeliwicaAdmin: boolean = false;

  ngOnInit(): void {
    if (this.sessionSt.retrieve('is_admin')) {
      this.isAdmin = true;
    }

    if (this.sessionSt.retrieve('is_insurer')) {
      this.isInsurer = true;
    }
    
    if (this.sessionSt.retrieve('is_staff')) {
      this.isStaff = true;
    }
    
    if (this.sessionSt.retrieve('is_broker')) {
      this.isBroker = true;
    }
    
    // GET QUOTE DETAILS
   this.quoteService.getQuoteDetailsByQuoteID(this.route.snapshot.params.quote_id, 1)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteData = response.result;
            // Enable Actions Dropdown
            this.getQuoteNotes();
            this.policyActionsBTNShowHide();
            this.getBrokerCommissions();
            // Enable NTU  
            if (this.quoteData.policy_start_date && this.sessionSt.retrieve('company_role') == 'Broker') {
              let currentDate = new Date()
              let policy_start_date = new Date(this.quoteData.policy_start_date);
              var Difference_In_Time = currentDate.getTime() - policy_start_date.getTime();
              if (Difference_In_Time / (1000 * 3600 * 24) < 14) {
                this.is_NTU = true;
                this.enableLapse = true;
              }
            } else {
              this.is_NTU = true;
              this.enableLapse = true;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );


  }
  ngOnChanges(changes: SimpleChanges) {
    let newDate = new Date();
    if (this.quoteData.policy_end_date) {
      let policy_end_date = new Date(this.quoteData.policy_end_date);
      var Difference_In_Time = policy_end_date.getTime() - newDate.getTime();
      let Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days_cancle < 14 && this.sessionSt.retrieve('company_role') != 'Broker') {
        this.is_NTU = true;
      }
    }
    this.getQuoteNotes();
    this.policyActionsBTNShowHide();
  }
  getQuoteNotes() {
    if (this.quoteData.id) {
      this.quoteService.getNotes(this.quoteData.id)
        .subscribe(
          (response: any) => {
            let impNotes = response.result.filter(x => x.is_important == 1);
            this.totalImpNotes = impNotes.length;
            if (impNotes.length > 0) {
              this.hasImportantNotes = true;
            }
          });
    }
  }

  getBrokerCommissions() {
    if (this.quoteData.company_id) {
      this.quoteService.getLiveStockBrokerCommission(this.quoteData.company_id, this.quoteData.product_id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              let brokers_data = response.result;
              if (brokers_data != null) {
                if (this.quoteData.financial_details.broker_commission != brokers_data.broker_commission) {
                  this.isBrokerCommissionReduced = true;
                }
              }
            }
          });
    }
  }

  policyActionsBTNShowHide() {
    if(this.quoteData.type == 1 || this.quoteData.type == 2 || this.quoteData.type == 3) {
      this.actionDropDown = true;
    } else {
      this.actionDropDown = false;
    }
  }
  onhasImportantNotesChange(event) {
    this.hasImportantNotes = event;
    this.totalImpNotes = this.totalImpNotes + 1;
  }

  quoteDetailsUpdated(quotedata) {
    this.quoteData = quotedata;
  }


  /*
   * Convet To MTA PopUp - List of Reasons
   */
  mtaMsg: string = '';
  policyActions(content, type, delete_mta = 0) {
    if (type == 3) {
      this.policyService.mtaCheck(this.quoteData.quote_meta_id, delete_mta)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.modalService.dismissAll();   
              this.sessionSt.store('showHideMTAReasons', true);  // Enable to clear mta reasons id if the page loads first time under quote edit screen            
              this.router.navigateByUrl("/pre-qualifier/2/" + this.quoteData.id + '/' + type);
            } else {
              this.modalService.open(content, { centered: true });
              this.mtaMsg = response.message;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.router.navigateByUrl("/pre-qualifier/2/" + this.quoteData.id + '/' + type);
    }
  }

  displayEmailView: boolean = false;
  displayNewEmailView: boolean = false;
  newEmailView() {
    this.displayNewEmailView = true;
  }

  email_id: string = '';
  getEmailView(email_id) {
    this.email_id = email_id;
    this.displayEmailView = true;
  }
  backToEmailsList() {
    this.displayEmailView = false;
    this.displayNewEmailView = false;
  }

}
