import { Component, OnInit, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { QuoteService } from '../../../quote/allianz-equine/allianz.service';
import { PolicyService } from '../../policy.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';
import { UserInfoService } from '../../../services/user-info.service';

@Component({ 
  selector: 'app-allianz-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.css']
})
export class AllianzPolicyDetailsComponent implements OnInit {
  quoteData = new QuoteVersions(); 
  staticData;
  policyReasons;
  is_NTU: boolean = false;
  enableLapse: boolean = false;
  latestVersion: number = 0;
  isBrokerCommissionReduced: boolean = false;

  constructor(private quoteService: QuoteService, private route: ActivatedRoute, private userService: UserInfoService,
    private policyService: PolicyService, private modalService: NgbModal, private router: Router, private sessionSt: LocalStorageService) { }

  isBroker: boolean = false;
  isAdmin: boolean = false;
  isInsurer: boolean = false;
  isStaff: boolean = false;
  hasImportantNotes: boolean = false;
  hasImportantClaims:boolean=false;
  totalImpNotes: number = 0;
  enableActions: boolean = false;
  isPolicyActive = false;
  LebalPolicyStaus;
  actionDropDown: boolean = false;
  ngOnInit(): void {
    if (this.sessionSt.retrieve('is_broker')) {
      this.isBroker = true;
    }

    if (this.sessionSt.retrieve('is_admin')) {
      this.isAdmin = true;
    }

    if (this.sessionSt.retrieve('is_insurer')) {
      this.isInsurer = true;
    }
    if (this.sessionSt.retrieve('is_staff')) {
      this.isStaff = true;
    }

    // GET POLICY DETAILS
    this.quoteService.getQuoteDetailsByQuoteID(this.route.snapshot.params.quote_id, 1)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteData = response.result;
            this.quoteData.is_policy = 1;
            this.getBrokerCommissions();
            // let quote_versions = response.result.versions.filter(x => x.is_policy == 1);
            // this.quoteData.quote_versions = quote_versions;
            this.latestVersion = response.result.latest_version;
            this.policyActions();
            
            // Enable Actions Dropdown
            let currentDate = new Date()
            let policy_start_date = new Date(this.quoteData.policy_start_date);
            var Difference_In_Time = currentDate.getTime() - policy_start_date.getTime();
            if (Difference_In_Time >= 0) {
              this.enableActions = true;
            }

            this.getStaticData(response.result.rating_version);

            // Enable NTU  
            if (this.quoteData.policy_start_date && !this.sessionSt.retrieve('is_admin')) {
              let currentDate = new Date()
              let policy_start_date = new Date(this.quoteData.policy_start_date);
              var Difference_In_Time = currentDate.getTime() - policy_start_date.getTime();
              if (Difference_In_Time / (1000 * 3600 * 24) < 14) {
                this.is_NTU = true;
                this.enableLapse = true;
              }
            } else {
              this.is_NTU = true;
              this.enableLapse = true;
            }
            

            // Check Policy Active Or Inactive
            let policy_end_date = new Date(this.quoteData.policy_end_date);
            let policy_end_date_time = policy_end_date.getTime();
            let current_date_time = currentDate.getTime();
            if(policy_end_date_time > current_date_time ){
              this.isPolicyActive = true;
              this.LebalPolicyStaus = "Active";
            }
            else{
              this.LebalPolicyStaus = "Inactive";
            }


            //get Claims
            response.result.versions.forEach((res) => {
              this.getClaims(res.quote_id);
            })
            
          }
        },
        (error) => {
          console.log(error);
        }
      );
     
    // API - GET NOTES
    this.quoteService.getNotes(this.route.snapshot.params.quote_id)
      .subscribe(
        (response: any) => {
          let impNotes = response.result.filter(x => x.is_important == 1);
          this.totalImpNotes = impNotes.length;
          if (impNotes.length > 0) {
            this.hasImportantNotes = true;
          }
        });
    
            // API - GET NOTES
    this.quoteService.getQuoteClaims(this.route.snapshot.params.quote_id)
    .subscribe(
      (response: any) => {
        let impClaims = response.result;
        if (impClaims.length > 0) {
          this.hasImportantClaims = true;
        }
      });
        
  }

  ngOnChanges(changes: SimpleChanges) {
    let newDate = new Date();
    
    if (this.quoteData.policy_start_date && this.sessionSt.retrieve('company_role') == 'Broker') {
      let currentDate = new Date()
      let policy_start_date = new Date(this.quoteData.policy_start_date);
      var Difference_In_Time = currentDate.getTime() - policy_start_date.getTime();
      if (Difference_In_Time / (1000 * 3600 * 24) < 14) {
        this.is_NTU = true;
      }
    } else {
      this.is_NTU = true;
    }


    this.policyActions();
    
  }

  getClaims(quote_id) {
    this.quoteService.getQuoteClaims(quote_id)
    .subscribe(
      (response: any) => {
        let impClaims = response.result;
        if (impClaims.length > 0) {
          this.hasImportantClaims = true;
        }
      });
  }

  getBrokerCommissions() {
    if (this.quoteData.company_id) {
      this.quoteService.getLiveStockBrokerCommission(this.quoteData.company_id, this.quoteData.product_id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              let brokers_data = response.result;
              if (brokers_data != null) {
                if (this.quoteData.financial_details.broker_commission != brokers_data.broker_commission) {
                  this.isBrokerCommissionReduced = true;
                }
              }
            }
          });
    }
  }

  // Hide action dropdown based on insurance status
  policyActions() {
    if(this.quoteData.type == 1 || this.quoteData.type == 2 || this.quoteData.type == 3 || this.quoteData.type == 4) {
      this.actionDropDown = true;
    } else {
      this.actionDropDown = false;
    }
    // switch(this.quoteData.insurance_status_id) {
    //   case '7a877243-0919-4f92-8c35-6f5732013ca6':  // Cancel
    //     this.actionDropDown = false;
    //     break;
    //   case '0c836e51-f8b0-4df8-8736-3ce56a40869f': //Lapsed
    //     this.actionDropDown = false;
    //     break;
    //   case 'dda8734c-d149-4336-b8cc-c2e205430752': // Declined
    //     this.actionDropDown = false;
    //     break;
    //   case '9bcea739-fd0b-4c67-980c-9e0dadeb8445': // NTU
    //     this.actionDropDown = false;
    //     break;
    //   default:
    //     this.actionDropDown = true;
    //     break;
    // }
  }

  updatedStaticData(rating_version) {
    this.getStaticData(rating_version);
  }
  getStaticData(rating_version) {
    // GET STATIC DATA
    this.quoteService.getStaticData(rating_version)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.staticData = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  onhasImportantNotesChange(event) {
    this.hasImportantNotes = event;
    this.totalImpNotes = this.totalImpNotes + 1;
  }
  onhasClaimsChange(event) {
    this.hasImportantClaims = event;
  }
  /*
  * Convet To MTA PopUp - List of Reasons
  */
  mtaMsg: string = '';
  quoteType = '';
  convert_to_mta(content, type, delete_mta = 0) {
   // if (type == 3 || type == 6) {
      this.policyService.mtaCheck(this.quoteData.quote_meta_id, delete_mta)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.modalService.dismissAll();
              this.sessionSt.store('showHideMTAReasons', true);  // Enable to clear mta reasons id if the page loads first time under quote edit screen            
              this.router.navigateByUrl("/pre-qualifier/4/" + this.quoteData.id + '/' + type);
            } else {
              this.modalService.open(content, { centered: true });
              this.mtaMsg = response.message;
              this.quoteType = type;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    // } else {
    //   this.router.navigateByUrl("/quote-new/" + this.quoteData.id + '/' + type);
    // }
  }



  displayEmailView: boolean = false;
  displayNewEmailView: boolean = false;
  newEmailView() {
    this.displayNewEmailView = true;
  }

  email_id: string = '';
  getEmailView(email_id) {
    this.email_id = email_id;
    this.displayEmailView = true;
  }
  backToEmailsList() {
    this.displayEmailView = false;
    this.displayNewEmailView = false;
  }

  quoteDetailsUpdated(quotedata) {
    this.quoteData = quotedata;
    this.getStaticData(quotedata.rating_version);
    this.policyActions();
  }
  
}
