<mat-expansion-panel expanded [disabled]="isAddingNotes"  class="mb-5">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <span style="line-height: 33px;">Notes</span> <button (click)="openNotesPopup(content)" class="btn btn-default btn-gray btn-sm ml-3">Add Notes</button>
        </mat-panel-title>
    </mat-expansion-panel-header>
        <ag-grid-angular style="width: 100%;" class="ag-theme-bootstrap" [gridOptions]="gridOptions"
            [rowData]="rowData" [columnDefs]="columnDefs" animateRows pagination=false paginationPageSize=5 rowHeight=27
            headerHeight=35 domLayout='autoHeight'
            (rowClicked)='onGridRowClicked($event,content,deleteModel,notesViewModel)'> </ag-grid-angular>
</mat-expansion-panel>
<mat-expansion-panel expanded [disabled]="isAddingDocs">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <span style="line-height: 33px;">Documents</span> <button (click)="openDocumentsPopup(docsContent)"
            class="btn btn-default btn-gray btn-sm ml-3">Add Document</button>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ag-grid-angular style="width: 100%; height: 400px;" class="ag-theme-bootstrap" [gridOptions]="gridOptionsDocs"
            [rowData]="rowDataDocs" [columnDefs]="columnDefsDocs" animateRows pagination paginationPageSize=10 rowHeight=27
            headerHeight=35 (rowClicked)='onDocsGridRowClicked($event,docsContent,documentsDelete)'>
        </ag-grid-angular>
</mat-expansion-panel>

<!--  NOTES POPUP -->
<ng-template #content let-modal>
    <div class="modal-body">
        <div class="form-group">
            <label>Notes</label>
            <textarea name="notes" class="form-control" [(ngModel)]="notes.notes" rows="5"></textarea>
        </div>
        <div class="form-group">
            <mat-checkbox name="is_important" value="1" [(ngModel)]="notes.is_important"
                [checked]="notes.is_important == 1">
                Is this note important?
            </mat-checkbox>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="saveNotes()" class="btn btn-success btn-sm pl-4 pr-4 pt-2 pb-2">Save</button>
        <button type="button" class="btn btn-default btn-gray btn-sm" (click)="onCloseNotesModal()">Close</button>
        <!-- <button type="button" class="btn btn-default btn-gray btn-sm" (click)="modal.close()">Close</button> -->
    </div>
</ng-template>
<ng-template #deleteModel let-modal>
    <div class="modal-body">
        <p>Are you sure you want to delete note?</p>
    </div>

    <div class="modal-footer">
        <button type="button" name="ok" (click)="deleteNotes(notes.id,selectedRow)"
            class="btn btn-success btn-sm pl-4 pr-4 pt-2 pb-2">OK</button>
        <button type="button" class="btn btn-default btn-gray btn-sm" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<ng-template #notesViewModel let-modal>
    <div class="modal-body">
        {{ notesText }}
    </div>
</ng-template>

<!--  DOCUMENTS -->
<ng-template #docsContent let-modal>
    <div class="modal-header">
        <h4>Document</h4>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Title</label>
            <input type="text" name="document_title" [(ngModel)]="docTitle" class="form-control form-control-sm">
        </div>
        <div class="form-group" *ngIf="!documentsEditMode">
            <label>Document</label>
            <div class="custom-file">
                <input type="file" name="file_name" (change)="getUploadedFile($event.target.files)"
                    class="custom-file-input" id="customFile">
                <label class="custom-file-label" for="customFile">{{quote.quote_document}}</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" *ngIf="!documentsEditMode" class="btn btn-success"
            (click)="documentUpload()">Upload</button>
        <button type="button" *ngIf="documentsEditMode" class="btn btn-success"
            (click)="updateDocument()">Update</button>
        <button type="button" class="btn btn-default btn-gray" (click)="onCloseDocsModal()">Close</button>
    </div>
</ng-template>
<ng-template #documentsDelete let-modal>
    <div class="modal-body">
        Are you sure you want to delete document {{docTitle}}?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="deleteDocument()">Yes</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
    </div>
</ng-template>