<form class="g-3 needs-validation" novalidate [formGroup]="frmFields" (ngSubmit)="onSubmit()">
<!------------- CONVICTIONS  ------------------>
<div>  
    <div formArrayName="convictions" *ngIf="staticData" class="pt-0">
        <div *ngFor="let c of convictionsArr.controls; let i=index">
            <div formGroupName="{{i}}" class="mt-3">
                <u class="text-white">Conviction {{ i+1 }}</u> <a (click)="removeConvictions(i)" class="btn btn-danger btn-sm p-1 ml-3"
                    *ngIf="i > 0"><i class="mdi mdi-delete"></i></a>
                <div class="row mt-2">
                    <div class="col-md-6">
                        <mat-form-field class="w-100">
                            <mat-label>Conviction code</mat-label>
                            <mat-select formControlName="code" class="form-control form-control-sm"
                                name="code" required
                                [ngClass]="{ 'is-invalid': (c.get('code').touched || submitted) && c.get('code').errors?.required }">
                                <mat-option *ngFor="let type of staticData.conviction_types"
                                    [value]="type.code">
                                    {{ type.code }}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(c.get('code').touched || submitted) && c.get('code').errors?.required">
                                Please select an option.
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group w-100 mt-2">
                            <input type="text" required name="date" formControlName="date"
                                placeholder="Date of the conviction"
                                class="form-control form-control-sm datepicker" matInput
                                (focus)="cdDatepicker.open()" [matDatepicker]="cdDatepicker"
                                [ngClass]="{ 'is-invalid': (c.get('date').touched || submitted) && c.get('date').errors?.required }">
                            <mat-datepicker-toggle [for]="cdDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #cdDatepicker></mat-datepicker>
                            <div class="invalid-feedback"
                                *ngIf="(c.get('date').touched || submitted) && c.get('date').errors?.required">
                                Date is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group w-100 mt-2">
                            <input type="number" required name="number_of_points"
                                formControlName="points" placeholder="Number of Points"
                                class="form-control form-control-sm" matInput
                                [ngClass]="{ 'is-invalid': (c.get('points').touched || submitted) && c.get('points').errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(c.get('points').touched || submitted) && c.get('points').errors?.required">
                                Number of points are required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group w-100 mt-2">
                            <input type="number" name="months_banned" formControlName="banned"
                                placeholder="Enter number of months banned" class="form-control form-control-sm"
                                matInput>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group w-100 mt-2">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="number" required name="fine" formControlName="fine"
                                    placeholder="Enter fine amount" class="form-control form-control-sm"
                                    matInput
                                    [ngClass]="{ 'is-invalid': (c.get('fine').touched || submitted) && c.get('fine').errors?.required }">
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="(c.get('fine').touched || submitted) && c.get('fine').errors?.required">
                                Amount is required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a (click)="addConvictions()" class="btn btn-success btn-sm" *ngIf="is_convictions_selected">Add Convictions</a>
    </div>

</div>
</form>