<div [formGroup]="proposerSection">
    <div class="form_sections">
        <mat-expansion-panel [expanded]="selectedTabIndex == 1">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    About the Proposer
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group"> 
                        <input type="text" name="first_name" placeholder="First Name" [readonly]="quote_type > 3"
                            class="form-control form-control-sm" (keyup)="firstnameToTotleCase($event)"
                            formControlName="first_name" required (blur)="updateModel()"
                            [ngClass]="{ 'is-invalid': (proposerSectionControl.first_name.touched || submitted) && proposerSectionControl.first_name.errors?.required }"
                            appTitleCase>
                        <div class="invalid-feedback"
                            *ngIf="(proposerSectionControl.first_name.touched || submitted) && proposerSectionControl.first_name.errors?.required">
                            First Name is required.
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" name="last_name" placeholder="Last Name" class="form-control form-control-sm"
                            (keyup)="lastnameToTotleCase($event)" formControlName="last_name" required [readonly]="quote_type > 3"
                            [ngClass]="{ 'is-invalid': (proposerSectionControl.last_name.touched || submitted) && proposerSectionControl.last_name.errors?.required }"
                            appTitleCase (blur)="updateModel()">
                        <div class="invalid-feedback"
                            *ngIf="(proposerSectionControl.last_name.touched || submitted) && proposerSectionControl.last_name.errors?.required">
                            Last Name is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="email" name="email" placeholder="Email" class="form-control form-control-sm"
                            formControlName="email" required [readonly]="quote_type > 3"
                            pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                            [ngClass]="{ 'is-invalid': (proposerSectionControl.email.touched || submitted) && proposerSectionControl.email.errors?.required }"
                            appTitleCase (blur)="updateModel()">
                        <div class="invalid-feedback" *ngIf="proposerSectionControl.email.touched || submitted">

                            <div *ngIf="proposerSectionControl.email.errors?.required">Email is required.</div>
                            <div *ngIf="proposerSectionControl.email.errors?.pattern">Please provide a valid email
                                address</div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</div>