<form class="pt-3" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <label for="exampleInputEmail">Username</label>
        <div class="input-group">
            <div class="input-group-prepend bg-transparent">
                <span class="input-group-text bg-transparent border-right-0">
                    <i class="mdi mdi-account-outline text-primary"></i>
                </span>
            </div>
            <input type="text" name="username" formControlName="username"
                class="form-control form-control-lg border-left-0" placeholder="Username"
                [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
        </div>
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Username is required</div>
        </div>
    </div>
    <div class="form-group">
        <label for="exampleInputPassword">Password</label>
        <div class="input-group">
            <div class="input-group-prepend bg-transparent">
                <span class="input-group-text bg-transparent border-right-0">
                    <i class="mdi mdi-lock-outline text-primary"></i>
                </span>
            </div>
            <input type="password" formControlName="password" class="form-control form-control-lg border-left-0"
                placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
        </div>
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            <div *ngIf="f.password.errors.minlength">
                Password must be at least 8 characters long.
            </div>
        </div>
    </div>

    <div class="my-3">
        <button class="btn btn-block btn-success btn-lg font-weight-medium auth-form-btn">LOGIN</button>
    </div>
    <div class="my-2 d-flex justify-content-between align-items-center">
        <!-- <div class="form-check">
                                <label class="form-check-label text-muted">
                                    <input type="checkbox" class="form-check-input">
                                    Keep me signed in
                                </label>
                            </div> -->
        <a routerLink="/forgot-password" class="auth-link text-black">Forgot password?</a>
    </div>
</form>