import { Component, OnInit, Input, forwardRef, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, AbstractControl, FormGroup, FormArray, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-am-convictions',
  templateUrl: './am-convictions.component.html',
  styleUrls: ['./am-convictions.component.css', '../../quote.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmConvictionsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AmConvictionsComponent),
      multi: true
    }
  ]
})
export class AmConvictionsComponent implements OnInit {
  frmFields: FormGroup;
  @Input() submitted: boolean;
  @Input() staticData;
  @Input() modelObj;
  @Input() driver_id;
  @Input() is_convictions_selected;
  subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {

  }

  /*
* ControlValueAccessor methods
* registerOnChange, writeValue, registerOnTouched
* Used to pass data to parent form
*/
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }
  writeValue(value) {

  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /*
  * Indicate parent form if convictions form is valid or not.
  */
  validate(_: FormControl) {
    return this.frmFields.valid ? null : { drivers: { valid: false } };
  }

  get f(): { [key: string]: AbstractControl } {
    return this.frmFields.controls;
  }

  get convictionsArr(): FormArray {
    return this.frmFields.get("convictions") as FormArray
  }

  ngOnChanges(changes: SimpleChanges) {
    this.frmFields = this.fb.group({
      convictions: this.fb.array([])
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.frmFields.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    if (this.driver_id && this.modelObj && this.modelObj.drivers && this.modelObj.drivers.length > 0) {
      let driver_convictions = this.modelObj.drivers.filter(x => x.id == this.driver_id);
      if (driver_convictions.length > 0 && driver_convictions[0].convictions) {
        this.convictionsArr.clear();
        driver_convictions[0].convictions.forEach((res1) => {
          let c = this.fb.group({
            'code': [res1.code],
            'date': [res1.date],
            'points': [res1.points],
            'banned': [res1.banned],
            'fine': [res1.fine],
          })
          this.convictionsArr.push(c);
        })
      }
    }
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  initConvictions() {
    return this.fb.group({
      'code': ['', [Validators.required]],
      'date': ['', [Validators.required]],
      'points': ['', [Validators.required]],
      'banned': [''],
      'fine': ['', [Validators.required]],
    })
  }

  /*
  * Claim
  */
  addConvictions() {
    this.convictionsArr.push(this.initConvictions());
  }

  removeConvictions(i) {
    this.convictionsArr.removeAt(i);
  }

  onSubmit() {
    this.submitted = true;
    if (!this.frmFields.valid) {
      return false;
    }
  }

}
