<div class="text-white p-4">
    <div class="row">
        <div class="col-md-8">
                <h3>{{ emailDetails.subject }}</h3>
                <h5>{{ emailDetails.sender_name }}</h5>
                <p class="f-13">Sent: {{ emailDetails.created_at | date: 'dd-MM-yyyy hh:mm a'}}</p>
                <p class="f-13">
                    To: {{ emailDetails.email_to }} <br>
                    <span *ngIf="emailDetails.email_cc">Cc: {{ emailDetails.email_cc }}<br></span> 
                    <span *ngIf="emailDetails.email_bcc">Bcc: {{ emailDetails.email_bcc }}</span>
                </p>
            </div>
        <div class="col-md-4">
            <button (click)="returnToEmailsList()" class="btn btn-success btn-sm float-right">Back to Emails List</button>
        </div>
    </div>
    
    
    <div class="email_body mt-4"  [innerHTML]="emailDetails.body"></div>
    <div *ngIf="attachment_details"  class="mt-4">
        <h5>Attachments</h5>
        <ul>
            <li [hidden]="!attachments[0]" *ngFor="let attachments of attachment_details">
                <a (click)="downloadAttachments(attachments)"><img src="assets/images/download.png" width="17"> &nbsp;{{ attachments[0] }}</a>
            </li>
        </ul>
    </div>
</div>