import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../model/quote/quote_versions.model';
import { QuoteEndorsement } from '../model/quote/quote_endorsement.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { QuoteService } from '../quote/quote.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GridOptions } from "ag-grid-community";
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-quote-endorsement',
  templateUrl: './quote-endorsement.component.html',
  styleUrls: ['./quote-endorsement.component.css']
})
export class QuoteEndorsementComponent implements OnInit {
  @Input() quote: QuoteVersions;
  endorsement = new QuoteEndorsement();
  editType = 'fullRow';

  constructor(private modalService: NgbModal, private quoteService: QuoteService, private sharedService: SharedService, 
    private _snackBar: MatSnackBar, private sessionSt: LocalStorageService) { }

  ngOnChanges(changes: SimpleChanges) {
    // GET ENDORSEMENT
    this.rowData = this.quote.endorsements;
    if (this.sessionSt.retrieve('is_broker')) { 
      if(this.rowData) {
        this.rowData.forEach((res) => {
          res.isBroker = true;
        })
      }
    }
  }

 isAdmin: boolean = false;
  ngOnInit(): void {
    if (this.sessionSt.retrieve('is_admin')) {
      this.isAdmin = true;
    }
  }

  // SAVE ENDORSEMENT
  addEndorsement(content) {
    this.modalService.open(content, { centered: true });
    this.endorsement = new QuoteEndorsement();
  }
  onEndorsementSubmit(f: NgForm) {
    let data = {
      id: this.endorsement.id,
      quote_id: this.quote.id, 
      title: this.endorsement.title,
      endorsement_text: this.endorsement.endorsement_text,
      type: this.endorsement.type
    };
    this.quoteService.saveEndorsement(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.rowData = response.result.endorsement_list;
            if (this.sessionSt.retrieve('is_broker')) { 
              if(this.rowData) {
                this.rowData.forEach((res) => {
                  res.isBroker = true;
                })
              }
            }
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
          this.modalService.dismissAll();
        });
  }

  // GET ENDORSEMENT
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }


  };
  columnDefs = [
    { headerName: 'Title', field: 'title', sortable: true, width: 200, editable: false },
    {
      headerName: 'Endorsement', field: 'endorsement_text', sortable: true, editable: false, width: 500,
      cellRenderer: function (param) {
        return '<a data-action-type="view">' + param.data.endorsement_text.replace('\r\n', '<br>') + '</a>';
      },
      tooltip: function (param) {
        return param.data.endorsement_text;
      },
    },
    {
      headerName: 'Created At', field: 'created_at', sortable: true, width: 200,
      comparator: this.sharedService.agGridDteSortingComparator,
      cellRenderer: function (params) {
        return formatDate(params.data.created_at, 'dd-MM-yyyy hh:mm a', 'en-US');
      }
    },
    {
      headerName: null,
      width: 120,
      cellRenderer: function (params) {
        if (!params.data.isBroker) {
          let links = '<div class="text-right">';
          links += '<a title="Edit Endorsement" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
          links += '<a title="Delete" style="font-size: 18px; margin-left: 20px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete" id="' + params.data.id + '"></i></a>';
          links += '</div>';
          return links;
        } else {
          return '';
        }
      }
    }
  ];
  rowData: any=[];

  selectedRow;
  defaultColDef = {
    flex: 1,
    wrapText: true,
    autoHeight: true,
    sortable: true,
    resizable: true,
  };
  onColumnResized(params) {
    params.api.resetRowHeights();
  }
  endorsementText;
  onGridRowClicked(e: any, content, deleteModel, endorsementViewModel) {
    if (e) {

      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {

            const modalRef = this.modalService.open(content, { centered: true });
            e.data.type = Number(e.data.type);
            this.endorsement = e.data;
            break;
          }
        case "view":
          {
            this.modalService.open(endorsementViewModel, { centered: true });
            this.endorsementText = e.data.endorsement_text.replace('\r\n', '<br>');
            break;
          }
        case "delete": {
          this.modalService.open(deleteModel, { centered: true });
          this.selectedRow = this.gridOptions.api.getFocusedCell();
          this.endorsement = e.data;
        }
      }
    }
  }

  deleteEndorsement(id, selectedRow) {
    this.quoteService.deleteEndorsement(id)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
          this.gridOptions.api.setRowData(this.gridOptions.rowData);
        });
  }

}
