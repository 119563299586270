import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../../quote/alpaca/model/quote/quote_versions.model';

@Component({
  selector: 'app-al-policy-financials',
  templateUrl: './financials.component.html',
  styles: [
  ]
})
export class AlPolicyFinancialsComponent implements OnInit {
  @Input() quoteData: QuoteVersions;
  quote;
  total_premium_ipt: number = 0;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteData;
    if (this.quote && this.quote.financial_details) {
      this.total_premium_ipt = Number(this.quote.financial_details.policy_premium) + (Number(this.quote.financial_details.policy_premium) * Number(this.quote.financial_details.ipt_fee)) / 100;
    }
  }

  ngOnInit(): void {

  }

}