import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ElementRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Quote } from '../../model/quote/quote.model';
import { formatDate } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { HorseDetailsTrailer } from '../../model/quote/horse_details_trailer.model';
import { AlCalculationsDetails } from '../al-calculations/al-calculations.model';
import { AlQuoteService } from '../al-quote.service';
import { AlpacaDetails } from '../../model/quote/alpaca_details.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployerLiability } from '../../model/quote/employer_liability.model';
import { AnimalType } from '../../model/quote/animal_type.model';
import { LocalStorageService } from 'ngx-webstorage';
import { UserInfoService } from '../../../../services/user-info.service';
import { AboutAlpaca } from '../../model/quote/about_alpaca.model';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-admin-al-full-details',
  templateUrl: './al-full-details.component.html',
  styleUrls: ['../../../quote.component.css']
})
export class AdminAlFullDetailsComponent implements OnInit {
  @Input() quoteDetails: Quote;
  @Output() quoteDetailsUpdated = new EventEmitter<Quote>();
  @Output() BackToQuoteDetails = new EventEmitter();
  @Output() ProceedWithSOFDetails = new EventEmitter();
  Calculations = new AlCalculationsDetails();
  currDate = new Date();
  isBroker: boolean = false;
  brokers = [];
  @Input()
  set allbrokers(value: any) {
    this.brokers = value;
  }

  policyReasons = [];
  @Input()
  set allPolicyReasons(value: any) {
    this.policyReasons = value;
  }

  ratesTextFields = [];
  ratesRadioFields = [];
  alpacaLiabiities = [];
  alpacaAllLiabiities = [];
  traierSecurity = [];
  alpacaRates;
  allClaims = [];
  modelBtnText: string = 'Close';
  modelBtnClass: string = 'btn-default btn-gray';
  minimum_mortality_premium: number = 0;
  @Input()
  set staticData(value: any) {
    if (value) {

      // Alpaca Rates
      this.alpacaRates = value.alpaca_rates;
      // Alapca Liabiity
      this.alpacaAllLiabiities = value.alpaca_liability;
      value.alpaca_liability.forEach((res) => {
        res.is_checked = false;
      })
      this.alpacaLiabiities = value.alpaca_liability.filter(x => x.indeminity_limit == '1 Million');
      //Trailer Security
      this.traierSecurity = value.trailer_security;

      // Claims
      this.allClaims = value.claim_loading;

      this.minimum_mortality_premium = Number(value.minimum_mortality_premium);
    }
  }


  constructor(private modalService: NgbModal, private quoteService: AlQuoteService, private _snackBar: MatSnackBar, private userService: UserInfoService,
    private el: ElementRef, private route: ActivatedRoute, private sessionSt: LocalStorageService, private alquoteService: AlQuoteService) { }

  ngOnInit(): void {
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      this.isBroker = true;
    }

    if (this.sessionSt.retrieve('user_group') == 'Staff') {
      this.minPolicyDate = new Date();
    }


  }

  quote;
  minDOB;
  minPolicyDate;
  maxPolicyDate;
  mta_quote_data;
  quote_type = 0;
  ngDoCheck() {
    // Employer Liability Initiaization
    if (this.quote && this.quote.quote_versions.length > 0) {
      if (!this.quote.quote_versions[0].employer_liability) {
        this.quote.quote_versions[0].employer_liability = new EmployerLiability();
      }

      // Animal Type Initiaization
      if (!this.quote.quote_versions[0].animal_type) {
        this.quote.quote_versions[0].animal_type = new AnimalType();
      }
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
    if (this.quoteDetails.quote_versions.length > 0) {
      this.mta_quote_data = this.quoteDetails.quote_versions[0];

      this.Calculations.mta_date = this.quote.quote_versions[0].policy_start_date;
    }

    if (!this.quote.quote_versions[0].about_alpaca) {
      this.quote.quote_versions[0].about_alpaca = new AboutAlpaca();
    }

    // GET STATIC DATA
    if (!this.alpacaRates) {
      this.alquoteService.getStaticData(this.quote.quote_versions[0].rating_version)
        .subscribe(
          (response: any) => {
            if (response.success) {
              //this.staticData = response.result;
              // Alpaca Rates
              this.alpacaRates = response.result.alpaca_rates;
              // Alapca Liabiity
              this.alpacaAllLiabiities = response.result.alpaca_liability;
              response.result.alpaca_liability.forEach((res) => {
                res.is_checked = false;
              })
              this.alpacaLiabiities = response.result.alpaca_liability.filter(x => x.indeminity_limit == '1 Million');

              // IPT/Administrative Fee
              this.Calculations.ipt_charges = Number(response.result.peliwica_ipt_commission);
              // this.Calculations.administrative_charges = Number(response.result.broker_uw_fees);


              // Claims
              this.allClaims = response.result.claim_loading;

              this.minimum_mortality_premium = Number(response.result.minimum_mortality_premium);
              this.Calculations.minimum_mortality_premium = this.minimum_mortality_premium;
              this.Calculations.calculate_data(this.mta_quote_data);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }

    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      let maxDate = new Date().setDate(new Date().getDate() + 30);
      this.maxPolicyDate = new Date(maxDate);
      this.minPolicyDate = new Date();
    }

    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);
    } else {
      this.quote_type = Number(this.quote.quote_versions[0].type);
    }
    switch (this.quote_type) {
      case 3:
        this.Calculations.is_mta = true;
        break;
      case 4:
        this.Calculations.is_cancel = true;
        this.quote.quote_versions[0].policy_start_date = this.quote.quote_versions[0].policy_start_date;
        break;
      case 6:
        //this.enableNTU();
        this.minPolicyDate = this.quote.quote_versions[0].policy_start_date;
        this.maxPolicyDate = this.quote.quote_versions[0].policy_end_date;
        break;
      default:
        this.Calculations.is_mta = false;
        this.Calculations.is_cancel = false;
        break;
    }
    this.Calculations.calculate_data(this.mta_quote_data);


    // Make smallholding activities selected
    if (this.quote.quote_versions[0].smallholding_activities) {
      this.alpacaAllLiabiities.forEach((res) => {
        if ((res.id == '52f635f8-c5c6-41ad-a75a-463f82b73a0e' || res.id == '62f635f8-c5c6-41ad-a75a-463f82b73a0e' || res.id == '72f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_alpaca_experience_sessions == 1) {
          res.is_checked = true;
        }
        if ((res.id == '53f635f8-c5c6-41ad-a75a-463f82b73a0e' || res.id == '63f635f8-c5c6-41ad-a75a-463f82b73a0e' || res.id == '73f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_group_visits_insured_premises == 1) {
          res.is_checked = true;
        }
        if ((res.id == '54f635f8-c5c6-41ad-a75a-463f82b73a0e' || res.id == '64f635f8-c5c6-41ad-a75a-463f82b73a0e' || res.id == '74f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_visits_external_venues == 1) {
          res.is_checked = true;
        }
        if ((res.id == '55f635f8-c5c6-41ad-a75a-463f82b73a0e' || res.id == '65f635f8-c5c6-41ad-a75a-463f82b73a0e' || res.id == '75f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_attending_events_trade_stand == 1) {
          res.is_checked = true;
        }
        if ((res.id == '56f635f8-c5c6-41ad-a75a-463f82b73a0e' || res.id == '66f635f8-c5c6-41ad-a75a-463f82b73a0e' || res.id == '76f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_promoting_selling_products == 1) {
          res.is_checked = true;
        }
      })
    }

    // Remove calculations is mortality/Pubic liability/Trailer is deselected
    if (this.quote.quote_versions[0].alpaca_theft_cover != 1) {
      this.Calculations.age_7_90_rate = 0;
      this.Calculations.age_7_90_sum_insured = 0;
      this.Calculations.age_7_90_total_count = 0;
      this.Calculations.age_above_90_rate = 0;
      this.Calculations.age_above_90_sum_insured = 0;
      this.Calculations.age_above_90_total_count = 0;
      this.Calculations.age_13_rate = 0;
      this.Calculations.age_13_sum_insured = 0;
      this.Calculations.age_13_total_count = 0;
      this.Calculations.age_14_rate = 0;
      this.Calculations.age_14_sum_insured = 0;
      this.Calculations.age_14_total_count = 0;
      this.Calculations.male_economic_slaughter_rate = 0;
      this.Calculations.male_economic_slaughter_sum_insured = 0;
      this.Calculations.life_saving_surgical_fees = 0;
      this.Calculations.veterinary_fees = 0;
      this.Calculations.trailer_rate = 0;
      this.Calculations.trailer_minimum_premium = 0;

    }
    else {
      this.Calculations.minimum_mortality_premium = this.minimum_mortality_premium;
    }

    if (this.quote.quote_versions[0].alpaca_public_liability_cover != 1) {
      this.Calculations.acrage = 0;
      this.Calculations.basic_liabilty_minimum_premium = 0;
      this.Calculations.basic_liabilty_excess_acrage = 0;
      this.Calculations.employer_liability = 0;
      this.Calculations.alpaca_experience_minimum_premium = 0;
      this.Calculations.alpaca_excess_acrage = 0;
      this.Calculations.visit_insured_premises = 0;
      this.Calculations.visit_to_extrenal_venues = 0;
      this.Calculations.promoting_selling_related_products = 0;
      this.Calculations.trade_stand = 0;
    }

    if (this.quote.quote_versions[0].alpaca_trailer_cover != 1) {
      this.Calculations.trailer_rate = 0;
      this.Calculations.trailer_minimum_premium = 0;
      this.Calculations.trailer_sum_insured = 0;
    }
    //this.Calculations.calculate_data(this.mta_quote_data);

    // Animal DOB Min/Max Validations
    let minDate = new Date();
    switch (this.quote.quote_versions[0].type) {
      case 1:
        minDate.setFullYear(minDate.getFullYear() - 12);
        this.minDOB = minDate;
        break;
      case 2:
        minDate.setFullYear(minDate.getFullYear() - 14);
        this.minDOB = minDate;
        break;
      case 3:
        if (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') {
          minDate.setFullYear(minDate.getFullYear() - 70);
          this.minDOB = minDate;
        }
        break;
      default:
        minDate.setFullYear(minDate.getFullYear() - 12);
        this.minDOB = minDate;
        break;
    }

    //if (this.route.snapshot.params.quote_id) {
    this.setLiabilityLimitValues();

    // Set SmallHolding Activities
    this.setSmallHoldingActivities();
    // Assign Financial Detail Values 
    this.quoteService.setFinancialDetails(this.quote, this.alpacaRates, this.Calculations);

    if (!this.route.snapshot.params.quote_id) {
      this.getBrokerCommissionById(this.quote.quote_versions[0].company_id);
      // let uw_fees = 0;
      // if (this.quote.quote_versions[0].company_id) {
      //   let brokers_data = this.brokers.filter(x => x.id == this.quote.quote_versions[0].company_id);
      //   if (brokers_data.length > 0) {
      //     uw_fees = brokers_data[0].uw_fee;
      //   }
      //   this.Calculations.administrative_charges = Number(uw_fees);
      //   this.Calculations.calculate_data(this.mta_quote_data);
      // }
    }

  }

  /*
  * On Change Broker Dropdown
  */
  onChangeBroker(event) {
    // let commission_percentage = 0;
    // let uw_fees = 0;
    if (event.value != undefined) {
      // let brokers_data = this.brokers.filter(x => x.id == event.value);

      // if (brokers_data.length > 0) {
      //   commission_percentage = brokers_data[0].commission_percentage;
      //   uw_fees = brokers_data[0].uw_fees;
      //   this.quote.company_id = event.value;
      // }
      // this.quote.quote_versions[0].financial_details.administrative_charges = Number(uw_fees);

      // this.Calculations.administrative_charges = Number(uw_fees);
      // this.Calculations.ipt_charges = Number(commission_percentage);
      // this.Calculations.calculate_data(this.mta_quote_data);
      this.getBrokerCommissionById(event.value);
    }
  }

  // Get Commission Data On Broker change 
  getBrokerCommissionById(id) {
    let commission_percentage = 0;
    let uw_fees = 0;
    if (id) {
      this.quoteService.getLiveStockBrokerCommission(id, environment.ALPACA_ID)
        .subscribe(
          (response: any) => {
            if (response.success) {
              let brokers_data = response.result;
              if (brokers_data != null) {

                commission_percentage = brokers_data.broker_commission;
                uw_fees = brokers_data.uw_fee;
                this.quote.company_id = id;
                this.quote.quote_versions[0].financial_details.administrative_charges = Number(uw_fees);

                this.Calculations.administrative_charges = Number(uw_fees);
                // this.Calculations.ipt_charges = Number(commission_percentage);
                this.Calculations.calculate_data(this.mta_quote_data);
              }
            }
          });
    }
  }
  /*
  * Set SmallHolding Activities In Case Of Edit Quote
  */
  setSmallHoldingActivities() {
    if (this.quote.quote_versions[0].smallholding_activities) {
      this.alpacaLiabiities.forEach((res) => {
        let id = res.id;
        if ((id == '52f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '62f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '72f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_alpaca_experience_sessions == 1) {
          res.is_checked = true;
        }
        if ((id == '53f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '63f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '73f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_group_visits_insured_premises == 1) {
          res.is_checked = true;
        }
        if ((id == '54f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '64f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '74f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_visits_external_venues == 1) {
          res.is_checked = true;
        }
        if ((id == '56f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '66f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '76f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_promoting_selling_products == 1) {
          res.is_checked = true;
        }
        if ((id == '55f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '65f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '75f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.quote_versions[0].smallholding_activities.is_attending_events_trade_stand == 1) {
          res.is_checked = true;
        }

      });
    }
  }

  /*
  * Set Liability Limit Financials
  */
  setLiabilityLimitValues() {
    if (this.quote.quote_versions[0].alpaca_liability) {
      switch (this.quote.quote_versions[0].alpaca_liability.public_liability_cover) {
        case 1:
          this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '1 Million');
          this.alpacaLiabiities.forEach((liability) => {
            if (liability.id == '51f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
              this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
            }
            if (liability.id == '57f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
            }
          });
          break;
        case 2:
          this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '2 Million');
          this.alpacaLiabiities.forEach((liability) => {
            if (liability.id == '61f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
              this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
            }
            if (liability.id == '67f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
            }
          });
          break;
        case 5:
          this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '5 Million');
          this.alpacaLiabiities.forEach((liability) => {
            if (liability.id == '71f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
              this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
            }
            if (liability.id == '77f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
            }
          });
          break;
      }
    }
  }


  /*
  * HELP TEXT POPUP
  */
  helptext = '';
  helpTextPopup(content, text) {
    this.modalService.open(content, { centered: true });
    this.helptext = text;
  }

  /*
  * On Quote Type Change
  */
  onQuoteTypeChange(event) {
    this.quote_type = event.value;
    this.quote.quote_versions[0].type = event.value;
  }

  /*
    * POSTCODE LOOKUP
    */
  addresses;
  postcodeLookup(event) {
    let postcode = event.target.value;
    this.quoteService.getAddressPostcodeLookup(postcode)
      .subscribe(
        (response: any) => {
          this.addresses = response.addresses;
        },
        (error) => {
          console.log(error);
        }
      );
  }
  onSelectAddress(address) {
    this.quote.quote_versions[0].policy_holder.address.address1 = address.line_1;
    this.quote.quote_versions[0].policy_holder.address.address2 = address.line_2;
    this.quote.quote_versions[0].policy_holder.address.city = address.town_or_city;
    this.quote.quote_versions[0].policy_holder.address.country = address.county;
  }

  /*
  * Back To Quote Details
  */
  backToQuoteDetailsTab() {
    this.BackToQuoteDetails.emit();
  }
  is_disabled: boolean = false;
  notrailersecurty(event, content) {
    if (event.value == "55d8c0bb-b933-4f28-bc70-b5cc47fafb95") {
      this.helptext = 'Unfortunately we are unable to cover Trailers that have no security features, please remove the trailer from your quote to proceed.';
      this.modelBtnText = 'OK';
      this.modelBtnClass = 'btn-success';
      this.modalService.open(content, { centered: true });
      this.is_disabled = true;
    }
    else {
      this.is_disabled = false;
    }
  }

  /*
  * Alpaca Rate Change
  */
  onAlpacaRatesChange(event, type) {
    this.quote.total_animals = Number(this.quote.quote_versions[0].about_alpaca.alpaca_7dto90d) + Number(this.quote.quote_versions[0].about_alpaca.alpaca_91dto12y) + Number(this.quote.quote_versions[0].about_alpaca.alpaca_13y) + Number(this.quote.quote_versions[0].about_alpaca.alpaca_14y);
    if (!this.route.snapshot.params.quote_id && this.quote.quote_versions[0].alpaca_details.length == 0) {
      this.quote.quote_versions[0].alpaca_details = [];
    }

    if (this.quote.total_animals > this.quote.quote_versions[0].alpaca_details.length) {
      for (let i = this.quote.quote_versions[0].alpaca_details.length; i < this.quote.total_animals; i++) {
        this.quote.quote_versions[0].alpaca_details.push(new AlpacaDetails());
      }
    } else {
      let remainingItems = this.quote.quote_versions[0].alpaca_details.length - this.quote.total_animals;
      this.quote.quote_versions[0].alpaca_details.splice(-remainingItems, remainingItems);
    }

    this.quote.quote_versions[0].alpaca_details.forEach((res) => {
      if (res.is_cria == 0) {
        if (type == 1) {
          res.is_cria = 1;
        } else {
          res.is_cria = 2;
        }
      }
    })

    if (this.quote.quote_versions[0].about_alpaca.economic_slaughter_required_for > this.quote.total_animals) {
      this.quote.quote_versions[0].about_alpaca.economic_slaughter_required_for = 0;
    }

    let rates = this.alpacaRates.filter(x => x.type == type);
    if (rates.length > 0) {
      switch (type) {
        case 1:
          this.Calculations.age_7_90_rate = Number(rates[0].rate);
          this.Calculations.age_7_90_sum_insured = Number(event.target.value) * Number(rates[0].value);
          this.Calculations.age_7_90_total_count = Number(event.target.value);
          break;
        case 2:
          this.Calculations.age_above_90_rate = Number(rates[0].rate);
          this.Calculations.age_above_90_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured);
          this.Calculations.age_above_90_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_91dto12y);
          break;
        case 3:
          this.Calculations.male_economic_slaughter_rate = Number(rates[0].rate);

          this.Calculations.male_economic_slaughter_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.economic_slaughter_sum_insured);
          break;
        case 5:
          this.Calculations.life_saving_surgical_fees = (this.quote.quote_versions[0].about_alpaca.does_life_saving_cover_required == 1) ? Number(rates[0].rate) : 0;
          break;
        case 6:
          this.Calculations.veterinary_fees = (this.quote.quote_versions[0].about_alpaca.does_vet_treatments_covered == 1) ? Number(rates[0].rate) : 0;
          break;
        case 8:
          this.Calculations.age_13_rate = Number(rates[0].rate);
          this.Calculations.age_13_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured_13y);
          this.Calculations.age_13_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_13y);
          break;
        case 9:
          this.Calculations.age_14_rate = Number(rates[0].rate);
          this.Calculations.age_14_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured_14y);
          this.Calculations.age_14_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_14y);
          break;
      }

    } else {
      this.Calculations.age_7_90_rate = 0;
      this.Calculations.age_7_90_sum_insured = 0;
      this.Calculations.age_7_90_total_count = 0;
      this.Calculations.age_above_90_rate = 0;
      this.Calculations.age_above_90_sum_insured = 0;
      this.Calculations.age_above_90_total_count = 0;
      this.Calculations.male_economic_slaughter_rate = 0;
      this.Calculations.male_economic_slaughter_sum_insured = 0;
      this.Calculations.life_saving_surgical_fees = 0;
      this.Calculations.veterinary_fees = 0;
      this.Calculations.trailer_rate = 0;
      this.Calculations.trailer_minimum_premium = 0;
      this.Calculations.age_13_rate = 0;
      this.Calculations.age_13_sum_insured = 0;
      this.Calculations.age_13_total_count = 0;
      this.Calculations.age_14_rate = 0;
      this.Calculations.age_14_sum_insured = 0;
      this.Calculations.age_14_total_count = 0;

    }
    this.Calculations.calculate_data(this.mta_quote_data);
  }


  /*
  * Vaidation on total value of Cria
  */
  totalCriaValue: number = 0;
  criaTotalValue(event) {
    this.totalCriaValue = 0;
    this.quote.quote_versions[0].alpaca_details.forEach((res) => {
      if (res.is_cria == 1) {
        this.totalCriaValue = this.totalCriaValue + Number(res.value);
      }
    })
  }
  /*
  * Vaidation on total value of Animals
  */
  totalAnimalsValue: number = 0;
  totalAnimalsSumInsured: number = 0;
  animalsTotalValue(event) {
    this.totalAnimalsValue = 0;
    this.totalAnimalsSumInsured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured) + Number(this.quote.quote_versions[0].about_alpaca.sum_insured_13y) + Number(this.quote.quote_versions[0].about_alpaca.sum_insured_14y);
    this.quote.quote_versions[0].alpaca_details.forEach((res) => {
      if (res.is_cria == 2) {
        this.totalAnimalsValue = this.totalAnimalsValue + Number(res.value);
      }
    })
  }

  /*
  * Claim
  */
  onChangeClaim(event) {
    let claim = this.allClaims.filter(x => x.number_of_claims == event.value);
    if (claim.length > 0) {
      this.Calculations.claim_loading_percentage = Number(claim[0].claim_loading_percentage);
    } else {
      this.Calculations.claim_loading_percentage = 0;
    }
    this.Calculations.calculate_data(this.mta_quote_data);
  }

  /*
  * Acreage Change
  */
  acreageChange(event) {
    this.Calculations.acrage = this.quote.quote_versions[0].alpaca_liability.acerage;
    this.Calculations.calculate_data(this.mta_quote_data);
  }

  /*
  * SmallHolding Activities
  */
  smallHoldingActivities = [];
  onSmallHoldingActivitiesChange(event, id) {
    if (event.checked) {
      this.smallHoldingActivities.push(id);
    } else {
      let index = this.smallHoldingActivities.indexOf(id);
      this.smallHoldingActivities.splice(index, 1);
      if (id == '52f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '62f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '72f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.Calculations.alpaca_experience_minimum_premium = 0;
        this.Calculations.alpaca_excess_acrage = 0;
      }
      if (id == '53f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '63f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '73f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.Calculations.visit_insured_premises = 0;
      }
      if (id == '54f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '64f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '74f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.Calculations.visit_to_extrenal_venues = 0;
      }
      if (id == '56f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '66f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '76f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.Calculations.promoting_selling_related_products = 0;
      }
      if (id == '55f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '65f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '75f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.Calculations.trade_stand = 0;
      }
      this.Calculations.calculate_data(this.mta_quote_data);
    }

    this.quote.quote_versions[0].smallholding_activities.is_alpaca_experience_sessions = 0;
    this.quote.quote_versions[0].smallholding_activities.is_group_visits_insured_premises = 0;
    this.quote.quote_versions[0].smallholding_activities.is_visits_external_venues = 0;
    this.quote.quote_versions[0].smallholding_activities.is_promoting_selling_products = 0;
    this.quote.quote_versions[0].smallholding_activities.is_attending_events_trade_stand = 0;
    this.smallHoldingActivities.forEach((id) => {
      let activities = this.alpacaLiabiities.filter(x => x.id == id);
      if (id == '52f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '62f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '72f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.quote.quote_versions[0].smallholding_activities.is_alpaca_experience_sessions = 1;
        if (activities.length > 0) {
          this.Calculations.alpaca_experience_minimum_premium = activities[0].minimum_basic_premium;
          this.Calculations.alpaca_excess_acrage = activities[0].excess_acerage;
        }
        this.Calculations.calculate_data(this.mta_quote_data);
      }
      if (id == '53f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '63f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '73f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.quote.quote_versions[0].smallholding_activities.is_group_visits_insured_premises = 1;
        if (activities.length > 0) {
          this.Calculations.visit_insured_premises = activities[0].minimum_basic_premium;
        }
        this.Calculations.calculate_data(this.mta_quote_data);
      }
      if (id == '54f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '64f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '74f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.quote.quote_versions[0].smallholding_activities.is_visits_external_venues = 1;
        if (activities.length > 0) {
          this.Calculations.visit_to_extrenal_venues = activities[0].minimum_basic_premium;
        }
        this.Calculations.calculate_data(this.mta_quote_data);
      }
      if (id == '56f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '66f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '76f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.quote.quote_versions[0].smallholding_activities.is_promoting_selling_products = 1;
        if (activities.length > 0) {
          this.Calculations.promoting_selling_related_products = activities[0].minimum_basic_premium;
        }
        this.Calculations.calculate_data(this.mta_quote_data);
      }
      if (id == '55f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '65f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '75f635f8-c5c6-41ad-a75a-463f82b73a0e') {
        this.quote.quote_versions[0].smallholding_activities.is_attending_events_trade_stand = 1;
        if (activities.length > 0) {
          this.Calculations.trade_stand = activities[0].minimum_basic_premium;
        }
        this.Calculations.calculate_data(this.mta_quote_data);
      }

    });

  }

  /*
  * Animal Type
  */
  animalTypes = [];
  onAnimalTypeChange(event) {
    if (event.checked) {
      this.animalTypes.push(event.source.value);
    } else {
      let index = this.animalTypes.indexOf(event.source.value);
      this.animalTypes.splice(index, 1);
    }

    this.quote.quote_versions[0].animal_type.is_alpaca = (this.quote.quote_versions[0].animal_type.is_alpaca == 1) ? 1 : 0;
    this.quote.quote_versions[0].animal_type.is_sheep = (this.quote.quote_versions[0].animal_type.is_sheep == 1) ? 1 : 0;
    this.quote.quote_versions[0].animal_type.is_poultry = (this.quote.quote_versions[0].animal_type.is_poultry == 1) ? 1 : 0;
    this.quote.quote_versions[0].animal_type.is_pig = (this.quote.quote_versions[0].animal_type.is_pig == 1) ? 1 : 0;
    this.quote.quote_versions[0].animal_type.is_cattle = (this.quote.quote_versions[0].animal_type.is_cattle == 1) ? 1 : 0;
    this.quote.quote_versions[0].animal_type.is_horse = (this.quote.quote_versions[0].animal_type.is_horse == 1) ? 1 : 0;
    this.quote.quote_versions[0].animal_type.is_other = (this.quote.quote_versions[0].animal_type.is_other == 1) ? 1 : 0;
    this.animalTypes.forEach((type) => {
      if (type == 1) {
        this.quote.quote_versions[0].animal_type.is_alpaca = 1;
      } else if (type == 2) {
        this.quote.quote_versions[0].animal_type.is_sheep = 1;
      } else if (type == 3) {
        this.quote.quote_versions[0].animal_type.is_poultry = 1;
      } else if (type == 4) {
        this.quote.quote_versions[0].animal_type.is_pig = 1;
      } else if (type == 5) {
        this.quote.quote_versions[0].animal_type.is_cattle = 1;
      } else if (type == 6) {
        this.quote.quote_versions[0].animal_type.is_horse = 1;
      } else if (type == 7) {
        this.quote.quote_versions[0].animal_type.is_other = 1;
      }
    });

  }

  /*
    * Alpaca Liabilitites Limit Change
    */
  alpacaLiabilityLimitValue;
  onAlpacaLiabilityLimitChange(event) {
    this.Calculations.basic_liabilty_minimum_premium = 0;
    this.Calculations.basic_liabilty_excess_acrage = 0;
    this.Calculations.employer_liability = 0;

    this.alpacaLiabilityLimitValue = event.value;
    switch (event.value) {
      case 1:
        this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '1 Million');
        this.alpacaLiabiities.forEach((liability) => {
          if (liability.id == '51f635f8-c5c6-41ad-a75a-463f82b73a0e') {
            this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
            this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
          }
          if (liability.id == '57f635f8-c5c6-41ad-a75a-463f82b73a0e') {
            this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
          }
        });
        break;
      case 2:
        this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '2 Million');
        this.alpacaLiabiities.forEach((liability) => {
          if (liability.id == '61f635f8-c5c6-41ad-a75a-463f82b73a0e') {
            this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
            this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
          }
          if (liability.id == '67f635f8-c5c6-41ad-a75a-463f82b73a0e') {
            this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
          }
        });
        break;
      case 5:
        this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '5 Million');
        this.alpacaLiabiities.forEach((liability) => {
          if (liability.id == '71f635f8-c5c6-41ad-a75a-463f82b73a0e') {
            this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
            this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
          }
          if (liability.id == '77f635f8-c5c6-41ad-a75a-463f82b73a0e') {
            this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
          }
        });
        break;
    }
    this.Calculations.calculate_data(this.mta_quote_data);
  }

  indexTracker(index: number, value: any) {
    return index;
  }

  /*
  * Validate Age Limit
  */
  ageSelectors(event, type) {
    if (type < 3) {
      event.target.value = (Number(event.target.value) < Number(event.target.min) || Number(event.target.value) > Number(event.target.max)) ? null : event.target.value;
    }
    let rates = this.ratesTextFields.filter(x => x.type == type);
    if (rates.length > 0) {
      switch (type) {
        case 1:
          this.Calculations.age_7_90_rate = Number(rates[0].rate);
          this.Calculations.age_7_90_sum_insured = Number(event.target.value) * Number(rates[0].value);
          this.Calculations.age_7_90_total_count = Number(event.target.value);
          break;
        case 2:
          this.Calculations.age_above_90_rate = Number(rates[0].rate);
          this.Calculations.age_above_90_sum_insured = Number(rates[0].value);
          this.Calculations.age_above_90_total_count = Number(event.target.value);
          break;
        case 3:
          this.Calculations.male_economic_slaughter_rate = Number(rates[0].rate);
          this.Calculations.male_economic_slaughter_sum_insured = Number(event.target.value);
          break;
      }

    }
  }

  ageRadioSelectors(event, type) {
    if (event.value == 1) {
      let rates = this.ratesRadioFields.filter(x => x.type == type);
      if (rates.length > 0) {
        switch (type) {
          case 5:
            this.Calculations.life_saving_surgical_fees = Number(rates[0].rate);
            break;
          case 6:
            this.Calculations.veterinary_fees = Number(rates[0].rate);
            break;
          case 7:
            this.Calculations.trailer_sum_insured = Number(rates[0].value);
            this.Calculations.trailer_rate = Number(rates[0].rate);
            this.Calculations.trailer_minimum_premium = Number(rates[0].min_value);
            break;
        }
      }
    } else {
      this.Calculations.life_saving_surgical_fees = 0;
    }
  }

  /*
  *
  */

  acrage(event) {
    this.Calculations.veterinary_fees = Number(event.target.value);
  }

  /*
  * Alpaca Liabilitites Change
  */
  onAlpacaLiabilitiesChange(event) {

  }

  /*
  * Economic Slaughter
  */
  onEconomicSlaughterChange(event) {
    if (this.quote.quote_versions[0].about_alpaca.does_economic_slaughter_required == 2) {
      this.quote.quote_versions[0].about_alpaca.economic_slaughter_required_for = 0;
      this.quote.quote_versions[0].about_alpaca.economic_slaughter_sum_insured = 0;
    }
  }

  /*
  * Stud Male Conformation message
  */

  studMalesConformation(content) {
    this.helptext = 'This cover is for Stud Males only, please click accept to continue';
    this.modelBtnText = 'Accept';
    this.modelBtnClass = 'btn-success';
    this.modalService.open(content, { centered: true });
  }

  /*
  * SAVE QUOTE
  */
  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      for (const key of Object.keys(f.controls)) {
        if (f.controls[key].invalid || f.controls[key].status == 'INVALID') {
          const invalidControl = this.el.nativeElement.querySelector('input[name=' + key + '], mat-select[name=' + key + '], textarea[name=' + key + ']');
          if (invalidControl) {
            invalidControl.focus();
            invalidControl.scrollIntoView({ behavior: 'smooth' });
          } else {
            // var elmnt = document.getElementsByClassName('ng-invalid');
            var elmnt = this.el.nativeElement.querySelector('input.ng-invalid');
            //elmnt.focus();
            elmnt.scrollIntoView({ behavior: 'smooth' });
          }

          break;
        }
      }

      return false;
    }

    // if (this.totalCriaValue > 1500) {
    //   return false;
    // }
    let totalSumInsured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured) + Number(this.quote.quote_versions[0].about_alpaca.sum_insured_13y) + Number(this.quote.quote_versions[0].about_alpaca.sum_insured_14y);
    if (this.totalAnimalsValue > totalSumInsured) {
      return false;
    }

    if (this.quote.quote_versions[0].about_alpaca.alpaca_7dto90d > 30 || this.quote.quote_versions[0].about_alpaca.alpaca_91dto12y > 30 || this.quote.quote_versions[0].about_alpaca.alpaca_13y > 30 || this.quote.quote_versions[0].about_alpaca.alpaca_14y > 30) {
      return false;
    }

    if (this.quote.quote_versions[0].alpaca_theft_cover == 1) {
      if (this.quote.quote_versions[0].about_alpaca.alpaca_91dto12y > 0 && this.quote.quote_versions[0].about_alpaca.sum_insured == 0) {
        const invalidControlSI = this.el.nativeElement.querySelector('input[name=sum_insured]');
        if (invalidControlSI) {
          invalidControlSI.focus();
          invalidControlSI.scrollIntoView({ behavior: 'smooth' });
        }
        return false;
      }
      if (this.quote.quote_versions[0].about_alpaca.alpaca_13y > 0 && this.quote.quote_versions[0].about_alpaca.sum_insured_13y == 0) {
        const invalidControlSI = this.el.nativeElement.querySelector('input[name=sum_insured_13y]');
        if (invalidControlSI) {
          invalidControlSI.focus();
          invalidControlSI.scrollIntoView({ behavior: 'smooth' });
        }
        return false;
      }
      if (this.quote.quote_versions[0].about_alpaca.alpaca_14y > 0 && this.quote.quote_versions[0].about_alpaca.sum_insured_14y == 0) {
        const invalidControlSI = this.el.nativeElement.querySelector('input[name=sum_insured_14y]');
        if (invalidControlSI) {
          invalidControlSI.focus();
          invalidControlSI.scrollIntoView({ behavior: 'smooth' });
        }
        return false;
      }
    }

    this.quote.quote_versions[0].quote_page_index = 1;
    this.quote.calculations = this.Calculations;
    let quote_temp = this.quote;
    if (this.quote.quote_versions[0].alpaca_details.length > 0) {
      this.quote.quote_versions[0].alpaca_details.forEach((res) => {
        if (res.horse_purchase_date) {
          res.horse_purchase_date = formatDate(res.horse_purchase_date, 'yyyy-MM-dd', 'en-US');
        }
      });
    }

    // this.quote.quote_versions[0].policy_start_date = formatDate(this.quote.quote_versions[0].policy_start_date, 'yyyy-MM-dd', 'en-US');
    // if (this.quote.quote_versions[0].retroactive_date) {
    //   this.quote.quote_versions[0].retroactive_date = formatDate(this.quote.quote_versions[0].retroactive_date, 'yyyy-MM-dd', 'en-US');
    // }
    let data: any = '';
    data = {
      quote_data: this.quote
    };

    this.quoteService.saveQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quote = response.result.quote_data;
            this.quote.calculations = quote_temp.calculations;
            this.quote.prev_sequence_number = quote_temp.prev_sequence_number;

            this.quote.quote_versions[0].mta_sum_policy_premium = response.result.quote_data.mta_sum_policy_premium;
            this.quote.quote_versions[0].sum_alpaca_moroality_premium = response.result.quote_data.sum_alpaca_moroality_premium;
            this.quote.quote_versions[0].sum_alpaca_total_premium = response.result.quote_data.sum_alpaca_total_premium;
            this.quote.quote_versions[0].sum_liability_total_premium = response.result.quote_data.sum_liability_total_premium;
            this.quote.quote_versions[0].sum_policy_premium = response.result.quote_data.sum_policy_premium;
            this.quote.quote_versions[0].sum_public_liability_premium = response.result.quote_data.sum_public_liability_premium;
            this.quote.quote_versions[0].sum_trailer_premium = response.result.quote_data.sum_trailer_premium;
            this.quote.quote_versions[0].latest_policy_start_date = response.result.quote_data.latest_policy_start_date;
            this.quote.quote_versions[0].latest_policy_end_date = response.result.quote_data.latest_policy_end_date;

            this.quoteDetailsUpdated.emit(this.quote);
            this.ProceedWithSOFDetails.emit();
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });


  }

}
