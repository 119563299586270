<div class="container-fluid page-body-wrapper full-page-wrapper bg-white">
    <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
        <div class="row flex-grow">
            <div class="col-lg-6 d-flex align-items-center justify-content-center">
                <div class="auth-form-transparent text-left p-3">
                    <img src="assets/images/logo.jpg" alt="logo" class="img-fluid">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="bg-gray text-center mt-5">
                    <h3>Get in contact with us about our other products</h3>
                </div>
                <div class="login_image_container">
                    <img src="assets/images/login_3.png" alt="Image" class="img-fluid">
                </div>
                <div class="bg-gray text-center">
                    <h5><i class="mdi mdi-cellphone"></i> 01423 593 334 <i class="mdi mdi-web ml-5"></i>
                        www.peliwicaltd.co.uk <i class="mdi mdi-email ml-5"></i> info@peliwica.co.uk</h5>
                </div> 
            </div> 
        </div>
    </div>
    <!-- content-wrapper ends -->
</div>