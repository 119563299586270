import { ExtendedUse } from "./extended_use.model";
import { Drivers } from "./drivers.model";
export class Vehicle {
    registration_number: string = '';
    make: string = '';  
    model: string = '';  
    year: string = '';  
    weight: string = '';  
    value: number = null;  
    cover_type: string = '';  
    driving_restriction: string = '';  
    is_extended_use: string = '';      
    extended_use: any = new ExtendedUse();  
    non_equine_trade: string = '';  
    drivers: any = [];
    non_equine_trade_drivers: any = [{'driver_id':''}];
    breakdown_cover: boolean = true;
    ncd: string = '';
    green_card: string = '';
    mileage: string = '';
    registered_owner: string = '';
    ownership: string = '';
    is_kept_overnight: string = '';
    postcode: string = '';
    tracker: string = '';
}
