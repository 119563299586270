// import { Quote } from '@angular/compiler';
// import { parse } from 'path';

import { number } from "ngx-custom-validators/src/app/number/validator";

export class CalculationsDetails {
    net_rate: number = 0.0;
    full_class_net_rate_amount: number = 0.0;
    full_main_discount: number = 0.0;
    full_class_gross_amount: number = 0.0;
    full_ipt_gross_amount: number = 0.0;
    full_total_gross_primum: number = 0.0;
    full_total_premium: number = 0.0;
    full_total_amount_payable: number = 0.0;
    full_broker_amount: number = 0.0;
    full_pay_away_amount: number = 0.0;
    full_peliwica_retained_amount: number = 0.0;
    full_net_Premium_amount = 0.0;

   
    broker_commission: number = 0.0;
    reduced_broker_commission: number = 0.0;
    actual_broker_commission: number = 0.0;
    peliwica_pay_away_commission: number = 0.0;
    actual_peliwica_pay_away_commission: number = 0.0;
    peliwica_retained_commission: number = 0.0;
    actual_peliwica_retained_commission: number = 0.0;
    ipt_fee: number = 0.0;
    full_underwriting_fee: number = 0.0;
    broker_admin_fee: number = 0.0;
    
    sum_insured: number = 0.0;
    policy_minimum_premium: number = 0.0;
    

    premium_loading: number = 0;
    total_premium_loading: number = 0;
    premium_loading_percentage: number = 0;
    is_mta: boolean = false;
    is_cancel: boolean = false;
    is_lapse: boolean = false;
    is_NTU: boolean = false;
    mta_date;
    livestock_UW_loading: number = 0;
    old_livestock_UW_loading: number = 0;
    old_premium_loading_percentage: number = 0;
    clearStatus: boolean = false;
    claim_loading_percentage: number = 0.0;
    claim_loading_amount: number = 0.0;
    constructor()
    { 
        this.net_rate = 0.0;
        this.full_class_net_rate_amount = 0.0;
        this.full_main_discount = 0.0;
        this.full_class_gross_amount = 0.0;
        this.full_ipt_gross_amount = 0.0;
        this.full_total_gross_primum = 0.0;
        this.full_total_premium = 0.0;
        this.full_total_amount_payable = 0.0;
        this.full_broker_amount = 0.0;
        this.full_pay_away_amount = 0.0;
        this.full_peliwica_retained_amount = 0.0;
        this.full_net_Premium_amount = 0.0;
    
       
        this.broker_commission = 0.0;
        this.reduced_broker_commission = 0.0;
        this.actual_broker_commission = 0.0;
        this.peliwica_pay_away_commission = 0.0;
        this.actual_peliwica_pay_away_commission = 0.0;
        this.peliwica_retained_commission = 0.0;
        this.actual_peliwica_retained_commission = 0.0;
        this.ipt_fee = 0.0;
        this.full_underwriting_fee = 0.0;
        this.broker_admin_fee = 0.0;
        this.sum_insured = 0.0;
        this.policy_minimum_premium = 0.0;
        
    
        this.premium_loading = 0;
        this.total_premium_loading = 0;
       	this.premium_loading_percentage = 0;
        this.is_mta = false;
        this.is_cancel = false;
        this.is_lapse = false;
        this.is_NTU = false;
        this.mta_date;
        this.livestock_UW_loading = 0;
        this.old_livestock_UW_loading = 0;
        this.old_premium_loading_percentage = 0;
        this.claim_loading_percentage= 0.0;
        this.claim_loading_amount = 0.0;
        this.clearStatus = false;
    }

    //mta_quote_data ;
    public full_calculate_data(mta_quote_data, data) {
        //this.mta_quote_data = mta_quote_data;
        // let IsMTA = true;
        let fullNetPremiumAmount = 0;
        this.reduced_broker_commission = this.actual_broker_commission - this.broker_commission;
        this.mta_date = mta_quote_data.policy_start_date;
        let total_commission = Number(this.broker_commission) + Number(this.peliwica_pay_away_commission) + Number(this.peliwica_retained_commission); // main class
        // let oCalculationModel : CalculationModel;
        var net_rate_amount = 0;
        var net_gross_amount = 0;
        var full_gross_amount = 0;
        data.forEach((res, index) => {
            res.sum_insured = (res.sum_insured)?res.sum_insured:0;
            res.net_rate = (res.net_rate)?res.net_rate:0;
           // res.purchase_date = formatDate(res.purchase_date, 'yyyy-MM-dd', 'en-US');
            net_rate_amount =  res.sum_insured * Number(res.net_rate) / 100;
            net_gross_amount =  this.main_class_net(net_rate_amount, 0,
               this.full_main_discount);
               full_gross_amount = Number(full_gross_amount) + this.gross_amount(net_gross_amount, total_commission, this.reduced_broker_commission);

          });
       // var net_rate_amount = this.sum_insured * Number(this.full_class_net_rate) / 100;

        // oCalculationModel.sum_insured = this.sum_insured;
        this.full_class_net_rate_amount =  Number(net_gross_amount);
        this.full_class_gross_amount =full_gross_amount;
        // Vat Fee
      

        var net_policy_amount = parseFloat(Number((this.full_class_net_rate_amount)).toFixed(2));;
        this.premium_loading = this.calculate_percentage_amount(net_policy_amount, this.premium_loading_percentage);

        this.total_premium_loading = this.gross_amount(this.premium_loading, total_commission, this.reduced_broker_commission);


        this.full_net_Premium_amount = parseFloat(Number((this.full_class_net_rate_amount) + Number(this.premium_loading)).toFixed(2));;


        this.full_total_gross_primum = parseFloat(Number((this.full_class_gross_amount)  + (this.total_premium_loading) +
         Number ( this.livestock_UW_loading)).toFixed(2));;

        let minimum_premium :number = 0.0;
        if (mta_quote_data.livestock_mta && mta_quote_data.livestock_mta.livestock_type_id == "10246458-ac55-4d2d-aef8-8c75101f8ce2")
        {
            this.policy_minimum_premium = this.policy_minimum_premium * data.length;
        }
        minimum_premium = this.gross_amount(this.policy_minimum_premium, total_commission, this.reduced_broker_commission);

        if (Number(this.full_total_gross_primum) < minimum_premium) {
            //this.full_total_gross_primum = Number(this.policy_minimum_premium); 
            this.full_total_gross_primum = this.gross_amount(this.policy_minimum_premium, total_commission, this.reduced_broker_commission);
        }
        else {
            // this.full_total_gross_primum =  this.gross_amount(this.full_total_gross_primum, total_commission);  
        }


        if (Number(this.claim_loading_percentage) > 0) {
            this.claim_loading_amount = this.convert_to_2_decimal(Number(this.full_total_gross_primum) * Number(this.claim_loading_percentage) / 100);
            this.full_total_gross_primum = this.convert_to_2_decimal(Number(this.full_total_gross_primum) + Number(this.claim_loading_amount));
        }
        else {
            this.claim_loading_amount = 0;
        }

        var Difference_In_Days_cancle;
        var Difference_In_Days_old;

        if (this.is_mta) {
            if (parseFloat(Number(mta_quote_data.mta_sum_policy_premium).toFixed(2)) != parseFloat(Number(this.full_total_gross_primum).toFixed(2))) {

                this.full_total_gross_primum = parseFloat(Number(Number(this.full_total_gross_primum) - Number(mta_quote_data.mta_sum_policy_premium)).toFixed(2));
                
                let newDate = new Date(this.mta_date);
                let policy_end_date = new Date(mta_quote_data.latest_policy_end_date)
                var Difference_In_Time = (policy_end_date.getTime() - newDate.getTime()) + 86400000;
                var Difference_In_Days_MTA = Difference_In_Time / (1000 * 3600 * 24);

                let oldDate = new Date(mta_quote_data.latest_policy_start_date);
                // let policy_end_date = new Date(mta_quote_data.policy_end_date)
                Difference_In_Time_old = (policy_end_date.getTime() - oldDate.getTime()) + 86400000;
                Difference_In_Days_old = Difference_In_Time_old / (1000 * 3600 * 24);

                this.full_total_gross_primum = parseFloat(Number(this.full_total_gross_primum / Number(Difference_In_Days_old) * Number(Difference_In_Days_MTA)).toFixed(2));
                
                if (this.full_total_gross_primum  <= 0.10 && this.full_total_gross_primum >= -0.10)
                {
                    this.full_total_gross_primum  = 0;
                }
            }
            else {
                this.full_total_gross_primum = 0
            }
            
        }


        if (this.is_cancel) {
            let newDate = new Date(this.mta_date);
            let policy_end_date = new Date(mta_quote_data.latest_policy_end_date)
            var Difference_In_Time = (policy_end_date.getTime() - newDate.getTime()) + 86400000;
            Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);

            let policy_ntu_date = new Date()


            //let oldDate = new Date(mta_quote_data.latest_policy_start_date);
            let oldDate = new Date(mta_quote_data.original_policy_start_date);
            // let policy_end_date = new Date(mta_quote_data.policy_end_date)
            var Difference_In_ntu_Time = policy_ntu_date.getTime() - oldDate.getTime();
            Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);
            var Difference_In_Time_old = (policy_end_date.getTime() - oldDate.getTime()) + 86400000;
            Difference_In_Days_old = Difference_In_Time_old / (1000 * 3600 * 24);
            if (newDate >= oldDate) {
                if (parseFloat(Number(mta_quote_data.mta_sum_policy_premium).toFixed(2)) == Number(this.full_total_gross_primum)) {
                    this.full_total_gross_primum = - parseFloat(Number(this.full_total_gross_primum / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                }
                else {
                    let full_return_amount :number = 0;
                    full_return_amount = - parseFloat(Number(mta_quote_data.mta_sum_policy_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                    if( Number(this.old_premium_loading_percentage) != this.premium_loading_percentage )
                    {
                        this.full_total_gross_primum =  parseFloat(Number(this.full_total_gross_primum / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                        this.full_total_gross_primum =  parseFloat(Number(Number(this.full_total_gross_primum) + Number(full_return_amount)).toFixed(2));
                        this.full_total_gross_primum = parseFloat(Number(Number(this.full_total_gross_primum) + Number(full_return_amount)).toFixed(2));
                    }
                    else
                    {

                        this.full_total_gross_primum = full_return_amount;
                    }
                  
                }
            }

        }

        this.full_ipt_gross_amount = parseFloat(Number(Number(this.full_total_gross_primum) * Number(this.ipt_fee) / 100).toFixed(2));;


        this.full_total_premium = parseFloat(Number(this.full_total_gross_primum + (this.full_total_gross_primum * this.ipt_fee / 100)).toFixed(2));


        this.full_underwriting_fee = this.full_underwriting_fee ;//this.calculate_UW_fee(this.full_total_premium);
        this.broker_admin_fee =this.broker_admin_fee;

        if (this.is_mta) {
            this.full_underwriting_fee = 0;
            this.broker_admin_fee = 0;
        }

        if (this.is_cancel && !this.is_NTU) {
             this.full_underwriting_fee = 0;
             this.broker_admin_fee = 0;
        }

        this.full_total_amount_payable = parseFloat(Number(Number(this.full_total_premium) + Number(this.full_underwriting_fee) + Number(this.broker_admin_fee)).toFixed(2));


        //this.full_broker_amount = parseFloat(Number(parseFloat(Number(this.full_total_gross_primum).toFixed(2)) * this.broker_commission / 100).toFixed(2));
        this.full_broker_amount = parseFloat(Number(parseFloat(Number(this.full_net_Premium_amount).toFixed(2)) / (100 - total_commission) * this.broker_commission).toFixed(2));
        this.full_pay_away_amount = parseFloat(Number(parseFloat(Number(this.full_net_Premium_amount).toFixed(2)) / (100 - total_commission) * this.peliwica_pay_away_commission).toFixed(2));
        this.full_peliwica_retained_amount = parseFloat(Number(parseFloat(Number(this.full_net_Premium_amount).toFixed(2)) / (100 - total_commission) * this.peliwica_retained_commission).toFixed(2));

        if (this.is_lapse || this.is_NTU) {

            this.full_total_gross_primum = parseFloat(Number(Number(mta_quote_data.sum_policy_premium)).toFixed(2));
            this.full_ipt_gross_amount = parseFloat(Number(Number(this.full_total_gross_primum) * Number(this.ipt_fee) / 100).toFixed(2));;
            this.full_total_premium = parseFloat(Number(this.full_total_gross_primum + (this.full_total_gross_primum * this.ipt_fee / 100)).toFixed(2));
            this.full_total_amount_payable = parseFloat(Number(Number(this.full_total_premium) + Number(mta_quote_data.financial_details.uw_fee)+ Number(this.broker_admin_fee)).toFixed(2));
            this.full_broker_amount = parseFloat(Number(parseFloat(Number(this.full_total_gross_primum).toFixed(2)) * this.broker_commission / 100).toFixed(2));
         //   this.full_underwriting_fee = this.calculate_UW_fee(this.full_total_premium);
             this.full_total_gross_primum = -Number(this.full_total_gross_primum);
            this.full_ipt_gross_amount = -Number(this.full_ipt_gross_amount)
            this.full_total_premium = -Number(this.full_total_premium);
            this.full_underwriting_fee = -Number(this.full_underwriting_fee);
            this.broker_admin_fee = -Number(this.broker_admin_fee);

            
            this.full_total_amount_payable = - Number(this.full_total_amount_payable);
            this.full_broker_amount = -Number(this.full_broker_amount);
            //  this.full_underwriting_fee = - Number(this.full_underwriting_fee);
        }


    }



    /* Calcluate basic values */

    private main_class_net(basic_value, minimum_premium, main_discount): number {
        if (basic_value <= minimum_premium) {
            basic_value = Number(minimum_premium);
        }

        if (main_discount > 0) {
            basic_value = Number(basic_value) - (Number(basic_value) * Number(main_discount / 100));

        }
        return parseFloat(Number(basic_value).toFixed(2));
    }

    private gross_amount(basic_net_value: number, total_commission: number, reduced_broker_commission: number): number {

        basic_net_value = Number(basic_net_value) / (100 - Number(total_commission)) * 100;
        return parseFloat(Number(basic_net_value).toFixed(2));
    }

    private calculate_percentage_amount(value: number, percentage: number): number {

        value = (Number(value) * Number(percentage)) / 100;
        return parseFloat(Number(value).toFixed(2));
    }

    private calculate_UW_fee(primum: number): number {
        var uw_fee: number = 0;
        if (Number(primum < 400)) {
            uw_fee = Number(primum * 10 / 100);
        }
        else if (Number(primum >= 400 && Number(primum < 1200))) {
            uw_fee = 40;
        }
        else {
            uw_fee = 65;
        }
        return parseFloat(Number(uw_fee).toFixed(2));

    }
    private convert_to_2_decimal(value: number): number {
        return parseFloat(Number(value).toFixed(2));
    }

}

