export class LivestockMeta {
    id: string = '';
    quote_id: string = '';
    livestock_type_id: string = '';
    type_of_policy: number = null;
    breed_society_inspection: number = null;
    animal_count: number = null;
    claim_count: number = null;
    sale_location: string = '';
    sale_date = new Date();
    proposer_type: number = null;
    is_nba_breeding_warranty: number = null;
    breeding_society_name: string = '';
    about_proposer_first_name: string = '';
    about_proposer_last_name: string = '';
    about_proposer_email: string = '';
}
