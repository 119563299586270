<mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChanged($event)">
    <mat-tab [disabled]="quote_type > 3 || (selectedIndex >= 4 && quoteModelDetails.livestock_meta.type_of_policy >= 3) || (selectedIndex >= 5 && quoteModelDetails.livestock_meta.type_of_policy < 3)">
        <ng-template mat-tab-label>
            Cover Options
        </ng-template>
        <div class="card-body">
            <app-cover-options [selectedTabIndex]='selectedIndex' [brokers]='brokers' [quoteModelDetails]="quoteModelDetails"
                (quoteDetailsUpdated)="quoteDetailsUpdated($event)" (proceedToNextTab)="proceedToNextTab()">
            </app-cover-options>
        </div>
    </mat-tab>
    
    <div *ngIf="quoteModelDetails.livestock_meta.type_of_policy < 3">
        <mat-tab [disabled]="selectedIndex < 1 || selectedIndex >= 5">
            <ng-template mat-tab-label>
                Quotation
            </ng-template>
            <div class="card-body">
                <app-quotation [staticData]="staticData" [brokers]='brokers' [selectedTabIndex]='selectedIndex'
                    [quoteModelDetails]="quoteModelDetails" (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
                    (proceedToNextTab)="proceedToNextTab()" (backToPrevTab)="backToPrevTab()" 
                    (isSofTabEnable)="isSofTabEnable($event)" (newSelectedTabIndex)="newSelectedTabIndex($event)"></app-quotation>
            </div>
        </mat-tab>
    </div>
    <div *ngIf="quoteModelDetails.livestock_meta.type_of_policy >= 3">
        <mat-tab [disabled]="selectedIndex < 1 || selectedIndex >= 4 || selectedIndex >= 5">
            <ng-template mat-tab-label>
                Indicative Quote Details
            </ng-template>
            <div class="card-body">
                <app-sale-quote-details [staticData]="staticData" [brokers]='brokers' [selectedTabIndex]='selectedIndex'
                    [quoteModelDetails]="quoteModelDetails" (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
                    (proceedToNextTab)="proceedToNextTab()" (backToPrevTab)="backToPrevTab()"
                    (newSelectedTabIndex)="newSelectedTabIndex($event)"></app-sale-quote-details>
            </div>
        </mat-tab>
        <mat-tab [disabled]="selectedIndex < 2 || selectedIndex >= 4 || selectedIndex >= 5">
            <ng-template mat-tab-label>
                Full Details
            </ng-template>
            <div class="card-body">
                <app-sale-full-details [staticData]="staticData" [brokers]='brokers' [selectedTabIndex]='selectedIndex'
                    [quoteModelDetails]="quoteModelDetails" (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
                    (proceedToNextTab)="proceedToNextTab()" (isSofTabEnable)="isSofTabEnable($event)" 
                    (backToPrevTab)="backToPrevTab()" (newSelectedTabIndex)="newSelectedTabIndex($event)">

                </app-sale-full-details>
            </div>
        </mat-tab>
    </div>
    <mat-tab [disabled]="selectedIndex < 2 || (quoteModelDetails.livestock_meta.type_of_policy >= 3 &&  (selectedIndex < 3 || selectedIndex >= 4))  || (selectedIndex >= 5 && quoteModelDetails.livestock_meta.type_of_policy < 3)" *ngIf="this.isSofTab">
        <ng-template mat-tab-label>
            Statements of Fact
        </ng-template>
        <div class="card-body">
            <app-livestock-sof [selectedTabIndex]='selectedIndex' (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
                [quoteModelDetails]="quoteModelDetails" (proceedToNextTab)="proceedToNextTab()"  (backToPrevTab)="backToPrevTab()"></app-livestock-sof>
        </div>
    </mat-tab>
    <div *ngIf="quoteModelDetails.livestock_meta.type_of_policy < 3">
        <mat-tab [disabled]="selectedIndex < 3  || selectedIndex >= 5">
            <ng-template mat-tab-label>
                Quote Summary
            </ng-template>
            <div class="card-body">
                <app-summary [staticData]="staticData" [brokers]='brokers' [selectedTabIndex]='selectedIndex'
                    (quoteDetailsUpdated)="quoteDetailsUpdated($event)" [quoteModelDetails]="quoteModelDetails"
                    (proceedToNextTab)="proceedToNextTab()" (backToPrevTab)="backToPrevTab()"
                    (newSelectedTabIndex)="newSelectedTabIndex($event)"></app-summary>
            </div>
        </mat-tab>
        <mat-tab [disabled]="selectedIndex < 4  || selectedIndex >= 5">
            <ng-template mat-tab-label>
                Payment Confirmation
            </ng-template>
            <div class="card-body">
                <app-lv-payment-confirmation (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
                    [quoteModelDetails]="quoteModelDetails" [selectedTabIndex]='selectedIndex' (proceedToNextTab)="proceedToNextTab()">
                </app-lv-payment-confirmation>
            </div>
        </mat-tab>
        <mat-tab [disabled]="selectedIndex < 5 || selectedIndex < 6 ">
            <ng-template mat-tab-label>
                Policy confirmation
            </ng-template>
            <div class="card-body">
                <app-lv-policy-payment (quoteDetailsUpdated)="quoteDetailsUpdated($event)" [selectedTabIndex]='selectedIndex'
                    [quoteModelDetails]="quoteModelDetails" (proceedToNextTab)="proceedToNextTab()">
                </app-lv-policy-payment>
            </div>
        </mat-tab>
        <mat-tab [disabled]="selectedIndex < 6">
            <ng-template mat-tab-label>
                Full Details
            </ng-template>
            <div class="card-body">
                <app-lv-full-details [staticData]="staticData" [brokers]='brokers' [selectedTabIndex]='selectedIndex'
                    [quoteModelDetails]="quoteModelDetails" (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
                    (newSelectedTabIndex)="newSelectedTabIndex($event)">
                </app-lv-full-details>
            </div>
        </mat-tab>
    </div>
    <div *ngIf="quoteModelDetails.livestock_meta.type_of_policy >= 3">
        <mat-tab [disabled]="selectedIndex < 4 || selectedIndex == 5">
            <ng-template mat-tab-label>
                Quotation Details
            </ng-template>
            <div class="card-body">
                <app-quotation-confirmation (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
                    [quoteModelDetails]="quoteModelDetails" (proceedToNextTab)="proceedToNextTab()">
                </app-quotation-confirmation>
            </div>
        </mat-tab>
        <mat-tab [disabled]="selectedIndex < 5">
            <ng-template mat-tab-label>
                Policy & Payment Details
            </ng-template>
            <div class="card-body">
                <app-lv-policy-payment (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
                    [quoteModelDetails]="quoteModelDetails" (proceedToNextTab)="proceedToNextTab()">
                </app-lv-policy-payment>
            </div>
        </mat-tab>
    </div>
</mat-tab-group>