import { Component, OnInit, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../quote/alpaca/model/quote/quote_versions.model';
import { QuoteService } from '../../quote/quote.service';
import { ActivatedRoute, Router } from "@angular/router";
import { LivestockService } from '../../quote/livestock/livestock.service';
import { LocalStorageService } from 'ngx-webstorage';
import { PolicyService } from '../../policy/policy.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-livestock-view',
  templateUrl: './livestock-view.component.html',
  styleUrls: ['./livestock-view.component.css']
})
export class LivestockViewComponent implements OnInit {
  quoteData = new QuoteVersions();
  actionDropDown: boolean = false;
  hasImportantNotes: boolean = false;
  totalImpNotes: number = 0;
  latestVersion: number = 0;
  enableActions: boolean = false;
  is_NTU: boolean = false;
  staticData;
  isAdmin = false;
  isStaff: boolean = false;
  isBroker: boolean = false;

  constructor(private lvkService: LivestockService, private quoteService: QuoteService, private route: ActivatedRoute, private _snackBar: MatSnackBar,
    private sessionSt: LocalStorageService, private policyService: PolicyService, private modalService: NgbModal, private router: Router) { }

  is_policy: number = 0;
  quoteId: string = "";
  isActionBtn: boolean = false;
  isBrokerCommissionReduced: boolean = false;

  ngOnInit(): void {
    if (this.sessionSt.retrieve('is_admin')) {
      this.isAdmin = true;
    }

    if (this.sessionSt.retrieve('is_staff')) {
      this.isStaff = true;
    }
    if (this.sessionSt.retrieve('is_broker')) {
      this.isBroker = true;
    }

    //Get Policy Details
    if (this.router.url.includes('policy')) {
      this.is_policy = 1;
      this.quoteId = this.route.snapshot.params.id;
    }
    else if (this.router.url.includes('quote-details')) {
      this.is_policy = 0;
      this.quoteId = this.route.snapshot.params.quote_id;
    }
    this.lvkService.getQuoteDetailsByQuoteID(this.quoteId, this.is_policy).subscribe(
      (response: any) => {
        if (response.success) {

          this.quoteData = response.result;
          this.getBrokerCommissions();
          if (this.is_policy == 1) {
            this.quoteData.isPolicyData = true;
          }
          else {
            this.quoteData.isPolicyData = false;
          }
          this.latestVersion = response.result.latest_version;
          this.quoteData.sofIsDisabled = true;
          this.policyActions();

          // Enable Actions Dropdown
          let currentDate = new Date()
          let policy_start_date = new Date(this.quoteData.policy_start_date);
          var Difference_In_Time = currentDate.getTime() - policy_start_date.getTime();
          if (Difference_In_Time >= 0) {
            this.enableActions = true;
          }

          // Enable NTU  
          this.enableNTU();

          //f6d1 : Referred, 80c0 : Quoted, dda8 : Declined
          if ((this.quoteData.insurance_status_id == "f6d14624-6351-4d93-b27b-70fb8a06ec5d")
            || (this.quoteData.insurance_status_id == "80c0db90-ba5d-4387-b09a-4c008a664932")
            || (this.quoteData.insurance_status_id != "dda8734c-d149-4336-b8cc-c2e205430752")) {
            this.isActionBtn = true;
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );



    // API - GET NOTES
    this.quoteService.getNotes(this.quoteId)
      .subscribe(
        (response: any) => {
          let impNotes = response.result.filter(x => x.is_important == 1);
          this.totalImpNotes = impNotes.length;
          if (impNotes.length > 0) {
            this.hasImportantNotes = true;
          }
        });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.enableNTU();
    this.policyActions();

  }

  getBrokerCommissions() {
    if (this.quoteData.company_id) {
      this.quoteService.getLiveStockBrokerCommission(this.quoteData.company_id, this.quoteData.product_id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              let brokers_data = response.result;
              if (brokers_data != null) {
                if (this.quoteData.financial_details.broker_commission != brokers_data.broker_commission) {
                  this.isBrokerCommissionReduced = true;
                }
              }
            }
          });
    }
  }

  enableNTU() {
    if (this.quoteData.policy_start_date && this.sessionSt.retrieve('company_role') == 'Broker') {
      let currentDate = new Date()
      let policy_start_date = new Date(this.quoteData.policy_start_date);
      var Difference_In_Time = currentDate.getTime() - policy_start_date.getTime();
      if (Difference_In_Time / (1000 * 3600 * 24) < 14) {
        this.is_NTU = true;
      }
    } else {
      this.is_NTU = true;
    }
  }

  displayEmailView: boolean = false;
  displayNewEmailView: boolean = false;
  newEmailView() {
    this.displayNewEmailView = true;
  }

  email_id: string = '';
  getEmailView(email_id) {
    this.email_id = email_id;
    this.displayEmailView = true;
  }
  backToEmailsList() {
    this.displayEmailView = false;
    this.displayNewEmailView = false;
  }

  quoteDetailsUpdated(quotedata) {
    this.quoteData = quotedata;
    this.policyActions();
  }

  onhasImportantNotesChange(event) {
    this.hasImportantNotes = event;
    this.totalImpNotes = this.totalImpNotes + 1;
  }

  // Hide action dropdown based on insurance status
  policyActions() {
    if (this.quoteData.type == 1 || this.quoteData.type == 2 || this.quoteData.type == 3) {
      this.actionDropDown = true;
    } else {
      this.actionDropDown = false;
    }
  }

  /*
  * Convet To MTA PopUp - List of Reasons
  */
  mtaMsg: string = '';
  quoteType = '';
  convert_to_mta(content, type, delete_mta = 0) {
    this.policyService.mtaCheck(this.quoteData.quote_meta_id, delete_mta)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.sessionSt.store('showHideMTAReasons', true);  // Enable to clear mta reasons id if the page loads first time under quote edit screen            
            this.router.navigateByUrl("/pre-qualifier/3/" + this.quoteData.id + '/' + type);
          } else {
            this.modalService.open(content, { centered: true });
            this.mtaMsg = response.message;
            this.quoteType = type;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
 * Update Quote Status
 */
  updateQuoteStatus(status_id) {
    let data = {
      quote_id: this.quoteData.id,
      insurance_status_id: status_id
    };

    this.quoteService.updateQuoteStatus(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteData.insurance_status_id = response.result.insurance_status_id;
            window.location.reload();
          }
        });
  }


  /*
  * Update Quote Status
  */
  updateQuoteDeclineStatus(declineMsgBox) {
    this.confimMsg = 'Are you sure you want to decline the quote?';
    this.modalService.open(declineMsgBox, { centered: true });
  }
  DeclineQuote() {
    let data = {
      quote_id: this.quoteData.id,
      insurance_status_id: "dda8734c-d149-4336-b8cc-c2e205430752"
    };

    this.quoteService.updateAnyQuoteStatus(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
            window.location.reload();
          }
          else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

  // Quote Lapse
  confimMsg: string = '';
  QuoteLapseCfmMSg(msgBox) {
    this.confimMsg = 'Are you sure you want to lapse the quote?';
    this.modalService.open(msgBox, { centered: true });
  }

  lapseQuote() {
    let data: any = '';
    data = {
      quote_id: this.quoteData.id,
      type: 8
    };
    this.quoteService.lapseQuote(data)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.router.navigate(['/policy', response.result.id]);
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * On Policy Start Date Change
  */
  onPolicyStartDateChange(event) {
    this.disablIfPastDate = false;
  }

  /*
  * Convert to Policy
  */
  disablIfPastDate: boolean = false;
  minPolicyDate;
  maxPolicyDate;

  convertToPolicy(converttopolicymodal, MTAConvertToPolicy) {
    var date1: any = new Date(this.quoteData.policy_start_date);
    var date2: any = new Date();
    var diffDays: any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));

    if (this.sessionSt.retrieve('is_broker')) {
      if (this.quoteData.type == 3) {
        if (diffDays >= 0 && diffDays < 7) {
          this.router.navigateByUrl("/livestock/quote/" + this.quoteData.id + '/7');
        } else if (diffDays > 7) {
          this.modalService.open(MTAConvertToPolicy, { centered: true });
        } else {
          this.convertToPolicRedirect(converttopolicymodal);
        }
      } else {
        this.convertToPolicRedirect(converttopolicymodal);
      }
    } else if (this.sessionSt.retrieve('is_broker') && this.quoteData.type == 1) {
      if (diffDays <= 30) {
        let maxDate = new Date().setDate(new Date().getDate() + 30);
        this.maxPolicyDate = new Date(maxDate);
        this.minPolicyDate = new Date();
        this.quoteData.policy_start_date = new Date();
        this.convertToPolicRedirect(converttopolicymodal);
      } else {
        this.modalService.open(MTAConvertToPolicy, { centered: true });
      }
    } else if (this.sessionSt.retrieve('is_broker') && this.quoteData.type == 2) {
      if (diffDays > 0) {
        this.modalService.open(MTAConvertToPolicy, { centered: true });
      } else {
        this.convertToPolicRedirect(converttopolicymodal);
      }
    } else {
      this.convertToPolicRedirect(converttopolicymodal);
    }
  }

  minPolicyDate1 = new Date();
  convertToPolicRedirect(converttopolicymodal) {
    if (this.sessionSt.retrieve('is_broker')) {
      let maxDate = new Date().setDate(new Date().getDate() + 30);
      this.maxPolicyDate = new Date(maxDate);
      //this.minPolicyDate = new Date(); --Enable later
      this.minPolicyDate = new Date("2022,05,15");
      //  this.minPolicyDate = new Date("2022,05,15");    
      this.minPolicyDate1 = new Date("2022,05,14");
      this.minPolicyDate1.setHours(0, 0, 0, 0);
    }

    if (this.sessionSt.retrieve('user_group') == 'Staff') {
      //this.minPolicyDate = new Date(); --Enable later
      this.minPolicyDate = new Date("2022,05,15");
    }

    // Disable convert to policy buttons if date is past date
    let policy_start_date = new Date(this.quoteData.policy_start_date);
    policy_start_date.setHours(0, 0, 0, 0);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (policy_start_date.getTime() < this.minPolicyDate1.getTime() && this.sessionSt.retrieve('company_role') == 'Broker') {
      this.disablIfPastDate = true;
    }




    this.modalService.open(converttopolicymodal, { centered: true });
  }

  convertToPolicyPost() {
    let data: any = '';
    data = {
      quote_id: this.quoteData.id,
      policy_ref_number: this.quoteData.policy_ref_number,
      policy_start_date: formatDate(this.quoteData.policy_start_date, 'yyyy-MM-dd', 'en-US')
    };
    this.quoteService.convertToPolicy(data)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.router.navigate(['/livestock/policy', this.quoteData.id]);
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }


}
