import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges,ElementRef } from '@angular/core';
import { Quote } from '../../../model/quote/quote.model';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from "@angular/router";
import { NgForm } from '@angular/forms';
import { QuoteService } from '../../quote.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FactStatement } from '../../../model/quote/fact_statement.model';
import { GridOptions } from "ag-grid-community";
import { formatDate } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { UserInfoService } from '../../../services/user-info.service';
import {
  saveAs as importedSaveAs
} from "file-saver";

@Component({
  selector: 'app-ajg-fact-statement',
  templateUrl: './ajg-fact-statement.component.html',
  styleUrls: ['../../quote.component.css'],
})
export class AjgFactStatementComponent implements OnInit {
  @Input() quoteDetails: QuoteVersions;
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  quote;
  parent_quote = new Quote();
  @Output() BackToFullDetails = new EventEmitter();
  @Output() ProceedToPayment = new EventEmitter();
  statementOfFactModel: FactStatement;
  statementOfFacts: any = [];
  rowData: any = [];
  mta_quote_data = new QuoteVersions(); 

  constructor(private quoteService: QuoteService, private _snackBar: MatSnackBar, private userService: UserInfoService,
    private modalService: NgbModal, private route: ActivatedRoute,private el: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;

    if( this.quoteDetails.quote_id != "" && this.quoteDetails.quote_id != "")
    {
      this.mta_quote_data = this.quoteDetails;
      this.parent_quote.quote_versions[0].mta_sum_policy_premium = this.quoteDetails.mta_sum_policy_premium;
      this.parent_quote.quote_versions[0].sum_policy_premium = this.quoteDetails.sum_policy_premium;
      this.parent_quote.quote_versions[0].latest_policy_start_date = this.quoteDetails.latest_policy_start_date;
      this.parent_quote.quote_versions[0].latest_policy_end_date = this.quoteDetails.latest_policy_end_date;

    }
    
    this.quote.statement_of_facts.forEach((res, index) => {
      if (res.response == 0) {
        res.response = null;
      }
    });

    if (this.route.snapshot.params.id && this.quote) { 
      if(this.quote.statement_of_facts.length != this.statementOfFacts.length) {
        this.statementOfFacts.forEach((res, index) => { 
          let sof = this.quote.statement_of_facts.filter(x => x.question_id == res.id);
          if (sof.length == 0) {
            this.quote.statement_of_facts.push({
              id: '',
              quote_id: '',
              question_id: res.id,
              response: null,
              response_bool: false,
              response_text: '',
              display_order: res.display_order
            });
          }
        });
      }
      // GET QUOTE DOCUMENTS       
        this.getDocuments();   
    } else {
      if (this.quote.statement_of_facts.length == 1) {
        this.quote.statement_of_facts = [];
      }
    }
    
    this.quote = this.quoteDetails;
    // Get All Questions
    this.quoteService.getStatementOfFacts(environment.AJG_ID,this.quote.sof_version)
      .subscribe(
        (response: any) => {
          if (response.success) {            
            this.statementOfFacts = response.result.statement_of_fact;

            if (this.quote.statement_of_facts.length == 0) {
              response.result.statement_of_fact.forEach((res, index) => {
                this.quote.statement_of_facts.push({
                  id: '',
                  quote_id: '',
                  question_id: res.id,
                  response: null,
                  response_bool: false,
                  response_text: '',
                  display_order: res.display_order
                });

              });
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );

  }

  ngOnInit(): void {
    

  }

  getDocuments() {
    if (this.quote.id) {
      this.quoteService.getQuoteDocuments(this.quote.id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              let data = [];
              response.result.forEach((res, index) => {
                if (res.is_static == 0) {
                  data.push(res);
                }
              });
              
              this.rowData = data;
             
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
}

  replace_horse_name(value: string) {
    return value.replace('[HORSENAME]', this.quote.horse_details.name);
  }

  backToFullDetailsBtn() {
    this.BackToFullDetails.emit();
  }

  response_description = [];
  onstatementOfFactChange(event, id, i) {
    if (event.value == 1) {
      this.response_description.push(id);
    } else {
      this.response_description.splice(this.response_description.indexOf(id), 1);
    }
  }

  /*
  * HELP TEXT POPUP
  */
  helptext = '';
  helpTextPopup(content, text) {
    const modalRef = this.modalService.open(content, { centered: true });
    this.helptext = text;
  }


  // UPLOAd DOCUMENT
  docTitle: string = '';
  fileToUpload: File = null;
  uploadDocumentBtn(documents) {
    this.documentID = '';
    this.docTitle = '';
    this.fileToUpload = null;
    this.documentsEditMode = false;
    this.quote.quote_document = '';
    this.modalService.open(documents, { centered: true });
  }

  getUploadedFile(files) {
    this.fileToUpload = files.item(0);
    this.quote.quote_document = this.fileToUpload.name;
  }


  documentUpload() {
    let formData = new FormData();
    formData.append("file_name", this.fileToUpload);
    formData.append("quote_id", this.quote.id);
    formData.append("broker_id", this.quote.broker_id);
    formData.append("title", this.docTitle);

    this.quoteService.saveQuoteDocument(formData)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.quote.quote_document = response.result.file_name;
            this.getDocuments();   
            // this.gridOptions.rowData.unshift({
            //   'id': response.result.id,
            //   'title': response.result.title,
            //   'quote_version': this.quote.sequence_number,
            //   'created_date': response.result.created_at,
            //   'download_path': response.result.file_path + '/' + response.result.file_name,
            // });
            // this.gridOptions.api.setRowData(this.gridOptions.rowData);
            // this.gridOptions.api.refreshCells();
            this._snackBar.open('Document uploaded.', '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to upload document.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  // List Quote Versions
  gridOptions: GridOptions = {
    onGridReady: () => {
      //this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    { headerName: 'Document Name', field: 'title', sortable: true, width: 300 },
    { headerName: 'Document Version', field: 'quote_version', sortable: true },
    {
      headerName: 'Creation Date', sortable: true, filter: true,
      cellRenderer: function (params) {
        return formatDate(params.data.created_date, 'dd-MM-yyyy', 'en-US');
      }
    },
    {
      headerName: null,
      width: 130,
      cellRenderer: function (params) {
        let links = '<a  title="Edit Documents" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit"></i></a>';
        // links += '<a class="ml-2" target="_blank" href="' + environment.imageURL + params.data.download_path + '" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"  download_path="' + params.data.download_path + '"></i></a>';
        links += '<a class="ml-2" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"  data-action-type="download"></i></a>';
        links += '<a class="ml-2" title="Delete Documents" style="font-size: 18px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete"></i></a>';
        return links;
      }
    }
  ];


  /*
  * GRID BUTTONS ACTION
  */
  documentsEditMode: boolean = false;
  documentID: string = '';
  onGridRowClicked(e: any, documents,documentsDelete) {
    if (e) {
      let download_path = e.event.target.getAttribute("download_path");
      let actionType = e.event.target.getAttribute("data-action-type");
      this.documentID = e.data.id;
      this.docTitle = e.data.title;
      switch (actionType) {
        case "download":
          {
            this.quoteService.downloadDocs(e.data);
            break;
          }
        case "edit": {          
          this.documentsEditMode = true;
          this.modalService.open(documents, { centered: true });
          break
        }
        case "delete": {
          this.modalService.open(documentsDelete, { centered: true });
          break;
        }
      }
    }
  }

  /*
  * UPDATE DOCUMENT
  */
  updateDocument() {
    let data: any = '';
    data = {
      document_id: this.documentID,
      title: this.docTitle
    };
    this.quoteService.updateDocument(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.rowData.forEach((res) => {
              if (this.documentID == res.id) {
                res.title = this.docTitle
              }
            });
            this.gridOptions.api.setRowData(this.rowData);
            this.gridOptions.api.refreshCells();
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to update title. Please try again later.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * Delete Document
  */
  deleteDocument() {
    let data: any = '';
    data = {
      document_id: this.documentID
    };
    this.quoteService.deleteDocument(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            const selectedRow = this.gridOptions.api.getFocusedCell()
            const id = this.gridOptions.rowData[selectedRow.rowIndex].i

            this.gridOptions.rowData.splice(selectedRow.rowIndex, 1)
            this.gridOptions.api.setRowData(this.gridOptions.rowData)
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      for (const key of Object.keys(f.controls)) {
        if (f.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('input[name=' + key + '], textarea.ng-invalid');
          invalidControl.focus();
          invalidControl.scrollIntoView({ behavior: 'smooth' });
          break;
        }
      }

      return false;
    }

    let dob = formatDate(this.quote.policy_holder.dob, 'yyyy-MM-dd', 'en-US');
    this.quote.policy_holder.dob = dob;

    let data: any = ''
    this.parent_quote.quote_versions[0] = this.quote;
    this.parent_quote.id = this.quote.quote_meta_id;
    data = {
      quote_data: this.parent_quote
    };
    this.quoteService.saveAJGIndicativeQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            var temp;
            temp = this.quote;
            this.quote = response.result.quote_data.quote_versions[0];
            this.quote.company_id = response.result.quote_data.company_id;  // Remove when company id will be under quote versions.
            this.quote.full_class_of_use = temp.full_class_of_use;
            this.quote.full_policy_cover = temp.full_policy_cover;
            this.quote.full_mortality_claim_loading = temp.full_mortality_claim_loading;
            this.quote.full_vet_fee_cover = temp.full_vet_fee_cover;
            this.quote.full_vet_claim_loading = temp.full_vet_claim_loading;
            this.quote.full_vet_fee_excess = temp.full_vet_fee_excess;
            this.quote.full_personal_accident = temp.full_personal_accident;
            this.quote.full_public_liability = temp.full_public_liability;
            this.quote.full_company_co_pay_excess = temp.full_company_co_pay_excess;
            this.quote.quote_document = temp.quote_document;
            this.quote.full_quote_section = temp.full_quote_section;
            this.quote.hide_sections_for_blood_stock = temp.hide_sections_for_blood_stock;
            this.quote.prev_sequence_number = temp.prev_sequence_number;
            this.quote.calculations = temp.calculations;
            this.quote.statement_of_facts = temp.statement_of_facts;
            this.quote.mta_sum_policy_premium = response.result.quote_data.mta_sum_policy_premium;
            this.quote.sum_policy_premium = response.result.quote_data.sum_policy_premium;
            this.quote.latest_policy_start_date = response.result.quote_data.latest_policy_start_date;
            this.quote.latest_policy_end_date = response.result.quote_data.latest_policy_end_date;
            this.quoteDetailsUpdated.emit(this.quote);
            this.ProceedToPayment.emit();
          }

        });

  }


}
