import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class ProductBasedConfigurationService {

  constructor(private sharedService: SharedService,private httpClient: HttpClient) { }

  // GET PRODUCTS
  getProducts() {
    return this.sharedService.getAPI('products');
  }
  // GET COMMISSION LIMIT
  maxCommission(company_id) {
    return this.sharedService.getAPI('staticdata/get_maximum_commission_limit/' + company_id);
  }
  //GET COMMISSION LIST
  getCommissions(company_id,product_id,version) {
    return this.sharedService.getAPI('company/configuration/commission/list/' + company_id +"/" + product_id + "/" + version);
  }
    //GET PUBLISH COMMISSION LIST
  getVersionList(company_id,product_id,publish) {
      return this.sharedService.getAPI('company/configuration/commission/versionlist/' + company_id +"/" + product_id + "/" + publish);
    }
  //GET COMMISSION DATA BY ID
  getCommissionDataById(Commission_id) {
    return this.sharedService.getAPI('company/configuration/commission/' + Commission_id);
  }
  //Save Commissions
  saveCommissionDetails(data){
    return this.sharedService.postAPI('company/configuration/commission',data);
  }
    //Publish Commissions
  publishCommissionDetails(commission_id){
    let data = {
      "id": commission_id
    }
      return this.sharedService.postAPI('company/configuration/commission/publish',data);
  }

  //GET LOGO LIST
  getLogoList(company_id,product_id,version) {
    return this.sharedService.getAPI('company/configuration/logo/list/' + company_id +"/" + product_id + "/" + version);
  }

  //Save LOGO
    saveLogoDetails(data){
      return this.sharedService.postFormData('company/configuration/logo',data);
    }

  //Publish LOGO
  publishLogoDetails(logo_id){
    let data = {
      "id": logo_id
    }
      return this.sharedService.postAPI('company/configuration/logo/publish',data);
    }

  //GET PUBLISH LOGO VERSION LIST 
  getLogoVersionList(company_id,product_id,publish) {
    return this.sharedService.getAPI('company/configuration/logo/versionlist/' + company_id +"/" + product_id + "/" + publish);
  }
  //GET LOGO DATA BY ID
  getLogoDataById(logo_id) {
    return this.sharedService.getAPI('company/configuration/logo/' + logo_id);
  }
  //GET MAIL QOUTE TYPE
  getMailLoadType() {
    return this.sharedService.getAPI('company/configuration/mail/loadtype');
  }
  //GET MAIL LOAD BASIC
  getMailLoadBasic(company_id) {
    return this.sharedService.getAPI('company/configuration/mail/loadbasic/' + company_id);
  }
  //GET MAIL LOAD TITLE
  getMailLoadTitle(company_id,product_id,type) {
    return this.sharedService.getAPI('company/configuration/mail/loadtitle/' + company_id +"/" + product_id +"/"+ type);
  }
  //GET MAIL TEMPLATE BY EMAIL TEMPLATE ID
  getMailLoadTemplateById(temp_id) {
    return this.sharedService.getAPI('company/configuration/mail/' + temp_id);
  }
  //Save Email
  saveEmailDetails(data){
    return this.sharedService.postAPI('company/configuration/mail',data);
  }
  //GET DOCUMENT QOUTE TYPE
  getDocumentLoadType() {
    return this.sharedService.getAPI('company/configuration/pdf/loadtype');
  }
  //GET DOCUMENT LOAD BASIC
  getDocumentLoadBasic(company_id) {
    return this.sharedService.getAPI('company/configuration/pdf/loadbasic/' + company_id);
  }
  //GET DOCUMENT LOAD TITLE
  getDocumentLoadTitle(company_id,product_id,type) {
    return this.sharedService.getAPI('company/configuration/pdf/loadtitle/' + company_id +"/" + product_id +"/"+ type);
  }
  //GET DOCUMENT TEMPLATE BY EMAIL TEMPLATE ID
  getDocumentLoadTemplateById(temp_id) {
    return this.sharedService.getAPI('company/configuration/pdf/' + temp_id);
  }
  //Save DOCUMENT
  saveDocumentDetails(data){
    return this.sharedService.postAPI('company/configuration/pdf',data);
  }
  //GET DOCUMENT LIST
  getDocumentLoadList(company_id,product_id) {
    return this.sharedService.getAPI('company/configuration/pdf/list/' + company_id +"/" + product_id);
  }
  //Get Bank Detail List
  getBankDetailList(company_id,product_id,version) {
    return this.sharedService.getAPI('company/configuration/bankdetail/list/' + company_id +"/" + product_id + "/" + version);
  }
  //GET PUBLISH COMMISSION LIST
  getBankDetailVersionList(company_id,product_id,publish) {
    return this.sharedService.getAPI('company/configuration/bankdetail/versionlist/' + company_id +"/" + product_id + "/" + publish);
  }
  //Save Bank Detail
  saveBankDetails(data){
    return this.sharedService.postAPI('company/configuration/bankdetail',data);
  }
  //Publish Bank Detail
  publishBankDetails(bank_id){
    let data = {
      "id": bank_id
    }
      return this.sharedService.postAPI('company/configuration/bankdetail/publish',data);
  }
  
  //GET BANK DATA BY ID
  getBankDataById(bank_id) {
    return this.sharedService.getAPI('company/configuration/bankdetail/' + bank_id);
  }
  //Save Static DOCUMENT
  saveStaticDocumentDetails(data){
    return this.sharedService.postFormData('company/configuration/staticdocs',data);
  }
  //GET STATIC DOCUMENT LIST
  getStaticDocumentList(company_id,product_id,version) {
    return this.sharedService.getAPI('company/configuration/staticdocuments/list/' + company_id +"/" + product_id + "/" + version);
  }
  //GET D2C CONFIGURATIONS LIST
  getD2CList(company_id,product_id,version) {
    return this.sharedService.getAPI('company/configuration/d2c/list/' + company_id +"/" + product_id + "/" + version);
  }

  //GET D2C CONFIGURATIONS BY ID
  getD2CById(id) {
    return this.sharedService.getAPI('company/configuration/d2c/' + id);
  }

  //Save D2C Detail
  saveD2CData(data){
    return this.sharedService.postAPI('company/configuration/d2c',data);
  }

  //GET D2C PUBLISH LIST
  getD2CVersionList(company_id,product_id,publish) {
    return this.sharedService.getAPI('company/configuration/d2c/versionlist/' + company_id +"/" + product_id + "/" + publish);
  }

  //Publish D2C Detail
  publishD2CDetails(id){
    let data = {
      "id": id
    }
      return this.sharedService.postAPI('company/configuration/d2c/publish',data);
  }
}
