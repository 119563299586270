<div [formGroup]="qdfrm">
    <div class="form_sections">
        <mat-expansion-panel expanded class="text-white">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Commissions
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="form-group row">
                <div class="col-md-4">Broker Comission <span *ngIf="isBrokerCommissionReduced" class="ml-1" style="color: #ff0000" title="Broker Commission Reduced"><i class="mdi mdi-alert-circle"></i></span></div>
                <div class="col-md-6">
                    <div *ngIf="quote_type >=3 || !isAdmin">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">%</span>
                            </div>
                            <input type="text" readonly class="form-control form-control-sm"
                                [value]="broker_commission">
                        </div>
                    </div>
                    <div *ngIf="quote_type < 3 && isAdmin">
                        <mat-slider name="broker_commission" thumbLabel [value]="broker_commission"
                            formControlName="broker_commission" (change)="onBrokerComissionChange($event)"
                            [displayWith]="formatSliderLabel" min="0" max="{{slider_broker_comm_max}}" step="0.5">
                        </mat-slider>
                    </div>
                </div>
            </div>

            <div *ngIf="isAdmin && enableCommissionsSection">
                <div class="row form-group">
                    <div class="col-md-4">Peliwica pay away commission</div>
                    <div class="col-md-6">
                        <div *ngIf="quote_type >= 3">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">%</span>
                                </div>
                                <input type="text" readonly class="form-control form-control-sm"
                                    [value]="peliwica_pay_away_commission">
                            </div>
                        </div>
                        <div *ngIf="quote_type < 3">
                            <mat-slider name="peliwica_pay_away_commission" thumbLabel
                                [value]="peliwica_pay_away_commission" formControlName="peliwica_pay_away_commission"
                                [displayWith]="formatSliderLabel" min="0" max="{{max_ppa_comission}}" step="0.5"
                                (change)="onPPayAwayComissionChange($event)">
                            </mat-slider>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-4">Peliwica pay away gross commission</div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="text" name="payaway_gross_commision" placeholder="Gross Commission"
                                class="form-control form-control-sm" [value]="ppaGrossCommission | number:'1.2-2'"
                                [disabled]="quote_type >= 3" readonly>
                        </div>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col-md-4">Peliwica retained commission</div>
                    <div class="col-md-6">
                        <div *ngIf="quote_type >= 3">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">%</span>
                                </div>
                                <input type="text" readonly class="form-control form-control-sm"
                                    [value]="peliwica_retained_commission">
                            </div>
                        </div>
                        <div *ngIf="quote_type < 3">
                            <mat-slider name="peliwica_retained_commission" thumbLabel
                                [value]="peliwica_retained_commission" formControlName="peliwica_retained_commission"
                                [displayWith]="formatSliderLabel" min="0" [max]="max_prt_comission" step="0.5"
                                (change)="onPRetainedComissionChange($event)">
                            </mat-slider>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-4">Peliwica retained gross commission</div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="text" name="retained_gross_commision" placeholder="Gross Commission"
                                class="form-control form-control-sm" [value]="prGrossCommission | number:'1.2-2'"
                                [disabled]="quote_type >= 3" readonly>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-4">Premium Loading</div>
                    <div class="col-md-6">
                        <div *ngIf="quote_type == 5 || quote_type == 6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">%</span>
                                </div> 
                                <input type="text" readonly class="form-control form-control-sm"
                                    [value]="premium_loading">
                            </div>
                        </div>
                        <div *ngIf="(quote_type != 5 &&  quote_type != 6)">
                            <mat-slider name="premium_loading" thumbLabel [value]="premium_loading"
                                formControlName="premium_loading" [displayWith]="formatSliderLabel" min="-100" max="100"
                                step="0.5" (change)="onPreLoadingChange($event)">
                            </mat-slider>
                        </div>
                    </div>
                </div>
                <!-- <div class="row form-group">
                    <div class="col-md-4">Legacy Loading</div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="number" class="form-control form-control-sm"
                                (blur)="ontTgerLabPremLoadingbChange($event)" [value]="legacy_loading">
                        </div>
                    </div>
                </div> -->
                <div class="row form-group">
                    <div class="col-md-4">Total Premium (Excl IPT)</div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="text" name="premium_loading" placeholder="Total Premium"
                                class="form-control form-control-sm" [value]="QuoteDetails.calculations.full_total_gross_primum | number:'1.2-2'" readonly>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-4">Underwriting Fee</div>
                    <div class="col-md-6">
                        <div *ngIf="quote_type >= 3">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="text" readonly class="form-control form-control-sm"
                                    [value]="uw_fees">
                            </div>
                        </div>
                        <div *ngIf="quote_type < 3">
                            <mat-slider name="uw_fee" thumbLabel [value]="uw_fees" formControlName="uw_fee"
                                (change)="onFeeChange($event)" [displayWith]="formatUWSliderLabel" min="0"
                                max="{{max_uw_fee}}" step="0.5">
                            </mat-slider>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-4">Broker Admin Fee</div>
                    <div class="col-md-6">
                        <div *ngIf="quote_type >= 3">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="text" readonly class="form-control form-control-sm"
                                    [value]="broker_admin_fee">
                            </div>
                        </div>
                        <div *ngIf="quote_type < 3">
                            <mat-slider name="uw_fee" thumbLabel [value]="broker_admin_fee" formControlName="broker_admin_fee"
                                (change)="onAdminBrokerFeeChange($event)" [displayWith]="formatUWSliderLabel" min="0"
                                max="{{max_broker_admin_fee}}" step="0.5">
                            </mat-slider>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</div>