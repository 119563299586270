<mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChanged($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            Proposer
        </ng-template>
        <div class="card-body">
            <app-proposer-details [staticData]="staticData" (isFleetActivated)="is_fleet($event)" [apiModelObj]="apiModelObj" (modelObjUpdated)="modelObjUpdated($event)" [authDetails]="authDetails"></app-proposer-details>
        </div>
    </mat-tab>
    <mat-tab *ngIf="isFleetActivated">
        <ng-template mat-tab-label>
            Fleet Experience
        </ng-template>
        <div class="card-body">
            <app-fleet-experience [staticData]="staticData" [modelObj]="modelObj" [apiModelObj]="apiModelObj" (modelObjUpdated)="modelObjUpdated($event)"></app-fleet-experience>
        </div>
    </mat-tab>

    <!-- <mat-tab > -->
    <mat-tab [disabled]="(selectedIndex == 0 ||  (selectedIndex == 1 && isFleetActivated)) && !url_id">
        <ng-template mat-tab-label>
            Vehicles
        </ng-template>
        <div class="card-body">
            <app-vehicles [staticData]="staticData" [modelObj]="modelObj" (modelObjUpdated)="modelObjUpdated($event)" [apiModelObj]="apiModelObj" [authDetails]="authDetails"></app-vehicles>
        </div>
    </mat-tab>
    <mat-tab [disabled]="(selectedIndex < 2 ||  (selectedIndex == 3 && isFleetActivated)) && !url_id">
        <ng-template mat-tab-label>
            Drivers
        </ng-template>
        <div class="card-body">
            <app-drivers [staticData]="staticData" [modelObj]="modelObj" (modelObjUpdated)="modelObjUpdated($event)" 
            [selectedIndex]="selectedIndex" [apiModelObj]="apiModelObj" [authDetails]="authDetails"></app-drivers>
        </div>
    </mat-tab>
    <mat-tab [disabled]="(selectedIndex < 3 ||  (selectedIndex == 4 && isFleetActivated)) && !url_id">
        <ng-template mat-tab-label>
            Quotation
        </ng-template>
        <div class="card-body">
            <app-am-quotation [staticData]="staticData" [authDetails]="authDetails" [modelObj]="modelObj" 
            (modelObjUpdated)="modelObjUpdated($event)" [apiModelObj]="apiModelObj" [selectedIndex]="selectedIndex"></app-am-quotation>
        </div>
    </mat-tab>
</mat-tab-group>
