<div class="row">
    <div class="col-md-12">
        <h3>Documents
            <div class="btn-group" *ngIf="!isInsurer">
                <button (click)="openDocumentsPopup(content)" class="btn btn-success btn-sm">Add Document</button>
                <!-- Hide Regenrate Schedule if quote is inprogress and referred -->
                <!-- <button (click)="regenerate_schedule(reSchedule)" 
                    *ngIf="quote && quote.insurance_status_id != 'e216564f-552a-4e45-ae67-0a63b7074691' && quote.insurance_status_id != 'f6d14624-6351-4d93-b27b-70fb8a06ec5d'" 
                    class="btn btn-gray text-white btn-sm">Regenerate
                    Schedule</button> -->
            </div>
        </h3>
    </div>
</div>
<div class="row mt-3" *ngIf="isAdmin && quote && quote.vet_certificate_required == 1">
    <div class="col-md-12">
        <div class="alert alert-success f13">
            <div class="row">
                <div class="col-md-4">
                    <input type="checkbox" name="vetcert" [checked]="quote.vet_certificate_submitted"
                        (change)="is_vet_cert_uploaded($event,vetCert)"> Is vet certificate uploaded?
                </div>
                <div class="col-md-4" *ngIf="quote.vet_certificate_submitted == 1">
                    Vet Certificate Upload Date: <b>{{ quote.vet_cert_upload_date | date : 'dd-MM-yyyy hh:mm a' }}</b>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <div class="form_sections">
            <h5>Amlin Documents</h5>
            <ag-grid-angular style="width: 100%; height: 300px;" class="ag-theme-bootstrap" [gridOptions]="gridOptions"
                [rowData]="rowData" [columnDefs]="columnDefs" animateRows pagination paginationPageSize=10 rowHeight=27
                headerHeight=35 (rowClicked)='onGridRowClicked($event,content,documentsDelete)'>
            </ag-grid-angular>
        </div>
        <div class="form_sections">
            <h5>Peliwica Documents</h5>
            <ag-grid-angular style="width: 100%; height: 300px;" class="ag-theme-bootstrap" [gridOptions]="gridOptions"
                [rowData]="rowDataPeliwica" [columnDefs]="columnDefsPeliwica" animateRows pagination paginationPageSize=10 rowHeight=27
                headerHeight=35 (rowClicked)='onGridRowClicked($event,content,documentsDelete)'>
            </ag-grid-angular>
        </div>
    </div>
    <ng-template #content let-modal>
        <div class="modal-header">
            <h4>Document</h4>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label>Title</label>
                <input type="text" name="document_title" [(ngModel)]="docTitle" class="form-control form-control-sm">
            </div>
            <div class="form-group" *ngIf="!documentsEditMode">
                <label>Document</label>
                <div class="custom-file">
                    <input type="file" name="file_name" (change)="getUploadedFile($event.target.files)"
                        class="custom-file-input" id="customFile">
                    <label class="custom-file-label" for="customFile">{{quote.quote_document}}</label>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" *ngIf="!documentsEditMode" class="btn btn-success"
                (click)="documentUpload()">Upload</button>
            <button type="button" *ngIf="documentsEditMode" class="btn btn-success"
                (click)="updateDocument()">Update</button>
            <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
        </div>
    </ng-template>
    <ng-template #documentsDelete let-modal>
        <div class="modal-header">
            <h4>Delete Document</h4>
        </div>
        <div class="modal-body text-center">
            <img src="assets/images/deactivate.png" width="50"><br>
            Are you sure you want to delete document {{docTitle}}?
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="deleteDocument()">Yes</button>
            <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
        </div>
    </ng-template>
    <ng-template #reSchedule let-modal>
        <div class="modal-body">
            {{schMsg}}
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default" (click)="modal.close()">OK</button>
        </div>
    </ng-template>
    <ng-template #vetCert let-modal>
        <div class="modal-body">
            <p [ngStyle]="{'color': certMsgStyle?'#138a51':'red'}">{{vetCertMsg}}</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="submit_vet_certificate()">Yes</button>
            <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
        </div>
    </ng-template>