<form [formGroup]="qdfrm" (ngSubmit)="onSubmit()">
    <div class="form_details">
        <!-- Policy Holder Details-->
        <div class="form_sections">
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Quote Details
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row form-group" *ngIf="quote.policy_start_date !=null">
                    <div class="col-md-4"><label>Policy Start Date</label></div>
                    <div class="col-md-6">
                        <input type="text" class="form-control form-control-sm"
                            [value]="quote.policy_start_date | date:'dd-MM-yyyy'" readonly />
                    </div>
                </div>
                <div class="row form-group" *ngIf="brokerName !=null">
                    <div class="col-md-4"><label>Broker</label></div>
                    <div class="col-md-6">
                        <!-- <div *ngIf="!isPolicy"> -->
                        <input type="text" [value]="brokerName" class="form-control form-control-sm" readonly>
                        <!-- </div>
                        <div *ngIf="isPolicy">
                            <mat-select class="form-control form-control-sm" [value]="quote.company_id"
                                formControlName="company_id" required
                                [ngClass]="{ 'is-invalid': (qdFrmCtrl.company_id.touched || submitted) && qdFrmCtrl.company_id.errors?.required }">
                                <mat-option [value]="">Select Broker</mat-option>
                                <mat-option *ngFor="let brk of brokers" [value]="brk.id">
                                    {{brk.name}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(qdFrmCtrl.company_id.touched || submitted) && qdFrmCtrl.company_id.errors?.required">
                                Policy start date is required
                            </div>
                        </div> -->
                    </div>
                </div>

            </mat-expansion-panel>
        </div>

        <div class="form_sections" *ngIf="quote.livestock_meta">
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        About the Proposer
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row form-group">
                    <div class="col-md-4"><label>Name</label></div>
                    <div class="col-md-3">
                        <input type="text" name="first_name" placeholder="First Name"
                            class="form-control form-control-sm" formControlName="about_proposer_first_name"
                            readonly required
                            [ngClass]="{ 'is-invalid': (qdFrmCtrl.about_proposer_first_name.touched || submitted) && qdFrmCtrl.about_proposer_first_name.errors?.required }" />
                        <div class="invalid-feedback"
                            *ngIf="(qdFrmCtrl.about_proposer_first_name.touched || submitted) && qdFrmCtrl.about_proposer_first_name.errors?.required">
                            First name is required
                        </div>
                    </div>
                    <div class="col-md-3">
                        <input type="text" name="last_name" placeholder="Last Name" class="form-control form-control-sm"
                            formControlName="about_proposer_last_name" readonly>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-4"><label>Email</label></div>
                    <div class="col-md-6">
                        <input type="email" name="email" placeholder="Email" class="form-control form-control-sm"
                            formControlName="about_proposer_email" readonly required
                            pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                            [ngClass]="{ 'is-invalid': (qdFrmCtrl.about_proposer_email.touched || submitted) && qdFrmCtrl.about_proposer_email.errors?.required }"
                            appTitleCase>
                        <div class="invalid-feedback" *ngIf="qdFrmCtrl.about_proposer_email.touched || submitted">
                            <div *ngIf="qdFrmCtrl.about_proposer_email.errors?.required">Email is required.
                            </div>
                            <div *ngIf="qdFrmCtrl.about_proposer_email.errors?.pattern">Please provide a valid
                                email address
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="form_sections">
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Proposer Other Details
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row form-group">
                    <div class="col-md-4"><label class="text-white f13">Proposer Type</label>
                    </div>
                    <div class="col-md-6">
                        <mat-radio-group class="row" aria-label="Select an option" name="proposer_type" required
                            formControlName="proposer_type" (change)="onPprTypeChange($event)">

                            <div class="col-md-4">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="3">Commercial</mat-radio-button>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="1">Individual</mat-radio-button>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="2">Joint Names</mat-radio-button>
                                </div>
                            </div>
                        </mat-radio-group>
                        <div class="invalid-feedback" style="font-size: 13px;"
                            *ngIf="(qdFrmCtrl.proposer_type.touched || submitted) && qdFrmCtrl.proposer_type.errors?.required">
                            Please select an animal.
                        </div>
                    </div>
                </div>


                <!-- Commercial -->
                <div *ngIf="qdFrmCtrl && qdFrmCtrl.proposer_type.value == 3" formGroupName="commercial_details">
                    <div *ngFor="let policyholder of quote.policy_holders">
                        <div class="row form-group">
                            <div class="col-md-4"><label class="text-white f13">Is this client a
                                    Micro-enterprise?</label>
                            </div>
                            <div class="col-md-6">
                                <mat-radio-group class="row" aria-label="Select an option"
                                    formControlName="is_micro_enterprise"
                                    name="is_micro_enterprise">
                                    <div class="col-md-6">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="1">
                                                Yes</mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>
                                            <div class="quote_radio">
                                                <mat-radio-button [value]="2">
                                                    No</mat-radio-button>
                                            </div>
                                        </div>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                        <h5 class="text-white">Company Details</h5>
                        <div class="row form-group">
                            <div class="col-md-4"><label>Company Name</label></div>
                            <div class="col-md-6">
                                <input type="text" name="company_name" placeholder="Company name/ Trading name"
                                    class="form-control form-control-sm" formControlName="company_name"
                                    [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.company_name').touched || submitted) && qdfrm.get('commercial_details.company_name').errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(qdfrm.get('commercial_details.company_name').touched || submitted) && qdfrm.get('commercial_details.company_name').errors?.required">
                                    Company name is required
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-4"><label>Email</label></div>
                            <div class="col-md-6">
                                <input type="email" name="email" placeholder="Email"
                                    class="form-control form-control-sm" formControlName="email"
                                    required
                                    pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                                    [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.email').touched || submitted) && qdfrm.get('commercial_details.email').errors?.required }"
                                    appTitleCase>
                                <div class="invalid-feedback"
                                    *ngIf="qdfrm.get('commercial_details.email').touched || submitted">
                                    <div *ngIf="qdfrm.get('commercial_details.email').errors?.required">Email is
                                        required.
                                    </div>
                                    <div *ngIf="qdfrm.get('commercial_details.email').errors?.pattern">Please provide a
                                        valid
                                        email address
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-md-4"><label>Contact Number</label></div>
                            <div class="col-md-3">
                                <input type="text" name="contact_no_1" placeholder="Contact number 1"
                                    class="form-control form-control-sm" formControlName="contact_no_1"
                                     reqquired
                                    [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.contact_no_1').touched || submitted) && qdfrm.get('commercial_details.contact_no_1').errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(qdfrm.get('commercial_details.contact_no_1').touched || submitted) && qdfrm.get('commercial_details.contact_no_1').errors?.required">
                                    Contact number is required
                                </div>
                            </div>
                            <div class="col-md-3">
                                <input type="text" name="contact_no_2" placeholder="Contact number 2"
                                    class="form-control form-control-sm" formControlName="contact_no_2">
                            </div>

                        </div>
                        <div class="row form-group">
                            <div class="col-md-4"><label>Postcode</label></div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" name="postcode" placeholder="Postcode"
                                        class="form-control form-control-sm" autocomplete="off"
                                        (keyup)="qdfrm.get('commercial_details.postcode').errors?'':postcodeLookup($event)"
                                        formControlName="postcode" required
                                        pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr]?0[Aa]{2})$"
                                        [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.postcode').touched || submitted)  && qdfrm.get('commercial_details.postcode').errors?.required }"
                                        [matAutocomplete]="auto" appUpperCase>
                                    <div *ngIf="(qdfrm.get('commercial_details.postcode').touched || submitted) && qdfrm.get('commercial_details.postcode').errors?.required && qdfrm.get('commercial_details.postcode').errors?.pattern"
                                        class="invalid-feedback">
                                        Invalid postcode.
                                    </div>
                                    <div>
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <div *ngIf="!qdfrm.get('commercial_details.postcode').errors">
                                                <mat-option [value]="qdfrm.get('commercial_details.postcode').value"
                                                    (click)="onSelectAddress(adrs)" *ngFor="let adrs of addresses">
                                                    {{adrs.formatted_address.join(', ')}}</mat-option>
                                            </div>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-4"><label>Address</label></div>
                            <div class="col-md-3">
                                <input type="text" name="address_line_1" placeholder="Line 1"
                                    class="form-control form-control-sm" formControlName="address_line_1"
                                    required
                                    [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.address_line_1').touched || submitted) && qdfrm.get('commercial_details.address_line_1').errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(qdfrm.get('commercial_details.address_line_1').touched || submitted) && qdfrm.get('commercial_details.address_line_1').errors?.required">
                                    Address line 1 is required
                                </div>
                            </div>
                            <div class="col-md-3">
                                <input type="text" name="address_line_2" placeholder="Line 2"
                                    class="form-control form-control-sm" formControlName="address_line_2">
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-4"><label>Town</label></div>
                            <div class="col-md-6">
                                <input type="text" name="city" placeholder="Town" class="form-control form-control-sm"
                                    formControlName="city" required
                                    [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.city').touched || submitted) && qdfrm.get('commercial_details.city').errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(qdfrm.get('commercial_details.city').touched || submitted) && qdfrm.get('commercial_details.city').errors?.required">
                                    Town is required
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-4"><label>County</label></div>
                            <div class="col-md-6">
                                <input type="text" name="county" placeholder="County"
                                    class="form-control form-control-sm" formControlName="county"
                                    required
                                    [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.county').touched || submitted) && qdfrm.get('commercial_details.county').errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(qdfrm.get('commercial_details.county').touched || submitted) && qdfrm.get('commercial_details.county').errors?.required">
                                    County is required
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-4"><label>Year of Establishment</label></div>
                            <div class="col-md-6">
                                <input type="text" name="year_of_establishment" placeholder="Year of Establishment"
                                    class="form-control form-control-sm" formControlName="year_of_establishment"
                                     required maxlength="4" appNumbers
                                    [ngClass]="{ 'is-invalid': (qdfrm.get('commercial_details.year_of_establishment').touched || submitted) && qdfrm.get('commercial_details.year_of_establishment').errors?.required }">
                                <div class="invalid-feedback"
                                    *ngIf="(qdfrm.get('commercial_details.year_of_establishment').touched || submitted) && qdfrm.get('commercial_details.year_of_establishment').errors?.required">
                                    Year of establishment is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Joint Names & Individual -->
                <div *ngIf="qdFrmCtrl && qdFrmCtrl.proposer_type.value != 3">
                    <div formArrayName="proposer_type_details" *ngFor="let ptd of podArr.controls; let i=index">
                        <div [formGroupName]="i">
                            <label class="text-white">Proposer {{ (qdFrmCtrl.proposer_type.value == 2)?i+1:""
                                }}</label>
                            <span *ngIf="i >= 1">
                                <a class="btn btn-danger btn-sm text-white ml-3" (click)="removeProposers(i)"
                                    style="padding: 5px;">
                                    <i class="mdi mdi-delete"></i>
                                </a>
                            </span>
                            <div class="row form-group">
                                <div class="col-md-4"><label>Proposer First Name</label></div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="proposer_first_name" placeholder="Proposer First Name"
                                             class="form-control form-control-sm"
                                            formControlName="proposer_first_name" required
                                            [ngClass]="{ 'is-invalid': (ptd.controls.proposer_first_name.touched || submitted) && ptd.controls.proposer_first_name.errors?.required }">
                                        <div class="invalid-feedback" style="font-size: 13px;"
                                            *ngIf="(ptd.controls.proposer_first_name.touched || submitted) && ptd.controls.proposer_first_name.errors?.required">
                                            First name is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-4"><label>Proposer Last Name</label></div>
                                <div class="col-md-6">
                                    <input type="text" name="proposer_last_name" placeholder="Proposer Last Name"
                                       class="form-control form-control-sm"
                                        formControlName="proposer_last_name">
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-4"><label>Proposer Email</label></div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="proposer_email" placeholder="Proposer Email"
                                             class="form-control form-control-sm"
                                            formControlName="proposer_email"
                                            requiredpattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                                            [ngClass]="{ 'is-invalid': (ptd.controls.proposer_email.touched || submitted) && ptd.controls.proposer_email.errors?.required }"
                                            appTitleCase>
                                        <div class="invalid-feedback"
                                            *ngIf="ptd.controls.proposer_email.touched || submitted">
                                            <div *ngIf="ptd.controls.proposer_email.errors?.required">Email is required.
                                            </div>
                                            <div *ngIf="ptd.controls.proposer_email.errors?.pattern">Please provide a
                                                valid email address </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-4"><label>Proposer Postcode</label></div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="proposer_postcode" placeholder="Postcode"
                                            class="form-control form-control-sm" autocomplete="off"
                                            (keyup)="ptd.controls.proposer_postcode.errors?'':postcodeLookup($event)"
                                            pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr]?0[Aa]{2})$"
                                            formControlName="proposer_postcode"
                                            [ngClass]="{ 'is-invalid': (ptd.controls.proposer_postcode.touched || submitted)  && ptd.controls.proposer_postcode.errors?.required }"
                                            [matAutocomplete]="auto" appUpperCase>
                                        <div class="invalid-feedback"
                                            *ngIf="ptd.controls.proposer_postcode.touched || submitted">
                                            <div *ngIf="ptd.controls.proposer_postcode.errors?.required">Postcode is
                                                required.
                                            </div>
                                            <div *ngIf="ptd.controls.proposer_postcode.errors?.pattern">Invalid
                                                postcode. </div>
                                        </div>
                                        <div>
                                            <mat-autocomplete #auto="matAutocomplete">
                                                <div *ngIf="!ptd.controls.proposer_postcode.errors">
                                                    <mat-option [value]="ptd.controls.proposer_postcode.value"
                                                        (click)="onSelectAddress(adrs,i)"
                                                        *ngFor="let adrs of addresses">
                                                        {{adrs.formatted_address.join(', ')}}</mat-option>
                                                </div>
                                            </mat-autocomplete>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-4"><label>Proposer Address Line 1</label></div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="address_line_1" placeholder="Line 1"
                                            formControlName="proposer_address_line_1"
                                            class="form-control form-control-sm" appTitleCase
                                            [ngClass]="{ 'is-invalid': (ptd.controls.proposer_address_line_1.touched || submitted) && ptd.controls.proposer_address_line_1.errors?.required }"
                                            required>
                                        <div *ngIf="(ptd.controls.proposer_address_line_1.touched || submitted) && ptd.controls.proposer_address_line_1.errors?.required"
                                            class="invalid-feedback">
                                            Address 1 is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-4"><label>Proposer Address Line 2</label></div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="proposer_address_line_2" placeholder="Line 2"
                                            formControlName="proposer_address_line_2"
                                            class="form-control form-control-sm" appTitleCase>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-4"><label>Proposer City</label></div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="proposer_city" placeholder="Town"
                                            formControlName="proposer_city"
                                            class="form-control form-control-sm" appTitleCase
                                            [ngClass]="{ 'is-invalid': (ptd.controls.proposer_city.touched || submitted) && ptd.controls.proposer_city.errors?.required }"
                                            required>
                                        <div *ngIf="(ptd.controls.proposer_city.touched || submitted) && ptd.controls.proposer_city.errors?.required"
                                            class="invalid-feedback">
                                            City is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-4"><label>Proposer Country</label></div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="proposer_county" placeholder="County"
                                           formControlName="proposer_county"
                                            class="form-control form-control-sm" appTitleCase
                                            [ngClass]="{ 'is-invalid': (ptd.controls.proposer_county.touched || submitted) && ptd.controls.proposer_county.errors?.required }"
                                            required>
                                        <div *ngIf="(ptd.controls.proposer_county.touched || submitted) && ptd.controls.proposer_county.errors?.required"
                                            class="invalid-feedback">
                                            County is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="qdFrmCtrl.proposer_type.value == 2">
                        <div class="col-md-4">
                            <div class="form-group">
                                <a (click)="addProposer()" class="btn btn-success">Add another proposer</a>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="form_sections">
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <!-- About the Sale -->
                        Date of Sale
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row form-group">
                    <div class="col-md-4"><label>Sale Location</label></div>
                    <div class="col-md-6">
                        <input type="text" name="sale_location" placeholder="Sale Location"
                            class="form-control form-control-sm" formControlName="sale_location">
                    </div>
                </div>
                <div class="row form-group" *ngIf="quote.livestock_meta">
                    <div class="col-md-4"><label>Sale Date</label></div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" readonly name="sale_date" formControlName="sale_date"
                                placeholder="Sale Date" class="form-control form-control-sm datepicker" matInput
                                (focus)="sdDatepicker.open()" [matDatepicker]="sdDatepicker">
                            <mat-datepicker-toggle [for]="sdDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #sdDatepicker></mat-datepicker>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="form_sections">
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        About the Claims History
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row form-group">
                    <div class="col-md-4"><label>Number of Claims</label></div>
                    <div class="col-md-6">
                        <input type="text" name="number_of_claims" class="form-control form-control-sm"
                             formControlName="claims" readonly>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="form_sections" *ngIf="qdFrmCtrl.claims && qdFrmCtrl.claims.value > 0"> 
            <div *ngFor="let claim of livestock_claims; let i = index;">
                    <label>Claim {{ i + 1 }}</label>
                    <div class="row form-group">
                        <div class="col-md-4"><label>Animal Type</label></div>
                        <div class="col-md-6">
                            <input type="text" name="animal_type" [value]="claim.animal_type" class="form-control form-control-sm" readonly>
                            
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-4"><label>How much was the claim for</label></div>
                        <div class="col-md-6">
                            <input type="text" name="claim_for" [value]="claim.claim_for" class="form-control form-control-sm" readonly>
                            
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-4"><label>Cover claimed for</label></div>
                        <div class="col-md-6">
                            <input type="text" name="cover_claim_for" [value]="claim.cover_claim_for" class="form-control form-control-sm" readonly>
                            
                        </div>
                    </div>
            </div>
        </div>

        <!--Animal Section-->
        <app-animals-insurance formControlName="animals" [submitted]="submitted" (calDetails)="calDetails($event)"
            [staticData]="staticData" [selectedTabIndex]='1' [quoteModelDetails]="quote">
        </app-animals-insurance>

        <ng-template #helpTextPopupModal let-modal>
            <div class="modal-body">
                <div class="helptext" [innerHTML]="msg"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
            </div>
        </ng-template>
        <div class="form_sections">
            <app-quote-endorsement [quote]="quote"></app-quote-endorsement>
        </div>

        <button class="btn btn-default btn-success mt-5">Save</button>
    </div>
</form>

<!-- <p>
    Form is {{qdfrm.valid ? 'Valid' : 'Invalid'}}
</p>
<pre>
            {{qdfrm.value | json}}
            </pre> -->