import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { LocalStorageService } from 'ngx-webstorage';
import { ActivatedRoute, Router } from "@angular/router";
import { QuoteService } from '../../quote/quote.service';
import { PolicyService } from '../../policy/policy.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-qv-overview',
  templateUrl: './qv-overview.component.html',
  styleUrls: ['./qv-overview.component.css']
})
export class QvOverviewComponent implements OnInit {
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() hasImportantNotes = new EventEmitter();
  quote = new QuoteVersions();
  policyStatuses;
  @Input() set quoteData(value: any) {
    if (value) {
      this.quote = value;
    }
  }
  insurers;
  underwriters;
  selectedUnderwriterID = this.sessionSt.retrieve('userInfo').id;
  productIdLivestock = environment.LIVESTOCK_ID;
  constructor(private sessionSt: LocalStorageService, private router: Router, private quoteService: QuoteService, private policyService: PolicyService) { }

  ngOnInit(): void {
    // API - GET INSURERS
    this.quoteService.getInsurers()
      .subscribe(
        (response: any) => {
          this.insurers = response.result;
        });

    // API - GET UNDERWRITERS
    this.quoteService.getUnderwriters()
      .subscribe(
        (response: any) => {
          this.underwriters = response.result;


        });
  }

  policyReason: string = '';
  isPolicy: boolean = false;
  product_id = '';
  ngOnChanges(changes: SimpleChanges) {
    this.isPolicy = (this.quote.isPolicyData) ? true : false;

    if (this.quote) {
      switch (this.quote.product_id) {
        case environment.EQUINE_ID:
          this.product_id = environment.EQUINE_ID;
          break;
        case environment.ALPACA_ID:
          this.product_id = environment.ALPACA_ID;
          break;
        case environment.LIVESTOCK_ID:
          this.product_id = environment.LIVESTOCK_ID;
          break;
        case environment.ALLIANZ_EQUINE_ID:
          this.product_id = environment.ALLIANZ_EQUINE_ID;
          break;
        case environment.AJG_ID:
          this.product_id = environment.AJG_ID;
          break;
      }
    }
    if (this.product_id) {
      this.policyService.getPolicyMTAReasons(this.product_id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              if (response.result.types) {
                let reasons = response.result.types.filter(x => x.id == this.quote.mta_reason_id);
                if (reasons.length > 0) {
                  this.policyReason = reasons[0].reasons;
                }
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
    this.getQuoteTypes();
    if (this.quote) {
      if (this.quote.is_policy == 0) {
        this.getPolicyStatus(0);
      } else {
        this.getPolicyStatus(1);
      }
    }
  }
  transction_type = '';
  getQuoteTypes() {
    if (!this.transction_type) {
      // Get Quote Types
      this.quoteService.getQuoteTypes(1)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.transction_type = response.result.types[this.quote.type];
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
  /*
  * View Quote Details
  */
  quoteDetails() {
    this.sessionSt.store('policy_meta_id', this.quote.id);
    this.sessionSt.store('quote_ref_id', this.quote.id);
    switch (this.product_id) {
      case environment.EQUINE_ID:
        this.router.navigate(['/quote-details', this.quote.id]);
        break;
      case environment.ALPACA_ID:
        this.router.navigate(['/alpaca-admin/quote-details', this.quote.id]);
        break;
      case environment.LIVESTOCK_ID:
        this.router.navigate(['/livestock/quote-details', this.quote.id]);
        break;
      case environment.ALLIANZ_EQUINE_ID:
        this.router.navigate(['/allianz-equine/quote-details', this.quote.id]);
        break;
      case environment.AJG_ID:
        this.router.navigate(['/ajg/quote-details', this.quote.id]);
        break;
    }
    
  }

  /*
  * View Policy Details
  */
  policyDetails() {
    let quote_id;
    let quote_versions = this.quote.versions.filter(x => x.is_policy == 1);
    if (quote_versions.length > 0) {
      quote_id = quote_versions[0].quote_id;
    }
    switch (this.product_id) {
      case environment.EQUINE_ID:
        this.router.navigate(['/policy', this.quote.id]);
        break;
      case environment.ALPACA_ID:
        this.router.navigate(['/alpaca-admin/policy', this.quote.id]);
        break;
      case environment.LIVESTOCK_ID:
        this.router.navigate(['/livestock/policy', quote_id]);
        break;
      case environment.ALLIANZ_EQUINE_ID:
        this.router.navigate(['/allianz-equine/policy', this.quote.id]);
        break;
      case environment.AJG_ID:
        this.router.navigate(['/ajg/policy', this.quote.id]);
        break;
    }
    
  }
  // List Quote Versions
  quoteVersionsUpdated(qudatedQuote) {
    this.quote = qudatedQuote;
    this.quoteDetailsUpdated.emit(qudatedQuote);
    this.transction_type = '';
    this.getQuoteTypes();

  }
  onhasImportantNotesChange(isImportant) {
    this.hasImportantNotes.emit(isImportant);
  }

  getPolicyStatus(type) {
    this.policyService.getPolicyStatuses()
      .subscribe(
        (response: any) => {
          if (response.success) {
            if (type == 0) {
              this.policyStatuses = response.result.quote_insurance_status;
            } else {
              this.policyStatuses = response.result.policy_insurance_status;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
