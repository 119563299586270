import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../../quote/alpaca/model/quote/quote_versions.model';
import { AlQuoteService } from '../../../quote/alpaca/al-quote/al-quote.service';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import { UserInfoService } from '../../../services/user-info.service';

@Component({
  selector: 'app-al-policy-sof',
  templateUrl: './policy-sof.component.html',
  styleUrls: ['../../../quote/quote.component.css'],
})
export class AlPolicySofComponent implements OnInit {
  @Input() quoteData: QuoteVersions;

  statementOfFacts;

  constructor(private quoteService: AlQuoteService, private modalService: NgbModal,
    private sessionSt: LocalStorageService, private userService: UserInfoService) { }
  quote;
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteData;
    if(this.quote.length > 0 && this.quote.statement_of_facts.length > 0) {
      this.quote.statement_of_facts.forEach((res, index) => {
        if(res.response == 0) {
          res.response = null;
        }
      });
    }
  } 

  ngDoCheck() {
    if (this.statementOfFacts && this.quote) {
      let temp;
      this.statementOfFacts.forEach((res, index) => {
        // Assign Section Titles
        if (res.product_type == 2 && temp != res.product_type) {
          if(this.quote.about_alpaca.does_vet_treatments_covered == 1) {
            res.quesTitles = 'ALPACA/ LLAMA mortality and limited theft with vets fees statement of facts';
          } else {
            res.quesTitles = 'ALPACA/ LLAMA mortality';
          }          
        } else if (res.product_type == 3 && temp != res.product_type) {
          res.quesTitles = 'Public; Product and Employers Liability statement of Facts';
        }
        temp = res.product_type;
      });
    }

  }

  ngOnInit(): void {
    // Get All Questions
    this.quoteService.getStatementOfFacts(this.quote.sof_version)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.statementOfFacts = response.result.statement_of_fact;
            if (this.quote.statement_of_facts.length <= 1) {
              this.statementOfFacts.forEach((res, index) => {     
                this.quote.statement_of_facts.push({
                  id: '',
                  quote_id: '',
                  question_id: res.id,
                  response: null,
                  response_bool: false,
                  response_text: '',
                  display_order: res.display_order
                });

              });
            }
          }

        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
  * HELP TEXT POPUP
  */
 helptext = '';
 helpTextPopup(content, text) {
   const modalRef = this.modalService.open(content, { centered: true });
   this.helptext = text;
 }


}
