import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { QuoteService } from '../../quote.service';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { UserInfoService } from '../../../services/user-info.service';

@Component({
  selector: 'app-quote-sof',
  templateUrl: './quote-sof.component.html',
  styleUrls: ['../../quote.component.css'],
})
export class QuoteSofComponent implements OnInit {
  @Input() quote: QuoteVersions;
  statementOfFacts = [];


  constructor(private quoteService: QuoteService, private modalService: NgbModal, private userService: UserInfoService) { }

  ngOnChanges(changes: SimpleChanges) {
    if( this.quote.statement_of_facts.length > 0) {
      this.quote.statement_of_facts.forEach((res, index) => {
        if(res.response == 0) {
          res.response = null;
        }
      });

      this.initializeSOFForNewQuest();
    }
 
        // Get All Questions
        this.quoteService.getStatementOfFacts(environment.EQUINE_ID,this.quote.sof_version)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.statementOfFacts = response.result.statement_of_fact;
              this.initializeSOFForNewQuest();
              if (this.quote.statement_of_facts.length <= 1) {
                response.result.statement_of_fact.forEach((res, index) => {
                  this.quote.statement_of_facts.push({
                    id: '',
                    quote_id: '',
                    question_id: res.id,
                    response: null,
                    response_bool: false,
                    response_text: '',
                    display_order: res.display_order
                  });
  
                });
              } 
            }
          },
          (error) => {
            console.log(error);
          }
        );

  }

  ngOnInit(): void {

  }

  initializeSOFForNewQuest() {
    if(this.quote.statement_of_facts.length != this.statementOfFacts.length) {
      this.statementOfFacts.forEach((res, index) => { 
        let sof = this.quote.statement_of_facts.filter(x => x.question_id == res.id);
        if (sof.length == 0) {
          this.quote.statement_of_facts.push({
            id: '',
            quote_id: '',
            question_id: res.id,
            response: null,
            response_bool: false,
            response_text: '',
            display_order: res.display_order
          });
        }
      });
    }
  }
  
  /*
  * HELP TEXT POPUP
  */
 helptext = '';
 helpTextPopup(content, text) {
   const modalRef = this.modalService.open(content, { centered: true });
   this.helptext = text;
 }


}
