<form #f="ngForm" (ngSubmit)="onSubmit(f)">
  <div class="row">
    <div class="col-md-6">
      <h3>New Message</h3>
    </div>
    <div class="col-md-6">
      <button (click)="returnToEmailsList()" class="btn btn-success btn-sm float-right">Back to Emails List</button>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-8">
      <div class="form_sections">
        <div class="form-group row">
          <label class="col-md-3">To</label>
          <div class="col-md-9">
            <input type="text" name="email_to" placeholder="To" class="form-control form-control-sm"
              [(ngModel)]="emailDetails.email_to" [ngClass]="{ 'is-invalid': f.submitted && email_to.invalid }"
              #email_to="ngModel" required autocomplete="off">
            <div *ngIf="f.submitted && email_to.invalid" class="invalid-feedback">
              Email to is required.
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3">Cc</label>
          <div class="col-md-9">
            <input type="text" name="email_cc" placeholder="Cc" class="form-control form-control-sm"
              [(ngModel)]="emailDetails.email_cc" #email_cc="ngModel" autocomplete="off">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3">Bcc</label>
          <div class="col-md-9">
            <input type="text" name="email_bcc" placeholder="Bcc" class="form-control form-control-sm"
              [(ngModel)]="emailDetails.email_bcc" #email_bcc="ngModel" autocomplete="off">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3">Subject</label>
          <div class="col-md-9">
            <input type="text" name="subject" placeholder="Subject" class="form-control form-control-sm"
              [(ngModel)]="emailDetails.subject" [ngClass]="{ 'is-invalid': f.submitted && subject.invalid }"
              #subject="ngModel" required>
            <div *ngIf="f.submitted && subject.invalid" class="invalid-feedback">
              Subject is required.
            </div>
          </div>
        </div>
        <div class="form-group">
          <!-- <editor name="body"
          [(ngModel)]="emailDetails.body"
          required
          [ngClass]="{ 'is-invalid': f.submitted && body.invalid }"
          #body="ngModel" 
          [apiKey]='TINY_MCE_KEY' [init]="{
            icons: 'material',
            skin: 'borderless',
            plugins: 'wordcount',
            menubar: false,
            min_height: 300
          }"></editor> -->
          <angular-editor [ngClass]="{ 'is-invalid': f.submitted && body.invalid }"
                                    #body="ngModel" required  [placeholder]="'Enter text here...'" [(ngModel)]="emailDetails.body" name="body" style="background-color: #fff;"></angular-editor>
          <div *ngIf="f.submitted && body.invalid" class="invalid-feedback">
            Message is required.
          </div>
        </div>
        <div class="form-group">
          <label>Attachments</label>
          <div class="custom-file">
            <input type="file" name="file_name" (change)="getUploadedFile($event.target.files)"
              class="custom-file-input" id="customFile" multiple>
            <label class="custom-file-label" for="customFile">{{emailDetails.attachments}}</label>
          </div>
        </div>
        <div class="form-group">
          <button name="submit" class="btn btn-success btn-gray">Send</button>
        </div>
      </div>
    </div>
  </div>
</form>