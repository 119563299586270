import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { formatDate } from '@angular/common';
import { CalculationsDetails } from '../../calculations/calculations.model';
import { NgForm } from '@angular/forms';
import { QuoteService } from '../../quote.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserInfoService } from '../../../services/user-info.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-financials',
  templateUrl: './financials.component.html',
  styles: [
  ]
})
export class FinancialsComponent implements OnInit {
  @Input() quote: QuoteVersions;
  calculations = new CalculationsDetails();
  co_pay_discount_details;
  vetFeesCover = [];
  vetFeesCoverBloodStock = [];
  vetFeeCoverSpecial = [];
  vet_minimum_premium = 0;
  AllianzProductID = environment.ALLIANZ_EQUINE_ID;
  AJGProductID = environment.AJG_ID;
  @Input()
  set staticData(value: any) {
    if (value) {
      // Company Co Pay Discount
      if (value.company_co_pay_discount.length > 0) {
        this.co_pay_discount_details = value.company_co_pay_discount[0];

      }
      // Vet Fee Cover
      value.policy_cover_details.forEach((res, index) => {
        if (res.is_vet_fee_cat == 1) {
          res.section_indeminity_limit.forEach((res1) => {
            if (res1.is_special_vet == 0) {
              if (res1.is_blood_stock == 0) {
                if (res1.id == this.quote.vet_fee_sum_insured_id) {
                  this.quote.full_vet_fee_cover = res1.description;
                  res1.is_checked = true;
                }
                this.vetFeesCover.push(res1);
              } else {
                this.vetFeesCoverBloodStock.push(res1);
              }
            } else {
              this.vetFeeCoverSpecial.push(res1);
            }
          });
        }
      });
    }
  }

  quote_end_date;
  constructor(private quoteService: QuoteService, private _snackBar: MatSnackBar, private userService: UserInfoService) { }

  quote_type: string = '';
  max_broker_comission: number = 0;
  max_ppa_comission: number = 0;
  max_prt_comission: number = 0;
  max_uw_fee: number = 0;
  ngOnChanges(changes: SimpleChanges) {
    switch (this.quote.type) {
      case 1:
        this.quote_type = 'New';
        break;
      case 2:
        this.quote_type = 'Renew';
        break;
      case 3:
        this.quote_type = 'MTA';
        break;
      case 4:
        this.quote_type = 'Cancel';
        break;
      case 5:
        this.quote_type = 'Lapse';
        break;
      default:
        this.quote_type = '';
        break;
    }

    this.max_broker_comission = this.quote.financial_details.broker_commission;
    this.max_ppa_comission = this.quote.financial_details.peliwica_pay_away_commission;
    this.max_prt_comission = this.quote.financial_details.peliwica_retained_commission;
    this.max_uw_fee = this.quote.financial_details.uw_fee;

    this.getCommissions();



    if (this.quote.financial_details) {
      this.quoteCalculations();
    }

    this.calculateGrossPremium();
  }

  ngOnInit(): void {

  }

  netPremium: Number = 0;
  calculateNetPremium() {
    let full_class_net_rate_amount = Number(this.quote.financial_details.net_rate_amount);
    let full_net_vet_fee_amount = Number(this.quote.financial_details.net_vet_fee_amount);
    let full_net_public_liability_amount = Number(this.quote.financial_details.net_public_liability_amount);
    let full_net_personal_accident_amount = Number(this.quote.financial_details.net_personal_accident_amount);
    let premium_loading = Number(this.quote.financial_details.premium_loading);
    let annual_agg_life_saving = Number(this.quote.financial_details.annual_agg_life_saving_gross_amount);
    let disposal_euthansia_cost = Number(this.quote.financial_details.disposal_euthansia_cost);
    // let elective_surgery_ga_cover = Number(this.quote.financial_details.elective_surgery_ga_cover);
    // let elective_surgery_ga_cover_cost = this.calculate_percentage_amount(this.quote.horse_details.value, elective_surgery_ga_cover);
    // let elective_surgery_ga_min_premium = Number(this.quote.financial_details.elective_surgery_ga_min_premium);
    // if (elective_surgery_ga_cover_cost < elective_surgery_ga_min_premium) {
    //   elective_surgery_ga_cover_cost = elective_surgery_ga_min_premium;
    // }
    let livery_hospitalisation_emegency_transportation = Number(this.quote.financial_details.livery_hospitalisation_emegency_transportation);
    let transportation = Number(this.quote.financial_details.transportation);

    //  Trailers and Horsedrawn Vehicles
    var trailer_net_rate_amount = parseFloat(Number(this.quote.addon_trailer_value * this.quote.financial_details.trailer_net_rate / 100).toFixed(2));
    let trailer_net_premium = this.main_class_net(trailer_net_rate_amount, this.quote.financial_details.trailer_minimum_premium, 0, false, 0,
      this.quote.financial_details.vet_excess_discount, 0, 0);

    // Saddlery and Tack 
    var sat_net_rate_amount = parseFloat(Number(this.quote.addon_saddlery_value * this.quote.financial_details.saddlery_net_rate / 100).toFixed(2));
    let sat_net_premium = this.main_class_net(sat_net_rate_amount, this.quote.financial_details.saddlery_minimum_premium, 0, false, 0
      , this.quote.financial_details.vet_excess_discount, 0, 0);

    //LSS
    // let life_saving_surgery = this.gross_amount(this.life_saving_surgery, this.total_commission_main, this.reduced_broker_commission);
    let life_saving_surgery = 0;

    // if (this.quote.type <= 2) {
    //   this.netPremium = parseFloat(Number((full_class_net_rate_amount) + (full_net_vet_fee_amount) + (full_net_public_liability_amount) + (full_net_personal_accident_amount)
    //     + (trailer_net_premium) + (sat_net_premium) + Number(premium_loading) + Number(annual_agg_life_saving)
    //     + Number(life_saving_surgery) + Number(disposal_euthansia_cost) + Number(elective_surgery_ga_cover_cost)
    //     + Number(livery_hospitalisation_emegency_transportation) + Number(transportation)
    //   ).toFixed(2));
    // } else {

    //   let pay_away = (Number(this.quote.financial_details.policy_premium) * Number(this.quote.financial_details.peliwica_pay_away_commission)) / 100;
    //   let retained_comm = (Number(this.quote.financial_details.policy_premium) * Number(this.quote.financial_details.peliwica_retained_commission)) / 100;

    //   this.netPremium = Number(this.quote.financial_details.policy_premium) - (Number(this.quote.financial_details.broker_amount) + pay_away + retained_comm);
    // }
    
    let total_commission = this.total_commission;
    let reduced_comm = this.actual_broker_commission - Number(this.quote.financial_details.broker_commission);
   
    this.netPremium = (Number(this.quote.financial_details.policy_premium) * (100 - Number(total_commission))) / (100 - Number(reduced_comm) );

    let fullNetPremiumAmount = this.netPremium;

    if(this.quote.product_id == environment.LIVESTOCK_ID) {
      this.quote.is_aeio = 0;
    }

   // if (this.quote.is_aeio != 1) {
      this.broker_gross_amount = parseFloat(Number(parseFloat(Number(fullNetPremiumAmount).toFixed(2)) / (100 - total_commission) * this.quote.financial_details.broker_commission).toFixed(2));
      this.pay_away_gross_amount = parseFloat(Number(parseFloat(Number(fullNetPremiumAmount).toFixed(2)) / (100 - total_commission) * this.quote.financial_details.peliwica_pay_away_commission).toFixed(2));
      this.peliwica_retained_gross_amount = parseFloat(Number(parseFloat(Number(fullNetPremiumAmount).toFixed(2)) / (100 - total_commission) * this.quote.financial_details.peliwica_retained_commission).toFixed(2));
    // } else {
    //   let aeio_class_gross_amount = Number(this.quote.financial_details.net_rate_amount) / (100 - Number(total_commission)) * 100;
    //   aeio_class_gross_amount = parseFloat(Number(aeio_class_gross_amount).toFixed(2));
    //   let aeio_public_gross_amount = Number(this.quote.financial_details.net_public_liability_amount) / (100 - Number(total_commission)) * 100;
    //   aeio_public_gross_amount = parseFloat(Number(aeio_public_gross_amount).toFixed(2));
    //   let aeio_personal_gross_amount = Number(this.quote.financial_details.net_personal_accident_amount) / (100 - Number(total_commission)) * 100;
    //   aeio_personal_gross_amount = parseFloat(Number(aeio_personal_gross_amount).toFixed(2));

    //   let aeio_total_gross_primum = parseFloat(Number(Number(aeio_class_gross_amount) + Number(this.quote.financial_details.full_vet_gross_amount) + Number(aeio_public_gross_amount) + Number(aeio_personal_gross_amount)
    //     + Number(trailer_net_premium) + Number(sat_net_premium) + Number(this.quote.financial_details.premium_loading) + Number(this.quote.financial_details.tigerlab_premium_loading)
    //     + Number(this.quote.financial_details.claim_discount_amount)
    //     + Number(this.quote.financial_details.customer_discount_amount)
    //   ).toFixed(2));

    //   this.broker_gross_amount = parseFloat(Number(parseFloat(Number(aeio_total_gross_primum).toFixed(2)) / (100 - total_commission) * this.quote.financial_details.broker_commission).toFixed(2));
    //   this.pay_away_gross_amount = parseFloat(Number(parseFloat(Number(aeio_total_gross_primum).toFixed(2)) / (100 - total_commission) * this.quote.financial_details.peliwica_pay_away_commission).toFixed(2));
    //   this.peliwica_retained_gross_amount = parseFloat(Number(parseFloat(Number(aeio_total_gross_primum).toFixed(2)) / (100 - total_commission) * this.quote.financial_details.peliwica_retained_commission).toFixed(2));
    // }

  }

  main_class_net(basic_value, minimum_premium, age_loading, isVet, main_discount, vet_fee_excess_discount, full_co_pay_discount, veterinary_fee_co_pay): number {
    if (basic_value <= minimum_premium) {
      basic_value = Number(minimum_premium);
    }

    if (age_loading > 0) {
      basic_value = Number(basic_value) + Number(basic_value * age_loading / 100);
    }

    // basic_value = Number(basic_value) + Number(this.life_saving_surgery);
    if (isVet) {

      basic_value = Number(basic_value) - (Number(basic_value) * Number(vet_fee_excess_discount) / 100);

      basic_value = Number(basic_value) - (Number(basic_value) * Number(full_co_pay_discount) / 100);
      basic_value = Number(basic_value) - (Number(basic_value) * Number(veterinary_fee_co_pay) / 100);
    }

    if (main_discount > 0) {
      basic_value = Number(basic_value) - (Number(basic_value) * Number(main_discount / 100));

    }

    return parseFloat(Number(basic_value).toFixed(2));
  }

  private gross_amount(basic_net_value: number, total_commission: number, reduced_broker_commission: number): number {

    basic_net_value = Number(basic_net_value) / (100 - Number(total_commission)) * (100 - Number(reduced_broker_commission));
    return parseFloat(Number(basic_net_value).toFixed(2));
  }


  actual_broker_commission: number = 0;
  actual_pay_away_commission: number = 0;
  actual_retained_commission: number = 0;
  broker_gross_amount: number = 0;
  pay_away_gross_amount: number = 0;
  peliwica_retained_gross_amount: number = 0;
  total_commission: number = 0;
  getCommissions() {
    if (this.quote.company_id) {
      this.quoteService.getLiveStockBrokerCommission(this.quote.company_id, this.quote.product_id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              let brokers_data = response.result;
              if (brokers_data != null) {
                this.actual_broker_commission = brokers_data.broker_commission;
                this.actual_pay_away_commission = brokers_data.pay_away_commission;
                this.actual_retained_commission = brokers_data.retained_commission;

                let total_commission = Number(this.actual_broker_commission) + Number(this.actual_pay_away_commission) + Number(this.actual_retained_commission);
                this.total_commission = total_commission;
                let trailer_net_rate_amount = parseFloat(Number(this.quote.addon_trailer_value * this.quote.financial_details.trailer_net_rate / 100).toFixed(2));
                let trailer_net_premium = trailer_net_rate_amount;
                if (trailer_net_rate_amount <= this.quote.financial_details.trailer_minimum_premium) {
                  trailer_net_premium = Number(this.quote.financial_details.trailer_minimum_premium);
                }

                let sat_net_rate_amount = parseFloat(Number(this.quote.addon_saddlery_value * this.quote.financial_details.saddlery_net_rate / 100).toFixed(2));
                let sat_net_premium = sat_net_rate_amount;
                if (sat_net_rate_amount <= this.quote.financial_details.saddlery_minimum_premium) {
                  sat_net_premium = Number(this.quote.financial_details.saddlery_minimum_premium);
                }

                var net_policy_amount = parseFloat((Number(this.quote.financial_details.net_rate_amount) + Number(this.quote.financial_details.net_vet_fee_amount)
                  + Number(trailer_net_premium) + Number(sat_net_premium)).toFixed(2));;
                let premium_loading = this.calculate_percentage_amount(net_policy_amount, this.quote.financial_details.premium_loading_percentage);

                let annual_agg_life_saving = 0;
                if (this.quote.product_id == environment.AJG_ID) {
                  annual_agg_life_saving = Number(this.quote.financial_details.annual_agg_life_saving_gross_amount) / (100 - Number(total_commission)) * (100 - Number(this.quote.financial_details.broker_commission));
                }

                let life_saving_surgery = 0; // Do it later


                this.calculateNetPremium();

              }
            }
          });
    }

  }

  private calculate_percentage_amount(value: number, percentage: number): number {

    value = (Number(value) * Number(percentage)) / 100;
    return parseFloat(Number(value).toFixed(2));
  }

  /*
 * Calculate Gross Premium
 */
  GrossPremium: number = 0.0;
  calculateGrossPremium() {
    let total_commissions = Number(this.quote.financial_details.broker_commission) + Number(this.quote.financial_details.peliwica_pay_away_commission) + Number(this.quote.financial_details.peliwica_retained_commission);
    this.GrossPremium = Number(this.quote.financial_details.policy_premium) * (100 - total_commissions) / 100;
  }

  /*
 * Commission Slider Number Formatter
 */
  formatSliderLabel(value: number) {
    return value + '%';
  }
  formatUWSliderLabel(value: number) {
    return '£' + value;
  }

  /*
   * On Broker Comission Slider Change
   */
  onBrokerComissionChange(event) {
    this.quote.financial_details.broker_commission = event.value;
    this.calculations.broker_commission = event.value;
    this.quoteCalculations();
  }
  /*
   * On Peliwica Pay Away Comission Slider Change
   */
  onPPayAwayComissionChange(event) {
    this.quote.financial_details.peliwica_pay_away_commission = event.value;
    this.calculations.peliwica_pay_away_commission = event.value;
    this.quoteCalculations();
  }
  /*
   * On Peliwica Retained Comission Slider Change
   */
  onPRetainedComissionChange(event) {
    this.quote.financial_details.peliwica_retained_commission = event.value;
    this.calculations.peliwica_retained_commission = event.value;
    this.quoteCalculations();
  }
  /*
   * On UW Fee Slider Change
   */
  onUWFeeChange(event) {
    this.quote.financial_details.uw_fee = event.value;
    this.quoteCalculations();
  }
  /*
   * On Premium Loading Slider Change
   */
  onPreLoadingChange(event) {
    this.quote.financial_details.premium_loading_percentage = event.value;
    this.quoteCalculations();
  }

  /*
  * Calculations
  */
  quoteCalculations() {
    this.assignCalculationValues();

    this.calculations.full_calculate_data(this.quote);

  }

  assignCalculationValues() {
    if (this.quote.product_id == '7dd8c0bb-b933-4f28-bc70-b5cc47fafb95') {
      if (this.quote.livestock_details.length > 0) {
        this.calculations.sum_insured = parseFloat(Number(this.quote.livestock_details[0].value).toFixed(2));
      }

    }
    else {
      this.calculations.sum_insured = parseFloat(Number(this.quote.horse_details.value).toFixed(2));
    }

    // this.calculations.full_co_pay_discount = parseFloat(Number(this.co_pay_discount_details.discount).toFixed(2));
    // if (this.quote.vet_fee_sum_insured_id) {
    //   let vetFeesCoverData = this.vetFeesCover.filter(x => x.id == this.quote.vet_fee_sum_insured_id);
    //   if (vetFeesCoverData.length > 0) {
    //     this.calculations.full_vet_fee_amount = Number(vetFeesCoverData[0].vet_indemnity_data.value);
    //   }
    // }


    if (this.quote.is_aeio == 0) {
      this.calculations.full_class_net_rate = parseFloat(Number(this.quote.financial_details.class_net_rate).toFixed(2));
      this.calculations.full_main_discount = parseFloat(Number(this.quote.financial_details.main_discount).toFixed(2));
      this.calculations.full_vet_fee_excess_discount = parseFloat(Number(this.quote.financial_details.vet_excess_discount).toFixed(2));
      this.calculations.full_public_liability_amount = parseFloat(Number(this.quote.financial_details.public_liability_amount).toFixed(2));
      this.calculations.full_personal_accident_amount = parseFloat(Number(this.quote.financial_details.personal_accident_amount).toFixed(2));
      this.calculations.peliwica_pay_away_commission = parseFloat(Number(this.quote.financial_details.peliwica_pay_away_commission).toFixed(2));
      this.calculations.broker_commission = parseFloat(Number(this.quote.financial_details.broker_commission).toFixed(2));
      this.calculations.peliwica_retained_commission = parseFloat(Number(this.quote.financial_details.peliwica_retained_commission).toFixed(2));
      this.calculations.full_class_net_rate_amount = parseFloat(Number(this.quote.financial_details.net_rate_amount).toFixed(2));
      this.calculations.class_minimum_premium = parseFloat(Number(this.quote.financial_details.class_minimum_premium).toFixed(2));
      this.calculations.class_age_loading_amount = parseFloat(Number(this.quote.financial_details.class_section_loading).toFixed(2));
      this.calculations.sat_net_rate = parseFloat(Number(this.quote.financial_details.saddlery_net_rate).toFixed(2));
      this.calculations.sat_minimum_premium = parseFloat(Number(this.quote.financial_details.saddlery_minimum_premium).toFixed(2));
      this.calculations.trailer_net_rate = parseFloat(Number(this.quote.financial_details.trailer_net_rate).toFixed(2));
      this.calculations.trailer_minimum_premium = parseFloat(Number(this.quote.financial_details.trailer_minimum_premium).toFixed(2));
      this.calculations.full_ipt_gross_amount = parseFloat(Number(this.quote.financial_details.policy_premium * this.quote.financial_details.ipt_fee / 100).toFixed(2));
      this.calculations.full_total_gross_primum = parseFloat(Number(this.quote.financial_details.policy_premium).toFixed(2));
      this.calculations.full_total_premium = parseFloat(Number(this.quote.financial_details.total_premium).toFixed(2));
      this.calculations.full_total_amount_payable = parseFloat(Number(this.quote.financial_details.total_policy_cost).toFixed(2));
      this.calculations.full_underwriting_fee = parseFloat(Number(this.quote.financial_details.fees).toFixed(2));
      this.calculations.full_underwriting_fee = parseFloat(Number(this.quote.financial_details.uw_fee).toFixed(2));
      this.calculations.full_broker_amount = parseFloat(Number(this.quote.financial_details.broker_amount).toFixed(2));
      this.calculations.full_vet_gross_amount = parseFloat(Number(this.quote.financial_details.full_vet_gross_amount).toFixed(2));
      this.calculations.full_net_vet_fee_amount = parseFloat(Number(this.quote.financial_details.net_vet_fee_amount).toFixed(2));
      this.calculations.full_net_Premium_amount = parseFloat(Number(this.quote.financial_details.total_net_amount).toFixed(2));
    } else {
      this.calculations.aeio_class_net_rate = parseFloat(Number(this.quote.financial_details.class_net_rate).toFixed(2));
      this.calculations.aeio_main_discount = parseFloat(Number(this.quote.financial_details.main_discount).toFixed(2));
      this.calculations.aeio_vet_fee_excess_discount = parseFloat(Number(this.quote.financial_details.vet_excess_discount).toFixed(2));
      this.calculations.aeio_public_liability_amount = parseFloat(Number(this.quote.financial_details.public_liability_amount).toFixed(2));
      this.calculations.aeio_personal_accident_amount = parseFloat(Number(this.quote.financial_details.personal_accident_amount).toFixed(2));
      this.calculations.peliwica_pay_away_commission = parseFloat(Number(this.quote.financial_details.peliwica_pay_away_commission).toFixed(2));
      this.calculations.broker_commission = parseFloat(Number(this.quote.financial_details.broker_commission).toFixed(2));
      this.calculations.peliwica_retained_commission = parseFloat(Number(this.quote.financial_details.peliwica_retained_commission).toFixed(2));
      this.calculations.aeio_class_net_rate_amount = parseFloat(Number(this.quote.financial_details.net_rate_amount).toFixed(2));
      this.calculations.class_minimum_premium = parseFloat(Number(this.quote.financial_details.class_minimum_premium).toFixed(2));
      this.calculations.class_age_loading_amount = parseFloat(Number(this.quote.financial_details.class_section_loading).toFixed(2));
      this.calculations.sat_net_rate = parseFloat(Number(this.quote.financial_details.saddlery_net_rate).toFixed(2));
      this.calculations.sat_minimum_premium = parseFloat(Number(this.quote.financial_details.saddlery_minimum_premium).toFixed(2));
      this.calculations.trailer_net_rate = parseFloat(Number(this.quote.financial_details.trailer_net_rate).toFixed(2));
      this.calculations.trailer_minimum_premium = parseFloat(Number(this.quote.financial_details.trailer_minimum_premium).toFixed(2));
      this.calculations.aeio_ipt_gross_amount = parseFloat(Number(this.quote.financial_details.policy_premium * this.quote.financial_details.ipt_fee / 100).toFixed(2));;;
      this.calculations.aeio_total_gross_primum = parseFloat(Number(this.quote.financial_details.policy_premium).toFixed(2));
      this.calculations.aeio_total_premium = parseFloat(Number(this.quote.financial_details.total_premium).toFixed(2));
      this.calculations.aeio_total_amount_payable = parseFloat(Number(this.quote.financial_details.total_policy_cost).toFixed(2));
      this.calculations.aeio_underwriting_fee = parseFloat(Number(this.quote.financial_details.fees).toFixed(2));
      this.calculations.aeio_underwriting_fee = parseFloat(Number(this.quote.financial_details.uw_fee).toFixed(2));
      this.calculations.aeio_broker_amount = parseFloat(Number(this.quote.financial_details.broker_amount).toFixed(2));
      this.calculations.aeio_vet_gross_amount = parseFloat(Number(this.quote.financial_details.full_vet_gross_amount).toFixed(2));
      this.calculations.aeio_net_vet_fee_amount = parseFloat(Number(this.quote.financial_details.net_vet_fee_amount).toFixed(2));
      this.calculations.aeio_net_Premium_amount = parseFloat(Number(this.quote.financial_details.total_net_amount).toFixed(2));
    }
  }


  /*
  * Save Financial Details
  */
  onSubmit(f: NgForm) {
    if (this.quote.is_aeio) {
      this.quote.financial_details.class_net_rate = this.calculations.full_class_net_rate;
      this.quote.financial_details.main_discount = this.calculations.full_main_discount;
      this.quote.financial_details.vet_excess_discount = this.calculations.full_vet_fee_excess_discount;
      this.quote.financial_details.public_liability_amount = this.calculations.full_public_liability_amount;
      this.quote.financial_details.personal_accident_amount = this.calculations.full_personal_accident_amount;
      this.quote.financial_details.peliwica_pay_away_commission = this.calculations.peliwica_pay_away_commission;
      this.quote.financial_details.broker_commission = this.calculations.broker_commission;
      this.quote.financial_details.peliwica_retained_commission = this.calculations.peliwica_retained_commission;
      this.quote.financial_details.net_rate_amount = this.calculations.full_class_net_rate_amount;
      this.quote.financial_details.class_minimum_premium = this.calculations.class_minimum_premium;
      this.quote.financial_details.class_section_loading = this.calculations.class_age_loading_amount;
      this.quote.financial_details.saddlery_net_rate = this.calculations.sat_net_rate;
      this.quote.financial_details.saddlery_minimum_premium = this.calculations.sat_minimum_premium;
      this.quote.financial_details.trailer_net_rate = this.calculations.trailer_net_rate;
      this.quote.financial_details.trailer_minimum_premium = this.calculations.trailer_minimum_premium;
      this.quote.financial_details.ipt_fee = this.calculations.ipt_fee;
      this.quote.financial_details.policy_premium = this.calculations.full_total_gross_primum;
      this.quote.financial_details.total_premium = this.calculations.full_total_premium;
      this.quote.financial_details.total_policy_cost = this.calculations.full_total_amount_payable;
      this.quote.financial_details.fees = this.calculations.full_underwriting_fee;
      this.quote.financial_details.uw_fee = this.calculations.full_underwriting_fee;
      this.quote.financial_details.broker_amount = this.calculations.full_broker_amount;
      this.quote.financial_details.full_vet_gross_amount = this.calculations.full_vet_gross_amount;
      this.quote.financial_details.net_vet_fee_amount = this.calculations.full_net_vet_fee_amount;
      this.quote.financial_details.total_net_amount = this.calculations.full_net_Premium_amount;

    } else {
      this.quote.is_aeio = 1;
      this.quote.financial_details.is_aeio = 1;
      this.quote.financial_details.class_net_rate = this.calculations.aeio_class_net_rate;
      this.quote.financial_details.main_discount = this.calculations.aeio_main_discount;
      this.quote.financial_details.vet_excess_discount = this.calculations.aeio_vet_fee_excess_discount;
      this.quote.financial_details.public_liability_amount = this.calculations.aeio_public_liability_amount;
      this.quote.financial_details.personal_accident_amount = this.calculations.aeio_personal_accident_amount;
      this.quote.financial_details.peliwica_pay_away_commission = this.calculations.peliwica_pay_away_commission;
      this.quote.financial_details.broker_commission = this.calculations.broker_commission;
      this.quote.financial_details.peliwica_retained_commission = this.calculations.peliwica_retained_commission;
      this.quote.financial_details.net_rate_amount = this.calculations.aeio_class_net_rate_amount;
      this.quote.financial_details.class_minimum_premium = this.calculations.class_minimum_premium;
      this.quote.financial_details.class_section_loading = this.calculations.class_age_loading_amount;
      this.quote.financial_details.saddlery_net_rate = this.calculations.sat_net_rate;
      this.quote.financial_details.saddlery_minimum_premium = this.calculations.sat_minimum_premium;
      this.quote.financial_details.trailer_net_rate = this.calculations.trailer_net_rate;
      this.quote.financial_details.trailer_minimum_premium = this.calculations.trailer_minimum_premium;
      this.quote.financial_details.ipt_fee = this.calculations.ipt_fee;
      this.quote.financial_details.policy_premium = this.calculations.aeio_total_gross_primum;
      this.quote.financial_details.total_premium = this.calculations.aeio_total_premium;
      this.quote.financial_details.total_policy_cost = this.calculations.aeio_total_amount_payable;
      this.quote.financial_details.fees = this.calculations.aeio_underwriting_fee;
      this.quote.financial_details.uw_fee = this.calculations.aeio_underwriting_fee;
      this.quote.financial_details.broker_amount = this.calculations.aeio_broker_amount;
      this.quote.financial_details.full_vet_gross_amount = this.calculations.aeio_vet_gross_amount;
      this.quote.financial_details.net_vet_fee_amount = this.calculations.aeio_net_vet_fee_amount;
      this.quote.financial_details.total_net_amount = this.calculations.aeio_net_Premium_amount;
    }
    let data = {
      quote_data: this.quote
    };
    this.quoteService.saveIndicativeQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quote = response.result.quote_data;
            this._snackBar.open('Financial details saved', '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

}
