import { PolicyHolderAddress } from './policy_holder_address.model';
import { StableAddress } from './stable_address.model';
import { HorseDetailsSaddlery } from './horse_details_saddlery.model';
import { HorseDetailsTrailer } from './horse_details_trailer.model';
export class HorseDetails {
    id: string = '';
    quote_id: string = '';
    policy_id: string = '';
    name: string = '';
    age: number;
    value: number;
    gender: number = 0;
    breed_id: string = '';
    color_id: string = '';
    height: number = 0;
    passport: string = '';
    microchip: string = '';
    horse_purchase_date: string = '';
    is_stable_address_different:number = null;
    address:Object = new StableAddress();
    saddlery_items: Array<object> = [new HorseDetailsSaddlery()];
    trailor_items: Array<object> = [new HorseDetailsTrailer()];
}
