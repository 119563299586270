<form #f="ngForm" class="form_details">
    <!-- Policy Holder Details-->
    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Policy Holder Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row form-group" *ngIf="quote.policy_holder.title">
                <div class="col-md-4"><label>Title</label></div>
                <div class="col-md-6">
                    <input type="text" name="title" placeholder="Title" class="form-control form-control-sm"
                        value="{{titleName}}" readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>First name</label></div>
                <div class="col-md-6">
                    <input type="text" name="first_name" placeholder="Firstname" class="form-control form-control-sm"
                        [(ngModel)]="quote.policy_holder.first_name" appTitleCase readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Last name</label></div>
                <div class="col-md-6">
                    <input type="text" name="last_name" placeholder="Lastname" class="form-control form-control-sm"
                        [(ngModel)]="quote.policy_holder.last_name" appTitleCase readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>DOB</label></div>
                <div class="col-md-6">
                    <input required type="text" name="dob" placeholder="DOB" class="form-control form-control-sm"
                        [value]="quote.policy_holder.dob | date: 'dd-MM-yyyy'" readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Contact Number</label></div>
                <div class="col-md-6">
                    <input required type="text" name="phone" placeholder="Contact Number"
                        class="form-control form-control-sm" [(ngModel)]="quote.policy_holder.phone"
                        readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Email</label></div>
                <div class="col-md-6">
                    <input type="text" name="email" [(ngModel)]="quote.policy_holder.email"
                        class="form-control form-control-sm" placeholder="Email" readonly>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Policy Level Details -->
    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Policy Level Details 
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row form-group">
                <div class="col-md-4"><label>Cover Type</label></div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.horse_class_id"
                            class="form-control form-control-sm" name="cver_type">
                            <mat-option *ngFor="let uses of classOfUse" [value]="uses.id">
                                {{uses.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row form-group" *ngIf="!BloodStockDetails">
                <div class="col-md-4"><label>Primary Cover</label></div>
                <div class="col-md-6">
                    <input type="text" name="hordeSubCass" [value]="quote.horse_sub_class.name"
                    readonly class="form-control form-control-sm">
                    <!-- <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.horse_class_sub_id"
                            class="form-control form-control-sm" name="horse_class_sub_id" disabled>
                            <mat-option *ngFor="let subClass of horseSubClasses" [value]="subClass.id">
                                {{subClass.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->
                </div>
            </div>
            <div class="row form-group" *ngIf="BloodStockDetails">
                <div class="col-md-4"><label>Primary Cover</label></div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.blood_stock_id"
                            class="form-control form-control-sm" name="blood_stock_id" disabled>
                            <mat-option [value]="">None</mat-option>
                            <mat-option *ngFor="let bloodStock of bloodStocks" [value]="bloodStock.id">
                                {{bloodStock.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Policy Cover Details</label></div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.policy_cover_id"
                            class="form-control form-control-sm" name="policy_cover" disabled>
                            <mat-option [value]="">None</mat-option>
                            <mat-option *ngFor="let policy of policyCover" [value]="policy.id">
                                {{policy.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Does the policy holder own the horse</label></div>
                <div class="col-md-6">
                    <label class="switch switch-left-right">
                        <input class="switch-input" type="checkbox" disabled name="does_policy_owner_owns_horse"
                            [checked]="quote.does_policy_owner_owns_horse == 1" />
                        <span class="switch-label" data-on="Yes" data-off="No"></span>
                        <span class="switch-handle"></span>
                    </label>
                </div>
            </div>
            <div class="row form-group" *ngIf="quote.horse_details">
                <div class="col-md-4"><label>Is the stabling address different to the correspondance address?</label>
                </div>
                <div class="col-md-6">
                    <label class="switch switch-left-right">
                        <input class="switch-input" type="checkbox" disabled name="does_policy_owner_owns_horse"
                            [checked]="quote.horse_details.is_stable_address_different == 1" />
                        <span class="switch-label" data-on="Yes" data-off="No"></span>
                        <span class="switch-handle"></span>
                    </label>
                </div>
            </div>
            <!-- <div class="row" *ngIf="quote.horse_details.is_stable_address_different == 1">
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" name="horse_address_line_1" placeholder="Line 1"
                            [(ngModel)]="quote.horse_details.address.address1"
                            class="form-control form-control-sm" appTitleCase readonly>
                    </div>
                    <div class="form-group">
                        <input type="text" name="horse_address_line_2" placeholder="Line 2"
                            [(ngModel)]="quote.horse_details.address.address2"
                            class="form-control form-control-sm" appTitleCase readonly>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" name="horse_city" placeholder="Town"
                            [(ngModel)]="quote.horse_details.address.city"
                            class="form-control form-control-sm" appTitleCase readonly>
                    </div>
                    <div class="form-group">
                        <input type="text" name="horse_county" placeholder="County"
                            [(ngModel)]="quote.horse_details.address.country"
                            class="form-control form-control-sm" appTitleCase readonly>
                    </div>
                    <div class="form-group">
                        <input type="text" name="horse_postcode" placeholder="Postcode"
                            [(ngModel)]="quote.horse_details.address.postcode"
                            class="form-control form-control-sm" appUpperCase readonly>
                    </div>
                </div>
            </div> -->
            <div class="row form-group" *ngIf="quote.horse_details">
                <div class="col-md-4"><label>Horse Purchase/Loan Date</label></div>
                <div class="col-md-6">
                    <div class="input-group">
                        <input type="text" name="purchase_date" placeholder="Horse Purchase/Loan Date"
                            class="form-control form-control-sm" readonly
                            [value]="quote.horse_details.horse_purchase_date | date:'dd-MM-yyyy'">
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Horse Details -->
    <div class="form_sections" *ngIf="quote.horse_details">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Horse Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row form-group">
                <div class="col-md-4"><label>Name</label></div>
                <div class="col-md-6">
                    <input type="text" name="horse_name" placeholder="Horse Name" class="form-control form-control-sm"
                        readonly [(ngModel)]="quote.horse_details.name" appTitleCase>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Value</label></div>
                <div class="col-md-6">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-success text-white">&pound;</span>
                        </div>
                        <input type="text" name="horse_value" placeholder="Horse Value"
                            class="form-control form-control-sm" readonly
                            [(ngModel)]="quote.horse_details.value" appDecimalNumbers>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Age</label></div>
                <div class="col-md-6">
                    <input type="text" name="horse_age" placeholder="Horse Age" class="form-control form-control-sm"
                        readonly [(ngModel)]="quote.horse_details.age">
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Gender</label></div>
                <div class="col-md-6">
                    <input type="text" name="horse_gender" placeholder="Horse Gender"
                        class="form-control form-control-sm" readonly
                        [value]="quote.horse_details.gender == 1?'Male':'Female'">
                </div>
            </div>
            <div class="row">
                <div class="col-md-4"><label>Height</label></div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.horse_details.height"
                            class="form-control form-control-sm" name="horse_height" disabled>
                            <mat-option *ngFor="let hh of horseHeight" [value]="hh.height">
                                {{hh.height}} HH
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4"><label>Breed</label></div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.horse_details.breed_id"
                            class="form-control form-control-sm" name="horse_breed" disabled>
                            <mat-option *ngFor="let hb of horseBreed" [value]="hb.id">
                                {{hb.breed}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-4"><label>Colour</label></div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.horse_details.color_id"
                            class="form-control form-control-sm" name="color_id" disabled>
                            <mat-option *ngFor="let color of horseColor" [value]="color.id">
                                {{color.color}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4"><label>Passport Number</label></div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" name="passport" placeholder="Horse Passport Number"
                            class="form-control form-control-sm" readonly
                            [(ngModel)]="quote.horse_details.passport">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4"><label>Microchip Number</label></div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" name="microchip" placeholder="Horse Microchip Number"
                            class="form-control form-control-sm" readonly
                            [(ngModel)]="quote.horse_details.microchip">
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Vet Fee Details -->
    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Vet Fee Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row form-group" *ngIf="quote.full_vet_fee_cover">
                <div class="col-md-4"><label>Vets Fees Indemnity Limit</label></div>
                <div class="col-md-6">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-success text-white">&pound;</span>
                        </div>
                        <input type="text" name="vet_fees_cover" placeholder="Vets fees cover"
                            [value]="(quote.full_vet_fee_cover)?quote.full_vet_fee_cover.replace('£',''):''"
                            class="form-control form-control-sm" readonly>
                    </div>
                </div>
            </div>
            <div class="row form-group" *ngIf="quote.full_life_saving_surgery">
                <div class="col-md-4"><label>Life Saving Surgery/Colic Surger</label></div>
                <div class="col-md-6">
                    <input type="text" name="full_life_saving_surgery" placeholder="Life Saving Surgery/Colic Surger"
                        [value]="quote.full_life_saving_surgery" class="form-control form-control-sm" readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Vets Fees Excess</label></div>
                <div class="col-md-6">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-success text-white">&pound;</span>
                        </div>
                        <input type="text" name="vet_fees_excess" [value]="quote.full_vet_fee_excess"
                            placeholder="Vets fees excess" class="form-control form-control-sm" readonly>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>20% Co-Insurance</label></div>
                <div class="col-md-6">
                    <label class="switch switch-left-right">
                        <input class="switch-input" type="checkbox" disabled name="company_co_pay_excess_id"
                            [checked]="quote.company_co_pay_excess_id" />
                        <span class="switch-label" data-on="Yes" data-off="No"></span>
                        <span class="switch-handle"></span>
                    </label>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Liability & Accident -->
    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Liability & Accident
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row form-group">
                <div class="col-md-4"><label>Public Liability</label></div>
                <div class="col-md-6">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-success text-white">&pound;</span>
                        </div>
                        <input type="text" name="public_liability_cover" placeholder="Public liability"
                            [value]="(quote.full_public_liability)?quote.full_public_liability.replace('£',''):''"
                            class="form-control form-control-sm" readonly>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Personal Accident</label></div>
                <div class="col-md-6 form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-success text-white">&pound;</span>
                        </div>
                        <input type="text" name="personal_accident_cover" placeholder="Personal Accident"
                            [value]="(quote.full_personal_accident)?quote.full_personal_accident.replace('£',''):''"
                            class="form-control form-control-sm" readonly>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Extra items -->
    <div class="form_sections"
        *ngIf="quote.horse_details && (quote.does_addon_saddlery_on == 1 || quote.does_addon_trailer_on == 1)">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Extra Items
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="quote.does_addon_saddlery_on == 1">
                <h5 class="text-white">Saddlery & Tack Details</h5>
                <p class="text-white"><small>Unspecified Item Sum Insured:
                        &pound;{{quote.addon_saddlery_value - saddleryTotalSumInsured}}</small></p>
                <div
                    *ngFor="let saddlery_tack_item of quote.horse_details.saddlery_items; let i = index;">
                    <h6 class="text-white">Item {{i + 1}}</h6>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="saddlery_items_brand_{{i}}" placeholder="Make & Model"
                                    class="form-control form-control-sm" [(ngModel)]="saddlery_tack_item.brand"
                                    readonly>
                            </div>
                            <div class="form-group">
                                <input type="text" name="saddlery_sum_insured_{{i}}" placeholder="Sum Insured"
                                    class="form-control form-control-sm" appDecimalNumbers
                                    [(ngModel)]="saddlery_tack_item.sum_insured" readonly>
                            </div>
                            <div class="form-group">
                                <input type="text" name="saddlery_color_{{i}}" placeholder="Color"
                                    class="form-control form-control-sm" [(ngModel)]="saddlery_tack_item.color"
                                    readonly>
                            </div>
                            <div class="form-group">
                                <input type="text" name="saddlery_purchase_date_{{i}}" placeholder="Purchase Date"
                                    class="form-control form-control-sm" [value]="saddlery_tack_item.purchase_date | date:'dd-MM-yyyy'"
                                    readonly>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <textarea name="saddlery_description_{{i}}" placeholder="Description"
                                    class="form-control" rows="7" [(ngModel)]="saddlery_tack_item.description"
                                    readonly></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Horse Trailer Details-->
            <div *ngIf="quote.does_addon_trailer_on == 1 and quote.horse_details">
                <h5 class="text-white mt-4">Horse Trailer Details</h5>
                <p class="text-white"><small>Unspecified Item Sum Insured:
                        &pound;{{quote.addon_trailer_value - trailerTotalSumInsured}}</small></p>
                <div *ngFor="let trailor of quote.horse_details.trailor_items; let i = index;">
                    <h6 class="text-white">Item {{i + 1}}</h6>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="chasis_number" placeholder="Chasis Number"
                                    class="form-control form-control-sm" readonly [(ngModel)]="trailor.chasis_number">
                            </div>
                            <div class="form-group">
                                <input type="text" name="make_model" placeholder="Make & Model"
                                    class="form-control form-control-sm" readonly [(ngModel)]="trailor.brand">
                            </div>
                            <div class="form-group">
                                <input type="text" name="year_of_manufacter" placeholder="Year of Manufacter"
                                    class="form-control form-control-sm" readonly
                                    [(ngModel)]="trailor.manufactured_year">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="trailer_purchase_date" placeholder="Purchase Date"
                                    class="form-control form-control-sm" readonly
                                    [value]="trailor.purchase_date | date:'dd-MM-yyyy'">
                            </div>
                            <div class="form-group">
                                <input type="text" name="sum_insured" placeholder="Trailer Sum Insured"
                                    class="form-control form-control-sm" appDecimalNumbers readonly
                                    [(ngModel)]="trailor.sum_insured">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Endorsement -->
    <div class="form_sections">
        <p>Endorsement</p>
        <ag-grid-angular style="width: 100%;float: left;min-height: 300px;" class="ag-theme-bootstrap mt-3" [gridOptions]="gridOptions"
            [rowData]="rowData" [columnDefs]="columnDefs" animateRows pagination=false paginationPageSize=5
            headerHeight=35 domLayout='autoHeight' [editType]='editType'
            rowHeight=100
            [defaultColDef]="defaultColDef"
            (columnResized)="onColumnResized($event)"
            (rowClicked)='onGridRowClicked($event,content,deleteModel,endorsementViewModel)'>
        </ag-grid-angular>
    </div>
</form>

<ng-template #content let-modal>
    <form #f="ngForm" (ngSubmit)="f.form.valid && onEndorsementSubmit(f)">
        <div class="modal-body">
            <div class="form-group">
                <label>Title</label>
                <input type="text" name="title" class="form-control form-control-sm" [(ngModel)]="endorsement.title"
                    [ngClass]="{ 'is-invalid': f.submitted && title.invalid }" #title="ngModel" required>
                <div *ngIf="f.submitted && title.invalid" class="invalid-feedback">
                    Title is required.
                </div>
            </div>
            <div class="form-group">
                <label>Type</label>
                <select [(ngModel)]="endorsement.type" class="form-control form-control-sm" name="endorsement_type"
                    [ngClass]="{ 'is-invalid': f.submitted && endorsement_type.invalid }" #endorsement_type="ngModel"
                    required style="border: 1px solid #f1f6f8;">
                    <option [value]="">Please select</option>
                    <option [value]="1">Endorsement</option>
                    <option [value]="2">Subjectivity</option>
                    <option [value]="3">Exclusion</option>
                </select>
                <div *ngIf="f.submitted && endorsement_type.invalid" class="invalid-feedback">
                    Type is required.
                </div>
            </div>
            <div class="form-group">
                <label>Endorsement</label>
                <textarea name="endorsement" class="form-control" [(ngModel)]="endorsement.endorsement_text"
                    rows="5"></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" name="submit" class="btn btn-success btn-sm pl-4 pr-4 pt-2 pb-2">Save</button>
            <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
        </div>
    </form>
</ng-template>
<ng-template #deleteModel let-modal>
    <div class="modal-body">
        <p>Are you sure you want to delete endorsement?</p>
    </div>

    <div class="modal-footer">
        <button type="button" name="ok" (click)="deleteEndorsement(endorsement.id,selectedRow)"
            class="btn btn-success btn-sm pl-4 pr-4 pt-2 pb-2">OK</button>
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<ng-template #endorsementViewModel let-modal>
    <div class="modal-body">
        {{ endorsementText }}
    </div>
</ng-template>