<div class="card-header bg-success">
    <div class="row">
        <div class="col-md-6">
            <h3 class="text-white">Policy: {{quoteData.policy_ref_number}}
                <label
                    [ngClass]="(quoteData.policy_status == 1) ? 'badge badge-primary' : 'badge badge-danger'">{{(quoteData.policy_status
                    == 1)?'Active':'Inactive'}}</label>
            </h3>
        </div>
        <div class="col-md-6" *ngIf="!isInsurer">
            <div class="align-items-center justify-content-between float-right" *ngIf="actionDropDown">
                <ul class="navbar-nav navbar-nav-right mr-3" *ngIf="latestVersion == quoteData.sequence_number">
                    <li class="nav-item nav-profile dropdown">
                        <a class="nav-link dropdown-toggle p-0 text-white" href="#" data-toggle="dropdown"
                            id="profileDropdown">
                            Actions
                        </a>
                        <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                            aria-labelledby="profileDropdown">
                            <a (click)="convert_to_mta(content,3)" class="dropdown-item">
                                <i class="mdi mdi-account-convert text-primary"></i>
                                Convert to MTA
                            </a>
                            <a (click)="convert_to_mta(content,4)" class="dropdown-item" *ngIf="!isBroker">
                                <i class="mdi mdi-delete-variant text-primary"></i>
                                Cancel
                            </a>
                            <a (click)="convert_to_mta(content,5)" class="dropdown-item"
                                *ngIf="quoteData.type == 2 && enableLapse && !isBroker">
                                <i class="mdi mdi-delete text-primary"></i>
                                Lapse
                            </a>
                            <a (click)="convert_to_mta(content,6)" class="dropdown-item"
                                *ngIf="(quoteData.type == 1 || quoteData.type == 3) && is_NTU && !isBroker">
                                <i class="mdi mdi-arrange-bring-forward"></i>
                                NTU
                            </a>

                        </div>
                    </li>
                </ul>
                <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                    data-toggle="horizontal-menu-toggle">
                    <span class="mdi mdi-menu"></span>
                </button>
            </div>
        </div>
    </div>
</div>
<mat-tab-group class="float-left w-100">
    <mat-tab>
        <ng-template mat-tab-label>
            Overview
        </ng-template>
        <div class="card-body">
            <app-qv-overview [quoteData]="quoteData" (hasImportantNotes)="onhasImportantNotesChange($event)"
                (quoteDetailsUpdated)="quoteDetailsUpdated($event)"></app-qv-overview>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Full Details
        </ng-template>
        <div class="card-body">
            <div *ngIf="quoteData.product_id == AjgProductId">
                <app-ajg-qv-full-details [quote]="quoteData" [staticData]="staticData"></app-ajg-qv-full-details>
            </div>
            
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Statement of Fact 
        </ng-template>
        <div class="card-body">
            <app-quote-view-sof [quote]="quoteData"></app-quote-view-sof>
        </div>
    </mat-tab>
    <mat-tab *ngIf="!isBroker">
        <ng-template mat-tab-label>
            Financials <span *ngIf="isBrokerCommissionReduced" class="ml-1" style="color: #ff0000" title="Broker Commission Reduced"><i class="mdi mdi-alert-circle"></i></span>
        </ng-template>
        <div class="card-body">
            <app-financials [quote]="quoteData" [staticData]="staticData"></app-financials>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Documents
        </ng-template>
        <div class="card-body">
            <app-quote-documents [quote]="quoteData"></app-quote-documents>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Claims <span *ngIf="hasClaims" class="ml-1" style="color: #ff0000" title="Claims"><i
                    class="mdi mdi-alert-circle"></i></span>
        </ng-template>
        <div class="card-body">
            <app-policy-claims [quote]="quoteData" (hasImportantClaims)="onhasClaimsChange($event)"></app-policy-claims>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Notes <span *ngIf="hasImportantNotes" class="ml-1" style="color: #ff0000"
                title="{{totalImpNotes}} Important Notes"><i class="mdi mdi-alert-circle"></i></span>
        </ng-template>
        <div class="card-body">
            <app-quote-notes [quote]="quoteData" (hasImportantNotes)="onhasImportantNotesChange($event)">
            </app-quote-notes>
        </div>
    </mat-tab>
    <mat-tab *ngIf="!isBroker && (isAdmin || isStaff)">
        <ng-template mat-tab-label>
            Underwriting
        </ng-template>
        <div class="card-body">
            <app-underwriting [quote]="quoteData"></app-underwriting>
        </div>
    </mat-tab>
    <mat-tab *ngIf="!isBroker">
        <ng-template mat-tab-label>
            PEP & Sanctions
        </ng-template>
        <div class="card-body">
            <app-policy-compliance [quote]="quoteData"></app-policy-compliance>
        </div>
    </mat-tab>
    <mat-tab *ngIf="isAdmin">
        <ng-template mat-tab-label>
            Payment
        </ng-template>
        <div class="card-body">
            <app-policy-payment [quote]="quoteData" quoteDetailsUpdated="quoteDetailsUpdated($event)">
            </app-policy-payment>
        </div>
    </mat-tab>    
    <mat-tab *ngIf="isAdmin">
        <ng-template mat-tab-label>
            Email
        </ng-template>
        <div class="card-body">
            <app-quote-email (emailView)="getEmailView($event)" (newEmailView)="newEmailView()" [quote]="quoteData"
                *ngIf="!displayEmailView && !displayNewEmailView"></app-quote-email>
            <app-quote-email-view (backToEmailsList)="backToEmailsList()" [email_id]="email_id"
                *ngIf="displayEmailView"></app-quote-email-view>
            <app-quote-email-compose (backToEmailsList)="backToEmailsList()" [quote]="quoteData"
                *ngIf="displayNewEmailView"></app-quote-email-compose>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Agreed Questions
        </ng-template>
        <div class="card-body">
            <app-allianz-quote-agq [quote]="quoteData"></app-allianz-quote-agq>
        </div>
    </mat-tab>
    
</mat-tab-group>
<ng-template #content let-modal>
    <div class="modal-body">
        <p>{{mtaMsg}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="convert_to_mta(content,quoteType,1)">Yes</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
    </div>
</ng-template>