<ag-grid-angular
    style="width: 100%; height: 300px;"
    class="ag-theme-bootstrap mt-3"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    animateRows
    pagination
    paginationPageSize = 10
    rowHeight = 27
    headerHeight=70
    (rowClicked)='onGridRowClicked($event,opt_in_out_modal)' 
    >

    <ng-template #opt_in_out_modal let-modal>
        <form #f="ngForm" (ngSubmit)="onSubmit(f)">
            <div class="modal-body">
                <p><b>Please write some notes for {{ (bdx_opt_option==1)?'opt in':'opt out' }} the quote.</b></p>
                <div class="form-group">
                    <label>Notes</label>
                    <textarea name="optinoutnotes" [(ngModel)]="opt_in_out_notes" class="form-control"
                        [ngClass]="{ 'is-invalid': f.submitted && optinoutnotes.invalid }"
                        (change)="opt_in_out_notes = opt_in_out_notes.trim()" #optinoutnotes="ngModel"
                        required></textarea>
                    <div *ngIf="f.submitted && optinoutnotes.invalid" class="invalid-feedback">
                        Notes are required.
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-success btn-sm">Save</button>
                <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close()">Close</button>
            </div>
        </form>
    </ng-template>