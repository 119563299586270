import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { QuoteService } from '../quote.service';
import { ActivatedRoute, Router } from "@angular/router";
import { CalculationsDetails } from './calculations/calculations.model';
import { UserInfoService } from '../../services/user-info.service';
import { Quote } from '../../model/quote/quote.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-ajg-quote',
  templateUrl: './ajg-quote.component.html',
  styleUrls: ['../quote.component.css'],
})

export class AjgQuoteComponent implements OnInit {
  selectedIndex: number = 0;
  @Input() quote;
  full_details_tab = false;
  statement_of_facts_tab = false;
  payment_details = false;
  submission = false;
  staticData;
  parentQuote = new Quote();

  quoteDetailsForChildTemplates = new QuoteVersions();
  CalculationDetails = new CalculationsDetails();


  constructor(private quoteService: QuoteService, private route: ActivatedRoute, private sessionSt: LocalStorageService, 
    private userService: UserInfoService) {}


  ngOnInit(): void {  
    this.userService.getUserDetails();
    
    // GET QUOTE DETAILS
    if (this.route.snapshot.params.id) {
      let temp = this.quoteDetailsForChildTemplates;
      let type = 0;
      if(this.route.snapshot.params.quote_type) {
        type = 1;
      }
      this.quoteService.getQuoteDetailsByQuoteID(this.route.snapshot.params.id, type)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.quoteDetailsForChildTemplates = response.result;
              if (this.sessionSt.retrieve('showHideMTAReasons')) {
                this.quoteDetailsForChildTemplates.mta_reason_id = null;
              }
              this.getStaticData(response.result.rating_version);
              // if (response.result.quote_versions.length > 0) { 
              //   let is_policy = 0;
              //   if(this.route.snapshot.params.quote_type) {
              //     is_policy = 1;
              //   }
                // let quote_versions = response.result.quote_versions.filter(x => x.is_policy == is_policy);
                // if(quote_versions.length > 0) {
                //   this.quoteDetailsForChildTemplates.quote_versions = quote_versions;
                // } else {
                //   if(!this.route.snapshot.params.quote_type && this.route.snapshot.params.id) {
                //     this.router.navigate(['/policy', this.route.snapshot.params.id]);
                //   } else {
                //     this.quoteDetailsForChildTemplates.quote_versions = response.result.quote_versions.slice(-1);
                //   }
                  
                // }
                
              // }
              

             this.quoteDetailsForChildTemplates.calculations = this.CalculationDetails;

              
              if(response.result.is_aeio == 0) {
                this.quoteDetailsForChildTemplates.full_quote_section = true;
              } 
              
              this.quoteDetailsForChildTemplates.calculations.sum_insured = parseFloat(Number(response.result.horse_details.value).toFixed(2));
              if(response.result.policy_holder.address == null) {
                this.quoteDetailsForChildTemplates.policy_holder.address = temp.policy_holder.address;
              }
              if(response.result.horse_details.address == null) {
                this.quoteDetailsForChildTemplates.horse_details.address = temp.horse_details.address;
              }         
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.getStaticData(0);
    }
    
  }

getStaticData(rating_version) {
  // GET STATIC DATA
  this.quoteService.getStaticData(rating_version, environment.AJG_ID)
  .subscribe(
    (response: any) => {
      if (response.success) {
        this.staticData = response.result;
      }
    },
    (error) => {
      console.log(error);
    }
  );

}

  quoteDetailsUpdated(quoteDetailsForChildTemplates) {
    this.quoteDetailsForChildTemplates = null;

    this.quoteDetailsForChildTemplates = quoteDetailsForChildTemplates;
    
  }




  tabChanged(event) {
    var a = this.quoteDetailsForChildTemplates;
    this.quoteDetailsForChildTemplates = null;
    this.quoteDetailsForChildTemplates = a;
    if (event.index == 0) {     
      this.showHideAEIOSection();
      if(this.quoteDetailsForChildTemplates.vet_fee_sum_insured_id == '713b6924-5b80-4c76-a469-f767594bc7d1') {
        this.quoteDetailsForChildTemplates.full_quote_section = true;
          this.quoteDetailsForChildTemplates.aeio_quote_section = false;
      }
      // this.quoteDetailsForChildTemplates.aeio_quote_section = true;
      // this.quoteDetailsForChildTemplates.full_quote_section = true;
      this.quoteDetailsForChildTemplates.indicativePage = true;
    } else {
      if(this.quoteDetailsForChildTemplates.is_aeio == 1) {
        this.quoteDetailsForChildTemplates.aeio_quote_section = true;
        this.quoteDetailsForChildTemplates.full_quote_section = false;
      } else {
        this.quoteDetailsForChildTemplates.aeio_quote_section = false;
        this.quoteDetailsForChildTemplates.full_quote_section = true;
      }
      this.quoteDetailsForChildTemplates.indicativePage = false;
    }
    
    
  }

  showHideAEIOSection() {
    if (this.quoteDetailsForChildTemplates.horse_details.age >= 20 && this.quoteDetailsForChildTemplates.type == 1) {
      this.quoteDetailsForChildTemplates.full_quote_section = false;
      this.quoteDetailsForChildTemplates.aeio_quote_section = true;
    } else if (this.quoteDetailsForChildTemplates.horse_details.age > 25 && this.quoteDetailsForChildTemplates.type == 2) {
      this.quoteDetailsForChildTemplates.full_quote_section = false;
      this.quoteDetailsForChildTemplates.aeio_quote_section = true;
    } else {
      this.quoteDetailsForChildTemplates.full_quote_section = true;
      this.quoteDetailsForChildTemplates.aeio_quote_section = true;
    }
  }

  activateFullDetailsTab() {
    this.full_details_tab = true;
    this.quoteDetailsForChildTemplates.indicativePage = false;
    this.selectedIndex += 1;
  }

  jumpToIndicativeQuoteDetilsTab() {
    this.quoteDetailsForChildTemplates.indicativePage = true;
    this.selectedIndex = 0;
  }
  jumpToStatementOfFactTab() {
    this.statement_of_facts_tab = true;
    this.selectedIndex = 2;
  }
  jumpToFullQuoteDetilsTab() {
    this.full_details_tab = true;
    this.selectedIndex = 1;
  }
  jumpToPayment() {
    this.payment_details = true;
    this.selectedIndex = 3;
  }
  jumpToUnderWritingSubmissionTab() {
    this.submission = true;
    this.selectedIndex = 4;
  }

}
