import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

import { switchMap } from 'rxjs/operators';
import { UserService } from '../user.service';
import { User } from '../../model/user/user.model';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
declare var $;
import { GridOptions } from "ag-grid-community";
import { SharedService } from '../../services/shared.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  company_id;
  UserType;

  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Name',
      cellRenderer: function (params) {
        if (params.data.userinfo) {
          return params.data.userinfo.first_name + ' ' + params.data.userinfo.last_name;
        } else {
          return null;
        }
      },
      sortable: true, filter: true
    },
    { headerName: 'Email', field: 'username', sortable: true, filter: true },
    {
      headerName: null,
      width: 50,
      cellRenderer: function (params) {
        let buttons = '<button><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></button>';
        if (params.data.status == 0) {
          buttons += '<button title="Do you want to activate company?"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete" id="' + params.data.id + '"></i></button>';
        } else {
          buttons += '<button title="Do you want to de-activate company?"><i class="mdi mdi-check-circle-outline activeIcon" data-action-type="delete" id="' + params.data.id + '"></i></button>';
        }
        return buttons;

      }
    }
  ];
  rowData: any;

  constructor(private userservice: UserService, private router: Router, private route: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.company_id = this.route.snapshot.params.company_id;
    this.userservice.getAllUsers(this.company_id).subscribe(data => {
      this.rowData = (data as any).result;
    });

    this.UserType = this.sharedService.getUserType();
  }

  onGridRowClicked(e: any) {
    if (e) {
      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {
            this.router.navigate(['/user', this.company_id, id]);
            break;
          }
        case "delete":
          {
            this.deleteDetails(e.data);
            break;
          }
      }
    }
  }

  updateUSer(data) {

    if (data.group_user_linking != null) {
      data.group_id = data.group_user_linking.group_id;   // Remove later
    }
    data.first_name = data.userinfo.first_name;   // Remove later
    data.last_name = data.userinfo.last_name;   // Remove later
    data.phone = data.userinfo.phone;   // Remove later

    this.userservice.saveUser(data).subscribe(
      (response: any) => {
        const options = {
          title: '',
          message: response.message,
          imageType: 'success',
          yesNoBtns: false
        };
        this.confirmDialogService.open(options);
        this.gridOptions.api.redrawRows()
      },
      (error) => {
        console.log(error);
      }
    );
  }

  deleteDetails(data) {
    let title = '';
    let message = '';
    let imageType = '';
    if (data.status == 1) {
      data.status = 0;
      message = "Are you sure you want to de-activate " + data.userinfo.first_name + ' ' + data.userinfo.last_name + "?";
      title = "De-activate User";
      imageType = 'deactivate';
    }
    else {
      data.status = 1;
      message = "Are you sure you want to Activate " + data.userinfo.first_name + ' ' + data.userinfo.last_name + "?";
      title = "Activate User";
      imageType = 'activate';
    }
    const options = {
      title: title,
      message: message,
      imageType: imageType,
      yesNoBtns: true
    };
    this.confirmDialogService.open(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.updateUSer(data);
      }
    });
  }

}
