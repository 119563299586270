<div class="card-header bg-success">
    <div class="row">
        <div class="col-md-6">
            <h3>Policy Details</h3>
        </div>
    </div>
</div>
    <div class="card-body">
        <form #f="ngForm">
            <div class="row">
                <div class="col-md-6">
                    <h5><u>Policy Search</u></h5>
                </div>
                <div class="col-md-6 text-right"><a (click)="clearFilters(f)" class="btn btn-success btn-sm">Clear
                        Filters</a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3">Insured name</label>
                        <div class="col-md-9">
                            <div class="input-group input-group-sm mb-3">
                                <div class="input-group-prepend bg-transparent">
                                    <span class="input-group-text bg-white" id="inputGroup-sizing-sm">
                                        <i class="mdi mdi-magnify text-primary"></i>
                                    </span>
                                </div>
                                <input type="text" name="policy_holder" class="form-control form-control-lg"
                                    [(ngModel)]="quote.policy_holder" (blur)="searchRecords()">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3">Broker Company</label>
                        <div class="col-md-9">
                            <mat-form-field class="w-100">
                                <mat-label>Broker Company</mat-label>
                                <mat-select class="form-control form-control-sm" name="broker_id"
                                    [(ngModel)]="quote.company_id" (selectionChange)="searchRecords()">
                                    <mat-option [value]="">Select Broker</mat-option>
                                    <mat-option *ngFor="let brk of brokers" [value]="brk.id">
                                        {{brk.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3">Reference</label>
                        <div class="col-md-9">
                            <div class="input-group input-group-sm mb-3">
                                <div class="input-group-prepend bg-transparent">
                                    <span class="input-group-text bg-white" id="inputGroup-sizing-sm">
                                        <i class="mdi mdi-magnify text-primary"></i>
                                    </span>
                                </div>
                                <input type="text" name="quote_ref_number" class="form-control form-control-lg"
                                    [(ngModel)]="quote.quote_ref_number" (blur)="searchRecords()">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3">Policy Type</label>
                        <div class="col-md-9">
                            <mat-form-field class="w-100">
                                <mat-label>Policy Type</mat-label>
                                <mat-select class="form-control form-control-sm" name="type" [(ngModel)]="quote.type"
                                    (selectionChange)="searchRecords()">
                                    <mat-option [value]="">Select Policy Type</mat-option>
                                    <mat-option [value]="type.key" *ngFor="let type of quoteTypes | keyvalue">
                                        {{type.value}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3">Date From</label>
                        <div class="col-md-9">
                            <div class="form-group">
                                <input type="text" name="date_from" class="form-control form-control-sm datepicker"
                                    matInput (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker"
                                    [(ngModel)]="quote.from" (dateChange)="searchRecords()">
                                <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #myDatepicker></mat-datepicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3">Date To</label>
                        <div class="col-md-9">
                            <div class="form-group">
                                <input type="text" name="date_to" class="form-control form-control-sm datepicker"
                                    matInput (focus)="dateToDatepicker.open()" [matDatepicker]="dateToDatepicker"
                                    [(ngModel)]="quote.to" (dateChange)="searchRecords()">
                                <mat-datepicker-toggle [for]="dateToDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #dateToDatepicker></mat-datepicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3">Policy Status</label>
                        <div class="col-md-9">
                            <mat-form-field class="w-100">
                                <mat-label>Policy Status</mat-label>
                                <mat-select class="form-control form-control-sm" name="type" 
                                    [(ngModel)]="quote.insurance_status" (selectionChange)="searchRecords()">
                                    <mat-option [value]="">All</mat-option>
                                    <mat-option [value]="2">Inactive</mat-option>
                                    <mat-option [value]="1">Active</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>            
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3">Product</label>
                        <div class="col-md-9">
                            <mat-form-field class="w-100">
                                <mat-label>Products</mat-label>
                                <mat-select class="form-control form-control-sm" name="type" [(ngModel)]="quote.product_id"
                                    (selectionChange)="searchRecords()">
                                    <mat-option [value]="">All Products</mat-option>
                                    <mat-option [value]="value.id" *ngFor="let value of products">{{ value.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3">Horse name</label>
                        <div class="col-md-9">
                            <div class="input-group input-group-sm mb-3">
                                <div class="input-group-prepend bg-transparent">
                                    <span class="input-group-text bg-white" id="inputGroup-sizing-sm">
                                        <i class="mdi mdi-magnify text-primary"></i>
                                    </span>
                                </div>
                                <input type="text" name="animal_name" class="form-control form-control-lg"
                                    [(ngModel)]="quote.animal_name" (blur)="searchRecords()">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-3">Subjectivity</label>
                        <div class="col-md-9">
                            <mat-form-field class="w-100">
                                <mat-select class="form-control form-control-sm" name="subjectivity" 
                                    [(ngModel)]="quote.subjectivity" (selectionChange)="searchRecords()">
                                    <mat-option [value]="">Select Subjectivity</mat-option>
                                    <mat-option [value]="1">Is Subjectivity</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div> 
            </div>
        </form>

        <div class="row">
            <div class="col-md-2 pt-4">
                <h5>Policy MI</h5>
            </div>
            <div class="col-md-9">
                <div class="row text-green text-center">
                    <div class="col-md-3">
                        <div class="bg-white p-3">Policies<br><b>{{quote.total_records}}</b></div>
                    </div>
                    <div class="col-md-3">
                        <div class="bg-white p-3">Gross Premium 
                            <span class="ml-1" (click)="helpTextPopup(helpTextModal,'Policy Net Premium + Broker Commission')"><i class="mdi mdi-comment-question-outline text-green"></i></span>
                                <br>
                                <b>&pound; {{quote.total_net_rate}}</b></div>
                    </div>
                    <div class="col-md-3">
                        <div class="bg-white p-3">Total Premium
                            <span class="ml-1" (click)="helpTextPopup(helpTextModal,'Net Premium + IPT Fee + Underwriting Fee + Broker Commission')"><i class="mdi mdi-comment-question-outline text-green"></i></span>
                                <br><b>&pound; {{quote.total_gross_premium}}</b></div>
                    </div>
                    <div class="col-md-3">
                        <div class="bg-white p-3">Fees<br><b>&pound; {{quote.total_fees}}</b></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <h5><u>Policy Details</u></h5>
            <ag-grid-angular style="width: 100%; height: 550px;" class="ag-theme-bootstrap" [gridOptions]="gridOptions"
                [rowData]="rowData" [columnDefs]="columnDefs" animateRows pagination paginationPageSize=15 rowHeight=27
                headerHeight=70 (rowClicked)='onGridRowClicked($event,content)'>
            </ag-grid-angular>
        </div>
    </div>
    <ng-template #content let-modal>
        <div class="modal-body">
            {{msg}}
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-sm" (click)="deleteQuote(quote_id,rowIndex)">Delete</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close()">Close</button>
        </div>
    </ng-template>

    <ng-template #helpTextModal let-modal>
        <div class="modal-body">
            <div class="helptext" [innerHTML]="helptext"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
        </div>
    </ng-template>