import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { LivestockService } from '../livestock.service';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-cover-options',
  templateUrl: './cover-options.component.html',
  styleUrls: ['../../quote.component.css']
})
export class CoverOptionsComponent implements OnInit {
  submitted = false;
  quoteDetailsForm: FormGroup;
  @Input() selectedTabIndex;
  @Input() quoteModelDetails;
  @Input() brokers;
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() proceedToNextTab = new EventEmitter();
  quoteDetails = new QuoteVersions();
  calculations = new CalculationsDetails(); 

  constructor(private formBuilder: FormBuilder, private cdRef: ChangeDetectorRef, private lvService: LivestockService) {
    this.quoteDetailsForm = this.formBuilder.group({
      commonSections: []
    });
  }

  ngOnInit(): void {
    let date = formatDate("2021-09-11 00:00:00", 'yyyy-MM-dd', 'en-US');
    if (!this.selectedTabIndex) {
      this.selectedTabIndex = 0;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quoteModelDetails;
    this.updatedCalDetails = this.quoteModelDetails.calculations;
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  iscommonSectionFormValid: string = 'INVALID';
  commonSectionFormStatus(status) {
    this.iscommonSectionFormValid = status;
  }

  updatedCalDetails;
  calDetails(data) {
    this.updatedCalDetails = data;
  }

  onSubmit() {
    this.submitted = true;
    let modelObj = this.lvService.modelBinding(this.quoteDetailsForm, this.quoteDetails);
    if (modelObj) {
      this.quoteDetails = modelObj;
      if (!this.quoteDetailsForm.valid) {
        this.scrollToError();
        return false;
      }
      this.quoteDetails.calculations = this.updatedCalDetails; 
      this.quoteModelDetails.calculations = this.updatedCalDetails; 
      this.quoteDetailsUpdated.emit(this.quoteDetails);  
      this.proceedToNextTab.emit();
    }
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

}
