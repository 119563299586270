import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteService } from '../../../quote/quote.service';
import { LivestockService } from '../../../quote/livestock/livestock.service';
import { QuoteEndorsement } from '../../../model/quote/quote_endorsement.model';
import {
  FormBuilder, Validators, FormGroup, FormControl, NG_VALIDATORS, FormArray
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CalculationsDetails } from '../../../quote/livestock/livestock-calculations/livestock-calculations.model';
import { Quote } from '../../../model/quote/quote.model';

@Component({
  selector: 'app-qv-full-details',
  templateUrl: './qv-full-details.component.html',
  styleUrls: ['../../../quote/quote.component.css']
})
export class QvFullDetailsComponent implements OnInit {
  @Input() quote;
  endorsement = new QuoteEndorsement();
  qdfrm: FormGroup;
  calculations = new CalculationsDetails();

  constructor(private lvkService: LivestockService, private modalService: NgbModal, private quoteService: QuoteService,
    private fb: FormBuilder, private _snackBar: MatSnackBar, private cdRef: ChangeDetectorRef) { }

  allClaims = [];
  animalTypes = [];
  claimFor = [];
  coverClaimFor = [];
  liveStocks = [];
  totalBullsInsurance = [];
  livestockBreeds = [];
  CoverPeriods = [];
  AllCoverPeriods = [];
  animalBreed: string = '';
  isBullSelected: boolean = false;
  isEweSelected: boolean = false;
  isHeiferSelected: boolean = false;
  coverRequired: boolean = false;
  staticData;
  brokers = [];
  proposer_type_details: FormArray;
  ngOnInit(): void {
    this.qdfrm = this.fb.group({
      policy_start_date: ['', Validators.required],
      policy_type: ['', Validators.required],
      company_id: ['', Validators.required],
      about_proposer_first_name: ['', Validators.required],
      about_proposer_last_name: [''],
      about_proposer_email: ['', Validators.required],
      proposer_type: ['', Validators.required],
      proposer_type_details: this.fb.array([this.bindProposerTypeDetails()]),
      commercial_details: this.commercialDetails(),
      sale_location: [''],
      sale_date: [''],
      claims: [''],
      claim_history: this.fb.array([]),
      animals: [],
    });
  }
  brokerName: string = '';
  isPolicy: boolean = false;
  ngOnChanges(changes: SimpleChanges) {
    this.bindValues();
    this.isPolicy = (this.quote.is_policy) ? true : false;
    if (this.quote.livestock_meta != null && this.quote.livestock_meta.livestock_type_id != null) {
      if (this.quote.livestock_meta.proposer_type == 0) {
        this.qdfrm.get('proposer_type').setValue(null);
      };
      switch (this.quote.livestock_meta.livestock_type_id) {
        case '10146458-ac55-4d2d-aef8-8c75101f8ce2':
          this.isBullSelected = true;
          this.animalBreed = "Bulls"
          break;
        case '10246458-ac55-4d2d-aef8-8c75101f8ce2':
          this.isEweSelected = true;
          this.animalBreed = "Ram"
          break;
        case '10346458-ac55-4d2d-aef8-8c75101f8ce2':
          this.isHeiferSelected = true;
          this.animalBreed = "Heifer and Cows"

          break;
        default:
          this.isBullSelected = false;
          this.isEweSelected = false;
          this.isHeiferSelected = false;
          break;
      }
    }


    // Show/Hide cover required field
    if (this.quote.livestock_meta != null) {
      if (this.quote.livestock_meta.type_of_policy == 1 && this.isHeiferSelected) {
        this.coverRequired = false;
      } else if (this.quote.livestock_meta.type_of_policy == 3 && (this.isHeiferSelected || this.isEweSelected)) {
        this.coverRequired = false;
      } else {
        this.coverRequired = true;
      }
    }

    this.getStaticData();
    this.getBreedName();

    // Get All Brokers
    this.quoteService.getBrokers(3)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.brokers = response.result;
            response.result.forEach((res) => {
              if (res.id == this.quote.company_id) {
                this.brokerName = res.name;
              }
            })
          }
        });

    // Enable/Disable fields
    this.enableDisableFields();

  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  commercialDetails() {
    return this.fb.group({
      is_micro_enterprise: [''],
      company_name: ['', Validators.required],
      email: ['', Validators.required],
      year_of_establishment: ['', Validators.required],
      contact_no_1: ['', Validators.required],
      contact_no_2: [''],
      postcode: ['', Validators.required],
      address_line_1: ['', Validators.required],
      address_line_2: [''],
      city: ['', Validators.required],
      county: ['', Validators.required],
    })
  }

  enableDisableFields() {
    if (this.quote.livestock_meta) {
      if (this.quote.livestock_meta.proposer_type == 3) {
        this.qdfrm.get('proposer_type_details').disable();
        this.qdfrm.get('commercial_details').enable();
      } else {
        this.qdfrm.get('proposer_type_details').enable();
        this.qdfrm.get('commercial_details').disable();
      }
    }
  }

  get qdFrmCtrl() {
    if (this.qdfrm) {
      return this.qdfrm.controls;
    }
  }
  livestock_claims = [];
  bindValues() {
    if (this.quote && this.qdfrm) {
      this.qdfrm.patchValue({
        policy_start_date: this.quote.policy_start_date,
        company_id: this.quote.company_id,
        policy_type: this.quote.livestock_meta.type_of_policy,
        about_proposer_first_name: (this.quote.livestock_meta) ? this.quote.livestock_meta.about_proposer_first_name : null,
        about_proposer_last_name: (this.quote.livestock_meta) ? this.quote.livestock_meta.about_proposer_last_name : null,
        about_proposer_email: (this.quote.livestock_meta) ? this.quote.livestock_meta.about_proposer_email : null,
        proposer_type: (this.quote.livestock_meta) ? this.quote.livestock_meta.proposer_type : null,
        sale_location: (this.quote.livestock_meta) ? this.quote.livestock_meta.sale_location : null,
        sale_date: (this.quote.livestock_meta) ? this.quote.livestock_meta.sale_date : null,
        claims: (this.quote.livestock_meta) ? this.quote.livestock_meta.claim_count : null,
      });



      if (this.quote.livestock_meta.proposer_type == 3) {
        this.qdfrm.get('commercial_details.is_micro_enterprise').setValue(this.quote.policy_holders[0].is_micro_enterprise);
        this.qdfrm.get('commercial_details.company_name').setValue(this.quote.policy_holders[0].company_name);
        this.qdfrm.get('commercial_details.email').setValue(this.quote.policy_holders[0].email);
        this.qdfrm.get('commercial_details.year_of_establishment').setValue(this.quote.policy_holders[0].year_of_establishment);
        this.qdfrm.get('commercial_details.contact_no_1').setValue(this.quote.policy_holders[0].phone);
        this.qdfrm.get('commercial_details.contact_no_2').setValue(this.quote.policy_holders[0].mobile);
        this.qdfrm.get('commercial_details.postcode').setValue((this.quote.policy_holders[0].address) ? this.quote.policy_holders[0].address.postcode : '');
        this.qdfrm.get('commercial_details.address_line_1').setValue((this.quote.policy_holders[0].address) ? this.quote.policy_holders[0].address.address1 : '');
        this.qdfrm.get('commercial_details.address_line_2').setValue((this.quote.policy_holders[0].address) ? this.quote.policy_holders[0].address.address2 : '');
        this.qdfrm.get('commercial_details.city').setValue((this.quote.policy_holders[0].address) ? this.quote.policy_holders[0].address.city : '');
        this.qdfrm.get('commercial_details.county').setValue((this.quote.policy_holders[0].address) ? this.quote.policy_holders[0].address.country : '');
      } else {
        this.qdfrm.get('proposer_type_details').enable();
        this.qdfrm.get('commercial_details').disable();
        (this.qdfrm.get('proposer_type_details') as FormArray).clear();
        this.quote.policy_holders.forEach((res) => {
          let details = this.fb.group({
            proposer_first_name: res.first_name,
            proposer_last_name: res.last_name,
            proposer_email: res.email,
            proposer_postcode: (res.address) ? res.address.postcode : '',
            proposer_address_line_1: (res.address) ? res.address.address1 : '',
            proposer_address_line_2: (res.address) ? res.address.address2 : '',
            proposer_city: (res.address) ? res.address.city : '',
            proposer_county: (res.address) ? res.address.country : ''
          });
          this.proposer_type_details = this.qdfrm.get('proposer_type_details') as FormArray;
          this.proposer_type_details.push(details);
        })
      }
    }
  }

  bindClaimHistory() {
    if (this.quote.livestock_meta && this.quote.livestock_meta.claim_count > 0) {
      this.claimsArr.controls = this.quote.livestock_claims;
      this.quote.livestock_claims.forEach((res) => {
        let animalTypes = this.animalTypes.filter(x => x.type == res.livestock_type_id);
        let animal_type = '';
        if(animalTypes.length > 0) {
          animal_type = animalTypes[0].value;
        }
        let claimFor = this.claimFor.filter(x => x.type == Number(res.claim_amount));
        let claim_for = '';
        if(claimFor.length > 0) {
          claim_for = claimFor[0].value;
        }
        let coverClaimFor = this.coverClaimFor.filter(x => x.type == res.cover_claimed_for);
        let cover_claim_for = '';
        if(coverClaimFor.length > 0) {
          cover_claim_for = coverClaimFor[0].value;
        }

        let claimsHistory = {
          animal_type: animal_type,
          claim_for:claim_for,
          cover_claim_for:cover_claim_for
        };
        this.livestock_claims.push(claimsHistory);  
      });


    }
  }

  get podArr() { return this.qdFrmCtrl.proposer_type_details as FormArray; }

  /*
  * Add Proposer Sections
  */
  addProposer() {
    this.podArr.push(this.bindProposerTypeDetails());
  }
  /*
  * Remove Proposers
  */
  removeProposers(i) {
    const formArray = this.qdfrm.get('proposer_type_details') as FormArray;
    formArray.removeAt(i);
  }

  onPprTypeChange(event) {
    if (event.value == 3) {
      this.qdfrm.get("commercial_details").enable();
      this.qdfrm.get("proposer_type_details").disable();
      if (this.quote.policy_holders.length == 0) {
        this.quote.policy_holders.push({
          'quote_id': '',
          'is_micro_enterprise': '',
          'company_name': '',
          'email': '',
          'phone': '',
          'mobile': '',
          'year_of_establishment': '',
          'address': {
            'address1': '',
            'address2': '',
            'city': '',
            'country': '',
            'postcode': '',
          }
        });

      }
    } else {
      if (event.value == 1) {
        (this.qdfrm.controls['proposer_type_details'] as FormArray).clear();
        this.podArr.push(this.bindProposerTypeDetails());
      }
      this.qdfrm.get("commercial_details").disable();
      this.qdfrm.get("proposer_type_details").enable();
    }
  }

  bindProposerTypeDetails(): FormGroup {
    return this.fb.group({
      proposer_first_name: [null, [Validators.required]],
      proposer_last_name: [null],
      proposer_email: [null, [Validators.required]],
      proposer_postcode: [null, [Validators.required]],
      proposer_address_line_1: [null, [Validators.required]],
      proposer_address_line_2: [null],
      proposer_city: [null, [Validators.required]],
      proposer_county: [null, [Validators.required]],
    });
  }

  /*
 * POSTCODE LOOKUP
 */
  addresses = [];
  postcodeLookup(event) {
    let postcode = event.target.value;
    // API call - postcode lookup
    this.quoteService.getAddressPostcodeLookup(postcode)
      .subscribe(
        (response: any) => {
          this.addresses = response.addresses;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSelectAddress(address, type = -1) {
    if (type == -1) {
      this.qdfrm.get('commercial_details.address_line_1').setValue(address.line_1);
      this.qdfrm.get('commercial_details.address_line_2').setValue(address.line_2);
      this.qdfrm.get('commercial_details.city').setValue(address.town_or_city);
      this.qdfrm.get('commercial_details.county').setValue(address.county);
    } else {
      this.podArr.controls.forEach((res, index) => {
        if (type == index) {
          res.get("proposer_address_line_1").setValue(address.line_1);
          res.get("proposer_address_line_2").setValue(address.line_2);
          res.get("proposer_city").setValue(address.town_or_city);
          res.get("proposer_county").setValue(address.county);
        }
      })
    }
  }

  /*
  * Display popup if purchase price is > 10K
  */
  msg = '';
  purchasePriceChange(modal, event) {
    if (Number(event.target.value) > 10000) {
      this.msg = "Proof of copy of sale (value) required for animals over £10k";
      this.modalService.open(modal, { centered: true });
    }
  }
  getStaticData() {
    // GET STATIC DATA
    if (this.quote.rating_version) {
      this.lvkService.getStaticData(this.quote.rating_version)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.staticData = response.result;
              this.liveStocks = this.staticData.livestock;
              this.livestockBreeds = this.liveStocks[0].breeds;
              this.CoverPeriods = this.staticData.livestock_cover_periods;
              this.AllCoverPeriods = this.staticData.livestock_cover_periods;
              this.allClaims = this.staticData.claim_loading;
              this.animalTypes = Object.keys(this.staticData.sub_animal_types).map(key => ({ type: key, value: this.staticData.sub_animal_types[key] }));
              this.claimFor = Object.keys(this.staticData.claim_for).map(key => ({ type: key, value: this.staticData.claim_for[key] }));
              this.coverClaimFor = Object.keys(this.staticData.cover_claim_for).map(key => ({ type: key, value: this.staticData.cover_claim_for[key] }));
              this.bindClaimHistory();
              // let i = 0
              // this.quote.livestock_details.forEach((res) => {

              //   this.getCoverPeriods(res.livestock_cover_id, i);
              //   i++;
              // });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
  breedName: string = "";
  coverPeriodName: string = "";
  coverName: string = "";
  livestockCovers = [];

  getBreedName() {
    let livestock = this.liveStocks.filter(x => x.id == this.quote.livestock_meta.livestock_type_id);
    if (livestock.length > 0) {
      this.livestockBreeds = livestock[0].breeds;
      if (this.quote.livestock_meta.type_of_policy == 3 && this.isBullSelected) {
        this.livestockCovers = livestock[0].covers.filter(x => x.id !== '70246458-ac55-4d2d-aef8-8c75101f8ce2');
      } else if (this.quote.livestock_meta.type_of_policy == 4 && this.isBullSelected) {
        this.livestockCovers = livestock[0].covers.filter(x => x.id !== '70146458-ac55-4d2d-aef8-8c75101f8ce2');
      } else {
        this.livestockCovers = livestock[0].covers;
      }
    }

  }
  getCoverPeriods(cover_id, i) {
    this.CoverPeriods[i] = [this.AllCoverPeriods];
    if (this.isBullSelected) {
      let livestocks = this.liveStocks.filter(x => x.id == this.quote.livestock_meta.livestock_type_id);
      if (livestocks.length > 0) {
        let covers = livestocks[0].covers.filter(x => x.id == cover_id);
        if (covers.length > 0) {
          this.CoverPeriods[i] = covers[0].cover_periods;
        }
      }
    } else {
      this.CoverPeriods[i] = this.AllCoverPeriods;
    }
  }

  /*
  * CLAIMS
  */
  get claimsArr() { return this.qdFrmCtrl.claim_history as FormArray; }

  claimHistory() {
    const claims = this.fb.group({
      animal_type: ['', Validators.required],
      claim_for: ['', Validators.required],
      cover_claim_for: ['', Validators.required]
    });
    if (this.qdfrm) {
      this.claim_history = this.qdfrm.get('claim_history') as FormArray;
      this.claim_history.push(claims);
    }
  }

  claim_history: FormArray;



  calDetails(data, dataFrom = '') { }

  submitted: boolean = false;
  onSubmit() {
    this.submitted = true;
    this.quote.calculations = this.calculations;
    this.quote.calculations = this.lvkService.bindCalculations(this.quote);
    if (this.quote.livestock_details) {
      this.quote.livestock_details.forEach((res, index) => {
        this.qdfrm.value.animals.animal_details.forEach((res1, index1) => {
          res1.livestock_purchasers = [];
          if (res1.id == res.id) {
            res.livestock_purchasers.forEach((res2) => {
              res1.livestock_purchasers.push(res2);
            })
          }

        })
      })
    }

    let modelObj = this.lvkService.modelBinding(this.qdfrm, this.quote);
    this.quote = modelObj;
    if (!this.qdfrm.valid) {
      this.scrollToError();
      return false;
    }
    this.saveQuote(modelObj);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

  saveQuote(modelObj) {
    let parent_quote = new Quote();
    parent_quote.id = modelObj.quote_meta_id;
    parent_quote.quote_versions[0] = modelObj;
    if (modelObj.is_policy == 1) {
      parent_quote.quote_versions[0].quote_page_index = 4;
    }
    let data = {
      quote_data: parent_quote
    };
    this.quoteService.saveLivestockQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quote = response.result.quote_data.quote_versions[0];
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-success'
            });
          }
        });
  }

}
