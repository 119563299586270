import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { formatDate, TitleCasePipe } from '@angular/common';
import { FormBuilder, NgForm, FormArray, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'ngx-webstorage';
import { Quote } from '../../../model/quote/quote.model';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { CalculationsDetails } from '../calculations/calculations.model';
import { QuoteService } from '../../quote.service';
import { PolicyService } from '../../../policy/policy.service';
import { ConfirmDialogService } from '../../../confirm-dialog/confirm-dialog.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { UserInfoService } from '../../../services/user-info.service';
import { HorseDetailsSaddlery } from '../../../model/quote/horse_details_saddlery.model';
import { HorseDetailsTrailer } from '../../../model/quote/horse_details_trailer.model';


@Component({
  selector: 'app-ajg-indicative-quote',
  templateUrl: './ajg-indicative-quote.component.html',
  styleUrls: ['../../quote.component.css'],
})
export class AjgIndicativeQuoteComponent implements OnInit {
  @Input() quoteDetails: QuoteVersions;
  @Output() ProceedFullDetailsBtn = new EventEmitter();
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  indicativeCalculationsDetails = new CalculationsDetails();

  quote: QuoteVersions;
  parent_quote = new Quote();
  classOfUse;
  allClassOfUse;
  policyCover = [];
  vetFeesCoverData = [];
  vetFeesCover = [];
  vetFeesCoverBloodStock = [];
  publicLiability;
  accidentCover;
  vetFeeExcess = [];
  bloodStocks;
  vetFeeCoverSpecial = [];
  saddleryDetails;
  TrailerDetails;
  policyDiscount = [];
  disableVetFeeExcess = false;
  slider_broker_comm_max = 100;
  max_ppa_comission = 100;
  max_prt_comission = 100;
  max_uw_fee = 100;
  selected_full_vet_fee_amount = 0;
  ipt_fee = 0;
  aeio_class_net_rate = 0;
  vet_minimum_premium = 0;
  co_pay_discount_details;
  visibleOnlytoAdmin = false;
  dob_max;
  mta_quote_data = new QuoteVersions();
  BloodStockDetails = false;
  horseSubClasses = [];
  QuoteTypeVisibility: boolean = false;
  disableFieldsForQuoteType: boolean = false;
  tigerlabPremiumLoading: boolean = false;
  isPelwicaUser: boolean = false;
  allPolicyCovers = [];
  coPayDiscounts = [];
  minimumPolicyPremiums = [];
  coPay20Discount;
  coPay15Discount;
  MortalityClaimLoading = [];
  VetClaimLoading = [];
  @Input()
  set staticData(value: any) {
    if (value) {
      // Minumum Premiums
      value.minimum_premium.forEach((res, index) => {
        if (index == 0) {
          this.indicativeCalculationsDetails.policy_minimum_premium = res.amount;
        }
      })
      this.minimumPolicyPremiums = value.minimum_premium;
      // Class Of Use 
      this.classOfUse = value.class_of_use;
      this.allClassOfUse = value.class_of_use;
      value.class_of_use.forEach((res, index) => {
        if (index == 0 && !this.route.snapshot.params.id) {
          this.quote.horse_class_id = res.id;
          this.quote.full_class_of_use = res.name;
          this.horseSubClasses = res.sub_classes;
        }
      });
      if (this.route.snapshot.params.id && value.class_of_use) {
        let class_of_use = value.class_of_use.filter(x => x.id == this.quote.horse_class_id);
        if (class_of_use.length > 0) {
          this.quote.full_class_of_use = class_of_use[0].name;

        }
      }
      this.bloodStocks = value.blood_stock;

      // Policy Cover Details
      if (this.route.snapshot.params.id && value.policy_cover_details) {
        let policy_cover = value.policy_cover_details.filter(x => x.id == this.quote.policy_cover_id);
        if (policy_cover.length > 0) {
          this.quote.full_policy_cover = policy_cover[0].name;
        }
      }
      this.allPolicyCovers = value.policy_cover_details;
      value.policy_cover_details.forEach((res, index) => {
        if (res.is_vet_fee_cat == 0 && res.is_addon_cat == 0) {
          this.policyCover.push(res);
          if (index == 0 && !this.route.snapshot.params.id) {
            this.policyCoverChange(res, res.id, "");
          }

        }

        if (res.is_vet_fee_cat == 1) {
          res.section_indeminity_limit.forEach((res1) => {

            if (res1.is_special_vet == 0) {
              if (res1.is_blood_stock == 0) {
                if (res1.id == this.quote.vet_fee_sum_insured_id) {
                  this.quote.full_vet_fee_cover = res1.description;
                  res1.is_checked = true;
                  this.vetFeeCoverIndex = res1.vet_fee_cover_index;
                }
                this.vetFeesCover.push(res1);
                this.vetFeesCoverData.push(res1);
              } else {
                this.vetFeesCoverBloodStock.push(res1);
              }
            } else {

              this.vetFeeCoverSpecial.push(res1);
            }


          });
          this.vet_minimum_premium = res.minimum_premium;
        }



        // Saddlery
        if (res.cover_index == 6) {
          this.saddleryDetails = res;
        }

      });

      // Vet Fee Excess
      value.company_excess.forEach((res, index) => {
        if (index == 0 && !this.route.snapshot.params.id) {
          res.is_checked = true;
          this.quote.full_vet_fee_excess = res.excess_amount;
        } else {
          res.is_checked = false;
        }
        if (this.route.snapshot.params.id && res.id == this.quote.company_excess_id) {
          this.quote.full_vet_fee_excess = res.excess_amount;
          res.is_checked = true;
        }
        this.vetFeeExcess.push(res);
      });

      // Peliwica Commissions
      value.peliwica_commissions.forEach((res) => {
        if (res.code == 'IPT') {
          this.ipt_fee = res.commission_percentage;
        }
      });

      // Policy Discount
      this.policyDiscount = value.policy_discount;

      // Company Co Pay Discount
      if (value.company_co_pay_discount.length > 0) {
        this.coPayDiscounts = value.company_co_pay_discount;
        let coPay20Discount = value.company_co_pay_discount.filter(x => x.id == '94d0defd-daba-4da0-8e15-d500beb351e8');
        if (coPay20Discount.length > 0) {
          this.coPay20Discount = coPay20Discount[0];
        }

        let coPay15Discount = value.company_co_pay_discount.filter(x => x.id != '94d0defd-daba-4da0-8e15-d500beb351e8');
        if (coPay15Discount.length > 0) {
          this.coPay15Discount = coPay15Discount[0];
        }
      }

      // AEIO Class Net Rate
      if (value.aeio.length > 0) {
        this.aeio_class_net_rate = value.aeio[0].value;
      } else {
        this.aeio_class_net_rate = 0;
      }

      // Mortality Claim Loading
      this.MortalityClaimLoading = value.claim_loading_mortality;
      // Vet Claim Loading
      this.VetClaimLoading = value.claim_loading_vet;

    }
    // this.calculations();
  }

  horseAge;
  horseAgeMonth;
  constructor(private formBuilder: FormBuilder, private sessionSt: LocalStorageService, private userService: UserInfoService,
    private el: ElementRef, private quoteService: QuoteService, private _snackBar: MatSnackBar,
    private confirmDialogService: ConfirmDialogService, public dialog: MatDialog, private titlecasePipe: TitleCasePipe,
    private modalService: NgbModal, private route: ActivatedRoute, private router: Router, private policyService: PolicyService) {
    this.horseAge = Array(31).fill(0, 0, 31).map((x, i) => i);
    this.horseAgeMonth = Array(12).fill(0, 0, 12).map((x, i) => i);
  }

  ComponentName;
  tigerlabfield: boolean = false;
  isEditMode: boolean = false;
  curr_date = new Date();
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
    if (this.route.snapshot.params.id) {
      this.getAllFields();
    }
    this.setValuesToFields();
    if (this.quote.horse_details.value) {
      this.quote.horse_details.value = Math.round(this.quote.horse_details.value);
    }
    this.quote.horse_details.age = Number(this.quote.horse_details.age);




    if (this.quoteDetails.quote_id != "") {
      this.mta_quote_data = this.quoteDetails;
      this.parent_quote.quote_versions[0].mta_sum_policy_premium = this.quoteDetails.mta_sum_policy_premium;
      this.parent_quote.quote_versions[0].sum_policy_premium = this.quoteDetails.sum_policy_premium;
      this.parent_quote.quote_versions[0].latest_policy_start_date = this.quoteDetails.latest_policy_start_date;
      this.parent_quote.quote_versions[0].latest_policy_end_date = this.quoteDetails.latest_policy_end_date;

      this.indicativeCalculationsDetails.old_tigerlab_premium_loading = this.mta_quote_data.financial_details.tigerlab_premium_loading;
      this.indicativeCalculationsDetails.old_premium_loading_percentage = this.mta_quote_data.financial_details.premium_loading_percentage;
    }

    if (this.route.snapshot.params.id) {
      this.getBrokerCommissionById(this.quote.company_id);
      this.indicativeCalculationsDetails.tigerlab_premium_loading = this.quote.financial_details.tigerlab_premium_loading;
      // Main Class Use - Enable BloodStock Sections If BloodStock Selected
      if (this.quote.horse_class_id && this.classOfUse) {
        let class_of_use = this.classOfUse.filter(x => x.id == this.quote.horse_class_id);
        if (class_of_use.length > 0) {
          this.horseSubClasses = class_of_use[0].sub_classes;
          this.class_type = class_of_use[0].class;
          let subclass = this.horseSubClasses.filter(x => x.id == this.quote.horse_class_sub_id);
          if (subclass.length > 0) {
            this.quote.primaryCover = subclass[0].name;
          }

          if (class_of_use[0].class == 5) {
            this.BloodStockDetails = true;
            let bloodstockDetails = this.bloodStocks.filter(x => x.id == this.quote.blood_stock_id);
            if (bloodstockDetails.length > 0) {
              this.quote.primaryCover = bloodstockDetails[0].name;
            }
            this.vetFeesCover = this.vetFeesCoverBloodStock;
          }
        }

      }

      // Life Saving
      if (this.vetFeeCoverSpecial) {
        let lifeSaving = this.vetFeeCoverSpecial.filter(x => x.id == this.quote.life_saving_surgery_id);
        if (lifeSaving.length > 0) {
          lifeSaving[0].is_checked = true;
        }
      }
    }

    this.indicativeCalculationsDetails.full_calculate_data(this.mta_quote_data);




    // if (this.sessionSt.retrieve('company_role') == 'Broker' && !this.quote.quote_versions[0].id) {
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      this.getBrokerCommissionById(this.sessionSt.retrieve('userInfo').company_id);
    }
    this.quote.indicativePage = true;

    // ENABLE MTA AND CANCEL
    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);
      this.tigerlabfield = false;
    } else {
      this.quote_type = Number(this.quote.type);
    }
    switch (this.quote_type) {
      case 1:
        this.tigerlabfield = true;
      case 2:
        this.tigerlabfield = true;
        this.indicativeCalculationsDetails.is_mta = false;
        this.indicativeCalculationsDetails.is_cancel = false;
        this.QuoteTypeVisibility = true;
        this.indicativeCalculationsDetails.is_NTU = false;
        this.indicativeCalculationsDetails.is_lapse = false;
        break;
      case 3:
        this.tigerlabfield = true;
        this.indicativeCalculationsDetails.is_mta = true;
        this.getPolicyReasons();
        this.QuoteTypeVisibility = false;
        this.indicativeCalculationsDetails.is_NTU = false;
        this.indicativeCalculationsDetails.is_lapse = false;
        if (this.sessionSt.retrieve('is_admin')) {
          this.minPolicyDate = this.quote.original_policy_start_date;
        } else {
          if (this.sessionSt.retrieve('is_broker')) {
            if (this.quote.policy_start_date) {
              let policy_start_date = new Date(this.quote.policy_start_date);
              if (policy_start_date < this.curr_date) {
                this.minPolicyDate = new Date();
                this.quote.policy_start_date = this.curr_date;
              }
            }
          }
          if (this.sessionSt.retrieve('is_staff')) {
            let minDate = new Date().setDate(new Date().getDate() - 7);
            this.minPolicyDate = new Date(minDate);
            if (this.quote.policy_start_date) {
              let policy_start_date = new Date(this.quote.policy_start_date);
              if (policy_start_date < this.minPolicyDate) {
                this.quote.policy_start_date = this.curr_date;
              }
            }
          }
          let maxDate = new Date().setDate(new Date().getDate() + 14);
          this.maxPolicyDate = new Date(maxDate);
        }
        break;
      case 4:
        this.tigerlabfield = true;
        this.indicativeCalculationsDetails.is_cancel = true;
        this.indicativeCalculationsDetails.is_NTU = false;
        this.indicativeCalculationsDetails.is_lapse = false;
        this.getPolicyReasons();
        this.QuoteTypeVisibility = false;
        if (this.sessionSt.retrieve('is_admin')) {
          this.maxPolicyDate = this.quote.policy_end_date;
          this.minPolicyDate = this.quote.original_policy_start_date;
        } else {
          let minDate = new Date().setDate(new Date().getDate() - 7);
          this.minPolicyDate = new Date(minDate);
          let maxDate = new Date().setDate(new Date().getDate() + 14);
          this.maxPolicyDate = new Date(maxDate);
          if (new Date(this.quote.policy_start_date) < new Date()) {
            let formattedDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
            this.quote.policy_start_date = new Date(formattedDate);
          }
        }
        this.fullQuote = this.quote.is_aeio == 0 ? true : false;
        this.disableFieldsForQuoteType = true;
        break;
      case 5:
        this.indicativeCalculationsDetails.is_mta = false;
        this.indicativeCalculationsDetails.is_cancel = false;
        this.QuoteTypeVisibility = false;
        this.indicativeCalculationsDetails.is_NTU = false;
        this.indicativeCalculationsDetails.is_lapse = true;
        this.minPolicyDate = this.quote.original_policy_start_date;
        this.fullQuote = this.quote.is_aeio == 0 ? true : false;

        let endDate = new Date(this.quote.policy_start_date);
        endDate.setDate(endDate.getDate() + 14);
        this.maxPolicyDate = endDate;
        break;
      case 6:
        this.enableNTU();
        this.QuoteTypeVisibility = false;
        this.quote.policy_start_date = new Date(this.quote.original_policy_start_date);
        this.minPolicyDate = this.quote.original_policy_start_date;
        this.maxPolicyDate = this.quote.policy_end_date;
        this.fullQuote = this.quote.is_aeio == 0 ? true : false;
        break;
      case 7:
        this.quote.policy_start_date = new Date();
        break;
      default:
        this.indicativeCalculationsDetails.is_mta = false;
        this.indicativeCalculationsDetails.is_cancel = false;
        this.QuoteTypeVisibility = true;
        this.indicativeCalculationsDetails.is_NTU = false;
        this.indicativeCalculationsDetails.is_lapse = false;
        break;
    }

    this.ComponentName = this.constructor.name;
    if (this.route.snapshot.params.id) {
      this.isEditMode = true;
      if (this.quote.indicativePage) {
        if (this.route.snapshot.params.quote_type == 3) {
          this.showHideAEIOSection(this.quote.type);
        } else {
          this.showHideAEIOSection(this.quote_type);
        }
      }

      if (this.vetFeeCoverIndex == 3) {
        this.quote.full_quote_section = true;
        this.quote.aeio_quote_section = false;
      }
      this.setCoverDetailsFullQuoteTab();
    }

    if (this.quote.horse_details.age > 17) {
      this.quote.life_saving_annual_surgery_id = null;
    }

    this.mark2500LSSSelected();
    this.calculations();
  }

  sliderMinValue: number;
  brokers;
  minPolicyDate;
  maxPolicyDate;
  today = new Date();
  quote_type;
  isBroker: boolean = false;
  policyReasons = [];
  reasons = [];
  titleList = [];

  ngOnInit(): void {

    this.quote = this.quoteDetails;
    this.quote.indicativePage = true;
    this.mta_quote_data = this.quote;

    let maxDobDate = new Date().setFullYear(new Date().getFullYear() - 18);
    this.dob_max = new Date(maxDobDate);

    if (this.sessionSt.retrieve('is_admin')) {
      this.isPelwicaUser = true;
    }

    if (this.sessionSt.retrieve('userInfo') != null) {
      const userinfo = this.sessionSt.retrieve('userInfo');
      if (this.route.snapshot.params.id == '') {
        this.slider_broker_comm_max = (userinfo.company.commission_percentage > 0) ? userinfo.company.commission_percentage : this.slider_broker_comm_max;
      }

      if (this.sessionSt.retrieve('company_role') == 'Broker' || this.sessionSt.retrieve('user_group') == 'Staff') {
        this.minPolicyDate = new Date();
      }

      if (this.sessionSt.retrieve('is_staff')) {
        let minDate = new Date().setDate(new Date().getDate() - 7);
        this.minPolicyDate = new Date(minDate);
        let maxDate = new Date().setDate(new Date().getDate() + 30);
        this.maxPolicyDate = new Date(maxDate);
      }

      if (this.sessionSt.retrieve('is_broker')) {
        let maxDate = new Date().setDate(new Date().getDate() + 30);
        this.maxPolicyDate = new Date(maxDate);
        this.isBroker = true;
        this.quote.company_id = this.sessionSt.retrieve('broker_company_id');
      } else {
        this.tigerlabPremiumLoading = true;
        if (this.route.snapshot.params.id) {
          this.visibleOnlytoAdmin = true;
        }
      }
    }

    // ENABLE IS MTA AND IS CANCEL
    this.indicativeCalculationsDetails.is_mta = false;
    this.indicativeCalculationsDetails.is_cancel = false;
    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);
      if (this.route.snapshot.params.quote_type == 3) {
        this.indicativeCalculationsDetails.is_mta = true;
      }
      if (this.route.snapshot.params.quote_type == 4) {
        this.indicativeCalculationsDetails.is_cancel = true;
      }

    } else {
      this.quote_type = Number(this.quote.type);
    }


    // GET POLICY MTA REASONS
    if (this.route.snapshot.params.quote_type) {
      this.getPolicyReasons();
    }

    if (this.sessionSt.retrieve('company_role') != 'Broker') {
      this.indicativeCalculationsDetails.broker_commission = 0;
      // Get All Brokers
      this.quoteService.getBrokers(5)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.brokers = response.result;


              if (this.quote.company_id && this.brokers) {
                let brokers_data = this.brokers.filter(x => x.id == this.quote.company_id);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }

    // Get All Brokers
    this.quoteService.getTitleList()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.titleList = response.result;
          }
        });


  }

  firstnameToTotleCase(event) {
    this.quote.policy_holder.first_name = this.titlecasePipe.transform(event.target.value);
  }
  lastnameToTotleCase(event) {
    this.quote.policy_holder.last_name = this.titlecasePipe.transform(event.target.value);
  }
  horsenameToTotleCase(event) {
    this.quote.horse_details.name = this.titlecasePipe.transform(event.target.value);
  }
  /*
  * Set Cover Detail Values On Full Details Tab
  */
  setCoverDetailsFullQuoteTab() {
    this.quote.full_vet_fee_cover = '';
    this.quote.full_life_saving_surgery = '';
    this.quote.full_vet_fee_excess = '';
    this.quote.full_public_liability = '';
    this.quote.full_personal_accident = '';
    this.quote.primaryCover = '';
    // Class Of Use 
    if (this.classOfUse) {
      let horseClass = this.classOfUse.filter(x => x.id == this.quote.horse_class_id);
      if (horseClass.length > 0) {
        this.quote.full_class_of_use = horseClass[0].name;
        let subclass = horseClass[0].sub_classes.filter(x => x.id == this.quote.horse_class_sub_id);
        if (subclass.length > 0) {
          this.quote.primaryCover = subclass[0].name;
        }
      }
    }
    // BloodStock
    if (this.bloodStocks) {
      let bloodstockDetails = this.bloodStocks.filter(x => x.id == this.quote.blood_stock_id);
      if (bloodstockDetails.length > 0) {
        this.quote.primaryCover = bloodstockDetails[0].name;
      }
    }
    // Policy Cover
    if (this.policyCover) {
      let policy_cover_data = this.policyCover.filter(x => x.id == this.quote.policy_cover_id);
      if (policy_cover_data.length > 0) {
        this.quote.full_policy_cover = policy_cover_data[0].name;
      } else {
        this.quote.full_policy_cover = '';
      }
    }
    // Vet Fee Cover
    if (this.vetFeesCover) {
      let vetFeesCoverData = this.vetFeesCover.filter(x => x.id == this.quote.vet_fee_sum_insured_id);
      if (vetFeesCoverData.length > 0) {
        this.quote.full_vet_fee_cover = vetFeesCoverData[0].description;
        //vetFeesCoverData[0].is_checked = true;
      } else {
        this.quote.full_vet_fee_cover = ''
      }
    }
    // Life Saving Surgery
    if (this.vetFeeCoverSpecial && this.quote.horse_details.age < 21) {
      this.quote.full_life_saving_surgery = '';
      this.indicativeCalculationsDetails.life_saving_surgery = 0;
      this.indicativeCalculationsDetails.annual_agg_life_saving = 0;
      if (this.quote.life_saving_annual_surgery_id && this.quote.horse_details.age < 17 && !this.BloodStockDetails) {
        let vetFeeCoverSpecial = this.vetFeeCoverSpecial.filter(x => x.id == this.quote.life_saving_annual_surgery_id);
        if (vetFeeCoverSpecial.length > 0) {
          this.quote.full_life_saving_surgery = vetFeeCoverSpecial[0].description;
          this.indicativeCalculationsDetails.annual_agg_life_saving = Number(vetFeeCoverSpecial[0].vet_indemnity_data.value);
        }
      }
      if (this.quote.life_saving_surgery_id && this.BloodStockDetails) {
        let vetFeeCoverSpecial = this.vetFeeCoverSpecial.filter(x => x.id == this.quote.life_saving_surgery_id);
        if (vetFeeCoverSpecial.length > 0) {
          this.quote.full_life_saving_surgery = vetFeeCoverSpecial[0].description;
          this.indicativeCalculationsDetails.life_saving_surgery = vetFeeCoverSpecial[0].vet_indemnity_data.value;
        }
      }

    }
    // Vet Fee Excess    
    if (this.vetFeeExcess) {
      let vetFeeExcess = this.vetFeeExcess.filter(x => x.id == this.quote.company_excess_id);
      if (vetFeeExcess.length > 0) {
        this.quote.full_vet_fee_excess = vetFeeExcess[0].excess_amount;
        vetFeeExcess[0].is_checked = true;
      } else {
        this.quote.full_vet_fee_excess = '';
      }
    }
    // Public liability
    if (this.publicLiability) {
      let public_liability = this.publicLiability.filter(x => x.id == this.quote.public_liability_cover_id);
      if (public_liability.length > 0) {
        this.quote.full_public_liability = public_liability[0].description;
        public_liability[0].is_checked = true;
      } else {
        this.quote.full_public_liability = '';
      }
    }
    // Personal Accident
    if (this.accidentCover) {
      let accident_cover = this.accidentCover.filter(x => x.id == this.quote.personal_accidental_cover_id);
      if (accident_cover.length > 0) {
        accident_cover[0].is_checked = true;
        this.quote.full_personal_accident = accident_cover[0].description;
      } else {
        this.quote.full_personal_accident = '';
      }
    }

  }

  /*
  * ENABLE NTU IN CASE OF CANCEL POLICY
  */
  enableNTU() {
    if (this.quote.policy_start_date && this.sessionSt.retrieve('company_role') == 'Broker') {
      let currentDate = new Date()
      let policy_start_date = new Date(this.quote.policy_start_date);
      var Difference_In_Time = currentDate.getTime() - policy_start_date.getTime();
      if (Difference_In_Time / (1000 * 3600 * 24) < 14) {
        this.indicativeCalculationsDetails.is_NTU = true;
      }
    } else {
      this.indicativeCalculationsDetails.is_NTU = true;
    }
  }

  /*
  * GET POLICY REASONS
  */
  getPolicyReasons() {
    this.policyService.getPolicyMTAReasons(environment.AJG_ID)
      .subscribe(
        (response: any) => {
          if (response.success) {
            // this.reasons = [];
            // response.result.types.forEach((res) => {

            //   if (res.product_id == environment.EQUINE_ID) {
            //     this.reasons.push(res);
            //   }
            // })
            this.policyReasons = response.result.types;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  /*
  * HELP TEXT POPUP
  */
  helptext = '';
  helpTextPopup(content, text) {
    const modalRef = this.modalService.open(content, { centered: true });
    this.helptext = text;
  }

  /*
  * Proceed To Full Details 
  */
  fullQuote = false;
  ProceedWithFullQuoteBtn(isValid: boolean, f: any) {
    if (isValid) {
      this.fullQuote = true;
    }


  }
  ProceedWithAEIOQuoteBtn(isValid: boolean, f: any) {
    if (isValid) {
      this.fullQuote = false;
    }


  }

  /*
  * Event on Checkboxes Change
  */
  onCheckboxChange(id, fieldValues) {
    fieldValues.forEach((res) => {
      if (id != res.id) {
        res.is_checked = false;
      }
    });

  }

  /*
  * On Policy Start Date Change
  */
  onPolicyStartDateChange(event) {
    this.calculations();
  }

  /*
  * On Change Broker Dropdown
  */
  onChangeBroker(event) {
    this.getBrokerCommissionById(event.value)
  }


  //Get Broker Commissions
  isBrokerCommissionReduced: boolean = false;
  getBrokerCommissionById(id) {
    let commission_percentage = 0;
    let uw_fees = 0;
    if (id) {
      this.quoteService.getLiveStockBrokerCommission(id, environment.AJG_ID)
        .subscribe(
          (response: any) => {
            if (response.success) {
              let brokers_data = response.result;
              if (brokers_data != null) {
                commission_percentage = brokers_data.broker_commission;
                uw_fees = brokers_data.uw_fee;
                this.quote.company_id = id;

                this.slider_broker_comm_max = Number(commission_percentage);
                this.max_ppa_comission = Number(brokers_data.pay_away_commission);
                this.max_prt_comission = Number(brokers_data.retained_commission);
                this.max_uw_fee = Number(uw_fees);

                this.indicativeCalculationsDetails.actual_broker_commission = Number(commission_percentage);
                this.indicativeCalculationsDetails.actual_peliwica_pay_away_commission = Number(brokers_data.pay_away_commission);
                this.indicativeCalculationsDetails.actual_peliwica_retained_commission = Number(brokers_data.retained_commission);

                if (!this.route.snapshot.params.id && !this.quote.id) {
                  this.quote.financial_details.broker_commission = Number(commission_percentage);
                  this.quote.financial_details.peliwica_pay_away_commission = Number(brokers_data.pay_away_commission);
                  this.quote.financial_details.peliwica_retained_commission = Number(brokers_data.retained_commission);
                  this.quote.financial_details.premium_loading_percentage = Number(brokers_data.premium_loading);

                  this.indicativeCalculationsDetails.broker_commission = Number(commission_percentage);
                  this.indicativeCalculationsDetails.peliwica_pay_away_commission = Number(brokers_data.pay_away_commission);
                  this.indicativeCalculationsDetails.peliwica_retained_commission = Number(brokers_data.retained_commission);
                  this.indicativeCalculationsDetails.premium_loading_percentage = Number(brokers_data.premium_loading);

                  this.indicativeCalculationsDetails.full_underwriting_fee = Number(uw_fees);
                  this.indicativeCalculationsDetails.aeio_underwriting_fee = Number(uw_fees);
                } else {
                  this.indicativeCalculationsDetails.broker_commission = Number(this.quote.financial_details.broker_commission);
                  this.indicativeCalculationsDetails.peliwica_pay_away_commission = Number(this.quote.financial_details.peliwica_pay_away_commission);
                  this.indicativeCalculationsDetails.peliwica_retained_commission = Number(this.quote.financial_details.peliwica_retained_commission);
                  this.indicativeCalculationsDetails.premium_loading_percentage = Number(this.quote.financial_details.premium_loading_percentage);

                  this.indicativeCalculationsDetails.full_underwriting_fee = Number(this.quote.financial_details.uw_fee);
                  this.indicativeCalculationsDetails.aeio_underwriting_fee = Number(this.quote.financial_details.uw_fee);
                }

                if (this.quote.financial_details.broker_commission != brokers_data.broker_commission) {
                  this.isBrokerCommissionReduced = true;
                }
                this.calculations();
              }
            }
          });
    }
  }

  /*
  * On Change Horse Value
  * 1: Leisure
  * 2: Sports
  */
  leisure_sport_type: number = 0;
  //PolicyCoverFiltered = [];
  vetFeeExcessFiltered = [];
  policyCoverAddons = [];
  DisposalEuthansia;
  ElectiveSurgery;
  LiveryHospitalisation;
  LiveryHospitalisation_ID: string = '';
  Transportation_ID: string = '';
  Transportation;
  horseValueChange(event, content) {
    this.getAllFields();
    if (event.target.value == 0) {
      this.quote.horse_details.value = null;
      this.helpTextPopup(content, "The Sum Insured must be greater than £0");
    }
    if (event.target.value > 50000) {
      this.helpTextPopup(content, "As you have entered a Sum Insured of over £50,000, this quotation will need to be referred to Insurers before the full terms and premium can be advised. You should disregard the indication premium shown and await the confirmed quotation.");
    }
  }

  // Filter Fields
  getAllFields() {
    let DisposalEuthansia_ID = '';
    let ElectiveSurgery_ID = '';
    this.LiveryHospitalisation_ID = '';
    this.Transportation_ID = '';
    if (this.quote.horse_details.value < 5000) {
      this.leisure_sport_type = 1;
      DisposalEuthansia_ID = '805c13db-2395-4020-9f30-57ccc9402105';
      ElectiveSurgery_ID = '804c13db-2395-4020-9f30-57ccc9402104';
      this.LiveryHospitalisation_ID = '813b79ee-3c13-4fb4-96fc-3f79e8ce0003';
      this.Transportation_ID = '816b79ee-3c13-4fb4-96fc-3f79e8ce0004';
      this.quote.is_aeio = (this.quote.is_aeio == 0) ? 2 : (this.quote.is_aeio == 3) ? 1 : this.quote.is_aeio;
    } else {
      this.leisure_sport_type = 2;
      DisposalEuthansia_ID = '855c13db-2395-4020-9f30-57ccc9402105';
      ElectiveSurgery_ID = '854c13db-2395-4020-9f30-57ccc9402104';
      this.LiveryHospitalisation_ID = '866b79ee-3c13-4fb4-96fc-3f79e8ce0114';
      this.Transportation_ID = '867b79ee-3c13-4fb4-96fc-3f79e8ce0115';
      this.quote.is_aeio = 3;
    }
    // Filter Class of Use
    // if (this.leisure_sport_type == 1) {
    //   this.classOfUse = this.allClassOfUse.filter(x => x.id != '6d182464-83e5-4a5c-9347-a528d6ad4cb1' && x.id != '94d4c44d-45c5-4d30-b4b5-d7cb92c51d65');

    // } else {
    //   this.classOfUse = this.allClassOfUse;
    // }

    // Filter Policy Cover 
    // this.PolicyCoverFiltered = this.policyCover.filter(x => x.type == this.leisure_sport_type);
    // this.PolicyCoverFiltered.forEach((res, index) => {
    //   if (index == 0) {
    //     this.quote.policy_cover_id = res.id;
    //     this.quote.full_policy_cover = res.name;
    //     let DisposalEuthansia = res.section_indeminity_limit.filter(x => x.id == DisposalEuthansia_ID);
    //     if (DisposalEuthansia.length > 0) {
    //       this.DisposalEuthansia = DisposalEuthansia[0];
    //     }
    //     let ElectiveSurgery = res.section_indeminity_limit.filter(x => x.id == ElectiveSurgery_ID);
    //     if (ElectiveSurgery.length > 0) {
    //       this.ElectiveSurgery = ElectiveSurgery[0];
    //     }
    //   }

    // });

    // Filter Vet Fee Cover
    this.filterVetFeeCover();


    // Filter Vet Fee Excess 
    this.getVetFeeExcess();
    //this.vetFeeExcessFiltered = this.vetFeeExcess.filter(x => x.type == this.leisure_sport_type);

    this.mark2500LSSSelected();
    this.calculations();
  }

  // Assign Values To Fields
  setValuesToFields() {
    if (this.quote.id && this.quote.is_disposal_euthansia == 1) {
      this.setDisposalEuthansia();
    }
    if (this.quote.id && this.quote.is_elective_surgery == 1) {
      this.setElectiveSurgery();
    }
    if (this.quote.id && this.quote.is_livery_hispitalization == 1) {
      this.setLiveryHospitalisation();
    }

    if (this.vetFeesCover) {
      let vetFeesCoverData = this.vetFeesCover.filter(x => x.id == this.quote.vet_fee_sum_insured_id);
      if (vetFeesCoverData.length > 0) {
        vetFeesCoverData[0].is_checked = true;

      }
    }

    // this.vetFeeExcessFiltered.forEach((res) => {
    //   if (res.id == this.quote.company_excess_id) {
    //     res.is_checked = true;
    //   } else {
    //     res.is_checked = false;
    //   }
    // })

    if (this.quote.life_saving_annual_surgery_id) {
      let vetFeeCoverSpecial = this.vetFeeCoverSpecial.filter(x => x.id == this.quote.life_saving_annual_surgery_id);
      if (vetFeeCoverSpecial.length > 0) {
        vetFeeCoverSpecial[0].is_checked = true;
      }
    }
    this.calculations();
  }

  // On Quote Cover Type Change
  quoteCoverTypeChange(event) {
    if (this.quote.horse_details.value < 5000) {
      if (event.source.value == 1) {
        // this.quote.is_aeio = 1;
        this.BloodStockDetails = false;
        if (this.quote.horse_class_id == "g25b2769-5a42-416e-b361-f032704e6dce") {
          this.quote.horse_class_id = "f7684102-61f1-4bfe-8b8d-e89788bce134";
          let class_of_use = this.classOfUse.filter(x => x.id == this.quote.horse_class_id);
          if (class_of_use.length > 0) {
            this.quote.full_class_of_use = class_of_use[0].name;
            this.horseSubClasses = class_of_use[0].sub_classes;
          }
        }
      }
      // else {
      //   this.quote.is_aeio = 2;
      // }
    }
    // else {
    //   this.quote.is_aeio = 3;
    // }
    this.filterVetFeeCover();
  }


  filterVetFeeCover() {
    // let policyCover = this.allPolicyCovers.filter(x => x.type == this.leisure_sport_type && x.is_vet_fee_cat == 1);
    // if (policyCover.length > 0) {
    //   this.vetFeesCover = [];
    //   let SectionIndemnityLimit = policyCover[0].section_indeminity_limit.filter(x => x.id != this.LiveryHospitalisation_ID && x.is_special_vet == 0 && x.vet_fee_cover_index > 0 && x.is_aeio == ((this.quote.is_aeio == 1) ? 1 : 0));
    //   this.vetFeesCover = SectionIndemnityLimit;
    //   let SectionIndemnityLimitAddons = policyCover[0].section_indeminity_limit.filter(x => x.id == this.LiveryHospitalisation_ID);
    //   if (SectionIndemnityLimitAddons.length > 0) {
    //     this.LiveryHospitalisation = SectionIndemnityLimitAddons[0];
    //   }
    //   let SectionIndemnityLimitAddonsTransportation = policyCover[0].section_indeminity_limit.filter(x => x.id == this.Transportation_ID);
    //   if (SectionIndemnityLimitAddonsTransportation.length > 0) {
    //     this.Transportation = SectionIndemnityLimitAddonsTransportation[0];
    //   }
    //   let LifeSavingSurgery = policyCover[0].section_indeminity_limit.filter(x => x.is_special_vet == 1);
    //   this.vetFeeCoverSpecial = LifeSavingSurgery;

    // } else {
    //   this.vetFeesCover = [];
    //   this.LiveryHospitalisation = '';
    //   this.Transportation = '';
    //   this.vetFeeCoverSpecial = [];
    // }
  }

  lifeSavingSurgerySelection() {
    // 2500 Life Saving Surgery/Colic Selection
    this.quote.life_saving_annual_surgery_id = null;
    this.quote.full_life_saving_surgery = null;
    if (this.quote.rating_version == 1) {
      if (this.vetFeeCoverIndex == 2 && this.quote.horse_details.value > 2500) {
        let vetFeeCoverSpecial = this.vetFeeCoverSpecial.filter(x => x.life_saving_index == 2);
        if (vetFeeCoverSpecial.length > 0) {
          this.quote.life_saving_annual_surgery_id = vetFeeCoverSpecial[0].id;
          this.quote.full_life_saving_surgery = vetFeeCoverSpecial[0].description;
        }
      }
    } else {
      if (this.quote.horse_details.value >= 5000 && this.quote.horse_details.age <= 16 && this.quote.vet_fee_sum_insured_id && !this.quote.life_saving_annual_surgery_id) {
        let vetFeeCoverSpecial = this.vetFeeCoverSpecial.filter(x => x.life_saving_index == 2);
        if (vetFeeCoverSpecial.length > 0 && vetFeeCoverSpecial[0].is_checked) {
          this.quote.life_saving_annual_surgery_id = vetFeeCoverSpecial[0].id;
          this.quote.full_life_saving_surgery = vetFeeCoverSpecial[0].description;
        }
      }

    }
  }

  /*
  * On Quote Type Change
  */
  onQuoteTypeChange(event) {
    this.quote_type = event.value;
    // this.tigerlabfield = false;
    // if (this.quote_type == 2) {
    //   this.tigerlabfield = true;
    // }
    this.showHideAEIOSection(this.quote_type);
  }

  /*
  * On Horse Age Change
  */
  onHorseAgeChange(event, content) {
    if (this.route.snapshot.params.quote_type == 3) {
      this.showHideAEIOSection(this.quote.type);
    } else {
      this.showHideAEIOSection(this.quote_type);
    }

    if (this.quote.horse_details.age > 17) {
      this.quote.life_saving_annual_surgery_id = null;
    }
    if (event.value >= 15) {
      this.helpTextPopup(content, "Due to the age of the horse (15 years +), this quotation will need to be referred to Insurers before the full terms and premium can be advised. You should disregard the indication premium shown and await the confirmed quotation.");
    }
  }


  hide7500VetFee: boolean = false;
  showHideAEIOSection(quote_type) {
    // if (this.leisure_sport_type == 1) {
    if (this.quote.horse_details.age > 20 && (quote_type == 1 || quote_type == 3)) {
      this.quote.full_quote_section = false;
      this.quote.aeio_quote_section = true;
      this.quote.is_aeio = 1;
      this.hide7500VetFee = true;
      this.leisure_sport_type = 1
    }
    if (this.quote.horse_details.age > 25 && quote_type == 2) {
      this.quote.full_quote_section = false;
      this.quote.aeio_quote_section = true;
      this.quote.is_aeio = 1;
      this.hide7500VetFee = true;
      this.leisure_sport_type = 1;
    }
    // else {
    //   this.quote.full_quote_section = true;
    //   this.quote.aeio_quote_section = true;
    //   this.quote.is_aeio = 2;
    //   this.hide7500VetFee = false;
    // }
    // }

    this.mark2500LSSSelected();
    this.calculations();
  }

  /*
  * On Change Class OF Use
  */
  class_type: number = 0;
  classOfUseOnChange(event, content) {
    this.quote.blood_stock_id = '';
    this.quote.hide_sections_for_blood_stock = true;
    let class_of_use = this.classOfUse.filter(x => x.id == event.value);
    let classType = 0;
    if (class_of_use.length > 0) {
      this.quote.full_class_of_use = class_of_use[0].name;
      this.horseSubClasses = class_of_use[0].sub_classes;
      classType = class_of_use[0].class;
      this.class_type = class_of_use[0].class;
      if (classType == 4) {
        this.helpTextPopup(content, "Due to Class of Use 4 being selected, this quotation will need to be referred to Insurers before the full terms and premium can be advised. You should disregard the indication premium shown and await the confirmed quotation.");
      }
      if (classType == 5) {
        this.clearSections();
        this.BloodStockDetails = true;
        this.vetFeesCover = this.vetFeesCoverBloodStock;
        this.vetFeesCoverBloodStock.forEach((res) => {
          // res.is_checked = true;
        });
        this.vetFeeExcess.forEach((res, index) => {
          if (index == 0) {
            res.is_checked = true;
          } else {
            res.is_checked = false;
          }

        });
        this.disableVetFeeExcess = true;
        this.quote.blood_stock_id = '';
        this.quote.hide_sections_for_blood_stock = true;
      } else {
        this.BloodStockDetails = false;
        this.vetFeesCover = this.vetFeesCoverData;
        this.disableVetFeeExcess = false;
        this.quote.hide_sections_for_blood_stock = false;
      }
      this.calculations();
    }
  }

  clearSections() {
    // Policy Cover
    // this.policyCover.forEach((res, index) => {
    //   if (index == 0) {
    //     this.quote.policy_cover_id = res.id;
    //     this.quote.full_policy_cover = res.name;
    //   }
    // });

    // Vet Fee Cover
    this.quote.vet_fee_sum_insured_id = '';
    this.quote.full_vet_fee_cover = '';
    this.vetFeeCoverIndex = null;
    this.vetFeesCover.forEach((res) => {
      res.is_checked = false;
    })

    // Life Saving Surgery
    this.indicativeCalculationsDetails.life_saving_surgery = 0;
    this.indicativeCalculationsDetails.annual_agg_life_saving = 0;
    this.indicativeCalculationsDetails.is_life_saving_surgery = false;
    this.quote.life_saving_surgery_id = '';
    this.quote.life_saving_annual_surgery_id = '';

    // Co-Insurance
    this.quote.company_co_pay_excess_id = '';
    this.quote.company_co_insurance = false;
    this.quote.company_co_pay_discount = 0;

    // Vet Fee Excess
    this.quote.company_excess_id = '';
  }

  /*
  *
  */
  horseSubClassOnChange(event) {
    let horseSubClasses = this.horseSubClasses.filter(x => x.id == event.value);
    if (horseSubClasses.length > 0) {
      this.quote.primaryCover = horseSubClasses[0].name;
    }
  }
  /*
  * On Change Blood Stock
  */
  hideLSS: boolean = false;
  onBloodStockChange(event) {
    let bloodstockDetails = this.bloodStocks.filter(x => x.id == event.value);
    if (bloodstockDetails.length > 0) {
      this.quote.primaryCover = bloodstockDetails[0].name;
    }
    if (event.value == '70160001-d71f-4ac0-b92f-e137dbbcddf5') {    // Hurdles
      this.hideLSS = true;
    } else {
      this.hideLSS = false;
    }
    this.calculations();
  }

  /*
  * On Change Policy Cover
  */
  PolicyCoverDropDown;
  policyCoverChange(event, id = '', content) {
    let policy_cover_id = event.value;
    if (id != '') {
      policy_cover_id = id;
    }
   // POP Up on LOU
    if (event.value == "0002e4b3-0866-4938-bc80-e6bc2a4b4bc8" || event.value == '2002e4b3-0866-4938-bc80-e6bc2a4b4bc8') {
      this.helpTextPopup(content, "This quotation will need to be referred to Insurers before the full terms and premium can be advised. You should disregard the indication premium shown and await the confirmed quotation.");
    }



    let policy_cover_data = this.policyCover.filter(x => x.id == policy_cover_id);

    if (policy_cover_data.length > 0) {
      this.quote.full_policy_cover = policy_cover_data[0].name;
    }
    if (this.quote.vet_fees_cover_id) {
      this.quote.vet_fee_sum_insured_id = this.quote.vet_fees_cover_id;
    }
    this.getMinimumPremiums();
    this.calculations();

  }

  getMinimumPremiums() {
    // let minimum_premium = this.minimumPolicyPremiums.filter(x => x.policy_cover_detail_id == this.quote.policy_cover_id && x.section_indemnity_limit_linking_id == null);
    // if (minimum_premium.length > 0) {
    //   this.indicativeCalculationsDetails.policy_minimum_premium = minimum_premium[0].amount;
    // } else {
    //   this.indicativeCalculationsDetails.policy_minimum_premium = this.minimumPolicyPremiums[0].amount;
    // }
  }
  /*
  * Set Disposal/Euthansia Cost
  */
  onDisposalEuthansiaChange(event) {
    if (event.checked) {
      this.setDisposalEuthansia();
    } else {
      this.indicativeCalculationsDetails.disposal_euthansia_cost = 0;
    }
    this.calculations();
  }

  // Set Values To Disposal/Euthansia
  setDisposalEuthansia() {
    if (this.DisposalEuthansia) {
      let policy_indemnity_data = this.DisposalEuthansia.policy_indemnity_data;
      let data = policy_indemnity_data.filter(x => x.class_id == this.quote.horse_class_id);
      if (data.length > 0) {
        this.indicativeCalculationsDetails.disposal_euthansia_cost = Number(data[0].value);
      }
    }
  }

  /*
  * Set Elective Surgery Cost
  */
  onElectiveSurgeryChange(event) {
    if (event.checked) {
      this.setElectiveSurgery();
    } else {
      this.indicativeCalculationsDetails.elective_surgery_ga_cover = 0;
    }
    let minimum_premium = this.minimumPolicyPremiums.filter(x => x.policy_cover_detail_id == this.quote.policy_cover_id && x.section_indemnity_limit_linking_id == '854c13db-2395-4020-9f30-57ccc9402104');
    if (minimum_premium.length > 0) {
      this.indicativeCalculationsDetails.policy_minimum_premium = minimum_premium[0].amount;
    } else {
      this.indicativeCalculationsDetails.policy_minimum_premium = this.minimumPolicyPremiums[0].amount;
    }
    this.calculations();
  }

  /*
  * Set Elective Surgery values
  */
  setElectiveSurgery() {
    if (this.ElectiveSurgery) {
      let policy_indemnity_data = this.ElectiveSurgery.policy_indemnity_data;
      let data = policy_indemnity_data.filter(x => x.class_id == this.quote.horse_class_id);
      if (data.length > 0) {
        this.indicativeCalculationsDetails.elective_surgery_ga_cover = Number(data[0].value);
      }
    }
  }
  /*
  * Set Livery/Hospitalisation Cost
  */
  onLiveryHospitalisationChange(event) {
    if (event.checked) {
      this.setLiveryHospitalisation();
    } else {
      this.indicativeCalculationsDetails.livery_hospitalisation_emegency_transportation = 0;
    }
    this.calculations();
  }
  /*
  * Set Livery/Hospitalisation Value
  */
  setLiveryHospitalisation() {
    if (this.LiveryHospitalisation) {
      let policy_indemnity_data = this.LiveryHospitalisation.policy_indemnity_data;
      let data = policy_indemnity_data.filter(x => x.class_id == this.quote.horse_class_id);
      if (data.length > 0) {
        this.indicativeCalculationsDetails.livery_hospitalisation_emegency_transportation = Number(data[0].value);
      }
    }
  }


  /*
  * Set Transportation Cost
  */
  onTransportationChange(event) {
    if (event.checked) {
      let policy_indemnity_data = this.Transportation.policy_indemnity_data;
      let data = policy_indemnity_data.filter(x => x.class_id == this.quote.horse_class_id);
      if (data.length > 0) {
        this.indicativeCalculationsDetails.transportation = Number(data[0].value);
      }
    } else {
      this.indicativeCalculationsDetails.transportation = 0;
    }
    this.calculations();
  }

  /*
  * Age Load Percentage
  */
  age_load_percentage_value = 0;
  ageLoadPer(id) {
    if (id) {
      let policy_cover_data = this.policyCover.filter(x => x.id == id);
      if (policy_cover_data.length > 0) {
        policy_cover_data[0].section_indeminity_limit.forEach((res) => {
          let age_load = res.section_age_loading_data.filter(x => x.section_indem_limit_link_id == res.id);
          let age_load_percentage = age_load.filter(x => x.lower_age <= this.quote.horse_details.age && x.upper_age >= this.quote.horse_details.age);
          if (age_load_percentage.length > 0) {
            this.age_load_percentage_value = age_load_percentage[0].percentage_value;
          } else {
            this.age_load_percentage_value = 0;
          }
        });
      }
    }
  }

  /*
  * On Change Vet Fee Cover
  */
  vetFeeCoverIndex: number = 0;
  onVetFeeCoverChange(id, vetFeesCover, event) {
    this.vetFeeCoverIndex = 0;
    this.checkboxRequired = false;   // For vet fee excess
    if (event.checked) {
      this.quote.vet_fee_sum_insured_id = event.source.value;
      this.quote.vet_fees_cover_id = id;
      this.vetFeeExcess.forEach((res, index) => {
        if (index == 0) {
          res.is_checked = true;
          this.quote.company_excess_id = res.id;
        }
      });

    } else {
      this.quote.full_vet_fee_cover = '';
      this.quote.vet_fee_sum_insured_id = '';
      this.quote.company_excess_id = '';

      this.quote.life_saving_surgery_id = null;
      this.quote.life_saving_annual_surgery_id = null;
      // Show AEIO Section If unchecked options
      this.quote.indicativePage = true;
      this.quote.aeio_quote_section = true;
    }
    this.mark2500LSSSelected();
    this.calculations();
  }

  /*
  * Filter Vet Fee Excess
  */
  getVetFeeExcess() {
    // let id = this.quote.vet_fee_sum_insured_id;
    // let vetFeesCoverData = this.vetFeesCover.filter(x => x.id == id);
    // if (vetFeesCoverData.length > 0) {
    //   // 3500 per incident
    //   if (id == '8112bca6-de1e-4c0c-9f3c-199c397c0001') {
    //     this.vetFeeExcessFiltered = this.vetFeeExcess.filter(x => x.type == this.leisure_sport_type && x.id != '9289390c-65d3-4d10-b277-b12eedea0fe0');
    //   }

    //   // 5000 per incident
    //   if (id == '812b79ee-3c13-4fb4-96fc-3f79e8ce0002') {
    //     this.vetFeeExcessFiltered = this.vetFeeExcess.filter(x => x.id == '9289390c-65d3-4d10-b277-b12eedea0fe0');
    //   }

    //   // For AEIO
    //   if (this.quote.is_aeio == 1) {
    //     this.vetFeeExcessFiltered = this.vetFeeExcess.filter(x => x.is_aeio == 1);
    //   }
    //   // For Sports
    //   if (this.leisure_sport_type == 2) {
    //     this.vetFeeExcessFiltered = this.vetFeeExcess.filter(x => x.type == this.leisure_sport_type);
    //   }
    //   this.vetFeeCoverIndex = vetFeesCoverData[0].vet_fee_cover_index;
    //   if (this.vetFeeCoverIndex == 3) {
    //     this.vetFeeExcess.forEach((res, index) => {
    //       if (index == 1) {
    //         //  this.quote.company_excess_id = res.id;
    //         res.is_checked = true;
    //       } else {
    //         res.is_checked = false;
    //       }
    //     });
    //     // Disappear AEIO Section If 7500 selected
    //     this.quote.is_aeio = 0;
    //     this.quote.aeio_quote_section = false;
    //     this.quote.indicativePage = false;
    //   } else {
    //     if (!this.quote.id) {
    //       this.vetFeeExcess.forEach((res, index) => {
    //         if (res.id == '90816c2a-e93a-48cd-a6a2-7b58252589b5') {
    //           this.quote.company_excess_id = res.id;
    //           res.is_checked = true;
    //         } else {
    //           res.is_checked = false;
    //         }
    //       });
    //     }
    //     // Show FULL Quote Section If 3000/5000 selected
    //     if (this.quote.horse_details.age < 21) {
    //       this.quote.full_quote_section = true;
    //     }

    //     // this.quote.is_aeio = 1;
    //     this.quote.aeio_quote_section = true;
    //     this.quote.indicativePage = true;
    //   }

    //   this.quote.full_vet_fee_cover = vetFeesCoverData[0].description;

    //   // 2500 Life Saving Surgery/Colic Selection
    //   // this.lifeSavingSurgerySelection();
    // }

  }


  /*
  * On Vet Fee Cover Special Change
  */
  is2500selected: boolean = false;
  mark2500LSSSelected() {
    // this.is2500selected = false;
    // switch (this.quote.rating_version) {
    //   case 1:
    //     if (this.quote.horse_details.value > 2500 && this.vetFeeCoverIndex == 2) {
    //       this.is2500selected = true;
    //     }
    //     break;
    //   case 2:
    //     if (this.quote.horse_details.value >= 5000 && this.quote.horse_details.age <= 16 && this.quote.vet_fee_sum_insured_id && this.quote.life_saving_annual_surgery_id) {
    //       this.is2500selected = true;
    //     } else {
    //       if (this.quote.life_saving_annual_surgery_id) {
    //         this.is2500selected = true;
    //       }
    //     }
    //     break;
    //   default:
    //     if (this.quote.is_aeio == 1) {
    //       if (this.quote.life_saving_annual_surgery_id) {
    //         this.is2500selected = true;
    //       } else {
    //         this.is2500selected = false;
    //       }
    //     } else {
    //       if (this.quote.horse_details.value >= 5000 && this.quote.horse_details.age <= 16 && this.quote.vet_fee_sum_insured_id) {
    //         this.is2500selected = true;
    //       } else {
    //         if (this.quote.life_saving_annual_surgery_id) {
    //           this.is2500selected = true;
    //         }
    //       }
    //     }
    //     break;
    // }

    // if (this.is2500selected) {
    //   let vetFeeCoverSpecial = this.vetFeeCoverSpecial.filter(x => x.life_saving_index == 2);
    //   if (vetFeeCoverSpecial.length > 0) {
    //     this.quote.life_saving_annual_surgery_id = vetFeeCoverSpecial[0].id;
    //     this.quote.full_life_saving_surgery = vetFeeCoverSpecial[0].description;
    //   }
    // }
    // else {
    //   this.quote.life_saving_annual_surgery_id = null;
    //   this.quote.full_life_saving_surgery = null;
    // }
  }
  onvetFeeCoverSpecialChange(life_saving_index, value, event, description) {
    //this.quote.life_saving_surgery_id = '';
    // this.quote.life_saving_annual_surgery_id = '';
    this.quote.full_life_saving_surgery = '';
    if (event.checked) {
      if (life_saving_index == 1) {
        this.is2500selected = false;
        this.quote.life_saving_surgery_id = event.source.value;
      } else {
        this.quote.life_saving_annual_surgery_id = event.source.value;
        this.is2500selected = true;
      }

      this.quote.full_life_saving_surgery = description;
    } else {
      this.quote.life_saving_surgery_id = '';
      this.quote.life_saving_annual_surgery_id = '';
      this.is2500selected = false;
    }

    this.calculations();
  }

  /*
  * On Change Vet Fee Excess
  */
  checkboxRequired: boolean = false;
  vetFeeExcessOnChange(amount, event, excess_amount) {
    this.quote.full_vet_fee_excess = '';
    this.checkboxRequired = false;

    if (event.checked) {
      this.quote.full_vet_fee_excess = excess_amount;
      this.quote.company_excess_id = event.source.value;
    } else {
      this.quote.company_excess_id = '';
      // if (this.quote.vet_fee_sum_insured_id) {
      //   this.checkboxRequired = true;
      // }
      // if (!this.quote.vet_fee_sum_insured_id) {
      //   this.quote.company_excess_id = '';
      // } else {
      //   this.checkboxRequired = true;
      // }
    }
    this.calculations();
  }

  /*
  * On Co-Insurance Change
  */
  onCoInsuranceChange(event) {
    this.quote.company_co_pay_excess_id = '';
    this.quote.veterinary_co_pay_id = '';
    this.quote.is_vet_fee_co_pay = 0;
    if (event.checked) {
      if (event.source.value == "94d0defd-daba-4da0-8e15-d500beb351e8") {
        this.quote.company_co_pay_excess_id = event.source.value;
        this.quote.is_vet_fee_co_pay = 0;
      } else {
        this.quote.is_vet_fee_co_pay = 1;
        this.quote.veterinary_co_pay_id = event.source.value;
      }
    }

    this.calculations();
  }

  /*
  * On Change Public Liability
  */
  onPublicLiabilityChange(amount, event, description) {
    this.quote.full_public_liability = '';
    this.quote.public_liability_cover_id = '';
    if (event.checked) {
      this.quote.public_liability_cover_id = event.source.value;
      this.quote.full_public_liability = description;
      this.indicativeCalculationsDetails.aeio_net_public_liability_amount = amount;
      this.indicativeCalculationsDetails.full_net_personal_accident_amount = amount;
    }
    this.calculations();
  }

  /*
  * On Change Personal Accident
  */
  onPersonalAccidentChange(amount, event, description) {
    this.quote.full_personal_accident = '';
    this.quote.personal_accidental_cover_id = '';
    if (event.checked) {
      this.quote.personal_accidental_cover_id = event.source.value;
      this.quote.full_personal_accident = description;
      this.indicativeCalculationsDetails.aeio_net_personal_accident_amount = amount;
      this.indicativeCalculationsDetails.full_net_personal_accident_amount = amount;
    }
    this.calculations();
  }

  /*
  * On Change Sadalery Tack
  */
  saddlery_tack($event) {
    if ($event.target.checked) {
      this.quote.does_addon_saddlery_on = 1;
    } else {
      this.quote.addon_saddlery_value = 0;
      this.quote.does_addon_saddlery_on = 0;
      this.quote.horse_details.saddlery_items = [];
    }
    this.calculations();
  }
  onChangeSaddlery(event) {
    this.calculations();
  }

  /*
  * On Change Trailer Cover
  */
  trailer_cover($event) {
    if ($event.target.checked) {
      this.quote.does_addon_trailer_on = 1;
    } else {
      this.quote.addon_trailer_value = 0;
      this.quote.does_addon_trailer_on = 0;
      this.quote.horse_details.trailor_items = [];
    }
    this.calculations();
  }
  onChangeTrailer(event) {
    this.calculations();
  }

  /*
  * Mortality Claim loading
  */
  onMortalityClaimLoadingChange(event, content) {
    if (event.value == '104635f8-c5c6-41ad-a75a-463f82b73a0e' || event.value == '105635f8-c5c6-41ad-a75a-463f82b73a0e') {
      this.modalService.open(content, { centered: true });
      this.helptext = 'This quotation will need to be referred to Insurers before the full terms and premium can be advised. You should disregard the indication premium shown and await the confirmed quotation.';
    }
    this.calculations();
  }
  /*
  * Veterinary Fees Claim History
  */
  onVetClaimLoadingChange(event, content) {
    if (event.value == '109635f8-c5c6-41ad-a75a-463f82b73a0e' || event.value == '110635f8-c5c6-41ad-a75a-463f82b73a0e') {
      this.modalService.open(content, { centered: true });
      this.helptext = 'This quotation will need to be referred to Insurers before the full terms and premium can be advised. You should disregard the indication premium shown and await the confirmed quotation.';
    }
    this.calculations();
  }


  /*
  * Commission Slider Number Formatter
  */
  formatSliderLabel(value: number) {
    return value + '%';
  }
  formatUWSliderLabel(value: number) {
    return '£' + value;
  }
  /*
  * On Broker Comission Slider Change
  */
  onBrokerComissionChange(event) {
    this.quote.financial_details.broker_commission = Number(event.value);
    this.indicativeCalculationsDetails.broker_commission = Number(event.value);
    this.calculations();
  }

  /*
   * On Peliwica Pay Away Comission Slider Change
   */
  onPPayAwayComissionChange(event) {
    this.quote.financial_details.peliwica_pay_away_commission = event.value;
    this.indicativeCalculationsDetails.peliwica_pay_away_commission = event.value;
    this.calculations();
  }
  /*
   * On Peliwica Retained Comission Slider Change
   */
  onPRetainedComissionChange(event) {
    this.quote.financial_details.peliwica_retained_commission = event.value;
    this.indicativeCalculationsDetails.peliwica_retained_commission = event.value;
    this.calculations();
  }

  /*
  * On Premium Loading Slider Change
  */
  onPreLoadingChange(event) {
    this.quote.financial_details.premium_loading_percentage = event.value;
    this.indicativeCalculationsDetails.premium_loading_percentage = event.value;
    this.calculations();
  }

  /*
  * On UW Fee Slider Change
  */
  onUWFeeChange(event) {
    if (event) {
      // this.quote.financial_details.uw_fee = event.value;
      this.indicativeCalculationsDetails.full_underwriting_fee = event.value;
      this.calculations();
    }

  }

  /*
  * Set Claim Discount Value
  */
  onClaimDiscountChange(event) {
    this.calculations();
  }
  /*
  * Set Customer Discount Value
  */
  onCustomerDiscountChange(event) {
    this.calculations();
  }


  /*
  * API - Get Policy Data By Horse Value
  */
  policyDataByHorseValue;
  getPolicyData(class_value, policy_cover_index, horse_value) {
    this.quoteService.getPolicyData(class_value, policy_cover_index, horse_value, this.quote.rating_version, environment.AJG_ID)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.policyDataByHorseValue = response.result;
            if (response.result) {
              response.result.forEach((res) => {
                if (class_value != 5) {
                  this.indicativeCalculationsDetails.full_class_net_rate = Number(res.value);
                  this.indicativeCalculationsDetails.full_calculate_data(this.mta_quote_data);
                }

              })
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
  * Ligerlab Premium Loading Change
  */
  ontTgerLabPremLoadingbChange(event) {
    this.quote.financial_details.tigerlab_premium_loading = event.target.value;
    this.indicativeCalculationsDetails.tigerlab_premium_loading = event.target.value;
    this.calculations();
  }

  // CALCULATIONS
  vetAgeLoadingAmount: number = 0;
  calculations(life_saving = '') {
    let horse_value: number = Number(this.quote.horse_details.value);
    let vet_fee_sum_insured_id = this.quote.vet_fee_sum_insured_id;
    let horse_age = Number(this.quote.horse_details.age);
    let company_excess_id = this.quote.company_excess_id;
    this.indicativeCalculationsDetails.mta_date = this.quote.policy_start_date;
    this.indicativeCalculationsDetails.life_saving_surgery = 0;
    this.indicativeCalculationsDetails.annual_agg_life_saving = 0;
    this.indicativeCalculationsDetails.is_life_saving_surgery = false;


    // let company_co_pay_excess_id = this.quote.company_co_pay_excess_id;
    let class_index = 0;
    let policy_cove_index = 0;
    this.initializeCalculationDetails();


    // Horse Value
    if (!horse_value) {
      horse_value = 0;
    }
    this.indicativeCalculationsDetails.sum_insured = horse_value;

    this.indicativeCalculationsDetails.vet_minimum_premium = Number(this.vet_minimum_premium);

    // IPT Fee
    this.indicativeCalculationsDetails.ipt_fee = Number(this.ipt_fee);

    // AEIO net rate
    this.indicativeCalculationsDetails.aeio_class_net_rate = Number(this.aeio_class_net_rate);

    // Horse Value Change
    if (!horse_value) {
      horse_value = 0;
    }
    this.indicativeCalculationsDetails.sum_insured = horse_value;
    this.policyDiscount.forEach((res) => {
      if (Number(horse_value) >= Number(res.horse_lower_amount) && Number(horse_value) <= Number(res.horse_upper_amount) && Number(horse_age) <= Number(res.maximum_age)) {
        this.indicativeCalculationsDetails.full_main_discount = Number(res.discount_percentage);
        this.indicativeCalculationsDetails.aeio_main_discount = Number(res.discount_percentage);
      } else {
        this.indicativeCalculationsDetails.full_main_discount = 0;
        this.indicativeCalculationsDetails.aeio_main_discount = 0;
      }
    });

    if (this.vetFeeCoverIndex == 2 && Number(horse_value) > 2500 && this.quote.rating_version == 1) {
      this.indicativeCalculationsDetails.full_vet_fee_amount = 0;
    }
    //} else {

    this.indicativeCalculationsDetails.life_saving_surgery = 0;
    if (vet_fee_sum_insured_id) {
      let vetFeesCoverData = this.vetFeesCover.filter(x => x.id == vet_fee_sum_insured_id);
      if (vetFeesCoverData.length > 0 && vetFeesCoverData[0].vet_indemnity_data) {
        let indemnity_amount = 0;
        vetFeesCoverData[0].vet_indemnity_data.forEach(res => {
          if (horse_value >= res.lower_bound && horse_value <= res.upper_bound && res.class_id == this.quote.horse_class_id && res.company_excess_id == company_excess_id) {
            indemnity_amount = Number(res.value);
          }
        })
        if (this.BloodStockDetails) {
          this.indicativeCalculationsDetails.life_saving_surgery = indemnity_amount;
        } else {
          this.indicativeCalculationsDetails.full_vet_fee_amount = indemnity_amount;
        }
      }
    }
    //}

    // Horse Age Change
    this.ageLoadPer(this.quote.policy_cover_id);

    // Main Class Use Change
    if (this.quote.horse_class_id && this.classOfUse) {
      let class_of_use = this.classOfUse.filter(x => x.id == this.quote.horse_class_id);

      if (class_of_use.length > 0) {
        class_index = class_of_use[0].class;
        if (class_of_use[0].class == 5) {
          this.indicativeCalculationsDetails.full_vet_fee_amount = 0;
        } else {
          this.indicativeCalculationsDetails.full_vet_fee_amount = Number(this.selected_full_vet_fee_amount);
        }
      }
    }

    // Bloodstock Change
    if (this.quote.blood_stock_id && this.bloodStocks) {
      let bloodStocks = this.bloodStocks.filter(x => x.id == this.quote.blood_stock_id);

      if (bloodStocks.length > 0) {
        this.quote.blood_stock_arm_linking_id = bloodStocks[0].arm_linking.id;
        this.quote.blood_stock_vet_fee_linking_id = bloodStocks[0].vet_fee_linking.id;
        this.indicativeCalculationsDetails.full_vet_fee_amount = Number(bloodStocks[0].vet_fee_linking.arm_value);
        this.indicativeCalculationsDetails.full_class_net_rate = Number(bloodStocks[0].arm_linking.arm_value);
        this.indicativeCalculationsDetails.full_vet_fee_excess_discount = 0;
      }
    }

    // Policy Cover Change
    this.indicativeCalculationsDetails.class_minimum_premium = 0;
    this.indicativeCalculationsDetails.life_saving_surgery = 0;
    let policy_cover_id = this.quote.policy_cover_id;
    if (policy_cover_id && this.policyCover) {
      let policy_cover_data = this.policyCover.filter(x => x.id == policy_cover_id);
      if (policy_cover_data.length > 0) {
        policy_cove_index = policy_cover_data[0].cover_index;
        // Minimum Premium
        if (policy_cover_data[0].minimum_premium && policy_cover_data[0].minimum_premium.length > 0) {
          let minimum_premium = policy_cover_data[0].minimum_premium.filter(x => x.policy_cover_detail_id == policy_cover_id);
          if (minimum_premium != null) {
            this.indicativeCalculationsDetails.class_minimum_premium = Number(minimum_premium[0].amount);
          }
        }
      }
    }
    this.policyDiscount.forEach((res) => {
      if (Number(horse_value) >= Number(res.horse_lower_amount) &&
        Number(horse_value) <= Number(res.horse_upper_amount) && Number(horse_age) <= Number(res.maximum_age)) {
        this.indicativeCalculationsDetails.full_main_discount = res.discount_percentage;
        this.indicativeCalculationsDetails.aeio_main_discount = res.discount_percentage;
      } else {
        this.indicativeCalculationsDetails.full_main_discount = 0;
        this.indicativeCalculationsDetails.aeio_main_discount = 0;
      }
    });

    // Mortality Claim Loading
    if (this.quote.mortality_claim_loading_id) {
      let mortality = this.MortalityClaimLoading.filter(x => x.id == this.quote.mortality_claim_loading_id);
      if (mortality.length > 0) {
        this.indicativeCalculationsDetails.mortality_claim_loading_percentage = mortality[0].claim_loading;
      } else {
        this.indicativeCalculationsDetails.mortality_claim_loading_percentage = 0;
      }
    } else {
      this.indicativeCalculationsDetails.mortality_claim_loading_percentage = 0;
    }

    // Vet Fee Cover Change
    if (vet_fee_sum_insured_id && this.vetFeesCover) {
      let vetFeesCoverData = this.vetFeesCover.filter(x => x.id == vet_fee_sum_insured_id);
      if (vetFeesCoverData.length > 0) {
        if (vetFeesCoverData[0].is_blood_stock == 0) {
          let vet_indemnity_amount = 0;
          vetFeesCoverData[0].vet_indemnity_data.forEach(res => {
            if (horse_value >= res.lower_bound && horse_value <= res.upper_bound && res.class_id == this.quote.horse_class_id && res.company_excess_id == company_excess_id) {
              vet_indemnity_amount = Number(res.value);
            }
          })


          this.selected_full_vet_fee_amount = Number(vet_indemnity_amount);
          this.indicativeCalculationsDetails.full_vet_fee_amount = Number(vet_indemnity_amount);
        } else {
          this.indicativeCalculationsDetails.full_vet_fee_amount = 0;
          this.selected_full_vet_fee_amount = 0;
        }
        if (vetFeesCoverData[0].aeio_vet_data != null) {
          this.indicativeCalculationsDetails.aeio_vet_fee_amount = Number(vetFeesCoverData[0].aeio_vet_data.value);
        } else {
          this.indicativeCalculationsDetails.aeio_vet_fee_amount = 0;
        }

        let amount = 0;
        vetFeesCoverData[0].section_age_loading_data.forEach(res1 => {
          if (Number(horse_age) >= Number(res1.lower_age) && Number(horse_age) <= Number(res1.upper_age)) {
            amount = Number(res1.percentage_value);
          }
        });
        this.vetAgeLoadingAmount = Number(amount);
        //this.indicativeCalculationsDetails.vet_age_loading_amount = Number(amount);
      }
    }
    else {
      this.indicativeCalculationsDetails.full_vet_fee_amount = 0;
      this.selected_full_vet_fee_amount = 0;
      this.indicativeCalculationsDetails.aeio_vet_fee_amount = 0;
      // this.indicativeCalculationsDetails.vet_age_loading_amount = 0;
      this.vetAgeLoadingAmount = 0;
    }

    // Mortality Claim Loading
    if (this.quote.vet_claim_loading_id) {
      let VetClaimLoading = this.VetClaimLoading.filter(x => x.id == this.quote.vet_claim_loading_id);
      if (VetClaimLoading.length > 0) {
        this.indicativeCalculationsDetails.vet_fee_claim_loading_percentage = VetClaimLoading[0].claim_loading;
      } else {
        this.indicativeCalculationsDetails.vet_fee_claim_loading_percentage = 0;
      }
    } else {
      this.indicativeCalculationsDetails.vet_fee_claim_loading_percentage = 0;
    }

    // Life Saving Surgery
    if (this.vetFeeCoverSpecial) {
      this.indicativeCalculationsDetails.annual_agg_life_saving = 0;


      if (this.quote.life_saving_surgery_id && this.quote.blood_stock_id) {
        let bloodStocks = this.bloodStocks.filter(x => x.id == this.quote.blood_stock_id);
        if (bloodStocks.length > 0) {
          this.indicativeCalculationsDetails.life_saving_surgery = Number(bloodStocks[0].vet_fee_linking.arm_value);
        }
        this.indicativeCalculationsDetails.is_life_saving_surgery = true;
      }

      if (this.quote.life_saving_annual_surgery_id) {
        let vetFeeCoverSpecial = this.vetFeeCoverSpecial.filter(x => x.id == this.quote.life_saving_annual_surgery_id);
        if (vetFeeCoverSpecial.length > 0) {
          this.indicativeCalculationsDetails.life_saving_surgery = 0;
          if (this.quote.rating_version == 1) {
            // if (this.vetFeeCoverIndex == 2 && Number(horse_value) > 2500 && this.quote.rating_version == 1) {
            //   this.indicativeCalculationsDetails.annual_agg_life_saving = 0;
            // } else {
            let annual_agg_life_saving = 0;
            vetFeeCoverSpecial[0].vet_indemnity_data.forEach(res => {
              if (horse_value >= res.lower_bound && horse_value <= res.upper_bound && res.class_id == this.quote.horse_class_id) {
                annual_agg_life_saving = Number(res.value);
              }
            })
            this.indicativeCalculationsDetails.annual_agg_life_saving = annual_agg_life_saving;
            // }
          } else {
            let annual_agg_life_saving = 0;
            if (this.quote.life_saving_annual_surgery_id) {
              vetFeeCoverSpecial[0].vet_indemnity_data.forEach(res => {
                if (horse_value >= res.lower_bound && horse_value <= res.upper_bound && res.class_id == this.quote.horse_class_id) {
                  annual_agg_life_saving = Number(res.value);
                }
              })
            }
            this.indicativeCalculationsDetails.annual_agg_life_saving = annual_agg_life_saving;
          }
          this.indicativeCalculationsDetails.is_life_saving_surgery = false;
        }
      }

      // if (horse_age > 17) {
      //   this.indicativeCalculationsDetails.annual_agg_life_saving = 0;

      // }


    }

    // Vet Fee Excess Change    
    if (company_excess_id != '' && this.vetFeeExcess) {
      let vetFeeExcess = this.vetFeeExcess.filter(x => x.id == company_excess_id);
      if (vetFeeExcess.length > 0) {
        let vetFeeExcessDiscount = vetFeeExcess[0].discount;
        if (this.vetFeeCoverIndex == 3) {
          vetFeeExcessDiscount = vetFeeExcess[0].discount_7500;
        }
        this.indicativeCalculationsDetails.full_vet_fee_excess_discount = Number(vetFeeExcessDiscount);
        this.indicativeCalculationsDetails.aeio_vet_fee_excess_discount = Number(vetFeeExcessDiscount);
      }
    } else {
      this.indicativeCalculationsDetails.full_vet_fee_excess_discount = 0;
      this.indicativeCalculationsDetails.aeio_vet_fee_excess_discount = 0;
    }


    // Co pay discount
    this.indicativeCalculationsDetails.full_co_pay_discount = 0;
    this.indicativeCalculationsDetails.veterinary_fee_co_pay = 0;
    if (this.quote.company_co_pay_excess_id) {
      let coPayDiscounts20 = this.coPayDiscounts.filter(x => x.id = this.quote.company_co_pay_excess_id);
      if (coPayDiscounts20.length > 0) {
        this.indicativeCalculationsDetails.full_co_pay_discount = coPayDiscounts20[0].discount;
        this.indicativeCalculationsDetails.veterinary_fee_co_pay = 0;
      } else {
        this.indicativeCalculationsDetails.full_co_pay_discount = 0;
      }
    }

    if (this.quote.veterinary_co_pay_id) {
      let coPayDiscounts15 = this.coPayDiscounts.filter(x => x.id = this.quote.veterinary_co_pay_id);
      if (coPayDiscounts15.length > 0) {
        this.indicativeCalculationsDetails.veterinary_fee_co_pay = coPayDiscounts15[0].discount;
        this.indicativeCalculationsDetails.full_co_pay_discount = 0;
      } else {
        this.indicativeCalculationsDetails.veterinary_fee_co_pay = 0;
      }
    }

    // Claim Discount
    if (this.quote.is_claim_discount == 1) {
      this.indicativeCalculationsDetails.claim_discount = 10;
    } else {
      this.indicativeCalculationsDetails.claim_discount = 0;
    }

    // Customer Discount
    if (this.quote.is_customer_discount == 1) {
      this.indicativeCalculationsDetails.customer_discount = 10;
    } else {
      this.indicativeCalculationsDetails.customer_discount = 0;
    }

    // Public Liability
    if (this.publicLiability) {
      let public_liability_cover = this.publicLiability.filter(x => x.id == this.quote.public_liability_cover_id);
      if (public_liability_cover.length > 0) {
        this.indicativeCalculationsDetails.full_public_liability_amount = Number(public_liability_cover[0].addon_public_personal_data.value);
        this.indicativeCalculationsDetails.aeio_public_liability_amount = Number(public_liability_cover[0].addon_public_personal_data.value);
      } else {
        this.indicativeCalculationsDetails.full_public_liability_amount = 0;
        this.indicativeCalculationsDetails.aeio_public_liability_amount = 0;
      }
    }

    // Personal Accident Cover
    if (this.accidentCover && this.accidentCover.length > 0) {
      let accidentCover = this.accidentCover.filter(x => x.id == this.quote.personal_accidental_cover_id);
      if (accidentCover.length > 0) {
        this.indicativeCalculationsDetails.full_personal_accident_amount = Number(accidentCover[0].addon_public_personal_data.value);
        this.indicativeCalculationsDetails.aeio_personal_accident_amount = Number(accidentCover[0].addon_public_personal_data.value);
      } else {
        this.indicativeCalculationsDetails.full_personal_accident_amount = 0;
        this.indicativeCalculationsDetails.aeio_personal_accident_amount = 0;
      }
    }
    // Saddlery & Tack Change
    if (Number(this.quote.does_addon_saddlery_on) == 1) {
      this.indicativeCalculationsDetails.sat_sum_insured = Number(this.quote.addon_saddlery_value);
      // Saddlery & Tack Net Amount
      let sat_net_rate = 0;
      if (this.saddleryDetails) {
        this.saddleryDetails.section_indeminity_limit.forEach((res) => {
          sat_net_rate = Number(res.addon_saddlery_trailers_data.value);
        })
      }
      this.indicativeCalculationsDetails.sat_net_rate = Number(sat_net_rate);

      // Minimum Premium
      let sat_minimum_premium = 0;
      if (this.saddleryDetails) {
        this.saddleryDetails.minimum_premium.forEach((res) => {
          sat_minimum_premium = Number(res.amount);
        })
      }
      this.indicativeCalculationsDetails.sat_minimum_premium = Number(sat_minimum_premium);
    }
    else {
      this.indicativeCalculationsDetails.sat_sum_insured = 0;
      this.indicativeCalculationsDetails.sat_minimum_premium = 0;
    }
    // Trailer value change
    if (Number(this.quote.does_addon_trailer_on) == 1) {
      this.indicativeCalculationsDetails.trailer_sum_insured = Number(this.quote.addon_trailer_value);
      // Trailer Net Amount    
      let trailer_net_rate = 0;
      if (this.TrailerDetails) {
        this.TrailerDetails.section_indeminity_limit.forEach((res) => {
          trailer_net_rate = res.addon_saddlery_trailers_data.value;
        })

        this.indicativeCalculationsDetails.trailer_net_rate = Number(trailer_net_rate);
        // Minimum Premium
        let minimum_premium = 0;
        this.TrailerDetails.minimum_premium.forEach((res) => {
          minimum_premium = res.amount;
        })

        this.indicativeCalculationsDetails.trailer_minimum_premium = Number(minimum_premium);
      }
    }
    else {
      this.indicativeCalculationsDetails.trailer_sum_insured = 0;
      this.indicativeCalculationsDetails.trailer_minimum_premium = 0;
    }
    this.indicativeCalculationsDetails.class_age_loading_amount = Number(this.age_load_percentage_value);
    this.indicativeCalculationsDetails.vet_age_loading_amount = Number(this.vetAgeLoadingAmount);

    if (Number(horse_value) > 0 && class_index > 0 && policy_cove_index > 0) {
      this.getPolicyData(class_index, policy_cove_index, horse_value);
    } else {
      this.indicativeCalculationsDetails.full_class_net_rate = 0;
    }

    this.indicativeCalculationsDetails.full_calculate_data(this.mta_quote_data);
    this.quote.calculations = this.indicativeCalculationsDetails;

  }

  initializeCalculationDetails() {
    this.indicativeCalculationsDetails.full_main_discount = 0;
    this.indicativeCalculationsDetails.aeio_main_discount = 0;
    this.indicativeCalculationsDetails.full_vet_fee_amount = 0;
    this.indicativeCalculationsDetails.aeio_vet_fee_amount = 0;
    this.indicativeCalculationsDetails.full_class_net_rate = 0;
    this.indicativeCalculationsDetails.class_minimum_premium = 0;
    this.indicativeCalculationsDetails.vet_age_loading_amount = 0;
    this.indicativeCalculationsDetails.full_public_liability_amount = 0;
    this.indicativeCalculationsDetails.aeio_public_liability_amount = 0;
    this.indicativeCalculationsDetails.full_personal_accident_amount = 0;
    this.indicativeCalculationsDetails.aeio_personal_accident_amount = 0;
    this.indicativeCalculationsDetails.trailer_sum_insured = 0;
  }


  // QUOTE FORM SUBMIT
  previousSequenceNumber: number = 0;
  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      for (const key of Object.keys(f.controls)) {
        if (f.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('input[name=' + key + '], mat-select[name=' + key + ']');
          invalidControl.focus();
          invalidControl.scrollIntoView({ behavior: 'smooth' });
          break;
        }
      }

      return false;
    }

    if (this.checkboxRequired) {
      const invalidControl = this.el.nativeElement.querySelector('#vetExcess');
      invalidControl.focus();
      invalidControl.scrollIntoView({ behavior: 'smooth' });
      return false;
    }
    this.quote.isIndicative = false;
    //if (this.quote_type <= 3) {
    this.quote.type = this.quote_type;
    //}
    let data: any = '';
    let formattedDate = formatDate(this.quote.policy_start_date, 'yyyy-MM-dd', 'en-US');
    let dob = formatDate(this.quote.policy_holder.dob, 'yyyy-MM-dd', 'en-US');
    // if (this.quote.company_co_insurance == false) {
    //   this.quote.company_co_pay_excess_id = "";
    // }

    //aeio_co_pay_discount
    this.quote.policy_start_date = new Date(formattedDate);
    this.quote.policy_holder.dob = dob;
    this.quote.broker_id = this.sessionSt.retrieve('userInfo').id;
    this.quote.financial_details.is_aeio = this.quote.is_aeio;

    this.quote.full_quote_section = true;
    this.quote.aeio_quote_section = false;
    this.quote.financial_details.class_net_rate = this.indicativeCalculationsDetails.full_class_net_rate;
    this.quote.financial_details.main_discount = this.indicativeCalculationsDetails.full_main_discount;
    this.quote.financial_details.vet_excess_discount = this.indicativeCalculationsDetails.full_vet_fee_excess_discount;
    this.quote.financial_details.public_liability_amount = this.indicativeCalculationsDetails.full_public_liability_amount;
    this.quote.financial_details.personal_accident_amount = this.indicativeCalculationsDetails.full_personal_accident_amount;
    this.quote.financial_details.peliwica_pay_away_commission = this.indicativeCalculationsDetails.peliwica_pay_away_commission;
    this.quote.financial_details.broker_commission = Number(this.indicativeCalculationsDetails.broker_commission);
    this.quote.financial_details.peliwica_retained_commission = this.indicativeCalculationsDetails.peliwica_retained_commission;
    this.quote.financial_details.net_rate_amount = this.indicativeCalculationsDetails.full_class_net_rate_amount;
    this.quote.financial_details.class_minimum_premium = this.indicativeCalculationsDetails.class_minimum_premium;
    this.quote.financial_details.class_section_loading = this.indicativeCalculationsDetails.class_age_loading_amount;
    this.quote.financial_details.saddlery_net_rate = this.indicativeCalculationsDetails.sat_net_rate;
    this.quote.financial_details.saddlery_minimum_premium = this.indicativeCalculationsDetails.sat_minimum_premium;
    this.quote.financial_details.trailer_net_rate = this.indicativeCalculationsDetails.trailer_net_rate;
    this.quote.financial_details.trailer_minimum_premium = this.indicativeCalculationsDetails.trailer_minimum_premium;
    this.quote.financial_details.ipt_fee = this.indicativeCalculationsDetails.ipt_fee;
    this.quote.financial_details.policy_premium = this.indicativeCalculationsDetails.full_total_gross_primum;
    this.quote.financial_details.total_premium = this.indicativeCalculationsDetails.full_total_premium;
    this.quote.financial_details.total_policy_cost = this.indicativeCalculationsDetails.full_total_amount_payable;
    this.quote.financial_details.fees = this.indicativeCalculationsDetails.full_underwriting_fee;
    this.quote.financial_details.uw_fee = this.indicativeCalculationsDetails.full_underwriting_fee;
    this.quote.financial_details.broker_amount = this.indicativeCalculationsDetails.full_broker_amount;
    this.quote.financial_details.full_vet_gross_amount = this.indicativeCalculationsDetails.full_vet_gross_amount;
    this.quote.financial_details.net_vet_fee_amount = this.indicativeCalculationsDetails.full_net_vet_fee_amount;
    this.quote.financial_details.total_net_amount = this.indicativeCalculationsDetails.full_net_Premium_amount;
    this.quote.financial_details.net_co_insurance_towards_lameness_diagnostics = this.indicativeCalculationsDetails.full_co_pay_discount;
    this.quote.financial_details.net_personal_accident_amount = this.indicativeCalculationsDetails.full_net_personal_accident_amount;
    this.quote.financial_details.net_public_liability_amount = this.indicativeCalculationsDetails.full_net_public_liability_amount;
    this.quote.financial_details.premium_loading = this.indicativeCalculationsDetails.total_premium_loading;
    this.quote.financial_details.premium_loading_percentage = this.indicativeCalculationsDetails.premium_loading_percentage;
    this.quote.financial_details.claim_discount = this.indicativeCalculationsDetails.claim_discount;
    this.quote.financial_details.claim_discount_amount = this.indicativeCalculationsDetails.claim_discount_amount;
    this.quote.financial_details.customer_discount = this.indicativeCalculationsDetails.customer_discount;
    this.quote.financial_details.customer_discount_amount = this.indicativeCalculationsDetails.customer_discount_amount;
    this.quote.financial_details.mortality_claim_loading_amount = this.indicativeCalculationsDetails.mortality_claim_loading_amount;
    this.quote.financial_details.mortality_claim_loading_percentage = this.indicativeCalculationsDetails.mortality_claim_loading_percentage;
    this.quote.financial_details.vet_claim_loading_amount = this.indicativeCalculationsDetails.vet_fee_claim_loading_amount;
    this.quote.financial_details.class_gross_amount = this.indicativeCalculationsDetails.full_class_gross_amount;
    this.quote.financial_details.vet_gross_amount = this.indicativeCalculationsDetails.full_vet_gross_amount;
    this.quote.financial_details.sat_gross_amount = this.indicativeCalculationsDetails.sat_gross_amount;
    this.quote.financial_details.annual_agg_life_saving_gross_amount = this.indicativeCalculationsDetails.annual_agg_life_saving;
    this.quote.financial_details.disposal_euthansia_cost = this.indicativeCalculationsDetails.disposal_euthansia_cost;
    this.quote.financial_details.elective_surgery_ga_cover = this.indicativeCalculationsDetails.elective_surgery_ga_cover;
    this.quote.financial_details.elective_surgery_ga_min_premium = this.indicativeCalculationsDetails.elective_surgery_ga_min_premium;
    this.quote.financial_details.livery_hospitalisation_emegency_transportation = this.indicativeCalculationsDetails.livery_hospitalisation_emegency_transportation;
    this.quote.financial_details.transportation = this.indicativeCalculationsDetails.transportation;

    // FOR MTA/RENEWAL
    if (this.route.snapshot.params.id) {
      this.quote.insurance_status_id = 'e216564f-552a-4e45-ae67-0a63b7074691';
    }
    if (!this.quote.prev_sequence_number) {
      this.quote.prev_sequence_number = this.quote.sequence_number;
    }
    if (this.route.snapshot.params.quote_type) {
      if (this.quote.prev_sequence_number == this.quote.sequence_number) {
        this.quote.id = null;
        this.quote.financial_details.quote_id = null;
        this.quote.financial_details.id = null;
        this.quote.horse_details.quote_id = null;
        this.quote.horse_details.id = null;
        this.quote.policy_holder.quote_id = null;
        this.quote.policy_holder.id = null;
        this.quote.statement_of_facts.forEach((res, index) => {
          res.quote_id = null;
          res.id = null;
        });

      }
    }

    this.quote.sof_version = "";
    this.quote.agreed_question_version = "";
    this.parent_quote.quote_versions[0] = this.quote;
    this.parent_quote.id = this.quote.quote_meta_id;
    data = {
      quote_data: this.parent_quote
    };

    this.quoteService.saveAJGIndicativeQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            var temp;
            temp = this.quote;
            this.quote = response.result.quote_data.quote_versions[0];
            this.quoteDetails = response.result.quote_data.quote_versions[0];
            // Disbale to stop clearing mta reasons id if the page loads first time under quote edit screen 
            this.sessionSt.store('showHideMTAReasons', false);
            this.quote.horse_details.age = Math.ceil(response.result.quote_data.quote_versions[0].horse_details.age);
            // this.quoteDetails = this.quote;
            this.quote.primaryCover = temp.primaryCover;
            this.quote.bloodstock_text = temp.bloodstock_text;
            this.quote.full_class_of_use = temp.full_class_of_use;
            this.quote.full_policy_cover = temp.full_policy_cover;
            this.quote.full_vet_fee_cover = temp.full_vet_fee_cover;
            this.quote.full_life_saving_surgery = temp.full_life_saving_surgery;
            this.quote.full_vet_fee_excess = temp.full_vet_fee_excess;
            this.quote.full_personal_accident = temp.full_personal_accident;
            this.quote.full_public_liability = temp.full_public_liability;
            if (this.quote.mortality_claim_loading_id) {
              let mortality_claim_loading = this.MortalityClaimLoading.filter(x => x.id == this.quote.mortality_claim_loading_id);
              if (mortality_claim_loading.length > 0) {
                this.quote.full_mortality_claim_loading = mortality_claim_loading[0].claim_history;
              }
            }
            if (this.quote.vet_claim_loading_id) {
              let VetClaimLoading = this.VetClaimLoading.filter(x => x.id == this.quote.vet_claim_loading_id);
              if (VetClaimLoading.length > 0) {
                this.quote.full_vet_claim_loading = VetClaimLoading[0].claim_history;
              }
            }

            this.quote.indicativePage = temp.indicativePage;
            this.quote.quote_document = temp.quote_document;
            this.quote.company_co_insurance = temp.company_co_insurance;
            this.quote.full_quote_section = temp.full_quote_section;
            this.quote.aeio_quote_section = temp.aeio_quote_section;
            this.quote.hide_sections_for_blood_stock = temp.hide_sections_for_blood_stock;
            this.quote.prev_sequence_number = temp.prev_sequence_number;
            if (this.quote.horse_details.gender == 0) {
              this.quote.horse_details.gender = null;
            }
            if (this.quote.does_policy_owner_owns_horse == 0) {
              this.quote.does_policy_owner_owns_horse = null;
            }
            if (this.quote.is_domicile_in_UK == 0) {
              this.quote.is_domicile_in_UK = null;
            }

            this.quote.mta_sum_policy_premium = response.result.quote_data.mta_sum_policy_premium;
            this.quote.sum_policy_premium = response.result.quote_data.sum_policy_premium;
            this.quote.latest_policy_start_date = response.result.quote_data.latest_policy_start_date;
            this.quote.latest_policy_end_date = response.result.quote_data.latest_policy_end_date;



            this.quote.calculations = this.indicativeCalculationsDetails;
            this.quote.company_co_pay_discount = this.indicativeCalculationsDetails.full_co_pay_discount;
            this.parent_quote.quote_versions[0] = this.quote;
            if (this.route.snapshot.params.quote_type != 4 && this.route.snapshot.params.quote_type != 5 && this.route.snapshot.params.quote_type != 6) {
              this.quoteDetailsUpdated.emit(this.quote);
              this.ProceedFullDetailsBtn.emit();
            } else {
              this.router.navigateByUrl("/policy-list");
            }


          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  getUpdatedvalue($event) {
    this.indicativeCalculationsDetails = $event;
  }

  // CONVERT TO POLICY
  ConvertToPolicy() {
    let data: any = '';
    data = {
      quote_id: this.quote.id,
      policy_ref_number: this.quote.policy_ref_number,
      policy_start_date: formatDate(this.quote.policy_start_date, 'yyyy-MM-dd', 'en-US')
    };
    this.quoteService.convertToPolicy(data)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.router.navigate(['/ajg/policy', this.quote.id]);
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

}
