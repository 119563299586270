<form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div class="row">
        <div class="col-md-8">
            <div class="form_sections">
                <div class="row">
                    <div class="col-md-6">
                        <h6>Quote Details</h6>
                        <div class="form-group w-100">
                            <input type="text" readonly required name="policy_start_date" [disabled]="quote_type >= 6"
                                [(ngModel)]="quote.policy_start_date" #policy_start_date="ngModel"
                                placeholder="Policy Start Date" class="form-control form-control-sm datepicker"
                                [ngClass]="{ 'is-invalid': f.submitted && policy_start_date.invalid }" matInput
                                (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker" [min]="minPolicyDate"
                                [max]="maxPolicyDate" (dateChange)="onPolicyStartDateChange($event)">
                            <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #myDatepicker></mat-datepicker>
                            <div *ngIf="f.submitted && policy_start_date.invalid" class="invalid-feedback">
                                Policy date is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" *ngIf="isPelwicaUser">
                            <mat-form-field class="mt-4 w-100">
                                <mat-label>Broker</mat-label>
                                <mat-select [(ngModel)]="quote.company_id" class="form-control form-control-sm"
                                    name="broker_id" (selectionChange)="onChangeBroker($event)"
                                    [ngClass]="{ 'is-invalid': f.submitted && broker.invalid }" #broker="ngModel"
                                    required [disabled]="quote_type >= 4">
                                    <mat-option [value]="">Select Broker</mat-option>
                                    <mat-option *ngFor="let brk of brokers" [value]="brk.id">
                                        {{brk.name}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="f.submitted && broker.invalid" class="invalid-feedback">
                                    Broker field is required.
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"
                        *ngIf="quote_type >= 3 || QuoteTypeVisibility">
                        <mat-form-field class="w-100">
                            <mat-label>Transaction Type</mat-label>
                            <mat-select [(ngModel)]="quote_type" class="form-control form-control-sm" name="quote_type"
                                [disabled]="!QuoteTypeVisibility" (selectionChange)="onQuoteTypeChange($event)">
                                <mat-option [value]="1">New Business</mat-option>
                                <mat-option [value]="2" [hidden]="isBroker">Renewal</mat-option>
                                <mat-option [value]="3" [hidden]="QuoteTypeVisibility">MTA</mat-option>
                                <mat-option [value]="4" [hidden]="QuoteTypeVisibility">Cancelled</mat-option>
                                <mat-option [value]="5" [hidden]="QuoteTypeVisibility">Lapsed</mat-option>
                                <mat-option [value]="6" [hidden]="QuoteTypeVisibility">NTU</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6" *ngIf="quote_type >= 3">
                        <mat-form-field class="w-100">
                            <mat-label>Policy Reason</mat-label>
                            <mat-select [(ngModel)]="quote.mta_reason_id" class="form-control form-control-sm"
                                name="mta_reason_id" [ngClass]="{ 'is-invalid': f.submitted && mta_reason_id.invalid }"
                                #mta_reason_id="ngModel" required [disabled]="quote_type == 7">
                                <mat-option *ngFor="let rsn of policyReasons" [value]="rsn.id">
                                    {{rsn.reasons}}
                                </mat-option>
                                <mat-option [value]="'1'">Other</mat-option>
                            </mat-select>
                            <div *ngIf="f.submitted && mta_reason_id.invalid" class="invalid-feedback">
                                Please select a specific reason.
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 mt-2"
                        *ngIf="(quote_type == 1 || quote_type == 2) && isPelwicaUser &&  tigerlabfield">
                        <input type="text" name="tigerlab_policy_reference" placeholder="Legacy Policy Reference"
                            class="form-control form-control-sm" [(ngModel)]="quote.tigerlab_policy_reference"
                            [ngClass]="{ 'is-invalid': f.submitted && tigerlab_policy_reference.invalid }"
                            #tigerlab_policy_reference="ngModel" required>
                        <div *ngIf="f.submitted && tigerlab_policy_reference.invalid" class="invalid-feedback">
                            Legacy policy reference is required.
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="quote.mta_reason_id == '1'">
                    <div class="col-md-12">
                        <div class="form-group">
                            <textarea name="other_reason" placeholder="Please specify reason" class="form-control"
                                [(ngModel)]="quote.mta_reason_other" [disabled]="quote_type == 7"
                                [ngClass]="{ 'is-invalid': f.submitted && other_reason.invalid }"
                                #other_reason="ngModel" required></textarea>
                            <div *ngIf="f.submitted && other_reason.invalid" class="invalid-feedback">
                                Please provide a specific reason.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections">
                <h6>Personal Details</h6>
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field class="w-100">
                            <mat-label>Title</mat-label>
                            <mat-select [(ngModel)]="quote.policy_holder.title" class="form-control form-control-sm"
                                name="title" #title="ngModel" [disabled]="quote_type >= 4">
                                <mat-option [value]="">Select Title</mat-option>
                                <mat-option *ngFor="let title of titleList" [value]="title.id">
                                    {{title.title}}
                                </mat-option>
                            </mat-select>
                            <!-- <div *ngIf="f.submitted && title.invalid" class="invalid-feedback">
                                Title field is required.
                            </div> -->
                        </mat-form-field>
                    </div>
                    <div class="col-md-5 mt-2">
                        <input type="text" name="first_name" placeholder="First Name"
                            class="form-control form-control-sm quote_radio w-90" (keyup)="firstnameToTotleCase($event)"
                            [(ngModel)]="quote.policy_holder.first_name"
                            [ngClass]="{ 'is-invalid': f.submitted && first_name.invalid }" #first_name="ngModel"
                            required appTitleCase [disabled]="quote_type >= 4">
                        <span class="ml-1"
                            (click)="helpTextPopup(content,'The name must be for one individual and must be the name in full, not just initials')"><i
                                class="mdi mdi-comment-question-outline"></i></span>
                        <div *ngIf="f.submitted && first_name.invalid" class="invalid-feedback">
                            First Name is required.
                        </div>
                    </div>
                    <div class="col-md-4 mt-2">
                        <input type="text" name="last_name" placeholder="Last Name" class="form-control form-control-sm"
                            (keyup)="lastnameToTotleCase($event)" [(ngModel)]="quote.policy_holder.last_name"
                            [ngClass]="{ 'is-invalid': f.submitted && last_name.invalid }" #last_name="ngModel" required
                            appTitleCase [disabled]="quote_type >= 4">
                        <div *ngIf="f.submitted && last_name.invalid" class="invalid-feedback">
                            Lastname is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <small>** The name must be for one individual and must be the name in full, not just
                            initials</small>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="email" name="email" required [(ngModel)]="quote.policy_holder.email"
                                #email="ngModel"
                                pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                                [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
                                class="form-control form-control-sm" placeholder="Email"
                                [disabled]="quote_type >= 4"
                                oninput="this.value = this.value.toLowerCase()">
                            <div *ngIf="f.submitted && email.errors && (email.invalid || email.touched)"
                                class="invalid-feedback">
                                <div *ngIf="email.invalid && !email.touched">Email is required.</div>
                                <div *ngIf="email.errors.pattern && email.touched">Please provide a valid email address
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input required type="text" name="phone" placeholder="Contact Number"
                                class="form-control form-control-sm" [(ngModel)]="quote.policy_holder.phone"
                                #phone="ngModel" [ngClass]="{ 'is-invalid': f.submitted && phone.invalid }"
                                [disabled]="quote_type >= 4">
                            <div *ngIf="f.submitted && phone.invalid" class="invalid-feedback">
                                Contact number is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" required name="dob" readonly [(ngModel)]="quote.policy_holder.dob"
                                #dob="ngModel" placeholder="DOB" class="form-control form-control-sm datepicker"
                                matInput (focus)="dobDatepicker.open()" [matDatepicker]="dobDatepicker"
                                [ngClass]="{ 'is-invalid': f.submitted && dob.invalid }" [max]="dob_max"
                                [disabled]="quote_type >= 4">
                            <mat-datepicker-toggle [for]="dobDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #dobDatepicker></mat-datepicker>
                            <div *ngIf="f.submitted && dob.invalid" class="invalid-feedback">
                                DOB is required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections">
                <h6>Horse Details</h6>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="horse_name" placeholder="Stable Name"
                                class="form-control form-control-sm" (keyup)="horsenameToTotleCase($event)"
                                [(ngModel)]="quote.horse_details.name" #horse_name="ngModel" required
                                [ngClass]="{ 'is-invalid': f.submitted && horse_name.invalid }" appTitleCase
                                [disabled]="quote_type >= 4">
                            <div *ngIf="f.submitted && horse_name.invalid" class="invalid-feedback">
                                Horse name is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="passport_name " placeholder="Passport Name"
                                class="form-control form-control-sm" [(ngModel)]="quote.horse_details.passport_name"
                                #passport_name="ngModel" required
                                [ngClass]="{ 'is-invalid': f.submitted && passport_name .invalid }" appTitleCase
                                [disabled]="quote_type >= 4">
                            <div *ngIf="f.submitted && passport_name .invalid" class="invalid-feedback">
                                Passport name is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="text" appNumbers name="purchase_price" placeholder="Purchase Price"
                                    class="form-control form-control-sm" autocomplete="off"
                                    [(ngModel)]="quote.horse_details.purchase_price " #purchase_price="ngModel" required
                                    [ngClass]="{ 'is-invalid': f.submitted && purchase_price .invalid }"
                                    [disabled]="quote_type >= 4">
                            </div>

                            <div *ngIf="f.submitted && purchase_price .invalid" class="invalid-feedback">
                                Purchase Price is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6"> 
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="text" appNumbers name="horse_value" placeholder="Sum Insured"
                                    class="form-control form-control-sm" autocomplete="off"
                                    [(ngModel)]="quote.horse_details.value" (blur)="horseValueChange($event,content)"
                                    #horse_value="ngModel" required [min]="1"
                                    [ngClass]="{ 'is-invalid': f.submitted && horse_value.invalid }"
                                    [disabled]="quote_type >= 4">
                            </div>

                            <div *ngIf="f.submitted && horse_value.invalid" class="invalid-feedback">
                                Sum insured is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field class="w-100">
                            <mat-label>Horse Age</mat-label>
                            <mat-select [(ngModel)]="quote.horse_details.age" class="form-control form-control-sm"
                                name="horse_age" (selectionChange)="onHorseAgeChange($event,content)"
                                #horse_age="ngModel" required
                                [ngClass]="{ 'is-invalid': f.submitted && horse_age.invalid }"
                                [disabled]="quote_type >= 4">
                                <mat-option [value]="">Horse Age</mat-option>
                                <mat-option *ngFor="let age of horseAge" [value]="age">
                                    {{age}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="f.submitted && horse_age.invalid" class="invalid-feedback">
                                Horse age is required.
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="w-100">
                            <mat-label>Age In Month</mat-label>
                            <mat-select [(ngModel)]="quote.horse_details.age_month" class="form-control form-control-sm"
                                name="age_month" #age_month="ngModel" required
                                [ngClass]="{ 'is-invalid': f.submitted && age_month.invalid }"
                                [disabled]="quote_type >= 4">
                                <mat-option [value]="">Age In Month</mat-option>
                                <mat-option *ngFor="let age of horseAgeMonth" [value]="age">
                                    {{age}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="f.submitted && age_month.invalid" class="invalid-feedback">
                                Horse age in month is required.
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="form_sections">
                <h6>Main Horse Use</h6>
                <mat-radio-group class="row" aria-label="Select an option" name="horse_class_id"
                    [disabled]="quote_type >= 4"
                    [(ngModel)]="quote.horse_class_id" (change)="classOfUseOnChange($event,content)">
                    <div class="col-md-4" *ngFor="let uses of classOfUse; let idx = index">
                        <div class="quote_radio w-90 mb-3">
                            <mat-radio-button [value]="uses.id">{{uses.name}}</mat-radio-button>
                        </div>
                        <span class="ml-1" (click)="helpTextPopup(content,uses.tooltip)"><i
                                class="mdi mdi-comment-question-outline"></i></span>
                    </div>
                </mat-radio-group>
                <div class="form-group row" *ngIf="!BloodStockDetails">
                    <div class="col-md-8">
                        <mat-form-field class="w-100">
                            <mat-label>Primary Cover</mat-label>
                            <mat-select [(ngModel)]="quote.horse_class_sub_id" class="form-control form-control-sm"
                                name="horse_class_sub_id"
                                [disabled]="quote_type >= 4"
                                (selectionChange)="horseSubClassOnChange($event)" #horse_class_sub_id="ngModel" required
                                [ngClass]="{ 'is-invalid': f.submitted && horse_class_sub_id.invalid }">
                                <mat-option [value]="">Please Select Primary Cover</mat-option>
                                <mat-option *ngFor="let subClass of horseSubClasses" [value]="subClass.id">
                                    {{subClass.name}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="f.submitted && horse_class_sub_id.invalid" class="invalid-feedback">
                                Primary cover is required.
                            </div>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="BloodStockDetails">
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="w-100">
                                <mat-label>Bloodstock</mat-label>
                                <mat-select [(ngModel)]="quote.blood_stock_id" class="form-control form-control-sm"
                                    name="blood_stock_id" (selectionChange)="onBloodStockChange($event)"
                                    #blood_stock_id="ngModel" required
                                    [disabled]="quote_type >= 4"
                                    [ngClass]="{ 'is-invalid': f.submitted && blood_stock_id.invalid }">
                                    <mat-option [value]="">Please Select Use</mat-option>
                                    <mat-option *ngFor="let bloodStock of bloodStocks" [value]="bloodStock.id">
                                        {{bloodStock.name}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="f.submitted && blood_stock_id.invalid" class="invalid-feedback">
                                    Bloodstock is required.
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form_sections">
                <h6>Policy Cover Details</h6>
                <mat-radio-group class="row" aria-label="Select an option" name="policy_cover_id"
                    [disabled]="quote_type >= 4"
                    (selectionChange)="horseSubClassOnChange($event)" #policy_cover_id="ngModel" required
                    [(ngModel)]="quote.policy_cover_id" (change)="policyCoverChange($event,'',content)">
                    <div class="col-md-6" *ngFor="let policy of policyCover; let idx = index">
                        <div
                            [hidden]="policy.cover_index == 2 && (BloodStockDetails || (quote.horse_details.age >=14 && quote_type != 2) || (quote.horse_details.age >=15 && quote_type == 2))">
                            <div class="quote_radio w-90 mb-3">
                                <mat-radio-button [value]="policy.id">{{policy.name}}</mat-radio-button>
                            </div>
                            <span class="ml-1" (click)="helpTextPopup(content,policy.help_text)"
                                *ngIf="quote.is_aeio != 1"><i class="mdi mdi-comment-question-outline"></i></span>
                        </div>
                    </div>
                </mat-radio-group>
                <div *ngIf="f.submitted && policy_cover_id.invalid" class="invalid-feedback">
                    Policy cover is required.
                </div>
                <div class="form-group mt-3">
                    <h6>Claims History</h6>
                    <small>This is based on when the claim was made (rather than the date of loss). The timeframe is
                        based on time from the proposed inception date rather than calendar year. The specific claim
                        details should be entered under Question 15 of the Statement of Fact.</small>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="w-100">
                                <mat-label>Mortality Claims History</mat-label>
                                <mat-select [(ngModel)]="quote.mortality_claim_loading_id"
                                    class="form-control form-control-sm" name="mortality_claim_loading_id"
                                    (selectionChange)="onMortalityClaimLoadingChange($event, content)"
                                    #mortality_claim_loading_id="ngModel" required
                                    [disabled]="quote_type >= 4"
                                    [ngClass]="{ 'is-invalid': f.submitted && mortality_claim_loading_id.invalid }">
                                    <mat-option [value]="">Please Select Use</mat-option>
                                    <mat-option [value]="cl.id" *ngFor="let cl of MortalityClaimLoading">{{
                                        cl.claim_history
                                        }}</mat-option>
                                </mat-select>
                                <div *ngIf="f.submitted && mortality_claim_loading_id.invalid" class="invalid-feedback">
                                    Mortality claims history is required.
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections" *ngIf="!BloodStockDetails">
                <h6>Vet Fees Cover<br>
                    <small>**Note that there is no premium refund for this section of cover once the policy has been in
                        force for 6 months or more.</small>
                </h6>
                <div class="row">
                    <div class="col-md-6" [class.disabled]="quote_type >= 4"
                        *ngFor="let vetFees of vetFeesCover; let i = index;">
                        <div class="form-group" (click)="onCheckboxChange(vetFees.id, vetFeesCover)">
                            <mat-checkbox name="vet_fees_cover_{{i}}"
                                (change)="onVetFeeCoverChange(vetFees.id,vetFeesCover, $event)" value="{{vetFees.id}}"
                                [(ngModel)]="vetFees.is_checked" [checked]="vetFees.is_checked">
                                {{vetFees.description}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <h6>Claim History</h6>
                    <small>This question relates to the CUSTOMER (rather than the proposed horse) and is based on
                        when they made the claim (rather than the date of loss). The timeframe is based on time from
                        the proposed inception date rather than calendar year. The specific claim details should be
                        entered under Question 15 of the Statement of Fact.</small>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="w-100">
                                <mat-label>Veterinary Fees Claim History</mat-label>
                                <mat-select [(ngModel)]="quote.vet_claim_loading_id"
                                    class="form-control form-control-sm" name="vet_claim_loading_id"
                                    (selectionChange)="onVetClaimLoadingChange($event, content)"
                                    #vet_claim_loading_id="ngModel" required
                                    [disabled]="quote_type >= 4"
                                    [ngClass]="{ 'is-invalid': f.submitted && vet_claim_loading_id.invalid }">
                                    <mat-option [value]="">Please Select Use</mat-option>
                                    <mat-option [value]="cl.id" *ngFor="let cl of VetClaimLoading;">{{ cl.claim_history
                                        }}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="f.submitted && vet_claim_loading_id.invalid" class="invalid-feedback">
                                    Claims history is required.
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>



            <!-- [checked]="((quote.horse_details.value > 2500 && vetFeeCoverIndex == 2  && vetFees.life_saving_index == 2 && quote.rating_version == 1) || (quote.horse_details.value >= 5000 && vetFees.life_saving_index == 2 && quote.horse_details.age <= 16 && quote.vet_fee_sum_insured_id && ((quote.life_saving_annual_surgery_id && quote.rating_version == 2) || (!quote.life_saving_annual_surgery_id && quote.rating_version == 0)))) ?true:vetFees.is_checked" -->
            <div class="form_sections" *ngIf="!BloodStockDetails && quote.vet_fee_sum_insured_id">
                <h6>Vet Fee Excess </h6>
                <div class="row" id="vetExcess" [class.disabled]="disableVetFeeExcess">
                    <div class="col-md-6 quote_radio"
                        [class.disabled]="quote_type >= 4"
                        *ngFor="let excess of vetFeeExcess; let i = index">
                        <div class="form-group" (click)="onCheckboxChange(excess.id, vetFeeExcess)">
                            <mat-checkbox name="company_excess_{{i}}" value="{{excess.id}}"
                                [checked]="excess.is_checked" [(ngModel)]="excess.is_checked"
                                (ngModelChange)="excess.is_checked"
                                (change)="vetFeeExcessOnChange(excess.discount, $event, excess.excess_amount)">
                                &pound;{{excess.description}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div *ngIf="checkboxRequired" class="invalid-feedback">
                    Please select an option.
                </div>
            </div>
            <!-- <div class="form_sections" *ngIf="LiveryHospitalisation && quote.is_aeio != 1">
                <h6>Vets Fees Add ons</h6>
                <div class="row">
                    <div class="col-md-6" [class.disabled]="quote_type == 4 || quote_type == 5 ||  quote_type == 6">
                        <div class="form-group">
                            <mat-checkbox name="LiveryHospitalisation" [value]="LiveryHospitalisation.id"
                                (change)="onLiveryHospitalisationChange($event)"
                                [(ngModel)]="quote.is_livery_hispitalization">
                                {{LiveryHospitalisation.description}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="form_sections" *ngIf="vetFeeCoverSpecial.length > 0 && !hideLSS">
                <div [class.disabled]="quote_type >= 4"
                    *ngFor="let vetFees of vetFeeCoverSpecial; let i=index;">
                    <h6 *ngIf="i == 0"> Life-Saving Surgical Fees <br>
                        <small *ngIf="!BloodStockDetails">** This extension is a catastrophe life-saving surgical cover
                            to immediately save the
                            life of the horse. It can be selected in addition to Veterinary Fees or taken on its
                            own. <br>
                            Note that there is no premium refund for this section of cover outside of the 14 day cooling
                            off period.</small>
                    </h6>
                    <div class="row" *ngIf="vetFees.life_saving_index == 1 && BloodStockDetails">
                        <div class="col-md-9 quote_radio mb-3">
                            <mat-checkbox name="vet_fees_coverspecial_life_saving" value="{{vetFees.id}}"
                                [(ngModel)]="vetFees.is_checked"
                                (change)="onvetFeeCoverSpecialChange(vetFees.life_saving_index,vetFees.vet_indemnity_data.value,$event,vetFees.description)">
                                {{vetFees.description}}
                            </mat-checkbox>
                        </div>
                    </div>
                    <!-- Hide 2500 colic if ver fee cove is 7500 per incident-->
                    <div class="row" *ngIf="vetFees.life_saving_index == 2 && !BloodStockDetails"
                        [hidden]="quote.horse_details.age > 17 || quote.vet_fee_sum_insured_id == '863b6924-5b80-4c76-a469-f767594bc111'">
                        <div class="col-md-9 quote_radio">
                            <mat-checkbox name="vet_fees_coverspecial_top_up" value="{{vetFees.id}}"
                                [(ngModel)]="quote.life_saving_annual_surgery_id"
                                [disabled]="quote_type >= 4"
                                (change)="onvetFeeCoverSpecialChange(vetFees.life_saving_index,vetFees.vet_indemnity_data.value,$event,vetFees.description)">
                                {{vetFees.description}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form_sections" *ngIf="isPelwicaUser">
                <h6>Claim Discount</h6>
                <div class="form-group quote_radio">
                    <mat-checkbox name="claim_discount" [(ngModel)]="quote.is_claim_discount"
                        [disabled]="quote_type >= 4"
                        value="{{quote.company_co_pay_excess_id}}" (change)="onClaimDiscountChange($event)"
                        [checked]="quote.is_claim_discount">
                        Apply 10% No Claims Discount
                    </mat-checkbox>
                </div>
            </div>
            <div class="form_sections" *ngIf="isPelwicaUser">
                <h6>Customer Discount</h6>
                <div class="form-group quote_radio">
                    <mat-checkbox name="customer_discount" [(ngModel)]="quote.is_customer_discount"
                        [disabled]="quote_type >= 4"
                        value="{{quote.company_co_pay_excess_id}}" (change)="onCustomerDiscountChange($event)"
                        [checked]="quote.is_customer_discount">
                        Apply 10% Customer Discount
                    </mat-checkbox>
                </div>
            </div>
            <div class="form_sections">
                <h6>Saddlery and Tack <small>(£100 Standard Excess applies) </small></h6>
                <div class="form-group">
                    <label class="toggle-switch toggle-switch-dark">
                        <input type="checkbox" checked="" name="does_addon_saddlery_on"
                            [(ngModel)]="quote.does_addon_saddlery_on" (change)="saddlery_tack($event)"
                            [disabled]="quote_type >= 4">
                        <span class="toggle-slider round"></span>
                    </label>
                </div>
                <div class="form-group row" *ngIf="quote.does_addon_saddlery_on">
                    <div class="col-md-4">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="text" name="addon_saddlery_value"
                                [disabled]="quote_type >= 4"
                                [(ngModel)]="quote.addon_saddlery_value" class="form-control form-control-sm"
                                placeholder="Value" (change)="onChangeSaddlery($event)" appDecimalNumbers>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections">
                <h6 class="mt-4">Commission</h6>
                <div class="form-group row">
                    <div class="col-md-4">Broker Comission <span *ngIf="isBrokerCommissionReduced" class="ml-1" style="color: #ff0000" title="Broker Commission Reduced"><i class="mdi mdi-alert-circle"></i></span></div>
                    <div class="col-md-6">
                        <div *ngIf="quote_type >= 3 || !visibleOnlytoAdmin">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">%</span>
                                </div>
                                <input type="text" readonly class="form-control form-control-sm"
                                    [value]="quote.financial_details.broker_commission">
                            </div>
                        </div>
                        <div *ngIf="quote_type != 4 && quote_type != 3 && quote_type != 5 && quote_type != 6 && quote_type != 7 && visibleOnlytoAdmin">
                            <mat-slider name="broker_commission" thumbLabel
                                [value]="quote.financial_details.broker_commission"
                                [(ngModel)]="quote.financial_details.broker_commission"
                                (change)="onBrokerComissionChange($event)" [displayWith]="formatSliderLabel" min="0"
                                max="{{slider_broker_comm_max}}" step="0.5">
                            </mat-slider>
                        </div>
                    </div>
                </div>

                <div *ngIf="visibleOnlytoAdmin">
                    <div class="row form-group">
                        <div class="col-md-4">Peliwica pay away commission</div>
                        <div class="col-md-6">
                            <div *ngIf="quote_type >= 3">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">%</span>
                                    </div>
                                    <input type="text" readonly class="form-control form-control-sm"
                                        [value]="quote.financial_details.peliwica_pay_away_commission">
                                </div>
                            </div>
                            <div *ngIf="quote_type != 4 && quote_type != 3 && quote_type != 5 && quote_type != 6 && quote_type != 7">
                                <mat-slider name="peliwica_pay_away_commission" thumbLabel
                                    [value]="quote.financial_details.peliwica_pay_away_commission"
                                    [(ngModel)]="quote.financial_details.peliwica_pay_away_commission"
                                    [displayWith]="formatSliderLabel" min="0" max="{{max_ppa_comission}}" step="0.5"
                                    (change)="onPPayAwayComissionChange($event)">
                                </mat-slider>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-4">Peliwica pay away gross commission</div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="text" name="payaway_gross_commision" placeholder="Gross Commission"
                                    class="form-control form-control-sm"
                                    [disabled]="quote_type >= 3"
                                    [value]="indicativeCalculationsDetails.full_pay_away_amount | number:'1.2-2'"
                                    readonly>
                            </div>
                            <!-- [value]="(indicativeCalculationsDetails.full_total_gross_primum * quote.financial_details.peliwica_pay_away_commission)/100 | number:'1.2-2'" -->
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-md-4">Peliwica retained commission</div>
                        <div class="col-md-6">
                            <div *ngIf="quote_type >= 3">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">%</span>
                                    </div>
                                    <input type="text" readonly class="form-control form-control-sm"
                                        [value]="quote.financial_details.peliwica_retained_commission">
                                </div>
                            </div>
                            <div *ngIf="quote_type != 4 && quote_type != 3 && quote_type != 5 && quote_type != 6 && quote_type != 7">
                                <mat-slider name="peliwica_retained_commission" thumbLabel [disabled]="quote_type == 4"
                                    [value]="quote.financial_details.peliwica_retained_commission"
                                    [(ngModel)]="quote.financial_details.peliwica_retained_commission"
                                    [displayWith]="formatSliderLabel" min="0" [max]="max_prt_comission" step="0.5"
                                    (change)="onPRetainedComissionChange($event)">
                                </mat-slider>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-4">Peliwica retained gross commission</div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="text" name="retained_gross_commision" placeholder="Gross Commission"
                                    class="form-control form-control-sm"
                                    [disabled]="quote_type >= 3"
                                    [value]="indicativeCalculationsDetails.full_peliwica_retained_amount | number:'1.2-2'"
                                    readonly>
                            </div>
                            <!-- [value]="(indicativeCalculationsDetails.full_total_gross_primum * quote.financial_details.peliwica_retained_commission)/100 | number:'1.2-2'" -->
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-4">Premium Loading</div>
                        <div class="col-md-6">
                            <div *ngIf="quote_type == 5 || quote_type == 6">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">%</span>
                                    </div>
                                    <input type="text" readonly class="form-control form-control-sm"
                                        [value]="quote.financial_details.premium_loading_percentage">
                                </div>
                            </div>
                            <div *ngIf="(quote_type != 5 &&  quote_type != 6) || isBroker">
                                <mat-slider name="premium_loading" thumbLabel
                                    [value]="quote.financial_details.premium_loading_percentage"
                                    [(ngModel)]="quote.financial_details.premium_loading_percentage"
                                    [displayWith]="formatSliderLabel" min="-100" max="100" step="0.5"
                                    (change)="onPreLoadingChange($event)">
                                </mat-slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-group" *ngIf="(quote_type <= 4  ) && !isBroker && tigerlabfield">
                    <div class="col-md-4">Peliwica Loading</div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="number" class="form-control form-control-sm"
                                (blur)="ontTgerLabPremLoadingbChange($event)"
                                [value]="quote.financial_details.tigerlab_premium_loading">
                        </div>
                    </div>
                </div>
                <div *ngIf="visibleOnlytoAdmin">
                    <div class="row form-group">
                        <div class="col-md-4">Policy premium</div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="text" name="premium_loading" placeholder="Total Premium"
                                    class="form-control form-control-sm"
                                    [disabled]="quote_type >= 3"
                                    [value]="indicativeCalculationsDetails.full_total_gross_primum" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-4">Underwriting Fee</div>
                        <div class="col-md-6">
                            <div *ngIf="quote_type >= 3">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="text" readonly class="form-control form-control-sm"
                                        [value]="quote.financial_details.uw_fee">
                                </div>
                            </div>
                            <div *ngIf="quote_type != 4 && quote_type != 3 && quote_type != 5 && quote_type != 6 && quote_type != 7">
                                <mat-slider name="uw_fee" thumbLabel [disabled]="quote_type == 4"
                                    [value]="quote.financial_details.uw_fee" (change)="onUWFeeChange($event)"
                                    [(ngModel)]="quote.financial_details.uw_fee" [displayWith]="formatUWSliderLabel"
                                    min="0" max="{{max_uw_fee}}" step="0.5">
                                </mat-slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div *ngIf="quote_type <= 3">
                    <button (click)="ProceedWithFullQuoteBtn(f.valid,f)" class="btn btn-default btn-gray">Proceed with
                        quote</button>
                </div>
                <div *ngIf="quote_type == 4">
                    <button class="btn btn-default btn-gray">Cancel</button>
                </div>
                <div *ngIf="quote_type == 5">
                    <button class="btn btn-default btn-gray">Lapse</button>
                </div>
                <div *ngIf="quote_type == 6">
                    <button class="btn btn-default btn-gray">Submit for NTU</button>
                </div>
                <div *ngIf="quote_type == 7">
                    <a class="btn btn-default btn-gray" (click)="ConvertToPolicy()">Convert to Policy</a>
                </div>
            </div>

        </div>
        <div class="col-md-4">
            <app-ajg-calculations [indicativeCalculationsDetails]="indicativeCalculationsDetails"
                [quoteInfo]="quoteDetails"></app-ajg-calculations>

        </div>
    </div>
    <ng-template #content let-modal>
        <div class="modal-body">
            <div class="helptext" [innerHTML]="helptext"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
        </div>
    </ng-template>
</form>