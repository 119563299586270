
<form class="pt-3" [formGroup]="forgotPassowrdForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <label for="exampleInputEmail">Email</label>
        <div class="input-group">
            <div class="input-group-prepend bg-transparent">
                <span class="input-group-text bg-transparent border-right-0">
                    <i class="mdi mdi-contact-mail text-primary"></i>
                </span>
            </div>
            <input type="email" name="email" formControlName="email" class="form-control form-control-lg border-left-0"
                placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
        </div>
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
        </div>
    </div>
    <div class="my-3">
        <button class="btn btn-block btn-success btn-lg font-weight-medium auth-form-btn">Submit</button>
    </div>
    <div class="my-2 d-flex justify-content-between align-items-center">
        <a routerLink="/" class="auth-link text-black"><i class="mdi mdi-arrow-left"></i>Back to login</a>
    </div>
</form>