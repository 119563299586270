<div [formGroup]="commonSection">
    <div class="form_sections">
        <mat-expansion-panel [expanded]="selectedTabIndex == 0 || quote_type > 3">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Quote Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group w-100">
                        <input type="text" readonly required name="policy_start_date_{{selectedTabIndex}}"
                            formControlName="policy_start_date" [disabled]="quote_type >= 6"
                            [value]="quoteDetailsFormControl.policy_start_date.value" placeholder="Policy Start Date"
                            class="form-control form-control-sm datepicker" matInput [min]="minPolicyDate"
                            [max]="maxPolicyDate" (dateChange)="onPolicyStartDateChange($event)"
                            [ngClass]="{ 'is-invalid': (quoteDetailsFormControl.policy_start_date.touched || submitted) && quoteDetailsFormControl.policy_start_date.errors?.required }"
                            (focus)="policyStartDatepicker.open()" [matDatepicker]="policyStartDatepicker">
                        <mat-datepicker-toggle [for]="policyStartDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #policyStartDatepicker></mat-datepicker>
                        <div class="invalid-feedback"
                            *ngIf="(quoteDetailsFormControl.policy_start_date.touched || submitted) && quoteDetailsFormControl.policy_start_date.errors?.required">
                            Policy start date is required
                        </div>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="!isBroker">
                    <mat-form-field class="w-100" style="margin-top: -8px;">
                        <mat-label>Broker</mat-label>
                        <mat-select formControlName="company_id" class="form-control form-control-sm" name="broker_id"
                            [ngClass]="{ 'is-invalid': (quoteDetailsFormControl.company_id.touched || submitted) && quoteDetailsFormControl.company_id.errors?.required }"
                            required (selectionChange)="onChangeBroker($event)"
                            [disabled]="quote_type > 3">
                            <mat-option [value]="">Select Broker</mat-option>
                            <mat-option *ngFor="let brk of brokers" [value]="brk.id">
                                {{brk.name}}
                            </mat-option>
                        </mat-select>
                        <div class="invalid-feedback"
                            *ngIf="(quoteDetailsFormControl.company_id.touched || submitted) && quoteDetailsFormControl.company_id.errors?.required">
                            Please select a broker.
                        </div>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6" *ngIf="quote_type == 3 || quote_type == 4 || quote_type == 5 ||  quote_type == 6 || QuoteTypeVisibility">
                    <mat-form-field class="w-100">
                        <mat-label>Transaction Type</mat-label>  
                        <mat-select formControlName="quote_type" class="form-control form-control-sm" name="quote_type"
                            [disabled]="!QuoteTypeVisibility" (selectionChange)="onQuoteTypeChange($event)">
                            <mat-option [value]="1">New Business</mat-option>
                            <mat-option [value]="2" [hidden]="QuoteDetails.livestock_meta.type_of_policy <= 2">Renewal</mat-option>
                            <mat-option [value]="3" [hidden]="QuoteTypeVisibility">MTA</mat-option>
                            <mat-option [value]="4" [hidden]="QuoteTypeVisibility">Cancelled</mat-option>
                            <mat-option [value]="5" [hidden]="QuoteTypeVisibility">Lapsed</mat-option>
                            <mat-option [value]="6" [hidden]="QuoteTypeVisibility">NTU</mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                </div>
                <div class="col-md-6" *ngIf="quote_type >= 3">
                    <mat-form-field class="w-100">
                        <mat-label>Policy Reason</mat-label>
                        <mat-select formControlName="mta_reason_id" [disabled]="quote_type == 7"
                            class="form-control form-control-sm" name="mta_reason_id"
                            [ngClass]="{ 'is-invalid': (quoteDetailsFormControl.mta_reason_id.touched || submitted) && quoteDetailsFormControl.mta_reason_id.errors?.required }"
                            required>
                            <mat-option *ngFor="let rsn of policyReasons" [value]="rsn.id">
                                {{rsn.reasons}}
                            </mat-option>
                            <mat-option [value]="1">Other</mat-option>
                        </mat-select>
                        <div class="invalid-feedback"
                            *ngIf="(quoteDetailsFormControl.mta_reason_id.touched || submitted) && quoteDetailsFormControl.mta_reason_id.errors?.required">
                            Please select a specific reason.
                        </div>
                    </mat-form-field>
                </div>
            </div>
            
            <div class="row" *ngIf="quoteDetailsFormControl.mta_reason_id.value == '1'">
                <div class="col-md-12">
                    <div class="form-group">
                        <textarea class="form-control" name="mta_reason_other" placeholder="Please specify reason"  
                        [readonly]="quote_type == 7"
                        [ngClass]="{ 'is-invalid': (quoteDetailsFormControl.mta_reason_other.touched || submitted) && quoteDetailsFormControl.mta_reason_other.errors?.required }"
                        required></textarea>
                        <div *ngIf="(quoteDetailsFormControl.mta_reason_other.touched || submitted) && quoteDetailsFormControl.mta_reason_other.errors?.required" class="invalid-feedback">
                            Please provide a specific reason.
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    <div class="form_sections">
        <mat-expansion-panel [expanded]="selectedTabIndex == 0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    About the type of policy required
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-group row mt-5 f13 text-white">
                <div class="col-md-9">
                    <mat-radio-group aria-label="Select an option" name="policy_type_{{selectedTabIndex}}"
                        formControlName="policy_type" required (change)="policyTypeChange($event)" [disabled]="quote_type > 3 || disableFields">
                        <div class="row mb-3">
                            <div class="col-md-9">
                                Is a Vendor Public Auction quote required?
                                <span class="ml-1" (click)="helpTextPopup(helpTextModal,'You require cover for Mortality and/or the Bull Breeding Warranty on an animal
                                you are selling at Public Auction. ')"><i
                                    class="mdi mdi-comment-question-outline"></i></span>
                            </div>
                            <div class="col-md-3">
                                <div class="quote_radio">
                                    <mat-radio-button [value]="1" ngDefaultControl formControlName="policy_type"
                                        required>
                                        Yes
                                    </mat-radio-button>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-9">
                                Is a Purchaser Public Auction quote required?
                                <span class="ml-1" (click)="helpTextPopup(helpTextModal,'You require cover for Mortality on an animal you are buying at Public Auction.')"><i
                                    class="mdi mdi-comment-question-outline"></i></span>
                            </div>
                            <div class="col-md-3">
                                <div class="quote_radio">
                                    <mat-radio-button [value]="2" ngDefaultControl formControlName="policy_type"
                                        required>
                                        Yes
                                    </mat-radio-button>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-9">
                                <!-- Is Private Sale Vendor or home bred cover required? -->
                                Is Private Sale Vendor cover required?
                                <span class="ml-1" (click)="helpTextPopup(helpTextModal,'You require cover for Bull Breeding Warranty on an animal you are selling by 
                                Private Sale.')"><i
                                    class="mdi mdi-comment-question-outline"></i></span>
                            </div>
                            <div class="col-md-3">
                                <div class="quote_radio">
                                    <mat-radio-button [value]="3" ngDefaultControl formControlName="policy_type"
                                        required>
                                        Yes
                                    </mat-radio-button>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-9">
                                Is Private Sale Purchaser or home bred cover required?
                                <span class="ml-1" (click)="helpTextPopup(helpTextModal,'You require cover for Mortality on an animal you are buying by Private Sale or 
                                you have now.')"><i
                                    class="mdi mdi-comment-question-outline"></i></span>
                            </div>
                            <div class="col-md-3">
                                <div class="quote_radio">
                                    <mat-radio-button [value]="4" ngDefaultControl formControlName="policy_type"
                                        required>
                                        Yes
                                    </mat-radio-button>
                                </div>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(quoteDetailsFormControl.policy_type.touched || submitted) && quoteDetailsFormControl.policy_type.errors?.required">
                                    Policy type is required
                                </div>
                            </div>
                        </div>
                    </mat-radio-group>

                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- </form> -->
    <ng-template #helpTextModal let-modal>
        <div class="modal-body">
            <div class="helptext" [innerHTML]="helptext"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
        </div>
    </ng-template>
    