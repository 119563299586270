export class AlpacaFinancialDetails {
    id: string = null;
    financial_detail_id: string = null;
    alpaca_rate: number = 0.0;
    alpaca_premium_amount: number = 0.0;
    cria_rate: number = 0.0;
    cria_premium_amount: number = 0.0;
    economic_slaughter_rate: number = 0.0;
    economic_slaughter_amount: number = 0.0;
    operation_rate: number = 0.0;
    operation_amount: number = 0.0;
    life_saving_rate: number = 0.0;
    life_saving_amount: number = 0.0;
    veterinary_rate: number = 0.0;
    veterinary_amount: number = 0.0;
    alpaca_total_premium: number = 0.0;
    liability_total_premium: number = 0.0;
    basic_liability_premium: number = 0.0;
    alpaca_premium: number = 0.0;
    minimum_basic_liability: number = 0.0;
    minimum_alpaca_premium: number = 0.0;
    minimum_acerage: number = 0.0;
    minimum_excess_acerage: number = 0.0;
    trailer_premium: number = 0.0;
    trailer_rate: number = 0.0;
    trailer_minimum_basic_premium: number = 0.0;
    visit_insured_premises_premium: number = 0.0;
    visit_to_external_premises_premium: number = 0.0;
    visit_to_trade_stand_premium: number = 0.0;
    promoting_and_selling_premium: number = 0.0;
    trade_stand_premium: number = 0.0;
    employer_liability_premium: number = 0.0;
    claim_loading_percentage: number = 0.0;
    claim_loading_amount: number = 0.0;
    indeminity_limit_id: number = 0.0;
}
