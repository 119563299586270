<h2 mat-dialog-title>Install Angular</h2>
<mat-dialog-content class="mat-typography"> heloooooooooooooooo
  <h3>Develop across all platforms</h3>
  <p>Learn one way to build applications with Angular and reuse your code and abilities to build
    apps for any deployment target. For web, mobile web, native mobile and native desktop.</p>

  <h3>Speed &amp; Performance</h3>
  <p>Achieve the maximum speed possible on the Web Platform today, and take it further, via Web
    Workers and server-side rendering. Angular puts you in control over scalability. Meet huge
    data requirements by building data models on RxJS, Immutable.js or another push-model.</p>

 
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>