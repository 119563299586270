<h4>Financial Transactions</h4>
    <div class="row form-group">
        <div class="col-md-4">Record creation date</div>
        <div class="col-md-6">
            <input type="text" name="creation_date" placeholder="Record creation date"
                class="form-control form-control-sm" [value]="quote.policy_converted_date | date:'dd-MM-yyyy'"
                readonly>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-md-4">Quote start date</div>
        <div class="col-md-6">
            <input type="text" name="creation_date" placeholder="Quote creation date"
                class="form-control form-control-sm"
                [value]="quote.policy_start_date | date:'dd-MM-yyyy'" readonly>
        </div>
    </div> 
    <div class="row mb-2">
        <div class="col-md-4">Quote Type</div>
        <div class="col-md-6">
            <mat-form-field class="w-100 float-left">
                <mat-select [(ngModel)]="quote.type" disabled class="form-control form-control-sm customDropdown"
                    name="quote_type">
                    <mat-option [value]="1">New Business</mat-option>
                    <mat-option [value]="2">Renewal</mat-option>
                    <mat-option [value]="3">MTA</mat-option>
                    <mat-option [value]="4">Cancelled</mat-option>
                    <mat-option [value]="5">Lapsed</mat-option>
                    <mat-option [value]="6">NTU</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-md-4">Net Premium</div>
        <div class="col-md-6">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-success text-white">&pound;</span>
                </div>
                <input type="text" name="net_premium" placeholder="Gross Premium" class="form-control"
                    [value]="this.quote.financial_details.policy_premium * (100-((this.quote.financial_details.broker_commission-0) + (this.quote.financial_details.peliwica_pay_away_commission-0) + (this.quote.financial_details.peliwica_retained_commission-0)))/100| number:'1.2-2'" readonly>
            </div>
        </div>
    </div>
    
    <div class="row form-group">
        <div class="col-md-4">Premium Loading</div>
        <div class="col-md-6">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-success text-white">&pound;</span>
                </div>
                <input type="text" name="premium_loading" placeholder="Premium Loading" class="form-control"
                [value]="quote.financial_details.premium_loading" readonly>
            </div>            
        </div>
    </div>
    <div class="row form-group">
        <div class="col-md-4">Total Premium (Excl IPT)</div>
        <div class="col-md-6">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-success text-white">&pound;</span>
                </div>
                <input type="text" name="retained_gross_commision" placeholder="Total Premium"
                class="form-control"
                [value]="quote.financial_details.policy_premium | number: '1.2-2'"
                readonly>
            </div>             
        </div>
    </div>
    <div class="row form-group">
        <div class="col-md-4">Insurance Premium Tax % (IPT)</div>
        <div class="col-md-6">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-success text-white">%</span>
                </div>
                <input type="text" name="retained_gross_commision" placeholder="IPT" class="form-control"
                [value]="quote.financial_details.ipt_fee" readonly>
            </div> 
            
        </div>
    </div>
    <div class="row form-group">
        <div class="col-md-4">Insurance Premium Tax &pound; (IPT)</div>
        <div class="col-md-6">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-success text-white">&pound;</span>
                </div>
                <input type="text" name="retained_gross_commision" placeholder="IPT" class="form-control"
                    [value]="(quote.financial_details.policy_premium * quote.financial_details.ipt_fee)/100 | number:'1.2-2'"
                    readonly>
            </div>             
        </div>
    </div>
    <div class="row form-group">
        <div class="col-md-4">Total Premium (Incl IPT)</div>
        <div class="col-md-6">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-success text-white">&pound;</span>
                </div>
                <input type="text" name="total_premium_ipt" placeholder="Total Premium (Incl IPT)"
                    class="form-control"
                    [value]="total_premium_ipt | number: '1.2-2'" readonly>
            </div>            
        </div>
    </div>
    <div class="row form-group">
        <div class="col-md-4">Fee</div>
        <div class="col-md-6">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-success text-white">&pound;</span>
                </div>
                <input type="text" name="uw_fee" placeholder="Underwriting Fee" class="form-control"
                    [value]="quote.financial_details.uw_fee | number: '1.2-2'"
                    readonly>
            </div>               
        </div>
    </div>
    <div class="row form-group">
        <div class="col-md-4">Total Amount To Pay</div>
        <div class="col-md-6">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-success text-white">&pound;</span>
                </div>
                <input type="text" name="total_amount_to_pay" placeholder="Total Amount To Pay"
                    class="form-control"
                    [value]="(quote.financial_details.total_policy_cost | number: '1.2-2')"
                    readonly>                
            </div> 
            
        </div>
    </div>