import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, AbstractControl, FormGroup, FormArray } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Amline } from 'src/app/model/quote/amline/amline.model';
import { AmlineService } from '../amline.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-fleet-experience',
  templateUrl: './fleet-experience.component.html',
  styleUrls: ['./fleet-experience.component.css', '../../quote.component.css']
})
export class FleetExperienceComponent implements OnInit {
  staticAPIData: any;
  @Input() staticData;
  @Input() modelObj;
  @Input() apiModelObj;
  frmFields: any;
  submitted = false;
  @Output() modelObjUpdated = new EventEmitter<Amline>();

  constructor(private fb: FormBuilder, private modalService: NgbModal, private as: AmlineService, private cdr: ChangeDetectorRef,
     private route: ActivatedRoute,private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.frmFields = this.fb.group({
      fleet_experiences: this.fb.array([
        this.initFleet()
      ]),

    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.frmFields.controls;
  }

  get FE(): FormArray {
    return this.frmFields.get("fleet_experiences") as FormArray
  }

  ngOnChanges(changes: SimpleChanges) {
    this.frmFields = this.fb.group({
      fleet_experiences: this.fb.array([
        this.initFleet()
      ]),

    });
    if (this.apiModelObj) {
      this.modelObj = this.apiModelObj.amlin_data.data.attributes.application;
      this.patchValues();
    }
  }
  patchValues() {
    if (this.modelObj && this.modelObj.fleet_experiences) {
      this.FE.clear();
      this.modelObj.fleet_experiences.forEach((res) => {
        let maxDate = new Date(res.start_date).setFullYear(new Date(res.start_date).getFullYear() + 1);
        let endDate = new Date(new Date(maxDate).getTime() - (24 * 60 * 60 * 1000));

        let data = this.fb.group({
          start_date: [res.start_date, Validators.required],
          end_date: [{ value: endDate, disabled: true }],
          years: [res.years, Validators.required],
          claims: [res.claims, Validators.required],
          details: [res.details],
          paid: this.fb.group({
            own_damage: [res.paid.own_damage, Validators.required],
            fire_theft: [res.paid.fire_theft, Validators.required],
            third_party: [res.paid.third_party, Validators.required]
          }),
          outstanding: this.fb.group({
            own_damage: [res.outstanding.own_damage, Validators.required],
            fire_theft: [res.outstanding.fire_theft, Validators.required],
            third_party: [res.outstanding.third_party, Validators.required]
          }),
        })
        this.FE.push(data);
      })
    }
  }

  /*
* Initialize Fleet Parameters
*/
  initFleet() {
    return this.fb.group({
      start_date: ['', Validators.required],
      end_date: [{ value: '', disabled: true }],
      years: ['', Validators.required],
      claims: ['', Validators.required],
      details: [''],
      paid: this.fleet_paid(),
      outstanding: this.outstanding(),
    })
  }
  fleet_paid(res: any = {}) {
    if (res) {
      return this.fb.group({
        own_damage: [res.own_damage, Validators.required],
        fire_theft: [res.fire_theft, Validators.required],
        third_party: [res.third_party, Validators.required]
      })
    } else {
      return this.fb.group({
        own_damage: ['', Validators.required],
        fire_theft: ['', Validators.required],
        third_party: ['', Validators.required]
      })
    }
  }
  outstanding() {
    return this.fb.group({
      own_damage: ['', Validators.required],
      fire_theft: ['', Validators.required],
      third_party: ['', Validators.required]
    })
  }



  /*
   * Add Another Year
   */
  addYear() {
    this.FE.push(this.initFleet());
  }

  removeYear(index: number) {
    this.FE.removeAt(index);
  }

  /*
 * HELP TEXT POPUP
 */
  helptext = '';
  helpTextPopup(content, text) {
    this.modalService.open(content, { centered: true });
    this.helptext = text;
  }

  endDate;
  onStartDateChange(event, index) {
    let maxDate = new Date(event.value).setFullYear(new Date(event.value).getFullYear() + 1);
    let endDate = new Date(new Date(maxDate).getTime() - (24 * 60 * 60 * 1000));
    this.FE.at(index).get('end_date').setValue(endDate);
  }

  /*
  * Set Paid/Outstanding Values to 0 if Claim Value is 0
  */
  onClaimValueChange(event, fleet_index) {
    if (event.target.value == 0) {
      this.FE.at(fleet_index).get('paid.own_damage').setValue(0);
      this.FE.at(fleet_index).get('paid.fire_theft').setValue(0);
      this.FE.at(fleet_index).get('paid.third_party').setValue(0);

      this.FE.at(fleet_index).get('outstanding.own_damage').setValue(0);
      this.FE.at(fleet_index).get('outstanding.fire_theft').setValue(0);
      this.FE.at(fleet_index).get('outstanding.third_party').setValue(0);
    } else {
      this.FE.at(fleet_index).get('paid.own_damage').setValue(null);
      this.FE.at(fleet_index).get('paid.fire_theft').setValue(null);
      this.FE.at(fleet_index).get('paid.third_party').setValue(null);

      this.FE.at(fleet_index).get('outstanding.own_damage').setValue(null);
      this.FE.at(fleet_index).get('outstanding.fire_theft').setValue(null);
      this.FE.at(fleet_index).get('outstanding.third_party').setValue(null);
    }
  }

  onSubmit() {
    this.submitted = true;
    if (!this.frmFields.valid) {
      return false;
    }
    let formData = this.frmFields.value;
    this.modelObj.fleet_experiences = formData.fleet_experiences;

    let data = {
      data: {
        "type": this.modelObj.type,
        "attributes": this.modelObj,
        "id": (this.route.snapshot.params.id) ? this.route.snapshot.params.id : this.modelObj.id
      }
    };

    this.as.saveQuote(data)
      .subscribe(
        (res: any) => {
          if (res.success) {
            if (res.result.quote_data && res.result.quote_data.quote_versions[0].amlin_data) {
              this.modelObj = res.result.quote_data.quote_versions[0].amlin_data.attributes.application;
              this.modelObj.id = res.result.quote_data.quote_versions[0].amlin_data.id;
              this.modelObj.reference = res.result.quote_data.quote_versions[0].amlin_data.attributes.reference;
              this.modelObj.status = res.result.quote_data.quote_versions[0].amlin_data.attributes.status;
              this.patchValues();
              this.modelObjUpdated.emit(this.modelObj);
            };
          } else {
            this._snackBar.open(res.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

}
