<div [formGroup]="animalSection" class="form_sections">
    <mat-expansion-panel
        [expanded]="selectedTabIndex == 1 || ( selectedTabIndex == 2 && quoteModelDetails.livestock_meta.type_of_policy >= 3) || ( selectedTabIndex == 6 && quoteModelDetails.livestock_meta.type_of_policy < 3)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                About the Animals to be insured
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="form_sections1">
            <!-- <h6>About the Animals to be insured</h6> -->
            <mat-radio-group class="row" aria-label="Select an option" name="animals_to_be_insured_{{selectedTabIndex}}"
                formControlName="animals_to_be_insured" required (change)="onAnimalsToBeRequiredChange($event)"
                [disabled]="quote_type > 3 || disableFields">
                <div class="col-md-4" *ngFor="let animal of liveStocks">
                    <div class="quote_radio w-90 mb-3">
                        <mat-radio-button [value]="animal.id"
                            [hidden]="(quoteModelDetails.livestock_meta.type_of_policy == 1 && animal.id=='10346458-ac55-4d2d-aef8-8c75101f8ce2') || (quoteModelDetails.livestock_meta.type_of_policy == 3 && animal.id !='10146458-ac55-4d2d-aef8-8c75101f8ce2')">
                            {{ animal.name }} </mat-radio-button>
                    </div>
                </div>
            </mat-radio-group>
            <div class="invalid-feedback" style="font-size: 13px;"
                *ngIf="(frmCtrl.animals_to_be_insured.touched || submitted) && frmCtrl.animals_to_be_insured.errors?.required">
                Please select an animal.
            </div>
        </div>
        <!----------------- ANIMALS SECTION------------------------->
        <div class="form_sections1 text-white mt-3" *ngIf="frmCtrl.animals_to_be_insured.value">
            <!-- <h6 *ngIf="isBullSelected && QuoteDetails.livestock_meta.type_of_policy > 2">About the Bull mortality, Loss
                of Use & limited theft cover</h6> -->
            <h6
                *ngIf="(isBullSelected && QuoteDetails.livestock_meta.type_of_policy < 3)  || (isEweSelected && QuoteDetails.livestock_meta.type_of_policy == 1) || (isHeiferSelected && QuoteDetails.livestock_meta.type_of_policy == 2)">
                Have the animals passed the
                Breed Society official collective sale inspection</h6>
            <!-- <h6 *ngIf="isEweSelected">About the Ram Mortality cover</h6> -->
            <!-- <h6 *ngIf="isHeiferSelected">About the Heifer & Cow(s)</h6>
            <p *ngIf="isHeiferSelected" class="f13">Mortality & Limited Theft cover only</p> -->
            <!-- <p *ngIf="isBullSelected && (quoteModelDetails.livestock_meta.type_of_policy ==1)" class="f13">Will a vet
                cert be provided at a point of insurance cover</p> -->
            <div
                *ngIf="(isBullSelected && QuoteDetails.livestock_meta.type_of_policy < 3) || (isEweSelected && QuoteDetails.livestock_meta.type_of_policy == 1) || (isHeiferSelected && QuoteDetails.livestock_meta.type_of_policy == 2)">
                <mat-radio-group aria-label="Select an option" formControlName="breed_society_inspection"
                    (change)="onResponseChange($event)" [disabled]="quote_type > 3 || disableFields">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="quote_radio w-90 mb-3">
                                <mat-radio-button [value]="1">Yes </mat-radio-button>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="quote_radio w-90 mb-3">
                                <mat-radio-button [value]="2"
                                    (click)="helpTextPopup(helpTextPopupModal,'If the animal has not passed the Breed Society Inspection we are not able to provide a quotation, please refer to your Insurance Broker.')">
                                    No</mat-radio-button>
                            </div>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
            <div class="row" *ngIf="selectedTabIndex != 6 && frmCtrl.animals_to_be_insured.value">
                <div class="col-md-6">
                    <div class="form-group">
                        <mat-form-field class="w-100">
                            <label *ngIf="isBullSelected">How many Bulls require insurance</label>
                            <mat-label *ngIf="isEweSelected">How many animals require insurance?</mat-label>
                            <label *ngIf="isHeiferSelected">How many Heifer(s) & Cow(s) require insurance?</label>
                            <mat-select formControlName="total_animals_require_insurance"
                                class="form-control form-control-sm" required
                                [disabled]="quote_type > 3 || disableFields"
                                (selectionChange)="onAnimalInsuranceChange($event)"
                                [ngClass]="{ 'is-invalid': (frmCtrl.total_animals_require_insurance.touched || submitted) && frmCtrl.total_animals_require_insurance.errors?.required }">
                                <mat-option *ngFor="let values of totalBullsInsurance" [value]="values">
                                    {{values}}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback" style="font-size: 13px;"
                                *ngIf="(frmCtrl.total_animals_require_insurance.touched || submitted) && frmCtrl.total_animals_require_insurance.errors?.required">
                                Please select an animal.
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-animals-additonal-details [frmCtrl]="frmCtrl"></app-animals-additonal-details> -->
        <div class="text-white mt-3" formArrayName="animal_details"
            *ngFor="let animals of animalsArr.controls; let i = index;">
            <div class="form_sections1">
                <h6 *ngIf="i==0 &&  selectedTabIndex != 6">
                    Give details for each animal <span *ngIf="isBullSelected">-
                        Cover can be given from breeding up to 4 years old</span>
                    <span class="ml-1" (click)="helpTextPopup(helpTextPopupModal,agesHelpText)"><i
                            class="mdi mdi-comment-question-outline"></i></span>
                </h6>
                <div [formGroupName]="i" class=" mt-3">
                    <label style="font-size: 16px;"><u>Animal {{i + 1}}</u></label>
                    <div class="row">
                        <div class="col-md-4"
                            *ngIf="selectedTabIndex == 6 || quoteModelDetails.livestock_meta.type_of_policy >= 3 || isView">
                            <div class="form-group">
                                <input type="text" name="tag_no_{{selectedTabIndex}}" placeholder="Tag Number"
                                    [readonly]="quote_type > 3"
                                    [ngClass]="{ 'is-invalid': (animals.controls.tag_no.touched || submitted) && animals.controls.tag_no.errors?.required }"
                                    class="form-control form-control-sm" formControlName="tag_no" required>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(animals.controls.tag_no.touched || submitted) && animals.controls.tag_no.errors?.required">
                                    Tag number is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4"
                            *ngIf="selectedTabIndex == 6 || quoteModelDetails.livestock_meta.type_of_policy >= 3 || isView">
                            <div class="form-group">
                                <input type="text" name="animal_name_{{selectedTabIndex}}" placeholder="Name"
                                    [readonly]="quote_type > 3"
                                    [ngClass]="{ 'is-invalid': (animals.controls.animal_name.touched || submitted) && animals.controls.animal_name.errors?.required }"
                                    class="form-control form-control-sm" formControlName="animal_name" required>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(animals.controls.animal_name.touched || submitted) && animals.controls.animal_name.errors?.required">
                                    Animal name is required.
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4" *ngIf="quoteModelDetails.livestock_meta.type_of_policy < 3">
                            <div class="form-group mt-2">
                                <input type="text" name="lot_no_{{selectedTabIndex}}" placeholder="Lot No."
                                    [readonly]="quote_type > 3 || disableFields"
                                    [ngClass]="{ 'is-invalid': (animals.controls.lot_no.touched || submitted) && animals.controls.lot_no.errors?.required }"
                                    class="form-control form-control-sm" formControlName="lot_no" required>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(animals.controls.lot_no.touched || submitted) && animals.controls.lot_no.errors?.required">
                                    Lot number is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group"
                                [ngClass]="(selectedTabIndex == 6 || quoteModelDetails.livestock_meta.type_of_policy >= 3 || isView)?'':'mt-2'">
                                <input type="text" name="dob_{{selectedTabIndex}}" placeholder="DOB"
                                    style="width:79%; float: left;"
                                    [ngClass]="{ 'is-invalid': (animals.controls.dob.touched || submitted) && animals.controls.dob.errors?.required }"
                                    class="form-control form-control-sm" formControlName="dob" required
                                    [matDatepicker]="policyStartDatepicker" matInput
                                    [disabled]="quote_type > 3 || isQuoteView" (focus)="policyStartDatepicker.open()"
                                    [min]="minPolicyDate" [max]="maxDOB">
                                <mat-datepicker-toggle [for]="policyStartDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #policyStartDatepicker></mat-datepicker>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(animals.controls.dob.touched || submitted) && animals.controls.dob.errors?.required">
                                    DOB is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="w-100">
                                <mat-label>Breed</mat-label>
                                <mat-select formControlName="bull_breed" class="form-control form-control-sm"
                                    name="bull_breed" (selectionChange)="onBreedChange($event,i)"
                                    [disabled]="quote_type > 3 || disableFields" required
                                    [ngClass]="{ 'is-invalid': (animals.controls.bull_breed.touched || submitted) && animals.controls.bull_breed.errors?.required }">
                                    <mat-option *ngFor="let value of livestockBreeds" [value]="value.id">
                                        {{value.breed}}
                                    </mat-option>
                                </mat-select>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(animals.controls.bull_breed.touched || submitted) && animals.controls.bull_breed.errors?.required">
                                    Please select animal breed.
                                </div>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4"
                            *ngIf="animals.controls.bull_breed.value == '77777777-ac55-4d2d-aef8-8c75101f8ce2' || animals.controls.bull_breed.value == '88888888-ac55-4d2d-aef8-8c75101f8ce2' || animals.controls.bull_breed.value == '99999999-ac55-4d2d-aef8-8c75101f8ce2'">
                            <div class="form-group mt-2">
                                <input type="text" name="livestock_breed_other_{{selectedTabIndex}}"
                                    placeholder="Other Breed"
                                    [ngClass]="{ 'is-invalid': (animals.controls.livestock_breed_other.touched || submitted) && animals.controls.livestock_breed_other.errors?.required }"
                                    class="form-control form-control-sm" formControlName="livestock_breed_other"
                                    required>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(animals.controls.livestock_breed_other.touched || submitted) && animals.controls.livestock_breed_other.errors?.required">
                                    Please fill other breed type.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group mt-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="text" name="sale_price" class="form-control form-control-sm" required
                                        placeholder="{{(quoteModelDetails.livestock_meta.type_of_policy >= 3)?'Purchase price' : 'Sale Price'}}"
                                        formControlName="sale_price" appDecimalNumbers
                                        [readonly]="quote_type > 3 || disableFields"
                                        (blur)="purchasePriceChange(helpTextPopupModal, $event, i)"
                                        [ngClass]="{ 'is-invalid': (animals.controls.sale_price.touched || submitted) && animals.controls.sale_price.errors?.required }">
                                    <div class="invalid-feedback" style="font-size: 13px;"
                                        *ngIf="(animals.controls.sale_price.touched || submitted) && animals.controls.sale_price.errors?.required">
                                        price is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div class="col-md-4" *ngIf="!isBullSelected">
                            <mat-form-field class="w-100 mb-2">
                                <mat-label>Sex</mat-label>
                                <mat-select formControlName="sex" class="form-control form-control-sm" name="sex"
                                    (selectionChange)="onGenderChange($event,i)"
                                    [disabled]="quote_type > 3 || disableFields" required
                                    [ngClass]="{ 'is-invalid': (animals.controls.sex.touched || submitted) && animals.controls.sex.errors?.required }">
                                    <mat-option [value]="value.id" *ngFor="let value of livestockGender">
                                        {{ value.sex }}
                                    </mat-option>
                                </mat-select>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(animals.controls.sex.touched || submitted) && animals.controls.sex.errors?.required">
                                    Livestock sex is required.
                                </div>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4" *ngIf="coverRequired">
                            <mat-form-field class="w-100">
                                <mat-label>Cover required</mat-label>
                                <mat-select formControlName="cover_required" class="form-control form-control-sm"
                                    name="cover_required" (selectionChange)="onLivestockCoverChange($event,i)"
                                    [disabled]="quote_type == 3 || disableFields" required
                                    [ngClass]="{ 'is-invalid': (animals.controls.cover_required.touched || submitted) && animals.controls.cover_required.errors?.required }">
                                    <mat-option [value]="value.id" *ngFor="let value of livestockCovers;"
                                        [hidden]="(value.id=='70146458-ac55-4d2d-aef8-8c75101f8ce2' && isEweSelected && quoteModelDetails.livestock_meta.type_of_policy == 1) || (quoteModelDetails.livestock_meta.type_of_policy == 2 && isBullSelected && value.id=='70146458-ac55-4d2d-aef8-8c75101f8ce2')">
                                        {{ value.cover }}</mat-option>
                                </mat-select>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(animals.controls.cover_required.touched || submitted) && animals.controls.cover_required.errors?.required">
                                    Please select a cover.
                                </div>
                            </mat-form-field>
                        </div>
                        <!-- <div class="col-md-4"
                            *ngIf="coverRequired && displayCoverPeriod && animals.controls.cover_required.value"> -->
                        <div class="col-md-4"
                            *ngIf="coverRequired && displayCoverPeriod && animals.controls.cover_required.value && (!isBullSelected || (isBullSelected && animals.controls.cover_required.value == '70246458-ac55-4d2d-aef8-8c75101f8ce2'))">
                            <mat-form-field class="w-100">
                                <mat-label>Cover period</mat-label>
                                <mat-select formControlName="cover_period" class="form-control form-control-sm"
                                    name="cover_period" (selectionChange)="onCoverPeriodChange($event,i)"
                                    [disabled]="quote_type == 3 || disableFields" required
                                    [ngClass]="{ 'is-invalid': (animals.controls.cover_period.touched || submitted) && animals.controls.cover_period.errors?.required }">
                                    <mat-option [value]="value.id" *ngFor="let value of CoverPeriods[i];">{{
                                        value.period }} months</mat-option>
                                </mat-select>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(animals.controls.cover_period.touched || submitted) && animals.controls.cover_period.errors?.required">
                                    Please select a cover period.
                                </div>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4"
                            *ngIf="isBullSelected && (quoteModelDetails.livestock_meta.type_of_policy == 1 || quoteModelDetails.livestock_meta.type_of_policy == 3) && quoteModelDetails.livestock_meta.type_of_policy != 4">
                            <div class="row">
                                <div class="pr-0" [ngClass]="isBullSelected?'col-10':'col-12'">
                                    <mat-form-field class="w-100">
                                        <mat-label>Semen tested?</mat-label>
                                        <mat-select formControlName="semen_tested" class="form-control form-control-sm"
                                            name="semen_tested" (selectionChange)="onSemenTestedChange(i)"
                                            [disabled]="isSemenTested || quote_type > 3 || disableFields"
                                            [ngClass]="{ 'is-invalid': (animals.controls.semen_tested.touched || submitted) && animals.controls.semen_tested.errors?.required }">
                                            <mat-option [value]="1">Yes</mat-option>
                                            <mat-option [value]="2">No</mat-option>
                                        </mat-select>
                                        <div class="invalid-feedback" style="font-size: 13px;"
                                            *ngIf="(animals.controls.semen_tested.touched || submitted) && animals.controls.semen_tested.errors?.required">
                                            Please select semen test.
                                        </div>
                                    </mat-form-field>
                                </div>
                                <div class="col-2 pl-1" *ngIf="isBullSelected">
                                    <span style="cursor: pointer;" (click)="helpTextPopup(helpTextPopupModal,'If you select this option of semen tested, 
                                        the bull must have passed a semen test withing 30 days prior to the sale and 
                                        evidence of this is required in the event of a claim.')"><i
                                            class="mdi mdi-comment-question-outline"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4" [hidden]="hideVetCert"
                            *ngIf="(isEweSelected && animals.controls.sale_price.value > 1000) || (isBullSelected && (quoteModelDetails.livestock_meta.type_of_policy ==1||quoteModelDetails.livestock_meta.type_of_policy >= 3)) || (isHeiferSelected && quoteModelDetails.livestock_meta.type_of_policy == 4)">
                            <div class="row">
                                <div class="pr-0" [ngClass]="isBullSelected?'col-10':'col-11'">
                                    <mat-form-field class="w-100">
                                        <mat-label>Vets cert provided</mat-label>
                                        <mat-select formControlName="vet_cert_provided"
                                            class="form-control form-control-sm" name="vet_cert_provided"
                                            (selectionChange)="onVetCertChange(i,$event,helpTextPopupModal)"
                                            [disabled]="quote_type == 3 || disableFields" required
                                            [ngClass]="{ 'is-invalid': (animals.controls.vet_cert_provided.touched || submitted) && animals.controls.vet_cert_provided.errors?.required }">

                                            <mat-option [value]="1" *ngIf="!isEweSelected"
                                                [hidden]="!quoteModelDetails.isButton && quoteModelDetails.livestock_meta.type_of_policy ==1 && isBullSelected">
                                                Yes</mat-option>
                                            <mat-option [value]="2" *ngIf="!isEweSelected"
                                                [hidden]="!quoteModelDetails.isButton && quoteModelDetails.livestock_meta.type_of_policy ==1 && isBullSelected">
                                                No</mat-option>
                                            <mat-option [value]="3" *ngIf="!isEweSelected"
                                                [hidden]="quoteModelDetails.livestock_meta.type_of_policy >= 3">
                                                Passed
                                                pre-sale
                                                inspection</mat-option>
                                            <mat-option [value]="2" *ngIf="isEweSelected && !hideVetCertOptionNo">
                                                No cert required for renewal </mat-option>
                                            <mat-option [value]="1" *ngIf="isEweSelected">
                                                Yes subject to vet cert being provided</mat-option>

                                        </mat-select>
                                        <div class="invalid-feedback" style="font-size: 13px;"
                                            *ngIf="(animals.controls.vet_cert_provided.touched || submitted) && animals.controls.vet_cert_provided.errors?.required">
                                            Please select vet cert.
                                        </div>
                                    </mat-form-field>
                                </div>
                                <div class="col-2 pl-1" *ngIf="vetCertHelpText">
                                    <span style="cursor: pointer;"
                                        (click)="helpTextPopup(helpTextPopupModal,vetCertHelpText)"><i
                                            class="mdi mdi-comment-question-outline"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="trialPeriod">
                        <div class="row mt-2">
                            <div class="col-md-6">
                                <label style="font-size: 12px;">Is the Trial Period to be deferred?</label>
                                <mat-radio-group class="row" aria-label="Select an option"
                                    (change)="onTrialPeriodChange($event, i)" name="is_trial_period_deferred_{{i}}"
                                    formControlName="is_trial_period_deferred" #is_trial_period_deferred required>
                                    <div class="col-md-6">
                                        <div class="quote_radio w-90 mb-3">
                                            <mat-radio-button [value]="1">Yes </mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="quote_radio w-90 mb-3">
                                            <mat-radio-button [value]="2">No</mat-radio-button>
                                        </div>
                                    </div>
                                </mat-radio-group>
                                <div class="invalid-feedback" style="font-size: 13px;"
                                    *ngIf="(animals.controls.is_trial_period_deferred.touched || submitted) && animals.controls.is_trial_period_deferred.errors?.required">
                                    Please select trial period.
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3" *ngIf="animals.controls.is_trial_period_deferred.value == 1">
                            <div class="col-md-6">
                                <label>Date of trial period to commence from</label>
                                <div class="form-group w-100">
                                    <input type="text" readonly required name="trial_period_date"
                                        [min]="minTrialPeriodDate" [max]="maxTrialPeriodDate"
                                        formControlName="trial_period_date" [disabled]="quote_type == 6"
                                        placeholder="Date" class="form-control form-control-sm datepicker" matInput
                                        [ngClass]="{ 'is-invalid': (animals.controls.trial_period_date.touched || submitted) && animals.controls.trial_period_date.errors?.required }"
                                        (focus)="trialperiodDatepicker.open()" [matDatepicker]="trialperiodDatepicker">
                                    <mat-datepicker-toggle [for]="trialperiodDatepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #trialperiodDatepicker></mat-datepicker>
                                    <div class="invalid-feedback"
                                        *ngIf="(animals.controls.trial_period_date.touched || submitted) && animals.controls.trial_period_date.errors?.required">
                                        Date is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form_sections1"
                        *ngIf="(quoteModelDetails.livestock_meta.type_of_policy == 1 || quoteModelDetails.livestock_meta.type_of_policy == 3) && ((selectedTabIndex == 6 || selectedTabIndex == 2) || isView) 
                        && (animals.controls.cover_required.value == '70246458-ac55-4d2d-aef8-8c75101f8ce2' || animals.controls.cover_required.value == '70346458-ac55-4d2d-aef8-8c75101f8ce2')">
                        <mat-expansion-panel expanded>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Purchaser Details
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row" formArrayName="livestock_purchasers"
                                *ngFor="let prhs of animals.get('livestock_purchasers').controls; let ip=index">
                                <div class="col-md-11" [formGroupName]="ip">
                                    <label class="text-white">Purchaser {{ip + 1}}</label>
                                    <span *ngIf="ip >= 1">
                                        <a class="btn btn-danger btn-sm text-white ml-3"
                                            (click)="removePurchasers(animals, ip)" style="padding: 5px;">
                                            <i class="mdi mdi-delete"></i>
                                        </a>
                                    </span>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="first_name" placeholder="First Name"
                                                    [ngClass]="{ 'is-invalid': (prhs.get('first_name').touched || submitted) && prhs.get('first_name').errors?.required }"
                                                    class="form-control form-control-sm" formControlName="first_name"
                                                    required>
                                                <div class="invalid-feedback" style="font-size: 13px;"
                                                    *ngIf="(prhs.get('first_name').touched || submitted) && prhs.get('first_name').errors?.required">
                                                    First name is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="last_name" placeholder="Last Name"
                                                    class="form-control form-control-sm" formControlName="last_name">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="email" name="email" placeholder="Email"
                                                    class="form-control form-control-sm" formControlName="email"
                                                    required
                                                    pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                                                    [ngClass]="{ 'is-invalid': (prhs.get('email').touched || submitted) && prhs.get('email').errors?.required }"
                                                    appTitleCase>
                                                <div class="invalid-feedback"
                                                    *ngIf="prhs.get('email').touched || submitted">
                                                    <div *ngIf="prhs.get('email').errors?.required">Email is
                                                        required.
                                                    </div>
                                                    <div *ngIf="prhs.get('email').errors?.pattern">Please provide a
                                                        valid
                                                        email address
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">



                                            <div class="form-group">
                                                <input type="text" name="postcode" placeholder="Postcode"
                                                    class="form-control form-control-sm" autocomplete="off"
                                                    (keyup)="prhs.get('postcode').errors?'':postcodeLookup($event)"
                                                    pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr]?0[Aa]{2})$"
                                                    formControlName="postcode"
                                                    [ngClass]="{ 'is-invalid': (prhs.get('postcode').touched || submitted)  && prhs.get('postcode').errors?.required }"
                                                    [matAutocomplete]="auto" appUpperCase>
                                                <div *ngIf="prhs.get('postcode').errors?.pattern"
                                                    class="invalid-feedback">
                                                    Invalid postcode.
                                                </div>
                                                <div>
                                                    <mat-autocomplete #auto="matAutocomplete">
                                                        <div *ngIf="!prhs.get('postcode').errors">
                                                            <mat-option [value]="prhs.get('postcode').value"
                                                                (click)="onSelectAddress(adrs,i, prhs)"
                                                                *ngFor="let adrs of addresses">
                                                                {{adrs.formatted_address.join(', ')}}</mat-option>
                                                        </div>
                                                    </mat-autocomplete>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="address1" placeholder="Line 1"
                                                    formControlName="address1" class="form-control form-control-sm"
                                                    appTitleCase
                                                    [ngClass]="{ 'is-invalid': (prhs.get('address1').touched || submitted) && prhs.get('address1').errors?.required }"
                                                    required>
                                                <div *ngIf="(prhs.get('address1').touched || submitted) && prhs.get('address1').errors?.required"
                                                    class="invalid-feedback">
                                                    Address 1 is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="address2" placeholder="Line 2"
                                                    formControlName="address2" class="form-control form-control-sm"
                                                    appTitleCase>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="city" placeholder="Town" formControlName="city"
                                                    class="form-control form-control-sm" appTitleCase
                                                    [ngClass]="{ 'is-invalid': (prhs.get('city').touched || submitted) && prhs.get('city').errors?.required }"
                                                    required>
                                                <div *ngIf="(prhs.get('city').touched || submitted) && prhs.get('city').errors?.required"
                                                    class="invalid-feedback">
                                                    City is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="country" placeholder="County"
                                                    formControlName="country" class="form-control form-control-sm"
                                                    appTitleCase
                                                    [ngClass]="{ 'is-invalid': (prhs.get('country').touched || submitted) && prhs.get('country').errors?.required }"
                                                    required>
                                                <div *ngIf="(prhs.get('country').touched || submitted) && prhs.get('country').errors?.required"
                                                    class="invalid-feedback">
                                                    County is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text bg-success text-white">%</span>
                                                    </div>
                                                    <input type="number" name="ownership" placeholder="Ownership"
                                                        required class="form-control form-control-sm"
                                                        formControlName="ownership" min="0" max="100"
                                                        (keyup)="ownershipValue($event, ip, animals)"
                                                        (blur)="ownershipValueBlurEvent($event, ip, animals)"
                                                        [ngClass]="{ 'is-invalid': (prhs.get('ownership').touched || submitted) && prhs.get('ownership').errors?.required }"
                                                        appDecimalNumbers>
                                                    <div class="invalid-feedback" style="font-size: 13px;"
                                                        *ngIf="(prhs.get('ownership').touched || submitted) && prhs.get('ownership').errors?.required">
                                                        Please enter the correct ownership value. It should not
                                                        exceed
                                                        the sum of 100 for all the purchasers.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <a (click)="addPurchasers(animals)" class="btn btn-success">Add Purchasers</a>
                        </mat-expansion-panel>
                    </div>
                </div>
            </div>
        </div>
        <!-- <app-lv-animals-additonal-details></app-lv-animals-additonal-details> -->
        <div class="form_sections1 text-white mt-4" [hidden]="hideBreedingWarranty"
            *ngIf="(isBullSelected && (quoteModelDetails.livestock_meta.type_of_policy == 1 || quoteModelDetails.livestock_meta.type_of_policy == 3)) || (isEweSelected && (quoteModelDetails.livestock_meta.type_of_policy <= 2))">
            <h6>Breeding warranty cover</h6>
            <mat-radio-group class="row" aria-label="Select an option"
                name="breeding_warranty_cover_{{selectedTabIndex}}" formControlName="breeding_warranty_cover"
                #breeding_warranty_cover>
                <div class="col-md-6">
                    <div class="quote_radio w-90 mb-3">
                        <mat-radio-button [value]="1">NBA Breeding Warranty </mat-radio-button>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="quote_radio w-90 mb-3">
                        <mat-radio-button [value]="2">Other society Breeding warranty</mat-radio-button>
                    </div>
                </div>
            </mat-radio-group>
            <div class="invalid-feedback" style="font-size: 13px;"
                *ngIf="(frmCtrl.breeding_warranty_cover.touched || submitted) && frmCtrl.breeding_warranty_cover.errors?.required">
                Please select breeding warranty cover.
            </div>
            <div class="form-group row" *ngIf="frmCtrl.breeding_warranty_cover.value == 2">
                <div class="col-md-6 mt-3">
                    <input type="text" name="breeding_society_name_{{selectedTabIndex}}" [readonly]="quote_type > 3"
                        class="form-control form-control-sm" placeholder="Enter the name of the Breeding Society"
                        formControlName="breeding_society_name">
                </div>
            </div>
        </div>
        <div class="form_sections1 text-white mt-3" [hidden]="quoteModelDetails.is_policy == 1 || isQuoteView"
            *ngIf="frmCtrl.animals_to_be_insured.value && selectedTabIndex != 6 && quoteModelDetails.livestock_meta.type_of_policy < 3">
            <h6>How many claims have you had in the last 2 years</h6>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-label>Claims</mat-label>
                        <mat-select formControlName="claims" class="form-control form-control-sm" name="claims"
                            [disabled]="quote_type > 3" (selectionChange)="onChangeClaim($event)" required
                            [ngClass]="{ 'is-invalid': (frmCtrl.claims.touched || submitted) && frmCtrl.claims.errors?.required }">
                            <mat-option *ngFor="let claim of allClaims" [value]="claim.number_of_claims">
                                {{ claim.number_of_claims + ((claim.number_of_claims < 2)?' Claim':' Claims') }}
                                    </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="invalid-feedback" style="font-size: 13px;"
                        *ngIf="(frmCtrl.claims.touched || submitted) && frmCtrl.claims.errors?.required">
                        Please select a claim.
                    </div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</div>
<ng-template #helpTextPopupModal let-modal>
    <div class="modal-body">
        <div class="helptext" [innerHTML]="msg"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>