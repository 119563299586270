import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { AlQuoteService } from '../../al-quote/al-quote.service';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import { UserInfoService } from '../../../../services/user-info.service';
import { QuoteService } from '../../../quote.service';

@Component({
  selector: 'app-admin-quote-agq',
  templateUrl: './quote-agq.component.html',
  styleUrls: ['../../../quote.component.css']
})
export class AlQuoteAgqComponent implements OnInit {
  @Input() quoteData: QuoteVersions;
  agreedQuestions = [];
  quote;
  constructor(private quoteService: AlQuoteService, private modalService: NgbModal, private userService: UserInfoService,private adminQuoteService: QuoteService) { }
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteData;
    // Get All Questions
    this.adminQuoteService.getAgreedQues(environment.ALPACA_ID,this.quote.agreed_question_version)
    .subscribe(
      (response: any) => {
        if (response.success) {
          this.agreedQuestions = response.result.agreed_questions;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  ngOnInit(): void {

  }
}
