<mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChanged($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            Quote
        </ng-template>
        <div class="card-body">
            <app-ajg-indicative-quote [quoteDetails]="quoteDetailsForChildTemplates" 
            (quoteDetailsUpdated)="quoteDetailsUpdated($event)"     
            (ProceedFullDetailsBtn)="activateFullDetailsTab()" [staticData]="staticData"></app-ajg-indicative-quote>
        </div>
    </mat-tab>
    <mat-tab [disabled]="!full_details_tab" (quoteDetailsUpdated)="quoteDetailsUpdated($event)" >
         <ng-template mat-tab-label>
            Full Details
        </ng-template>
        <div class="card-body">
            <app-ajg-full-details [quoteDetails]="quoteDetailsForChildTemplates"
                (quoteDetailsUpdated)="quoteDetailsUpdated($event)"  
                (BackToQuoteDetails)="jumpToIndicativeQuoteDetilsTab()"
                (ProceedToStatementOfFact)="jumpToStatementOfFactTab()"></app-ajg-full-details>
        </div>
    </mat-tab>
    <mat-tab [disabled]="!statement_of_facts_tab">
        <ng-template mat-tab-label>
            Statement Of Fact
        </ng-template>
        <div class="card-body">
            <app-ajg-fact-statement [quoteDetails]="quoteDetailsForChildTemplates"
                (quoteDetailsUpdated)="quoteDetailsUpdated($event)"  
                (BackToFullDetails)="jumpToFullQuoteDetilsTab()" (ProceedToPayment)="jumpToPayment()">
            </app-ajg-fact-statement>
        </div>
    </mat-tab>

    <mat-tab [disabled]="!payment_details">
        <ng-template mat-tab-label>
            Payment Details
        </ng-template>
        <div class="card-body">
            <app-ajg-payment [quoteDetails]="quoteDetailsForChildTemplates"
                (quoteDetailsUpdated)="quoteDetailsUpdated($event)"  
                (BackToFactStatement)="jumpToStatementOfFactTab()"
                (UnderWritingSubmission)="jumpToUnderWritingSubmissionTab()"></app-ajg-payment>
        </div>
    </mat-tab>
    <mat-tab [disabled]="!submission">
        <ng-template mat-tab-label>
            Submission
        </ng-template>
        <div class="card-body">
            <app-ajg-submission (quoteDetailsUpdated)="quoteDetailsUpdated($event)" 
            [quoteDetails]="quoteDetailsForChildTemplates" ></app-ajg-submission>
        </div>
    </mat-tab>
</mat-tab-group>