import { Component, OnInit } from '@angular/core';
import {BordereauService } from '../../bordereau/bordereau.service';
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-bordereau-lapse',
  templateUrl: './report.component.html',
  styleUrls: ['../../quote/quote.component.css']
})
export class ReportComponent implements OnInit {

  constructor(private bordereauService: BordereauService) { }

  start_date: string = '';
  end_date: string = '';
  product: string = '';
  allProducts = [];

  ngOnInit(): void {
    this.bordereauService.getProducts()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.allProducts = response.result;
          }
        });
  }

  onSubmit(f: NgForm) {
    let data: any = '';
    data = {
      start_date: formatDate(this.start_date, 'yyyy-MM-dd', 'en-US'),
      end_date: formatDate(this.end_date, 'yyyy-MM-dd', 'en-US'),
      product: this.product,
    };
    this.bordereauService.policy(data)
      .subscribe(
        (response: any) => {  
          var blob=new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            var link=document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download="policies.xlsx";
            link.click();          
        });
  }

}
