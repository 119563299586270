<form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit(f)">
    <div class="row">
        <div class="col-md-8">
 
            <div class="bg-white text-green p-5 mb-5 text-center payment">
                <img src="assets/images/invoice.webp" width="100" alt="invoice" />
                <h5 class="mt-3">BORDEREAUX INVOICE</h5>
                <br>
                <p>If cover is selected by you following the Peliwica underwriting process, the policy will be added to your next 
                    bordereaux and invoiced at the beginning of the month. Payment terms are 14 days. </p>

            </div>
            <h4 class="mt-3">Notes</h4>
            <div class="alert alert-danger" style="font-size: small;">
                <label>Please note that cover CANNOT be confirmed to the customer prior to our acceptance and agreement. If you wish to accept a quotation, this will only be confirmed as on-cover once the full policy documents have been issued. Please state clearly if you require an urgent quotation for this submission.</label>
            </div>
            <div class="form-group">
                <textarea class="form-control" name="convert_to_quote_notes" required id="notes" 
                (change) ="quote.convert_to_quote_notes = quote.convert_to_quote_notes.trim()"
                [(ngModel)]="quote.convert_to_quote_notes" #convert_to_quote_notes="ngModel" 
                [ngClass]="{ 'is-invalid': f.submitted && convert_to_quote_notes.invalid }"></textarea>
                <div *ngIf="f.submitted && convert_to_quote_notes.invalid" class="invalid-feedback">
                    Notes are required.
                </div>
            </div>
            <div class="form-group">
                <a (click)="backToFactStatementBtn()" class="btn btn-default btn-gray mr-3">Back</a>
                <input type="submit" name="proceed" class="btn btn-success btn-gray" value="Accept terms and submit">
            </div>
        </div>
        <div class="col-md-4">
            <app-ajg-calculations [quoteInfo]="quoteDetails"
                [indicativeCalculationsDetails]="indicativeCalculationsDetails"></app-ajg-calculations>
        </div>
    </div>

</form>