<form class="g-3 needs-validation" novalidate [formGroup]="frmFields" (ngSubmit)="onSubmit(content,referral)">
    <div class="row" *ngIf="premiums">
        <div class="col-md-8">

            <h4>Quote Summary</h4>
            <div class="form_sections" style="min-height: 411px;">
                <div class="row">
                    <div class="col-md-5 fw-300">Compulsory Excess</div>
                    <div class="col-md-7 fw-500">£{{ premiums.compulsory_excess }} {{ comp_cover_type }}</div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-5 fw-300">Additional Vehicle Excess</div>
                    <div class="col-md-7 fw-500">None</div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-5 fw-300">Voluntary Excess <span class="ml-1"
                            (click)="helpTextPopup(content,'Please note this will apply in addition to any compulsory, vehicle or driver excesses that may apply.')"><i
                                class="mdi mdi-comment-question-outline"></i></span></div>
                    <div class="col-md-7">
                        <mat-form-field class="w-100" *ngIf="staticData">
                            <mat-label>Voluntary Excess</mat-label>
                            <mat-select formControlName="voluntary_excess" class="form-control form-control-sm"
                                name="voluntary_excess" required (selectionChange)="calculatePremium()"
                                [ngClass]="{ 'is-invalid': (f.voluntary_excess.touched || submitted) && f.voluntary_excess.errors?.required }">
                                <mat-option [value]="v" *ngFor="let v of staticData.voluntary_excess_options">
                                    &pound;{{ v }}
                                </mat-option>
                            </mat-select>
                            <div class="invalid-feedback"
                                *ngIf="(f.voluntary_excess.touched || submitted) && f.voluntary_excess.errors?.required">
                                Please select an option.
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class="row mt-2">
                    <div class="col-md-5 fw-300">Peliwica Fee</div>
                    <div class="col-md-7">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                </div>
                                <input type="text" class="form-control form-control-sm" placeholder="Peliwica Fee"
                                    name="peliwica_fee" formControlName="peliwica_fee" readonly>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="row mt-3">
                    <div class="col-md-12">
                        <span class="fw-500">Young & inexperienced driver excess</span> (does not apply to
                        Agricultural
                        vehicles)
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-5">
                        Where driver is aged 21 years or below
                    </div>
                    <div class="col-md-7">
                        &pound;250
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-5">
                        Where driver is aged 22-24 years
                    </div>
                    <div class="col-md-7">
                        &pound;250
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-5">
                        Where a driver aged 25 years or more has not held their full relevant UK/EU driving
                        license at
                        least 12 months
                    </div>
                    <div class="col-md-7">
                        &pound;250
                    </div>
                </div>
            </div>

            <div *ngIf="breakdown_premiums_arr && breakdown_premiums_arr.length > 0">
                <h4 class="mt-2">Breakdown Cover</h4>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form_sections">
                            <p class="fw-300">Would you like to add breakdown cover to the quote?</p>
                            <div *ngFor="let mv of modelObj.vehicles;">
                                <div *ngFor="let v of breakdown_premiums_arr; let i = index;">
                                    <div class="row mt-3" *ngIf="v.registration_number == mv.registration_number">
                                        <div class="col-md-5 fw-300">{{ v.registration_number }} (£{{ v.value }})</div>
                                        <div class="col-md-7">
                                            <mat-radio-group class="row" aria-label="Select an option"
                                                name="breakdown_cover_{{i}}"
                                                (change)="onBreakdownSelection($event, v.registration_number)" required>
                                                <div class="col-md-4">
                                                    <div class="quote_radio w-90">
                                                        <mat-radio-button name="breakdown_cover" [value]="true"
                                                            [checked]="mv.breakdown_cover == true">Yes
                                                        </mat-radio-button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="quote_radio w-90">
                                                        <mat-radio-button name="breakdown_cover" [value]="false"
                                                            [checked]="mv.breakdown_cover == false">No
                                                        </mat-radio-button>
                                                    </div>
                                                </div>
                                            </mat-radio-group>
                                            <div class="invalid-feedback" style="font-size: 13px;"
                                                *ngIf="(f.breakdown_cover.touched || submitted) && f.breakdown_cover.errors?.required">
                                                Please select an option.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngFor="let v of modelObj.vehicles; let i = index;">

                                <div class="mt-3" *ngIf="v.owner">
                                    <p class="fw-300 mt-3" *ngIf="i == 0">Please provide details of the registered owner
                                        for the following
                                        vehicles;</p>
                                    <div class="row">
                                        <div class="col-md-5 fw-300">{{ v.registration_number }}</div>
                                        <div class="col-md-5">
                                            <input type="text" class="form-control form-control-sm" required
                                                [value]="v.owner_name" name="owner_name"
                                                (change)="onOwnerNameChange($event, v.id)"
                                                [ngClass]="{ 'is-invalid': (f.registered_owner.touched || submitted) && f.registered_owner.errors?.required }">
                                            <div class="invalid-feedback"
                                                *ngIf="(f.registered_owner.touched || submitted) && f.registered_owner.errors?.required">
                                                Please enter the details.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6  text-right"><button class="btn btn-success btn-sm">Save & Exit</button></div>
            </div>

        </div>
        <div class="col-md-4">
            <div class="calculator mt-4" style="position: relative;width: 100%;top: 0;right: 0;">
                <mat-accordion>
                    <mat-expansion-panel expanded>
                        <mat-expansion-panel-header class="m-0">
                            <mat-panel-title class="cal_header m-0">
                                Premium
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="cal_body p-3 bg-white">
                            <p>
                                <b>IPT</b><br>&pound;{{premiums.ipt}}
                            </p>
                            <p>
                                <b>Peliwica Fee</b><br>&pound;{{peliwica_fee}}
                            </p>
                            <div class="mb-2">
                                <b>Broker Fee</b><br>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm"
                                        required name="broker_admin_fee" formControlName="broker_admin_fee" 
                                        (change)="onBrokerCommissionChange($event)" [readonly]="isBroker"
                                        [ngClass]="{ 'is-invalid': (f.broker_admin_fee.touched || submitted) && f.broker_admin_fee.errors?.required }">
                                    <div class="invalid-feedback"
                                        *ngIf="(f.broker_admin_fee.touched || submitted) && f.broker_admin_fee.errors?.required">
                                        Broker commission is required.
                                    </div>
                                </div>
                            </div>
                            <p>
                                <b>Gross Premium</b><br>&pound;{{ gross_premium | number:'1.2-2'}}
                            </p>
                            <p>
                                <b>Total Amount(Inc. IPT)</b><br>&pound;{{total_amount | number:'1.2-2'}}
                            </p>
                            <p *ngIf="vehicle_premiums_key">
                                <b><u>Rate per Vehicle</u></b><br>
                            </p>
                            <div class="row" *ngIf="vehicle_premiums_key">
                                <div class="col-6">
                                    <b>{{ vehicle_premiums_key }}</b>
                                </div>
                                <div class="col-6">
                                    &pound;{{ vehicle_premiums_value }}
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-12" *ngIf="!isBroker">
                                    <b>Commission</b><br>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">%</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" placeholder="20"
                                            required name="commission" formControlName="commission" min="0" max="20"
                                            (change)="onCommissionChange($event)"
                                            [ngClass]="{ 'is-invalid': (f.commission.touched || submitted) && f.commission.errors?.required }">
                                        <div class="invalid-feedback"
                                            *ngIf="(f.commission.touched || submitted) && f.commission.errors?.required">
                                            Commission is required.
                                        </div>
                                    </div>
                                </div>
                              
                                <!-- <div class="col-md-12 mt-2" *ngIf="modelObj.type == 1">
                                    <b>Discount(Max 10%)</b><br>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">%</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" placeholder="Discount(Max 10%)"
                                            required name="discount" formControlName="discount" min="0" max="10"
                                            (change)="onDiscountChange($event)"
                                            [ngClass]="{ 'is-invalid': (f.discount.touched || submitted) && f.discount.errors?.required }">
                                        <div class="invalid-feedback"
                                            *ngIf="(f.discount.touched || submitted) && f.discount.errors?.required">
                                            Discount is required.
                                        </div>
                                    </div>
                                </div> -->
                            </div>

                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="form_sections mt-3">
                <h4>Quote Summary</h4>
                <hr>
                <div class="row" *ngIf="calErrors && calErrors.length > 0">
                    <div class="col-md-12">
                        <div class="alert alert-danger">
                            <h4>Attention Required</h4>
                            <ul>
                                <!-- <li *ngFor="let e of calErrors">
                                    {{ (e.detail)?e.detail:e.title }}
                                </li> -->

                                <li>The quote has been referred to Underwriters.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 fw-300">Countrywise</div>
                    <div class="col-md-7 fw-500">Horsebox</div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-5 fw-300">Reference</div>
                    <div class="col-md-7 fw-500">{{ reference }}</div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-5 fw-300">Cover Dates</div>
                    <div class="col-md-7 fw-500">{{ cover_period }} </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-5 fw-300">Customer Type</div>
                    <div class="col-md-7 fw-500 text-capitalize">{{ proposer_type }}</div>
                </div>
                <hr>
                <a href="{{ policy_wording_doc_url }}" target="_blank" class="btn btn-success btn-sm w-100">Policy
                    Wording</a><br>
                <a href="{{ breakdown_wording_doc_url }}" target="_blank"
                    class="btn btn-success btn-sm w-100 mt-2">Breakdown Wording</a><br>
                <a href="{{ ipid_wording_doc_url }}" target="_blank" class="btn btn-success btn-sm w-100 mt-2">IPID</a>
                <!-- <div class="row mt-3">
                <div class="col-md-5 fw-300">Insured</div>
                <div class="col-md-7 fw-500"></div>
            </div>
            <div class="row mt-3">
                <div class="col-md-5 fw-300">Driver(s)</div>
                <div class="col-md-7 fw-500"></div>
            </div> -->
            </div>
        </div>
    </div>
</form>
<ng-template #content let-modal>
    <div class="modal-body">
        <div class="helptext" [innerHTML]="helptext"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<ng-template #referral let-modal>
    <div class="modal-body">
        <div class="helptext">Clicking save will send your quote to the underwriter, the quote will then be locked for
            editing until the underwriter decision has been made. If you need to edit your quote please do so before
            clicking save.</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dark" (click)="modal.close()">Exit</button>
        <button type="button" class="btn btn-success" (click)="saveQuote(content)">Save</button>
    </div>
</ng-template>

<!-- <p>
    Form is {{frmFields.valid ? 'Valid' : 'Invalid'}}
</p>
<pre>
            {{frmFields.value | json}}
            </pre>  -->