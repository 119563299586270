import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  headers;
  UserType = 0;

  constructor(private httpClient: HttpClient, private sessionSt: LocalStorageService) { }

  ngOnInit(): void {

  }

  getUserType() {
    if (this.sessionSt.retrieve('user_group') == 'Staff') {
      this.UserType = 1;
    }
    if (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') {
      this.UserType = 2;
    }
    if (this.sessionSt.retrieve('is_broker')) {
      this.UserType = 3;
    }
    // if (this.sessionSt.retrieve('company_role') == 'Broker' && this.sessionSt.retrieve('user_group') == 'Administrator') {
    //   this.UserType = 3;
    // }
    if (this.sessionSt.retrieve('company_role') == 'Insurer') {
      this.UserType = 4;
    }

    return this.UserType;
  }

  apiURL() {
    return environment.apiURL;
  }
  apiHeaders() {
    let login_token = '';
    let BrokerId = '';
    if (this.sessionSt.retrieve('login_token') != null) {
      login_token = this.sessionSt.retrieve('login_token');
    }
    if (this.sessionSt.retrieve('al_broker_company_id') != null) {
      BrokerId = this.sessionSt.retrieve('al_broker_company_id');
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;',
      'Authorization': login_token,
      'Retry-After': '3600',
      'BrokerId': BrokerId
    }
    );
    return headers;
  }
  // GET AJAX CALL
  getAPI(url): Observable<string> {
    const headers = this.apiHeaders();
    url = environment.apiURL + url;
    return this.httpClient.get<string>(url, { headers: headers }).pipe(
      retry(3),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      }), shareReplay()
    );
  }
  

  // GET AJAX CALL
  getOtherAPI(url) {
    return this.httpClient.get(url);
  }

  // POST AJAX CALL
  postAPI(url, data) {
    try {
      const headers = this.apiHeaders();
      url = environment.apiURL + url;
      return this.httpClient.post(url, JSON.stringify(data), { headers: headers });
    }
    catch (error) {
      console.error('Unable to post data', error);
    }
  }

  postPaymentAPI(url, data) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json;',
        'Authorization': "T_S_32a8a3a2-a7a5-46bc-a525-924841483aa4",
      });
      return this.httpClient.post(url, JSON.stringify(data), { headers: headers });
    }
    catch (error) {
      console.error('Unable to post data', error);
    }
  }

  // POST FORM DATA AJAX CALL
  postFormData(url, formData) {
    try {
      const headers = new HttpHeaders({
        'Authorization': this.sessionSt.retrieve('login_token')
      });
      url = environment.apiURL + url;
      return this.httpClient.post(url, formData, { headers: headers });
    }
    catch (error) {
      console.error('Unable to post data', error);
    }
  }


  download(url: string) {
    url = environment.apiURL + url;
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Authorization': this.sessionSt.retrieve('login_token'),
      })
    };
    return this.httpClient.get(url, httpOptions).pipe(
      retry(3),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      }), shareReplay()
    );
  }


  postDownload(url, data) {
    let login_token = '';
    if (this.sessionSt.retrieve('login_token') != null) {
      login_token = this.sessionSt.retrieve('login_token');
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;',

      'Authorization': login_token
    });
    return this.httpClient.post(
      environment.apiURL + url,
      data,
      { headers: headers, responseType: 'blob' });
  }

  /*
  * Sort Date Column Of AG-GRID
  */
  agGridDteSortingComparator(date1, date2) {
    return new Date(date1) < new Date(date2) ? -1 : 1;
  }


  // GET AJAX CALL - Amline
  getAmlineAPI(url, authDetails = {token_type: '', access_token: ''}): Observable<string> {
    const headers = new HttpHeaders({
      Authorization: authDetails.token_type + ' ' + authDetails.access_token
    });
    url = environment.AMLINE_API_URL + url;
    return this.httpClient.get<string>(url, { headers: headers }).pipe(
      retry(3),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      }), shareReplay()
    );
  }

  // POST AJAX CALL - Amline
  postAmlineAPI(url, data, authDetails = {token_type: '', access_token: ''}) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': authDetails.token_type + ' ' + authDetails.access_token
      });
      //const headers = new HttpHeaders();
   // headers.append('Content-Type', 'application/json');
   // headers.append('Authorization', authDetails.token_type + ' ' + authDetails.access_token);
      url = environment.AMLINE_API_URL + url;
      return this.httpClient.post(url, data, { headers: headers });
    }
    catch (error) {
      console.error('Unable to post data', error);
    }
  }
  // POST AJAX CALL - Amline
  postAmlineAPI2(url, data, authDetails = {token_type: '', access_token: ''}) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': authDetails.token_type + ' ' + authDetails.access_token
      });
      //const headers = new HttpHeaders();
   // headers.append('Content-Type', 'application/json');
   // headers.append('Authorization', authDetails.token_type + ' ' + authDetails.access_token);
      url = environment.AMLINE_API_URL2 + url;
      return this.httpClient.put(url, data, { headers: headers });
    }
    catch (error) {
      console.error('Unable to post data', error);
    }
  }

}
