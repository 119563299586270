export class AlpacaDetails {
    id: string = '';
    name: string = '';
    value: number = null;
    gender: number = null;
    horse_purchase_date: string = '';       
    passport: string = '';       
    microchip: string = '';       
    is_cria: number = 0;       
}
