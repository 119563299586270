import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Quote } from '../../model/quote/quote.model';
import { LocalStorageService } from 'ngx-webstorage';
import { formatDate, TitleCasePipe } from '@angular/common';
import { GridOptions } from "ag-grid-community";
import { AlQuoteService } from '../../al-quote/al-quote.service';
import { QuoteService } from '../../../quote.service';
import { ActivatedRoute, Router } from "@angular/router";
import { PolicyService } from '../../../../policy/policy.service';
import { UserInfoService } from '../../../../services/user-info.service';

@Component({
  selector: 'app-admin-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class AlOverviewComponent implements OnInit {
  @Output() quoteDetailsUpdated = new EventEmitter<Quote>();
  @Output() hasImportantNotes = new EventEmitter();
  quote_end_date: string = '';
  quote;
  quote_type;
  @Input() set quoteData(value: any) {
    if (value) {
      this.quote = value;
    }
  }


  constructor(private sessionSt: LocalStorageService, private quoteService: AlQuoteService, private adminQuoteService: QuoteService, private userService: UserInfoService,
    private route: ActivatedRoute, private policyService: PolicyService, private titlecasePipe: TitleCasePipe, private router: Router) { }

  minPolicyDate;
  maxPolicyDate;
  underwriters;
  insurers;
  selectedUnderwriterID = this.sessionSt.retrieve('userInfo').id;
  quoteTypes;
  policyReason: string = '';
  policyStatuses;

  ngOnInit(): void {
    
    // API - GET INSURERS
    this.adminQuoteService.getInsurers()
      .subscribe(
        (response: any) => {
          this.insurers = response.result;
        });

    // Get Policy Statuses
    this.policyService.getPolicyStatuses()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.policyStatuses = response.result.quote_insurance_status;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.quote) {
      if(this.quote.is_policy == 0) {
        this.getPolicyStatus(0);
      } else {
        this.getPolicyStatus(1);
      }
    }
    this.getQuoteTypes();
  }

  // List Quote Versions
  quoteVersionsUpdated(qudatedQuote) {
    this.quote = qudatedQuote;
    this.quoteDetailsUpdated.emit(qudatedQuote);
    //this.getPolicyReasons();
    if (qudatedQuote.is_policy == 0) {
      this.getPolicyStatus(0);
    } else {
      this.getPolicyStatus(1);
    }
   // this.staticData.emit(qudatedQuote.rating_version);
   this.transction_type = '';
   this.getQuoteTypes();
  }

  onhasImportantNotesChange(isImportant) {
    this.hasImportantNotes.emit(isImportant);    
  }

  // Get Quote Types
  transction_type = '';
  getQuoteTypes() {
    if (!this.transction_type) {      
      this.adminQuoteService.getQuoteTypes()
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.transction_type = response.result.types[this.quote.type];
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

   /*
  * Policy Status Based on Quote/Policy
  * Type => 0: Quote, 1: Policy
  */
   getPolicyStatus(type) {
    this.policyService.getPolicyStatuses()
      .subscribe(
        (response: any) => {
          if (response.success) {
            if (type == 0) {
              this.policyStatuses = response.result.quote_insurance_status;
            } else {
              this.policyStatuses = response.result.policy_insurance_status;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
  * GRID BUTTONS ACTION
  */
  onGridRowClicked(e: any) {
    if (e) {
      let quote_id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "view":
          {
            this.getPolicyData(quote_id);
            break;
          }
      }
    }
  }

  // GET POLICY DETAILS
  getPolicyData(quote_id) {
    this.adminQuoteService.getQuoteDetailsByQuoteID(quote_id)
      .subscribe(
        (response: any) => {
          if(response.result.is_policy == 0) {
            this.getPolicyStatus(0);
          } else {
            this.getPolicyStatus(1);
          }
          this.quote = response.result;
          this.quote.financial_details.broker_commission = Number(this.quote.financial_details.broker_commission);
          this.quote.financial_details.peliwica_pay_away_commission = Number(this.quote.financial_details.peliwica_pay_away_commission)
          this.quote.financial_details.peliwica_retained_commission = Number(this.quote.financial_details.peliwica_retained_commission);
          this.quote.financial_details.policy_premium = Number(this.quote.financial_details.policy_premium);
          this.quoteDetailsUpdated.emit(this.quote);
        });
  }

   /*
  * View Policy Details
  */
 policyDetails() {
  let quote_id;
  let quote_versions = this.quote.versions.filter(x => x.is_policy == 1);
  if(quote_versions.length > 0) {
      quote_id = quote_versions[0].quote_id;
    }  
  this.router.navigate(['/alpaca-admin/policy/',quote_id]);
 }

}
