<mat-expansion-panel expanded style="padding:0 24px 16px !important;">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <h5> Configuration <i class="mdi mdi-arrow-right"></i> {{productName}}</h5>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="card-body" style="box-shadow: 1px 1px 8px 1px #138a51;">
        <!-- <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-3">Products</label>
                    <div class="col-md-9">
                        <mat-form-field class="w-100">
                            <mat-label>Products</mat-label>
                            <mat-select class="form-control form-control-sm" name="product_id" (selectionChange)="showHideProductConfiguration($event)">
                                <mat-option [value]="">Select Product</mat-option>
                                <mat-option *ngFor="let product of products" [value]="product.id">
                                    {{product.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div> -->
        <mat-tab-group class="configuration-tab vertical">

            <mat-tab label="Logo">
                <div *ngIf="logoList">
                    <div class="card-header bg-success productconfig-grid-header">
                        <h5>Manage Logo
                            <a class="btn btn-default float-right btn-sm ml-5" (click)='ShowLogoTab()'><i
                                    class="mdi mdi-plus"></i>
                                Logo</a>
                        </h5>
                    </div>
                    <div class="card-body p-1" style="margin: 0 -25px -5px;">
                        <ag-grid-angular style="width: 100%; height: 380px; background-color: #3b6c54"
                            class="ag-theme-bootstrap" [gridOptions]="gridOptions1" [rowData]="logoData"
                            [columnDefs]="columnDefs1" animateRows pagination paginationPageSize=10 rowHeight=27
                            headerHeight=35 (rowClicked)='onLogoGridRowClicked($event,publishLogoModel)'>
                        </ag-grid-angular>
                    </div>
                </div>


                <form #fl="ngForm" (ngSubmit)="fl.form.valid && onSubmitLogo(fl)" enctype="multipart/form-data"
                    *ngIf="logoTab">
                    <div class="mt-4">
                        <h6>Logo
                            <small><a (click)='BackToLogoList()' class="text-white float-right"><i
                                        class="mdi mdi-arrow-left"></i> Back to
                                    list</a></small>
                        </h6>
                        <div class="row" *ngIf="isLogoVersionList">
                            <div class="col-md-6">
                                <label>Versions</label>
                                <mat-form-field class="w-100">
                                    <mat-select class="form-control form-control-sm" name="version_id"
                                        [disabled]="companyDetails.logoversion"
                                        [(ngModel)]="companyDetails.logoversion">
                                        <mat-option [value]="">Select Version</mat-option>
                                        <mat-option *ngFor="let version of logoVersionList" [value]="version.version">
                                            {{version.version}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                            <div class="col-md-6" *ngIf="companyDetails.logo_path && viewMode"
                                style="padding-top: 33px;">
                                <img [src]="imagePath + companyDetails.logo_path" class="img-fluid"
                                    style="width: 200px;height: 100px;">
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group" *ngIf="!viewMode">
                                    <label>Upload Logo</label>
                                    <div class="custom-file">
                                        <input type="file" name="logo_path"
                                            (change)="getUploadedFile($event.target.files)" class="custom-file-input"
                                            id="customFile">
                                        <label class="vertical custom-file-label" for="customFile">{{fileName}}</label>
                                    </div>
                                    <small>Please upload the logo of size 180*100 for best resolution.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-left mt-5" *ngIf="!viewMode">
                        <input type="submit" name="submit" value="Save Logo" class="btn btn-default btn-gray">
                    </div>
                </form>
            </mat-tab>


            <mat-tab label="Commissions">

                <div *ngIf="brokerComission">
                    <div *ngIf="commissionList">
                        <div class="card-header bg-success productconfig-grid-header">
                            <h5>Manage Commissions
                                <a class="btn btn-default float-right btn-sm ml-5" (click)='ShowCommissionTab()'><i
                                        class="mdi mdi-plus"></i>
                                    Commission</a>
                            </h5>
                        </div>
                        <div class="card-body p-1" style="margin: 0 -25px -5px;">
                            <ag-grid-angular style="width: 100%; height: 380px; background-color: #3b6c54"
                                class="ag-theme-bootstrap" [gridOptions]="gridOptions" [rowData]="rowData"
                                [columnDefs]="columnDefs" animateRows pagination paginationPageSize=10 rowHeight=27
                                headerHeight=35 (rowClicked)='onGridRowClicked($event,publishModel)'>
                            </ag-grid-angular>
                        </div>
                    </div>

                    <div *ngIf="commissionTab">
                        <form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit(f)">
                            <h6 style="padding-top: 20px;">Commissions
                                <small><a (click)='BackToCommissionList()' class="text-white float-right"><i
                                            class="mdi mdi-arrow-left"></i> Back to
                                        list</a></small>
                            </h6>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Broker Comission</label>
                                        <div class="input-group">
                                            <div class="vertical-bg-color input-group-prepend">
                                                <span class="input-group-text bg-success text-white">%</span>
                                            </div>
                                            <input type="number" min="0" step="0.5" name="broker_commission"
                                                [(ngModel)]="companyDetails.broker_commission"
                                                class="form-control form-control-sm" placeholder="Broker Comission"
                                                [ngClass]="{ 'is-invalid': f.submitted && (reachedBrokerCommAndRetainedCommMaxLimit  || reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit)}">
                                            <div *ngIf="f.submitted && reachedBrokerCommAndRetainedCommMaxLimit && !reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit"
                                                class="invalid-feedback" style="color: #9e1c28 !important;">
                                                Sum of broker commission and retained commission should not be more than
                                                {{ maximum_broker_commission_and_ratained_commission }}
                                            </div>
                                            <div *ngIf="f.submitted && reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit && !reachedBrokerCommAndRetainedCommMaxLimit"
                                                class="invalid-feedback" style="color: #9e1c28 !important;">
                                                Sum of broker commission, pay away commission and retained commission
                                                should not be more than {{
                                                maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Peliwica Retained Commission</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-success text-white">%</span>
                                            </div>
                                            <input type="number" min="0" step="0.5" name="retained_commission"
                                                [(ngModel)]="companyDetails.retained_commission"
                                                class="form-control form-control-sm"
                                                placeholder="Peliwica Retained Commission"
                                                [ngClass]="{ 'is-invalid': f.submitted && (reachedBrokerCommAndRetainedCommMaxLimit  || reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit)}">
                                            <div *ngIf="f.submitted && reachedBrokerCommAndRetainedCommMaxLimit && !reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit"
                                                class="invalid-feedback" style="color: #9e1c28 !important;">
                                                Sum of broker commission and retained commission should not be more than
                                                {{ maximum_broker_commission_and_ratained_commission }}
                                            </div>
                                            <div *ngIf="f.submitted && reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit && !reachedBrokerCommAndRetainedCommMaxLimit"
                                                class="invalid-feedback" style="color: #9e1c28 !important;">
                                                Sum of broker commission, pay away commission and retained commission
                                                should not be more than {{
                                                maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Peliwica Pay Away Commission</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-success text-white">%</span>
                                            </div>
                                            <input type="number" min="0" step="0.5" name="pay_away_commission"
                                                [(ngModel)]="companyDetails.pay_away_commission"
                                                class="form-control form-control-sm"
                                                placeholder="Peliwica Pay Away Commission"
                                                [ngClass]="{ 'is-invalid': f.submitted && reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit}">
                                            <div *ngIf="f.submitted && reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit"
                                                class="invalid-feedback" style="color: #9e1c28 !important;">
                                                Sum of broker commission, pay away commission and retained commission
                                                should not be more than {{
                                                maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Underwriting Fee</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-success text-white">&pound;</span>
                                            </div>
                                            <input type="number" min="0" step="0.5" name="uw_fee"
                                                [(ngModel)]="companyDetails.uw_fee" class="form-control form-control-sm"
                                                placeholder="Underwriting Fee">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col-md-6 form-group">
                                    <label>Premium Loading</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-success text-white">%</span>
                                        </div>
                                        <input type="number" min="0" step="0.5" name="premium_loading"
                                            [(ngModel)]="companyDetails.premium_loading"
                                            class="form-control form-control-sm"
                                            placeholder="Peliwica Pay Away Commission">
                                    </div>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Broker Admin Fee</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-success text-white">&pound;</span>
                                        </div>
                                        <input type="number" min="0" step="0.5" name="broker_admin_fee"
                                            [(ngModel)]="companyDetails.broker_admin_fee"
                                            class="form-control form-control-sm" placeholder="Broker Admin Fee">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" *ngIf="isVersion">
                                    <div class="form-group">
                                        <label>Versions</label>
                                        <mat-form-field class="w-100">
                                            <mat-select class="form-control form-control-sm" name="version_id"
                                                [disabled]="companyDetails.version"
                                                [(ngModel)]="companyDetails.version">
                                                <mat-option [value]="">Select Version</mat-option>
                                                <mat-option *ngFor="let version of versionList"
                                                    [value]="version.version">
                                                    {{version.version}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                            <div class="form-group text-right">
                                <input type="submit" name="submit" value="Save Commission"
                                    class="btn btn-default btn-gray">

                            </div>

                        </form>

                    </div>
                </div>

            </mat-tab>
            <mat-tab label="Emails">
                <div *ngIf="emailList">
                    <div class="card-header bg-success productconfig-grid-header">
                        <h5>Manage Emails
                            <a class="btn btn-default float-right btn-sm ml-5" (click)='ShowEmailTab()'><i
                                    class="mdi mdi-plus"></i>
                                Email</a>
                        </h5>
                    </div>
                    <div class="card-body p-1" style="margin: 0 -25px -5px;">
                        <ag-grid-angular style="width: 100%; height: 380px; background-color: #3b6c54"
                            class="ag-theme-bootstrap" [gridOptions]="gridOptions2" [rowData]="emailData"
                            [columnDefs]="columnDefs2" animateRows pagination paginationPageSize=10 rowHeight=27
                            headerHeight=35 (rowClicked)='onLogoGridRowClicked($event,publishLogoModel)'>
                        </ag-grid-angular>
                    </div>
                </div>

                <form #fe="ngForm" (ngSubmit)="fe.form.valid && onSubmitEmail(fe)" *ngIf="emailTab">
                    <div class="mt-4">
                        <h6>Email
                            <small><a (click)='BackToEmailList()' class="text-white float-right"><i
                                        class="mdi mdi-arrow-left"></i> Back to
                                    list</a></small>
                        </h6>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <mat-label>Quote Type<span class="text-danger">*</span></mat-label>
                                    <mat-select class="form-control form-control-sm" name="mySelect" #mySelect="ngModel"
                                        required [(ngModel)]="companyDetails.email_quote"
                                        (selectionChange)='onEmailQuoteSelected($event)'
                                        [ngClass]="{ 'is-invalid': fe.submitted && mySelect.invalid }">
                                        <mat-option [value]="">Select Type</mat-option>
                                        <mat-option [value]="type.key" *ngFor="let type of loadTypeList | keyvalue">
                                            {{type.value}}</mat-option>
                                    </mat-select>
                                    <div *ngIf="fe.submitted && mySelect.invalid" class="invalid-feedback"
                                        style="color: #9e1c28 !important;">
                                        Quote is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <mat-label>Email Type</mat-label>
                                    <mat-select name="email_title" class="form-control form-control-sm"
                                        (selectionChange)='onEmailSelection($event)' name="mailType" #mailType="ngModel"
                                        required [(ngModel)]="companyDetails.email_type" [disabled]="disableEmailType"
                                        [ngClass]="{ 'is-invalid': fe.submitted && mailType.invalid }">
                                        <mat-option [value]="">Select Type</mat-option>
                                        <mat-option *ngFor="let emailtype of loadTitleList" [value]="emailtype.id">
                                            {{emailtype.title}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="fe.submitted && mailType.invalid" class="invalid-feedback"
                                        style="color: #9e1c28 !important;">
                                        Type is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Subject</label>
                                    <input type="text" name="Sublect" placeholder="Title"
                                        [(ngModel)]="companyDetails.email_subject" class="form-control form-control-sm"
                                        [disabled]="IsEmailTitleDisabled">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Template</label>
                                    <angular-editor [ngClass]="{ 'is-invalid': fe.submitted && body.invalid }"
                                        #body="ngModel" required [placeholder]="'Enter text here...'"
                                        [(ngModel)]="companyDetails.email_body" name="body"
                                        style="background-color: #fff;" [disabled]="IsEmailBodyDisabled">
                                    </angular-editor>

                                    <div *ngIf="fe.submitted && body.invalid" class="invalid-feedback"
                                        style="color: #9e1c28 !important;">
                                        Message is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group text-right mt-5">
                            <input type="submit" name="submit" value="Save Email" class="btn btn-default btn-gray">
                        </div>
                    </div>
                </form>
            </mat-tab>
            <mat-tab label="Scheduler documents">

                <div *ngIf="documentList">
                    <div class="card-header bg-success productconfig-grid-header">
                        <h5>Manage Documents
                            <a class="btn btn-default float-right btn-sm ml-5" (click)='ShowDocumentTab()'><i
                                    class="mdi mdi-plus"></i>
                                Document</a>
                        </h5>
                    </div>
                    <div class="card-body p-1" style="margin: 0 -25px -5px;">
                        <ag-grid-angular style="width: 100%; height: 380px; background-color: #3b6c54"
                            class="ag-theme-bootstrap" [gridOptions]="gridOptions3" [rowData]="documentData"
                            [columnDefs]="columnDefs3" animateRows pagination paginationPageSize=10 rowHeight=27
                            headerHeight=35 (rowClicked)='onDocumentGridRowClicked($event)'>
                        </ag-grid-angular>
                    </div>
                </div>

                <form #fd="ngForm" (ngSubmit)="fd.form.valid && onSubmitDocument(fd)" *ngIf="documentTab">
                    <div class="mt-4">
                        <h6>Document
                            <small><a (click)='BackToDocumentList()' class="text-white float-right"><i
                                        class="mdi mdi-arrow-left"></i> Back to
                                    list</a></small>
                        </h6>
                        <!-- <label> Scheduler documents</label> -->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <mat-label>Quote Type<span class="text-danger">*</span></mat-label>
                                    <mat-select class="form-control form-control-sm" name="mydocument"
                                        #mydocument="ngModel" required
                                        [(ngModel)]="companyDetails.scheduler_doc_quote_type"
                                        (selectionChange)='onDocQuoteSelected($event)'
                                        [ngClass]="{ 'is-invalid': fd.submitted && mydocument.invalid }">
                                        <mat-option [value]="">Select Type</mat-option>
                                        <mat-option [value]="type.key"
                                            *ngFor="let type of documentLoadTypeList | keyvalue">{{type.value}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="fd.submitted && mydocument.invalid" class="invalid-feedback"
                                        style="color: #9e1c28 !important;">
                                        Quote is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <mat-label>Document Type</mat-label>
                                    <mat-select class="form-control form-control-sm"
                                        (selectionChange)='onDocSelection($event)'
                                        [(ngModel)]="companyDetails.scheduler_doc_type" name="docType"
                                        #docType="ngModel" required
                                        [ngClass]="{ 'is-invalid': fd.submitted && docType.invalid }"
                                        [disabled]="disableDocType">
                                        <mat-option [value]="">Select Type</mat-option>
                                        <mat-option *ngFor="let doctype of documentLoadTitleList" [value]="doctype.id">
                                            {{doctype.title}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="fd.submitted && docType.invalid" class="invalid-feedback"
                                        style="color: #9e1c28 !important;">
                                        Document type is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Template</label>

                                    <angular-editor [ngClass]="{ 'is-invalid': fd.submitted && body.invalid }"
                                        #body="ngModel" required [placeholder]="'Enter text here...'"
                                        [(ngModel)]="companyDetails.scheduler_doc_body" name="body"
                                        style="background-color: #fff;" [disabled]="IsDocBodyDisabled"></angular-editor>

                                    <div *ngIf="fd.submitted && body.invalid" class="invalid-feedback"
                                        style="color: #9e1c28 !important;">
                                        Message is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group text-right mt-5">
                            <input type="submit" name="submit" value="Save Document" class="btn btn-default btn-gray">

                        </div>
                    </div>
                </form>
            </mat-tab>
            <mat-tab label="Bank Detail">

                <div>
                    <div *ngIf="bankDetailList">
                        <div class="card-header bg-success productconfig-grid-header">
                            <h5>Manage Bank Detail
                                <a class="btn btn-default float-right btn-sm ml-5" (click)='ShowBankDetailTab()'><i
                                        class="mdi mdi-plus"></i>
                                    Bank Detail</a>
                            </h5>
                        </div>
                        <div class="card-body p-1" style="margin: 0 -25px -5px;">
                            <ag-grid-angular style="width: 100%; height: 380px; background-color: #3b6c54"
                                class="ag-theme-bootstrap" [gridOptions]="gridOptions" [rowData]="bankData"
                                [columnDefs]="columnDefs4" animateRows pagination paginationPageSize=10 rowHeight=27
                                headerHeight=35 (rowClicked)='onBankDetailGridRowClicked($event,publishBankModel)'>
                            </ag-grid-angular>
                        </div>
                    </div>

                    <div *ngIf="bankDetailTab">
                        <form #fbd="ngForm" (ngSubmit)="fbd.form.valid && onBankDetailSubmit(fbd)">
                            <h6 style="padding-top: 20px;">Bank Details
                                <small><a (click)='BackToBankDetailList()' class="text-white float-right"><i
                                            class="mdi mdi-arrow-left"></i> Back to
                                        list</a></small>
                            </h6>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Account Name<span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" name="account_name" #account_name="ngModel" required
                                                [ngClass]="{ 'is-invalid': fbd.submitted && account_name.invalid }"
                                                [(ngModel)]="companyDetails.account_name"
                                                class="form-control form-control-sm" placeholder="Account Name">
                                        </div>
                                        <div *ngIf="fbd.submitted && account_name.invalid" class="invalid-feedback"
                                            style="color: #9e1c28 !important;">
                                            Account name is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Account Number<span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" name="account_number"
                                                [(ngModel)]="companyDetails.account_number"
                                                class="form-control form-control-sm" placeholder="Account Number"
                                                #account_number="ngModel" required
                                                [ngClass]="{ 'is-invalid': fbd.submitted && account_number.invalid }">
                                        </div>
                                        <div *ngIf="fbd.submitted && account_number.invalid" class="invalid-feedback"
                                            style="color: #9e1c28 !important;">
                                            Account number is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Sort Code <span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" name="sort_code" #sort_code="ngModel" required
                                                [ngClass]="{ 'is-invalid': fbd.submitted && sort_code.invalid }"
                                                [(ngModel)]="companyDetails.sort_code"
                                                class="form-control form-control-sm" placeholder="Sort Code">
                                        </div>
                                        <div *ngIf="fbd.submitted && sort_code.invalid" class="invalid-feedback"
                                            style="color: #9e1c28 !important;">
                                            Sort code is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Phone<span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" name="bank_phone" [(ngModel)]="companyDetails.bank_phone"
                                                class="form-control form-control-sm" placeholder="Phone"
                                                #Phone="ngModel" required
                                                [ngClass]="{ 'is-invalid': fbd.submitted && Phone.invalid }">
                                        </div>
                                        <div *ngIf="fbd.submitted && Phone.invalid" class="invalid-feedback"
                                            style="color: #9e1c28 !important;">
                                            Phone is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" *ngIf="isVersion">
                                    <div class="form-group">
                                        <label>Versions</label>
                                        <mat-form-field class="w-100">
                                            <mat-select class="form-control form-control-sm" name="version_id"
                                                [disabled]="companyDetails.version"
                                                [(ngModel)]="companyDetails.version">
                                                <mat-option [value]="">Select Version</mat-option>
                                                <mat-option *ngFor="let version of versionList"
                                                    [value]="version.version">
                                                    {{version.version}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                            <div class="form-group text-right">
                                <input type="submit" name="submit" value="Save Bank Detail"
                                    class="btn btn-default btn-gray">

                            </div>

                        </form>

                    </div>
                </div>

            </mat-tab>
            <mat-tab label="Static Documents">
                <div *ngIf="staticdocumentList">
                    <div class="card-header bg-success productconfig-grid-header">
                        <h5>Manage Documents
                            <a class="btn btn-default float-right btn-sm ml-5" (click)='ShowStaticDocumetTab()'><i
                                    class="mdi mdi-plus"></i>
                                Document</a>
                        </h5>
                    </div>
                    <div class="card-body p-1" style="margin: 0 -25px -5px;">
                        <ag-grid-angular style="width: 100%; height: 380px; background-color: #3b6c54"
                            class="ag-theme-bootstrap" [gridOptions]="gridOptions5" [rowData]="staticDocumentData"
                            [columnDefs]="columnDefs5" animateRows pagination paginationPageSize=10 rowHeight=27
                            headerHeight=35>
                        </ag-grid-angular>
                    </div>
                </div>


                <form #fsd="ngForm" (ngSubmit)="fsd.form.valid && onSubmitStaticDocument(fsd)"
                    enctype="multipart/form-data" *ngIf="staticdocumentTab">
                    <div class="mt-4">
                        <h6>Documents
                            <small><a (click)='BackToStaticDocumentList()' class="text-white float-right"><i
                                        class="mdi mdi-arrow-left"></i> Back to
                                    list</a></small>
                        </h6>
                        <h6 class="heading-span">Please make sure that all required files are uploaded in a single go
                            before you save them.</h6>
                        <div *ngFor="let static_docs of companyDetails.static_docs; let i = index;">
                            <div class="form-group" *ngIf="i==0">
                                <button type="button" class="btn btn-add-more-docs btn-sm"
                                    (click)="addStaticDocuments()">Add More
                                    Documents</button>
                            </div>

                            <div class="form_sections mt-3">
                                Document {{i + 1}}
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Title</label>
                                            <div class="input-group">
                                                <input type="text"
                                                    name="title_{{companyDetails.static_docs.length + i}}"
                                                    [(ngModel)]="static_docs.title" class="form-control form-control-sm"
                                                    required
                                                    [ngClass]="{ 'is-invalid': fsd.submitted && title.invalid }"
                                                    #title="ngModel">
                                            </div>
                                            <div *ngIf="fsd.submitted && title.invalid" class="invalid-feedback"
                                                style="color: #9e1c28 !important;">
                                                Title is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Upload Document</label>
                                            <div class="custom-file">
                                                <input type="file"
                                                    name="document_path_{{companyDetails.static_docs.length + i}}"
                                                    [(ngModel)]="static_docs.document_path"
                                                    (change)="getUploadedDocuments($event.target.files,i)"
                                                    class="custom-file-input"
                                                    id="customFile_{{companyDetails.static_docs.length + i}}"
                                                    [ngClass]="{ 'is-invalid': fsd.submitted && document_path.invalid }"
                                                    #document_path="ngModel" required>
                                                <label class="vertical custom-file-label"
                                                    for="customFile">{{companyDetails.static_docs[i].file_name}}</label>
                                            </div>
                                            <div *ngIf="fsd.submitted && document_path.invalid" class="invalid-feedback"
                                                style="color: #9e1c28 !important;">
                                                File is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 quote_radio">
                                        <mat-checkbox name="is_vet_{{companyDetails.static_docs.length + i}}" 
                                            [(ngModel)]="static_docs.is_vet">
                                            Is vet?
                                        </mat-checkbox>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-checkbox name="is_renew_type_{{companyDetails.static_docs.length + i}}"
                                            [(ngModel)]="static_docs.is_renew_type">
                                            Is renew specific?
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="form-group mt-3" *ngIf="i>0">
                                    <button type="button" class="btn btn-danger btn-sm"
                                        (click)="removeStaticDocuments(i)">Remove</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-left mt-5">
                        <input type="submit" name="submit" value="Save and Publish" class="btn btn-default btn-gray">
                    </div>
                </form>
            </mat-tab>
            <mat-tab label="D2C Configuration">
                <div>
                    <div *ngIf="d2cList">
                        <div class="card-header bg-success productconfig-grid-header">
                            <h5>D2C Configurations
                                <a class="btn btn-default float-right btn-sm ml-5" (click)='ShowD2CTab()'><i
                                        class="mdi mdi-plus"></i>
                                    Configurations</a>
                            </h5>
                        </div>
                        <div class="card-body p-1" style="margin: 0 -25px -5px;">
                            <ag-grid-angular style="width: 100%; height: 380px; background-color: #3b6c54"
                                class="ag-theme-bootstrap" [gridOptions]="gridOptionsD2c" [rowData]="d2cData"
                                [columnDefs]="columnDefsD2c" animateRows pagination paginationPageSize=10 rowHeight=27
                                headerHeight=35 (rowClicked)='onD2CGridRowClicked($event,publishD2CModel)'>
                            </ag-grid-angular>
                        </div>
                    </div>

                    <div *ngIf="d2cTab">
                        <form #fd="ngForm" (ngSubmit)="fd.form.valid && onD2CSubmit(fd)">
                            <h6 style="padding-top: 20px;">D2C Configurations
                                <small><a (click)='BackTod2cConfigList()' class="text-white float-right"><i
                                            class="mdi mdi-arrow-left"></i> Back to
                                        list</a></small>
                            </h6>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Site Name<span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" name="site_name" #site_name="ngModel" required
                                                [ngClass]="{ 'is-invalid': fd.submitted && site_name.invalid }"
                                                [(ngModel)]="companyDetails.site_name"
                                                class="form-control form-control-sm" placeholder="Site Name">
                                        </div>
                                        <div *ngIf="fd.submitted && site_name.invalid" class="invalid-feedback"
                                            style="color: #9e1c28 !important;">
                                            Site name is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Website<span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" name="d2c_website"
                                                [(ngModel)]="companyDetails.d2c_website"
                                                class="form-control form-control-sm" placeholder="Account Number"
                                                #d2c_website="ngModel" required
                                                [ngClass]="{ 'is-invalid': fd.submitted && d2c_website.invalid }">
                                        </div>
                                        <div *ngIf="fd.submitted && d2c_website.invalid" class="invalid-feedback"
                                            style="color: #9e1c28 !important;">
                                            Website is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Phone <span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" name="d2c_phone" #d2c_phone="ngModel" required
                                                [ngClass]="{ 'is-invalid': fd.submitted && d2c_phone.invalid }"
                                                [(ngModel)]="companyDetails.d2c_phone"
                                                class="form-control form-control-sm" placeholder="Phone">
                                        </div>
                                        <div *ngIf="fd.submitted && d2c_phone.invalid" class="invalid-feedback"
                                            style="color: #9e1c28 !important;">
                                            Phone number is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Email<span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" name="d2c_email" [(ngModel)]="companyDetails.d2c_email"
                                                class="form-control form-control-sm" placeholder="Email"
                                                #d2c_email="ngModel" required
                                                [ngClass]="{ 'is-invalid': fd.submitted && d2c_email.invalid }">
                                        </div>
                                        <div *ngIf="fd.submitted && d2c_email.invalid" class="invalid-feedback"
                                            style="color: #9e1c28 !important;">
                                            Email is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Short Code <span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" name="short_code" [(ngModel)]="companyDetails.short_code"
                                                class="form-control form-control-sm" placeholder="Short Code">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Registration Number<span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" name="d2c_registration_number"
                                                [(ngModel)]="companyDetails.d2c_registration_number"
                                                class="form-control form-control-sm" placeholder="Registration Number">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>FCA Number<span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" name="fca_number" [(ngModel)]="companyDetails.fca_number"
                                                class="form-control form-control-sm" placeholder="Registration Number">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Copyright Text<span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" name="copyright_text"
                                                [(ngModel)]="companyDetails.copyright_text"
                                                class="form-control form-control-sm" placeholder="Copyright Text"
                                                #copyright_text="ngModel" required
                                                [ngClass]="{ 'is-invalid': fd.submitted && copyright_text.invalid }">
                                        </div>
                                        <div *ngIf="fd.submitted && copyright_text.invalid" class="invalid-feedback"
                                            style="color: #9e1c28 !important;">
                                            Copyright text is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" *ngIf="isd2cVersion">
                                    <div class="form-group">
                                        <label>Versions</label>
                                        <mat-form-field class="w-100">
                                            <mat-select class="form-control form-control-sm" name="version_id"
                                                [disabled]="companyDetails.d2c_version"
                                                [(ngModel)]="companyDetails.d2c_version">
                                                <mat-option [value]="">Select Version</mat-option>
                                                <mat-option *ngFor="let version of d2cVersionList"
                                                    [value]="version.version">
                                                    {{version.version}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                            <div class="form-group text-right">
                                <input type="submit" name="submit" value="Save Configurations"
                                    class="btn btn-default btn-gray">

                            </div>

                        </form>

                    </div>
                </div>

            </mat-tab>
        </mat-tab-group>
    </div>
</mat-expansion-panel>

<ng-template #publishModel let-modal>
    <div class="modal-body">
        <p>Are you sure you want to publish this commission?</p>
    </div>

    <div class="modal-footer">
        <button type="button" name="ok" (click)="publishCommission(companyDetails.id,selectedRow)"
            class="btn btn-success">Yes</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
    </div>
</ng-template>


<ng-template #publishLogoModel let-modal>
    <div class="modal-body">
        <p>Are you sure you want to publish this logo?</p>
    </div>

    <div class="modal-footer">
        <button type="button" name="ok" (click)="publishLogo(companyDetails.id,selectedRow)"
            class="btn btn-success">Yes</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
    </div>
</ng-template>

<ng-template #publishBankModel let-modal>
    <div class="modal-body">
        <p>Are you sure you want to publish this bank detail?</p>
    </div>

    <div class="modal-footer">
        <button type="button" name="ok" (click)="publishBankDetail(companyDetails.id,selectedRow)"
            class="btn btn-success">Yes</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
    </div>
</ng-template>

<ng-template #publishD2CModel let-modal>
    <div class="modal-body">
        <p>Are you sure you want to publish this D2C configuration detail?</p>
    </div>

    <div class="modal-footer">
        <button type="button" name="ok" (click)="publishD2CDetails()" class="btn btn-success">Yes</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
    </div>
</ng-template>