<div class="card-header bg-success">
    <div class="row">
        <div class="col-md-6">
            <h3 class="text-white">Quote: {{quoteData.quote_ref_number}} 
                <small [hidden]="quoteData.is_policy == 1 || isInsurer || isBroker"><a [routerLink]="['/pre-qualifier',4,quoteData.id]" class="btn btn-success btn-sm">Edit</a></small>
            </h3>
        </div> 
       <div class="col-md-6 float-right" [hidden]="quoteData.is_policy == 1 || isInsurer"> 
            <div class="align-items-center justify-content-between float-right" *ngIf="isActionBtn">
                <ul class="navbar-nav navbar-nav-right mr-3">
                    <li class="nav-item nav-profile dropdown">
                        <a class="nav-link dropdown-toggle p-0 text-white" href="#" data-toggle="dropdown">
                            Actions
                        </a>
                        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                            <a (click)="updateQuoteStatus('80c0db90-ba5d-4387-b09a-4c008a664932')" class="dropdown-item" *ngIf="quoteData.insurance_status_id == 'f6d14624-6351-4d93-b27b-70fb8a06ec5d' && isAdmin">
                                <i class="mdi mdi-account-convert text-primary"></i>
                                Convert to Quote
                            </a>
                            <a (click)="convertToPolicy(converttopolicymodal,MTAMsgBox)" class="dropdown-item" *ngIf="quoteData.insurance_status_id == '80c0db90-ba5d-4387-b09a-4c008a664932'">
                                <i class="mdi mdi-account-convert text-primary"></i>
                                Convert to Policy
                            </a>
                            <a (click)="QuoteLapseCfmMSg(msgBox)" class="dropdown-item" *ngIf="quoteData.type == 2">
                                <i class="mdi mdi-delete text-primary"></i>
                                Lapse 
                            </a> 
                            <a (click)="updateQuoteDeclineStatus(declineMsgBox)" class="dropdown-item" *ngIf="quoteData.insurance_status_id != 'dda8734c-d149-4336-b8cc-c2e205430752'">
                                <i class="mdi mdi-alert-circle text-danger"></i>
                                Decline
                            </a>
                        </div>
                    </li>
                </ul>
            </div> 
        </div>   
    </div>
</div>
<mat-tab-group class="float-left w-100" [(selectedIndex)]="selectedIndex">
    <mat-tab>
        <ng-template mat-tab-label>
            Overview
        </ng-template>
        <div class="card-body">
            <app-allianz-overview [quoteData]="quoteData" (staticData)="updatedStaticData($event)"
            (hasImportantNotes)="onhasImportantNotesChange($event)" (quoteDetailsUpdated)="quoteDetailsUpdated($event)"></app-allianz-overview>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Full Details
        </ng-template>
        <div class="card-body">
            <app-allianz-quote-full-details [quote]="quoteData" [staticData]="staticData"></app-allianz-quote-full-details>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Statement of Fact
        </ng-template>
        <div class="card-body">
            <app-allianz-quote-sof [quote]="quoteData"></app-allianz-quote-sof>
        </div>
    </mat-tab>

    <mat-tab *ngIf="isAdmin">
        <ng-template mat-tab-label>
            Financials <span *ngIf="isBrokerCommissionReduced" class="ml-1" style="color: #ff0000" title="Broker Commission Reduced"><i class="mdi mdi-alert-circle"></i></span>
        </ng-template>
        <div class="card-body">
            <app-financials [quote]="quoteData" [staticData]="staticData"></app-financials>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Documents
        </ng-template>
        <div class="card-body">
            <app-quote-documents [quote]="quoteData"></app-quote-documents>
            <!-- <app-documents [quote]="quoteData"></app-documents> -->
        </div>
    </mat-tab>
    <!-- <mat-tab *ngIf="isAdmin">
        <ng-template mat-tab-label>
            Claims
        </ng-template>
        <div class="card-body">
            <app-claims [quote]="quoteData"></app-claims>
        </div>
    </mat-tab> -->
    <mat-tab>
        <ng-template mat-tab-label>
            Notes <span *ngIf="hasImportantNotes" class="ml-1" style="color: #ff0000" title="{{totalImpNotes}} Important Notes"><i class="mdi mdi-alert-circle"></i></span>
        </ng-template>
        <div class="card-body">
            <app-quote-notes [quote]="quoteData" (hasImportantNotes)="onhasImportantNotesChange($event)"></app-quote-notes>
            <!-- <app-notes [quote]="quoteData" (hasImportantNotes)="onhasImportantNotesChange($event)"></app-notes> -->
        </div>
    </mat-tab>
    
    <mat-tab *ngIf="!isBroker && (isAdmin || isStaff)">
        <ng-template mat-tab-label>
            Underwriting
        </ng-template>
        <div class="card-body">
            <app-underwriting [quote]="quoteData"></app-underwriting>
        </div>
    </mat-tab>
    <mat-tab *ngIf="isAdmin">
        <ng-template mat-tab-label>
            Email 
        </ng-template>
        <div class="card-body"> 
           <app-quote-email (emailView)="getEmailView($event)" 
                (newEmailView)="newEmailView()"
                [quote]="quoteData"
                *ngIf="!displayEmailView && !displayNewEmailView"></app-quote-email>
           <app-quote-email-view (backToEmailsList)="backToEmailsList()" [email_id]="email_id"  *ngIf="displayEmailView"></app-quote-email-view>
           <app-quote-email-compose (backToEmailsList)="backToEmailsList()" [quote]="quoteData" *ngIf="displayNewEmailView"></app-quote-email-compose>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            Agreed Questions
        </ng-template>
        <div class="card-body">
            <app-allianz-quote-agq [quote]="quoteData"></app-allianz-quote-agq>
        </div>
    </mat-tab>
    
</mat-tab-group>

<ng-template #converttopolicymodal let-modal>
    <div class="modal-body">
        <p>We assume you have all the quote data. Are you sure you want to convert <b>{{ quoteData.quote_ref_number }} </b> into policy?</p>
        <div class="form-group">
            <label>Policy Start Date</label>
            <!-- [min]="minPolicyDate" -->
            <div class="w-100"> 
                <input type="text" readonly required name="policy_start_date" [(ngModel)]="quoteData.policy_start_date"
                    #policy_start_date="ngModel" placeholder="Policy Start Date" style="height: 31px;" [disabled] = "quoteData.type == 3 && quoteData.financial_details.policy_premium > 0"
                    class="form-control form-control-sm datepicker" matInput  [max]="maxPolicyDate" 
                    [matDatepicker]="myDatepicker" [ngClass]="{ 'is-invalid': disablIfPastDate }" (dateChange)="onPolicyStartDateChange($event)">
                <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </div>
        </div>
    </div>
    <div class="modal-footer"> 
        <button type="button" class="btn btn-success" (click)="convertToPolicyPost()" [disabled]="disablIfPastDate">Convert to Policy</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
    </div>
</ng-template>

<ng-template #msgBox let-modal>
    <div class="modal-body">
        <p>{{confimMsg}}</p>        
    </div>
    <div class="modal-footer"> 
        <button type="button" class="btn btn-success" (click)="lapseQuote()">Lapse</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<ng-template #declineMsgBox let-modal>
    <div class="modal-body">
        <p>{{confimMsg}}</p>        
    </div>
    <div class="modal-footer"> 
        <button type="button" class="btn btn-success" (click)="DeclineQuote()">Decline</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<ng-template #MTAMsgBox let-modal>
    <div class="modal-body">
        <p>This quote has expired and can no longer be converted. Please edit and resubmit the quote for our review. Please ensure you leave a note to advise if you have made any changes or not.</p>        
    </div>
    <div class="modal-footer"> 
        <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
    </div>
</ng-template>
