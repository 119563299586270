<form #f="ngForm" class="form_details">
    <!-- Policy Holder Details-->
    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Policy Holder Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row form-group">
                <div class="col-md-4"><label>Title</label></div>
                <div class="col-md-6">
                    <input type="text" name="title" placeholder="Title" class="form-control form-control-sm"
                        value="{{titleName}}" readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>First name</label></div>
                <div class="col-md-6">
                    <input type="text" name="first_name" placeholder="Firstname" class="form-control form-control-sm"
                        [(ngModel)]="quote.policy_holder.first_name" appTitleCase readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Last name</label></div>
                <div class="col-md-6">
                    <input type="text" name="last_name" placeholder="Lastname" class="form-control form-control-sm"
                        [(ngModel)]="quote.policy_holder.last_name" appTitleCase readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>DOB</label></div>
                <div class="col-md-6">
                    <input required type="text" name="dob" placeholder="DOB" class="form-control form-control-sm"
                        [value]="quote.policy_holder.dob | date:'dd-MM-yyyy'" readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Contact Number</label></div>
                <div class="col-md-6">
                    <input required type="text" name="phone" placeholder="Contact Number"
                        class="form-control form-control-sm" [(ngModel)]="quote.policy_holder.phone" readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Email</label></div>
                <div class="col-md-6">
                    <input type="text" name="email" [(ngModel)]="quote.policy_holder.email"
                        class="form-control form-control-sm" placeholder="Email" readonly>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Policy Holder Address Details -->
    <div class="form_sections" *ngIf="quote.policy_holder.address !=null">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Address Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row form-group">
                <div class="col-md-4"><label>Address 1</label></div>
                <div class="col-md-6">
                    <input type="text" name="ph_address_line_1" placeholder="Address 1"
                        class="form-control form-control-sm" [(ngModel)]="quote.policy_holder.address.address1"
                        appTitleCase readonly>
                </div>
            </div>
            <div class="row form-group" *ngIf="quote.policy_holder.address.address2">
                <div class="col-md-4"><label>Address 2</label></div>
                <div class="col-md-6">
                    <input type="text" name="ph_address_line_2" placeholder="Address 2"
                        class="form-control form-control-sm" [(ngModel)]="quote.policy_holder.address.address2"
                        appTitleCase readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Town</label></div>
                <div class="col-md-6">
                    <input type="text" name="ph_city" placeholder="Town" class="form-control form-control-sm"
                        [(ngModel)]="quote.policy_holder.address.city" appTitleCase readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Country</label></div>
                <div class="col-md-6">
                    <input required type="text" name="ph_country" placeholder="Country"
                        class="form-control form-control-sm" [value]="quote.policy_holder.address.country" readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Postcode</label></div>
                <div class="col-md-6">
                    <input required type="text" name="ph_postcode" placeholder="Postcode"
                        class="form-control form-control-sm" [(ngModel)]="quote.policy_holder.address.postcode"
                        readonly>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Policy Level Details -->
    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Policy Level Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row form-group">
                <div class="col-md-4"><label>Cover Type</label></div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.horse_class_id" class="form-control form-control-sm"
                            name="cver_type" disabled1>
                            <mat-option *ngFor="let uses of classOfUse" [value]="uses.id">
                                {{uses.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row form-group" *ngIf="!BloodStockDetails">
                <div class="col-md-4"><label>Primary Cover</label></div>
                <div class="col-md-6">
                    <input type="text" name="hordeSubCass" readonly [(ngModel)]="quote.horse_sub_class.name"
                        class="form-control form-control-sm">
                </div>
            </div>
            <div class="row form-group" *ngIf="BloodStockDetails">
                <div class="col-md-4"><label>Primary Cover</label></div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.blood_stock_id" class="form-control form-control-sm"
                            name="blood_stock_id" disabled>
                            <mat-option [value]="">None</mat-option>
                            <mat-option *ngFor="let bloodStock of bloodStocks" [value]="bloodStock.id">
                                {{bloodStock.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Policy Cover Details</label></div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.policy_cover_id" class="form-control form-control-sm"
                            name="policy_cover" disabled>
                            <mat-option [value]="">None</mat-option>
                            <mat-option *ngFor="let policy of policyCover" [value]="policy.id">
                                {{policy.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Mortality Claim Loading</label></div>
                <div class="col-md-6">
                    <input type="text" name="mortality_claim_loading" class="form-control form-control-sm"
                        [(ngModel)]="mortality_claim_loading" readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Does the policy holder own the horse</label></div>
                <div class="col-md-6">
                    <label class="switch switch-left-right">
                        <input class="switch-input" type="checkbox" disabled name="does_policy_owner_owns_horse"
                            [checked]="quote.does_policy_owner_owns_horse == 1" />
                        <span class="switch-label" data-on="Yes" data-off="No"></span>
                        <span class="switch-handle"></span>
                    </label>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Horse Purchase/Loan Date</label></div>
                <div class="col-md-6">
                    <div class="input-group">
                        <input type="text" name="purchase_date" placeholder="Horse Purchase/Loan Date"
                            class="form-control form-control-sm" readonly
                            [value]="quote.horse_details.horse_purchase_date | date:'dd-MM-yyyy'">
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Horse Details -->
    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Horse Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row form-group">
                <div class="col-md-4"><label>Name</label></div>
                <div class="col-md-6">
                    <input type="text" name="horse_name" placeholder="Horse Name" class="form-control form-control-sm"
                        readonly [(ngModel)]="quote.horse_details.name" appTitleCase>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Passport Name</label></div>
                <div class="col-md-6">
                    <input type="text" name="passport_name" placeholder="Passport Name"
                        class="form-control form-control-sm" readonly [(ngModel)]="quote.horse_details.passport_name">
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Sum Insured</label></div>
                <div class="col-md-6">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-success text-white">&pound;</span>
                        </div>
                        <input type="text" name="horse_value" placeholder="Horse Value"
                            class="form-control form-control-sm" readonly [(ngModel)]="quote.horse_details.value"
                            appDecimalNumbers>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Purchase Price</label></div>
                <div class="col-md-6">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-success text-white">&pound;</span>
                        </div>
                        <input type="text" name="purchase_price" placeholder="Purchase Price"
                            class="form-control form-control-sm" readonly
                            [(ngModel)]="quote.horse_details.purchase_price" appDecimalNumbers>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Age</label></div>
                <div class="col-md-6">
                    <input type="text" name="horse_age" placeholder="Horse Age" class="form-control form-control-sm"
                        readonly value="{{quote.horse_details.age}} years {{quote.horse_details.age_month}} Months">
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Gender</label></div>
                <div class="col-md-6">
                    <input type="text" name="horse_gender" placeholder="Horse Gender"
                        class="form-control form-control-sm" readonly [value]="HorseGenderName">
                </div>
            </div>
            <div class="row">
                <div class="col-md-4"><label>Height</label></div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.horse_details.height" class="form-control form-control-sm"
                            name="horse_height" disabled>
                            <mat-option *ngFor="let hh of horseHeight" [value]="hh.height">
                                {{hh.height}} HH
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4"><label>Breed</label></div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.horse_details.breed_id" class="form-control form-control-sm"
                            name="horse_breed" disabled>
                            <mat-option *ngFor="let hb of horseBreed" [value]="hb.id">
                                {{hb.breed}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-4"><label>Colour</label></div>
                <div class="col-md-6">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="quote.horse_details.color_id" class="form-control form-control-sm"
                            name="color_id" disabled>
                            <mat-option *ngFor="let color of horseColor" [value]="color.id">
                                {{color.color}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4"><label>Passport Number</label></div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" name="passport" placeholder="Horse Passport Number"
                            class="form-control form-control-sm" readonly [(ngModel)]="quote.horse_details.passport">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4"><label>Microchip Number</label></div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" name="microchip" placeholder="Horse Microchip Number"
                            class="form-control form-control-sm" readonly [(ngModel)]="quote.horse_details.microchip">
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Horse Purchase/Loan Date</label></div>
                <div class="col-md-6">
                    <div class="input-group">
                        <input type="text" name="purchase_date" placeholder="Horse Purchase/Loan Date"
                            class="form-control form-control-sm" readonly
                            [value]="quote.horse_details.horse_purchase_date | date:'dd-MM-yyyy'">
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Have you ever had the horse vetted ?</label></div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label class="switch switch-left-right">
                            <input class="switch-input" type="checkbox" disabled name="is_horse_vetted"
                                [checked]="quote.horse_details.is_horse_vetted == 1" />
                            <span class="switch-label" data-on="Yes" data-off="No"></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row form-group" *ngIf="quote.horse_details.is_horse_vetted == 1">
                <div class="col-md-4"><label>Date of Vetting</label></div>
                <div class="col-md-6">
                    <div class="input-group">
                        <input type="text" name="purchase_date" placeholder="Horse Purchase/Loan Date"
                            class="form-control form-control-sm" readonly
                            [value]="quote.horse_details.date_of_vetting | date:'dd-MM-yyyy'">
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Is the stabling address different to the correspondence address?</label>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label class="switch switch-left-right">
                            <input class="switch-input" type="checkbox" disabled name="is_stable_address_different"
                                [checked]="quote.horse_details.is_stable_address_different == 1" />
                            <span class="switch-label" data-on="Yes" data-off="No"></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div *ngIf="quote.horse_details.is_stable_address_different == 1 && quote.id && quote.horse_details.address">
                <div class="row form-group">
                    <div class="col-md-4"><label>Address Line 1</label></div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <input type="text" name="horse_address_line_1" placeholder="Address Line 1"
                                class="form-control form-control-sm" readonly
                                [(ngModel)]="quoteDetails.horse_details.address.address1">
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-4"><label>Address Line 2</label></div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <input type="text" name="horse_address_line_2" placeholder="Address Line 2"
                                class="form-control form-control-sm" readonly
                                [(ngModel)]="quoteDetails.horse_details.address.address1">
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-4"><label>City</label></div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <input type="text" name="horse_city" placeholder="City"
                                class="form-control form-control-sm" readonly
                                [(ngModel)]="quoteDetails.horse_details.address.city">
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-4"><label>County</label></div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <input type="text" name="horse_county" placeholder="County"
                                class="form-control form-control-sm" readonly
                                [(ngModel)]="quoteDetails.horse_details.address.country">
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-4"><label>Postcode</label></div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <input type="text" name="postcode" placeholder="Postcode"
                                class="form-control form-control-sm" readonly
                                [(ngModel)]="quoteDetails.horse_details.address.postcode">
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Vet Fee Details -->
    <div class="form_sections">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Vet Fee Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row form-group" *ngIf="quote.full_vet_fee_cover">
                <div class="col-md-4"><label>Vets Fees Indemnity Limit</label></div>
                <div class="col-md-6">
                    <input type="text" name="vet_fees_cover" placeholder="Vets fees cover"
                        [value]="(quote.full_vet_fee_cover)?quote.full_vet_fee_cover.replace('£',''):''"
                        class="form-control form-control-sm" readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Veterinary Fees Claim History</label></div>
                <div class="col-md-6">
                    <input type="text" name="vet_claim_loading" class="form-control form-control-sm"
                        [(ngModel)]="vet_claim_loading" readonly>
                </div>
            </div>
            <div class="row form-group" *ngIf="quote.full_life_saving_surgery">
                <div class="col-md-4"><label>Life Saving Surgery/Colic Surger</label></div>
                <div class="col-md-6">
                    <input type="text" name="full_life_saving_surgery" placeholder="Life Saving Surgery/Colic Surger"
                        [value]="quote.full_life_saving_surgery" class="form-control form-control-sm" readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label>Vets Fees Excess</label></div>
                <div class="col-md-6">
                    <input type="text" name="vet_fees_excess" [value]="quote.full_vet_fee_excess"
                        placeholder="Vets fees excess" class="form-control form-control-sm" readonly>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Extra items -->
    <div class="form_sections" *ngIf="quote.does_addon_saddlery_on == 1">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Extra Items
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="quote.does_addon_saddlery_on == 1">
                <h5 class="text-white">Saddlery & Tack Details</h5>
                <p class="text-white"><small>Unspecified Item Sum Insured:
                        &pound;{{quote.addon_saddlery_value - saddleryTotalSumInsured}}</small></p>
                <div *ngFor="let saddlery_tack_item of quote.horse_details.saddlery_items; let i = index;">
                    <h6 class="text-white">Item {{i + 1}}</h6>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="saddlery_items_brand_{{i}}" placeholder="Make & Model"
                                    class="form-control form-control-sm" [(ngModel)]="saddlery_tack_item.brand"
                                    readonly>
                            </div>
                            <div class="form-group">
                                <input type="text" name="saddlery_sum_insured_{{i}}" placeholder="Sum Insured"
                                    class="form-control form-control-sm" appDecimalNumbers
                                    [(ngModel)]="saddlery_tack_item.sum_insured" readonly>
                            </div>
                            <div class="form-group">
                                <input type="text" name="saddlery_color_{{i}}" placeholder="Color"
                                    class="form-control form-control-sm" [(ngModel)]="saddlery_tack_item.color"
                                    readonly>
                            </div>
                            <div class="form-group">
                                <input type="text" name="saddlery_purchase_date_{{i}}" placeholder="Purchase Date"
                                    class="form-control form-control-sm"
                                    [value]="saddlery_tack_item.purchase_date | date:'dd-MM-yyyy'" readonly>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <textarea name="saddlery_description_{{i}}" placeholder="Description"
                                    class="form-control" rows="7" [(ngModel)]="saddlery_tack_item.description"
                                    readonly></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <!-- Endorsement -->
    <app-quote-endorsement [quote]="quote"></app-quote-endorsement>
</form>