import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private sharedService: SharedService, private sessionSt: LocalStorageService, private router: Router) { }

  getUserDetails() {
    return this.sharedService.getAPI('user/' + this.sessionSt.retrieve('userInfo').id).subscribe(
      (response: any) => {
        if (response.success) {
          this.sessionSt.store('userInfo', response.result.user);
          this.sessionSt.store('companyProducts', response.result.products_assigned);
          this.sessionSt.store('company_role', response.result.company_role_name);
          this.sessionSt.store('user_group', response.result.user_group_name);
          if (response.result.user.company != null) {
            this.sessionSt.store('broker_company_id', response.result.user.company.id);
            this.sessionSt.store('al_broker_company_id', response.result.user.company.id);
          }
          this.sharedService.getUserType();
          this.checkIfProductAssigned(response.result.products_assigned);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  checkIfProductAssigned(products_assigned) {
    let url = this.router.url;
    let product_id = '';
    if (url) {
      let splitted_url = url.split('/');     
      if (splitted_url.length > 0 && splitted_url[1] == 'pre-qualifier') {
        if (splitted_url[2]) {
          switch (splitted_url[2] && parseInt(splitted_url[2])) {
            case 1:
              product_id = environment.EQUINE_ID;
              break;
            case 2:
              product_id = environment.ALPACA_ID;
              break;
            case 3:
              product_id = environment.LIVESTOCK_ID;
              break;
            case 4:
              product_id = environment.ALLIANZ_EQUINE_ID;
              break;
            case 5:
              product_id = environment.AJG_ID;
              break;
            case 6:
              product_id = environment.AMLINE_ID;
              break;
          }

          let products = products_assigned.filter(x => x.product_id == product_id);
          if (products.length == 0) {
            this.router.navigate(['/unauthorized']);
          } else {
            if (parseInt(splitted_url[2]) == 1 && !splitted_url[3] && this.sessionSt.retrieve('is_broker')) {
              this.router.navigate(['/unauthorized']);
            }
          }
        }
      }

      if(splitted_url[1] == 'quote' && !splitted_url[2] && this.sessionSt.retrieve('is_broker')) {
        this.router.navigate(['/unauthorized']);
      }
    }


  }
}
