import { Component, OnInit, SimpleChanges } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { DashboardService } from '../dashboard.service';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-mi',
  templateUrl: './mi.component.html',
  styleUrls: ['../../quote/quote.component.css', '../dashboard.component.css'],
})
export class MiComponent implements OnInit {
  frequency:number = 0;
  constructor(private sessionSt: LocalStorageService, private dashboardService: DashboardService,
    private route: ActivatedRoute) { }

    
  isBroker: boolean = false;
  ngOnInit(): void {
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      this.isBroker = true;
    }
    this.frequency = Number(this.route.snapshot.params.frequency);
    this.getMIData();
  }

  /*
  * Frequency Change
  */

  onFrequencyChange(event) {
    this.frequency = event.value;
    this.getMIData();
  }

  /*
    * GET MI DATA
    */
  miData;
  getMIData() {
    let data = {
      broker_company_id: this.sessionSt.retrieve('broker_company_id'),
      filter_type: this.frequency
    };
    this.dashboardService.getMIData(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.miData = response.result;
          }
        });
  }

}
