export class Drivers {
    id: string = '';
    title: string = '';
    first_name: string = '';
    last_name: string = '';
    dob: string = '';
    licence_type: string = '';
    licence_length: string = '';
    
}
