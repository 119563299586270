// import { Quote } from '@angular/compiler';
// import { parse } from 'path';
import { formatDate, TitleCasePipe } from '@angular/common';
export class CalculationsDetails {
    full_class_net_rate: number = 0.0;
    full_class_net_rate_amount: number = 0.0;
    full_vet_fee_amount: number = 0.0;
    full_net_vet_fee_amount: number = 0.0;
    full_vet_fee_excess_amount: number = 0.0;
    full_vet_fee_excess_discount: number = 0.0;
    full_public_liability_amount: number = 0.0;
    full_personal_accident_amount: number = 0.0;
    full_net_public_liability_amount: number = 0.0;
    full_net_personal_accident_amount: number = 0.0;
    full_main_discount: number = 0.0;
    full_class_gross_amount: number = 0.0;
    full_vet_gross_amount: number = 0.0;
    full_public_gross_amount: number = 0.0;
    full_net_Premium_amount: number = 0.0;
    full_personal_gross_amount: number = 0.0;
    full_ipt_gross_amount: number = 0.0;
    full_total_gross_primum: number = 0.0;
    full_total_premium: number = 0.0;
    full_total_amount_payable: number = 0.0;
    full_broker_amount: number = 0.0;
    full_pay_away_amount: number = 0.0;
    full_peliwica_retained_amount: number = 0.0;
    full_co_pay_discount = 0.0;
    veterinary_fee_co_pay = 0.0;

    aeio_class_net_rate: number = 0.0;
    aeio_class_net_rate_amount: number = 0.0;
    aeio_vet_fee_amount: number = 0.0;
    aeio_net_vet_fee_amount: number = 0.0;
    aeio_vet_fee_excess_amount: number = 0.0;
    aeio_vet_fee_excess_discount: number = 0.0;
    aeio_public_liability_amount: number = 0.0;
    aeio_personal_accident_amount: number = 0.0;
    aeio_net_public_liability_amount: number = 0.0;
    aeio_net_personal_accident_amount: number = 0.0;
    aeio_net_Premium_amount: number = 0.0;
    aeio_main_discount: number = 0.0;
    aeio_class_gross_amount: number = 0.0;
    aeio_vet_gross_amount: number = 0.0;
    aeio_public_gross_amount: number = 0.0;
    aeio_personal_gross_amount: number = 0.0;
    aeio_total_gross_primum: number = 0.0;
    aeio_ipt_gross_amount: number = 0.0;
    aeio_total_premium: number = 0.0;
    aeio_total_amount_payable: number = 0.0;
    aeio_broker_amount: number = 0.0;
    aeio_co_pay_discount = 0.0;

    broker_commission: number = 0.0;
    reduced_broker_commission: number = 0.0;
    actual_broker_commission: number = 0.0;
    peliwica_pay_away_commission: number = 0.0;
    actual_peliwica_pay_away_commission: number = 0.0;
    peliwica_retained_commission: number = 0.0;
    actual_peliwica_retained_commission: number = 0.0;
    ipt_fee: number = 0.0;
    full_underwriting_fee: number = 0.0;
    admin_broker_fee: number = 0.0;

    aeio_underwriting_fee: number = 0.0;
    sum_insured: number = 0.0;
    policy_minimum_premium: number = 0.0;
    class_minimum_premium: number = 0.0;
    vet_minimum_premium: number = 0.0;
    sat_minimum_premium: number = 0.0;
    trailer_minimum_premium: number = 0.0;

    sat_sum_insured: number = 0.0;
    trailer_sum_insured: number = 0.0;
    sat_net_rate: number = 0.0;
    trailer_net_rate: number = 0.0;
    sat_net_premium: number = 0.0;
    trailer_net_premium: number = 0.0;

    sat_gross_amount: number = 0.0;
    trailer_gross_amount: number = 0.0;
    class_age_loading_amount: number = 0.0;
    vet_age_loading_amount: number = 0.0;

    life_saving_surgery: number = 0.0;
    is_life_saving_surgery: boolean = false;
    annual_agg_life_saving: number = 0.0;

    premium_loading: number = 0;
    total_premium_loading: number = 0;
    premium_loading_percentage: number = 0;
    is_mta: boolean = false;
    is_cancel: boolean = false;
    is_lapse: boolean = false;
    is_NTU: boolean = false;
    mta_date;
    tigerlab_premium_loading: number = 0;
    old_tigerlab_premium_loading: number = 0;
    old_premium_loading_percentage: number = 0;

    claim_discount = 0;
    claim_discount_amount = 0;
    customer_discount = 0;
    customer_discount_amount = 0;


    disposal_euthansia_cost = 0;
    elective_surgery_ga_cover = 0;
    elective_surgery_ga_cover_cost = 0;
    elective_surgery_ga_min_premium = 0;
    illness_disease_accident = 0;
    livery_hospitalisation_emegency_transportation = 0;
    aeio_minimum_premium = 0;
    transportation = 0;

    mortality_claim_loading_percentage = 0.0;
    mortality_claim_loading_amount = 0.0;
    vet_fee_claim_loading_percentage = 0.0;
    vet_fee_claim_loading_amount = 0.0;
    total_commission_main = 0;
    constructor() {
        this.full_class_net_rate = 0.0;
        this.full_class_net_rate_amount = 0.0;
        this.full_vet_fee_amount = 0.0;
        this.full_net_vet_fee_amount = 0.0;
        this.full_vet_fee_excess_amount = 0.0;
        this.full_vet_fee_excess_discount = 0.0;
        this.full_public_liability_amount = 0.0;
        this.full_personal_accident_amount = 0.0;
        this.full_net_public_liability_amount = 0.0;
        this.full_net_personal_accident_amount = 0.0;
        this.full_main_discount = 0.0;
        this.full_class_gross_amount = 0.0;
        this.full_vet_gross_amount = 0.0;
        this.full_public_gross_amount = 0.0;
        this.full_net_Premium_amount = 0.0;
        this.full_personal_gross_amount = 0.0;
        this.full_ipt_gross_amount = 0.0;
        this.full_total_gross_primum = 0.0;
        this.full_total_premium = 0.0;
        this.full_total_amount_payable = 0.0;
        this.full_broker_amount = 0.0;
        this.full_pay_away_amount = 0.0;
        this.full_peliwica_retained_amount = 0.0;
        this.full_co_pay_discount = 0.0;
        this.veterinary_fee_co_pay = 0.0;
        this.aeio_class_net_rate = 0.0;
        this.aeio_class_net_rate_amount = 0.0;
        this.aeio_vet_fee_amount = 0.0;
        this.aeio_net_vet_fee_amount = 0.0;
        this.aeio_vet_fee_excess_amount = 0.0;
        this.aeio_vet_fee_excess_discount = 0.0;
        this.aeio_public_liability_amount = 0.0;
        this.aeio_personal_accident_amount = 0.0;
        this.aeio_net_public_liability_amount = 0.0;
        this.aeio_net_personal_accident_amount = 0.0;
        this.aeio_net_Premium_amount = 0.0;
        this.aeio_main_discount = 0.0;
        this.aeio_class_gross_amount = 0.0;
        this.aeio_vet_gross_amount = 0.0;
        this.aeio_public_gross_amount = 0.0;
        this.aeio_personal_gross_amount = 0.0;
        this.aeio_total_gross_primum = 0.0;
        this.aeio_ipt_gross_amount = 0.0;
        this.aeio_total_premium = 0.0;
        this.aeio_total_amount_payable = 0.0;
        this.aeio_broker_amount = 0.0;
        this.aeio_co_pay_discount = 0.0;

        this.broker_commission = 0.0;
        this.reduced_broker_commission = 0.0;
        this.actual_broker_commission = 0.0;
        this.peliwica_pay_away_commission = 0.0;
        this.actual_peliwica_pay_away_commission = 0.0;
        this.peliwica_retained_commission = 0.0;
        this.actual_peliwica_retained_commission = 0.0;
        this.ipt_fee = 0.0;
        this.full_underwriting_fee = 0.0;
        this.admin_broker_fee = 0.0;

        this.aeio_underwriting_fee = 0.0;
        this.sum_insured = 0.0;
        this.policy_minimum_premium = 0.0;
        this.class_minimum_premium = 0.0;
        this.vet_minimum_premium = 0.0;
        this.sat_minimum_premium = 0.0;
        this.trailer_minimum_premium = 0.0;

        this.sat_sum_insured = 0.0;
        this.trailer_sum_insured = 0.0;
        this.sat_net_rate = 0.0;
        this.trailer_net_rate = 0.0;
        this.sat_net_premium = 0.0;
        this.trailer_net_premium = 0.0;

        this.sat_gross_amount = 0.0;
        this.trailer_gross_amount = 0.0;
        this.class_age_loading_amount = 0.0;
        this.vet_age_loading_amount = 0.0;

        this.life_saving_surgery = 0.0;
        this.is_life_saving_surgery = false;
        this.annual_agg_life_saving = 0.0;

        this.premium_loading = 0;
        this.total_premium_loading = 0;
        this.premium_loading_percentage = 0;
        this.is_mta = false;
        this.is_cancel = false;
        this.is_lapse = false;
        this.is_NTU = false;
        this.mta_date;
        this.tigerlab_premium_loading = 0;
        this.old_tigerlab_premium_loading = 0;
        this.old_premium_loading_percentage = 0;

        this.claim_discount = 0;
        this.claim_discount_amount = 0;
        this.customer_discount = 0;
        this.customer_discount_amount = 0;
        this.disposal_euthansia_cost = 0;
        this.elective_surgery_ga_cover = 0;
        this.elective_surgery_ga_cover_cost = 0;
        this.livery_hospitalisation_emegency_transportation = 0;
        this.aeio_minimum_premium = 0;
        this.elective_surgery_ga_min_premium = 0;
        this.transportation = 0;

        this.mortality_claim_loading_percentage = 0.0;
        this.mortality_claim_loading_amount = 0.0;
        this.vet_fee_claim_loading_percentage = 0.0;
        this.vet_fee_claim_loading_amount = 0.0;
        this.total_commission_main = 0;
    }
    //mta_quote_data ;
    public full_calculate_data(mta_quote_data) {
        //this.mta_quote_data = mta_quote_data;
        // let IsMTA = true;
        let fullNetPremiumAmount = 0;
        this.reduced_broker_commission = this.actual_broker_commission - this.broker_commission;
        let total_commission = Number(this.actual_broker_commission) + Number(this.peliwica_pay_away_commission) + Number(this.peliwica_retained_commission); // main class
        this.total_commission_main = total_commission;
        // let oCalculationModel : CalculationModel;
        var net_rate_amount = this.sum_insured * Number(this.full_class_net_rate) / 100;

        // oCalculationModel.sum_insured = this.sum_insured;
        this.full_class_net_rate_amount = this.main_class_net(net_rate_amount, this.class_minimum_premium,
            this.class_age_loading_amount, false, this.full_main_discount, this.full_vet_fee_excess_discount, this.full_co_pay_discount, this.veterinary_fee_co_pay);



        this.full_class_gross_amount = this.gross_amount(this.full_class_net_rate_amount, total_commission, this.reduced_broker_commission);
        let disposal_euthansia_cost = 0;
        disposal_euthansia_cost = this.gross_amount(this.disposal_euthansia_cost, total_commission, this.reduced_broker_commission);
        this.full_class_gross_amount = this.full_class_gross_amount + Number(disposal_euthansia_cost);

        let elective_surgery_ga_cover = 0;
        let elective_surgery_ga_cover_cost = 0;
        elective_surgery_ga_cover = this.gross_amount(this.elective_surgery_ga_cover, total_commission, this.reduced_broker_commission);
        elective_surgery_ga_cover_cost = this.calculate_percentage_amount(this.sum_insured, elective_surgery_ga_cover);
        let elective_surgery_ga_min_premium = this.gross_amount(this.elective_surgery_ga_min_premium, total_commission, this.reduced_broker_commission);
        if (this.elective_surgery_ga_cover_cost < elective_surgery_ga_min_premium) {
            elective_surgery_ga_cover_cost = elective_surgery_ga_min_premium;
        }
        this.full_class_gross_amount = this.full_class_gross_amount + Number(elective_surgery_ga_cover_cost);
        //


        // Vat Fee
        this.full_net_vet_fee_amount = this.main_class_net(this.full_vet_fee_amount, this.vet_minimum_premium,
            this.vet_age_loading_amount, true, this.full_main_discount, this.full_vet_fee_excess_discount, this.full_co_pay_discount, this.veterinary_fee_co_pay);
        this.full_vet_gross_amount = this.gross_amount(this.full_net_vet_fee_amount, total_commission, this.reduced_broker_commission);

        let livery_hospitalisation_emegency_transportation = 0;
        livery_hospitalisation_emegency_transportation = this.gross_amount(this.livery_hospitalisation_emegency_transportation, total_commission, this.reduced_broker_commission);
        this.full_vet_gross_amount = this.full_vet_gross_amount + Number(livery_hospitalisation_emegency_transportation);

        let transportation = 0;
        transportation = this.gross_amount(this.transportation, total_commission, this.reduced_broker_commission);
        this.full_vet_gross_amount = this.full_vet_gross_amount + Number(transportation);

        if (Number(this.mortality_claim_loading_percentage) > 0) {
            this.mortality_claim_loading_amount = Number(this.full_class_gross_amount) * Number(this.mortality_claim_loading_percentage) / 100;
            this.mortality_claim_loading_amount = this.gross_amount(this.mortality_claim_loading_amount, 0, this.reduced_broker_commission);
        }
        else {
            this.mortality_claim_loading_amount = 0;
        }
        if (Number(this.vet_fee_claim_loading_percentage) > 0) {
            this.vet_fee_claim_loading_amount = this.gross_amount(this.vet_fee_claim_loading_percentage, total_commission, this.reduced_broker_commission);
            // this.vet_fee_claim_loading_amount = this.vet_fee_claim_loading_amount + vet_fee_claim_loading;
        }
        else {
            this.vet_fee_claim_loading_amount = 0;
        }
        this.full_class_gross_amount = Number(this.full_class_gross_amount) + Number(this.mortality_claim_loading_amount);
        this.full_vet_gross_amount = Number(this.full_vet_gross_amount) + Number(this.vet_fee_claim_loading_amount);
        //    this.livery_hospitalisation_emegency_transportation = this.

        // LSS - 2500
        let annual_agg_life_saving = this.gross_amount(this.annual_agg_life_saving, this.total_commission_main, this.reduced_broker_commission);
        let life_saving_surgery = this.gross_amount(this.life_saving_surgery, this.total_commission_main, this.reduced_broker_commission);
        // Saddlery and Tack 
        var sat_net_rate_amount = parseFloat(Number(this.sat_sum_insured * this.sat_net_rate / 100).toFixed(2));
        this.sat_net_premium = this.main_class_net(sat_net_rate_amount, this.sat_minimum_premium, 0, false, 0
            , this.full_vet_fee_excess_discount, 0, 0);

        this.sat_gross_amount = this.gross_amount(this.sat_net_premium, total_commission, this.reduced_broker_commission);
        //  Trailers and Horsedrawn Vehicles
        var trailer_net_rate_amount = parseFloat(Number(this.trailer_sum_insured * this.trailer_net_rate / 100).toFixed(2));
        this.trailer_net_premium = this.main_class_net(trailer_net_rate_amount, this.trailer_minimum_premium, 0, false, 0,
            this.full_vet_fee_excess_discount, 0, 0);
        this.trailer_gross_amount = this.gross_amount(this.trailer_net_premium, total_commission, this.reduced_broker_commission);

        // calcualte primum loading amount 

        var net_policy_amount = parseFloat(Number((this.full_class_net_rate_amount) + (this.full_net_vet_fee_amount)
            + (this.trailer_net_premium) + (this.sat_net_premium)).toFixed(2));;
        this.premium_loading = this.calculate_percentage_amount(net_policy_amount, this.premium_loading_percentage);



        this.total_premium_loading = this.gross_amount(this.premium_loading, total_commission, this.reduced_broker_commission);




        fullNetPremiumAmount = parseFloat(Number((this.full_class_net_rate_amount) + (this.full_net_vet_fee_amount) + (this.full_net_public_liability_amount) + (this.full_net_personal_accident_amount)
            + (this.trailer_net_premium) + (this.sat_net_premium) + Number(this.premium_loading) + Number(annual_agg_life_saving) + Number(life_saving_surgery)
            + Number(this.disposal_euthansia_cost)
            + Number(this.elective_surgery_ga_cover_cost) + Number(this.livery_hospitalisation_emegency_transportation)
            + Number(this.transportation)).toFixed(2));

        this.full_net_Premium_amount = this.gross_amount(fullNetPremiumAmount, total_commission, this.reduced_broker_commission);


        this.full_total_gross_primum = parseFloat(Number((this.full_class_gross_amount) + (this.full_vet_gross_amount) + (this.full_public_gross_amount) + (this.full_personal_gross_amount)
            + (this.trailer_gross_amount) + (this.sat_gross_amount) + (this.total_premium_loading) + Number(this.tigerlab_premium_loading)
            + Number(annual_agg_life_saving) + Number(life_saving_surgery)
        ).toFixed(2));;

        this.claim_discount_amount = this.calculate_percentage_amount(this.full_total_gross_primum, -this.claim_discount);

        this.customer_discount_amount = this.calculate_percentage_amount(this.full_total_gross_primum, -this.customer_discount);
        this.full_total_gross_primum = this.full_total_gross_primum + Number(this.claim_discount_amount) + Number(this.customer_discount_amount)

        let minimum_premium: number = 0.0;
        minimum_premium = this.gross_amount(this.policy_minimum_premium, total_commission, this.reduced_broker_commission);

        if (Number(this.full_total_gross_primum) < minimum_premium) {
            //this.full_total_gross_primum = Number(this.policy_minimum_premium); 
            this.full_total_gross_primum = this.gross_amount(this.policy_minimum_premium, total_commission, this.reduced_broker_commission);
        }
        else {
            // this.full_total_gross_primum =  this.gross_amount(this.full_total_gross_primum, total_commission);  
        }



        var Difference_In_Days_cancle;
        var Difference_In_Days_old;

        if (this.is_mta) {
            if (parseFloat(Number(mta_quote_data.mta_sum_policy_premium).toFixed(2)) != parseFloat(Number(this.full_total_gross_primum).toFixed(2))) {

                this.full_total_gross_primum = parseFloat(Number(Number(this.full_total_gross_primum) - Number(mta_quote_data.mta_sum_policy_premium)).toFixed(2));
                let newDate = new Date(this.mta_date);
                let policy_end_date = new Date(mta_quote_data.latest_policy_end_date)
                var Difference_In_Time = (policy_end_date.getTime() - newDate.getTime()) + 86400000;
                var Difference_In_Days_MTA = Difference_In_Time / (1000 * 3600 * 24);

                let oldDate = new Date(mta_quote_data.latest_policy_start_date);
                // let policy_end_date = new Date(mta_quote_data.policy_end_date)
                Difference_In_Time_old = (policy_end_date.getTime() - oldDate.getTime()) + 86400000;
                Difference_In_Days_old = Difference_In_Time_old / (1000 * 3600 * 24);

                this.full_total_gross_primum = parseFloat(Number(this.full_total_gross_primum / Number(Difference_In_Days_old) * Number(Difference_In_Days_MTA)).toFixed(2));

                if (this.full_total_gross_primum <= 0.10 && this.full_total_gross_primum >= -0.10) {
                    this.full_total_gross_primum = 0;
                }
            }
            else {
                this.full_total_gross_primum = 0
            }

        }


        if (this.is_cancel) {
            // this.full_total_gross_primum =parseFloat(Number(Number(this.full_total_gross_primum)-Number(mta_quote_data.quote_versions[0].financial_details.policy_premium)).toFixed(2));
            if (!this.mta_date) {
                this.mta_date = mta_quote_data.policy_start_date;
            }
            let newDate1 = new Date(this.mta_date);
            let newDate = newDate1;
            if (newDate1) {
                newDate = new Date(formatDate(newDate1, 'yyyy-MM-dd 00:00:00', 'en-US'));
            }
            let policy_end_date = new Date(mta_quote_data.latest_policy_end_date)
            var Difference_In_Time = (policy_end_date.getTime() - newDate.getTime()) + 86400000;
            Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);

            let policy_ntu_date = new Date()


            let oldDate = new Date(mta_quote_data.original_policy_start_date);
            // let policy_end_date = new Date(mta_quote_data.policy_end_date)
            var Difference_In_ntu_Time = policy_ntu_date.getTime() - oldDate.getTime();
            Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);
            var Difference_In_Time_old = (policy_end_date.getTime() - oldDate.getTime()) + 86400000;
            Difference_In_Days_old = Difference_In_Time_old / (1000 * 3600 * 24);

            if (newDate >= oldDate) {
                let vet_gross_amount = 0;
                let annual_agg_life_saving_amount = 0;
                let life_saving_surgery = 0;
                if (Difference_In_Days_cancle > 182) {
                    vet_gross_amount = 0;
                }
                else {
                    vet_gross_amount = (this.full_vet_gross_amount)

                }
                if (Number(Difference_In_Days_old) - Number(Difference_In_Days_cancle) < 14) {
                    life_saving_surgery = 0;
                    annual_agg_life_saving_amount = 0;
                }
                else {
                    life_saving_surgery = this.life_saving_surgery;
                    annual_agg_life_saving_amount = annual_agg_life_saving;

                }


                if (parseFloat(Number(mta_quote_data.mta_sum_policy_premium).toFixed(2)) == Number(this.full_total_gross_primum)) {
                    this.full_total_gross_primum = - parseFloat(Number((this.full_total_gross_primum - vet_gross_amount - annual_agg_life_saving_amount - life_saving_surgery) / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                }
                else {
                    let full_return_amount: number = 0;
                    full_return_amount = - parseFloat(Number((mta_quote_data.mta_sum_policy_premium - vet_gross_amount - annual_agg_life_saving_amount - life_saving_surgery) / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                    if (Number(this.old_tigerlab_premium_loading) != this.tigerlab_premium_loading || Number(this.old_premium_loading_percentage) != this.premium_loading_percentage) {
                        this.full_total_gross_primum = parseFloat(Number(this.full_total_gross_primum / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                        this.full_total_gross_primum = parseFloat(Number(Number(this.full_total_gross_primum) + Number(full_return_amount)).toFixed(2));
                        //  this.full_total_gross_primum = parseFloat(Number(Number(this.full_total_gross_primum) + Number(full_return_amount)).toFixed(2));
                    }
                    else {

                        this.full_total_gross_primum = full_return_amount;
                    }

                }
            }

        }

        this.full_ipt_gross_amount = parseFloat(Number(Number(this.full_total_gross_primum) * Number(this.ipt_fee) / 100).toFixed(2));;


        this.full_total_premium = parseFloat(Number(this.full_total_gross_primum + (this.full_total_gross_primum * this.ipt_fee / 100)).toFixed(2));


        this.full_underwriting_fee = this.calculate_UW_fee();

        this.admin_broker_fee = this.admin_broker_fee;

        if (this.is_mta) {
            this.full_underwriting_fee = 0;
            this.admin_broker_fee = 0;
        }

        if (this.is_cancel && !this.is_NTU) {
            //   this.full_underwriting_fee =  - parseFloat(Number(this.full_underwriting_fee *  (Number(Difference_In_Days_old) - Number(Difference_In_Days_cancle))/Number(Difference_In_Days_old)).toFixed(2));

            //   this.full_underwriting_fee = Number(mta_quote_data.quote_versions[0].financial_details.uw_fee);
            // this.full_underwriting_fee =  - parseFloat(Number(this.full_underwriting_fee /Number(Difference_In_Days_old) *   Number(Difference_In_Days_cancle)).toFixed(2));
            this.full_underwriting_fee = 0;
            this.admin_broker_fee = 0;
        }

        this.full_total_amount_payable = parseFloat(Number(Number(this.full_total_premium) + Number(this.full_underwriting_fee) + Number(this.admin_broker_fee)).toFixed(2));

        if (this.is_mta || this.is_cancel) {
            fullNetPremiumAmount = this.full_total_gross_primum;
        }
        this.full_broker_amount = parseFloat(Number(parseFloat(Number(fullNetPremiumAmount).toFixed(2)) / (100 - total_commission) * this.broker_commission).toFixed(2));
        this.full_pay_away_amount = parseFloat(Number(parseFloat(Number(fullNetPremiumAmount).toFixed(2)) / (100 - total_commission) * this.peliwica_pay_away_commission).toFixed(2));
        this.full_peliwica_retained_amount = parseFloat(Number(parseFloat(Number(fullNetPremiumAmount).toFixed(2)) / (100 - total_commission) * this.peliwica_retained_commission).toFixed(2));
        if (this.is_lapse || this.is_NTU) {

            this.full_total_gross_primum = parseFloat(Number(Number(mta_quote_data.sum_policy_premium)).toFixed(2));
            this.full_ipt_gross_amount = parseFloat(Number(Number(this.full_total_gross_primum) * Number(this.ipt_fee) / 100).toFixed(2));;
            this.full_total_premium = parseFloat(Number(this.full_total_gross_primum + (this.full_total_gross_primum * this.ipt_fee / 100)).toFixed(2));
            this.full_broker_amount = parseFloat(Number(parseFloat(Number(this.full_total_gross_primum).toFixed(2)) * this.broker_commission / 100).toFixed(2));

            this.full_underwriting_fee = Number(this.calculate_UW_fee());
            this.full_total_amount_payable = parseFloat(Number(Number(this.full_total_premium) + Number(mta_quote_data.financial_details.uw_fee) + Number(this.admin_broker_fee)).toFixed(2));

            this.full_total_gross_primum = -Number(this.full_total_gross_primum);
            this.full_ipt_gross_amount = -Number(this.full_ipt_gross_amount)
            this.full_total_premium = -Number(this.full_total_premium);
            if (this.full_underwriting_fee > 0) {
                this.full_underwriting_fee = -Number(this.calculate_UW_fee());
            }
            this.admin_broker_fee = -Number(this.admin_broker_fee);
            this.full_total_amount_payable = - Number(this.full_total_amount_payable);
            this.full_broker_amount = -Number(this.full_broker_amount);
            //  this.full_underwriting_fee = - Number(this.full_underwriting_fee);
        }

    }


    /* Calcluate basic values */

    private main_class_net(basic_value, minimum_premium, age_loading, isVet, main_discount, vet_fee_excess_discount, full_co_pay_discount, veterinary_fee_co_pay): number {
        if (basic_value <= minimum_premium) {
            basic_value = Number(minimum_premium);
        }
        if (age_loading > 0) {
            basic_value = Number(basic_value) + Number(basic_value * age_loading / 100);
        }

        // basic_value = Number(basic_value) + Number(this.life_saving_surgery);
        if (isVet) {

            basic_value = Number(basic_value) - (Number(basic_value) * Number(vet_fee_excess_discount) / 100);

            basic_value = Number(basic_value) - (Number(basic_value) * Number(full_co_pay_discount) / 100);
            basic_value = Number(basic_value) - (Number(basic_value) * Number(veterinary_fee_co_pay) / 100);
        }

        if (main_discount > 0) {
            basic_value = Number(basic_value) - (Number(basic_value) * Number(main_discount / 100));

        }
        return parseFloat(Number(basic_value).toFixed(2));
    }

    private gross_amount(basic_net_value: number, total_commission: number, reduced_broker_commission: number): number {

        basic_net_value = Number(basic_net_value) / (100 - Number(total_commission)) * (100 - Number(reduced_broker_commission));
        return parseFloat(Number(basic_net_value).toFixed(2));
    }

    private calculate_percentage_amount(value: number, percentage: number): number {

        value = (Number(value) * Number(percentage)) / 100;
        return parseFloat(Number(value).toFixed(2));
    }

    private calculate_UW_fee(): number {
        return parseFloat(Number(this.full_underwriting_fee).toFixed(2));

    }
    private calculate_percentage_discount(value: number, percentage: number): number {
        if (Number(percentage) > 0) {
            value = Number(value) - (Number(value) * Number(percentage) / 100);
            return parseFloat(Number(value).toFixed(2));
        } else {
            return 0;
        }
    }


}

