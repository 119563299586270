import { UserInfo } from './userinfo.model';
import { Address } from '../address/address.model';
import { UserGroupLinking } from './user.group.linking.model';

export class User {
    id: string= "";
    username: string= "";
    password:string= "";
    company_id:string= "";
    password_token:string= "";
    status:number= 0;
    statusname:string= "";
    userinfo:UserInfo= new UserInfo();
    address:Address = new Address();
    group_user_linking:UserGroupLinking = new  UserGroupLinking();
  }

 



  