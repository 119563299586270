import { Component, OnInit, Input } from '@angular/core';
import { Amline } from 'src/app/model/quote/amline/amline.model';

@Component({
  selector: 'app-am-fleet-experiences-view',
  templateUrl: './am-fleet-experiences-view.component.html',
  styleUrls: ['./am-fleet-experiences-view.component.css']
})
export class AmFleetExperiencesViewComponent implements OnInit {
  quote;
  quoteApplicationData = new Amline();
  CoverTo;
  @Input() set quoteDetails(value: any) {
    if (value) {
      this.quote = value;

      value.application.fleet_experiences.forEach((res) => {
        let maxDate = new Date(res.start_date).setFullYear(new Date(res.start_date).getFullYear() + 1);
        res.end_date = new Date(new Date(maxDate).getTime() - (24 * 60 * 60 * 1000));
      })

      this.quoteApplicationData = value.application;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
