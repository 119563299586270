    <div *ngFor="let statementOfFact of statementOfFacts; let i = index;">
        <div
            *ngIf="(statementOfFact.product_type == 2 && quote.alpaca_theft_cover == 1) || (statementOfFact.product_type == 3 && quote.alpaca_public_liability_cover == 1)">
            <!-- Alpaca/LLAMA -->
            <h5 class="mt-4" *ngIf="statementOfFact.quesTitles">{{ statementOfFact.quesTitles }}</h5>
            <div [hidden]="quote.about_alpaca.does_vet_treatments_covered == 2 && statementOfFact.is_special_vet == 1 && statementOfFact.product_type == 2">
                <div class="form-group">
                    <div class="quote_radio">
                        <label class="f13 p-3">
                            {{ statementOfFact.description }}
                        </label>
                    </div>
                </div>
                <div class="form-group row"
                    *ngIf="statementOfFact.type == 1 && quote.statement_of_facts.length > 0">
                    <div class="col-md-3">
                        <mat-radio-group aria-label="Select an option"
                            name="statementOfFact_{{quote.statement_of_facts[i].question_id}}"
                            [(ngModel)]="quote.statement_of_facts[i].response" disabled>
                            <div class="quote_radio row">
                                <div class="col-md-6">
                                    <mat-radio-button style="background-color: #ccc;" [value]="1">Yes</mat-radio-button>
                                </div>
                                <div class="col-md-6">
                                    <mat-radio-button style="background-color: #ccc;" [value]="2">No</mat-radio-button>
                                </div>
                            </div>
                        </mat-radio-group>
                       
                    </div>
                </div>
                <div *ngIf="statementOfFact.is_exclusion == 1 && quote.statement_of_facts.length > 0 && quote.statement_of_facts[i].response == 2"
                    class="alert alert-secondary f13">
                    {{ statementOfFact.exclusion_text }}
                </div>
                <div class="form-group"
                    *ngIf="statementOfFact.is_exclusion == 0 && statementOfFact.is_decline == 0 && quote.statement_of_facts.length > 0 && quote.statement_of_facts[i].response == 2">
                    <textarea name="response_text_{{quote.statement_of_facts[i].question_id}}"
                        class="form-control"
                        placeholder="Please enter details here, your quote will be refered to one of our underwriters"
                        [(ngModel)]="quote.statement_of_facts[i].response_text" readonly>
                </textarea>
                </div>
                <div *ngIf="statementOfFact.is_decline == 1 && quote.statement_of_facts.length > 0 && quote.statement_of_facts[i].response == 2"
                    class="alert alert-secondary f13">
                    {{ statementOfFact.exclusion_text }}
                </div>
            </div>
        </div>
    </div>