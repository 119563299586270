import { Component, OnInit, Output, EventEmitter, Input, forwardRef, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder, Validators, FormGroup,
  ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { ActivatedRoute } from "@angular/router";
import { PolicyService } from '../../../policy/policy.service';
import { environment } from '../../../../environments/environment';
import { LivestockService } from '../../../quote/livestock/livestock.service';
import { QuoteService } from '../../../quote/quote.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-livestock-common-sections',
  templateUrl: './livestock-common-sections.component.html',
  styleUrls: ['../../quote.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LivestockCommonSectionsComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LivestockCommonSectionsComponent),
      multi: true
    }
  ]
})
export class LivestockCommonSectionsComponent implements ControlValueAccessor, OnInit {
  @Output() commonSectionFormStatus = new EventEmitter();
  @Output() calDetails = new EventEmitter();
  @Output() changedSelectedTabIndex = new EventEmitter();
  @Input() submitted: boolean;
  @Input() selectedTabIndex;
  @Input() brokers;
  @Input() quoteModelDetails = new QuoteVersions();
  commonSection: FormGroup;

  subscriptions: Subscription[] = [];
  QuoteDetails = new QuoteVersions();
  isBroker: boolean = false;
  calculations = new CalculationsDetails();
  quote_type: number = 1;
  QuoteTypeVisibility: boolean = false;

  constructor(private fb: FormBuilder, private cdRef: ChangeDetectorRef, private sessionSt: LocalStorageService, private modalService: NgbModal,
    private route: ActivatedRoute, private policyService: PolicyService, private lvkService: LivestockService, private quoteService: QuoteService) {

  }

  ngOnInit(): void {
    this.commonSection = this.fb.group({
      policy_start_date: [(this.route.snapshot.params.quote_type == 7) ? new Date() : this.QuoteDetails.policy_start_date, Validators.required],
      policy_type: [this.QuoteDetails.livestock_meta.type_of_policy, Validators.required],
      company_id: [this.QuoteDetails.company_id, Validators.required],
      quote_type: [(this.quote_type == 0) ? 1 : this.quote_type],
      mta_reason_id: [this.QuoteDetails.mta_reason_id, Validators.required],
      mta_reason_other: [this.QuoteDetails.mta_reason_other],
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.commonSection.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    if (this.sessionSt.retrieve('company_role') == 'Broker' || this.sessionSt.retrieve('user_group') == 'Staff') {
      // this.minPolicyDate = new Date(); --- Enable Later
      this.minPolicyDate = new Date("2022,05,15");
    }

    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      this.isBroker = true;
      // let minDate = new Date().setDate(new Date().getDate() - 14);
      // this.minPolicyDate = new Date(minDate);
      let maxDate = new Date().setDate(new Date().getDate() + 30);
      this.maxPolicyDate = new Date(maxDate);

      this.commonSection.get('company_id').disable();

    } else {
      if (this.sessionSt.retrieve('company_role') == 'Staff') {
        this.commonSection.get('company_id').disable();
      } else {
        this.commonSection.get('company_id').enable();
      }
    }

    // GET POLICY MTA REASONS
    if (this.route.snapshot.params.quote_type) {
      this.getPolicyReasons();
    }

    if (this.sessionSt.retrieve('is_broker')) {
      this.getBrokerCommissionById(this.sessionSt.retrieve('userInfo').company_id);
    }
  }

  disableFields: boolean = false;
  minPolicyDate;
  maxPolicyDate;
  ngOnChanges(changes: SimpleChanges) {
    this.QuoteDetails = this.quoteModelDetails;
    if (this.commonSection && this.quoteModelDetails) {
      this.commonSection.patchValue({
        policy_start_date: (this.route.snapshot.params.quote_type == 7) ? new Date() : this.quoteModelDetails.policy_start_date,
        policy_type: Number(this.quoteModelDetails.livestock_meta.type_of_policy),
        company_id: this.quoteModelDetails.company_id,
        quote_type: (this.quoteModelDetails.type == 0) ? 1 : this.quoteModelDetails.type,
        mta_reason_id: this.quoteModelDetails.mta_reason_id,
        mta_reason_other: this.quoteModelDetails.mta_reason_other,
      });
    }
    if (this.quoteDetailsFormControl && this.quoteDetailsFormControl.policy_type.value == 0) {
      this.commonSection.get('policy_type').setValue(null);
    }

    if (this.route.snapshot.params.quote_type) {
      this.quote_type = Number(this.route.snapshot.params.quote_type);
    } else {
      this.quote_type = Number(this.QuoteDetails.type);
    }
    if (this.commonSection) {
      switch (this.quote_type) {
        case 2:
          this.QuoteTypeVisibility = true;
          this.commonSection.get('mta_reason_id').disable();
          this.commonSection.get('mta_reason_other').disable();
          break;
        case 3:
          this.QuoteTypeVisibility = false;
          this.commonSection.get('mta_reason_id').enable();
          this.commonSection.get('mta_reason_other').enable();
          if (this.sessionSt.retrieve('is_admin')) {
            this.minPolicyDate = this.quoteModelDetails.original_policy_start_date;
          } else {
            if (this.sessionSt.retrieve('is_broker')) {
              if (this.quoteModelDetails.policy_start_date) {
                let policy_start_date = new Date(this.quoteModelDetails.policy_start_date);
                if (policy_start_date < new Date()) {
                  this.minPolicyDate = new Date();
                  this.quoteModelDetails.policy_start_date = new Date();
                }
              }
            }
            if (this.sessionSt.retrieve('is_staff')) {
              let minDate = new Date().setDate(new Date().getDate() - 7);
              this.minPolicyDate = new Date(minDate);
              if (this.quoteModelDetails.policy_start_date) {
                let policy_start_date = new Date(this.quoteModelDetails.policy_start_date);
                if (policy_start_date < this.minPolicyDate) {
                  this.quoteModelDetails.policy_start_date = new Date();
                }
              }
            }
            let maxDate = new Date().setDate(new Date().getDate() + 14);
            this.maxPolicyDate = new Date(maxDate);
          }
          break;
        case 4:
          this.QuoteTypeVisibility = false;
          this.commonSection.get('mta_reason_id').enable();
          this.commonSection.get('mta_reason_other').enable();


          if (this.sessionSt.retrieve('is_admin')) {
            this.maxPolicyDate = this.quoteModelDetails.policy_end_date;
            this.minPolicyDate = this.quoteModelDetails.original_policy_start_date;
          } else {
            let minDate = new Date().setDate(new Date().getDate() - 7);
            this.minPolicyDate = new Date(minDate);
            let maxDate = new Date().setDate(new Date().getDate() + 14);
            this.maxPolicyDate = new Date(maxDate);
            if (new Date(this.quoteModelDetails.policy_start_date) < new Date()) {
              let formattedDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
              this.quoteModelDetails.policy_start_date = new Date(formattedDate);
            }
          }
          break;
        case 5:
          this.QuoteTypeVisibility = false;
          this.commonSection.get('mta_reason_id').enable();
          this.commonSection.get('mta_reason_other').enable();
          this.quoteModelDetails.policy_start_date = new Date(this.quoteModelDetails.original_policy_start_date);
          this.minPolicyDate = this.quoteModelDetails.original_policy_start_date;
          let endDate = new Date(this.quoteModelDetails.policy_start_date);
          endDate.setDate(endDate.getDate() + 14);
          this.maxPolicyDate = endDate;
          break;
        case 6:
          this.QuoteTypeVisibility = false;
          this.minPolicyDate = this.quoteModelDetails.original_policy_start_date;
          this.commonSection.get('mta_reason_id').enable();
          this.commonSection.get('mta_reason_other').enable();
          break;
        case 7:
          this.quoteModelDetails.policy_start_date = new Date();
          break;
        default:
          this.commonSection.get('mta_reason_id').disable();
          this.commonSection.get('mta_reason_other').disable();
          this.QuoteTypeVisibility = true;
          break;
      }
    }

    if (this.selectedTabIndex == 6) {
      this.disableFields = true;
    }


  }

  onPolicyStartDateChange(event) {
    this.quoteModelDetails.policy_start_date = event.value;
    this.calDetails.emit(this.quoteModelDetails.calculations);
  }

  /*
* HELP TEXT POPUP
*/
  helptext = '';
  helpTextPopup(content, text) {
    const modalRef = this.modalService.open(content, { centered: true });
    this.helptext = text;
  }

  /*
  * GET POLICY REASONS
  */
  policyReasons;
  reasons = [];
  getPolicyReasons() {
    this.policyService.getPolicyMTAReasons(environment.LIVESTOCK_ID)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.reasons = [];
            response.result.types.forEach((res) => {
              //if (res.product_id == environment.LIVESTOCK_ID) {
              this.reasons.push(res);
              // }
            })
            this.policyReasons = this.reasons;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
  * On Quote Type Change
  */
  onQuoteTypeChange(event) {
    this.quoteModelDetails.type = event.value;
    this.quote_type = event.value;

  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {
    // this.quoteDetailsForm.statusChanges.subscribe(
    //   status =>this.commonSectionFormStatus.emit(status)
    //   );
  }

  // get value(): Quote {
  //   return this.quoteDetailsForm.value;
  // }

  // set value(value: Quote) {
  //   this.quoteDetailsForm.setValue(value);
  //   this.onChange(value);
  //   this.onTouched();
  // }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }



  writeValue(value) {
    // if (value) {
    //   this.value = value;
    // }

    // if (value === null) {
    //   this.quoteDetailsForm.reset();
    // }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.commonSection.valid ? null : { commonSections: { valid: false } };
  }



  get quoteDetailsFormControl() {
    if (this.commonSection) {
      return this.commonSection.controls;
    }
  }

  /*
  * On Change policy type set model value
  */
  policyTypeChange(event) {
    if (!this.route.snapshot.params.id) {
      if ((event.value >= 3 && this.QuoteDetails.livestock_meta.type_of_policy <= 2) || (event.value <= 2 && this.QuoteDetails.livestock_meta.type_of_policy >= 3)) {
        this.changedSelectedTabIndex.emit(0);
        this.QuoteDetails.livestock_meta.animal_count = 0;
        this.QuoteDetails.livestock_meta.livestock_type_id = null;
        this.QuoteDetails.livestock_meta.about_proposer_first_name = null;
        this.QuoteDetails.livestock_meta.about_proposer_last_name = null;
        this.QuoteDetails.livestock_meta.about_proposer_email = null;
        this.QuoteDetails.livestock_meta.breed_society_inspection = null;
        this.QuoteDetails.livestock_meta.is_nba_breeding_warranty = null;
        this.QuoteDetails.livestock_meta.breeding_society_name = null;
        this.QuoteDetails.livestock_meta.claim_count = null;
      }
    }
    this.QuoteDetails.livestock_meta.type_of_policy = event.value;
    if (event.value <= 2) {
      this.commonSection.controls['quote_type'].setValue(1);
      this.quoteModelDetails.type = 1;
      this.quote_type = 1;
    }
  }

  /*
  * On Change Broker Dropdown
  */
  onChangeBroker(event) {
    this.getBrokerCommissionById(event.value);
  }

  //Get Broker Commissions
  getBrokerCommissionById(id) {
    if (id) {
      this.quoteService.getLiveStockBrokerCommission(id, environment.LIVESTOCK_ID)
        .subscribe(
          (response: any) => {
            if (response.success) {
              let brokers_data = response.result;
              if (brokers_data != null) {
                this.calculations = this.QuoteDetails.calculations;
                this.calculations.broker_commission = Number(brokers_data.broker_commission);
                this.calculations.peliwica_pay_away_commission = Number(brokers_data.pay_away_commission);
                this.calculations.peliwica_retained_commission = Number(brokers_data.retained_commission);
                this.calculations.premium_loading_percentage = Number(brokers_data.premium_loading);
                this.calculations.full_underwriting_fee = Number(brokers_data.uw_fee);
                this.calculations.broker_admin_fee = Number(brokers_data.broker_admin_fee);

                if (this.QuoteDetails) {
                  this.QuoteDetails.calculations = this.calculations;
                  this.quoteModelDetails.calculations = this.calculations;
                  this.QuoteDetails.company_id = id;
                }
                this.calDetails.emit(this.calculations);
              }
            }
          });
    }
  }

}
