import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { QuoteService } from '../../quote.service';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { UserInfoService } from '../../../services/user-info.service';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-quote-agq',
  templateUrl: './quote-agq.component.html',
  styleUrls: ['../../quote.component.css']
})
export class QuoteAgqComponent implements OnInit {
  @Input() quote: QuoteVersions;
  agreedQuestions = [];
  envRoute:string;

  constructor(private quoteService: QuoteService, private modalService: NgbModal, private userService: UserInfoService, private router: Router) { }
  ngOnChanges(changes: SimpleChanges) {
    if((this.router.url.includes('livestock/quote-details')) || (this.router.url.includes('livestock/policy'))){
      this.envRoute =environment.LIVESTOCK_ID;
    }
    else if((this.router.url.includes('alpaca-admin/quote-details')) || (this.router.url.includes('alpaca-admin/policy')))
    {
      this.envRoute =environment.ALPACA_ID;
    }
    else{
      this.envRoute =environment.EQUINE_ID;
    }
    // Get All Questions
    this.quoteService.getAgreedQues(this.envRoute,this.quote.agreed_question_version)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.agreedQuestions = response.result.agreed_questions;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  ngOnInit(): void {
    
  }

}
