import { Component, OnInit, SimpleChanges, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuoteVersions } from '../../../../model/quote/quote_versions.model';
import { LivestockService } from '../../livestock.service';
import { QuoteService } from '../../../quote.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.css']
})
export class LvQuoteDetailsComponent implements OnInit {
  isAdmin = false;
  isInsurer = false;
  quoteData = new QuoteVersions();
  statementOfFacts = [];
  @Input() brokers;
  hasImportantNotes: boolean = false;
  totalImpNotes: number = 0;
  quoteDetailsForChildTemplates = new QuoteVersions();
  isActionBtn: boolean = false;
  staticData;

  constructor(private lvkService: LivestockService, private route: ActivatedRoute, private sessionSt: LocalStorageService,
    private router: Router, private modalService: NgbModal, private _snackBar: MatSnackBar, private quoteService: QuoteService) { }

  ngOnInit(): void {
    if (this.sessionSt.retrieve('is_admin')) {
      this.isAdmin = true;
    }
    if (this.sessionSt.retrieve('is_insurer')) {
      this.isInsurer = true;
    }
    //Get Quote Details
    this.quoteService.getQuoteDetailsByQuoteID(this.route.snapshot.params.quote_id).subscribe(
      (response: any) => {
        if (response.success) {

              this.quoteData = response.result;
              this.quoteData.sofIsDisabled = true;
              this.getQuoteNotes();

          //f6d1 : Referred, 80c0 : Quoted, dda8 : Declined
          if ((this.quoteData.insurance_status_id == "f6d14624-6351-4d93-b27b-70fb8a06ec5d")
              || (this.quoteData.insurance_status_id == "80c0db90-ba5d-4387-b09a-4c008a664932")
              || (this.quoteData.insurance_status_id != "dda8734c-d149-4336-b8cc-c2e205430752")) {
              this.isActionBtn = true;
            }

        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  displayEmailView: boolean = false;
  displayNewEmailView: boolean = false;
  newEmailView() {
    this.displayNewEmailView = true;
  }

  email_id: string = '';
  getEmailView(email_id) {
    this.email_id = email_id;
    this.displayEmailView = true;
  }
  backToEmailsList() {
    this.displayEmailView = false;
    this.displayNewEmailView = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getQuoteNotes();
  }

  getQuoteNotes() {
    if (this.quoteData.id) {
      this.quoteService.getNotes(this.quoteData.id)
        .subscribe(
          (response: any) => {
            let impNotes = response.result.filter(x => x.is_important == 1);
            this.totalImpNotes = impNotes.length;
            if (impNotes.length > 0) {
              this.hasImportantNotes = true;
            }
          });
    }
  }
  onhasImportantNotesChange(event) {
    this.hasImportantNotes = event;
    this.totalImpNotes = this.totalImpNotes + 1;
  }
  quoteDetailsUpdated(quotedata) {
    this.quoteData = quotedata;
  }

  /*
  * On Policy Start Date Change
  */
  onPolicyStartDateChange(event) {
    this.disablIfPastDate = false;
  }

  /*
  * Convert to Policy
  */
  disablIfPastDate: boolean = false;
  minPolicyDate;
  maxPolicyDate;
  convertToPolicy(converttopolicymodal) {
    if (this.sessionSt.retrieve('is_broker')) {
      let maxDate = new Date().setDate(new Date().getDate() + 30);
      this.maxPolicyDate = new Date(maxDate);
      this.minPolicyDate = new Date();
    } else {
      if(this.sessionSt.retrieve('user_group') == 'Staff') {
        this.minPolicyDate = new Date();
      }
    }
    // Disable convert to policy buttons if date is past date
    let policy_start_date = new Date(this.quoteData.policy_start_date);
    policy_start_date.setHours(0, 0, 0, 0);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (policy_start_date.getTime() < today.getTime() && this.sessionSt.retrieve('company_role') == 'Broker') {
      this.disablIfPastDate = true;
    }


    
    this.modalService.open(converttopolicymodal, { centered: true });
  }

  convertToPolicyPost() {
    // let quote_versions = this.quoteData.versions.filter(x => x.is_policy == 1);
    // let policy_ref_number = null;
    // if (quote_versions.length > 0) {
    //   policy_ref_number = quote_versions[0].policy_ref_number;
    // }
    let data: any = '';
    data = {
      quote_id: this.quoteData.id,
      policy_ref_number: this.quoteData.policy_ref_number,
      policy_start_date: formatDate(this.quoteData.policy_start_date, 'yyyy-MM-dd', 'en-US')
    };
    this.quoteService.convertToPolicy(data)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.router.navigate(['/livestock/policy', this.quoteData.id]);
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

   /*
  * Update Quote Status
  */
   updateQuoteStatus(status_id) {
    let data = {
      quote_id: this.quoteData.id,
      insurance_status_id: status_id
    };

    this.quoteService.updateQuoteStatus(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteData.insurance_status_id = response.result.insurance_status_id;
            window.location.reload();
          }
        });
  }

  /*
  * Update Quote Status
  */
  updateQuoteDeclineStatus(declineMsgBox) {
    this.confimMsg = 'Are you sure you want to decline the quote?';
    this.modalService.open(declineMsgBox, { centered: true });
  }
  DeclineQuote() {
    let data = {
      quote_id: this.quoteData.id,
      insurance_status_id: "dda8734c-d149-4336-b8cc-c2e205430752"
    };

    this.quoteService.updateAnyQuoteStatus(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
            window.location.reload();
          }
          else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

  // Quote Lapse
  confimMsg: string = '';
  QuoteLapseCfmMSg(msgBox) {
    this.confimMsg = 'Are you sure you want to lapse the quote?';
    this.modalService.open(msgBox, { centered: true });
  }

  lapseQuote() {
    let data: any = '';
    data = {
      quote_id: this.quoteData.id,
      type: 8
    };
    this.quoteService.lapseQuote(data)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.router.navigate(['/policy', response.result.id]);
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

}
