export class HorseDetailsTrailer {
            id: string = '';
            horse_detail_id: string = '';
            chasis_number: string = '';
            brand: string = '';
            sum_insured: number = null;
            manufactured_year: string = '';
            postcode: string = '';
            trailer_security_id: string = null;
            trailer_security_other_text: string = '';
}
