<form class="g-3 needs-validation" novalidate [formGroup]="frmFields" (ngSubmit)="onSubmit()">
    <mat-accordion formArrayName="fleet_experiences">
        <div class="form_sections" *ngFor="let fleet of FE.controls; let i = index">
            <mat-expansion-panel expanded formGroupName="{{i}}">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h4>
                            <u>
                                Year {{ i + 1 }}
                            </u>
                            <a (click)="removeYear(i)" title="Remove Horsebox" class="btn btn-danger btn-sm p-1 ml-3"
                                *ngIf="i > 0"><i class="mdi mdi-delete"></i></a>
                        </h4>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group w-100">
                            <input type="text" required name="start_date" formControlName="start_date"
                                placeholder="Start Date" class="form-control form-control-sm datepicker" matInput
                                (focus)="startDateDatepicker.open()" [matDatepicker]="startDateDatepicker"
                                (dateChange)="onStartDateChange($event, i)"
                                [ngClass]="{ 'is-invalid': (fleet.get('start_date').touched || submitted) && fleet.get('start_date').errors?.required }">
                            <mat-datepicker-toggle [for]="startDateDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDateDatepicker></mat-datepicker>
                            <span class="ml-1"
                                (click)="helpTextPopup(content,'Please enter most recent policy period in Year 1, with the next years in Year 2 and Year 3.')"><i
                                    class="mdi mdi-comment-question-outline"></i></span>
                            <div class="invalid-feedback"
                                *ngIf="(fleet.get('start_date').touched || submitted) && fleet.get('start_date').errors?.required">
                                Start date is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group w-100">
                            <input type="text" name="end_date" formControlName="end_date" placeholder="End Date"
                                class="form-control form-control-sm datepicker" matInput
                                (focus)="endDateDatepicker.open()" [matDatepicker]="endDateDatepicker">
                            <mat-datepicker-toggle [for]="endDateDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDateDatepicker></mat-datepicker>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="number" name="years" placeholder="Vehicle Years" appNumbers
                                class="form-control form-control-sm" formControlName="years" required
                                [ngClass]="{ 'is-invalid': (fleet.get('years').touched || submitted) && fleet.get('years').errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(fleet.get('years').touched || submitted) && fleet.get('years').errors?.required">
                                Please check the number of vehicle years entered.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="number" name="claims" placeholder="Number of Reported Claims" appNumbers
                                class="form-control form-control-sm" formControlName="claims" required (change)="onClaimValueChange($event, i)"
                                [ngClass]="{ 'is-invalid': (fleet.get('claims').touched || submitted) && fleet.get('claims').errors?.required }">
                            <div class="invalid-feedback"
                                *ngIf="(fleet.get('claims').touched || submitted) && fleet.get('claims').errors?.required">
                                Reported Claims is required.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="green_border_panel text-white mb-3" *ngIf="fleet.get('claims').value > 0">
                    <div class="row">
                        <div class="col-md-2">
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">Own Damage</div>
                                <div class="col-md-3">Fire & Theft</div>
                                <div class="col-md-3">Third Party</div>
                                <div class="col-md-3">Total</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" formGroupName="paid">
                        <div class="col-md-2">Paid</div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" appDecimalNumbers
                                            required name="own_damage" formControlName="own_damage" min="0" step="0.5"
                                            [ngClass]="{ 'is-invalid': (FE.controls[i].get('paid.own_damage').touched || submitted) && FE.controls[i].get('paid.own_damage').errors?.required }">
                                        <div class="invalid-feedback"
                                            *ngIf="(FE.controls[i].get('paid.own_damage').touched || submitted) && FE.controls[i].get('paid.own_damage').errors?.required">
                                            Value is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" appDecimalNumbers
                                            required name="fire_theft" formControlName="fire_theft" min="0" step="0.5"
                                            [ngClass]="{ 'is-invalid': (FE.controls[i].get('paid.fire_theft').touched || submitted) && FE.controls[i].get('paid.fire_theft').errors?.required }">
                                        <div class="invalid-feedback"
                                            *ngIf="(FE.controls[i].get('paid.fire_theft').touched || submitted) && FE.controls[i].get('paid.fire_theft').errors?.required">
                                            Value is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" appDecimalNumbers
                                            required name="third_party" formControlName="third_party" min="0" step="0.5"
                                            [ngClass]="{ 'is-invalid': (FE.controls[i].get('paid.third_party').touched || submitted) && FE.controls[i].get('paid.third_party').errors?.required }">
                                        <div class="invalid-feedback"
                                            *ngIf="(FE.controls[i].get('paid.third_party').touched || submitted) && FE.controls[i].get('paid.third_party').errors?.required">
                                            Value is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" readonly name="total"
                                            [value]="FE.controls[i].get('paid.own_damage').value + FE.controls[i].get('paid.fire_theft').value + FE.controls[i].get('paid.third_party').value | number:'1.2-2'">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" formGroupName="outstanding">
                        <div class="col-md-2">Outstanding</div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" appDecimalNumbers
                                            required name="own_damage" formControlName="own_damage" min="0" step="0.5"
                                            [ngClass]="{ 'is-invalid': (FE.controls[i].get('outstanding.own_damage').touched || submitted) && FE.controls[i].get('outstanding.own_damage').errors?.required }">
                                        <div class="invalid-feedback"
                                            *ngIf="(FE.controls[i].get('outstanding.own_damage').touched || submitted) && FE.controls[i].get('outstanding.own_damage').errors?.required">
                                            Value is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" appDecimalNumbers
                                            required name="fire_theft" formControlName="fire_theft" min="0" step="0.5"
                                            [ngClass]="{ 'is-invalid': (FE.controls[i].get('outstanding.fire_theft').touched || submitted) && FE.controls[i].get('outstanding.fire_theft').errors?.required }">
                                        <div class="invalid-feedback"
                                            *ngIf="(FE.controls[i].get('outstanding.fire_theft').touched || submitted) && FE.controls[i].get('outstanding.fire_theft').errors?.required">
                                            Value is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" appDecimalNumbers
                                            required name="third_party" formControlName="third_party" min="0" step="0.5"
                                            [ngClass]="{ 'is-invalid': (FE.controls[i].get('outstanding.third_party').touched || submitted) && FE.controls[i].get('outstanding.third_party').errors?.required }">
                                        <div class="invalid-feedback"
                                            *ngIf="(FE.controls[i].get('outstanding.third_party').touched || submitted) && FE.controls[i].get('outstanding.third_party').errors?.required">
                                            Value is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" readonly name="total"
                                            [value]="FE.controls[i].get('outstanding.own_damage').value + FE.controls[i].get('outstanding.fire_theft').value + FE.controls[i].get('outstanding.third_party').value | number:'1.2-2'">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-2">Total</div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" readonly name="total_own_damage" 
                                            [value]="FE.controls[i].get('paid.own_damage').value + FE.controls[i].get('outstanding.own_damage').value | number:'1.2-2'">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" readonly name="total_fire_theft" 
                                            [value]="FE.controls[i].get('paid.fire_theft').value + FE.controls[i].get('outstanding.fire_theft').value | number:'1.2-2'">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" readonly name="total_fire_theft" 
                                            [value]="FE.controls[i].get('paid.third_party').value + FE.controls[i].get('outstanding.third_party').value | number:'1.2-2'">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-group mt-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text dark-green-bg text-white">&pound;</span>
                                        </div>
                                        <input type="text" class="form-control form-control-sm" readonly name="total_fire_theft" 
                                            [value]="(FE.controls[i].get('paid.own_damage').value + FE.controls[i].get('paid.fire_theft').value + FE.controls[i].get('paid.third_party').value) + (FE.controls[i].get('outstanding.own_damage').value + FE.controls[i].get('outstanding.fire_theft').value + FE.controls[i].get('outstanding.third_party').value) | number:'1.2-2'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="form-group">
                    <textarea name="details"
                        placeholder="Please provide details of any individual large or unusual loss in year {{ i+1 }}"
                        class="form-control" formControlName="details"></textarea>
                </div>
            </mat-expansion-panel>
        </div>

    </mat-accordion>
    <div class="row">
        <div class="col-md-6"><a (click)="addYear()" class="btn btn-gray btn-sm">Add a Year</a></div>
        <div class="col-md-6  text-right"><button class="btn btn-success btn-sm">Proceed</button></div>
    </div>
    <ng-template #content let-modal>
        <div class="modal-body">
            <div class="helptext" [innerHTML]="helptext"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
        </div>
    </ng-template>
</form>

<!-- <p>
    Form is {{frmFields.valid ? 'Valid' : 'Invalid'}}
</p>
<pre>
            {{frmFields.value | json}}
            </pre> -->