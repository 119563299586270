<form [formGroup]="quoteDetailsForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-md-8">
            <app-livestock-common-sections formControlName="commonSections" [brokers]="brokers" [submitted]="submitted"
                [selectedTabIndex]="selectedTabIndex" [quoteModelDetails]="quoteModelDetails"
                (calDetails)="calDetails($event)" (changedSelectedTabIndex)="changedSelectedTabIndex($event)">
            </app-livestock-common-sections>
            <app-proposer formControlName="proposer" [submitted]="submitted" [selectedTabIndex]='selectedTabIndex'
                [quoteModelDetails]="quoteModelDetails"></app-proposer>
            <app-animals-insurance formControlName="animals" [submitted]="submitted" (calDetails)="calDetails($event)"
                [staticData]="staticData" [selectedTabIndex]='selectedTabIndex' [quoteModelDetails]="quoteModelDetails">
            </app-animals-insurance>
            <!-- <div class="form_sections">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            About the Statements of Fact
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-livestock-sof [selectedTabIndex]="selectedTabIndex"></app-livestock-sof>
                </mat-expansion-panel>
            </div> -->
            <div class="form_sections text-center">
                <h4 class="">Your quotation Reference No. is: <a class="text-white"
                        [routerLink]="['/livestock/quote-details',quoteModelDetails.id]">{{quoteModelDetails.quote_ref_number}}</a>
                </h4>
                <p style="font-size: 15px;">THIS IS A QUOTATION ONLY; THIS WILL NOT BE CONVERTED TO HOLDING COVER UNTIL
                    PAYMENT HAS BEEN
                    RECEIVED.
                    <br><br>
                    Your quotation schedule and quotation documents have been emailed to you; these include;<br><br>
                    Quotation schedule<br>
                    Policy wording<br>
                    IPID<br>
                    Broker Terms of Business<br>
                    Broker Fair Processing Notice<br><br>
                    If you want to proceed to taking out a policy then please click on the payment button below

                </p>
                <div class="mt-4">
                    <h5 class="text-center">POLICY PAYMENT TERMS</h5>
                    <div class="mt-3 row">
                        <div class="col-md-10 offset-1 text-left">
                            <ul class="f13" style="margin-left: 40px;">
                                <li>Payment is due within 14 days of your policy effective date, if payment is not
                                    received
                                    within this period your policy may be cancelled</li>
                                <li>If you would like to pay by credit/ debit card please call us on {{
                                    bankDetails.phone }}</li>
                                <li>If you would like to pay by monthly instalments (provided by Premium Credit Limited)
                                    then
                                    please call us on {{ bankDetails.phone }}</li>
                                <li>
                                    If you would like to make a direct bank transfer please use the details below
                                    quoting your
                                    policy number;
                                    <ul class="f13">
                                        <li>Bank Account Sort Code: {{ bankDetails.sort_code }}</li>
                                        <li>Bank Account no: {{ bankDetails.account_number }}</li>
                                        <li>Bank Account Name: {{ bankDetails.account_name }}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row float-left">
                <div class="col-md-2">
                    <a class="btn btn-default" (click)="backBtn()">Back</a>
                </div>
                <div class="col-md-5">
                    <a class="btn btn-default btn-gray"
                        [routerLink]="['/livestock/quote-details',quoteModelDetails.id]">Save quote details for
                        later</a>
                </div>
                <div class="col-md-5">
                    <button class="btn btn-default btn-success float-right pl-2 pr-2"
                        [disabled]="quoteModelDetails.isButton">Proceed to payment confirmation</button>
                </div>

            </div>

        </div>
        <div class="col-md-4">
            <app-livestock-calculations [quoteModelDetails]="quoteModelDetails"></app-livestock-calculations>
        </div>
    </div>

</form>
<!-- <pre>
    {{quoteDetailsForm.value | json}}
    </pre> -->