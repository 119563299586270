<div class="row">
    <div class="col-md-7">
        <div class="row mb-3">
            <div class="col-md-6"><label>Latest Version:</label></div>
            <div class="col-md-6">
                <input type="text" name="quote_version" [value]="quote.sequence_number"
                    class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>Quote Ref No.:</label></div>
            <div class="col-md-6">
                <a class="text-white" (click)="quoteDetails()"><input type="text" name="quote_ref"
                        [value]="quote.quote_ref_number" class="form-control form-control-sm" readonly
                        style="cursor: pointer;"></a>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>Start Date:</label></div>
            <div class="col-md-6">
                <input type="text" name="policy_start_date" [value]="quote.policy_start_date | date:'dd-MM-yyyy'"
                    placeholder="Policy Start Date" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6"><label>Transaction Type:</label></div>
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <mat-select [(ngModel)]="quote.type" disabled class="form-control form-control-sm"
                        name="quote_type">
                        <mat-option [value]="1">New Business</mat-option>
                        <mat-option [value]="2">Renewal</mat-option>
                        <mat-option [value]="3">MTA</mat-option>
                        <mat-option [value]="4">Cancelled</mat-option>
                        <mat-option [value]="5">Lapsed</mat-option>
                        <mat-option [value]="6">NTU</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-3" *ngIf="quote.type == 2">
            <div class="col-md-6"><label>Retroactive Date:</label></div>
            <div class="col-md-6">
                <input type="text" name="retroactive_date" [value]="quote.retroactive_date | date:'dd-MM-yyyy'"
                    placeholder="Retroactive Date" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <!-- <div class="row mb-2">
            <div class="col-md-6"><label>Status:</label></div>
            <div class="col-md-6">
                <input type="text" name="status" [value]="(quote.Status == 1)?'Active':'Inactive'" readonly
                        class="form-control form-control-sm">
                <mat-form-field class="w-100">
                    <mat-select class="form-control form-control-sm" name="status" disabled
                        [(ngModel)]="quote.insurance_status_id">
                        <mat-option [value]="status.id" *ngFor="let status of policyStatuses">
                            {{status.display_name}}</mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>
        </div> -->
        <div class="row mb-2">
            <div class="col-md-6"><label>Insurer:</label></div>
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <mat-select [(ngModel)]="quote.insurer" disabled class="form-control form-control-sm"
                        name="insurer">
                        <mat-option *ngFor="let insurer of insurers" [value]="insurer.id">
                            {{insurer.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<app-quote-versions [quote]="quote" [is_policy]="1" (hasImportantNotes)="onhasImportantNotesChange($event)"
(quoteVersionsUpdated)="quoteVersionsUpdated($event)"></app-quote-versions>