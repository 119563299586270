<form [formGroup]="quoteDetailsForm" (ngSubmit)="onSubmit()">
    <!-- <app-livestock-common-sections formControlName="commonSections"
        (commonSectionFormStatus)="commonSectionFormStatus($event)" [submitted]="submitted">
    </app-livestock-common-sections> -->
    <app-livestock-common-sections formControlName="commonSections" [submitted]="submitted" [brokers]="brokers"
        [selectedTabIndex]='selectedTabIndex' [quoteModelDetails]='quoteModelDetails' (calDetails)="calDetails($event)">
    </app-livestock-common-sections>
    <button class="btn btn-default btn-success">Proceed</button>

</form>
<!-- <p>
    Form is {{quoteDetailsForm.valid ? 'Valid' : 'Invalid'}}
</p>
<pre>
    {{quoteDetailsForm.value | json}}
    </pre> -->