import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../../../model/quote/quote_versions.model';
import { QuoteEndorsement } from '../../../../model/quote/quote_endorsement.model';
import { QuoteService } from '../../../quote.service';
import { CalculationsDetails } from '../../../calculations/calculations.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';
import { HorseSubClass } from '../../../../model/quote/horse_sub_class.model';
import { UserInfoService } from '../../../../services/user-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-allianz-quote-full-details',
  templateUrl: './quote-full-details.component.html',
  styleUrls: ['../../allianz.component.css'],
})
export class AllianzQuoteFullDetailsComponent implements OnInit {
  @Input() quote: QuoteVersions;
  endorsement = new QuoteEndorsement();
  horseAge;
  calculationDetails = new CalculationsDetails();
  selected_full_vet_fee_amount = 0;
  today = new Date();

  classOfUse;
  policyCover = [];
  bloodStocks;
  publicLiability;
  accidentCover;
  policyDiscount = [];
  vetFeesCover = [];
  vetFeesCoverBloodStock = [];
  vetFeeCoverSpecial = [];
  vetFeeExcess = [];
  saddlery_tack_items;
  BloodStockDetails = false;
  editType = 'fullRow';
  horseSubClasses = [];
  quoteDetails;
  horseSubClass: string = '';
  horseHeight;
  horseBreed;
  horseColor;
  staticDataValues;
  titleList: [];
  titleName: string;
  DisposalEuthansia;
  ElectiveSurgery;
  leisure_sport_type: number = 0;
  allPolicyCovers;
  LiveryHospitalisation;
  // STATIC DATA
  @Input()
  set staticData(value: any) {
    if (value) {
      this.staticDataValues = value;
      //Class Of Use
      this.classOfUse = value.class_of_use;
      if (this.quote) {
        let class_of_use = value.class_of_use.filter(x => x.id == this.quote.horse_class_id);
        if (class_of_use.length > 0) {
          if (this.horseSubClass == '') {
            setInterval(() => {
              let subclass = class_of_use[0].sub_classes.filter(x => x.id == this.quote.horse_class_sub_id);
              if (subclass.length > 0) {
                this.horseSubClass = subclass[0].name;
              }
            }, 1000);
          }

          if (class_of_use[0].class == 5) {
            this.BloodStockDetails = true;
          }
        }
      }

      // BloodStocks
      this.bloodStocks = value.blood_stock;

      let DisposalEuthansia_ID = '';
      let ElectiveSurgery_ID = '';
      let LiveryHospitalisation_ID = '';
      let Transportation_ID = '';
      if (this.quote.rating_version == 1) {
        if (this.quote.horse_details.value < 5000) {
          this.leisure_sport_type = 1;
          DisposalEuthansia_ID = '805c13db-2395-4020-9f30-57ccc9402105';
          ElectiveSurgery_ID = '804c13db-2395-4020-9f30-57ccc9402104';
          LiveryHospitalisation_ID = '813b79ee-3c13-4fb4-96fc-3f79e8ce0003';
          Transportation_ID = '816b79ee-3c13-4fb4-96fc-3f79e8ce0004';
        } else {
          this.leisure_sport_type = 2;
          DisposalEuthansia_ID = '855c13db-2395-4020-9f30-57ccc9402105';
          ElectiveSurgery_ID = '854c13db-2395-4020-9f30-57ccc9402104';
          LiveryHospitalisation_ID = '866b79ee-3c13-4fb4-96fc-3f79e8ce0114';
          Transportation_ID = '867b79ee-3c13-4fb4-96fc-3f79e8ce0115';
        }
      }
      else if (this.quote.rating_version == 2) {
        // ANKESH
        if (this.quote.horse_details.value < 5000) {
          this.leisure_sport_type = 1;
          DisposalEuthansia_ID = '105c13db-2395-4020-9f30-57ccc9402105';
          ElectiveSurgery_ID = '104c13db-2395-4020-9f30-57ccc9402104';
          LiveryHospitalisation_ID = '113b79ee-3c13-4fb4-96fc-3f79e8ce0003';
          Transportation_ID = '116b79ee-3c13-4fb4-96fc-3f79e8ce0004';
          this.quote.is_aeio = (this.quote.is_aeio == 3) ? 2 : this.quote.is_aeio;
        } else {
          this.leisure_sport_type = 2;
          DisposalEuthansia_ID = '155c13db-2395-4020-9f30-57ccc9402105';
          ElectiveSurgery_ID = '154c13db-2395-4020-9f30-57ccc9402104';
          LiveryHospitalisation_ID = '166b79ee-3c13-4fb4-96fc-3f79e8ce0114';
          Transportation_ID = '167b79ee-3c13-4fb4-96fc-3f79e8ce0115';
        }
      } else if (this.quote.rating_version == 3) {
        if (this.quote.horse_details.value < 5000) {
          this.leisure_sport_type = 1;
          DisposalEuthansia_ID = '105c13db-2395-4020-0003-57ccc9402105';
          ElectiveSurgery_ID = '104c13db-2395-4020-0003-57ccc9402104';
          LiveryHospitalisation_ID = '113b79ee-3c13-4fb4-0003-3f79e8ce0003';
          Transportation_ID = '116b79ee-3c13-4fb4-0003-3f79e8ce0004';
        } else {
          this.leisure_sport_type = 2;
          DisposalEuthansia_ID = '155c13db-2395-4020-0003-57ccc9402105';
          ElectiveSurgery_ID = '154c13db-2395-4020-0003-57ccc9402104';
          LiveryHospitalisation_ID = '166b79ee-3c13-4fb4-0003-3f79e8ce0114';
          Transportation_ID = '167b79ee-3c13-4fb4-0003-3f79e8ce0115';
        }
      } else {
        if (this.quote.horse_details.value < 5000) {
          this.leisure_sport_type = 1;
          DisposalEuthansia_ID = '105c13db-2395-4020-0004-57ccc9402105';
          ElectiveSurgery_ID = '104c13db-2395-4020-0004-57ccc9402104';
          LiveryHospitalisation_ID = '113b79ee-3c13-4fb4-0004-3f79e8ce0003';
          Transportation_ID = '116b79ee-3c13-4fb4-0004-3f79e8ce0004';
        } else {
          this.leisure_sport_type = 2;
          DisposalEuthansia_ID = '155c13db-2395-4020-0004-57ccc9402105';
          ElectiveSurgery_ID = '154c13db-2395-4020-0004-57ccc9402104';
          LiveryHospitalisation_ID = '166b79ee-3c13-4fb4-0004-3f79e8ce0114';
          Transportation_ID = '167b79ee-3c13-4fb4-0004-3f79e8ce0115';
        }
      }
      // Policy Cover
      this.allPolicyCovers = value.policy_cover_details;
      this.policyCover = value.policy_cover_details;
      // Policy Cover
      let policyCoverDetails = this.policyCover.filter(x => x.id == this.quote.policy_cover_id);
      if (policyCoverDetails.length > 0) {
        this.policyCoverDetails = policyCoverDetails[0].name;
      }
      
      value.policy_cover_details.forEach((res, index) => {
        // if (res.is_vet_fee_cat == 0 && res.is_addon_cat == 0) {
        //    this.policyCover.push(res);
        // }

        // Vet Fee Cover
        if (res.is_vet_fee_cat == 1) {          
          res.section_indeminity_limit.forEach((res1) => {
            if (res1.is_special_vet == 0) {
              if (res1.is_blood_stock == 0) {
                this.vetFeesCover.push(res1);
              } else {
                this.vetFeesCoverBloodStock.push(res1);
              }
            } else {
              this.vetFeeCoverSpecial.push(res1);
            }

            let SectionIndemnityLimitAddons = res.section_indeminity_limit.filter(x => x.id == LiveryHospitalisation_ID);
            if (SectionIndemnityLimitAddons.length > 0) {
              this.LiveryHospitalisation = SectionIndemnityLimitAddons[0];
            }
          });
        }

        // Public Liability
        if (res.is_addon_cat == 1) {
          if (res.cover_index == 4) {
            this.publicLiability = res.section_indeminity_limit;
          }
          if (res.cover_index == 5) {
            this.accidentCover = res.section_indeminity_limit;
          }
        }

      });

      // Filter Policy Cover - Mortality Addon 

      let PolicyCoverFiltered = this.policyCover.filter(x => x.type == this.leisure_sport_type);
      PolicyCoverFiltered.forEach((res, index) => {
        if (index == 0) {
          //this.quote.policy_cover_id = res.id;
         // this.quote.full_policy_cover = res.name;
          let DisposalEuthansia = res.section_indeminity_limit.filter(x => x.id == DisposalEuthansia_ID);
          if (DisposalEuthansia.length > 0) {
            this.DisposalEuthansia = DisposalEuthansia[0];
          }
          let ElectiveSurgery = res.section_indeminity_limit.filter(x => x.id == ElectiveSurgery_ID);
          if (ElectiveSurgery.length > 0) {
            this.ElectiveSurgery = ElectiveSurgery[0];
          }


        }

      });



      // Vet Fee Excess
      value.company_excess.forEach((res, index) => {
        this.vetFeeExcess.push(res);
      });

    }
  }

  constructor(private quoteService: QuoteService, private modalService: NgbModal, private route: ActivatedRoute,
    private userService: UserInfoService, private sessionSt: LocalStorageService, private _snackBar: MatSnackBar) {

  }

  saddleryTotalSumInsured: number = 0;
  trailerTotalSumInsured: number = 0;
  HorseGenderName: string = '';
  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quote;

    // GET ENDORSEMENT
    this.rowData = this.quote.endorsements;

    if (this.quote.horse_details.value) {
      this.quote.horse_details.value = Math.round(this.quote.horse_details.value);
    }

    if (this.quote.horse_sub_class == null) {
      this.quote.horse_sub_class = new HorseSubClass();
    }

    // Calculate Sum of Saddlery & Tack Item's Sum Insured
    this.saddleryTotalSumInsured = 0;
    this.quoteDetails.horse_details.saddlery_items.forEach((res, index) => {
      this.saddleryTotalSumInsured += Number(res.sum_insured);
    });

    // Calculate Sum of Trailer Item's Sum Insured
    this.trailerTotalSumInsured = 0;
    this.quoteDetails.horse_details.trailor_items.forEach((res, index) => {
      res.sum_insured = (res.sum_insured == 0) ? null : res.sum_insured;
      this.trailerTotalSumInsured += Number(res.sum_insured);
    });

    // Show/Hide Bloodstock
    if (this.classOfUse) {
      let class_of_use = this.classOfUse.filter(x => x.id == this.quote.horse_class_id);
      if (class_of_use.length > 0) {
        if (class_of_use[0].class == 5) {
          this.BloodStockDetails = true;
        }
      }
    }



    // Set Cover Details Values
    this.setCoverDetails();
    // Get Horse Gender
    if (this.quote.horse_details) {
      let gender = this.horseGender.filter(x => x.value == this.quote.horse_details.gender);
      if (gender.length > 0) {
        this.HorseGenderName = gender[0].title;
      }
    }

    // Title
    if (this.titleList && this.quote.policy_holder) {
      this.titleList.forEach((res: any, index) => {
        if (res.id == this.quote.policy_holder.title) {
          this.titleName = res.title;
        }
      });
    }

    if (this.policyCover && this.quote && this.quote.policy_cover_id) {
      let policyCoverDetails = this.policyCover.filter(x => x.id == this.quote.policy_cover_id);
      if (policyCoverDetails.length > 0) {
        this.policyCoverDetails = policyCoverDetails[0].name;
      }
    }

  }

  isNotBroker: boolean = false;
  horseGender = [];
  ngOnInit(): void {
    if (this.sessionSt.retrieve('company_role') != 'Broker') {
      this.isNotBroker = true;
    }

    // Get Horse Details
    this.quoteService.getHorseDetails()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.horseHeight = response.result.height;
            this.horseBreed = response.result.breed;
            this.horseColor = response.result.color;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // Get All List
    this.quoteService.getTitleList()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.titleList = response.result;
            response.result.forEach((res: any, index) => {
              if (res.id == this.quote.policy_holder.title) {
                this.titleName = res.title;
              }
            });
          }
        });

    // Get All Gender List
    this.quoteService.getHorseGenderList()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.horseGender = response.result;
            if (this.quote && this.quote.horse_details) {
              let gender = response.result.filter(x => x.value == this.quote.horse_details.gender);
              if (gender.length > 0) {
                this.HorseGenderName = gender[0].title;
              }
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  previousSequenceNumber;
  ngDoCheck() {
    if (this.previousSequenceNumber != this.quote.sequence_number) {
      this.setCoverDetails();
    }
  }
  /*
  * Set Cover Detail Values
  */
  policyCoverDetails;
  class_name: string = '';
  setCoverDetails() {
    this.previousSequenceNumber = this.quote.sequence_number;
    this.quote.full_vet_fee_cover = '';
    this.quote.full_life_saving_surgery = '';
    this.quote.full_vet_fee_excess = '';
    this.quote.full_public_liability = '';
    this.quote.full_personal_accident = '';

    // Class Type
    if (this.classOfUse && this.classOfUse.length > 0) {
      let cn = this.classOfUse.filter(x => x.id == this.quote.horse_class_id);
      if (cn.length > 0) {
        this.class_name = cn[0].name;
      }
    }

    // Vet Fee Cover
    this.quote.full_vet_fee_cover = '';
    if (this.quote.vet_fee_sum_insured_id) {
      if (this.BloodStockDetails) {
        let covers = this.allPolicyCovers.filter(x => x.cover_index == 99);
        if (covers.length > 0) {
          let sid = covers[0].section_indeminity_limit;
          let vfc = sid.filter(x => x.is_blood_stock == 1);
          if (vfc.length > 0) {
            this.quote.full_vet_fee_cover = vfc[0].description;
          } else {
            this.quote.full_vet_fee_cover = '';
          }
        }
      } else {
        if (this.vetFeesCover) {
          let vetFeesCoverData = this.vetFeesCover.filter(x => x.id == this.quote.vet_fee_sum_insured_id);
          if (vetFeesCoverData.length > 0) {
            this.quote.full_vet_fee_cover = vetFeesCoverData[0].description;
          } else {
            this.quote.full_vet_fee_cover = '';
          }
        }
      }
    }

    // Life Saving Surgery
    if (this.vetFeeCoverSpecial) {
      this.quote.full_life_saving_surgery = '';
      if (this.quote.life_saving_annual_surgery_id) {
        let vetFeeCoverSpecial = this.vetFeeCoverSpecial.filter(x => x.id == this.quote.life_saving_annual_surgery_id);
        if (vetFeeCoverSpecial.length > 0) {
          this.quote.full_life_saving_surgery = vetFeeCoverSpecial[0].description;
        }
      } else {
        let vetFeeCoverSpecial = this.vetFeeCoverSpecial.filter(x => x.life_saving_index == 2);
        if (vetFeeCoverSpecial.length > 0) {
          this.quote.full_life_saving_surgery = vetFeeCoverSpecial[0].description;
        }
      }
      if (this.quote.life_saving_surgery_id) {
        let vetFeeCoverSpecial = this.vetFeeCoverSpecial.filter(x => x.id == this.quote.life_saving_surgery_id);
        if (vetFeeCoverSpecial.length > 0) {
          this.quote.full_life_saving_surgery = vetFeeCoverSpecial[0].description;
        }
      }

    }
    // Vet Fee Excess    
    if (this.vetFeeExcess) {
      let vetFeeExcess = this.vetFeeExcess.filter(x => x.id == this.quote.company_excess_id);
      if (vetFeeExcess.length > 0 && this.quote.vet_fee_sum_insured_id) {
        this.quote.full_vet_fee_excess = vetFeeExcess[0].description;
      } else {
        this.quote.full_vet_fee_excess = '';
      }
    }
    // Public liability
    if (this.publicLiability) {
      let public_liability = this.publicLiability.filter(x => x.id == this.quote.public_liability_cover_id);
      if (public_liability.length > 0) {
        this.quote.full_public_liability = public_liability[0].description;
      } else {
        this.quote.full_public_liability = '';
      }
    }
    // Personal Accident
    if (this.accidentCover) {
      let accident_cover = this.accidentCover.filter(x => x.id == this.quote.personal_accidental_cover_id);
      if (accident_cover.length > 0) {
        this.quote.full_personal_accident = accident_cover[0].description;
      } else {
        this.quote.full_personal_accident = '';
      }
    }
  }

  // SAVE ENDORSEMENT
  addEndorsement(content) {
    this.modalService.open(content, { centered: true });
    this.endorsement = new QuoteEndorsement();
  }
  onEndorsementSubmit(f: NgForm) {
    let data = {
      id: this.endorsement.id,
      quote_id: this.quote.id, //this.route.snapshot.params.quote_meta_id,
      title: this.endorsement.title,
      endorsement_text: this.endorsement.endorsement_text,
      type: this.endorsement.type
    };
    this.quoteService.saveEndorsement(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.rowData = response.result.endorsement_list;
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
          this.modalService.dismissAll();
        });
  }

  // GET ENDORSEMENT
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
      // var cellDefs = this.gridOptions.api.getEditingCells();
    }


  };
  columnDefs = [
    { headerName: 'Title', field: 'title', sortable: true, width: 200, editable: false },
    {
      headerName: 'Endorsement', field: 'endorsement_text', sortable: true, editable: false, width: 500,
      cellRenderer: function (param) {
        return '<a data-action-type="view">' + param.data.endorsement_text.replace('\r\n', '<br>') + '</a>';
      },
      tooltip: function (param) {
        return param.data.endorsement_text;
      },
    },
    {
      headerName: 'Created At', field: 'created_at', sortable: false, width: 200,
      cellRenderer: function (params) {
        return formatDate(params.data.created_at, 'dd-MM-yyyy hh:mm a', 'en-US');
      }
    },
    {
      headerName: null,
      width: 120,
      cellRenderer: function (params) {
        if (!params.data.isBroker) {
          let links = '<div class="text-right">';
          links += '<a title="Edit Endorsement" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
          links += '<a title="Delete" style="font-size: 18px; margin-left: 20px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete" id="' + params.data.id + '"></i></a>';
          links += '</div>';
          return links;
        } else {
          return '';
        }
      }
    }
  ];
  rowData: any;

  selectedRow;
  defaultColDef = {
    flex: 1,
    wrapText: true,
    autoHeight: true,
    sortable: true,
    resizable: true,
  };
  onColumnResized(params) {
    params.api.resetRowHeights();
  }
  endorsementText;
  onGridRowClicked(e: any, content, deleteModel, endorsementViewModel) {
    if (e) {

      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {

            const modalRef = this.modalService.open(content, { centered: true });
            e.data.type = Number(e.data.type);
            this.endorsement = e.data;
            break;
          }
        case "view":
          {
            this.modalService.open(endorsementViewModel, { centered: true });
            this.endorsementText = e.data.endorsement_text.replace('\r\n', '<br>');
            break;
          }
        case "delete": {
          this.modalService.open(deleteModel, { centered: true });
          this.selectedRow = this.gridOptions.api.getFocusedCell();
          this.endorsement = e.data;
        }
      }
    }
  }

  deleteEndorsement(id, selectedRow) {
    this.quoteService.deleteEndorsement(id)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
          this.gridOptions.api.setRowData(this.gridOptions.rowData);
        });
  }

}
