import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteService } from '../../../quote.service';
import { AlQuoteService } from '../../al-quote/al-quote.service';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserInfoService } from '../../../../services/user-info.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-admin-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.css']
})
export class AlQuoteDetailsComponent implements OnInit {
  quoteData = new QuoteVersions();
  staticData;
  isAdmin = false;
  isInsurer = false;
  isStaff = false;
  isBroker = false;
  selectedIndex = 0;

  constructor(private route: ActivatedRoute, private quoteService: QuoteService, private adminQuoteService: AlQuoteService, private userService: UserInfoService,
    private sessionSt: LocalStorageService, private router: Router, private modalService: NgbModal, private _snackBar: MatSnackBar) { }


  hasImportantNotes: boolean = false;
  totalImpNotes: number = 0;
  hideForBroker: boolean = false;
  minPolicyDate;
  maxPolicyDate;
  isActionBtn: boolean = false;
  ngOnInit(): void {
    if (this.sessionSt.retrieve('is_admin')) {
      this.isAdmin = true;
    }
    if (this.sessionSt.retrieve('is_insurer')) {
      this.isInsurer = true;
    }
    if (this.sessionSt.retrieve('is_staff')) {
      this.isStaff = true;
    }
    if (this.sessionSt.retrieve('is_broker')) {
      this.isBroker = true;
      this.selectedIndex = 5;
    }
    // GET QUOTE DETAILS
    this.quoteService.getQuoteDetailsByQuoteID(this.route.snapshot.params.quote_id)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteData = response.result;
            let policy_start_date = new Date(response.result.policy_start_date);
            policy_start_date.setHours(0, 0, 0, 0);
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            // Hide convert to policy/Quote buttons if date is past date
            if (policy_start_date.getTime() < today.getTime() && this.sessionSt.retrieve('company_role') == 'Broker') {
              this.hideForBroker = true;
            }

            if ((this.quoteData.insurance_status_id == "f6d14624-6351-4d93-b27b-70fb8a06ec5d")
              || (this.quoteData.insurance_status_id == "80c0db90-ba5d-4387-b09a-4c008a664932")
              || (this.quoteData.insurance_status_id != "dda8734c-d149-4336-b8cc-c2e205430752")) {
              this.isActionBtn = true;
            }
            this.getQuoteNotes();
          }
        },
        (error) => {
          console.log(error);
        }
      );


  }

  getQuoteNotes() {
    if (this.quoteData.id) {
      this.quoteService.getNotes(this.quoteData.id)
        .subscribe(
          (response: any) => {
            let impNotes = response.result.filter(x => x.is_important == 1);
            this.totalImpNotes = impNotes.length;
            if (impNotes.length > 0) {
              this.hasImportantNotes = true;
            }
          });
    }
  }

  onhasImportantNotesChange(event) {
    this.hasImportantNotes = event;
    this.totalImpNotes = this.totalImpNotes + 1;
  }

  quoteDetailsUpdated(quotedata) {
    this.quoteData = quotedata;
  }

  /*
  * Update Quote Status
  */
  msg: string = '';
  updateQuoteStatus(status_id, content) {
    let data = {
      quote_id: this.quoteData.id,
      insurance_status_id: status_id
    };

    this.quoteService.updateQuoteStatus(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteData.insurance_status_id = '80c0db90-ba5d-4387-b09a-4c008a664932';
            this.modalService.open(content, { centered: true });
            this.msg = response.message;
          }
        });
  }

  /*
  * On Policy Start Date Change
  */
  onPolicyStartDateChange(event) {
    this.disablIfPastDate = false;
  }

  /*
  * Convert to Policy
  */
  disablIfPastDate: boolean = false;
  convertToPolicy(converttopolicymodal, MTAMsgBox) {
    // Redirect to quote journey if broker is converting quote to policy and policy start date is within 7 days
    var date1: any = new Date(this.quoteData.policy_start_date);
    var date2: any = new Date();
    var diffDays: any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
    if (this.sessionSt.retrieve('is_broker') && this.quoteData.type == 3) {
      if (diffDays >= 0 && diffDays < 7) {
        this.router.navigateByUrl("/alpaca-admin/quote/" + this.quoteData.id + '/7');
      } else {
        this.modalService.open(MTAMsgBox, { centered: true });
      }
    } else if (this.sessionSt.retrieve('is_broker') && this.quoteData.type == 1) {
      if (diffDays <= 30) {
        let maxDate = new Date().setDate(new Date().getDate() + 30);
        this.maxPolicyDate = new Date(maxDate);
        this.minPolicyDate = new Date();
        this.quoteData.policy_start_date = new Date();
        this.ConvertToPolicyCM(converttopolicymodal);
      } else {
        this.modalService.open(MTAMsgBox, { centered: true });
      }
    } else if (this.sessionSt.retrieve('is_broker') && this.quoteData.type == 2) {
      if (diffDays > 0) {
        this.modalService.open(MTAMsgBox, { centered: true });
      } else {
        this.ConvertToPolicyCM(converttopolicymodal);
      }
    } else {
      this.ConvertToPolicyCM(converttopolicymodal);
    }

  }

  ConvertToPolicyCM(converttopolicymodal) {

    if (this.sessionSt.retrieve('user_group') == 'Staff') {
      this.minPolicyDate = new Date();
    }

    // Disable convert to policy buttons if date is past date
    let policy_start_date = new Date(this.quoteData.policy_start_date);
    policy_start_date.setHours(0, 0, 0, 0);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (policy_start_date.getTime() < today.getTime() && this.sessionSt.retrieve('company_role') == 'Broker') {
      this.disablIfPastDate = true;
    }
    this.modalService.open(converttopolicymodal, { centered: true });
  }

  convertToPolicyPost() {
    let data: any = '';
    data = {
      quote_id: this.quoteData.id,
      policy_ref_number: this.quoteData.policy_ref_number,
      policy_start_date: formatDate(this.quoteData.policy_start_date, 'yyyy-MM-dd', 'en-US')
    };
    this.adminQuoteService.convertToPolicy(data)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.router.navigate(['/alpaca-admin/policy', this.quoteData.id]);
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

  displayEmailView: boolean = false;
  displayNewEmailView: boolean = false;
  newEmailView() {
    this.displayNewEmailView = true;
  }

  email_id: string = '';
  getEmailView(email_id) {
    this.email_id = email_id;
    this.displayEmailView = true;
  }
  backToEmailsList() {
    this.displayEmailView = false;
    this.displayNewEmailView = false;
  }

  /*
    * Update Quote Status
    */
  confimMsg: string = '';
  updateQuoteDeclineStatus(declineMsgBox) {
    this.confimMsg = 'Are you sure you want to decline the quote?';
    this.modalService.open(declineMsgBox, { centered: true });
  }
  DeclineQuote() {
    let data = {
      quote_id: this.quoteData.id,
      insurance_status_id: "dda8734c-d149-4336-b8cc-c2e205430752"
    };

    this.quoteService.updateAnyQuoteStatus(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
            window.location.reload();
          }
          else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }


  // Quote Lapse
  QuoteLapseCfmMSg(msgBox) {
    this.confimMsg = 'Are you sure you want to lapse the quote?';
    this.modalService.open(msgBox, { centered: true });
  }

  lapseQuote() {
    let data: any = '';
    data = {
      quote_id: this.quoteData.id,
      type: 8
    };
    this.quoteService.lapseQuote(data)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.router.navigate(['/policy', response.result.id]);
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

}
