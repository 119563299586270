import { Component, OnInit } from '@angular/core';
import {BordereauService } from '../bordereau.service';

@Component({
  selector: 'app-bordereau-live',
  templateUrl: './bordereau-live.component.html',
  styleUrls: ['./bordereau-live.component.css']
})
export class BordereauLiveComponent implements OnInit {

  constructor(private bordereauService: BordereauService) { }

  ngOnInit(): void {
  }

  export_report() {
    this.bordereauService.live()
      .subscribe(
        (response: any) => {
          var blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "peliwica.xlsx";
          link.click();
        });
  }

}
