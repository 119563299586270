import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteService } from '../../quote/quote.service';
import { GridOptions } from "ag-grid-community";
import { QuoteNotes } from '../../model/quote/quote_notes.model';
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-policy-notes',
  templateUrl: './policy-notes.component.html',
  styles: [
  ]
})
export class PolicyNotesComponent implements OnInit {
  @Input() quote: QuoteVersions;
  @Output() hasImportantNotes = new EventEmitter();
  notes = new QuoteNotes();
  constructor(private quoteService: QuoteService, private modalService: NgbModal, 
    private sessionSt: LocalStorageService, private _snackBar: MatSnackBar) { }

  previousSequenceNumber;
  ngDoCheck() {
    this.getNotes();
  }

  totalImpNotes: number = 0;
  ngOnChanges(changes: SimpleChanges) {   
  }
  ngOnInit(): void {    
  }

  getNotes() {
    if(this.quote && this.quote.notes) {    
      if (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') {
        this.quote.notes.forEach((res) => {
          res.isAdmin = true;
        })
      }
      this.rowData =this.quote.notes;
    }
  }

  // GET ENDORSEMENT
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: ' ', field: 'notes', sortable: true, width: 500,
      cellRenderer: function (params) {
        return ((params.data.is_important == 1) ? '<label class="badge badge-danger">Important</label> ' : '') + '<a data-action-type="view">' + params.data.notes + '</a>';
      },
      tooltip: function (params) {
        return params.data.notes;
      },
    },
    {
      headerName: 'Created By', sortable: false, width: 120,
      cellRenderer: function (params) {
        if (params.data.user) {
          return params.data.user.first_name + ' ' + params.data.user.last_name;
        }
      }
    },
    {
      headerName: 'Created At', sortable: false, width: 200,
      cellRenderer: function (params) {
        return formatDate(params.data.created_at, 'dd-MM-yyyy hh:mm a', 'en-US');
      }
    },
    {
      headerName: null,
      width: 120, type: 'rightAligned',
      cellRenderer: function (params) {
        let links = '';
        if (params.data.isAdmin) {
          links += '<a title="Edit Notes" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        }
        // links += '<a title="Delete" style="font-size: 18px; margin-left: 20px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete" id="' + params.data.id + '"></i></a>';
        return links;
      }
    }
  ];
  rowData: any;

  selectedRow;
  notesText;
  onGridRowClicked(e: any, content, deleteModel, notesViewModel) {
    if (e) {

      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {

            const modalRef = this.modalService.open(content, { centered: true });
            this.notes = e.data;
            break;
          }
        case "view":
          {
            this.modalService.open(notesViewModel, { centered: true });
            this.notesText = e.data.notes;
            break;
          }
        case "delete": {
          this.modalService.open(deleteModel, { centered: true });
          this.selectedRow = this.gridOptions.api.getFocusedCell();
          this.notes = e.data;
        }
      }
    }
  }

  deleteNotes(id, selectedRow) {
    this.quoteService.deleteNotes(id)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
          this.gridOptions.api.setRowData(this.gridOptions.rowData);
        });
  }

  /*
  * ADD NOTES
  */
  openNotesPopup(content) {
    this.modalService.open(content, { centered: true });
    this.notes = new QuoteNotes();
  }

  saveNotes() {
    let data = {
      id: this.notes.id,
      quote_id: this.quote.id, //this.route.snapshot.params.quote_meta_id,
      notes: this.notes.notes,
      is_important: this.notes.is_important
    };
    this.quoteService.saveNotes(data)
      .subscribe(
        (response: any) => {
          if(response.success) {
            response.result.notes_list.forEach((res) => {
              res.isAdmin = (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') ? true : false;
            })
            this.quote.notes = response.result.notes_list;
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
          this.isImportantNote();
          this.gridOptions.api.refreshCells();
          this.modalService.dismissAll();
        });
  }

  isImportantNote() {
    let is_important = false;
    this.rowData.forEach((res) => {
      if (res.is_important == 1) {
        is_important = true;
      }
    })
    this.hasImportantNotes.emit(is_important);
  }
}
