
<form class="pt-3" [formGroup]="resetPassowrdForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <label>New Password</label>
        <div class="input-group">
            <div class="input-group-prepend bg-transparent">
                <span class="input-group-text bg-transparent border-right-0">
                    <i class="mdi mdi-contact-mail text-primary"></i>
                </span>
            </div>
            <input type="password" name="password" formControlName="password"
                class="form-control form-control-lg border-left-0" placeholder="Password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
        </div>
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
        </div>
    </div>
    <div class="form-group">
        <label>Confirm Password</label>
        <div class="input-group">
            <div class="input-group-prepend bg-transparent">
                <span class="input-group-text bg-transparent border-right-0">
                    <i class="mdi mdi-contact-mail text-primary"></i>
                </span>
            </div>
            <input type="password" name="confirm_password" formControlName="confirm_password"
                class="form-control form-control-lg border-left-0" placeholder="Confirm Password"
                [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }">
        </div>
        <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
            <div *ngIf="f.confirm_password.errors.required">Confirm password field is required</div>
            <div *ngIf="f.confirm_password.errors.mustMatch">Passwords must match.</div>
        </div>
    </div>
    <div class="my-3">
        <button [disabled]="validLink !== 1"
            class="btn btn-block btn-success btn-lg font-weight-medium auth-form-btn">Submit</button>
    </div>
</form>
<!-- Modal For Error Message-->
<jw-modal id="errorMsg" class="messageModal">
    <img src="assets/images/failure_icon.jpg">
    <p class="mt-4">{{apiMSG}}</p>
    <p>
        <a href="/">Click here</a> to go back to login.
    </p>
</jw-modal>
<jw-modal id="errorMsg2" class="messageModal">
    <img src="assets/images/failure_icon.jpg">
    <p class="mt-4">{{apiMSG}}</p>
</jw-modal>
<!-- Modal For Success Message-->
<jw-modal id="successMsg" class="messageModal">
    <img src="assets/images/success_icon.jpg">
    <p class="mt-4">{{apiMSG}}</p>
</jw-modal>