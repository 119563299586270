import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, ElementRef } from '@angular/core';
import { formatDate, TitleCasePipe } from '@angular/common';
import { FormBuilder, NgForm } from '@angular/forms';
import { LocalStorageService } from 'ngx-webstorage';
import { ActivatedRoute } from "@angular/router";
import { Quote } from '../../../model/quote/quote.model';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { QuoteService } from '../../quote.service';
import { CalculationsDetails } from '../calculations/calculations.model';
import { StableAddress } from '../../../model/quote/stable_address.model';
import { HorseDetailsSaddlery } from '../../../model/quote/horse_details_saddlery.model';
import { HorseDetailsTrailer } from '../../../model/quote/horse_details_trailer.model';
import { UserInfoService } from '../../../services/user-info.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ajg-full-details',
  templateUrl: './ajg-full-details.component.html',
  styleUrls: ['../../quote.component.css'],
})
export class AjgFullDetailsComponent implements OnInit {
  @Input() quoteDetails: QuoteVersions;
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  quote;
  parent_quote = new Quote();
  @Output() BackToQuoteDetails = new EventEmitter();
  @Output() ProceedToStatementOfFact = new EventEmitter();
  saddleryYesDetails = false;
  indicativeCalculationsDetails = new CalculationsDetails();
  titleList = [];

  constructor(private quoteService: QuoteService, private sessionSt: LocalStorageService, private userService: UserInfoService,
    private route: ActivatedRoute, private el: ElementRef, private titlecasePipe: TitleCasePipe, private modalService: NgbModal) {

  }

  brokers;
  horseHeight;
  horseBreed;
  horseColor;
  saddlery_tack_items = [];
  vet_fee_excess_amount;
  saddleryTotalSumInsured: number = 0;
  trailerTotalSumInsured: number = 0;
  mta_quote_data = new QuoteVersions();
  hourseGender;

  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
    if (this.quoteDetails.quote_id != "" && this.quoteDetails.quote_id != "") {
      this.mta_quote_data = this.quoteDetails;
      this.parent_quote.quote_versions[0].mta_sum_policy_premium = this.quoteDetails.mta_sum_policy_premium;
      this.parent_quote.quote_versions[0].sum_policy_premium = this.quoteDetails.sum_policy_premium;
      this.parent_quote.quote_versions[0].latest_policy_start_date = this.quoteDetails.latest_policy_start_date;
      this.parent_quote.quote_versions[0].latest_policy_end_date = this.quoteDetails.latest_policy_end_date;

      this.indicativeCalculationsDetails.old_tigerlab_premium_loading = this.mta_quote_data.financial_details.tigerlab_premium_loading;
      this.indicativeCalculationsDetails.old_premium_loading_percentage = this.mta_quote_data.financial_details.old_premium_loading_percentage;
    }

    if (this.quote.horse_details.is_stable_address_different == 0) {
      this.quote.horse_details.is_stable_address_different = null;
    }
    if (this.quote.horse_details.is_horse_vetted == 0) {
      this.quote.horse_details.is_horse_vetted = null;
    }
    if (this.quote.does_policy_owner_owns_horse == 0) {
      this.quote.does_policy_owner_owns_horse = null;
    }
    if (this.quote.horse_details.gender == 0) {
      this.quote.horse_details.gender = null;
    }

    // Calculate Sum of Saddlery & Tack Item's Sum Insured
    this.saddleryTotalSumInsured = 0;
    this.quote.horse_details.saddlery_items.forEach((res, index) => {
      this.saddleryTotalSumInsured += Number(res.sum_insured);
    });

    // Calculate Sum of Trailer Item's Sum Insured
    this.trailerTotalSumInsured = 0;
    this.quote.horse_details.trailor_items.forEach((res, index) => {
      res.sum_insured = (res.sum_insured == 0) ? null : res.sum_insured;
      this.trailerTotalSumInsured += Number(res.sum_insured);
    });

    if (this.quote.policy_holder.address == null) {
      this.quote.policy_holder.address = new StableAddress();
    }
    if (this.quote.horse_details.address == null) {
      this.quote.horse_details.address = new StableAddress();
    }
    if (this.quoteDetails.calculations != '') {
      this.indicativeCalculationsDetails = this.quoteDetails.calculations;
    }
    // Horse Ownership
    if (this.quote.is_accepted == 0) {
      this.quote.is_accepted = null;
    }
    // Show/Hide Saddlery
    if (this.quote.horse_details.saddlery_items.length == 0) {
      this.quote.horse_details.saddlery_items.push(new HorseDetailsSaddlery());
    } else {
      if (this.route.snapshot.params.id && this.quote.does_addon_saddlery_on) {
        this.quote.horse_details.saddlery_items.forEach((res, index) => {
          res.sum_insured = (res.sum_insured == '0.00') ? null : res.sum_insured;
          if (index == 0) {
            if (res.brand) {
              this.quote.is_saddlery_tack = 1;
            } else {
              if (this.quote.horse_details.horse_purchase_date) {
                this.quote.is_saddlery_tack = 2;
              }

            }
          }
        });
      }
    }

    // Show/Hide Trailer
    if (this.quote.horse_details.trailor_items.length == 0) {
      this.quote.horse_details.trailor_items.push(new HorseDetailsTrailer());
    } else {
      if (this.route.snapshot.params.id && this.quote.does_addon_trailer_on) {
        this.quote.horse_details.trailor_items.forEach((res, index) => {
          res.sum_insured = (res.sum_insured == '0.00') ? null : res.sum_insured;
        });
      }
    }

    //Horse Breed Selection In Case of Bloodstock
    if (this.quote.blood_stock_id) {
      this.quote.horse_details.breed_id = 'dad1ba6a-fdf1-4ca8-86fd-69a080a09887';
    }
  }

  minPolicyDate;
  maxPolicyDate;
  today = new Date();
  isBroker: boolean = false;
  ngOnInit(): void {

    this.quote = this.quoteDetails;
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      //this.minPolicyDate = this.today;
      let minDate = new Date().setDate(new Date().getDate() - 14);
      this.minPolicyDate = new Date(minDate);
      let maxDate = new Date().setDate(new Date().getDate() + 30);
      this.maxPolicyDate = new Date(maxDate);
      this.isBroker = true;
    }


    // Get All Gender List
    this.quoteService.getHorseGenderList()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.hourseGender = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // Get All Brokers
    this.quoteService.getBrokers(5)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.brokers = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // Get Horse Details
    this.quoteService.getHorseDetails()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.horseHeight = response.result.height;
            this.horseBreed = response.result.breed;
            this.horseColor = response.result.color;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // Get All Brokers
    this.quoteService.getTitleList()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.titleList = response.result;
          }
        });
  }



  backToQuoteDetailsBtn() {
    this.quote.indicativePage = true;
    this.BackToQuoteDetails.emit();
  }

  /*
  * POSTCODE LOOKUP
  */
  addresses;
  postcodeLookup(event) {
    let postcode = event.target.value;
    // let res = '{"postcode": "NN1 3ER","latitude": 52.24593734741211,"longitude": -0.891636312007904,"addresses":[{"formatted_address": ["10 Watkin Terrace","","","Northampton","Northamptonshire"],"thoroughfare":  "Watkin Terrace","building_name":  "","sub_building_name":  "","sub_building_number":  "","building_number":  "10","line_1":  "10 Watkin Terrace", "line_2":  "","line_3":  "","line_4":  "", "locality":  "","town_or_city":  "Northampton", "county":  "Northamptonshire", "district":  "Northampton", "country":  "England" },{"formatted_address": ["1 Terrace","","","Northampton","Northamptonshire"],"thoroughfare":  "Watkin Terrace","building_name":  "","sub_building_name":  "","sub_building_number":  "","building_number":  "1","line_1":  "1 Watkin Terrace", "line_2":  "","line_3":  "","line_4":  "", "locality":  "","town_or_city":  "Leeds", "county":  "Wakefield", "district":  "Northampton", "country":  "England" } ]}';
    // let res1 = JSON.parse(res);

    // API call - postcode lookup
    this.quoteService.getAddressPostcodeLookup(postcode)
      .subscribe(
        (response: any) => {
          this.addresses = response.addresses;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSelectAddress(address) {
    this.quote.policy_holder.address.address1 = address.line_1;
    this.quote.policy_holder.address.address2 = address.line_2;
    this.quote.policy_holder.address.city = address.town_or_city;
    this.quote.policy_holder.address.country = address.county;
  }

  onSelectStableAddress(address) {
    this.quote.horse_details.address.address1 = address.line_1;
    this.quote.horse_details.address.address2 = address.line_2;
    this.quote.horse_details.address.city = address.town_or_city;
    this.quote.horse_details.address.country = address.county;
  }


  /*
  * On Horse Address Change
  */
  onHorseAddressChange(event) {
    
  }
  onHorseVettedChange(event) {
    if(event.value == 2) {
      this.quote.horse_details.date_of_vetting = '';
    }
  }
  addSaddleryItems() {
    this.quote.horse_details.saddlery_items.push(new HorseDetailsSaddlery());


  }
  removeSaddleryItems(i) {
    this.quote.horse_details.saddlery_items.splice(i, 1);
  }
  /*
  * Clear Saddlery Details If 'No' Selected
  */
  onSaddleryTackChange(event) {
    if (event.value == 2) {
      this.quote.horse_details.saddlery_items = [];
      this.quote.horse_details.saddlery_items.push(new HorseDetailsSaddlery());
    }
  }
  /*
  * TRAILER ADD/REMOVE ITEMS
  */
  addTrailerItems() {
    this.quote.horse_details.trailor_items.push(new HorseDetailsTrailer());


  }
  removeTrailerItems(i) {
    this.quote.horse_details.trailor_items.splice(i, 1);
  }

  /*
  * On Horse Ownership Change
  */
  onHorseOwnershipChange(event) {
    if (event.value == 1) {
      this.quote.is_accepted = null;
      this.validateAcceptTerms = false;
    }
  }
  acceptTerms(event) {
    if (event.source.value == 1) {
      this.validateAcceptTerms = false;
    } else {
      this.validateAcceptTerms = true;
    }
  }

  /*
  * Format Date
  */
  formatDate(event) {
    let formattedDate = formatDate(event.target.value, 'yyyy-MM-dd', 'en-US');
    this.quote.horse_details.horse_purchase_date = formattedDate;
  }
  formatVettingDate(event) {
    let formattedDate = formatDate(event.target.value, 'yyyy-MM-dd', 'en-US');
    this.quote.horse_details.date_of_vetting = formattedDate;
  }
  maxSelectedPolicyDate;

  /**
   * 
   */
  policyStartDate(event) {
    let formattedDate = formatDate(event.target.value, 'yyyy-MM-dd', 'en-US');
    this.maxSelectedPolicyDate = new Date(formattedDate);
  }

  validateAcceptTerms: boolean = false;
  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      for (const key of Object.keys(f.controls)) {
        if (f.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('input[name=' + key + '], mat-select[name=' + key + '], textarea[name=' + key + ']');
          invalidControl.focus();
          invalidControl.scrollIntoView({ behavior: 'smooth' });
          break;
        }
      }

      return false;
    }

    if (this.quote.does_policy_owner_owns_horse == 2 && !this.quote.is_accepted) {
      this.validateAcceptTerms = true;
      return false;
    }

    let data: any = '';
    let formattedDate = formatDate(this.quote.policy_start_date, 'yyyy-MM-dd', 'en-US');
    this.quote.policy_start_date = formattedDate;
    // if (this.quote.horse_details.date_of_vetting) {
    //   let date_of_vetting = formatDate(this.quote.horse_details.date_of_vetting, 'yyyy-MM-dd', 'en-US');
    //   this.quote.horse_details.date_of_vetting = date_of_vetting;
    // }

    if (this.quote.horse_details.saddlery_items.length > 0) {
      if (this.quote.horse_details.saddlery_items[0].purchase_date) {
        this.quote.horse_details.saddlery_items.forEach((res, index) => {
          res.purchase_date = formatDate(res.purchase_date, 'yyyy-MM-dd', 'en-US');
        });
      } else {
        this.quote.horse_details.saddlery_items = [];
      }
    }
    if (this.quote.horse_details.trailor_items.length > 0) {
      if (this.quote.horse_details.trailor_items[0].purchase_date) {
        this.quote.horse_details.trailor_items.forEach((res, index) => {
          res.purchase_date = formatDate(res.purchase_date, 'yyyy-MM-dd', 'en-US');
        });
      } else {
        this.quote.horse_details.trailor_items = [];
      }
    }

    let dob = formatDate(this.quote.policy_holder.dob, 'yyyy-MM-dd', 'en-US');
    this.quote.policy_holder.dob = dob;

    this.parent_quote.quote_versions[0] = this.quote;
    this.parent_quote.id = this.quote.quote_meta_id;
    data = {
      quote_data: this.parent_quote
    };
    this.quoteService.saveAJGIndicativeQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            var temp;
            temp = this.quote;
            this.quote = response.result.quote_data.quote_versions[0];
            this.quote.full_class_of_use = temp.full_class_of_use;
            this.quote.full_policy_cover = temp.full_policy_cover;
            this.quote.full_mortality_claim_loading = temp.full_mortality_claim_loading;
            this.quote.full_vet_fee_cover = temp.full_vet_fee_cover;
            this.quote.full_vet_claim_loading = temp.full_vet_claim_loading;
            this.quote.full_vet_fee_excess = temp.full_vet_fee_excess;
            this.quote.full_personal_accident = temp.full_personal_accident;
            this.quote.full_public_liability = temp.full_public_liability;
            this.quote.indicativePage = temp.indicativePage;
            this.quote.quote_document = temp.quote_document;
            this.quote.company_co_insurance = temp.company_co_insurance;
            this.quote.full_quote_section = temp.full_quote_section;
            this.quote.hide_sections_for_blood_stock = temp.hide_sections_for_blood_stock;
            this.quote.prev_sequence_number = temp.prev_sequence_number;
            this.quote.calculations = temp.calculations;
            this.quote.mta_sum_policy_premium = response.result.quote_data.mta_sum_policy_premium;
            this.quote.sum_policy_premium = response.result.quote_data.sum_policy_premium;
            this.quote.latest_policy_start_date = response.result.quote_data.latest_policy_start_date;
            this.quote.latest_policy_end_date = response.result.quote_data.latest_policy_end_date;
            this.quoteDetailsUpdated.emit(this.quote);
            this.ProceedToStatementOfFact.emit();
          }

        });
  }

  getUpdatedvalue($event) {
    this.indicativeCalculationsDetails = $event;
  }
  /*
  * HELP TEXT POPUP
  */
  helptext = '';
  helpTextPopup(content: any, text) {
    const modalRef = this.modalService.open(content, { centered: true });
    this.helptext = text;
  }
}