<div class="row">
    <div class="col-md-8">
        <div class="form_sections">
            <h4 class="text-center">Quote Summary</h4>
            <div class="table-responive">
                <table class="table table-stripped text-white payment_details" *ngIf="quoteDetails.financial_details">
                    <tr>
                        <td>Policy premium</td>
                        <td>&pound;{{ quoteDetails.financial_details.policy_premium | number:'1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td>Broker commission</td>
                        <td>&pound;{{ quoteDetails.financial_details.broker_commission | number:'1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td>Insurance premium tax (12%)</td>
                        <td>&pound;{{ quoteDetails.financial_details.ipt_fee }}</td>
                    </tr>
                    <tr>
                        <td>Total premium</td>
                        <td>&pound;{{ quoteDetails.financial_details.total_premium | number:'1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td>Fees</td>
                        <td>&pound;{{ quoteDetails.financial_details.fees | number:'1.2-2' }} </td>
                    </tr>
                    <tr class="total_cost">
                        <td>Total cost of the policy</td>
                        <td>&pound;{{ quoteDetails.financial_details.total_policy_cost | number:'1.2-2' }} </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="form_sections mt-4" *ngIf="PaymentSections">
            <h4 class="text-center">Billing Address</h4>
            <form [formGroup]="quoteDetailsForm" (ngSubmit)="onSubmit()">
                <div class="row mt-4">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="first_name" placeholder="First Name"
                                class="form-control form-control-sm" formControlName="first_name" required
                                [ngClass]="{ 'is-invalid': (frmCtrl.first_name.touched || submitted) && frmCtrl.first_name.errors?.required }"
                                appTitleCase>
                            <div class="invalid-feedback"
                                *ngIf="(frmCtrl.first_name.touched || submitted) && frmCtrl.first_name.errors?.required">
                                First Name is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="last_name" placeholder="Last Name"
                                class="form-control form-control-sm" formControlName="last_name" appTitleCase>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="city" placeholder="City" class="form-control form-control-sm"
                                formControlName="city" required
                                [ngClass]="{ 'is-invalid': (frmCtrl.city.touched || submitted) && frmCtrl.city.errors?.required }"
                                appTitleCase>
                            <div class="invalid-feedback"
                                *ngIf="(frmCtrl.city.touched || submitted) && frmCtrl.city.errors?.required">
                                City is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="postcode" placeholder="Postcode"
                                class="form-control form-control-sm" formControlName="postcode" required
                                [ngClass]="{ 'is-invalid': (frmCtrl.postcode.touched || submitted) && frmCtrl.postcode.errors?.required }"
                                appTitleCase>
                            <div class="invalid-feedback"
                                *ngIf="(frmCtrl.postcode.touched || submitted) && frmCtrl.postcode.errors?.required">
                                Postcode is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <textarea name="address" formControlName="address" placeholder="address"
                        class="form-control form-control-sm" required
                        [ngClass]="{ 'is-invalid': (frmCtrl.address.touched || submitted) && frmCtrl.address.errors?.required }"></textarea>
                    <div class="invalid-feedback"
                        *ngIf="(frmCtrl.address.touched || submitted) && frmCtrl.address.errors?.required">
                        Address is required.
                    </div>
                </div>
                <div class="form-group mt-5">
                    <button class="btn btn-gray btn-sm text-white" (click)="cancelPayment()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
    <div class="col-md-4">
        <div class="form_sections">
            <!-- <div style="min-height: 350px;"> -->
            <div style="min-height: 0px;">
                <h4 class="w-100 text-center">Payment Details</h4>
                
                 <!-- <form #paymentForm *ngIf="!token">
                    <div id="paymentSection" class="w-100"></div>
                 </form>
                 <h2 *ngIf="token">Token from WorldPay {{ token }}</h2>-->
                <div class="form-group mt-5 text-center">
                    <button class="btn btn-success btn-sm w-100" (click)="proceedBtn(converttopolicymodal)">Proceed</button>
                </div> 
            </div>
            <div *ngIf="!PaymentSections">
                <!-- <button class="btn btn-success btn-sm w-100 text-center">Monthly Installments</button><br>
                <button class="btn btn-success btn-sm mt-3 w-100 text-center" (click)="oneOffPayment()">One off
                    Payment</button> -->

                <h4 class="w-100 text-center documents">Useful Documents</h4>
                <ul>
                    <li><a href="#" class="text-white">Quotation schedule</a></li>
                    <li><a href="#" class="text-white">Policy wording</a></li>
                    <li><a href="#" class="text-white">IPID</a></li>
                    <li><a href="#" class="text-white">Broker Terms of Business</a></li>
                    <li><a href="#" class="text-white">Broker Fair Processing Notice</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

<ng-template #converttopolicymodal let-modal>
    <div class="modal-body">
        <p>We assume you have all the quote data. Are you sure you want to convert <b>{{ quoteDetails.quote_ref_number }} </b> into policy?</p>
        <div class="form-group">
            <label>Policy Start Date</label>
            <div class="w-100">
                <input type="text" readonly required name="policy_start_date" [(ngModel)]="quoteDetails.policy_start_date"
                    #policy_start_date="ngModel" placeholder="Policy Start Date" style="height: 31px;" [disabled] = "quoteDetails.type == 3"
                    class="form-control form-control-sm datepicker" matInput [min]="minPolicyDate" [max]="maxPolicyDate"
                    [matDatepicker]="myDatepicker" [ngClass]="{ 'is-invalid': disablIfPastDate }" (dateChange)="onPolicyStartDateChange($event)">
                <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </div>
        </div>
    </div>
    <div class="modal-footer"> 
        <button type="button" class="btn btn-success" (click)="convertToPolicyPost()" [disabled]="disablIfPastDate">Convert to Policy</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
    </div>
</ng-template>