import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";


@Component({
  selector: 'app-layout-innerpages',
  templateUrl: './layout-innerpages.component.html',
  styleUrls: ['./layout-innerpages.component.css']
})
export class LayoutInnerpagesComponent implements OnInit {
  pageTitle;
  constructor(location: Location, private router: Router) {
    this.routeEvent(router);
  }

  ngOnInit(): void {
    
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.pageTitle = e.url.replace('/', '');
      }
    });
  }



}
