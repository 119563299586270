<form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div class="row">
        <div class="col-md-6">
            <div class="form_sections">
                <div class="form-group">
                    <label>Please select if the policy is paid</label> 
                    <mat-checkbox name="is_paid" value="1" [(ngModel)]="quote.is_paid"
                        [checked]="quote.is_paid == 1">
                        Is Paid?
                    </mat-checkbox>
                </div>
            </div>

            <div class="form_sections">
                <div class="form-group">
                    <label>Paid Date</label><br>
                    <input type="text" required name="paid_date" [value]="quote.paid_date | date: 'dd-MM-yyyy'" readonly
                        placeholder="Paid Date" class="form-control form-control-sm datepicker">
                </div>
            </div>
            <div class="form_sections">
                <div class="form-group">
                    <label>Bank Payment Date</label><br>
                    <input type="text" required name="bank_payment_date" [(ngModel)]="quote.bank_payment_date"
                        #bank_payment_date="ngModel" placeholder="Bank Payment Date" class="form-control form-control-sm datepicker"
                        [ngClass]="{ 'is-invalid': f.submitted && bank_payment_date.invalid }" matInput
                        (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker">
                    <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker></mat-datepicker>
                    <div *ngIf="f.submitted && bank_payment_date.invalid" class="invalid-feedback">
                        Bank Payment date is required.
                    </div>
                </div>
            </div>
            
            <div class="form-group">
                <button type="submit" name="submit" class="btn btn-success btn-sm">Submit</button>
            </div>
        </div>
    </div>
</form>