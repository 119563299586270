<div class="row">
    <div class="col-md-7">
        <div class="row mb-3">
            <div class="col-md-6"><label>Latest Version:</label></div>
            <div class="col-md-6">
                <input type="text" name="quote_version" [value]="quote.sequence_number"
                    class="form-control form-control-sm" readonly>
            </div>
        </div>
        <!-- <div class="row mb-3" *ngIf="quote.policy_ref_number">
            <div class="col-md-6"><label>Policy Ref No.:</label></div>
            <div class="col-md-6">
                <a class="text-white" (click)="policyDetails()"><input type="text" name="policy_ref"
                        [value]="quote.policy_ref_number" class="form-control form-control-sm" readonly
                        style="cursor: pointer;"></a>
            </div>
        </div> -->
        <div class="row mb-3" *ngIf="quote.is_policy ==1">
            <div class="col-md-6"><label>Quote Ref No.:</label></div>
            <div class="col-md-6">
                <a class="text-white" (click)="quoteDetails()"><input type="text" name="quote_ref" [value]="quote.quote_ref_number"
                    class="form-control form-control-sm" readonly style="cursor: pointer;"></a></div>
        </div>
        <div class="row mb-3" *ngIf="quote.policy_ref_number && quote.is_policy !=1">
            <div class="col-md-6"><label>Policy Ref No.:</label></div>
            <div class="col-md-6">
                <a class="text-white" (click)="policyDetails()"><input type="text" name="policy_ref"
                        [value]="quote.policy_ref_number" class="form-control form-control-sm" readonly
                        style="cursor: pointer;"></a>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>Start Date:</label></div>
            <div class="col-md-6">
                <input type="text" name="policy_start_date" [value]="quote.policy_start_date | date:'dd-MM-yyyy'"
                    placeholder="Policy Start Date" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>End Date:</label></div>
            <div class="col-md-6">
                <input type="text" name="policy_end_date" [value]="quote.policy_end_date | date:'dd-MM-yyyy'"
                    class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6"><label>Transaction Type:</label></div>
            <div class="col-md-6">
                <input type="text" name="transction_type" [(ngModel)]="transction_type" readonly
                    class="form-control form-control-sm">
                <!-- <mat-form-field class="w-100">
                    <mat-select [(ngModel)]="quote.type" 
                        class="form-control form-control-sm" name="quote_type" >
                        <mat-option [(value)]="type.key" *ngFor="let type of quoteTypes | keyvalue">{{type.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
        </div>
        <div class="row mb-3" *ngIf="quote.is_policy == 0">
            <div class="col-md-6"><label>Status:</label></div>
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <mat-select class="form-control form-control-sm" name="status" 
                        [(ngModel)]="quote.insurance_status_id">
                        <mat-option [value]="status.id" *ngFor="let status of policyStatuses">
                            {{status.display_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <!-- && quote.is_policy !=0 -->
        <div class="row mb-3" *ngIf="quote.mta_reason_id ">
            <div class="col-md-6"><label>Policy Reason:</label></div>
            <div class="col-md-6">
                <div *ngIf="quote.mta_reason_id != '1'">
                    <input type="text" name="policy_reason" [(ngModel)]="policyReason" 
                        class="form-control form-control-sm">
                </div>
                <div *ngIf="quote.mta_reason_id == '1'">
                    <input type="text" name="policy_reason" [(ngModel)]="quote.mta_reason_other" readonly
                        class="form-control form-control-sm">
                </div>
            </div>
        </div>
        <div class="row mb-1" *ngIf="quote.tigerlab_policy_reference">
            <div class="col-md-6"><label>Legacy Policy Reference:</label></div>
            <div class="col-md-6">
                <input type="text" name="tigerlab_policy_reference" placeholder="Legacy Policy Reference"
                    class="form-control form-control-sm" [(ngModel)]="quote.tigerlab_policy_reference" readonly>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6"><label>Insurer:</label></div>
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <mat-select [(ngModel)]="quote.insurer" disabled class="form-control form-control-sm"
                        name="insurer">
                        <mat-option *ngFor="let insurer of insurers" [value]="insurer.id">
                            {{insurer.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6"><label>Quote:</label></div>
            <div class="col-md-6">
                <input type="text" name="quote_type" placeholder="Full/Quote" class="form-control form-control-sm"
                    [value]="(quote.is_aeio == 1)?'AEIO':(quote.is_aeio == 2)?'Leisure Cover':'Sports Cover'" readonly>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-md-6"><label>Underwriter:</label></div>
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <mat-select [(ngModel)]="selectedUnderwriterID" disabled
                        class="form-control form-control-sm" name="underwriter" >
                        <mat-option *ngFor="let uw of underwriters" [value]="uw.id">
                         {{uw.userinfo.first_name}} {{uw.userinfo.last_name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->
    </div>
</div>
<!-- <app-quote-versions [quote]="quote" [is_policy]="0"   (hasImportantNotes)="onhasImportantNotesChange($event)"
(quoteVersionsUpdated)="quoteVersionsUpdated($event)"></app-quote-versions> -->

<app-quote-versions *ngIf="quote.is_policy ==1" [quote]="quote" [is_policy]="1"  (hasImportantNotes)="onhasImportantNotesChange($event)"
(quoteVersionsUpdated)="quoteVersionsUpdated($event)"></app-quote-versions>

<app-quote-versions *ngIf="quote.is_policy ==0" [quote]="quote" [is_policy]="0" (quoteVersionsUpdated)="quoteVersionsUpdated($event)"></app-quote-versions>