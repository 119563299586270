import { Injectable } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AmlineService {

  constructor(private sharedService: SharedService, private sessionSt: LocalStorageService) { }

  // Get Access Token
  getAccessToken(data) {
    return this.sharedService.postAmlineAPI('oauth/token', data);
  }
  // Get All Static Data
  getStaticData(authDetails) {
    return this.sharedService.getAmlineAPI('horsebox-api/lists', authDetails);
  }
  // Get Amlin Data
  getAmlineData(id) {
    return this.sharedService.getAPI('amlin/quote/' + id);
  }

  // Save Indicative Quote
  saveQuote(data) {
    return this.sharedService.postAPI('amlin/quote', data);
    // return this.sharedService.postAmlineAPI('horsebox-api/quote', data, authDetails);
  }
  // Calculate Premium
  calculatePremium(data, authDetails) {
    return this.sharedService.postAmlineAPI('horsebox-api/quote/calculate', data, authDetails);
  }

  // Get Quote Details 
  getQuoteDetails(authDetails, id) {
    return this.sharedService.getAmlineAPI('horsebox-api/quote/' + id, authDetails);
  }
  // GET UNIQUE ID FOR DRIVERS
  getUniqueID(authDetails) {
    return this.sharedService.getAmlineAPI('horsebox-api/generate-id', authDetails);
  }

  /*
  * Create Quote Object for Save API
  */
  createQuoteObj(data) {
    let obj = {
      data: {
        type: "horsebox-api/quote",
        attributes: {
          declaration: true,
          //inception_date: formatDate(data.cover_from, 'yyyy-MM-dd', 'en-US'),
          proposer: data.proposer,
        },
      }
    }
    return obj;
  }

  /*
  * Issue The Quote
  */
  issueQuote(data) {
    return this.sharedService.postAPI('amlin/issuequote', data);
  }
  /*
  * Get Cancellation Return Premium
  */
  getReturnPremium(data, policy_id, authDetails) {
    return this.sharedService.postAmlineAPI('horsebox-api/policy/' + policy_id + ':return-premium', data, authDetails);
  }
  /*
  * Cancel Policy
  */
  getCancelPolicy(data, policy_id, authDetails) {
    return this.sharedService.postAmlineAPI('horsebox-api/policy/' + policy_id + ':cancel', data, authDetails);
  }
  /*
  * Cancel Policy
  */
  getVehicleDetails(registration_number, authDetails) {
    return this.sharedService.getAmlineAPI('horsebox-api/vehicle/' + registration_number, authDetails);
  }
}

