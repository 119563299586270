<div *ngIf="!saveDetailsSection">
    <h3>Claims <button (click)="saveClaims()" *ngIf="isAdminStaff" class="btn btn-success btn-sm ml-3">Add Claim</button></h3>

    <ag-grid-angular style="width: 100%; height: 400px;" class="ag-theme-bootstrap" [gridOptions]="gridOptions"
        [rowData]="rowData" [columnDefs]="columnDefs" animateRows pagination paginationPageSize=10 rowHeight=27
        headerHeight=35 (rowClicked)='onGridRowClicked($event)'>
    </ag-grid-angular>
</div>
<div *ngIf="saveDetailsSection">
    <div class="row">
        <div class="col-6"><h3>Claims </h3></div>
        <div class="col-6 text-right"><a (click)="closeSaveClaimsSection()" class="btn btn-gray btn-sm">Back to list</a></div>
    </div>
    <form (ngSubmit)="f.form.valid && onSubmit(resModal)" #f="ngForm">
        <div class="row mt-3">
            <div class="col-md-6">
                <div class="form-group w-100">
                    <input type="text" required name="fnol_date" [(ngModel)]="claim.fnol_date" #fnol_date="ngModel"
                        placeholder="FNOL Date" class="form-control form-control-sm datepicker claimDatepicker"
                        [ngClass]="{ 'is-invalid': f.submitted && fnol_date.invalid }" matInput
                        (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker" style="width: 93%;">
                    <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker></mat-datepicker>
                    <div *ngIf="f.submitted && fnol_date.invalid" class="invalid-feedback">
                        Date is required.
                    </div>
                </div>
                <div class="form-group w-100">
                    <input type="text" required name="loss_date" [(ngModel)]="claim.loss_date" #loss_date="ngModel"
                        placeholder="Loss Date" class="form-control form-control-sm datepicker"
                        [ngClass]="{ 'is-invalid': f.submitted && loss_date.invalid }" matInput
                        (focus)="lossDatepicker.open()" [matDatepicker]="lossDatepicker" style="width: 93%;">
                    <mat-datepicker-toggle [for]="lossDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #lossDatepicker></mat-datepicker>
                    <div *ngIf="f.submitted && loss_date.invalid" class="invalid-feedback">
                        Loss date is required.
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-success text-white">&pound;</span>
                        </div>
                        <input type="text" appNumbers required name="payment_amount" [(ngModel)]="claim.payment_amount"
                            #payment_amount="ngModel" placeholder="Amount" class="form-control form-control-sm"
                            [ngClass]="{ 'is-invalid': f.submitted && payment_amount.invalid }">
                        <div *ngIf="f.submitted && payment_amount.invalid" class="invalid-feedback">
                            Amount is required.
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="claim.id">
                    <input type="text" name="claim_number" [(ngModel)]="claim.claim_number"
                        readonly placeholder="Claim Number" class="form-control form-control-sm">                    
                </div>
            </div>
            <div class="col-md-6">                
                <mat-form-field class="w-100" style="margin-top: -10px;">
                    <mat-label>Claim Type</mat-label>
                    <mat-select [(ngModel)]="claim.claim_type" class="form-control form-control-sm" name="claim_type"
                        required #claim_type="ngModel" [ngClass]="{ 'is-invalid': f.submitted && claim_type.invalid }">
                        <mat-option [value]="1">New Claim</mat-option>
                    </mat-select>
                    <div *ngIf="f.submitted && claim_type.invalid" class="invalid-feedback">
                        Type is required.
                    </div>
                </mat-form-field>
                <mat-form-field class="w-100" style="margin-top: 5px;">
                    <mat-label>Claim Status</mat-label>
                    <mat-select [(ngModel)]="claim.status" class="form-control form-control-sm" name="status" required
                        #status="ngModel" [ngClass]="{ 'is-invalid': f.submitted && status.invalid }">
                        <mat-option [value]="1">Case Opened</mat-option>
                    </mat-select>
                    <div *ngIf="f.submitted && status.invalid" class="invalid-feedback">
                        Status is required.
                    </div>
                </mat-form-field>
                <div class="form-group mt-2">
                    <input type="text" required name="legacy_claim_number" [(ngModel)]="claim.legacy_claim_number"
                        #legacy_claim_number="ngModel" placeholder="AXA Claim Number" class="form-control form-control-sm"
                        [ngClass]="{ 'is-invalid': f.submitted && legacy_claim_number.invalid }">
                    <div *ngIf="f.submitted && legacy_claim_number.invalid" class="invalid-feedback">
                        AXA claim number is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="isAdminStaff">
            <button type="submit" class="btn btn-success">Save</button>
            
        </div>
    </form>

    <div *ngIf="claim.id" class="mt-5">
        <h5>Claim Documents and Reports <a (click)="uploadDocumentBtn(documents,1)" class="btn btn-success ml-3" *ngIf="isAdminStaff">Upload Document</a></h5>
        <ag-grid-angular style="width: 100%; height: 350px;" class="ag-theme-bootstrap" [gridOptions]="gridOptionsDocs"
        [rowData]="rowDataDocs" [columnDefs]="columnDefsDocs" animateRows pagination paginationPageSize=10 rowHeight=27
        headerHeight=35 (rowClicked)='onGridRowClickedDocs($event,documents,documentsDelete,1)'>
    </ag-grid-angular>
        <h5 class="mt-5">Invoices <a (click)="uploadDocumentBtn(documents,2)" class="btn btn-success ml-3" *ngIf="isAdminStaff">Upload Document</a></h5>
        <ag-grid-angular style="width: 100%; height: 350px;" class="ag-theme-bootstrap" [gridOptions]="gridOptionsInv"
        [rowData]="rowDataInv" [columnDefs]="columnDefsInv" animateRows pagination paginationPageSize=10 rowHeight=27
        headerHeight=35 (rowClicked)='onGridRowClickedDocs($event,documents,documentsDelete,2)'>
    </ag-grid-angular>
        <h5 class="mt-5">Correspondents <a (click)="uploadDocumentBtn(documents,3)" class="btn btn-success ml-3" *ngIf="isAdminStaff">Upload Document</a></h5>
        <ag-grid-angular style="width: 100%; height: 350px;" class="ag-theme-bootstrap" [gridOptions]="gridOptionsCorrespondent"
        [rowData]="rowDataCorrespondent" [columnDefs]="columnDefsCorrespondent" animateRows pagination paginationPageSize=10 rowHeight=27
        headerHeight=35 (rowClicked)='onGridRowClickedDocs($event,documents,documentsDelete,3)'>
    </ag-grid-angular>
    </div>
</div>

<ng-template #documents let-modal>
    <div class="modal-header">
        <h4>Document</h4>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Title</label>
            <input type="text" name="document_title" [(ngModel)]="docTitle" class="form-control form-control-sm">
        </div>
        <div class="form-group" *ngIf="!documentsEditMode">
            <label>Document</label>
            <div class="custom-file">
                <input type="file" name="file_name" (change)="getUploadedFile($event.target.files)"
                    class="custom-file-input" id="customFile">
                <label class="custom-file-label" for="customFile">{{quote.quote_document}}</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" *ngIf="!documentsEditMode" class="btn btn-success" (click)="documentUpload()">Upload</button>
        <button type="button" *ngIf="documentsEditMode" class="btn btn-success" (click)="updateDocument()">Update</button>
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<ng-template #documentsDelete let-modal>
    <div class="modal-header">
        <h4>Delete Document</h4>
    </div>
    <div class="modal-body text-center">
        <img src="assets/images/deactivate.png" width="50"><br>
        Are you sure you want to delete document {{docTitle}}?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="deleteDocument()">Yes</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
    </div>
</ng-template>

<ng-template #resModal let-modal>
    <div class="modal-body">
        <div class="text-success">{{succssMsg}}</div>
        <div class="text-danger">{{errorMsg}}</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
    </div>
</ng-template>