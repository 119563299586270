<form [formGroup]="fullDetailSection" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-md-8">
            <app-livestock-common-sections formControlName="commonSections" [brokers]="brokers" [submitted]="submitted"
                [selectedTabIndex]="selectedTabIndex" [quoteModelDetails]="quoteModelDetails"
                (calDetails)="calDetails($event)" (changedSelectedTabIndex)="changedSelectedTabIndex($event)">
            </app-livestock-common-sections>

            <app-proposer formControlName="proposer" [submitted]="submitted" [selectedTabIndex]='selectedTabIndex'
                [quoteModelDetails]="quoteModelDetails"></app-proposer>

            <app-animals-insurance formControlName="animals" [submitted]="submitted" [staticData]="staticData"
                [selectedTabIndex]='selectedTabIndex' [quoteModelDetails]="quoteModelDetails"
                (calDetails)="calDetails($event)">
            </app-animals-insurance>
            <app-commissions [brokers]="brokers" [quoteModelDetails]="quoteModelDetails"
                (calDetails)="calDetails($event,'commissions')">
            </app-commissions>
            <div *ngIf="quote_type <= 3">
                <a class="btn btn-default btn-gray mr-3" (click)="backBtn()">Back</a>
                <button class="btn btn-default btn-success" [disabled]="quoteModelDetails.isButton">Proceed</button>
            </div>
            <div *ngIf="quote_type == 4">
                <button class="btn btn-default btn-gray">Cancel</button>
            </div>
            <div *ngIf="quote_type == 5">
                <button class="btn btn-default btn-gray">Lapse</button>
            </div>
            <div *ngIf="quote_type == 6">
                <button class="btn btn-default btn-gray">Submit for NTU</button>
            </div>
        </div>
        <div class="col-md-4">
            <app-livestock-calculations [quoteModelDetails]="quoteModelDetails"></app-livestock-calculations>
        </div>
    </div>
</form>
<!-- <p>
    Form is {{fullDetailSection.valid ? 'Valid' : 'Invalid'}}
</p>
<pre>
            {{fullDetailSection.value | json}}
</pre> -->