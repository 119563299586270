<div class="card-header bg-success">
    <div class="row">
        <div class="col-md-6">
            <h3>Lapse Report</h3>
        </div>
    </div>
</div>
<div class="card-body">
    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <div class="col-md-4">Start Date</div>
                    <div class="col-md-8">
                        <input type="text" required name="bdr_start_date" placeholder="Start Date"
                            class="form-control form-control-sm datepicker" matInput (focus)="myDatepicker.open()"
                            [matDatepicker]="myDatepicker" [(ngModel)]="start_date"
                            #bdr_start_date="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && bdr_start_date.invalid }">
                        <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                        <div *ngIf="f.submitted && bdr_start_date.invalid" class="invalid-feedback">
                            Start date is required.
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">End Date</div>
                    <div class="col-md-8">
                        <input type="text" required name="bdr_end_date" placeholder="End Date"
                            class="form-control form-control-sm datepicker" matInput (focus)="endDateDatepicker.open()"
                            [matDatepicker]="endDateDatepicker" [(ngModel)]="end_date"
                            #bdr_end_date="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && bdr_end_date.invalid }">
                        <mat-datepicker-toggle [for]="endDateDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDateDatepicker></mat-datepicker>
                        <div *ngIf="f.submitted && bdr_end_date.invalid" class="invalid-feedback">
                            End date is required.
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">Product</div>
                    <div class="col-md-8">
                        <mat-form-field class="w-100">
                            <mat-label>Product</mat-label>
                            <mat-select [(ngModel)]="product" class="form-control form-control-sm" name="product">
                                <mat-option [value]="">Select Product</mat-option>
                                <mat-option *ngFor="let prod of allProducts" [value]="prod.id">
                                    {{prod.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-success btn-gray">Submit</button>
                </div>
            </div>
        </div>
    </form>
</div>