import { Component, OnInit,Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteService } from '../../quote.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { LivestockService } from '../livestock.service';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';

@Component({
  selector: 'app-quotation-confirmation',
  templateUrl: './quotation-confirmation.component.html',
  styleUrls: ['../../quote.component.css']
})
export class QuotationConfirmationComponent implements OnInit {
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() proceedToNextTab = new EventEmitter();
  @Input() quoteModelDetails;
  quoteDetails = new QuoteVersions();
  calculations = new CalculationsDetails();

  constructor(private lvService: LivestockService, private sessionSt: LocalStorageService, private _snackBar: MatSnackBar, 
    private modalService: NgbModal, private quoteService: QuoteService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quoteModelDetails;
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  proceedBtn(converttopolicymodal) {
    this.convertToPolicy(converttopolicymodal);
    
  }

  /*
  * On Policy Start Date Change
  */
  onPolicyStartDateChange(event) {
    this.disablIfPastDate = false;
  }

   /*
  * Convert to Policy
  */
   disablIfPastDate: boolean = false;
   minPolicyDate;
   maxPolicyDate;
   convertToPolicy(converttopolicymodal) {
     if (this.sessionSt.retrieve('company_role') == 'Broker') {
       let maxDate = new Date().setDate(new Date().getDate() + 30);
       this.maxPolicyDate = new Date(maxDate);
       this.minPolicyDate = new Date();
     } else {
      if(this.sessionSt.retrieve('user_group') == 'Staff') {
        this.minPolicyDate = new Date();
      }
    }
     // Disable convert to policy buttons if date is past date
     let policy_start_date = new Date(this.quoteDetails.policy_start_date);
     policy_start_date.setHours(0, 0, 0, 0);
     let today = new Date();
     today.setHours(0, 0, 0, 0);
     if (policy_start_date.getTime() < today.getTime() && this.sessionSt.retrieve('company_role') == 'Broker') {
       this.disablIfPastDate = true;
     }
     this.modalService.open(converttopolicymodal, { centered: true });
   }
 
   convertToPolicyPost() {
     let data: any = '';
     data = {
       quote_id: this.quoteDetails.id,
       policy_ref_number: this.quoteDetails.policy_ref_number,
       policy_start_date: formatDate(this.quoteDetails.policy_start_date, 'yyyy-MM-dd', 'en-US')
     };
     this.quoteService.convertToPolicy(data)
       .subscribe(
         (response: any) => {
           this.modalService.dismissAll();
           if (response.success) {
            let temp;
            temp = this.quoteDetails;
            this.quoteDetails = response.result.quote_versions[0];
            this.quoteDetails = this.lvService.bindQouteSaveApiResponse(this.quoteDetails, response.result);   
            this.quoteDetails.calculations = this.calculations;            
            this.quoteDetails.prev_sequence_number = temp.prev_sequence_number;
            this.quoteDetailsUpdated.emit(this.quoteDetails);
      this.proceedToNextTab.emit();
           } else {
             this._snackBar.open(response.message, '', {
               duration: 5000,
               panelClass: 'text-danger'
             });
           }
 
         });
   }

}
