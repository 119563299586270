import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ElementRef } from '@angular/core';
import { Quote } from '../../../model/quote/quote.model';
import { LocalStorageService } from 'ngx-webstorage';
import { formatDate, TitleCasePipe } from '@angular/common';
import { GridOptions } from "ag-grid-community";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteService } from '../../../quote/quote.service';
import { Router } from "@angular/router";
import { PolicyService } from '../../policy.service';
import { UserInfoService } from '../../../services/user-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-al-policy-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class AlPolicyOverviewComponent implements OnInit {
  @Output() quoteDetailsUpdated = new EventEmitter<Quote>();
  @Output() hasImportantNotes = new EventEmitter();
  quote_end_date: string = '';
  quote;
  quote_type;
  isAdmin: boolean = false;
  @Input() set quoteData(value: any) {
    if (value) {
      this.quote = value;      
    }
  }


  constructor(private sessionSt: LocalStorageService, private modalService: NgbModal, private adminQuoteService: QuoteService,
    private _snackBar: MatSnackBar, private policyService: PolicyService, private titlecasePipe: TitleCasePipe, private router: Router, private el: ElementRef) { }

  minPolicyDate;
  maxPolicyDate;
  underwriters;
  insurers;
  selectedUnderwriterID = this.sessionSt.retrieve('userInfo').id;
  quoteTypes;
  policyReason: string = '';
  policyStatuses;

  ngOnInit(): void {
    if (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') {
      this.isAdmin = true;
    }

    // API - GET INSURERS
    this.adminQuoteService.getInsurers()
      .subscribe(
        (response: any) => {
          this.insurers = response.result;
        });

    // Get Policy Statuses
    this.policyService.getPolicyStatuses()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.policyStatuses = response.result.policy_insurance_status;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  // List Quote Versions
  quoteVersionsUpdated(qudatedQuote) {
    this.quote = qudatedQuote;
    this.quoteDetailsUpdated.emit(qudatedQuote);
    this.transction_type = '';
    this.getQuoteTypes();
  }

  transction_type = '';
  getQuoteTypes() {
    if (!this.transction_type) {
      // Get Quote Types
      this.adminQuoteService.getQuoteTypes(1)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.transction_type = response.result.types[this.quote.type];
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
 
  onhasImportantNotesChange(isImportant) {
    this.hasImportantNotes.emit(isImportant);    
  }

  /*
  * View Quote Details
  */
  quoteDetails() {
    //this.sessionSt.store('policy_meta_id', this.quote.id);
    this.sessionSt.store('quote_ref_id', this.quote.id);
    this.router.navigate(['/alpaca-admin/quote-details', this.quote.id]);
  }
}
