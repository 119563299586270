<div class="card-header bg-success">
    <h3 *ngIf="routeType==2">Thank you for requesting a quotation from Insure My Alpaca. Please confirm:</h3>
    <h3 *ngIf="routeType!=2">Declaration Statement</h3>
</div>
<div class="card-body">
    <div class="form-group bg-white p-3 text-green" *ngFor="let ques of agreedQuestions;">
  {{ques.text}}
    </div>
    <p *ngIf="routeType==3">NOTE: PLEASE ENSURE THE PROPOSER IS AWARE THAT ANY INSURANCE CERTIFICATE ISSUED ON THIS APPLICATION WILL NOT COVER ANY PRE-EXISTING CONDITIONS OF THE ANIMAL (S) TO BE INSURED THAT ARE KNOWN TO THE PROPOSER.</p>
    <div class="form-group">
        <button (click)="agreedBtnAction()" class="btn btn-success btn-sm">Agree</button>
    </div>
</div>