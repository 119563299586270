

export class Address {
    id: string = "";
    address1: string= "";
    address2:string= "";
    address3:string= "";
    address4:string= "";
    city:string= "";
    country:string= "";
    postcode:string= "";
    is_company_or_user:number= 0;
    object_id:string= "";
    status:string= "";
   
    
  }

