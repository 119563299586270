import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Quote } from '../../../model/quote/quote.model';
import { LivestockService } from '../livestock.service';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { QuoteService } from '../../quote.service';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-sale-quote-details',
  templateUrl: './sale-quote-details.component.html',
  styleUrls: ['./sale-quote-details.component.css']
})
export class SaleQuoteDetailsComponent implements OnInit {
  quoteDetailsForm: FormGroup;
  submitted = false;
  //quoteDetails = new Quote();
  @Input() staticData;
  @Input() brokers;
  @Input() selectedTabIndex;
  @Input() quoteModelDetails;
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() proceedToNextTab = new EventEmitter();
  @Output() backToPrevTab = new EventEmitter();
  @Output() newSelectedTabIndex = new EventEmitter();
  quoteDetails = new QuoteVersions();
  calculations = new CalculationsDetails();

  constructor(private fb: FormBuilder, private lvService: LivestockService, private quoteService: QuoteService, 
    private cdRef: ChangeDetectorRef,private route: ActivatedRoute) {
    this.quoteDetailsForm = this.fb.group({
      commonSections: [],
      proposer: [],
      pprOtherDetails: [],
      saleAndClaim: [],
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quoteModelDetails;
    // Bind Broker Commissions
    if (!this.route.snapshot.params.id) {
    this.quoteDetails.calculations = this.lvService.getCals(this.quoteModelDetails);
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  changedSelectedTabIndex(index) {
    this.newSelectedTabIndex.emit(index);
  }

  backBtn() {
    this.backToPrevTab.emit();
  }

  updatedCalDetails;
  calDetails(data, dataFrom = '') {
    this.updatedCalDetails = data;
    // Bind Broker commissions    
    this.calculations = this.lvService.brokerCommissions(dataFrom, data, this.brokers, this.calculations, this.quoteModelDetails, this.staticData);
    this.quoteDetails.calculations = this.calculations;
    this.quoteModelDetails.calculations = this.calculations;
    // Animals
    let animal_details = [];
    if (this.quoteDetailsForm.value.animals) {
      if (this.quoteDetailsForm.value.animals.animal_details.length > 0) {
        animal_details = this.quoteDetailsForm.value.animals.animal_details;
      }
    }
    this.calculations = this.lvService.bindStaticCals(this.quoteDetailsForm.value, this.calculations, this.staticData, this.quoteDetails);
    this.calculations.full_calculate_data(this.quoteDetails, animal_details);
  }

  onSubmit() {
    this.submitted = true;

    let modelObj = this.lvService.modelBinding(this.quoteDetailsForm, this.quoteDetails);
    this.quoteDetails = modelObj;

    if (!this.quoteDetailsForm.valid) {
      this.scrollToError();
      return false;
    }



    let parent_quote = new Quote();
    parent_quote.id = modelObj.quote_meta_id;
    parent_quote.quote_versions[0] = modelObj;
    parent_quote.quote_versions[0].agreed_question_version = "";
    parent_quote.quote_versions[0].sof_version = "";
    let data = {
      quote_data: parent_quote
    };
    this.quoteService.saveLivestockQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteDetails = response.result.quote_data.quote_versions[0];
            this.calculations = this.lvService.brokerCommissions('', data, this.brokers, this.calculations, this.quoteModelDetails, this.staticData);
            this.quoteDetails.calculations = this.calculations;
            this.quoteDetailsUpdated.emit(this.quoteDetails);
            this.proceedToNextTab.emit();
          }
        });
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

}
