<div class="card-header bg-success">
    <h3>Manage Company
        <small><a routerLink="/company" class="text-white float-right"><i class="mdi mdi-arrow-left"></i> Back to
                list</a></small>
    </h3>
</div>

<mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChanged($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            Basic Information
        </ng-template>
        <div class="card-body">
            <form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit(f)" enctype="multipart/form-data">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Company</label>
                            <select class="form-control custom-select custom-select-sm" name="parent_id"
                                [(ngModel)]="companyDetails.parent_id">
                                <option value="null">Select Company</option>
                                <option *ngFor="let parentCompany of parentCompanies" value="{{parentCompany.id}}">
                                    {{parentCompany.name}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Company Name <span class="text-danger">*</span></label>
                            <input type="text" name="name" [(ngModel)]="companyDetails.name" required #name="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && name.invalid }"
                                class="form-control form-control-sm" placeholder="Company Name">
                            <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                                Company name is required.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Main Contact name</label>
                            <input type="text" name="contact_name" [(ngModel)]="companyDetails.contact_name"
                                class="form-control form-control-sm" placeholder="Main Contact name">
                        </div>


                        <div class="form-group">
                            <label>Website Address</label>
                            <input type="text" name="website" [(ngModel)]="companyDetails.website"
                                class="form-control form-control-sm" placeholder="Website Address">
                        </div>
                        <div class="form-group">
                            <label>Terms of Business Agreement ref.</label>
                            <input type="text" name="terms_of_business" [(ngModel)]="companyDetails.terms_of_business"
                                class="form-control form-control-sm" placeholder="Terms of Business Agreement ref.">
                        </div>
                        <!-- <div class="form-group" *ngIf="editMode">
                            <label>Upload Logo</label>
                            <div class="custom-file">
                                <input type="file" name="logo_path" (change)="getUploadedFile($event.target.files)"
                                    class="custom-file-input" id="customFile">
                                <label class="custom-file-label" for="customFile">{{fileName}}</label>
                            </div>
                            <small>Please upload the logo of size 180*99 for best resolution.</small>
                        </div>
                        <div class="form-group row" *ngIf="companyDetails.logo_path && editMode">
                            <div class="col-md-6">
                                <img [src]="imagePath + companyDetails.logo_path" class="img-fluid">
                            </div>
                        </div> -->
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Role <span class="text-danger">*</span></label>
                            <select class="form-control custom-select custom-select-sm" name="role_id"
                                [(ngModel)]="companyDetails.role_id" (change)="roleChange($event)">
                                <option value="">Select Role</option>
                                <option *ngFor="let role of roles" value="{{role.id}}">{{role.name}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>FCA Registration Number</label>
                            <input type="text" name="registration_number"
                                [(ngModel)]="companyDetails.registration_number" class="form-control form-control-sm"
                                placeholder="Registration Number">
                        </div>

                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" name="email" [(ngModel)]="companyDetails.email" #email="ngModel"
                                pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                                [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
                                class="form-control form-control-sm" placeholder="Email">
                            <div *ngIf="email.errors && (email.invalid || email.touched)" class="invalid-feedback">
                                <small *ngIf="email.errors.pattern">Please provide a valid email address</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Contact number</label>
                            <input type="text" name="phone" [(ngModel)]="companyDetails.phone"
                                class="form-control form-control-sm" placeholder="Phone">
                        </div>
                        <div class="form-group row mt-5">
                            <label class="col-md-2">Status</label>
                            <div class="col-md-10">
                                <label class="toggle-switch toggle-switch-dark">
                                    <input type="checkbox" checked="" name="status" [(ngModel)]="companyDetails.status">
                                    <span class="toggle-slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="mt-4" *ngIf="brokerComission">
                    <h6>Commissions</h6>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Broker Comission</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">%</span>
                                    </div>
                                    <input type="number" min="0" step="0.5" name="commission_percentage"
                                        [(ngModel)]="companyDetails.commission_percentage"
                                        class="form-control form-control-sm" placeholder="Broker Comission"
                                        [ngClass]="{ 'is-invalid': f.submitted && (reachedBrokerCommAndRetainedCommMaxLimit  || reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit)}">
                                    <div *ngIf="f.submitted && reachedBrokerCommAndRetainedCommMaxLimit && !reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit" class="invalid-feedback">
                                        Sum of broker commission and retained commission should not be more than {{ maximum_broker_commission_and_ratained_commission }}
                                    </div>
                                    <div *ngIf="f.submitted && reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit && !reachedBrokerCommAndRetainedCommMaxLimit" class="invalid-feedback">
                                        Sum of broker commission, pay away commission and retained commission should not be more than {{ maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Peliwica Ratained Commission</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">%</span>
                                    </div>
                                    <input type="number" min="0" step="0.5" name="peliwica_ratained_commission"
                                        [(ngModel)]="companyDetails.peliwica_ratained_commission"
                                        class="form-control form-control-sm" placeholder="Peliwica Ratained Commission"
                                        [ngClass]="{ 'is-invalid': f.submitted && (reachedBrokerCommAndRetainedCommMaxLimit  || reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit)}">
                                        <div *ngIf="f.submitted && reachedBrokerCommAndRetainedCommMaxLimit && !reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit" class="invalid-feedback">
                                            Sum of broker commission and retained commission should not be more than {{ maximum_broker_commission_and_ratained_commission }}
                                        </div>
                                        <div *ngIf="f.submitted && reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit && !reachedBrokerCommAndRetainedCommMaxLimit" class="invalid-feedback">
                                            Sum of broker commission, pay away commission and retained commission should not be more than {{ maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission }}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Peliwica Pay Away Commission</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">%</span>
                                    </div>
                                    <input type="number" min="0" step="0.5" name="peliwica_pay_away_commission"
                                        [(ngModel)]="companyDetails.peliwica_pay_away_commission"
                                        class="form-control form-control-sm" placeholder="Peliwica Pay Away Commission"
                                        [ngClass]="{ 'is-invalid': f.submitted && reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit}">
                                        <div *ngIf="f.submitted && reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit" class="invalid-feedback">
                                            Sum of broker commission, pay away commission and retained commission should not be more than {{ maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission }}
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Underwriting Fee</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="number" min="0" step="0.5" name="uw_fees"
                                        [(ngModel)]="companyDetails.uw_fees" class="form-control form-control-sm"
                                        placeholder="Underwriting Fee">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label>Premium Loading</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">%</span>
                                </div>
                                <input type="number" min="0" step="0.5" name="loading_percentage"
                                    [(ngModel)]="companyDetails.loading_percentage" class="form-control form-control-sm"
                                    placeholder="Peliwica Pay Away Commission">
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="form-group text-right">
                    <input type="submit" name="submit" value="Save Basic Details" class="btn btn-default btn-gray">
                    <a class="btn btn-success ml-3" [class.disabled]="!isNewCompany" (click)='NextBtnAction()'>Next <i
                            class="mdi mdi-arrow-right"></i></a>
                </div>

            </form>



        </div>
    </mat-tab>
    <mat-tab [disabled]="!isNewCompany">
        <ng-template mat-tab-label>
            Address

        </ng-template>
        <div class="card-body">
            <form #fa="ngForm" (ngSubmit)="fa.form.valid && onAddressSubmit(fa)">
                <input type="hidden" name="company_id" [(ngModel)]="addressCompanyID">
                <input type="hidden" name="id" [(ngModel)]="companyDetails.address.id">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Address 1 <span class="text-danger">*</span></label>
                            <input type="text" name="address1" [(ngModel)]="companyDetails.address.address1" required
                                #address1="ngModel" [ngClass]="{ 'is-invalid': fa.submitted && address1.invalid }"
                                class="form-control form-control-sm" placeholder="Address 1">
                            <div *ngIf="fa.submitted && address1.invalid" class="invalid-feedback">
                                Address line 1 is required.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Address 2</label>
                            <input type="text" name="address2" [(ngModel)]="companyDetails.address.address2"
                                class="form-control form-control-sm" placeholder="Address 2">
                        </div>
                        <div class="form-group">
                            <label>Address 3</label>
                            <input type="text" name="address3" [(ngModel)]="companyDetails.address.address3"
                                class="form-control form-control-sm" placeholder="Address 3">
                        </div>
                        <div class="form-group">
                            <label>Address 4</label>
                            <input type="text" name="address4" [(ngModel)]="companyDetails.address.address4"
                                class="form-control form-control-sm" placeholder="Address 4">
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>City <span class="text-danger">*</span></label>
                            <input type="text" name="city" [(ngModel)]="companyDetails.address.city" required
                                #city="ngModel" [ngClass]="{ 'is-invalid': fa.submitted && city.invalid }"
                                class="form-control form-control-sm" placeholder="City">
                            <div *ngIf="fa.submitted && city.invalid" class="invalid-feedback">
                                City is required.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Country <span class="text-danger">*</span></label>
                            <input type="text" name="country" [(ngModel)]="companyDetails.address.country" required
                                #country="ngModel" [ngClass]="{ 'is-invalid': fa.submitted && country.invalid }"
                                class="form-control form-control-sm" placeholder="Country">
                            <div *ngIf="fa.submitted && country.invalid" class="invalid-feedback">
                                Country is required.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Postcode <span class="text-danger">*</span></label>
                            <input type="text" name="postcode" [(ngModel)]="companyDetails.address.postcode" required
                                #postcode="ngModel" [ngClass]="{ 'is-invalid': fa.submitted && postcode.invalid }"
                                class="form-control form-control-sm" placeholder="Postcode">
                            <div *ngIf="fa.submitted && postcode.invalid" class="invalid-feedback">
                                Postcode is required.
                            </div>
                        </div>
                        <!-- <div class="form-group row mt-5">
                            <label class="col-md-2">Status</label>
                            <div class="col-md-10">
                                <label class="toggle-switch toggle-switch-dark">
                                    <input type="checkbox" checked="" name="status"
                                        [(ngModel)]="companyDetails.address.status">
                                    <span class="toggle-slider round"></span>
                                </label>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="form-group text-right">
                    <a class="btn btn-success mr-3" (click)='PreviousBtnAction()'><i class="mdi mdi-arrow-left"></i>
                        Previous</a>
                    <a class="btn btn-success mr-3" [class.disabled]="!activateProducts" (click)='NextBtnAction()'>Next
                        <i class="mdi mdi-arrow-right"></i></a>
                    <input type="submit" name="submit" value="Save Address" class="btn btn-default btn-gray">

                </div>
            </form>
        </div>

    </mat-tab>

    <mat-tab [disabled]="!activateProducts">
        <mat-expansion-panel expanded style="padding:0 24px 16px !important;border-color: #138A51 !important;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h5>Select Products</h5>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="card-body" style="box-shadow: 1px 1px 8px 1px #138a51;">
                <form #fp="ngForm" (ngSubmit)="fp.form.valid && onSubmitProducts(fp)">
                    <h5>Select Products</h5>
                            <div class="row">
                                <div class="col-md-6" *ngFor="let product of products">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group quote_radio">
                                                <mat-checkbox name="product_{{product.id}}" [value]="product.id"
                                                    [(ngModel)]="product.is_checked">
                                                    {{product.name}}
                                                </mat-checkbox>
                                            </div>
                                            
                                        </div>
                                        <div class="col-md-6">
                                            <div *ngFor="let sp of selectedProducts">
                                                <div *ngIf="product.is_checked && product.id == sp.product_id">
                                                    <a title="View Configuration" class="btn btn-success mr-3" (click)='ProductConfiguration(product.id,product.name)'><i class="mdi mdi-settings"></i></a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    <div class="form-group text-center mt-5">
                        <a class="btn btn-success mr-3" (click)='PreviousBtnAction()'><i class="mdi mdi-arrow-left"></i>
                            Previous</a>
                        <input type="submit" name="submit" value="Save Products" class="btn btn-default btn-gray">
    
                    </div>
                </form>
            </div>
        </mat-expansion-panel>
        <ng-template mat-tab-label>
            Products

        </ng-template>
<div *ngIf="configurationSubTabs">
            <app-product-based-configuration [selectedProductData]="productData"></app-product-based-configuration>
			</div>
    </mat-tab>
</mat-tab-group>

<!-- Modal For Success Message-->
<!-- <jw-modal id="successMsg" class="messageModal">
    <img src="assets/images/success_icon.jpg">
    <p class="mt-4">{{apiMSG}}</p>
    <div *ngIf="isCompanyPopup">  
    <p>Do you want to update address details?</p>
    <p>
        <a class="btn btn-success ml-3" (click)='NextBtnAction()'>Yes</a>
        <a class="btn btn-default text-white ml-3" (click)='closeModal("successMsg")'>No</a>
    </p>
    </div>
</jw-modal> -->