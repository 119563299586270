<mat-accordion> 
    <div class="form_sections" *ngFor="let d of quoteApplicationData.drivers; let i = index">
    <div  *ngIf="d.id">
        <!-- [expanded]="i == 0" -->
        <mat-expansion-panel [expanded]="((i==0 && d.id) || (i==1 && d.id))">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>
                        <u>
                            <span>{{ d.title }} {{ d.first_name }} {{ d.last_name }} </span>
                        </u>
                    </h4>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-md-10">
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>DOB:</label></div>
                        <div class="col-md-8">
                            <input type="text" name="registration_number" [value]="d.dob"
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10">
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Type of licence:</label></div>
                        <div class="col-md-8">
                            <input type="text" name="registration_number" [value]="d.licence_type"
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10">
                    <div class="row mb-3">
                        <div class="col-md-4 text-white"><label>Length licence held:</label></div>
                        <div class="col-md-8">
                            <input type="text" name="registration_number" [value]="d.licence_length"
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    </div>
</mat-accordion>