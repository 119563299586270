import { PolicyHolder } from './policy_holder.model';
import { HorseDetails } from './horse_details.model';
import { FinancialDetails } from './financial_details.model';
import { FactStatement } from './fact_statement.model';
import { HorseSubClass } from './horse_sub_class.model';
import { CalculationsDetails } from '../../quote/calculations/calculations.model';
import { LivestockMeta } from './livestock_meta.model';
import { LivestockClaim } from './livestock_claim.model';
import { LivestockPurchasers } from './livestock_purchasers.model';
export class QuoteVersions {
    product_id:string='';
    id: string = '';
    company_id: string = '';    
    broker_id: string = '';   
    sequence_number: number = 0;    
    type: number = 1;
    quote_ref_number: string = '';       
    policy_ref_number: string = '';
    is_policy: number = 0;
    underwriter: string = null;
    insurer: string = null;
    policy_start_date: Date;
    policy_end_date: Date;
    create_date: string = '';
    insurance_status_id: string = 'e216564f-552a-4e45-ae67-0a63b7074691';
    horse_class_id: string = '';
    horse_class_sub_id: string = '';
    section_cover_sum_insured_id: string = '';
    vet_fee_sum_insured_id: string = '';
    company_excess_id: string = '';
    company_co_pay_excess_id: string = '';
    veterinary_co_pay_id: string = '';
    addon_saddlery_value: number = null;
    addon_trailer_value: number = null;
    does_policy_owner_owns_horse: number = 0;
    is_domicile_in_UK: number = 0;
    quote_meta_id: string = '';
    policy_cover_id: string = '';
    blood_stock_id: string = '';
    blood_stock_arm_linking_id: string = '';
    blood_stock_vet_fee_linking_id: string = '';
    is_aeio: number = 0;
    does_addon_saddlery_on: number = 0;
    does_addon_trailer_on: number = 0;
    domicile_details: string = '';
    is_accepted: number = null;
    life_saving_surgery_id: string = '';
    life_saving_annual_surgery_id: string = '';
    personal_accidental_cover_id: string = '';
    public_liability_cover_id: string = '';
    co_insurance_towards_diagnostic_id: string = '';
    tigerlab_policy_reference: string = '';
    mta_reason_id: string = null;
    mta_reason_other: string = '';
    is_saddlery_tack: number = null;
    quote_converted_date: string = '';
    policy_converted_date: string = '';
    convert_to_quote_notes: string = '';
    rating_version: number = 0;
    is_paid: number = 0;
    paid_date: any = '';
    bank_payment_date: any = '';

    full_class_of_use: string = '';
    full_policy_cover: string = '';
    full_vet_fee_cover: string = '';
    full_life_saving_surgery: string = '';
    full_vet_fee_excess: string = '';
    full_public_liability: string = '';
    full_personal_accident: string = '';
    latest_version: string = '';
    latest_policy_uw_fee: number = 0;
    quote_document = 'Choose file';
    
    
    horse_sub_class: HorseSubClass = new HorseSubClass();
    policy_holder:PolicyHolder = new PolicyHolder();
    horse_details:HorseDetails = new HorseDetails();
    financial_details:FinancialDetails = new FinancialDetails();
    statement_of_facts:Array<FactStatement> = [new FactStatement()];

    documents = [];
    endorsements = [];
    notes = [];
    vet_certificate_submitted: number = null;
    vet_certificate_required: number = null;
    vet_cert_upload_date: string = '';




    status: number = 0;
    quote_id: string = '';   
    policy_cover_index: string = ''; 
    vet_fees_cover_id: string = '';
    slider_broker_comm: number = 0;
    company_co_pay_discount: number = 0.00;  
    is_full_terms_accepted:  number = 0;
    is_sof_accepted:  number = 0;
    
    bloodstock_text: string = '';
    full_company_co_pay_excess = false;
    indicativePage: boolean = false;
    company_co_insurance: boolean = false;
    full_quote_section: boolean = true;
    aeio_quote_section: boolean = true;
    hide_sections_for_blood_stock: boolean = false;
    LifeSaving7500: boolean = false;
    primaryCover: string = '';
    prev_sequence_number: number = null;
    mta_sum_policy_premium: number = 0;
    sum_policy_premium: number = 0;
    latest_policy_start_date: string = '';
    latest_policy_end_date: string = '';
    old_premium_loading_percentage: number = 0;
    static_document_version: string = '';
    quote_type: string = '';
    original_policy_start_date: string = '';

    isIndicative: boolean = true;
    hideAEIOCal: boolean = false;
    policy_status: number = 1;

    calculations: any = new CalculationsDetails();
    versions: any = [];


    // Livestock
    quote_page_index: number = 1;
    livestock_meta: any = new LivestockMeta();
    livestock_details: any = [new HorseDetails()];
    livestock_claims: any = [new LivestockClaim()];
    livestock_purchasers: any = [new LivestockPurchasers()];
    policy_holders: any = [new PolicyHolder()];
    isButton:boolean;
    sofIsDisabled:boolean =false;
    sofIsQuoteEdit:boolean =false;

    agreed_question_version:string="0";
    sof_version:string="0";
    isPolicyData:boolean;

    is_referred: boolean = false;
    is_declined: boolean = false;
    declined_reasons: string = '';

    // Allianz Equine
    is_claim_discount: number=0;
    is_customer_discount: number =0;
    is_disposal_euthansia: number =0;
    is_elective_surgery: number =0;
    is_livery_hispitalization: number =0;
    is_vet_fee_co_pay: number =0;
    is_transportation_on: number =0;

    //AJG
    mortality_claim_loading_id: string = '';
    vet_claim_loading_id: string = '';
    full_mortality_claim_loading: string = '';
    full_vet_claim_loading: string = '';
}
