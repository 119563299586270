import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from "@angular/router";
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;


  constructor(private formBuilder: FormBuilder, private service: LoginService, private sessionSt: LocalStorageService, private router: Router,
    private confirmDialogService: ConfirmDialogService) { }

  ngOnInit(): void {
    // REDIRECT TO LOGIN SCREEN IF NOT LOGGED IN
    if (this.sessionSt.retrieve('login_token') != null) {
      this.router.navigate(["/dashboard"]);
      //this.router.navigateByUrl("/dashboard");
    }




    // VALIDATIONS
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required]]
    });
  }

  // BIND ERRORS TO ALL FORM FIELDS
  get f() { return this.loginForm.controls; }

  // SUBMIT LOGIN FORM
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    let login: any = this.loginForm.value;
    this.service.create(login)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.sessionSt.store('userInfo', response.data.user);
            this.sessionSt.store('companyProducts', response.data.products_assigned);
            this.sessionSt.store('login_token', response.login_token);
            this.sessionSt.store('company_role', response.data.company_role_name);
            this.sessionSt.store('user_group', response.data.user_group_name);
            if (response.data.user.company != null) {
              this.sessionSt.store('broker_company_id', response.data.user.company.id);
              this.sessionSt.store('al_broker_company_id', response.data.user.company.id);
            }
            if (response.data.company_role_name == 'Broker') {
              this.sessionSt.store('is_broker', true);
            } else {
              this.sessionSt.store('is_broker', false);
            }
            if (response.data.user_group_name == 'Staff') {
              this.sessionSt.store('is_staff', true);
            } else {
              this.sessionSt.store('is_staff', false);
            }
            // if (response.data.company_role_name == 'Broker' && response.data.user_group_name == 'Administrator') {
            //   this.sessionSt.store('is_broker', true);
            // } else {
            //   this.sessionSt.store('is_broker', false);
            // }
            // if (response.data.company_role_name == 'Broker' && response.data.user_group_name == 'Staff') {
            //   this.sessionSt.store('is_staff', true);
            // } else {
            //   this.sessionSt.store('is_staff', false);
            // }
            if (response.data.company_role_name == 'Peliwica' && response.data.user_group_name == 'Administrator') {
              this.sessionSt.store('is_admin', true);
            } else {
              this.sessionSt.store('is_admin', false);
            }
            if (response.data.company_role_name == 'Insurer') {
              this.sessionSt.store('is_insurer', true);
            } else {
              this.sessionSt.store('is_insurer', false);
            }
            this.router.navigate(['/dashboard']);
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false,
            };
            this.confirmDialogService.open(options);
          }

        },
        (error) => {
          console.log(error);
          const options = {
            title: '',
            message: error,
            imageType: 'error',
            yesNoBtns: false,
          };
          this.confirmDialogService.open(options);
        }
      );
  }

}
