import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';

@Directive({
  selector: '[appFirstCharUpper]'
})
export class FirstCharUpperDirective {

  constructor(public ngControl: NgControl,public titleCase: TitleCasePipe) { }

  @HostListener('ngModelChange', ['$event'])
  onInputChange(value) {

    if (value && value !== '' && value.length > 0) {
      const arrStr = value.split(' ');
      const finalStrArr = [];
      $.each(arrStr, function(i,e) {
        e = e ? e.charAt(0).toUpperCase() + e.substr(1) : '';
        finalStrArr.push(e);
      })
      
      let res = finalStrArr.join(' ');

    //  let titleCaseStr = this.titleCase.transform(value);
      this.ngControl.valueAccessor.writeValue(res);
    }
  }

}
