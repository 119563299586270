import { Component, OnInit, Input, forwardRef, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder, Validators, FormGroup, FormArray,
  ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS
} from '@angular/forms';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sale-and-claims',
  templateUrl: './sale-and-claims.component.html',
  styleUrls: ['../../quote.component.css', './sale-and-claims.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SaleAndClaimsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SaleAndClaimsComponent),
      multi: true
    }
  ]
})
export class SaleAndClaimsComponent implements ControlValueAccessor, OnInit {
  qdfrm: FormGroup;
  @Input() submitted: boolean;
  @Input() selectedTabIndex;
  @Input() quoteModelDetails = new QuoteVersions();
  QuoteDetails = new QuoteVersions();
  claim_history: FormArray;
  allClaims = [];
  AlllivestockCoverPeriods = [];
  claimFor = [];
  coverClaimFor = [];
  animalTypes = [];
  subscriptions: Subscription[] = [];
  @Input()
  set staticData(value: any) {
    if (value) {
      this.animalTypes = value.sub_animal_types;
      this.allClaims = value.claim_loading;
      this.claimFor = value.claim_for;
      this.coverClaimFor = value.cover_claim_for;
    }
  }

  constructor(private fb: FormBuilder, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.qdfrm = this.fb.group({
      sale_location: [],
      sale_date: [],
      claims: ['', Validators.required],
      claim_history: this.fb.array([]),
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.qdfrm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.QuoteDetails = this.quoteModelDetails;
    if (this.qdfrm) {
      this.qdfrm.get('claims').setValue(this.quoteModelDetails.livestock_meta.claim_count);
      this.qdfrm.get('sale_date').setValue(this.quoteModelDetails.livestock_meta.sale_date);
      this.qdfrm.get('sale_location').setValue(this.quoteModelDetails.livestock_meta.sale_location);
      if (this.quoteModelDetails.livestock_meta.claim_count > 0) {
        (this.qdfrm.get('claim_history') as FormArray).clear();
        this.quoteModelDetails.livestock_claims.forEach((res) => {
          let details = this.fb.group({
            animal_type: res.livestock_type_id,
            claim_for: Math.floor(res.claim_amount).toString(),
            cover_claim_for: res.cover_claimed_for.toString()
          });
          this.claim_history = this.qdfrm.get('claim_history') as FormArray;
          this.claim_history.push(details);
        })
      }
      if (this.selectedTabIndex == 6) {
        this.addClaimHistorySections();
      }
      //this.claimHistory();
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  /*
 * Access form control values
 */
  get frmCtrl() {
    return this.qdfrm.controls;
  }

  /*
  * ControlValueAccessor methods
  * registerOnChange, writeValue, registerOnTouched
  * Used to pass data to parent form
  */
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }
  writeValue(value) {

  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /*
  * Indicate parent form if animal form is valid or not.
  */
  validate(_: FormControl) {
    return this.qdfrm.valid ? null : { pprOtherDetails: { valid: false } };
  }

  /*
  * CLAIMS
  */
  get claimsArr() { return this.frmCtrl.claim_history as FormArray; }

  claimHistory() {
    const claims = this.fb.group({
      animal_type: ['', Validators.required],
      claim_for: ['', Validators.required],
      cover_claim_for: ['', Validators.required]
    });
    if (this.qdfrm) {
      this.claim_history = this.qdfrm.get('claim_history') as FormArray;
      this.claim_history.push(claims);
    }
  }

  /*
  * Add Claim History Sections Based on Number of Claims
  */
  onClaimsChange(event) {
    this.quoteModelDetails.livestock_meta.claim_count = event.value;
    this.addClaimHistorySections();
  }
  addClaimHistorySections() {
    let initialPoint = 1;
    if (this.claim_history) {
      initialPoint = this.claim_history.length + 1;
    }

    if (this.claimsArr.length < this.quoteModelDetails.livestock_meta.claim_count) {
      this.claimsArr.clear();
      for (let i = initialPoint; i <= this.quoteModelDetails.livestock_meta.claim_count; i++) {
        const animals = this.fb.group({
          animal_type: ['', Validators.required],
          claim_for: ['', Validators.required],
          cover_claim_for: ['', Validators.required],
        });

        this.claimsArr.push(animals);
      }
    } else {
      for (let i = this.claimsArr.length; i >= this.quoteModelDetails.livestock_meta.claim_count; i--) {
        this.claimsArr.removeAt(i);
      }
    }
  }
}
