<div class="messageModal">
  <div style="text-align:center;">
    <div *ngIf="imageType == 'deactivate'">
      <img src="assets/images/deactivate.png" width="65">
    </div>
    <div *ngIf="imageType =='activate'">
      <img src="assets/images/success_icon.jpg" width="65">
    </div>
    <div *ngIf="imageType =='success'">
      <img src="assets/images/success_icon.jpg" width="65">
    </div>
    <div *ngIf="imageType =='error'">
      <img src="assets/images/failure_icon.jpg" width="65">
    </div>

    <h4 class="mt-3" mat-dialog-title *ngIf = "title !== ''">
      {{title}}
    </h4>

    <div mat-dialog-content>
      <div class="mt-2 text-left"><div [innerHTML]="message"></div></div>
    </div>

    <div *ngIf="yesNoBtns" mat-dialog-actions style="display: flex;
    justify-content: center;">
      <button class="btn btn-default btn-gray ml-3" mat-button (click)="onDismiss()">No</button>
      <button class="btn btn-default btn-success ml-3" mat-raised-button color="primary"
        (click)="onConfirm()">Yes</button>
    </div>
    <div *ngIf="!yesNoBtns">
      <button class="btn btn-default btn-gray ml-3 mt-4" mat-button (click)="onDismiss()">Close</button>
    </div>
  </div>
</div>