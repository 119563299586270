import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { QuoteVersions } from '../../../model/quote/quote_versions.model';
import { CalculationsDetails } from '../livestock-calculations/livestock-calculations.model';
import { LivestockService } from '../livestock.service';
import { CompanyBankDetails } from '../../../model/quote/company_bank_details.model';
import { QuoteService } from '../../quote.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-lv-policy-payment',
  templateUrl: './lv-policy-payment.component.html',
  styleUrls: ['../../quote.component.css']
})
export class LvPolicyPaymentComponent implements OnInit {
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() proceedToNextTab = new EventEmitter();
  @Input() quoteModelDetails;
  @Input() selectedTabIndex;
  quoteDetails = new QuoteVersions();
  calculations = new CalculationsDetails();
  bankDetails = new CompanyBankDetails();

  constructor(private lvService: LivestockService, private cdRef: ChangeDetectorRef, private qs: QuoteService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.quoteDetails = this.quoteModelDetails;
    if (this.selectedTabIndex == 5) {
      this.quoteDetails.calculations = this.lvService.bindCalculations(this.quoteModelDetails);
      this.calculations = this.quoteDetails.calculations;

      // Get Broker Company Bank Details
      this.getBrokerCompanyBankDetails();
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  getBrokerCompanyBankDetails() {
    this.qs.getCompanyBankDetails(this.quoteDetails.company_id, environment.LIVESTOCK_ID)
      .subscribe(
        (response: any) => {
          if (response.success) {
            if (response.result) {
              this.bankDetails = response.result;
            } else {
              this.bankDetails = new CompanyBankDetails();
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }


  proceedBtn() {
    this.quoteDetailsUpdated.emit(this.quoteDetails);
    this.proceedToNextTab.emit();
  }

}
